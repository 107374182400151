import React, { useState, useRef, Fragment } from "react";
import Axios from "axios";
import { getCourierName } from "../../../trackingboard/OrderTracker/functions/getCourierName";
import ScannedCourierCompanies from "../res/ScannedCourierCompanies";
import ScannedFails from "../res/ScannedFails";
import ScanLog from "../res/ScanLog";
let audio = new Audio(require("../res/sounds/tink.wav"));

const PrePackScan = ({ setPage }) => {
  document.title = "Pre Packaging Scan";

  const [result, setResult] = useState("");
  const [previouslyScanned, setPreviouslyScanned] = useState([]);
  const [failScans, setFailScans] = useState([]);
  const [successScans, setSuccessScans] = useState([]);

  const inputElement = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) handleSubmit();
  };

  const handleSubmit = async () => {
    const awb = inputElement.current.value;
    if (awb.length !== 0) {
      try {
        const { data } = await Axios.get(`/api/warehouse/isLabelPacked/${awb}`);
        if (data.status) {
          setResult(data.message);
          if (data.colorCode === "green") {
            const success = {
              awb,
              message: data.message,
            };
            setSuccessScans([...successScans, success]);
          } else if (data.colorCode === "orange") {
            const previous = {
              awb,
              message: data.message,
            };
            setPreviouslyScanned([...previouslyScanned, previous]);
          }
        }
      } catch (error) {
        console.log("in catch block");
        const { data } = error.response;
        const fail = {
          awb,
          message: data.message,
        };
        setResult(data.message);
        setFailScans((failScans) => {
          return [...failScans, fail];
        });
      }
      audio.play();
      inputElement.current.value = "";
    }
  };

  const styles = {
    scanAwbheaderContainer: {
      padding: "0.5rem",
      color: "white",
      background: "#2980b9",
    },
    scanAwbHeader: {
      textAlign: "center",
    },
    backButtonContainer: {
      marginLeft: "2vw",
      marginTop: "2vh",
      width: "auto",
      color: "#2980B9",
    },
  };

  return (
    <Fragment>
      <div style={styles.backButtonContainer}>
        <i
          className="fas fa-2x fa-arrow-circle-left back-button-icon"
          onClick={() => setPage("main")}
        ></i>
      </div>
      <div className="scan-awb-frontend">
        <div style={styles.scanAwbheaderContainer}>
          <div style={styles.scanAwbHeader}>
            <h2>Pre-packaging Scan</h2>
          </div>
        </div>
        <div className="scan-awb-body">
          <input
            type="text"
            name="awb_no"
            id="awb_input"
            className="form-control form-control-lg"
            placeholder="Scan AWB to be packed here"
            onKeyDown={(e) => handleKeyDown(e)}
            ref={inputElement}
          ></input>
          <div className="scan-awb-message">
            <h4 id="result_msg">{result}</h4>
          </div>
          {successScans.length === 0 ? null : (
            <ScanLog
              scans={successScans}
              header="Successful Scans"
              color=" #2ECC71"
            />
          )}
          {previouslyScanned.length === 0 ? null : (
            <ScanLog
              scans={previouslyScanned}
              header="Previously Scanned"
              color=" #FFA500"
            />
          )}
          {failScans.length == 0 ? null : (
            <ScanLog scans={failScans} header="Fail Scans" color=" #e74c3c" />
          )}
        </div>
        <audio src="../res/sounds/tink.wav" id="scanned_sound"></audio>
      </div>
    </Fragment>
  );
};

export default PrePackScan;

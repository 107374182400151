import { states } from "../utils/states";
import axios from "axios";
import { recordState } from "./recordState";
export const updateState = async (status, id, fetchOrders, products) => {
  let payment;

  status = states.indexOf(status);
  console.log(status);
  if (status === 0) {
    payment = prompt("Enter Amount Paid");
    recordState("payment", payment, id);
  }
  if (status === 4) {
    let count = {};
    products.map(product => {
      count[product.name] = prompt(
        `Enter count of ${product.name} (Out of ${product.quantity})`
      );
    });

    recordState("count", count, id);
  }

  let data = {
    payment,
    status,
    id
  };

  let result = await axios({
    method: "post",
    url: "/api/IMS/update",
    data
  });

  if (result.data.code) {
    console.log(result.data.msg);
    fetchOrders();
  }
};

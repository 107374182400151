import Axios from "axios";
import React, { useEffect, useState } from "react";

const DraftOrderDetails = ({
  lineItems,
  setLineItems,
  setNotification,
  discountCoupon,
  setDiscountCoupon,
  discountCode,
  setDiscountCode,
  store,
  partialPaymentAmount,
  flags,
  setLoading,
  totalPrice,
  setTotalPrice,
}) => {
  // const [totalPrice, setTotalPrice] = useState(null);
  const [discountedTotal, setDiscountedTotal] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(null);

  // fetch order total
  const fetchOrderTotal = (productArrayWithPrice) => {
    let total = 0;
    productArrayWithPrice.map((item) => {
      const price = parseFloat(item.quantity) * parseFloat(item.price);
      total += price;
    });
    return total;
  };

  // fetch the latest product price of line items
  const fetchLatestProductPrice = async (products) => {
    setLoading(true);
    const productArray = products.map((lineItem) => ({
      pid: lineItem.product.product_id,
      vid: lineItem.product.variant_id,
    }));

    const payload = {
      productArray,
      store: store.shopifyName,
    };
    const { data } = await Axios.post(
      "/api/support/fetchLatestProductPrice",
      payload
    );

    const newItemsWithPrice = [];
    for (const product of data) {
      const itemWithPrice = products.find(
        (item) => item.product.variant_id === product.vid
      );
      itemWithPrice.price = product.price;
      newItemsWithPrice.push(itemWithPrice);
    }
    setLoading(false);
    return newItemsWithPrice;
  };

  const normalDraftWithoutDiscount = async () => {
    setLoading(true);
    console.log(`Normal order without discount!`);
    const finalLineItems = [];
    const lineItemsWithPrice = await fetchLatestProductPrice(lineItems);
    if (lineItemsWithPrice.length === 0) {
      setNotification(
        "failure",
        "Unable to find product/variant on shopify store! Kindly check on shopify if the product/variant is still there!"
      );
      setLoading(false);
      return;
    }

    for (const product of lineItems) {
      const { price } = lineItemsWithPrice.find(
        (item) => item.product.variant_id === product.product.variant_id
      );
      finalLineItems.push({
        product: product.product,
        quantity: product.quantity,
        price,
      });
    }

    const total = fetchOrderTotal(finalLineItems);
    setLineItems(finalLineItems);
    setTotalPrice(total);
    setLoading(false);
  };

  const discountManipulation = async () => {
    // fetch discount details
    const { data } = await Axios.get(
      `/api/admin/fetchDiscountCoupon/${discountCode}`
    );
    console.log(data);

    if (!data.status) {
      setNotification("failure", data.message);
      setDiscountCode("");
      setDiscountCoupon(null);
      return;
    }

    const { discountDetails } = data;

    const {
      discountType,
      value,
      productsX,
      productsY,
      store: storeAssociatedWithDiscount,
    } = discountDetails;
    if (storeAssociatedWithDiscount.storeAnchor !== store.storeAnchor) {
      console.log(`Discount not associtaed with store selected!`);
      setNotification(
        "failure",
        "The discount applied is not associated with the store selected!"
      );
      setDiscountCode("");
      setDiscountCoupon(null);
      return;
    }
    setDiscountCoupon(discountDetails);

    const finalLineItems = [];
    // fetch the product prices. Right now only for products X price is being found as products Y are given free(phase 2 this might change)
    let allProducts = [...productsX];
    if (productsY) {
      allProducts = [...allProducts, ...productsY];
    }
    const uniqueVariantIds = {};
    const productArray = [];
    allProducts.map((product) => {
      const vid = product.product.variant_id;
      if (!uniqueVariantIds[vid]) {
        uniqueVariantIds[vid] = true;
        productArray.push(product);
      }
    });
    console.log(allProducts);

    const lineItemsWithPrice = await fetchLatestProductPrice(allProducts);
    console.log(lineItemsWithPrice);
    let vidFound;

    for (const vid in uniqueVariantIds) {
      vidFound = lineItemsWithPrice.find(
        (item) => item.product.variant_id == vid
      );
      if (!vidFound) {
        setNotification(
          "failure",
          "Unable to find one of the products on Shopify! Kindly check on shopify if all the variants exist!"
        );
        return;
      }
    }
    if (lineItemsWithPrice.length === 0) {
      setNotification(
        "failure",
        "Unable to find product on store! Kindly check on shopify if the product is still there!"
      );
      return;
    }
    console.log(`line items with price - ${lineItemsWithPrice}`);

    // do the discount manipulations --> SOMETHING WRONG WHEN MULTIPLE QUANTITY PRODUCTS ARE THERE BE CAREFUL
    if (discountType === "fixed-amount") {
      for (const product of productsX) {
        const { price } = lineItemsWithPrice.find(
          (item) => item.product.variant_id === product.product.variant_id
        );
        finalLineItems.push({
          product: product.product,
          quantity: product.quantity,
          price,
        });
      }
      let total = fetchOrderTotal(finalLineItems);

      // apply fixed amount discount
      total = total - value;
      setLineItems(finalLineItems);
      setDiscountAmount(value);
      setDiscountedTotal(total);
      setTotalPrice(total);
    } else if (discountType === "percentage") {
      for (const product of productsX) {
        const { price } = lineItemsWithPrice.find(
          (item) => item.product.variant_id === product.product.variant_id
        );
        finalLineItems.push({
          product: product.product,
          quantity: product.quantity,
          price,
        });
      }
      let total = fetchOrderTotal(finalLineItems);

      // apply percentage discount
      const afterDiscount = Math.floor((total * (100 - value)) / 100);
      setLineItems(finalLineItems);
      setDiscountAmount(total - afterDiscount);
      setDiscountedTotal(afterDiscount);
      setTotalPrice(afterDiscount);
    } else if (discountType === "buy-x-get-y") {
      // const { x, y } = value;
      console.log(`Discount type - buy x get y`);

      // buy items
      for (const product of productsX) {
        const { price } = lineItemsWithPrice.find(
          (item) => item.product.variant_id === product.product.variant_id
        );
        finalLineItems.push({
          product: product.product,
          quantity: product.quantity,
          price,
        });
      }

      // get items
      let discountReduction = 0;
      for (const product of productsY) {
        const { price } = lineItemsWithPrice.find(
          (item) => item.product.variant_id === product.product.variant_id
        );
        discountReduction += parseInt(product.quantity) * price;
        console.log(`Discount reduction - ${discountReduction}`);
        finalLineItems.push({
          product: product.product,
          quantity: product.quantity,
          price: "0",
        });
      }

      // apply discount
      let total = fetchOrderTotal(finalLineItems);
      setLineItems(finalLineItems);
      setDiscountAmount(discountReduction);
      setDiscountedTotal(total);
      setTotalPrice(total);
    } else if (discountType === "buy-x-at-fixed-price") {
      for (const product of productsX) {
        const { price } = lineItemsWithPrice.find(
          (item) => item.product.variant_id === product.product.variant_id
        );
        finalLineItems.push({
          product: product.product,
          quantity: product.quantity,
          price,
        });
      }
      let totalPrice = fetchOrderTotal(finalLineItems);

      // apply fixed amount discount
      const difference = totalPrice - value;
      setLineItems(finalLineItems);
      setDiscountAmount(difference);
      setDiscountedTotal(value);
      setTotalPrice(value);
    }
  };

  // useEffects
  useEffect(() => {
    if (discountCode) {
      console.log(`discount manipulation use effect is called`);
      discountManipulation();
    }
  }, [discountCode]);

  useEffect(() => {
    if (!discountCode && lineItems.length > 0) {
      let flag = false;
      lineItems.map((item) => {
        if (!item.price) {
          flag = true;
        }
      });
      if (flag) {
        console.log(lineItems);
        normalDraftWithoutDiscount();
      }
    }
  }, [lineItems]);

  useEffect(() => {
    if (partialPaymentAmount > 0) {
      let total;
      if (discountCoupon) {
        total = discountedTotal;
      } else {
        total = fetchOrderTotal(lineItems);
      }
      setTotalPrice(total - partialPaymentAmount);
    } else {
      let total;
      if (discountCoupon) {
        total = discountedTotal;
      } else {
        total = fetchOrderTotal(lineItems);
      }
      setTotalPrice(total);
    }
  }, [partialPaymentAmount]);

  return lineItems.length > 0 ? (
    <>
      <hr
        style={{
          backgroundColor: "#ddd",
          width: "95%",
          margin: "0.8rem auto",
          opacity: "0.3",
        }}
      ></hr>
      <div className="reusable-small-card">
        <h5 style={{ textAlign: "center" }}>DRAFT ORDER SUMMARY</h5>
        <div>
          <div>
            <ul>
              {lineItems.map((item, index) => (
                <li>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "70% 15% 15%",
                    }}
                  >
                    <div>{`${item.product.title}`}</div>
                    <div>{`\u20B9${item.price} X ${item.quantity}`}</div>
                    <div
                      style={{ textAlign: "right", color: "green" }}
                    >{`+ \u20B9${
                      parseFloat(item.price) * parseFloat(item.quantity)
                    }`}</div>
                  </div>
                </li>
              ))}
              {discountCoupon ? (
                <li>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "70% 15% 15%",
                    }}
                  >
                    <div>{`Discount value`}</div>
                    <div></div>
                    <div
                      style={{ textAlign: "right", color: "red" }}
                    >{`- \u20B9${discountAmount}`}</div>
                  </div>
                </li>
              ) : null}
              {partialPaymentAmount > 0 ? (
                <li>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "70% 15% 15%",
                    }}
                  >
                    <div>{`Partial payment`}</div>
                    <div></div>
                    <div
                      style={{ textAlign: "right", color: "red" }}
                    >{`- \u20B9${partialPaymentAmount}`}</div>
                  </div>
                </li>
              ) : null}

              {["STORE_21"].includes(store.storeAnchor) ? (
                <li>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "70% 15% 15%",
                    }}
                  >
                    <div>{`Shipping Charges`}</div>
                    <div>
                      {flags.paid ? `Free shipping` : `Cash On Delivery`}
                    </div>
                    <div style={{ textAlign: "right", color: "green" }}>
                      {flags.paid ? `+ \u20B90` : `+ \u20B925`}
                    </div>
                  </div>
                </li>
              ) : (
                <li>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "70% 15% 15%",
                    }}
                  >
                    <div>{`Shipping Charges`}</div>
                    <div>{`Free shipping`}</div>
                    <div
                      style={{ textAlign: "right", color: "green" }}
                    >{`+ \u20B90`}</div>
                  </div>
                </li>
              )}
            </ul>
          </div>

          {flags.paid ? (
            <div className="draft-order-details" style={{ marginLeft: "1rem" }}>
              <div style={{ color: "green" }}>PREPAID ORDER</div>
              <div style={{ textAlign: "right" }}></div>
            </div>
          ) : null}
          {discountCoupon ? (
            <div className="draft-order-details" style={{ marginLeft: "1rem" }}>
              <div>Discount Name</div>
              <div
                style={{ textAlign: "right" }}
              >{`${discountCoupon.discountName}`}</div>
            </div>
          ) : null}
          <div style={{ textAlign: "right", color: "green" }}>
            <b>{`TOTAL    ${
              flags.paid
                ? `\u20B90`
                : `\u20B9${
                    ["STORE_21"].includes(store.storeAnchor)
                      ? totalPrice + 25
                      : totalPrice
                  }`
            }`}</b>
          </div>
        </div>
      </div>
      <hr
        style={{
          backgroundColor: "#ddd",
          width: "95%",
          margin: "0.8rem auto",
          opacity: "0.3",
        }}
      ></hr>
    </>
  ) : null;
};

export default DraftOrderDetails;

import React from "react";

const SurfaceProductTag = ({ surfaceProduct, removeSelectedProduct }) => {
  // const [surfaceProduct, setSurfaceProduct] = useState(surfaceProduct);
  return (
    <div className="surface-tag">
      <span>{surfaceProduct.title + "  "}</span>
      <span onClick={() => removeSelectedProduct(surfaceProduct)}>
        <i className="fas fa-times cross-icon"></i>
      </span>
    </div>
  );
};

export default SurfaceProductTag;

// export const productArray = {
//   0: "None",
//   1: "Product 1",
//   2: "Product 2",
//   3: "Product 3",
//   4: "Product 4",
//   5: "Product 5"
// };

export const statusLog = [];

//Acessed to display status as text in status column
//Accessed by Filterbar dropdown to display options
export const statusArray = {
  0: "(Shiprocket) None",
  1: "(Shiprocket) New",
  2: "(Shiprocket) Invoiced",
  3: "(Shiprocket) Ready To Ship",
  4: "(Shiprocket) Pickup Scheduled",
  5: "(Shiprocket) Cancelled",
  6: "(Shiprocket) Shipped",
  7: "(Shiprocket) Delivered",
  8: "(Shiprocket) e-Payment Failed",
  9: "(Shiprocket) Returned",
  10: "(Shiprocket) Unmapped",
  11: "(Shiprocket) Unfulfillable",
  12: "(Shiprocket) Pickup Queued",
  13: "(Shiprocket) Pickup Rescheduled",
  14: "(Shiprocket) Pickup error(Created when there is an error on pickup schedule)",
  15: "(Shiprocket) RTO initiated",
  16: "(Shiprocket) RTO delivered",
  17: "(Shiprocket) RTO Acknowledged",
  18: "(Shiprocket) Cancellation Request",
  19: "(Shiprocket) Out for Delivery",
  20: "(Shiprocket) In Transit",
  21: "(Shiprocket) Return Pending",
  22: "(Shiprocket) Return Initiated",
  23: "(Shiprocket) Return Pickup Queued",
  24: "(Shiprocket) Return Pickup Error",
  25: "(Shiprocket) Return in Transit",
  26: "(Shiprocket) Return Delivered",
  27: "(Shiprocket) Return Cancelled",
  28: "(Shiprocket) Return Pickup Generated",
  29: "(Shiprocket) Return Cancellation Requested",
  30: "(Shiprocket) Return Pickup Cancelled",
  31: "(Shiprocket) Return Pickup Rescheduled",
  32: "(Shiprocket) Return Pickedup",
  33: "(Shiprocket) Lost",
  34: "(Shiprocket) Out for Pickup",
  35: "(Shiprocket) Pickup Exception",
  36: "(Shiprocket) Undelivered",
  37: "(Shiprocket) Delivery Delayed",
  38: "(Shiprocket) Partial Delivered",
  39: "(Shiprocket) Destroyed",
  40: "(Shiprocket) Damaged",
  41: "(Shiprocket) Fulfilled",
  42: "(Shiprocket) Archived",
  43: "(Shiprocket) Reached Destination Hub",
  44: "(Shiprocket) Misrouted",
  45: "(Shiprocket) RTO Out For Delivery",
  46: "(Shiprocket) RTO NDR",
  47: "(Shiprocket) Return Out For Pickup",
  48: "(Shiprocket) Return Out For Delivery",
  49: "(Shiprocket) Return Pickup Exception",
  50: "(Shiprocket) Return Undelivered",
  51: "(Shiprocket) Picked Up",
  52: "(Shiprocket) Self Fulfilled",
  55: "(Shiprocket) RTO In Transit",
  99: "(Jarvis) Scheduled By Jarvis",
  100: "(Jarvis) At Calling Dashboard",
  101: "(Jarvis) At Operations Dashboard",
  102: "(Jarvis) Order Cancelled At Calling Dashboard",
  103: "(Jarvis) Order Cancelled At Operations Dashboard",
  104: "(Jarvis) Pincode Not Serviceable",
  105: "(Jarvis) Removed From Operations Dashboard",
  106: "(Jarvis) Cancelled By Jarvis",
  107: "(Jarvis) Out Of Stock",
  108: "(Jarvis) To Be Scheduled(Held Orders)",
  999: "(Jarvis) Orders With No Status(Order Received On Store)",
  801: "(ClickPost) Order Has Been Placed / Manifested on Courier Partner",
  802: "(ClickPost) Pickup Pending",
  803: "(ClickPost) Pickup Failed",
  804: "(ClickPost) Pickup Has Been Done",
  805: "(ClickPost) In Transit",
  806: "(ClickPost) Shipment Out For Delivery",
  807: "(ClickPost) Area For Delivery Is Not Servicable",
  808: "(ClickPost) Shipment Delivered",
  809: "(ClickPost) Delivery Failed",
  810: "(ClickPost) Order Has Been Cancelled",
  811: "(ClickPost) Rto For Shipment has been Requested",
  812: "(ClickPost) Marked As Return",
  813: "(ClickPost) Shipment Is Out For Delivery For RTO",
  814: "(ClickPost) RTO Delivered",
  815: "(ClickPost) RTO Failed",
  816: "(ClickPost) Shipment is Lost",
  817: "(ClickPost) Shipment is damaged",
  818: "(ClickPost) Shipment Is Delayed Or Misroute",
  819: "(ClickPost) Contact To The Customer Care",
  820: "(ClickPost) Shipment Is being held",
  821: "(ClickPost) RTO In Transit",
  825: "(ClickPost) Shipment Out For Pickup",
  826: "(ClickPost) RTO Contact Customer Care",
  827: "(ClickPost) RTO Shipment Delayed",
  828: "(ClickPost) AWB registered on Clickpost",
  829: "(ClickPost) Return Order Placed",
  830: "(ClickPost) Pincode Not Serviceable",
  500: "(GoSwift) Order Placed",
  501: "(GoSwift) In Transit",
  502: "(GoSwift) Out for Delivery",
  503: "(GoSwift) Delivered",
  504: "(GoSwift) Cancelled",
  505: "(GoSwift) RTO",
  506: "(GoSwift) RTO Requested",
  507: "(GoSwift) RTO In Transit",
  508: "(GoSwift) RTO Delivered",
  509: "(GoSwift) RTO Out for Delivery",
  510: "(GoSwift) RTO Failed",
  511: "(GoSwift) Picked Up",
  512: "(GoSwift) Pickup Cancelled",
  513: "(GoSwift) Pickup Pending",
  514: "(GoSwift) Pickup Scheduled",
  515: "(GoSwift) Lost",
  516: "(GoSwift) Damaged",
  517: "(GoSwift) Shipment Delayed",
  518: "(GoSwift) Not Serviceable",
  519: "(GoSwift) Not Picked",
  520: "(GoSwift) Undelivered",
  521: "(GoSwift) Out for Pickup",
  600: "(Shadowfax) Order Placed",
  601: "(Shadowfax) Pickup Pending",
  602: "(Shadowfax) In Manifest",
  603: "(Shadowfax) Received At Dispatch Centre",
  604: "(Shadowfax) Received At Hub",
  605: "(Shadowfax) Out For Delivery",
  606: "(Shadowfax) Delivered",
  607: "(Shadowfax) Cancelled",
  608: "(Shadowfax) Customer Wants Future Delivery",
  609: "(Shadowfax) Customer Not Contactable",
  610: "(Shadowfax) Delivery Not Attempted",
  611: "(Shadowfax) On Hold",
  612: "(Shadowfax) Require Delivery - NDR",
  613: "(Shadowfax) Lost",
  614: "(Shadowfax) RTO Initiated",
  615: "(Shadowfax) RTO Instransit",
  616: "(Shadowfax) RTO Delivered",
  617: "(Shadowfax) RTS Delivered",
  618: "(Shadowfax) Picked Up",
  700: "(Shipyaari) Not Picked",
  701: "(Shipyaari) In Transit",
  702: "(Shipyaari) Out For Delivery",
  703: "(Shipyaari) Delivered",
  704: "(Shipyaari) RTO In Transit",
  705: "(Shipyaari) RTO Delivered",
  706: "(Shipyaari) Exception",
  707: "(Shipyaari) Lost/Damage",
  708: "(Shipyaari) Reverse Delivered",
  709: "(Shipyaari) RTO Exception",
  710: "(Shipyaari) RTO Created",
  711: "(Shipyaari) RTO out for delivery",
  712: "(Shipyaari) Reached Destination",
  713: "(Shipyaari) Reverse In Transit",
  714: "(Shipyaari) Reverse Exception",
  715: "(Shipyaari) Reverse Out for Delivery",
  716: "(Shipyaari) Cancelled",
  777: "(Jarvis) Held due to Lockdown, Schedule after reconfirmation",
};

export const stateArray = {
  0: "None",
  AN: "Andaman and Nicobar Islands",
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CH: "Chandigarh",
  CT: "Chhattisgarh",
  DN: "Dadra and Nagar Haveli",
  DD: "Daman and Diu",
  DL: "Delhi",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JK: "Jammu and Kashmir",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  LD: "Lakshadweep",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OR: "Odisha",
  PY: "Puducherry",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TG: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UT: "Uttarakhand",
  WB: "West Bengal",
};

export const statusAggregations = {
  // prettier-mode matrix
  Shipped: [
    51,
    511,
    804,
    7,
    808,
    503,
    6,
    19,
    20,
    805,
    37,
    38,
    43,
    806,
    818,
    501,
    502,
    21,
    36,
    820,
    809,
    517,
    520,
    15,
    16,
    17,
    55,
    811,
    812,
    813,
    814,
    815,
    821,
    827,
    505,
    506,
    507,
    508,
    509,
    510,
    701,
    702,
    703,
    704,
    705,
    706,
    709,
    708,
    710,
    711,
    712,
    713,
    715,
    602,
    603,
    604,
    605,
    606,
    608,
    609,
    610,
    611,
    612,
    614,
    615,
    616,
    617,
    618,
  ],
  Delivered: [7, 808, 503, 703, 606],
  RTO: [
    15,
    16,
    17,
    45,
    46,
    55,
    811,
    812,
    813,
    814,
    815,
    821,
    827,
    505,
    506,
    507,
    508,
    509,
    510,
    704,
    705,
    709,
    710,
    711,
    614,
    615,
    616,
    617,
  ],
  InTransit: [
    6,
    19,
    20,
    805,
    37,
    38,
    43,
    806,
    818,
    501,
    502,
    701,
    702,
    712,
    713,
    715,
    602,
    603,
    604,
    605,
  ],
  UnDelivered: [21, 36, 820, 809, 517, 520, 608, 609, 610, 611, 612, 706],
  Cancelled: [5, 18, 102, 103, 104, 105, 807, 810, 504, 716, 607],
  PickupPending: [
    3,
    4,
    12,
    13,
    14,
    34,
    35,
    99,
    802,
    828,
    825,
    500,
    513,
    514,
    519,
    521,
    700,
    600,
    601,
  ],
  PickupExceptions: [4, 13, 14, 35, 714],
  ShipRocketRTO: [15, 16, 17, 45, 46, 55],
  Processing: [100, 101],
  rtoAndDelivered: [
    15,
    16,
    17,
    45,
    46,
    55,
    811,
    812,
    813,
    814,
    815,
    821,
    827,
    505,
    506,
    507,
    508,
    509,
    510,
    704,
    705,
    709,
    710,
    711,
    7,
    808,
    503,
    703,
    708,
    614,
    615,
    616,
    617,
  ],
  Errors: [
    1,
    11,
    14,
    24,
    33,
    35,
    38,
    39,
    40,
    44,
    49,
    107,
    803,
    809,
    816,
    817,
    819,
    826,
    512,
    518,
    515,
    516,
    707,
    714,
    613,
  ],
};

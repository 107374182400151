import Axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../global/Spinner";
import ProductDetailsX from "../../operations/res/ProductDetails";
import ProductDetailsY from "../../operations/res/ProductDetails";
import StoreDropdownButton from "../../operations/res/StoreDropdownButton";

const DiscountCoupons = ({ setTab }) => {
  document.title = "Discount Coupons";
  const [store, setStore] = useState(null);
  const [discountName, setDiscountName] = useState("");
  const [discountType, setDiscountType] = useState(null);
  const [fixedAmount, setFixedAmount] = useState("");
  const [percentage, setPercentage] = useState("");
  const [fixedTotalPrice, setFixedTotalPrice] = useState("");
  const [discountCode, setDiscountCode] = useState(null);
  const [loading, setLoading] = useState(false);

  // products states
  const [productsX, setProductsX] = useState([]);
  const [productsY, setProductsY] = useState([]);

  const handleSubmit = async () => {
    if (!store) {
      alert("Please select a store for the discount");
      return;
    }
    if (!discountType) {
      alert("Please a select a discount type to proceed!");
      return;
    }
    if (!(productsX.length > 0)) {
      alert("Products not selected!");
      return;
    }
    if (!discountName) {
      alert("Please enter a name for your discount");
      return;
    }
    setLoading(true);
    const payload = {
      discountName,
      discountType,
      fixedAmount,
      fixedTotalPrice,
      percentage,
      productsX,
      productsY,
      store,
    };
    console.log(payload);

    const { data } = await Axios.post(
      "/api/admin/createDiscountCoupon",
      payload
    );
    setLoading(false);
    alert(data.message);
    if (data.status) {
      setDiscountCode(data.discountCode);
    }
  };

  const styles = {
    backButtonContainer: {
      marginLeft: "2vw",
      marginTop: "2vh",
      width: "auto",
      color: "#2980b9",
    },
  };

  return (
    <>
      <div style={styles.backButtonContainer}>
        <i
          className="fas fa-2x fa-arrow-circle-left back-button-icon"
          onClick={() => setTab("main")}
        ></i>
      </div>
      <div className="reusable-card-container">
        <div className="reusable-card-header">
          <StoreDropdownButton setStore={setStore} store={store} />
          <h3>Discount Coupons</h3>
        </div>
        {store ? (
          <div className="reusable-card-body">
            <div className="reusable-small-card">
              <h5>
                Discount Name<span style={{ color: "red" }}>*</span>
              </h5>
              <input
                id="discount-name"
                className="form-control form-control-sm"
                placeholder="Give your discount a name"
                onChange={(e) => setDiscountName(e.target.value)}
              ></input>
            </div>
            <div className="reusable-small-card">
              <h5>
                Discount Type<span style={{ color: "red" }}>*</span>
              </h5>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <input
                    type="radio"
                    id="percentage"
                    onClick={() => {
                      setDiscountType("percentage");
                      setPercentage("");
                      setFixedAmount("");
                    }}
                    checked={discountType === "percentage"}
                  ></input>
                  <label htmlFor="percentage">Percentage</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="fixed-amount"
                    onClick={() => {
                      setDiscountType("fixed-amount");
                      setPercentage("");
                      setFixedAmount("");
                    }}
                    checked={discountType === "fixed-amount"}
                  ></input>
                  <label htmlFor="fixed-amount">Fixed Amount</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="buy-x-get-y"
                    onClick={() => {
                      setDiscountType("buy-x-get-y");
                      setPercentage("");
                      setFixedAmount("");
                    }}
                    checked={discountType === "buy-x-get-y"}
                  ></input>
                  <label htmlFor="buy-x-get-y">Buy X get Y</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="buy-x-at-fixed-price"
                    onClick={() => {
                      setDiscountType("buy-x-at-fixed-price");
                      setPercentage("");
                      setFixedAmount("");
                    }}
                    checked={discountType === "buy-x-at-fixed-price"}
                  ></input>
                  <label htmlFor="buy-x-at-fixed-price">
                    Buy X at Fixed Price
                  </label>
                </div>
              </div>
            </div>
            {discountType ? (
              <div>
                {discountType === "percentage" ? (
                  <>
                    <div className="reusable-small-card">
                      <h5>
                        Discount Value<span style={{ color: "red" }}>*</span>
                      </h5>
                      <div style={{ width: "35%", display: "flex" }}>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter discount percentage"
                          style={{ width: "85%", marginRight: "0.5rem" }}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setPercentage("");
                              return;
                            }
                            const percentageFloat = parseFloat(e.target.value);
                            setPercentage(percentageFloat);
                          }}
                        ></input>
                        <span>%</span>
                      </div>
                    </div>
                    <div className="reusable-small-card">
                      <h5>
                        Product<span style={{ color: "red" }}>*</span>
                      </h5>
                      <ProductDetailsX
                        store={store}
                        lineItems={productsX}
                        setLineItems={setProductsX}
                      />
                    </div>
                  </>
                ) : null}

                {discountType === "fixed-amount" ? (
                  <>
                    <div className="reusable-small-card">
                      <h5>
                        Discount Value<span style={{ color: "red" }}>*</span>
                      </h5>
                      <div style={{ width: "35%", display: "flex" }}>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter discount amount"
                          style={{ width: "85%", marginRight: "0.5rem" }}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setFixedAmount("");
                              return;
                            }
                            const fixedAmountFloat = parseFloat(e.target.value);
                            setFixedAmount(fixedAmountFloat);
                          }}
                        ></input>
                        <span>INR</span>
                      </div>
                    </div>
                    <div className="reusable-small-card">
                      <h5>
                        Product<span style={{ color: "red" }}>*</span>
                      </h5>
                      <ProductDetailsX
                        store={store}
                        lineItems={productsX}
                        setLineItems={setProductsX}
                      />
                    </div>
                  </>
                ) : null}

                {discountType === "buy-x-get-y" ? (
                  <>
                    <div className="reusable-small-card">
                      <h5>
                        Customer buys<span style={{ color: "red" }}>*</span>
                      </h5>
                      <div>
                        <ProductDetailsX
                          store={store}
                          lineItems={productsX}
                          setLineItems={setProductsX}
                        />
                      </div>
                    </div>
                    <hr style={{ backgroundColor: "#ddd" }}></hr>
                    <div className="reusable-small-card">
                      <h5>
                        Customer gets<span style={{ color: "red" }}>*</span>
                      </h5>
                      <div>
                        <ProductDetailsY
                          store={store}
                          lineItems={productsY}
                          setLineItems={setProductsY}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                {discountType === "buy-x-at-fixed-price" ? (
                  <>
                    <div className="reusable-small-card">
                      <h5>
                        Fixed Total Price<span style={{ color: "red" }}>*</span>
                      </h5>
                      <div style={{ width: "35%", display: "flex" }}>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter a fixed total price"
                          style={{ width: "85%", marginRight: "0.5rem" }}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setFixedTotalPrice("");
                              return;
                            }
                            const fixedAmountFloat = parseFloat(e.target.value);
                            setFixedTotalPrice(fixedAmountFloat);
                          }}
                        ></input>
                        <span>INR</span>
                      </div>
                    </div>
                    <div className="reusable-small-card">
                      <h5>
                        Product<span style={{ color: "red" }}>*</span>
                      </h5>
                      <ProductDetailsX
                        store={store}
                        lineItems={productsX}
                        setLineItems={setProductsX}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}

            <div className="reusable-small-card">
              <h5>Summary</h5>
              <ul>
                {discountType === "fixed-amount" &&
                productsX.length > 0 &&
                fixedAmount ? (
                  <>
                    {productsX.map((item) => (
                      <li>{`Customer buys ${item.quantity} ${item.product.title}`}</li>
                    ))}
                    <li>{`Customer gets \u20B9${fixedAmount} off on total price`}</li>
                  </>
                ) : null}

                {discountType === "percentage" &&
                productsX.length > 0 &&
                percentage ? (
                  <>
                    {productsX.map((item) => (
                      <li>{`Customer buys ${item.quantity} ${item.product.title}`}</li>
                    ))}
                    <li>{`Customer gets ${percentage}% off on total price`}</li>
                  </>
                ) : null}
              </ul>
              {discountType === "buy-x-get-y" &&
              productsX.length > 0 &&
              productsY.length > 0 ? (
                <>
                  {productsX.map((item) => (
                    <li>{`Customer buys ${item.quantity} ${item.product.title}`}</li>
                  ))}
                  {productsY.map((item) => (
                    <li>{`Customer gets ${item.quantity} ${item.product.title} for free`}</li>
                  ))}
                </>
              ) : null}
              {discountType === "buy-x-at-fixed-price" &&
              productsX.length > 0 &&
              fixedTotalPrice ? (
                <>
                  {productsX.map((item) => (
                    <li>{`Customer buys ${item.quantity} ${item.product.title}`}</li>
                  ))}
                  <li>{`At a total price of \u20B9${fixedTotalPrice}`}</li>
                </>
              ) : null}
            </div>
            {discountCode ? (
              <div className="reusable-small-card">
                <h5
                  style={{ color: "#2ecc71" }}
                >{`Discount code created successfully - ${discountCode}`}</h5>
              </div>
            ) : null}
            <div className="final-submit-container">
              <button className="final-submit-button" onClick={handleSubmit}>
                {" "}
                Create Discount
              </button>
              {loading ? (
                <div className="final-submit-spinner-container">
                  <Spinner />
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", margin: "1rem" }}>
            Select a store to create a discount
          </div>
        )}
      </div>
    </>
  );
};

export default DiscountCoupons;

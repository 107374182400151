import React, { useEffect, useState, Fragment, useContext } from "react";
import { GlobalConsumer } from "../../../Global-Context/global-context";

import NavBar from "../res/NavBar";
import Axios from "axios";

const AbandonedCheckouts = () => {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);
  const [store, setStore] = useState("");
  const [processed, setProcessed] = useState(false);
  document.title = "Abandoned Checkouts";
  const pullOrders = async (e) => {
    e.preventDefault();
    setProcessed(true);
    try {
      await Axios.get(
        `/api/orders/getAbandonedCheckoutsV2?shopifyName=${store.shopifyName}&email=${user.email}`
      );
      alert(`Orders were imported, please check your email ${user.email}`);
      setProcessed(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} />
      {store ? (
        <div className="container-fluid">
          <form className="form-horizontal">
            <br />
            <div>
              <p id="status" name="status" />
            </div>
            <div>
              <center>
                <button
                  type="button"
                  className="btn btn-primary mb-2 btn-lg"
                  onClick={(e) => pullOrders(e)}
                >
                  Pull Last 10 Days Abandoned Checkout Orders
                </button>
              </center>
              <center>
                <b
                  style={{
                    textTransform: "uppercase",
                    fontSize: "20px",
                  }}
                >
                  {processed
                    ? `Abandoned orders will be imported for ${store.storeName} and will take sometime (You will get an email to ${user.email} later)`
                    : ""}
                </b>
              </center>
            </div>
          </form>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>Choose a store</h2>
        </div>
      )}
    </Fragment>
  );
};

export default AbandonedCheckouts;

import React from "react";
import { confirmation } from "../../../utils/confirmation";
import Axios from "axios";

function LeadProduct({ product, id, order }) {
  const handleProduct = async (type) => {
    if (type === "Update") {
      if (confirmation()) {
        let data = {
          name: order.name,
          type,
          productID: product.product_id,
          quantity: parseInt(
            document.getElementById(`product_quantity_${id}`).value
          ),
        };
        let result = await Axios.patch("/api/support/updateLead", data);
        alert(result.data.msg);
      }
    } else if (type === "Delete") {
      if (confirmation()) {
        let data = {
          name: order.name,
          type,
          productID: product.product_id,
          quantity: parseInt(
            document.getElementById(`product_quantity_${id}`).value
          ),
        };
        let result = await Axios.patch("/api/support/updateLead", data);
        alert(result.data.msg);
      }
    }
  };

  return (
    <div>
      <center>{`${product.title} (${product.price})`}</center>
      <input
        type="text"
        defaultValue={product.quantity}
        className="productInput"
        id={`product_quantity_${id}`}
      />
      <div style={{ marginTop: "5px", display: "flex" }}>
        <button
          type="button"
          className="product-button product-submit"
          onClick={() => handleProduct("Update")}
        >
          <i className="fas fa-check fa-control" />
        </button>
        <button
          type="button"
          className="product-button product-cancel"
          onClick={() => handleProduct("Delete")}
        >
          <i className="fas fa-times fa-control" />
        </button>
      </div>
      <div
        style={{ padding: "0px 20px", fontWeight: "600", marginTop: "20px" }}
      >
        Total Amount: {product.price}
      </div>
    </div>
  );
}

export default LeadProduct;

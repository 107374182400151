import React, { Fragment, useState, useContext } from "react";
import { orderAction } from "../functions/orderAction";
import NavBar from "../res/NavBar";
import MasterBar from "../../global/MasterBar";
import { GlobalConsumer } from "../../../Global-Context/global-context";
import Axios from "axios";

const OrderAction = ({ location }) => {
  const [store, setStore] = useState("");
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user, setUser] = useState(userInfo);
  const [scheduled, setScheduled] = useState(false);
  let action = location.pathname.slice(12);
  if (action === "cancelAnOrder") document.title = "Cancel An Order";
  else document.title = "Schedule An Order Manually";

  const cancelNormalOrder = async () => {
    setScheduled(false);
    let order = document.getElementById("orderName").value;
    if (order) {
      console.log(order, store);

      let result = await Axios.post(`/api/operations/cancelUnShippedOrder`, {
        order,
        store,
        user,
      });
      alert(result.data.status);
    } else alert("Please Enter Order ID");
  };
  console.log(action);
  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} />
      {store.storeName ? (
        <div className="container-fluid">
          <form className="form-horizontal">
            <br />
            <div>
              <p id="status" name="status" />
            </div>
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-header">
                  {action === "cancelAnOrder" ? (
                    <h3>
                      Want to Cancel An Order Manually ?{" "}
                      {store.storeName
                        ? `FOR(${store.storeName.toUpperCase()})`
                        : "CHOOSE A STORE!!"}
                    </h3>
                  ) : (
                    <h3>
                      Want to Schedule An Order Manually ?{" "}
                      {store.storeName
                        ? `FOR(${store.storeName.toUpperCase()})`
                        : "CHOOSE A STORE!!"}
                    </h3>
                  )}
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label
                      className="control-label col-sm-2"
                      htmlFor="orderName"
                    >
                      Order ID:
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control"
                        id="orderName"
                        name="orderName"
                        placeholder="Enter OrderID"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="form-group">
                    <div
                      className="col-sm-offset-2 col-sm-10"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        width: "30%",
                      }}
                    >
                      {action === "cancelAnOrder" ? (
                        <div id="button-div" className="button-div">
                          {
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <button
                                  type="button"
                                  id="scheduledCancel"
                                  className="btn btn-primary btn-md"
                                  style={{ margin: "5px" }}
                                  onClick={(e) => {
                                    orderAction(
                                      e,
                                      action,
                                      store,
                                      undefined,
                                      user
                                    );
                                  }}
                                >
                                  Cancel Scheduled Order
                                </button>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  id="notScheduledCancel"
                                  className="btn btn-primary btn-md"
                                  style={{ margin: "5px" }}
                                  onClick={() => cancelNormalOrder()}
                                >
                                  Cancel PreScheduled Order
                                </button>
                              </div>
                            </div>
                          }
                        </div>
                      ) : (
                        <div
                          id="button-div"
                          className="button-div"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <button
                            type="submit"
                            className="btn btn-primary mb-2 btn-lg"
                            onClick={(e) =>
                              orderAction(e, action, store, "shiprocket", user)
                            }
                          >
                            SHIPROCKET
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary mb-2 btn-lg"
                            onClick={(e) =>
                              orderAction(e, action, store, "ecom", user)
                            }
                          >
                            ECOM EXPRESS
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary mb-2 btn-lg"
                            onClick={(e) =>
                              orderAction(e, action, store, "click", user)
                            }
                          >
                            CLICKPOST
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary mb-2 btn-lg"
                            onClick={(e) =>
                              orderAction(e, action, store, "goswift", user)
                            }
                          >
                            GoSwift
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="container" style={{ textAlign: "center" }}>
          <h2>Choose a store</h2>
        </div>
      )}
    </Fragment>
  );
};

export default OrderAction;

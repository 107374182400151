import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import Spinner from "../../../components/global/Spinner";

function CallingSettings({
  loggedInAs,
  user,
  callTypes,
  setCallTypes,
  breakdown,
  total,
}) {
  const [existingStates, setExistingStates] = useState(user.states);
  const states = [
    "Andra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telagana",
    "Tripura",
    "Uttaranchal",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadar and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadeep",
    "Pondicherry",
  ];

  const [selected, setSelected] = useState([]);

  const typeMap = {
    addressIssue: "Address Issue",
    repeatCustomer: "Repeat Customer",
    multipleItems: "Multiple Items",
    multipleQuantity: "Multiple Quantity",
    productConfirmation: "Product Confirmation",
    ndr: "NDR",
    abandonedCheckout: "Abandoned Checkouts",
  };

  const handleSelection = (value) => {
    setSelected([...selected, value]);
  };

  const handleDeSelection = (item) => {
    let temporary = selected.filter((element) => element !== item);
    setSelected(temporary);
  };

  const handleDeSelectionExisting = async (item) => {
    let data = {
      user_id: user.user_id,
      item,
    };

    await Axios.post("/api/support/removeStates", data);

    let temp = existingStates.filter((state) => state !== item);
    setExistingStates(temp);
  };

  const submitStates = async () => {
    let data = {
      user_id: user.user_id,
      selected,
    };
    setExistingStates([...existingStates, ...selected]);
    await Axios.post("/api/support/addStates", data);
    setSelected([]);
  };

  const handleChange = async (type, e) => {
    let data = {
      modifiedBy: loggedInAs.name,
      name: user.name,
      user_id: user.user_id,
      active: e.target.checked,
      value: type.value,
    };
    callTypes = callTypes.map((callType) => {
      if (callType.value === type.value) callType.active = e.target.checked;
      return callType;
    });

    setCallTypes(callTypes);
    console.log(data);
    let result = await Axios.post("/api/support/addCallType", data);
  };

  const getCallCount = async () => {};
  console.log(user);

  useEffect(() => {
    getCallCount();
    setExistingStates(user.states ? user.states : []);
  }, [user]);

  return (
    <div className="callSetting">
      <div
        style={{
          padding: "10px",
          textAlign: "center",
          background: "#079992",
          color: "white",
        }}
      >
        {user.name}
      </div>

      {existingStates && existingStates.length > 0 ? (
        <div className="chosen-states">
          {existingStates.map((item) => (
            <div
              className="itemTag"
              id={`tag_${item}`}
              style={{ textTransform: "capitalize" }}
            >
              {item}
              <div
                className="cross"
                onClick={() => handleDeSelectionExisting(item)}
              >
                x
              </div>{" "}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}

      {selected.length > 0 ? (
        <div className="chosen-states">
          {selected.map((item) => (
            <div
              className="itemTag"
              id={`tag_${item}`}
              style={{ textTransform: "capitalize" }}
            >
              {item}
              <div className="cross" onClick={() => handleDeSelection(item)}>
                x
              </div>{" "}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      <div style={{ marginBottom: "10px", display: "flex" }}>
        <select
          style={{ textAlign: "center", marginRight: "0px" }}
          type="text"
          placeholder="Please enter a state"
          className="utilityBar-search"
          aria-label="Search"
          id="searchInput"
          onChange={(e) => handleSelection(e.target.value)}
        >
          <option selected disabled>
            Please Choose A State
          </option>
          {states.map((state) => (
            <option>{state}</option>
          ))}
        </select>
        <button
          type="button"
          className="btn btn-danger"
          style={{ borderRadius: "0px", padding: "10px" }}
          onClick={() => submitStates()}
        >
          Submit
        </button>
      </div>
      <div
        style={{
          padding: "10px",
          display: "flex",
        }}
      >
        {callTypes
          ? callTypes.map((type) => (
              <div
                className="form-check"
                style={{ width: "100%", textAlign: "center" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  //   defaultChecked={type.active}
                  checked={type.active}
                  onClick={(e) => handleChange(type, e)}
                />
                <label className="form-check-label" for="defaultCheck1">
                  {type.name}
                </label>
              </div>
            ))
          : ""}
      </div>
      {breakdown ? (
        <ul
          style={{
            padding: "10px",
            textAlign: "center",
            background: "#079992",
            color: "white",
            justifyContent: "space-between",
            display: "flex",

            listStyleType: "none",
          }}
        >
          {Object.keys(breakdown).map((type) => (
            <li
              style={{ width: "fit-content" }}
            >{`${typeMap[type]}: ${breakdown[type]}`}</li>
          ))}
        </ul>
      ) : (
        ""
      )}
      <div>
        <center>{total}</center>
      </div>
    </div>
  );
}

export default CallingSettings;

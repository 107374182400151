import React, { useState } from "react";
import StatsDetails from "./StatsDetails";

function StatCard({
  category,
  selectedStores,
  dateFilter,
  perspectiveOption,
  allProducts,
  selectedMode,
  dropdownType,
}) {
  const [show, setShow] = useState("");

  return (
    <div className="stats-grid-item">
      <div className="stats-head">
        <div className="stats-head-item" style={{ width: "50%" }}>
          {category.name}
        </div>
        <div
          className="stats-head-item"
          style={{ width: "25%", background: "rgba(192, 57, 43, 0.46)" }}
        >
          {category.count}
        </div>
        <div
          className="stats-head-item"
          style={{ width: "25%", background: "#c0392b" }}
        >
          {category.percentage}
        </div>
      </div>
      <div>
        <ul className="stats-body">
          {category.types.map((type) => (
            <li
              className="stats-body-item tooltip-new"
              onMouseEnter={() => setShow(type.name)}
            >
              {type.name}: {type.count}
              {type.more ? (
                <StatsDetails
                  name={type.name}
                  show={show}
                  count={type.count}
                  request={{
                    category: category.name,
                    type: type.name,
                    selectedStores,
                    fromDate: dateFilter.fromDate,
                    toDate: dateFilter.toDate,
                    selectedMode,
                    perspectiveOption,
                    allProducts,
                    dropdownType,
                    experimentName:
                      document.getElementById("experimentName") &&
                      document.getElementById("experimentName").value,
                  }}
                />
              ) : (
                ""
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default StatCard;

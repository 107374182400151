import React, { useState, Fragment } from "react";

function Navigation({
  sideBarOpen,

  setActiveTab,
}) {
  const [active, setActive] = useState("Issues");
  const [show, setShow] = useState(false);
  const tabs = [
    {
      name: "Issues",
    },
    {
      name: "Schedule Orders",
      operation: "Schedule",
      bulk: true,
    },
    {
      name: "Cancel Orders",
      operation: "Cancel",
      bulk: true,
    },
    {
      name: "Update Products",
    },
    {
      name: "Dimensions",
    },
  ];

  return (
    <Fragment>
      <div className="operations-nav">
        {tabs.map((tab) => (
          <div
            className={`operations-nav-item ${
              active === tab.name ? "operations-nav-item-active" : ""
            }`}
            onClick={() => {
              setActiveTab(tab.name);
              setActive(tab.name);
            }}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="operations-nav-mobile">
        {!show ? (
          <div
            style={{
              padding: "20px",
              color: "white",
              fontSize: "1.1rem",
              cursor: "pointer",
            }}
            onClick={() => setShow(true)}
          >
            <i className="fas fa-bars"></i>
          </div>
        ) : (
          <div
            style={{
              padding: "20px",
              color: "white",
              fontSize: "1.1rem",
              cursor: "pointer",
            }}
            onClick={() => setShow(false)}
          >
            <i className="fas fa-times"></i>
          </div>
        )}
        {show ? (
          <div className="operations-nav-mobile-list">
            {tabs.map((tab) => (
              <div
                className={`operations-nav-item ${
                  active === tab.name ? "operations-nav-item-active" : ""
                }`}
                onClick={() => {
                  setShow(false);
                  setActiveTab(tab.name);
                  setActive(tab.name);
                }}
              >
                {tab.name}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}

export default Navigation;

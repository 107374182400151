import React, { useEffect, useState, Fragment, useContext } from "react";
import Axios from "axios";
import InventoryItem from "../res/InventoryItem";
import ComboMapping from "./ComboMapping";
import ManageRules from "./ManageRules";
import qs from "querystring";
import { crypto } from "../../utils/crytpo";
import { GlobalConsumer } from "../../Global-Context/global-context";

function InventoryDashboard() {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  document.title = "Map Inventory";
  const [products, setProducts] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [show, setShow] = useState([]);
  const [user, setUser] = useState(userInfo);

  const [active, setActive] = useState("normal");
  const [mapped, setMapped] = useState([]);
  const reset = (type) => {
    setActive(type);
    setMapped([]);
    getProducts();
  };
  const createInventory = async () => {
    let id = document.getElementById("inventory_input").value;
    let data = {
      id,
      mapped,
    };
    let result = await Axios.post("/api/IMS/mapToInventory", data);
    if (result.data.msg) {
      document.getElementById("inventory_input").value = "";
      setMapped([]);
      getProducts();
      alert(result.data.msg);
    }
  };

  const setRule = async () => {
    let original_quantity = document.getElementById("of_quantity").value;
    let mutated_quantity = document.getElementById("to_quantity").value;
    let new_name = document.getElementById("rule_name").value;

    if (original_quantity === "" || mutated_quantity === "" || new_name === "")
      return alert("Input all fields");
    if (mapped.length === 0) return alert("Select products and then continue");
    let shopifyName = mapped[0].store;
    let data = {
      original_quantity: parseInt(original_quantity),
      mutated_quantity: parseInt(mutated_quantity),
      new_name,
      mapped,
      shopifyName,
      type: "mutation",
    };
    let res = await Axios.post("/api/product/mutate", data);
    if (res.data.msg) {
      document.getElementById("of_quantity").value = "";
      document.getElementById("to_quantity").value = "";
      document.getElementById("rule_name").value = "";
      setMapped([]);
      getProducts();
      alert(res.data.msg);
    }
  };

  const flagItems = async () => {
    if (mapped.length === 0) return alert("Select products and then continue");
    let shopifyName = mapped[0].store;
    let data = {
      mapped,
      shopifyName,
      type: "flagged",
    };
    let res = await Axios.post("/api/product/flag", data);
    if (res.data.msg) {
      setMapped([]);
      getProducts();
      alert(res.data.msg);
    }
  };

  const handleChange = (e) => {
    let input = [];
    let modifiedinput = [];
    //  if (e.key === "Enter") {

    e.target.value = e.target.value.replace(
      /[`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/gi,
      ""
    );
    input = e.target.value.split(",").map((item) => item.toLowerCase().trim());

    input.forEach((item) => {
      item.split(" ").forEach((item) => {
        modifiedinput.push(item);
      });
    });

    console.log("items ", modifiedinput);

    let filtered = products.filter((product) => {
      var temp = false;
      modifiedinput.forEach((element) => {
        temp =
          new RegExp("\\b" + element + "\\b", "i").test(
            product.store.storeName.toLowerCase()
          ) ||
          new RegExp("\\b" + element + "\\b", "i").test(
            product.store.shopifyName.toLowerCase()
          ) ||
          new RegExp("\\b" + element + "\\b", "i").test(
            product.title.toLowerCase()
          ) ||
          new RegExp("\\b" + element + "\\b", "i").test(
            product.product_id.toString().toLowerCase()
          );
      });

      return temp;
    });

    console.log("filtered items are ", filtered);

    console.log("filtered length is ", filtered.length);
    setShow(filtered);

    //}
  };
  const checkIfChecked = (variant) => {
    let checked = mapped.filter(
      (product) =>
        product.variant_id === variant.variant_id &&
        product.product_id === variant.product_id
    );

    if (checked.length > 0) return true;
  };
  const getProducts = async () => {
    // const userData = crypto(user, "encrypt");
    const userData = user.user_id;

    // let result = await Axios.post(`/api/product/getAllFavourites`, {
    //   user: userData
    // });
    // let res = await Axios.post(`/api/IMS/getInventory`, { user: userData });

    let result = await Axios.get(
      `/api/product/getAllFavourites?user=${userData}`
    );
    let res = await Axios.get(`/api/IMS/getInventory?user=${userData}`);
    console.log("Fetched Favourites", result.data.products);
    setInventory(res.data.inventory);
    setProducts(result.data.products);
    setShow(result.data.products);
  };

  console.log("Mapped", mapped);

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <div>
      <Fragment>
        <div className="active-pink-3 active-pink-4 searchForm">
          <input
            type="text"
            placeholder="SEARCH..."
            aria-label="Search"
            id="searchInput"
            onChange={(e) => handleChange(e)}
            style={{
              height: "60px",
              width: "100%",
              border: "none",
              padding: "10px",
              outline: "none",
            }}
          />
        </div>
        <div className="inventoryToggle">
          <div
            className={`inventory-toggle-item ${
              active === "normal" ? "inventory-active" : ""
            }`}
            onClick={() => {
              reset("normal");
            }}
          >
            Normal
          </div>
          <div
            className={`inventory-toggle-item ${
              active === "bundle" ? "inventory-active" : ""
            }`}
            onClick={() => {
              reset("bundle");
            }}
          >
            Bundle
          </div>
          <div
            className={`inventory-toggle-item ${
              active === "combo" ? "inventory-active" : ""
            }`}
            onClick={() => {
              reset("combo");
            }}
          >
            Combo
          </div>
          <div
            className={`inventory-toggle-item ${
              active === "rules" ? "inventory-active" : ""
            }`}
            onClick={() => {
              reset("rules");
            }}
          >
            Rules
          </div>
          <div
            className={`inventory-toggle-item ${
              active === "flag" ? "inventory-active" : ""
            }`}
            onClick={() => {
              reset("flag");
            }}
          >
            Flag
          </div>
          <div
            className={`inventory-toggle-item ${
              active === "flag&rule" ? "inventory-active" : ""
            }`}
            onClick={() => {
              reset("flag&rule");
            }}
          >
            Manage Rules
          </div>
        </div>
        {active === "normal" ||
        active === "bundle" ||
        active === "rules" ||
        active === "flag" ? (
          <Fragment>
            {show
              ? active !== "rules" && active !== "flag"
                ? show.map((product) =>
                    product.variants.map((variant) =>
                      !variant.mapped ? (
                        <InventoryItem
                          variant={variant}
                          product={product}
                          key={variant.variant_id}
                          checked={checkIfChecked(variant)}
                          mapped={mapped}
                          setMapped={setMapped}
                          active={active}
                        />
                      ) : (
                        ""
                      )
                    )
                  )
                : show.map((product) =>
                    product.variants.map((variant) => (
                      <InventoryItem
                        variant={variant}
                        product={product}
                        key={variant.variant_id}
                        checked={checkIfChecked(variant)}
                        mapped={mapped}
                        setMapped={setMapped}
                        active={active}
                      />
                    ))
                  )
              : ""}
            {active === "rules" ? (
              <Fragment>
                <input
                  type="text"
                  className="form-control quantity_input_3"
                  id={`rule_name`}
                  placeholder={`NAME TO BE PRINTED ON LABEL`}
                />
                <input
                  type="text"
                  className="form-control quantity_input_1"
                  id={`of_quantity`}
                  placeholder={`ORIGINAL QUANTITY`}
                />
                <input
                  type="text"
                  className="form-control quantity_input_2"
                  id={`to_quantity`}
                  placeholder={`QUANTITY TO BE SUBSTITUTED`}
                />

                <button
                  type="button"
                  id={`inventory_creator`}
                  className="btn btn-success  btn-product-add btn-inventory-creator"
                  onClick={(e) => setRule()}
                >
                  SET RULE
                </button>
              </Fragment>
            ) : active === "flag" ? (
              <button
                type="button"
                id={`inventory_creator`}
                className="btn btn-success  btn-product-add btn-inventory-creator"
                onClick={(e) => flagItems()}
              >
                Flag
              </button>
            ) : (
              <Fragment>
                <div className="form-group inventoryForm">
                  <input
                    type="text"
                    list="inventoryList"
                    className="form-control inventory_input"
                    id={`inventory_input`}
                    placeholder={`Search Inventory Name`}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) createInventory();
                    }}
                  />

                  <datalist id="inventoryList">
                    {inventory
                      ? inventory.map((inventory, i) => {
                          return (
                            <option value={inventory.id} key={i}>
                              {inventory.name}
                            </option>
                          );
                        })
                      : ""}
                  </datalist>
                </div>
                <button
                  type="button"
                  id={`inventory_creator`}
                  className="btn btn-success  btn-product-add btn-inventory-creator"
                  onClick={(e) => createInventory()}
                >
                  MAP
                </button>{" "}
              </Fragment>
            )}
          </Fragment>
        ) : active === "combo" ? (
          <ComboMapping
            list={products}
            fetchProducts={getProducts}
            inventory={inventory}
          />
        ) : (
          <ManageRules />
        )}
      </Fragment>
    </div>
  );
}

export default InventoryDashboard;

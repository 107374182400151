/**
 * New component to get sorted label count for generating labels and manifests
 */

import React, { useEffect, useState } from "react";
import axios from "axios";
import loadingGif from "../../../OperationsDashboard/Assets/ripple-loading.gif";
import { Accordion } from "../../common/Accordion";

export default function GetLabelsV2() {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({});
  document.title = "Print Labels/Manifests V2";
  const fetchLabelCount = async () => {
    const labelCountUrl = "/api/warehouse/getLabelsV2";
    let result;
    try {
      result = await axios.get(labelCountUrl);
      setData(result.data);
      setErrorMessage("");
    } catch (error) {
      console.error(error);
      setErrorMessage("Unable to fetch label count. Something went wrong!");
    }
    console.log(result);
    setLoading(false);
  };
  const printLabels = async (
    orders,
    storeType,
    aggregator,
    store,
    courier,
    product
  ) => {
    setProcessing(true);

    // Map the aggregator to its appropriate endpoint
    let printLabelUrl;
    switch (aggregator) {
      case "ShipRocket":
      case "ShipRocket Two":
        printLabelUrl = "/api/warehouse/generateManifestsAndLabelsV6";
        break;
      case "ClickPost - BlueDart":
        printLabelUrl = "";
        break;
      case "ClickPost - ShadowFax":
        printLabelUrl = "";
        break;
      case "GoSwift":
        printLabelUrl = "";
        break;
      case "ShipYaari":
        printLabelUrl = "";
        break;
      case "ShadowFax":
        printLabelUrl =
          "/api/shadowfax/warehouse/generateBulkLabelsAndManifestsShadowfaxV2";
        break;
      default:
        printLabelUrl = "";
    }

    if (!printLabelUrl) {
      setProcessing(false);
      alert("Contact devs for integration of new aggregator!");
      return;
    }

    try {
      const response = await axios.post(printLabelUrl, {
        orders,
        storeType,
        aggregator,
        store,
        courier,
        product,
      });
      let currentData = data;
      if (storeType === `our`) {
        currentData.ourStores[aggregator][store][courier][
          product
        ].clicked = true;
        currentData.ourStores[aggregator][store][courier][product].status =
          response.data.status;
        currentData.ourStores[aggregator][store][courier][product].message =
          response.data.message;
      } else {
        currentData.clientStores[aggregator][store][courier][
          product
        ].clicked = true;
        currentData.clientStores[aggregator][store][courier][product].status =
          response.data.status;
        currentData.clientStores[aggregator][store][courier][product].message =
          response.data.message;
      }
      setData(currentData);
      setProcessing(false);
    } catch (error) {
      console.error(error);
      setProcessing(false);
      setErrorMessage(error.message);
    }
  };
  useEffect(() => {
    fetchLabelCount();
  }, []);
  return !loading ? (
    <div>
      {errorMessage ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          {errorMessage}
        </div>
      ) : (
        <div className="bm-accordion">
          <Accordion
            title={`BAZINGA MEDIA (${data.ourStores.count})`}
            content={
              data.ourStores &&
              Object.keys(data.ourStores).length > 0 &&
              Object.keys(data.ourStores).map((aggregator) => {
                if (aggregator === "count") return null;
                console.log(aggregator);
                return (
                  <Accordion
                    title={`${aggregator} (${data.ourStores[aggregator].count})`.toUpperCase()}
                    content={
                      data.ourStores[aggregator] &&
                      Object.keys(data.ourStores[aggregator]).length > 0 &&
                      Object.keys(data.ourStores[aggregator]).map((store) => {
                        if (store === "count") return null;
                        return (
                          <Accordion
                            title={`${store} (${data.ourStores[aggregator][store].count})`.toUpperCase()}
                            content={
                              data.ourStores[aggregator][store] &&
                              Object.keys(data.ourStores[aggregator][store])
                                .length > 0 &&
                              Object.keys(
                                data.ourStores[aggregator][store]
                              ).map((courier) => {
                                if (courier === "count") return null;
                                return (
                                  <Accordion
                                    title={`${courier} (${data.ourStores[aggregator][store][courier].count})`.toUpperCase()}
                                    content={
                                      data.ourStores[aggregator][store][
                                        courier
                                      ] &&
                                      Object.keys(
                                        data.ourStores[aggregator][store][
                                          courier
                                        ]
                                      ).length > 0 &&
                                      Object.keys(
                                        data.ourStores[aggregator][store][
                                          courier
                                        ]
                                      ).map((product) => {
                                        if (product === "count") return null;
                                        return (
                                          <Accordion
                                            className={`bm-accordion-special`}
                                            title={`${product} (${data.ourStores[aggregator][store][courier][product].count})`}
                                            content={
                                              <div className="d-flex">
                                                <button
                                                  className="btn btn-outline-primary btn-sm"
                                                  onClick={() =>
                                                    printLabels(
                                                      data.ourStores[
                                                        aggregator
                                                      ][store][courier][product]
                                                        .orders,
                                                      "our",
                                                      aggregator,
                                                      store,
                                                      courier,
                                                      product
                                                    )
                                                  }
                                                  disabled={processing}
                                                >
                                                  {`Print ${data.ourStores[aggregator][store][courier][product].count} labels`}
                                                </button>
                                                {data.ourStores[aggregator][
                                                  store
                                                ][courier][product].clicked ? (
                                                  <span
                                                    className={`ml-1 ${
                                                      data.ourStores[
                                                        aggregator
                                                      ][store][courier][product]
                                                        .status
                                                        ? "text-success"
                                                        : "text-danger"
                                                    }`}
                                                  >
                                                    {
                                                      data.ourStores[
                                                        aggregator
                                                      ][store][courier][product]
                                                        .message
                                                    }
                                                  </span>
                                                ) : null}
                                              </div>
                                            }
                                          />
                                        );
                                      })
                                    }
                                  />
                                );
                              })
                            }
                          />
                        );
                      })
                    }
                  />
                );
              })
            }
          />
          <Accordion
            title={`CLIENT STORES (${data.clientStores.count || 0})`}
            content={
              data.clientStores &&
              Object.keys(data.clientStores).length > 0 &&
              Object.keys(data.clientStores).map((aggregator) => {
                if (aggregator === "count") return null;
                console.log(aggregator);
                return (
                  <Accordion
                    title={`${aggregator} (${data.clientStores[aggregator].count})`.toUpperCase()}
                    content={
                      data.clientStores[aggregator] &&
                      Object.keys(data.clientStores[aggregator]).length > 0 &&
                      Object.keys(data.clientStores[aggregator]).map(
                        (store) => {
                          if (store === "count") return null;
                          return (
                            <Accordion
                              title={`${store} (${data.clientStores[aggregator][store].count})`.toUpperCase()}
                              content={
                                data.clientStores[aggregator][store] &&
                                Object.keys(
                                  data.clientStores[aggregator][store]
                                ).length > 0 &&
                                Object.keys(
                                  data.clientStores[aggregator][store]
                                ).map((courier) => {
                                  if (courier === "count") return null;
                                  return (
                                    <Accordion
                                      title={`${courier} (${data.clientStores[aggregator][store][courier].count})`.toUpperCase()}
                                      content={
                                        data.clientStores[aggregator][store][
                                          courier
                                        ] &&
                                        Object.keys(
                                          data.clientStores[aggregator][store][
                                            courier
                                          ]
                                        ).length > 0 &&
                                        Object.keys(
                                          data.clientStores[aggregator][store][
                                            courier
                                          ]
                                        ).map((product) => {
                                          if (product === "count") return null;
                                          return (
                                            <Accordion
                                              title={`${product} (${data.clientStores[aggregator][store][courier][product].count})`}
                                              content={
                                                <div>
                                                  <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    onClick={() =>
                                                      printLabels(
                                                        data.clientStores[
                                                          aggregator
                                                        ][store][courier][
                                                          product
                                                        ].orders,
                                                        "client",
                                                        aggregator,
                                                        store,
                                                        courier,
                                                        product
                                                      )
                                                    }
                                                    disabled={processing}
                                                  >
                                                    {`Print ${data.clientStores[aggregator][store][courier][product].count} labels`}
                                                  </button>
                                                  {data.clientStores[
                                                    aggregator
                                                  ][store][courier][product]
                                                    .clicked ? (
                                                    <span
                                                      className={`ml-1 ${
                                                        data.clientStores[
                                                          aggregator
                                                        ][store][courier][
                                                          product
                                                        ].status
                                                          ? "text-success"
                                                          : "text-danger"
                                                      }`}
                                                    >
                                                      {
                                                        data.clientStores[
                                                          aggregator
                                                        ][store][courier][
                                                          product
                                                        ].message
                                                      }
                                                    </span>
                                                  ) : null}
                                                </div>
                                              }
                                            />
                                          );
                                        })
                                      }
                                    />
                                  );
                                })
                              }
                            />
                          );
                        }
                      )
                    }
                  />
                );
              })
            }
          />
        </div>
      )}
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={loadingGif}
        alt="Loading..."
        style={{
          marginTop: "30vh",
        }}
      />
    </div>
  );
}

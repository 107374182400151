import React, { useEffect } from "react";
import CircularProgressBarForCourier from "../res/CircularProgressBarForCourier";

const CourierItem = ({ courier, previousCourier, handleSubmit }) => {
  return (
    <div
      id={`courier-item-${courier.courier_company_id}`}
      className="card re-assign-courier-item"
    >
      <div className="card-body courier-item-info">
        <div className="courier-item-header">
          <div className="courier-item-header-and-button">
            <h5 className="card-title">{courier.courier_name.toUpperCase()}</h5>
            {courier.courier_company_id === previousCourier ? (
              <h5 style={{ color: "red" }}>
                Order already assigned to this courier
              </h5>
            ) : null}
            <button
              className="btn btn-primary"
              onClick={() => handleSubmit(courier)}
            >
              Re-assign Courier
            </button>
          </div>
          <h6 className="card-subtitle mb-2 text-muted">
            {`Courier company ID : ${courier.courier_company_id}`}
          </h6>
        </div>
        <hr className="horizontal-rule-courier" />
        <div className="courier-item-performance">
          <div>
            <CircularProgressBarForCourier
              performance={courier.rto_performance}
              sqSize={50}
              strokeWidth={5}
            />
            <p className="card-text">RTO performance</p>
          </div>
          <div>
            <CircularProgressBarForCourier
              performance={courier.pickup_performance}
              sqSize={50}
              strokeWidth={5}
            />
            <p className="card-text">Pickup performance</p>
          </div>
          <div>
            <CircularProgressBarForCourier
              performance={courier.delivery_performance}
              sqSize={50}
              strokeWidth={5}
            />
            <p className="card-text">Delivery performance</p>
          </div>
          <div>
            <CircularProgressBarForCourier
              performance={courier.tracking_performance}
              sqSize={50}
              strokeWidth={5}
            />
            <p className="card-text">Tracking performance</p>
          </div>
          <div>
            <CircularProgressBarForCourier
              performance={courier.rating}
              sqSize={50}
              strokeWidth={5}
            />
            <p className="card-text">Overall performance</p>
          </div>
        </div>
      </div>
      <div className="card-body courier-item-rate">
        <p className="card-text">
          Forward rate : {`\u20B9 ` + courier.freight_charge}
        </p>
        <p className="card-text">
          RTO rate : {`\u20B9 ` + courier.rto_charges}
        </p>
        <p className="card-text">Shipping rate : {`\u20B9 ` + courier.rate}</p>
        <p>Estimated DOD : {courier.etd}</p>
      </div>
    </div>
  );
};

export default CourierItem;

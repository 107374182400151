import React, { useState } from "react";

const SettingOption = ({
  option,
  handleValueChange,
  handleNameChange,
  status,
  id
}) => {
  const [mode, setMode] = useState("");

  const handleMode = e => {
    console.log(mode);
    if (mode === "") {
      // DEFAULT MODE
      setMode("edit");
    } else {
      // EDIT MODE
      let name = document.getElementById("nameForm").value;
      name = name ? name : option;
      handleNameChange(e, name);
      setMode("");
    }
  };

  return (
    <div className="settingOption">
      <div className="settingTool">
        <div>
          <button
            id={id}
            type="button"
            className="btn btn-default"
            aria-label="Left Align"
            style={{ border: "none" }}
            onClick={e => handleMode(e)}
          >
            {mode ? (
              <i id={id} className="far fa-check-circle" />
            ) : (
              <i id={id} className="fas fa-pencil-alt" />
            )}
          </button>
        </div>
        <div className="settingTitle">
          {mode ? (
            <input
              id="nameForm"
              type="text"
              placeholder={option}
              style={{ marginLeft: "10px" }}
            />
          ) : (
            <p>
              {option} ({id})
            </p>
          )}
        </div>
      </div>
      <label className="custom-toggle">
        <input
          id={id}
          type="checkbox"
          checked={status}
          onChange={e => handleValueChange(e)}
        />
        <span className="custom-toggle-slider rounded-circle" />
      </label>
    </div>
  );
};

export default SettingOption;

import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import SelectedItem from "../res/SelectedItem";

export default function OrderFlagger() {
  const defaultStates = [
    "Andra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telagana",
    "Tripura",
    "Uttaranchal",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadar and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadeep",
    "Pondicherry",
  ];

  const [inventory, setInventory] = useState([]);
  const [statesArray, setStatesArray] = useState(defaultStates);
  const [products, setProducts] = useState([]);
  const [states, setStates] = useState([]);

  const fetchProducts = async () => {
    let result = await Axios.get(`/api/IMS/getInventory`);
    console.log(result.data);
    setInventory(result.data.inventory);
  };

  const fetchData = async () => {
    let result = await Axios.get(`/api/orders/flagConfig`);
    setProducts(result.data.products || []);
    setStates(result.data.states || []);
  };

  const updateData = async () => {
    await Axios.post(`/api/orders/flagConfig`, { states, products });
  };

  const addItem = async (item, category) => {
    if (!item) return;
    if (category === "state") {
      setStates((current) => [...current, item]);
      setStatesArray((current) => current.filter((state) => state !== item));
    } else {
      item = JSON.parse(item);
      const products = item.products;
      setProducts((current) => [...current, ...products]);
      setInventory((current) =>
        current.filter((product) => product.name !== item.name)
      );
    }
  };

  const handleDelete = async (item, category) => {
    if (category === "state") {
      setStates((current) => current.filter((state) => state !== item));
      setStatesArray((current) => [...current, item]);
    } else {
      setProducts((current) => {
        return current.filter(
          (product) => product.variant_id !== item.variant_id
        );
      });
      setInventory((current) => [...current, ...products]);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProducts();
  }, []);

  useEffect(() => {
    updateData();
  }, [states.length, products.length]);

  return (
    <Fragment>
      <div className="flag-module">
        <div>
          <div>States To Be Flagged</div>
          <div
            style={{ display: "flex", flexWrap: "wrap", padding: "10px 0px" }}
          >
            {states.map((state) => (
              <SelectedItem item={state} handleClick={handleDelete} />
            ))}
          </div>{" "}
        </div>
        <select onChange={(e) => addItem(e.target.value, "state")}>
          <option value="">Choose a state to be flagged</option>
          {statesArray.map((state) => (
            <option>{state}</option>
          ))}
        </select>
      </div>
      <div className="flag-module">
        <div>
          {" "}
          <div>Products To Be Flagged</div>
          <div
            style={{ display: "flex", flexWrap: "wrap", padding: "10px 0px" }}
          >
            {products.map((product) => (
              <SelectedItem
                item={product}
                handleClick={handleDelete}
                object={true}
              />
            ))}
          </div>{" "}
        </div>
        <select onChange={(e) => addItem(e.target.value, "product")}>
          <option value="">Choose a product to be flagged</option>

          {inventory.length > 0
            ? inventory.map((item) => (
                <option value={JSON.stringify(item)}>{item.name}</option>
              ))
            : ""}
        </select>
      </div>
    </Fragment>
  );
}

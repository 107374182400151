export const productDimension = (e, formId, store, fetchProducts) => {
  console.log(`loaded productDimension file`);

  e.preventDefault();

  console.log("formId = ", formId);

  let length = document.getElementById(`${formId}_length`).value;
  let width = document.getElementById(`${formId}_width`).value;
  let height = document.getElementById(`${formId}_height`).value;
  let actualWeight = document.getElementById(`${formId}_actualWeight`).value;

  const url = `/api/operations/product/${parseInt(formId.split("_")[1])}`;

  const data = {
    name: "originalDimensions",
    length,
    width,
    height,
    actualWeight,
    store
  };

  console.log("data = ", data);
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      response.json().then(data => {
        console.log(data);
        document.getElementById(`${formId}_msg`).innerHTML =
          '<span class="badge badge-success">Successfully Added/Updated</span><br />';
        setTimeout(() => {
          document.getElementById(`${formId}_msg`).innerHTML = "";
        }, 3000);
        fetchProducts();
      });
    })
    .catch(err => console.log(err.message));
};

export const fakeProductDimension = (e, formId, store, fetchProducts) => {
  console.log(`loaded fake productDimension file`);

  e.preventDefault();

  console.log("formId = ", formId);

  let length = document.getElementById(`${formId}_fakeLength`).value;
  let width = document.getElementById(`${formId}_fakeWidth`).value;
  let height = document.getElementById(`${formId}_fakeHeight`).value;
  let actualWeight = document.getElementById(`${formId}_fakeActualWeight`)
    .value;

  const url = `/api/operations/product/${parseInt(formId.split("_")[2])}`;

  const data = {
    name: "fakeDimensions",
    length,
    width,
    height,
    actualWeight,
    store
  };

  console.log("data = ", data);
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      response.json().then(data => {
        console.log(data);
        document.getElementById(`${formId}_msg`).innerHTML =
          '<span class="badge badge-success">Successfully Added/Updated</span><br />';
        setTimeout(() => {
          document.getElementById(`${formId}_msg`).innerHTML = "";
        }, 3000);
        fetchProducts();
      });
    })
    .catch(err => console.log(err.message));
};

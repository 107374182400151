import React from "react";

const Tags = ({ item, permuteOrders, setFiltered, filtered }) => {
    const handleClick = item => {
        item[0] = parseInt(item[0]);
        let index = global.selected.indexOf(item);
        if (setFiltered) {
            setFiltered([...filtered, item]);
        }
        if (index > -1) {
            global.selected.splice(index, 1);
        }
        if (permuteOrders) permuteOrders();
    };
    return (
        <div className="itemTag" id={`tag_${item}`}>
            {item[1]}
            <div className="cross" onClick={() => handleClick(item)}>
                x
      </div>{" "}
        </div>
    );
};

export default Tags;

export const updateOrderDetails = (e, store) => {
  console.log(`loaded updateOrderDetails file`, store);

  const orderName = document.getElementById("orderName").value;
  const account = document.getElementById("account").value;
  const status = document.querySelector("#status");

  e.preventDefault();

  console.log(orderName, " - ", status, " - ", account);

  if (account.toLowerCase() === "select")
    alert("please select the shiprocket account");

  const url = `/api/operations/updateOrderDetails/${orderName}/${JSON.stringify(
    store
  )}?account=${account}`;

  console.log(url);

  let fetchRequest = fetch(url);
  console.log("Called Update Order Details Endpoint");

  fetchRequest
    .then((response) => {
      response.json().then((data) => {
        status.textContent = data.status;
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

import React, { Fragment, useState, useEffect, useContext } from "react";
import Axios from "axios";
import { GlobalLabelsAndManifestsList } from "../res/LabelsAndManifestsList";
import LabelAndManifestsListCP from "../res/LabelsAndManifestsListCP";
import { LabelsAndManifestsListShipyaari } from "../res/LabelsAndManifestsList";
import DatePicker from "../../global/DateRangeWithTime";
import useAlert from "../../global/Alert";
import { GlobalConsumer } from "../../../Global-Context/global-context";

import moment from "moment";
// import SpeechToText from "speech-to-text";

const GlobalLabelsAndManifests = () => {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();

  const [user] = useState(userInfo);
  const [aggregators, setAggregators] = useState([]);

  const [fromDate, setFromDate] = useState(new Date("2019"));
  const [toDate, setToDate] = useState(new Date());
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [clearFilter, setClearFilter] = useState(false);
  const [command, setCommand] = useState("");
  const [params] = useState(window.location.search);
  const [
    lengthOfGlobalManifestAndLabel,
    setLengthOfGlobalManifestAndLabel,
  ] = useState(-1);
  const [
    filterTotalWithFromAndToDate,
    setFilterTotalWithFromAndToDate,
  ] = useState({
    total: 0,
    fromDate: "Not Specified",
    toDate: "Not Specified",
  });
  const [label, setLabel] = useState(null);
  let [manifest, setManifest] = useState(null);
  const [AlertBox, setNotification] = useAlert();

  document.title = "Global Labels And Manifests";

  const fetchGlobalLabelsAndManifests = (from, to) => {
    setFromDate(moment(fromDate).format());
    setToDate(moment(toDate).format());

    let data = {
      params: {
        fromDate: from || moment(fromDate).format(),
        toDate: to || moment(toDate).format(),
        limit,
        offset,
      },
    };

    Axios.get(`/api/warehouse/globalLabelsAndManifests`, data)
      .then((result) => {
        setGlobalLabelsAndManifests(result.data.labelsAndManifestsList);
        setLengthOfGlobalManifestAndLabel(
          result.data.labelsAndManifestsList.length
        );
        setFilterTotalWithFromAndToDate(
          result.data.filterTotalWithFromAndToDate
        );
        // console.log(result.data.labelsAndManifestsList);
      })
      .catch((error) => alert("failed to fetch orders"));
  };

  const handleDisablingOfPaginationButtons = () => {
    if (offset === 0) {
      document.getElementById("previous-page").disabled = true;
    } else {
      document.getElementById("previous-page").disabled = false;
    }
    if (lengthOfGlobalManifestAndLabel < limit) {
      document.getElementById("next-page").disabled = true;
    } else {
      document.getElementById("next-page").disabled = false;
    }
  };

  const autoSearchDate = () => {
    let date = params.slice(params.indexOf("date=") + 5);
    date = new Date(decodeURIComponent(date));
    fetchGlobalLabelsAndManifests(
      new Date(moment(date).startOf("minute")),
      new Date(moment(date).endOf("minute"))
    );
  };

  const uploadLabel = async () => {
    let manifestPrompt = false;
    if (!label) return alert("Please upload a label");
    if (!manifest)
      manifestPrompt =
        prompt(
          `Are you sure you want to proceed without uploading manifest? (yes/no)`
        ) || false;

    if (manifestPrompt === "yes") {
      setNotification("failure", "Copying label to manifest");
      manifest = label;
    } else {
      return setNotification("success", "Okay, please try uploading again");
    }

    const data = new FormData();
    data.append("label", label);
    data.append("manifest", manifest);
    data.append("user", user.name);

    const extensionL = label.name.split(".")[1];
    const extensionM = manifest.name.split(".")[1];

    if (extensionL !== "pdf" || extensionM !== "pdf")
      return alert("Please upload a PDF");

    let input = prompt(`Are you sure you want to upload this? (yes/no)`) || "";

    if (input.toLowerCase() === "yes") {
      const agg = document.getElementById("aggregator-selector").value || "";
      if (agg) data.append("aggregator", agg);

      const result = await Axios.post("/api/upload/labelManifest", data);
      if (result.data.message.includes("Inserted succesfully")) {
        alert("Your label has been uploaded");
        fetchGlobalLabelsAndManifests();
      } else {
        alert("Unsuccessful -", result.data.message);
      }
    } else setNotification("failure", "Okay, try again when you're ready");
  };

  useEffect(() => {
    fetchGlobalLabelsAndManifests();
  }, [offset, limit, clearFilter]);
  const [globalLabelsAndManifests, setGlobalLabelsAndManifests] = useState([]);

  useEffect(() => {
    handleDisablingOfPaginationButtons();
  }, [limit, offset, lengthOfGlobalManifestAndLabel]);

  useEffect(() => {
    if (params.includes("date")) autoSearchDate();
  }, [params]);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const fetchAggregators = async () => {
    let { data } = await Axios.get("/api/control/fetchAggregators");
    setAggregators(data.aggregators);
  };

  useEffect(() => {
    if (command.includes("next")) {
      setOffset(globalLabelsAndManifests.length >= limit ? offset + limit : 0);
      setCommand("");
    } else if (command.includes("previous")) {
      setOffset(offset - limit > 0 ? offset - limit : 0);
      setCommand("");
    } else if (command.includes("take me to the tracking board")) {
      window.location.href = "http://localhost:3000/operations/orderboard";
    }
  }, [command, offset, limit]);

  useEffect(() => {
    fetchAggregators();
  }, []);

  const clearFilters = () => {
    setFromDate(new Date("2019"));
    setToDate(new Date());
    setOffset(0);
    setLimit(20);
    setClearFilter(!clearFilter);

    if (document.getElementById("datePickerFrom"))
      document.getElementById("datePickerFrom").value = "";
    if (document.getElementById("datePickerTo"))
      document.getElementById("datePickerTo").value = "";
    if (document.getElementById("datePickerRange"))
      document.getElementById("datePickerRange").value = 0;
  };

  return (
    <Fragment>
      <AlertBox />
      <div className="container-fluid">
        <div className="row">
          <div
            className="shadow-lg p-3 mb-5 bg-white rounded"
            style={{ width: "100%", margin: "30px" }}
          >
            <div className="card">
              <div className="card-header">
                <div>
                  <h4>List of Labels and Manifests Generated By Application</h4>
                </div>
                {user.role !== "warehouse" ? (
                  <div>
                    <input
                      type="file"
                      name="label"
                      onChange={(e) => setLabel(e.target.files[0])}
                    />
                    (Label)
                    <br />
                    {label ? (
                      <Fragment>
                        <br />
                        <input
                          type="file"
                          name="manifest"
                          onChange={(e) => setManifest(e.target.files[0])}
                        />
                        (Manifest)
                        <br />
                      </Fragment>
                    ) : (
                      ""
                    )}
                    <br />
                    {label ? (
                      <select
                        className="form-control form-control-sm"
                        style={{
                          height: "100%",
                          fontSize: "0.9rem",
                          borderRadius: 0,
                          width: "100%",
                        }}
                        id="aggregator-selector"
                      >
                        <option key="0" defaultValue disabled>
                          Aggregator
                        </option>
                        {aggregators.map((aggregator, index) => (
                          <option key={index + 1} value={aggregator.name}>
                            {aggregator.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      ""
                    )}
                    <br />
                    {label ? (
                      <button
                        onClick={() => uploadLabel()}
                        style={{
                          background: "#17A2B8",
                          color: "white",
                          padding: "10px",
                          width: "250px",
                        }}
                      >
                        Upload {label ? "Label" : ""}{" "}
                        {manifest ? "And Manifest" : ""}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",

                height: "8vh",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div
                className="stats-filter"
                style={{
                  flexGrow: 1,
                }}
              >
                <DatePicker setFromDate={setFromDate} setToDate={setToDate} />
              </div>
              <div className="form-check" style={{ marginRight: "10px" }}>
                <button
                  id="dateSubmit"
                  className="btn btn-info"
                  style={{
                    borderRadius: 0,
                    marginLeft: "10px",
                    height: "100%",
                    fontSize: "0.9rem",
                    borderRadius: 0,
                    width: "100%",
                  }}
                  onClick={() => fetchGlobalLabelsAndManifests()}
                >
                  Submit Date
                </button>
              </div>
              <div>
                <button
                  id="clearFilters"
                  className="btn btn-info"
                  style={{
                    borderRadius: 0,
                    marginLeft: "10px",
                    height: "100%",
                    fontSize: "0.9rem",
                    borderRadius: 0,
                    width: "100%",
                  }}
                  onClick={() => clearFilters()}
                >
                  Clear Filters
                </button>
              </div>
              <div className="form-check" style={{}}>
                <select
                  className="form-control form-control-sm"
                  style={{
                    height: "100%",
                    fontSize: "0.9rem",
                    borderRadius: 0,
                    width: "100%",
                    paddingLeft: 30,
                  }}
                  onChange={(event) => handleLimitChange(event)}
                  value={limit}
                >
                  <option defaultValue>20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                className="stats-total"
                style={{
                  margin: "0px",
                  background: "white",
                  color: "black",
                }}
              >
                <center>
                  <strong>{filterTotalWithFromAndToDate.total}</strong> Labels
                  from <strong>{filterTotalWithFromAndToDate.fromDate}</strong>{" "}
                  to <strong>{filterTotalWithFromAndToDate.toDate}</strong>
                </center>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Date</th>
                      <th scope="col">Manifests And Labels</th>
                    </tr>
                  </thead>
                  <tbody>
                    {globalLabelsAndManifests.map(
                      (labelAndManifestDetails, index) =>
                        labelAndManifestDetails.clickpost ||
                        labelAndManifestDetails.goswift ||
                        labelAndManifestDetails.shipyaari ||
                        labelAndManifestDetails.shadowfax ? (
                          labelAndManifestDetails.shipyaari ||
                          labelAndManifestDetails.shadowfax ? (
                            <LabelsAndManifestsListShipyaari
                              key={index}
                              labelAndManifestDetails={labelAndManifestDetails}
                              index={offset + index}
                            />
                          ) : (
                            <LabelAndManifestsListCP
                              key={index}
                              labelAndManifestDetails={labelAndManifestDetails}
                              index={offset + index}
                            />
                          )
                        ) : (
                          <GlobalLabelsAndManifestsList
                            key={index}
                            labelAndManifestDetails={labelAndManifestDetails}
                            index={offset + index}
                          />
                        )
                    )}
                  </tbody>
                </table>
              </div>

              <div className="pagination">
                <button
                  id="previous-page"
                  className="pagination-button"
                  onClick={() =>
                    setOffset(offset - limit > 0 ? offset - limit : 0)
                  }
                >
                  {offset !== 0 ? (
                    <i className="fas fa-arrow-circle-left"></i>
                  ) : (
                    ""
                  )}
                </button>
                <button
                  id="next-page"
                  className="pagination-button"
                  onClick={() =>
                    setOffset(
                      globalLabelsAndManifests.length >= limit
                        ? offset + limit
                        : 0
                    )
                  }
                >
                  {lengthOfGlobalManifestAndLabel >= limit ? (
                    <i className="fas fa-arrow-circle-right"></i>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default GlobalLabelsAndManifests;

import React from "react";

const TagBar = ({ item, filters }) => {
  const key = Object.keys(filters).find((key) => filters[key] === item);

  const keyMapping = {
    repeatCustomer: "Repeat Customer",
    abandonedCheckout: "Abandoned Checkout",
    multipleItems: "Multiple Items",
    multipleQuantity: "Multiple Quantity",
    callCustomer: "Address Issue",
    ndr: "NDR",
  };

  item = key === "call" ? keyMapping[item] : item;
  item =
    key === "from" || key === "to" ? new Date(item).toLocaleDateString() : item;

  return (
    <div
      className="itemTag"
      id={`tag_${item}`}
      style={{ textTransform: "capitalize" }}
    >
      {item}
    </div>
  );
};

export default TagBar;

import React, { useState, Fragment, useEffect } from "react";
import Product from "./Product";
import Axios from "axios";
import AddressModal from "./AddressModal";
import useOrderModal from "../../components/global/OrderDetails";
import { nameHash } from "../../utils/dimension";

function BoardOrderItem({
  order,
  selected,
  setSelected,
  selectedR,
  setSelectedR,
  removeOrders,
  fetchOrders,
}) {
  const [show, setShow] = useState(
    !order.show ? (order.show === "" ? true : false) : true
  );
  const [productDimensions, setProductDimensions] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [OrderModal, setShowOrder] = useOrderModal();

  const fetchDimension = async () => {
    if (order.line_items.length === 1) {
      if (order.line_items[0]["quantity"] === 1) {
        let product = order.line_items[0];
        console.log("Satisfies", product.variant_id, order.store.storeAnchor);
        let result = await Axios.get(
          `/api/product/getProductDimensions?variant_id=${product.variant_id}&storeAnchor=${order.store.storeAnchor}`
        );

        setProductDimensions(result.data);
      } else {
        console.log("No Dimensions");
      }
    } else {
      console.log("No Dimensions");
    }
  };

  const addSelection = async () => {
    if (show) {
      setSelected([...selected, order.name]);
      // Hit product dimensions

      if (order.line_items.length === 1) {
        if (order.line_items[0]["quantity"] === 1) {
          let product = order.line_items[0];
          console.log("Satisfies", product.variant_id, order.store.storeAnchor);
          let result = await Axios.get(
            `/api/product/getProductDimensions?variant_id=${product.variant_id}&storeAnchor=${order.store.storeAnchor}`
          );

          setProductDimensions(result.data);
        } else {
          console.log("No Dimensions");
        }
      } else {
        // chec if dimension exists
        const dimensionsName = nameHash(order.line_items);
        console.log(dimensionsName);
        try {
          const {
            status,
            data: { result },
          } = await Axios.get(`/api/dimensions/${dimensionsName}`);

          if (status !== 200) {
            console.log("No Dimensions");
            return;
          }

          const {
            length,
            breadth: width,
            height,
            weight: actualWeight,
          } = result;
          setProductDimensions({
            length,
            width,
            height,
            actualWeight,
          });
        } catch (err) {
          console.log(err.message);
        }
      }
    } else {
      selected = selected.filter((item) => item !== order.name);
      setSelected(selected);
    }
    setShow(!show);
  };

  const addRemoval = () => {
    setShowReason(!showReason);

    if (!showReason) {
      setSelectedR([...selectedR, order.name]);
    } else {
      selectedR = selectedR.filter((item) => item !== order.name);
      setSelectedR(selectedR);
    }
  };

  const editAddress = async () => {
    // POST

    const inputs = [
      { title: "First Name", property: "first_name" },
      { title: "Last Name", property: "last_name" },
      { title: "Email", property: "email" },
      { title: "Company", property: "company" },
      { title: "Address 1", property: "address1" },
      { title: "Address 2", property: "address2" },
      { title: "Phone", property: "phone" },
      { title: "City", property: "city" },
      { title: "Zip", property: "zip" },
    ];
    let shipping_address = {};
    let billing_address = {};

    inputs.map((item) => {
      // CONSTRUCT SHIPPING ADDRESS
      if (document.getElementById(`${order.name}-shipping-${item.property}`)) {
        shipping_address[item.property] = document.getElementById(
          `${order.name}-shipping-${item.property}`
        ).value
          ? document.getElementById(`${order.name}-shipping-${item.property}`)
              .value
          : "";

        // With Checks

        // if (
        //   document.getElementById(`${order.name}-shipping-${item.property}`)
        //     .value
        // )
        //   shipping_address[item.property] = document.getElementById(
        //     `${order.name}-shipping-${item.property}`
        //   ).value;
        // else return alert(`Please input shipping ${item.title}`);
      } else {
        return alert(`Something wrong with modal`);
      }
      // CONSTRUCT BILLING ADDRESS
      if (document.getElementById(`${order.name}-billing-${item.property}`)) {
        billing_address[item.property] = document.getElementById(
          `${order.name}-billing-${item.property}`
        ).value
          ? document.getElementById(`${order.name}-billing-${item.property}`)
              .value
          : "";

        // With Checks

        // if (
        //   document.getElementById(`${order.name}-billing-${item.property}`)
        //     .value
        // )
        //   billing_address[item.property] = document.getElementById(
        //     `${order.name}-billing-${item.property}`
        //   ).value;
        // else return alert(`Please input billing ${item.title}`);
      } else {
        return alert(`Something wrong with modal`);
      }
    });

    // new check for phone number
    const shippingPhone = document
      .getElementById(`${order.name}-shipping-phone`)
      .value.trim();
    const billingPhone = document
      .getElementById(`${order.name}-billing-phone`)
      .value.trim();
    console.log(shippingPhone);
    const phoneRegex = /^\d{10}$/;
    const incorrectPhone = !(
      phoneRegex.test(shippingPhone) && phoneRegex.test(billingPhone)
    );
    if (incorrectPhone) {
      return alert(
        `Shipping/Billing phone is incorrect! Enter 10 digits only!`
      );
    }
    shipping_address.phone = shippingPhone;
    billing_address.phone = billingPhone;

    if (document.getElementById(`${order.name}-shipping-state`)) {
      shipping_address["province"] = document.getElementById(
        `${order.name}-shipping-state`
      ).value
        ? document.getElementById(`${order.name}-shipping-state`).value
        : "";
    } else {
      return alert(`Something wrong with modal`);
    }
    if (document.getElementById(`${order.name}-shipping-country`)) {
      shipping_address["country"] = document.getElementById(
        `${order.name}-shipping-country`
      ).value
        ? document.getElementById(`${order.name}-shipping-country`).value
        : "";
    } else {
      return alert(`Something wrong with modal`);
    }
    if (document.getElementById(`${order.name}-billing-state`)) {
      billing_address["province"] = document.getElementById(
        `${order.name}-billing-state`
      ).value
        ? document.getElementById(`${order.name}-billing-state`).value
        : "";
    } else {
      return alert(`Something wrong with modal`);
    }
    if (document.getElementById(`${order.name}-billing-country`)) {
      billing_address["country"] = document.getElementById(
        `${order.name}-billing-country`
      ).value
        ? document.getElementById(`${order.name}-billing-country`).value
        : "";
    } else {
      return alert(`Something wrong with modal`);
    }
    let result = await Axios.post(`/api/operations/addressV2`, {
      shipping_address,
      billing_address,
      store: order.store,
      orderName: order.name,
    });
    console.log("result", result);
    setShowAddress(false);
    console.log(shipping_address, billing_address);
    fetchOrders(order.store);
  };

  useEffect(() => {
    setShow(!order.show);
    if (order.show) fetchDimension();
  }, [order.show]);

  return (
    <Fragment>
      <div>
        <div className="ops-order-item-date">
          Shopify, {new Date(order.created_at).toDateString()}
        </div>
        {order.sentToOpsOn ? (
          <div
            className="ops-order-item-date"
            style={{ background: "#e9ecef", color: "#2980b9" }}
          >
            Operations Dashboard, {new Date(order.sentToOpsOn).toDateString()}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        id={`order-${order.name}`}
        className="ops-order-item"
        style={show ? {} : { marginBottom: "0px" }}
      >
        <div className="ops-order-item-head" style={{ position: "relative" }}>
          <div
            onDoubleClick={() => {
              if (!showReason) addSelection();
              else alert("You've added it for removal, please uncheck");
            }}
            className="ops-order-item-head-title"
            style={!show ? { background: "#1ABC9C" } : {}}
          >
            {order.name}
          </div>
          <OrderModal
            order={order}
            position={{ top: "2px", left: "4px" }}
            cursor={{ color: "white" }}
          />
          <div>
            <div className="ops-order-item-head-issues">
              <div className="ops-issue">
                {order.financial_status === "paid" ? "Prepaid" : "COD"}
              </div>
              {order.productIdNotFound ? (
                <div className="ops-issue">PN</div>
              ) : (
                ""
              )}
              {order.multipleItems ? <div className="ops-issue">MI</div> : ""}
              {order.multipleQuantity ? (
                <div className="ops-issue">MQ</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="" style={{ marginLeft: "auto", display: "flex" }}>
            <div
              className="ops-footer-tools"
              onClick={() => setShowAddress(!showAddress)}
            >
              <i className="fas fa-home"></i>
            </div>
            <div
              className="ops-footer-tools"
              onClick={() => {
                if (show) {
                  addRemoval();
                } else alert("Added for scheduling, please uncheck");
              }}
            >
              <i className="far fa-times-circle"></i>
            </div>
          </div>
        </div>
        {show ? (
          <div className="ops-order-item-body">
            <div className="remarks-section">
              <div>
                {typeof order.shipmentFailureReason === "string" ? (
                  <div className="ops-remark-failure">
                    <div
                      style={{
                        padding: "15px",
                        background: "#3498db",
                        color: "white",
                      }}
                    >
                      Shipment Failure Reason
                    </div>
                    <div style={{ padding: "15px" }}>
                      {order.shipmentFailureReason.includes(
                        "Please try mailing the customer "
                      ) ? (
                        <p>
                          {order.shipmentFailureReason}{" "}
                          <a
                            href={`mailto:${order.shipmentFailureReason
                              .split("(")[1]
                              .replace(")", "")}`}
                          >
                            (Click Here To Mail)
                          </a>
                        </p>
                      ) : (
                        order.shipmentFailureReason
                      )}
                    </div>
                  </div>
                ) : typeof order.shipmentFailureReason === "object" &&
                  order.shipmentFailureReason ? (
                  <ul
                    style={{
                      listStyleType: "none",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {Object.keys(order.shipmentFailureReason).map(
                      (oneKey, i) => {
                        return (
                          <li className="ops-remark" key={i}>
                            {order.shipmentFailureReason[oneKey]}
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  ""
                )}
              </div>
              {order.remarks ? (
                <div className="ops-remark"> {order.remarks}</div>
              ) : (
                ""
              )}
            </div>
            <div>
              {order.productIdNotFound ||
              order.multipleItems ||
              order.multipleQuantity ? (
                <div className="ops-order-item-head-issues-mobile">
                  {order.productIdNotFound ? (
                    <div className="ops-issue">PN</div>
                  ) : (
                    ""
                  )}
                  {order.multipleItems ? (
                    <div className="ops-issue">MI</div>
                  ) : (
                    ""
                  )}
                  {order.multipleQuantity ? (
                    <div className="ops-issue">MQ</div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="ops-products-div">
              {order.line_items.map((product) => (
                <Product
                  key={product.variant_id}
                  product={product}
                  order={order}
                  store={order.store}
                  fetchOrders={fetchOrders}
                />
              ))}
            </div>
            {showReason ? (
              <div className="ops-footer">
                <input
                  id={`${order.name}-reason`}
                  type="text"
                  placeholder="Enter a reason"
                  required
                  style={{ padding: "15px", width: "100%" }}
                />
                <button
                  className="ops-reason-submit"
                  onClick={() => removeOrders(order.name)}
                >
                  <i className="far fa-check-circle"></i>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {!show ? (
        <div className="ops-order-item-hidden">
          {/* {order.line_items.map((product, index) => ( */}
          <div className="ops-product-hidden">
            <div className="product-dimensions-head text-left">
              {order.line_items.map((product, index) => (
                <p key={index + product.title}>
                  {`${product.title} X ${product.quantity}`}
                </p>
              ))}
            </div>
            {/* {index === 0 ? ( */}
            <div className="product-dimensions-list">
              <div className="product-dimensions">
                {productDimensions ? (
                  productDimensions.length ? (
                    <input
                      type="text"
                      id={`${order.name}-length`}
                      value={productDimensions.length}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      id={`${order.name}-length`}
                      placeholder="L"
                    />
                  )
                ) : (
                  <input
                    type="text"
                    id={`${order.name}-length`}
                    placeholder="L"
                  />
                )}
              </div>
              <div className="product-dimensions">
                {productDimensions ? (
                  productDimensions.width ? (
                    <input
                      type="text"
                      id={`${order.name}-width`}
                      value={productDimensions.width}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      id={`${order.name}-width`}
                      placeholder="W"
                    />
                  )
                ) : (
                  <input
                    type="text"
                    id={`${order.name}-width`}
                    placeholder="W"
                  />
                )}
              </div>
              <div className="product-dimensions">
                {productDimensions ? (
                  productDimensions.height ? (
                    <input
                      type="text"
                      id={`${order.name}-height`}
                      value={productDimensions.height}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      id={`${order.name}-height`}
                      placeholder="H"
                    />
                  )
                ) : (
                  <input
                    type="text"
                    id={`${order.name}-height`}
                    placeholder="H"
                  />
                )}
              </div>
              <div className="product-dimensions">
                {productDimensions ? (
                  productDimensions.actualWeight ? (
                    <input
                      type="text"
                      id={`${order.name}-actual`}
                      value={productDimensions.actualWeight}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      id={`${order.name}-actual`}
                      placeholder="A"
                    />
                  )
                ) : (
                  <input
                    type="text"
                    id={`${order.name}-actual`}
                    placeholder="A"
                  />
                )}
              </div>
            </div>
            {/* ) : (
                ""
              )} */}
          </div>
          {/* ))} */}
        </div>
      ) : (
        ""
      )}
      {showAddress ? (
        <AddressModal
          order={order}
          editAddress={editAddress}
          setShowAddress={setShowAddress}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default BoardOrderItem;

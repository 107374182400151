import React from "react";

const ScannedCourierCompanies = ({ couriers, scannedCount }) => {
  return (
    <div className="successful-scan-container">
      <h4>SUCCESSFUL SCANS - {scannedCount}</h4>
      <div className="scanned-courier-companies-container">
        {Object.keys(couriers).map((courier) => {
          return (
            <div className="courier-company">
              <h5 className="courier-company-header">
                {courier + " - " + Object.values(couriers[courier]).length}
              </h5>
              {Object.values(couriers[courier]).map((awb) => (
                <div className="awb-item">{awb}</div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScannedCourierCompanies;

import React, { useState } from "react";

const CustomerDataForm = ({ customer, setCustomer }) => {
  const handleChange = (e) => {
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  return (
    <div className="customer-form-draft-order">
      <div className="reusable-form-grid">
        <label htmlFor="">
          First Name<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="first_name"
          className="form-control form-control-sm"
          placeHolder="Customer first name"
          value={customer.first_name}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          Last Name<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="last_name"
          className="form-control form-control-sm"
          placeHolder="Customer last name"
          value={customer.last_name}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          Email<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="email"
          className="form-control form-control-sm"
          placeHolder="Email"
          value={customer.email}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          Phone<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="phone"
          className="form-control form-control-sm"
          placeHolder="Phone"
          value={customer.phone}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          Address Line 1<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="address1"
          className="form-control form-control-sm"
          placeHolder="Address"
          value={customer.address1}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          Address Line 2<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="address2"
          className="form-control form-control-sm"
          placeHolder="Address"
          value={customer.address2}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          City<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="city"
          className="form-control form-control-sm"
          placeHolder="City"
          value={customer.city}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">Company</label>
        <input
          name="company"
          className="form-control form-control-sm"
          placeHolder="Company"
          value={customer.company}
          onChange={handleChange}
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          Province<span className="required-form-field-asterisk">*</span>
        </label>
        {/*<input
          name="province"
          className="form-control form-control-sm"
          placeHolder="Province/State"
          value={customer.province}
          onChange={handleChange}
				></input>*/}
        <select
          name="province"
          className="form-control form-control-sm"
          placeHolder="Province/State"
          value={customer.province}
          onChange={handleChange}
        >
          <option value="">Choose a province</option>
          <option value="Andaman and Nicobar Islands" data-code="AN">
            Andaman and Nicobar Islands
          </option>
          <option value="Andhra Pradesh" data-code="AP">
            Andhra Pradesh
          </option>
          <option value="Arunachal Pradesh" data-code="AR">
            Arunachal Pradesh
          </option>
          <option value="Assam" data-code="AS">
            Assam
          </option>
          <option value="Bihar" data-code="BR">
            Bihar
          </option>
          <option value="Chandigarh" data-code="CH">
            Chandigarh
          </option>
          <option value="Chhattisgarh" data-code="CG">
            Chhattisgarh
          </option>
          <option value="Dadra and Nagar Haveli" data-code="DN">
            Dadra and Nagar Haveli
          </option>
          <option value="Daman and Diu" data-code="DD">
            Daman and Diu
          </option>
          <option value="Delhi" data-code="DL">
            Delhi
          </option>
          <option value="Goa" data-code="GA">
            Goa
          </option>
          <option value="Gujarat" data-code="GJ">
            Gujarat
          </option>
          <option value="Haryana" data-code="HR">
            Haryana
          </option>
          <option value="Himachal Pradesh" data-code="HP">
            Himachal Pradesh
          </option>
          <option value="Jammu and Kashmir" data-code="JK">
            Jammu and Kashmir
          </option>
          <option value="Jharkhand" data-code="JH">
            Jharkhand
          </option>
          <option value="Karnataka" data-code="KA">
            Karnataka
          </option>
          <option value="Kerala" data-code="KL">
            Kerala
          </option>
          <option value="Ladakh" data-code="LA">
            Ladakh
          </option>
          <option value="Lakshadweep" data-code="LD">
            Lakshadweep
          </option>
          <option value="Madhya Pradesh" data-code="MP">
            Madhya Pradesh
          </option>
          <option value="Maharashtra" data-code="MH">
            Maharashtra
          </option>
          <option value="Manipur" data-code="MN">
            Manipur
          </option>
          <option value="Meghalaya" data-code="ML">
            Meghalaya
          </option>
          <option value="Mizoram" data-code="MZ">
            Mizoram
          </option>
          <option value="Nagaland" data-code="NL">
            Nagaland
          </option>
          <option value="Odisha" data-code="OR">
            Odisha
          </option>
          <option value="Puducherry" data-code="PY">
            Puducherry
          </option>
          <option value="Punjab" data-code="PB">
            Punjab
          </option>
          <option value="Rajasthan" data-code="RJ">
            Rajasthan
          </option>
          <option value="Sikkim" data-code="SK">
            Sikkim
          </option>
          <option value="Tamil Nadu" data-code="TN">
            Tamil Nadu
          </option>
          <option value="Telangana" data-code="TS">
            Telangana
          </option>
          <option value="Tripura" data-code="TR">
            Tripura
          </option>
          <option value="Uttar Pradesh" data-code="UP">
            Uttar Pradesh
          </option>
          <option value="Uttarakhand" data-code="UK">
            Uttarakhand
          </option>
          <option value="West Bengal" data-code="WB">
            West Bengal
          </option>
        </select>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">Country</label>
        <input
          name="country"
          className="form-control form-control-sm"
          placeHolder="Country"
          value={customer.country ? customer.country : "India"}
          // onChange={handleChange}
          readOnly
          disabled
        ></input>
      </div>
      <div className="reusable-form-grid">
        <label htmlFor="">
          Zip code<span className="required-form-field-asterisk">*</span>
        </label>
        <input
          name="zip"
          className="form-control form-control-sm"
          placeHolder="Zip/postal code"
          value={customer.zip}
          onChange={handleChange}
        ></input>
      </div>
    </div>
  );
};

export default CustomerDataForm;

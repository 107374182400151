import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Cryptr from "cryptr";

import useModal from "../../global/Modal";

const ShippingCharges = () => {
  const [, Modal, setMessage, setShow] = useModal();
  const [aggregator, setAggregator] = useState(null);
  const [user, setUser] = useState(null);

  document.title = "Update ShippingCharges";

  const fetchUser = () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));
    setUser({
      user_id: details.user_id,
      name: details.name,
      email: details.email,
      role: details.role,
    });
  };

  const submit = async (e) => {
    e.preventDefault();

    if (!aggregator) {
      alert("Please select an aggregator!");
      return;
    }

    if (!user) {
      alert("User not found!");
      return;
    }

    const aggregatorUrls = {
      ShipRocket: "/api/upload/shipRocket/shippingCharges",
      ShipYaari: "/api/upload/shipYaari/shippingCharges",
      ShadowFax: "/api/upload/shadowFax/shippingCharges",
    };

    const formData = new FormData();
    const csvFile = document.querySelector("#shippingCharges_csv_input");
    formData.append("file", csvFile.files[0]);

    try {
      const result = await axios.post(aggregatorUrls[aggregator], formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          email: user.email,
        },
        validateStatus: () => true,
      });
      if (result && result.data && result.data.status) {
        setMessage(result.data.message);
        setShow(true);
        document.querySelector("form").reset();
        return;
      }
      setMessage(result.data.message);
      setShow(true);
      return;
    } catch (error) {
      setMessage("Something went wrong!");
      setShow(true);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="card configurationsCard IMS-order-card">
      <Modal />
      <div style={{ padding: "20px" }}>
        <form encType="multipart/form-data">
          <Fragment>
            <label htmlFor={`shippingCharges_csv_input`}>
              Upload Shipping Charges CSV
            </label>
            <div className="form-group resourceForm">
              <select
                className="form-control form-control-sm"
                style={{ width: "30%", marginRight: "2rem" }}
                onChange={(e) => setAggregator(e.target.value)}
              >
                <option default value={0}>
                  Choose aggregator
                </option>
                <option value="ShipRocket">ShipRocket</option>
                <option value="ShipYaari">ShipYaari</option>
                <option value="ShadowFax">ShadowFax</option>
              </select>
              <input
                type="file"
                name="file"
                accept="*.csv"
                id={`shippingCharges_csv_input`}
                placeholder={`ShippingCharges CSV File`}
              />

              <button
                type="button"
                id={`csv_btn`}
                className="btn btn-success btn-product-add"
                onClick={submit}
              >
                Add
              </button>
            </div>
          </Fragment>
        </form>
      </div>
    </div>
  );
};

export default ShippingCharges;

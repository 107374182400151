import React, { useState } from "react";

export const Accordion = ({ title, content, className }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className={"bm-accordion-item" + " " + className}>
      <div
        className="bm-accordion-title"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="bm-accordion-mouse-pointer">{title}</div>
        <div className="ml-3">
          <i
            className={`fas fa-angle-${
              isActive ? "up" : "down"
            } bm-accordion-mouse-pointer text-${
              isActive ? "danger" : "success"
            }`}
          />
        </div>
      </div>
      {isActive && <div className="bm-accordion-content">{content}</div>}
    </div>
  );
};

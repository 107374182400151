export const getCourierName = (courier_company_id) => {
  switch (courier_company_id) {
    case 1: {
      // console.log("bluedart");
      return { name: "Bluedart", link: "bluedart" };
    }
    case 2: {
      // console.log("fedex");
      return { name: "Fedex", link: "fedex" };
    }
    case 10: {
      // console.log("delhivery");
      return { name: "Delhivery", link: "delhivery" };
    }
    case 12: {
      // console.log("fedex surface");
      return { name: "FedEx Surface", link: "fedex" };
    }
    case 14: {
      // console.log("ecom_exp");
      return { name: "Ecom Express", link: "ecom-express" };
    }
    case 16: {
      // console.log("dotzot");
      return { name: "Dotzot", link: "dotzot" };
    }
    case 33: {
      // console.log("xpressbees");
      return { name: "Xpressbees", link: "xpressbees" };
    }
    case 39: {
      // console.log("delhivery surface");
      return { name: "Delhivery Surface", link: "delhivery" };
    }
    case 40: {
      // console.log("gati surface");
      return { name: "Gati Surface", link: "gati-kwe" };
    }
    case 41: {
      // console.log("fedex fr");
      return { name: "Fedex FR", link: "fedex" };
    }
    case 42: {
      // console.log("fedex sl");
      return { name: "Fedex SL", link: "fedex" };
    }
    case 43: {
      // console.log("delhivery surface standard");
      return { name: "Delhivery Surface Standard", link: "delhivery" };
    }
    case 44: {
      // console.log("delhivery surface lite");
      return { name: "Delhivery Surface Lite", link: "delhivery" };
    }
    case 45: {
      // console.log("ecom express reverse");
      return { name: "Ecom Express Reverse", link: "ecom-express" };
    }
    case 48: {
      // console.log("ekart");
      return { name: "Ekart", link: "ekart" };
    }
    case 51: {
      // console.log("xpressbees surface");
      return { name: "Xpressbees Surface", link: "xpressbees" };
    }
    case 54: {
      // console.log("ekart surface");
      return { name: "Ekart Surface", link: "ekart" };
    }
    case 55: {
      // console.log("dart surplus");
      return { name: "Dart Surplus", link: "bluedart" };
    }
    case 60: {
      // console.log("ecom ros");
      return { name: "Ecom ROS", link: "ecom-express" };
    }
    case 68: {
      // console.log("delhivety essentials");
      return { name: "Delhivery Essentials", link: "delhivery" };
    }
    case 103: {
      // console.log("xpressbees essentials");
      return { name: "Xpressbees Essentials", link: "xpressbees" };
    }
    case 108: {
      // console.log("ecom essentials");
      return { name: "Ecom Essentials", link: "ecom-express" };
    }
    case 109: {
      // console.log("ecom essentials");
      return { name: "Shadowfax", link: "shadowfax" };
    }
    default:
      return { name: "Others", link: "others" };
  }
};

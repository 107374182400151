import React from "react";

function CallBackModal({ order, setShowModal, callBack }) {
  const handleFocusDate = () => {
    document.getElementById(
      `datePicker-${order.name}-${order.store.storeName}`
    ).type = "datetime-local";
  };
  const handleBlurDate = () => {
    document.getElementById(
      `datePicker-${order.name}-${order.store.storeName}`
    ).type = "text";
  };
  return (
    <div className="cb-modal-card">
      <div style={{ display: "flex", padding: "10px" }}>
        <div
          style={{
            marginRight: "20px",
            color: "#DC3545",
            cursor: "pointer"
          }}
          onClick={() => setShowModal(false)}
        >
          <i className="fas fa-times-circle"></i>
        </div>
        <div style={{ marginLeft: "auto" }}>{order.name}</div>
      </div>
      <div>
        <input
          className="form-control datepicker-calling-dashboard"
          placeholder="Time"
          type="text"
          onFocus={() => handleFocusDate()}
          onBlur={() => handleBlurDate()}
          id={`datePicker-${order.name}-${order.store.storeName}`}
        />
        <input
          className="form-control datepicker-calling-dashboard"
          placeholder="Comments"
          type="text"
          id={`comments-${order.name}-${order.store.storeName}`}
        />
        <button className="cb-button" onClick={() => callBack("submit")}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default CallBackModal;

import React, { Fragment, useState, useEffect } from "react";
import Axios from "axios";
import useDatePicker from "../../../components/global/DatePicker";
import moment from "moment";
import useProductPicker from "../../../components/global/ProductPicker";
import { confirmation } from "../../../utils/confirmation";

function UtilityBar({
  users,
  user,
  getOrders,
  setType,
  setCurrent,
  searchOrder,
  filters,
  setFilters,
  ndrFilters,
  setNDRFilters,
  searchFunction,
  // setCallType,
  // setStore,
  // setCaller,
  // setFromDate,
  // setToDate,
}) {
  const [stores, setStores] = useState([]);
  const [show, setShow] = useState(false);
  const [showUpSell, setShowUpSell] = useState(false);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2021-04");
  const [ProductPicker, ProductDisplay, products, setProducts] =
    useProductPicker();

  const permuteOrders = (value, type) => {
    setCurrent(1);
    if (type === "call") {
      filters.call = value;
      // setCallType(value)
    } else if (type === "store") {
      filters.store = value;
      // setStore(value);
    } else if (type === "caller") {
      filters.caller = value;
      // setCaller(value);
    } else if (type === "date") {
      filters.from = fromDate.toLocaleString();
      // setFromDate(fromDate.toLocaleString())
      filters.to = toDate.toLocaleString();
      // setToDate(toDate.toLocaleString());
    }

    // if (
    //   value === "abandonedCheckout" ||
    //   value === "ndr" ||
    //   value === "upSellOrder" ||
    //   value === "leads"
    // )
    //   type = value;

    // setType({ value, type });
    setFilters(filters);
  };

  const permuteNDROrders = (value, type) => {
    if (type === "type") ndrFilters.type = value;
    if (type === "count") ndrFilters.count = value;

    setNDRFilters(ndrFilters);
    setCurrent(1);
    getOrders(user, "All", 1);
  };

  const upSellOrders = async () => {
    if (
      !confirmation(`Are you sure you want to push this product to the dialer?`)
    )
      return;

    if (!products || products.length <= 0)
      return alert("Please select one product at least");

    const vidArray = products.map((product) => product.variant_id);

    let from = document.getElementById("datePickerFromUpsell").value
      ? document.getElementById("datePickerFromUpsell").value
      : new Date("2019");
    let to = document.getElementById("datePickerToUpsell").value
      ? document.getElementById("datePickerToUpsell").value
      : moment().endOf("day");

    let limit = document.getElementById("numberOfCalls").value;

    let type = document.getElementById("call_selector-status").value;

    if (!limit)
      return alert("Please enter the number of calls you want to push");

    limit = parseInt(limit);

    let data = {
      fromDate: from,
      toDate: to,
      vidArray,
      limit,
      user: {
        email: user.email,
      },
      type,
    };

    const result = await Axios.post("/api/support/upSellProductV2", data);
    alert(result.data.message || "Something went wrong");
    setShowUpSell(false);
    setProducts([]);
    clearDate();
  };

  const reassignOrders = async () => {
    let user_id = document.getElementById("caller-selector-reassign-from")
      ? document.getElementById("caller-selector-reassign-from").value
      : "";

    let to = document.getElementById("caller-selector-reassign-to")
      ? document.getElementById("caller-selector-reassign-to").value
      : "";

    let type = document.getElementById("call_selector-reassign-type")
      ? document.getElementById("call_selector-reassign-type").value
      : "";

    let breakAt = document.getElementById("breakAt").value
      ? document.getElementById("breakAt").value
      : alert("Please enter the amount of orders to reassign");

    if (!user_id || !type || !breakAt)
      alert(
        "Please choose the caller, type of call and the number of calls to reassign"
      );

    let data = { type, user_id, breakAt, to };

    if (user_id !== "All") {
      const response = await Axios.post("/api/support/reassignOrders", data);
      alert(response.data.message);
      setShow(false);
    } else alert("Please Choose A Single User");
  };

  const getStoresList = async () => {
    let result = await Axios.get("/api/stores");

    // SHINE X PRO STORE ONLY
    result.data = result.data.filter(
      (store) => store.shopifyName === "shinexpro"
    );

    setStores(result.data);
  };

  const handleFocusDate = () => {
    document.getElementById("datePickerFromUpsell").type = "date";
    document.getElementById("datePickerToUpsell").type = "date";
  };
  const handleBlurDate = () => {
    document.getElementById("datePickerFromUpsell").type = "text";
    document.getElementById("datePickerToUpsell").type = "text";
  };

  useEffect(() => {
    permuteOrders(null, "date");
  }, [fromDate, toDate]);

  useEffect(() => {
    getStoresList();
    global.selected = [];
  }, []);

  useEffect(() => {
    if (!showUpSell) {
      setProducts([]);
      clearDate();
    }
  }, [showUpSell]);

  return (
    <Fragment>
      <div className="utilityBar">
        <input
          type="text"
          placeholder="Search..."
          className="utilityBar-search"
          aria-label="Search"
          id="searchInput"
          onChange={(e) => {
            if (e.target.value === "") searchOrder(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.charCode === 13) searchOrder(e.target.value);
          }}
        />

        <div className="callingboard-filter-bar">
          <select
            placeholder="States"
            className="utilityBar-filter"
            id={"call_selector"}
            onChange={(e) => permuteOrders(e.target.value, "call")}
          >
            <option value="All" disabled selected>
              Type
            </option>
            {/* <option value="All">All</option> */}
            <option value="repeatCustomer">Repeat Customer</option>

            <option value="abandonedCheckout">Abandoned Checkout</option>

            <option value="multipleItems">Multiple Items</option>

            <option value="multipleQuantity">Multiple Quantity</option>
            <option value="callCustomer">Address Issue</option>
            <option value="productConfirmation">Product Confirmation</option>

            <option value="ndr">NDR</option>
            <option value="upSellOrder">Upsell</option>
            <option value="leads">Leads</option>
          </select>

          <select
            className="utilityBar-filter"
            id={"store-selector"}
            onChange={(e) => permuteOrders(e.target.value, "store")}
          >
            <option value="All" disabled selected>
              Store
            </option>
            {stores.map((store) => {
              if (!store.deleted) {
                return (
                  <option key={store.shopifyName} value={store.shopifyName}>
                    {store.storeName}
                  </option>
                );
              }
            })}
          </select>

          {users && user.master ? (
            <select
              placeholder="States"
              className="utilityBar-filter"
              id={"caller-selector"}
              onChange={(e) => permuteOrders(e.target.value, "caller")}
            >
              <option value="All" disabled selected>
                User
              </option>
              {/* <option value="All">All</option> */}
              {users.map((user) =>
                user.role === "support" ? (
                  <option key={user.email} value={user.name}>
                    {user.name}
                  </option>
                ) : (
                  ""
                )
              )}
            </select>
          ) : (
            ""
          )}
          <div
            style={{
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
            }}
          >
            <DatePicker className="utilityBar-filter" />
          </div>
          <button
            type="button"
            className="btn btn-danger"
            style={{ borderRadius: "0px", padding: "10px" }}
            onClick={() => {
              searchFunction();
            }}
          >
            <i class="fas fa-search"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger"
            style={{ borderRadius: "0px", padding: "10px" }}
            onClick={() => {
              setFilters({
                store: "",
                caller: "",
                call: "",
                from: moment("2019").toLocaleString(),
                to: moment().endOf("day").toLocaleString(),
              });
              setNDRFilters({ type: "", count: "" });
              setType("");
              clearDate();
              window.location.href =
                window.location.origin + window.location.pathname;
              // window.location.reload();
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
        {filters.call === "ndr" ? (
          <Fragment>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <select
                placeholder="States"
                className="utilityBar-filter"
                id={"caller-selector"}
                onChange={(e) => permuteNDROrders(e.target.value, "type")}
              >
                <option value="All" disabled selected>
                  NDR Type
                </option>
                <option>ShipRocket</option>
                <option>ClickPost</option>
                <option>ShipYaari</option>
              </select>
              <select
                placeholder="States"
                className="utilityBar-filter"
                id={"caller-selector"}
                onChange={(e) => permuteNDROrders(e.target.value, "count")}
              >
                <option value="All" disabled selected>
                  NDR Count
                </option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>Others</option>
              </select>
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </div>
      {user.master || user.employee || user.privilegedCaller ? (
        <Fragment>
          <div className="utilityBar-tools">
            <button
              type="button"
              className="btn btn-danger"
              style={{
                borderRadius: "0px",
                padding: "10px",
                width: "22.5vw",
                marginRight: "20px",
              }}
              onClick={() => setShow(!show)}
            >
              Reassign Orders{" "}
              {!show ? (
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
              )}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              style={{
                borderRadius: "0px",
                padding: "10px",
                width: "22.5vw",
                background: "#3398DB",
                borderColor: "#3398DB",
              }}
              onClick={() => setShowUpSell(!showUpSell)}
            >
              <i class="fas fa-arrow-circle-up"></i> Upsell Orders{" "}
              {!show ? (
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
              )}
            </button>
          </div>
          {show ? (
            <div className="utilityBar">
              <select
                placeholder="States"
                className="utilityBar-filter"
                id={"caller-selector-reassign-from"}
              >
                <option value="All" disabled selected>
                  From
                </option>
                {users.map((user) =>
                  user.role === "support" ? (
                    <option value={user.user_id}>{user.name}</option>
                  ) : (
                    ""
                  )
                )}
              </select>

              <select
                placeholder="States"
                className="utilityBar-filter"
                id={"caller-selector-reassign-to"}
              >
                <option value="All" disabled selected>
                  To
                </option>
                {users.map((user) =>
                  user.role === "support" ? (
                    <option value={user.name}>{user.name}</option>
                  ) : (
                    ""
                  )
                )}
              </select>

              <select
                placeholder="States"
                className="utilityBar-filter"
                id={"call_selector-reassign-type"}
              >
                <option value="All">All</option>
                <option value="repeatCustomer">Repeat Customer</option>

                <option value="abandonedCheckout">Abandoned Checkout</option>

                <option value="multipleItems">Multiple Items</option>

                <option value="multipleQuantity">Multiple Quantity</option>
                <option value="callCustomer">Address Issue</option>
                <option value="ndr">NDR</option>
              </select>

              <input
                type="number"
                className="utilityBar-filter"
                placeholder="Amount Of Calls"
                aria-label="Search"
                id="breakAt"
              />

              <button
                type="button"
                className="btn btn-danger"
                style={{ borderRadius: "0px", padding: "10px" }}
                onClick={() => reassignOrders()}
              >
                Submit
              </button>
            </div>
          ) : (
            ""
          )}

          <div style={{ display: "flex", padding: "20px 20px" }}>
            <ProductDisplay />
          </div>

          {showUpSell ? (
            <div className="utilityBar">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ height: "60px" }}>
                  <ProductPicker />
                </div>

                <input
                  className="form-control datepicker-calling-dashboard"
                  placeholder="From"
                  type="text"
                  onFocus={() => handleFocusDate()}
                  onBlur={() => handleBlurDate()}
                  id="datePickerFromUpsell"
                />

                <input
                  className="form-control datepicker-calling-dashboard"
                  placeholder="To"
                  type="text"
                  onFocus={() => handleFocusDate()}
                  onBlur={() => handleBlurDate()}
                  id="datePickerToUpsell"
                />
                <input
                  className="form-control form-control-sm utilityBar-filter"
                  placeholder="Number Of Customers"
                  type="text"
                  id="numberOfCalls"
                />
                <select
                  placeholder="States"
                  className="utilityBar-filter"
                  id={"call_selector-status"}
                >
                  <option value="delivered" defaultValue>
                    Delivered
                  </option>
                  <option value="undelivered">Undelivered</option>
                </select>
              </div>

              <button
                type="button"
                className="btn btn-danger"
                style={{ borderRadius: "0px", padding: "10px" }}
                onClick={() => upSellOrders()}
              >
                Submit
              </button>
            </div>
          ) : (
            ""
          )}
        </Fragment>
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default UtilityBar;

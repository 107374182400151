import React from "react";
import Axios from "axios";

const SettingForm = ({ setShowAddForm, store, getStatus }) => {
  const addSetting = async e => {
    e.preventDefault();
    let name = document.getElementById("setting").value;
    let description = document.getElementById("setting_description").value;
    let body = {
      store,
      name,
      description
    };
    if (name !== "" && description !== "") {
      let response = await Axios({
        method: "post",
        url: `/api/settings/addSettings`,
        data: body,
        headers: {
          "content-type": `application/json`
        }
      });
      setShowAddForm(prevState => !prevState);
      await getStatus(store);
    } else {
      alert("Fill all fields");
      setShowAddForm(prevState => !prevState);
    }
  };
  return (
    <form>
      <div className="form-group">
        <label htmlFor="setting">Name of Setting(Permanent)</label>
        <input
          type="text"
          className="form-control"
          id="setting"
          placeholder="Name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="setting_description">Short Description(Editable)</label>
        <input
          type="text"
          className="form-control"
          id="setting_description"
          placeholder="Description"
        />
      </div>
      <button
        type="submit"
        className="btn btn-success"
        onClick={e => addSetting(e)}
        style={{ background: "#9b59b6" }}
      >
        Add
      </button>
    </form>
  );
};

export default SettingForm;

import React, { useState, useEffect, useContext, Fragment } from "react";
import Axios from "axios";
import moment from "moment";
import StoreButton from "../res/StoreButton";
import AggregatorButton from "../res/AggregatorButton";
import Home from "../res/Home";
import { GlobalConsumer } from "../../Global-Context/global-context";
import FilterBar from "../layouts/FilterBar";
import oldOrderCheck from "../res/oldOrderCheck";
import useAlert from "../../components/global/Alert";
import CourierModal from "../res/CourierModal";

function MainBoard() {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user, setUser] = useState(userInfo);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedR, setSelectedR] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [issues, setIssues] = useState([]);
  const [issuesMap, setIssuesMap] = useState(new Map());
  const [surfaceOrders, setSurfaceOrders] = useState([]);
  const [selectedCouriers, setSelectedCouriers] = useState({});
  const [courierModal, setCourierModal] = useState(false);
  const [courierPrices, setCourierPrices] = useState([]);
  const [AlertBox, setNotification] = useAlert();

  let [fromDate, setFromDate] = useState(moment("2019"));
  let [toDate, setToDate] = useState(moment().endOf("day"));
  document.title = "The Operations DashBoard";

  const fetchOrders = async (store) => {
    if (!store) return;
    setLoading(true);

    let result = await Axios.get(
      `/api/operations/scheduleOrdersManually?store=${JSON.stringify(
        store
      )}&fromDate=${moment(fromDate).toISOString()}&toDate=${moment(
        toDate
      ).toISOString()}`
    );
    setLoading(false);
    // Process orders
    window.scrollTo({
      top: 90,
      left: 0,
      behavior: "smooth",
    });
    if (result.data.ordersList) await processOrders(result.data.ordersList);
    setFilteredOrders(result.data.ordersList);
    setOrders(result.data.ordersList);
    setSurfaceOrders(result.data.surfaceOrders);
  };

  const selectOrders = (selectMode) => {
    let temp = [];
    let tempSelected = [];

    if (selectMode) {
      temp = filteredOrders.map((order) => {
        order.show = true;
        tempSelected.push(order.name);
        return order;
      });
      setFilteredOrders([]);
      setFilteredOrders(temp);
      setSelected(tempSelected);
    } else {
      temp = filteredOrders.map((order) => {
        order.show = false;
        return order;
      });
      setFilteredOrders([]);
      setFilteredOrders(temp);
      setSelected([]);
    }
  };

  const filterProducts = (order, product) => {
    let found = false;
    order.line_items.map((item) => {
      if (item.title === product) found = true;
    });
    return found;
  };

  const searchOrders = (input) => {
    input = input.trim();
    let temp = orders.filter(
      (order) =>
        order.name.includes(input) ||
        (typeof order.shipmentFailureReason === "string"
          ? order.shipmentFailureReason.toLowerCase().includes(input)
          : false) ||
        `px${order.line_items.length}` == input
    );
    setFilteredOrders(temp);
  };

  const filterOrders = () => {
    if (
      !document.getElementById("filterBar-product") ||
      !document.getElementById("filterBar-reason")
    )
      return;
    let product =
      document.getElementById("filterBar-product").value !== "Products"
        ? document.getElementById("filterBar-product").value
        : "";
    let reason =
      document.getElementById("filterBar-reason").value != "Reasons"
        ? document.getElementById("filterBar-reason").value
        : "";

    if (product && reason) {
      let temp = orders.filter((order) => {
        return (
          (reason !== "Others"
            ? typeof order.shipmentFailureReason === "string"
              ? order.shipmentFailureReason.includes(reason)
              : false
            : ![
                "Cannot reassign courier",
                "AWB is already assigned",
                "Unknown ProductDimensions",
                "Unknown",
                "Out Of Stock",
                "Error with Decision Engine, Schedule Manually",
                "Product not recognized, Please reimport the product/s from Shopify",
              ].includes(order.shipmentFailureReason)) &&
          filterProducts(order, product)
        );
      });

      setFilteredOrders(temp);
    } else if (product || reason) {
      let temp = orders.filter((order) => {
        return (
          (reason !== "Others"
            ? typeof order.shipmentFailureReason === "string"
              ? order.shipmentFailureReason.includes(reason)
              : false
            : ![
                "Cannot reassign courier",
                "AWB is already assigned",
                "Unknown ProductDimensions",
                "Unknown",
                "Out Of Stock",
                "Error with Decision Engine, Schedule Manually",
                "Product not recognized, Please reimport the product/s from Shopify",
              ].includes(order.shipmentFailureReason)) ||
          filterProducts(order, product)
        );
      });
      setFilteredOrders(temp);
    } else {
      setNotification("failure", "Please Choose A Filter");
    }
  };

  const clearFilters = () => {
    if (
      !document.getElementById("filterBar-product") ||
      !document.getElementById("filterBar-reason")
    )
      return;

    setFilteredOrders(orders);

    document.getElementById("filterBar-product").value = "Products";

    document.getElementById("filterBar-reason").value = "Reasons";
  };

  const processOrders = async (orders) => {
    let products = [];
    let issueArr = [];

    orders.map((order) => {
      order.line_items.map((product) => {
        if (!products.includes(product.title)) products.push(product.title);
      });
      if (!issuesMap.has(order.shipmentFailureReason)) {
        issuesMap.set(order.shipmentFailureReason, 0);
      } else {
        issuesMap.set(
          order.shipmentFailureReason,
          issuesMap.get(order.shipmentFailureReason) + 1
        );
        if (
          issuesMap.get(order.shipmentFailureReason) > 5 &&
          !issueArr.includes(order.shipmentFailureReason)
        )
          if (order.shipmentFailureReason)
            issueArr.push(order.shipmentFailureReason);
      }
    });
    setIssues(issueArr);
    setIssuesMap(issuesMap);
    setProducts(products);
  };

  const deselectOrders = () => {
    setSelected([]);
    setSelectedR([]);
    setOrders([]);
    fetchOrders(store);
  };

  const fetchCourierPrices = async (pricesToBeFetchedOrders) => {
    // setLoading(true);
    // send req to backend to fetch the prices of each order
    const payload = {
      orders: pricesToBeFetchedOrders,
    };
    const response = await Axios.post(
      "/api/operations/fetchCourierPrices",
      payload
    );
    // store the prices in courierPrices state
    setCourierPrices(response.data.courierPrices);
    // setLoading(false);
  };

  const scheduleOrders = async (aggregator) => {
    if (aggregator.includes("GoSwift")) {
      const input =
        prompt(
          "ARE YOU SURE YOU WANT TO SCHEDULE THIS/THESE ORDER/ORDERS THROUGH GOSWIFT (yes/no)"
        ).toLowerCase() || "no";
      if (input !== "yes") setNotification("failure", "Not proceeding!");
      return;
    }

    let issues = false;
    let orderNames = [];
    selected.map((order) => {
      let actualWeight = document.getElementById(`${order}-actual`)
        ? document.getElementById(`${order}-actual`).value
        : "";
      let height = document.getElementById(`${order}-height`)
        ? document.getElementById(`${order}-height`).value
        : "";
      let length = document.getElementById(`${order}-length`)
        ? document.getElementById(`${order}-length`).value
        : "";
      let width = document.getElementById(`${order}-width`)
        ? document.getElementById(`${order}-width`).value
        : "";

      if (!actualWeight || !height || !length || !width) {
        issues = true;
        setNotification("failure", `Please Input Dimensions For ${order}`);
        document
          .getElementById(`order-${order}`)
          .scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        //add
        let orderObject = {
          orderName: order,
          length,
          width,
          height,
          actualWeight,
        };
        orderNames.push(orderObject);
      }
    });

    if (issues) return;

    // check if orders with surface product are there
    let pricesToBeFetchedOrders = [];
    for (const order of orders) {
      // check if it is a order containing a surface product and a selected order and no courier selected for that order
      if (
        selected.includes(order.name) &&
        surfaceOrders.includes(order.name) &&
        !selectedCouriers[order.name]
      ) {
        // push into prices to be fetched array
        const orderObject = orderNames.find((o) => o.orderName === order.name);
        orderObject.cod = order.financial_status === "paid" ? 0 : 1;
        orderObject.zip =
          order.shipping_address.zip || order.billing_address.zip;
        orderObject.declaredValue = order.total_price;
        pricesToBeFetchedOrders.push(orderObject);
      }
    }

    // fetch prices for those orders that need prices to be disp
    if (pricesToBeFetchedOrders.length > 0) {
      // fetch the courier prices for the surface orders
      await fetchCourierPrices(pricesToBeFetchedOrders);
      // show courier modal so user can select the courier
      setCourierModal(true);
      return;
    }

    // check if old orders are there
    const oldOrderData = await oldOrderCheck(orderNames, store);
    if (oldOrderData.length > 0) {
      const answer = prompt(
        `Some orders are older than one month - ${oldOrderData}.\n Enter "all" to proceed with all orders.\n Enter "skip" to proceed with all orders except the old ones above.`
      );
      if (!answer) {
        setNotification("failure", "Could not schedule orders!");
        return;
      } else if (answer.toLowerCase() === "skip") {
        orderNames = orderNames.filter(
          (order) => !oldOrderData.includes(order)
        );
      } else if (answer.toLowerCase() === "all") {
        // pass orderNames itself
      } else {
        setNotification(
          "failure",
          "Wrong option. Could not schedule orders. Try again"
        );
        return;
      }
    }

    const data = {
      orderNames,
      store,
      user: {
        user_id: user.user_id,
        user: user.name,
      },
      aggregator,
    };

    let API = "";

    if (aggregator.includes("ShipRocket")) {
      API = "/api/operations/scheduleOrdersManually";
      data.selectedCouriers = selectedCouriers;
    } else if (aggregator.includes("ClickPost"))
      API = "/api/clickpost/operations/scheduleOrdersManually";
    else if (aggregator === "GoSwift")
      API = "/api/goswift/operations/scheduleOrdersManually";
    else if (aggregator === "ShipYaari")
      API = "/api/shipyaari/operations/scheduleOrdersManually";
    else if (aggregator === "ShadowFax")
      API = "/api/shadowfax/operations/scheduleOrdersManually";

    if (!API) return setNotification("failure", "Please contact the devs");

    try {
      const result = await Axios.post(API, data);
      const { data: response } = result;
      setNotification("success");
      if (response.scheduledOrders.length > 0) {
        setNotification(
          "success",
          `Attempted to schedule ${response.scheduledOrders}`
        );
      }
      if (response.unscheduledOrders.length > 0) {
        setNotification(
          "failure",
          `Failed to schedule ${response.unscheduledOrders}`
        );
      }

      // if (
      //   response.message &&
      //   response.message.includes("Please Select Order/Orders")
      // ) {
      //   return setNotification("success", response.message);
      // } else if (response.status) {
      //   return;
      // } else {
      //   setNotification(
      //     "success",
      //     `Attempted to schedule ${response.scheduledOrders}`
      //   );
      //   setTimeout(
      //     () =>
      //       setNotification(
      //         "failure",
      //         `Failed to schedule ${response.unscheduledOrders}`
      //       ),
      //     2000
      //   );
      // }

      setSelected([]);
      setOrders([]);
      setSelectedCouriers({});
      setCourierModal(false);
      fetchOrders(store);
    } catch (error) {
      setNotification(
        "failure",
        "Could not schedule the order, check shipment failure reason"
      );
      setSelected([]);
      setOrders([]);
      setSelectedCouriers({});
      setCourierModal(false);
      fetchOrders(store);
    }
  };

  const removeOrders = async (single) => {
    let orderNames = [];
    let issues = false;

    if (single) {
      const selectedOrder = orders.find((order) => order.name === single);
      const reason =
        typeof selectedOrder.shipmentFailureReason === "string"
          ? selectedOrder.shipmentFailureReason
          : "Do not consider";
      if (
        /Oops! Cannot reassign courier|AWB is already assigned|Please try again after|504|502/.test(
          reason
        )
      ) {
        setNotification(
          "failure",
          `${single} cannot be removed, Kindly update the order's details!`
        );

        window.open(
          "https://bazingamedia.xyz/operations/updateOrderDetails",
          "_blank"
        );
        window.open("https://app.shiprocket.in/orders/all", "_blank");

        return;
      }

      if (!document.getElementById(`${single}-reason`))
        return setNotification("failure", "Something went wrong!");
      let remarks = document.getElementById(`${single}-reason`).value
        ? document.getElementById(`${single}-reason`).value
        : "";

      if (!remarks)
        return setNotification(
          "failure",
          `Please enter a reason for removing ${single}!`
        );

      let item = {
        orderName: single,
        orderId: selectedOrder._id,
        remarks,
      };
      orderNames.push(item);
    } else {
      const awbAlreadyAssignedOrders = [];
      selectedR.map((selected) => {
        const selectedOrder = orders.find((order) => order.name === selected);
        const reason =
          typeof selectedOrder.shipmentFailureReason === "string"
            ? selectedOrder.shipmentFailureReason
            : "Do not consider";
        if (
          /Oops! Cannot reassign courier|AWB is already assigned|Please try again after|504|502/.test(
            reason
          )
        ) {
          awbAlreadyAssignedOrders.push(selected);
        }
      });
      if (awbAlreadyAssignedOrders.length > 0) {
        setNotification(
          "faliure",
          `${awbAlreadyAssignedOrders} cannot be removed, Kindly update the order's details!`
        );

        window.open(
          "https://bazingamedia.xyz/operations/updateOrderDetails",
          "_blank"
        );
        window.open("https://app.shiprocket.in/orders/all", "_blank");

        return;
      }
      selectedR.map((selected) => {
        const selectedOrder = orders.find((order) => order.name === selected);
        if (document.getElementById(`${selected}-reason`)) {
          if (!document.getElementById(`${selected}-reason`).value) {
            setNotification(
              "faliure",
              `Please enter the reason for ${selected}`
            );
            issues = true;
          } else {
            let item = {
              orderName: selected,
              orderId: selectedOrder._id,
              remarks: document.getElementById(`${selected}-reason`).value,
            };
            orderNames.push(item);
          }
        } else {
          setNotification("failure", "Something Wrong With Multiple Removal");
          issues = true;
        }
      });
      if (issues) return;
    }
    let data = {
      orderNames,
      store,
      user: {
        user_id: user.user_id,
        name: user.name,
      },
    };

    let result = await Axios.post(
      "/api/operations/removeOrdersFromDashboard",
      data
    );

    setSelectedR([]);

    if (result.data.ordersFound) {
      if (result.data.ordersFound.length > 0) {
        setNotification(
          "success",
          `Successfully removed ${result.data.ordersFound}`
        );
      } else {
        setNotification(
          "failure",
          `Couldn't find ${result.data.ordersNotFound}`
        );
      }
    } else {
      setNotification("failure", "Something went wrong while removing orders");
    }

    fetchOrders(store);
  };

  const fetchStores = async () => {
    let result = await Axios.get("/api/stores?ignoreDeleted=true");

    // SHINE X PRO STORE ONLY
    result.data = result.data.filter(
      (store) => store.shopifyName === "shinexpro"
    );

    setStores(result.data);
  };

  const setDate = (fromDate, toDate) => {
    console.log(
      `(OPERATIONS DASHBOARD)\nFrom Date:${new Date(
        fromDate
      ).toLocaleString()}\nTo Date:${new Date(toDate).toLocaleString()}`
    );
    setFromDate(fromDate);
    setToDate(toDate);
  };

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    fetchOrders(store);
  }, [store, fromDate, toDate]);

  return (
    <div className="operations-main">
      <AlertBox />
      <div className="operations-button-container">
        <div className="operations-button-container-one">
          <StoreButton stores={stores} store={store} setStore={setStore} />
          {selected.length > 0 ? (
            <div className="operations-button-aggregator">
              <AggregatorButton
                scheduleOrders={scheduleOrders}
                fetchActivatedAggregators={true}
                store={store}
              />
            </div>
          ) : (
            ""
          )}
          {selectedR.length > 0 ? (
            <button
              style={{ marginLeft: "20px" }}
              className=" operations-store-button"
              type="button"
              onClick={() => removeOrders()}
            >
              Remove
            </button>
          ) : (
            ""
          )}
          {selected.length > 0 || selectedR.length > 0 ? (
            <div className="operations-selected">
              <div
                onClick={() => deselectOrders()}
                style={{
                  marginRight: "10px",
                  color: "#e74c3c",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i className="far fa-times-circle"></i>
              </div>
              {selected.map((order) => (
                <div
                  key={order}
                  className="operations-itemTag"
                  id={`tag_${order}`}
                  style={{ textTransform: "capitalize" }}
                >
                  {order}
                </div>
              ))}
              {selectedR.map((order) => (
                <div
                  key={order}
                  className="operations-itemTag"
                  id={`tag_${order}`}
                  style={{
                    textTransform: "capitalize",
                    background: "#e74c3c",
                    color: "white",
                  }}
                >
                  {order}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {store ? (
        <FilterBar
          products={products}
          filterOrders={filterOrders}
          clearFilters={clearFilters}
          searchOrders={searchOrders}
          selectOrders={selectOrders}
          setDate={setDate}
          store={store}
          issues={issues}
        />
      ) : (
        ""
      )}
      <div className="operations-main-content">
        <Home
          orders={filteredOrders}
          setSelected={setSelected}
          store={store}
          loading={loading}
          selected={selected}
          searchOrders={searchOrders}
          selectOrders={selectOrders}
          filterOrders={filterOrders}
          clearFilters={clearFilters}
          setSelectedR={setSelectedR}
          selectedR={selectedR}
          products={products}
          removeOrders={removeOrders}
          fetchOrders={fetchOrders}
        />
      </div>
      {courierModal ? (
        <CourierModal
          orders={orders}
          selected={selected}
          surfaceOrders={surfaceOrders}
          selectedCouriers={selectedCouriers}
          setSelectedCouriers={setSelectedCouriers}
          setCourierModal={setCourierModal}
          scheduleOrders={scheduleOrders}
          courierPrices={courierPrices}
        />
      ) : null}
    </div>
  );
}

export default MainBoard;

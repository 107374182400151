import Axios from "axios";
import React, { useEffect } from "react";
import useProductPicker from "../components/global/ProductPicker";

export default function Dashboard() {
  const [
    ProductPicker,
    ProductDisplay,
    products,
    setProducts,
    ,
    ,
    getProductArray,
  ] = useProductPicker();

  const getProducts = async () => {
    const result = await Axios.get("/api/support/getFeedbackProducts");
    setProducts(result.data.products);
  };

  const submitProducts = async () => {
    await Axios.post("/api/support/addFeedbackProducts", {
      products,
      vidArray: getProductArray("v"),
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <center>Products to be uploaded for feedback</center>
      <div
        style={{
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          margin: "20px",
          marginTop: "10px",
        }}
      >
        {products.length > 0 ? (
          <div
            style={{ padding: "10px", height: "200px", overflowY: "scroll" }}
          >
            <ProductDisplay />
          </div>
        ) : (
          ""
        )}
        <div style={{ height: "50px" }}>
          <ProductPicker />
          <button
            className="btn btn-danger"
            style={{ borderRadius: 0, width: "100%" }}
            onClick={() => {
              submitProducts();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import FilterSelector from "../Items/FilterSelector";
import useDatePicker from "../../../../components/global/DatePicker";
import useDatePerspective from "../../../../components/global/DatePerspective";

const FilterBar = ({
  permuteOrders,
  aggregators,
  fetchAggregators,
  products,
  filtered,
  queryFilter,
  setDate,
  setPerspectiveOption,
  filterProducts,
  resetOffset,
  setSuperStatusTags,
  disabled,
  setDisabled,
  statusArray,
  statusAggregations,
  verboseMode,
  user,
}) => {
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [DatePerspectiveFilter, perspectiveOption] = useDatePerspective();

  const clearSelectors = () => {
    document.getElementById("statusSelector").value = 0;
    document.getElementById("superStatusSelector").value = "";
    document.getElementById("searchInput").value = "";
    if (document.getElementById("datePickerFrom"))
      document.getElementById("datePickerFrom").value = "";
    if (document.getElementById("datePickerTo"))
      document.getElementById("datePickerTo").value = "";
    if (document.getElementById("productSearch"))
      document.getElementById("productSearch").value = "";
    document.getElementById("order_percentage").innerHTML = "";

    if (document.getElementById("datePickerRange"))
      document.getElementById("datePickerRange").value = 0;

    global.selected = [];
    if (document.getElementById("FilterLog"))
      document.getElementById("FilterLog").innerHTML = "";

    if (document.getElementById("order-result").innerHTML)
      document.getElementById("order-result").innerHTML = "";

    if (document.getElementById("aggregator-selector"))
      document.getElementById("aggregator-selector").value = "Aggregator";

    if (document.getElementById("paymentOptionSelector"))
      document.getElementById("paymentOptionSelector").value = 0;

    if (!user.customer && document.getElementById("trafficSourceSelector"))
      document.getElementById("trafficSourceSelector").value = 0;

    clearDate();

    setDisabled({
      statusSelector: false,
      superStatusSelector: false,
    });
    setSuperStatusTags([]);
    resetOffset();
    // fetchInitialOrders();
  };

  useEffect(() => {
    fetchAggregators();
  }, []);

  useEffect(() => {
    setDate(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    setPerspectiveOption(perspectiveOption);
  }, [perspectiveOption]);

  return (
    <Fragment>
      <div className="trackingBoard-filterBar">
        <DatePerspectiveFilter />
        <select
          className="form-control form-control-sm"
          style={{
            height: "50px",
            fontSize: "0.9rem",
            borderRadius: 0,
            width: "100%",
          }}
          id="aggregator-selector"
        >
          <option defaultValue disabled>
            Aggregator
          </option>
          <option>All</option>
          {aggregators.map((aggregator) => (
            <option value={aggregator.name}>{aggregator.name}</option>
          ))}
        </select>
        <div style={{ height: "50px", width: "100%" }}>
          <DatePicker />
        </div>

        <FilterSelector
          options={statusArray}
          id={"statusSelector"}
          permuteOrders={permuteOrders}
          queryFilter={queryFilter}
          disabled={disabled}
          setDisabled={setDisabled}
          showVerbose={verboseMode && true}
        />

        <FilterSelector
          options={statusAggregations}
          id="superStatusSelector"
          disabled={disabled}
          setDisabled={setDisabled}
          setSuperStatusTags={setSuperStatusTags}
        />

        <FilterSelector id="paymentOptionSelector" />

        {!user.customer ? <FilterSelector id="trafficSourceSelector" /> : ""}

        <FilterSelector
          options={products}
          id={"productSelector"}
          permuteOrders={permuteOrders}
          filtered={filtered}
          queryFilter={queryFilter}
          filterProducts={filterProducts}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "50px",
          margin: "10px",
        }}
      >
        <button
          className="btn btn-danger"
          style={{ borderRadius: 0, width: "100%", marginRight: "10px" }}
          onClick={() => {
            resetOffset();
            queryFilter();
          }}
        >
          Search
        </button>

        <button
          className="btn btn-danger"
          style={{ borderRadius: 0, width: "100%" }}
          onClick={() => {
            clearSelectors();
          }}
        >
          Clear
        </button>
      </div>
    </Fragment>
  );
};

export default FilterBar;

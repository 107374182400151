import React, { Fragment, useState } from "react";
import BatchUpdateOrderDetails from "./BatchUpdateOrderByIds";
import BatchUpdateOrderDetailsAwb from "./BatchUpdateOrderDetailsAWB";
function BatchUpdateOrder() {
  document.title = "Batch Update ShipRocket Orders";
  const [option, setOption] = useState("");
  const options = ["By OrderIds", "By AWB"];

  return (
    <Fragment>
      <div className="card configurationsCard">
        <nav
          className="navbar jarvisBar "
          style={{ backgroundColor: "#2980b9", marginBottom: "10px" }}
        >
          <div className="dropdown">
            <button
              className="btn  dropdown-toggle storeButton"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "black" }}
            >
              {option == "" ? "Choose Options" : option}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {options.map((option, index) => (
                <button
                  key={index}
                  className="dropdown-item"
                  onClick={() => setOption(option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </nav>
        {option == "By OrderIds" ? <BatchUpdateOrderDetails /> : null}
        {option == "By AWB" ? <BatchUpdateOrderDetailsAwb /> : null}
        {option == "" ? (
          <div style={{ textAlign: "center" }}>
            <h4>Choose an Option</h4>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}

export default BatchUpdateOrder;

import React, { useEffect, useState } from "react";
import axios from "axios";

import StoreDropdownButton from "../../../components/operations/res/StoreDropdownButton";

const ConfirmationPage = ({
  temporaryCampaignId,
  size,
  setShowConfirmationPage,
  setCurrentTab,
}) => {
  const [showFinalCampaignPage, setShowFinalCampaignPage] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [campaignStore, setCampaignStore] = useState(null);
  const [campaignSize, setCampaignSize] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [campaignDuration, setCampaignDuration] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [perDay, setPerDay] = useState(null);
  const [campaignMessage, setCampaignMessage] = useState({
    productDetails: "",
    voucherCode: "",
  });
  const [campaignNotification, setCampaignNotification] = useState([]);
  const handleCancel = async () => {
    // handle cancel of populating data
    setShowConfirmationPage(false);

    // delete the data populated with temporary campaign id
    await axios.get(`/api/smsMobi/deleteDocs/${temporaryCampaignId}`);
  };

  const handleProceed = () => {
    setShowFinalCampaignPage(true);
  };

  const handleCalculation = (e) => {
    const durationOfCampaign = parseInt(e.target.value);

    if (!durationOfCampaign) {
      setEndDate(null);
      setPerDay(null);
      return;
    }

    setCampaignDuration(durationOfCampaign);
    let endDate;

    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    var date = new Date(startDate);

    if (startDate) {
      // endDate was giving one day extra so duration -1 will give correct endDate
      const endDateString = date
        .addDays(durationOfCampaign - 1)
        .toISOString()
        .slice(0, 10);
      console.log(endDateString);
      setEndDate(endDateString);

      const newDate =
        endDateString.slice(8) +
        endDateString.slice(4, 8) +
        endDateString.slice(0, 4);
    }
    setPerDay(parseInt(campaignSize / durationOfCampaign));
  };

  const handleCampaignSize = (e) => {
    console.log(e.target.value);
    setCampaignSize(parseInt(e.target.value));
  };

  const createCampaign = async () => {
    const campaignNotification = document
      .getElementById("campaign-final-notification-text-area")
      .value.split("\n");

    const payload = {
      temporaryCampaignId,
      campaignName,
      campaignStore,
      campaignSize,
      startDate,
      campaignDuration,
      perDay,
      endDate,
      campaignMessage,
      campaignNotification,
    };
    const { data } = await axios.post("/api/smsMobi/createCampaign", payload);
    console.log(data);
    alert(data.message);
    setShowConfirmationPage(false);
    setCurrentTab("mainTab");
  };

  useEffect(() => {
    if (!campaignSize) {
      setCampaignSize(size);
    }
  }, []);

  return !showFinalCampaignPage ? (
    <div className="sms-marketing-tabs">
      <div className="sms-populate-data-top-header">{`Total unique phone numbers that can be populated for this campaign - ${size}`}</div>
      <div className="sms-campaign-final-buttons">
        <button onClick={handleCancel} className="sms-campaign-populate-button">
          Cancel
        </button>
        {size > 0 ? (
          <button
            onClick={handleProceed}
            className="sms-campaign-populate-button"
          >
            Proceed
          </button>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="sms-marketing-tabs">
      <div className="sms-marketing-filter-final">
        <div>Campaign Name</div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Enter the name of the campaign here"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Campaign Store</div>
        <div>
          <StoreDropdownButton
            store={campaignStore}
            setStore={setCampaignStore}
          />
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Campaign Total Size</div>
        <div>
          <input
            type="number"
            className="form-control"
            placeholder="Enter the size of the campaign"
            value={campaignSize}
            onChange={(e) => handleCampaignSize(e)}
          ></input>
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Campaign Start Date</div>
        <div>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setStartDate(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Number Of Days To Run Campaign</div>
        <div>
          <input
            type="number"
            className="form-control"
            value={campaignDuration}
            onChange={(e) => handleCalculation(e)}
          ></input>
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>SMS Sent Per Day</div>
        <div>
          {perDay ? (
            <input
              type="number"
              className="form-control"
              value={perDay}
            ></input>
          ) : (
            `Select number of days to run campaign to calculate SMS sent per day`
          )}
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Campaign End Date</div>
        <div>
          {endDate
            ? endDate.slice(8) + endDate.slice(4, 8) + endDate.slice(0, 4)
            : "Select a start date and duration to calculate end date"}
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Campaign Product Details</div>
        <div>
          <textarea
            className="form-control"
            onChange={(e) => {
              const input = e.target.value;
              setCampaignMessage({
                ...campaignMessage,
                productDetails: input,
              });
            }}
          ></textarea>
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Campaign Voucher Code</div>
        <div>
          <input
            type="text"
            className="form-control"
            onChange={(e) => {
              const input = e.target.value;
              setCampaignMessage({
                ...campaignMessage,
                voucherCode: input,
              });
            }}
          ></input>
        </div>
      </div>
      <div className="sms-marketing-filter-final">
        <div>Campaign Final Notification Phone Numbers</div>
        <div>
          <textarea
            id="campaign-final-notification-text-area"
            className="form-control"
          ></textarea>
        </div>
      </div>
      <div className="sms-campaign-final-buttons">
        <button
          className="sms-campaign-populate-button"
          onClick={createCampaign}
        >
          Create Campaign
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPage;

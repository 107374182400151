import React, { Fragment, useContext } from "react";
import FilterSelector from "../../res/FilterSelector";
import AllSelector from "../../res/AllSelector";
import CallLog from "../layouts/CallLog";
import TeamCallLog from "../layouts/TeamCallLog";
import PendingCalls from "../layouts/PendingCalls";
import { UserConsumer } from "../context/caller-context";

const Dashboard = ({ user, userIndex, total }) => {
  const context = useContext(UserConsumer);
  const data = [
    { title: "Data 1", value: 1, color: "#2ed573" },
    { title: "Data 2", value: 2, color: "#ffa502" },
    { title: "Data 2", value: 3, color: "#ff7f50" },
    { title: "Data 2", value: 2, color: "#70a1ff" },
  ];
  return (
    <div className="callingDashboard">
      {user && user !== "All" && user !== "PendingCalls" ? (
        <Fragment>
          <div style={{ width: "80vw" }}>
            <div className="card card-5 grid-item-1">
              <FilterSelector userIndex={userIndex} user={user} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "35vh",
              justifyContent: "space-between",
            }}
          >
            {user.name !== "Team" ? (
              <CallLog user={user} />
            ) : (
              <TeamCallLog user={user} />
            )}
          </div>
        </Fragment>
      ) : user !== "PendingCalls" ? (
        <Fragment>
          <div style={{ width: "80vw" }}>
            <div className="card card-5 grid-item-1">
              <AllSelector />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "35vh",
              justifyContent: "space-between",
            }}
          >
            <div
              className="card card-5 stat-card "
              style={{ width: "100%", padding: "20px" }}
            >
              <p
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "90px",
                  fontSize: "5rem",
                  color: "#718093",
                }}
              >
                {total ? total : "0"}
              </p>
            </div>
          </div>
        </Fragment>
      ) : (
        <PendingCalls />
      )}
    </div>
  );
};

export default Dashboard;

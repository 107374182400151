import React, { useState } from "react";

const CustomDropdown = (name = "Select Any") => {
  const [values, setValues] = useState([]);
  const [selected, setSelected] = useState({});
  const [dropdownName, setDropdownName] = useState(name);
  const [disabled, setDisabled] = useState(false);
  const Dropdown = ({
    top = "0%",
    right = "0%",
    bottom = "0%",
    left = "0%",
  }) => {
    return (
      <div className="dropdown">
        <button
          style={{ padding: `${top} ${right} ${bottom} ${left}` }}
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {dropdownName}
        </button>
        <div
          style={{ height: "25vw", width: "30vw", overflowY: "scroll" }}
          className="dropdown-menu"
          aria-labelledby="dropdownMenu"
        >
          {!values.length ? (
            <button className="dropdown-item" type="button">
              {disabled ? "Please wait..." : "Empty"}
            </button>
          ) : (
            values.map(({ name, value }, index) => (
              <button
                key={index}
                className="dropdown-item"
                type="button"
                onClick={() => {
                  setSelected({ name, value });
                  setDropdownName(name);
                }}
              >
                {name}
              </button>
            ))
          )}
        </div>
      </div>
    );
  };
  return { Dropdown, selected, setValues, setDisabled };
};

export default CustomDropdown;

import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { createFunnel } from "../functions/createFunnel";
function ClickFunnels() {
  document.title = "Upload Funnel CSV";
  // const [stores, setStores] = useState([]);
  // const [store, setStore] = useState([]);
  const [funnels, setFunnels] = useState([]);
  const [funnel, setFunnel] = useState([]);
  const [active, setActive] = useState("upload");
  const [customers, setCustomers] = useState([]);

  const submit = async () => {
    var formData = new FormData();
    var csv_file = document.querySelector("#csv_input");
    formData.append("file", csv_file.files[0]);
    formData.append("funnel", funnel);

    const result = await axios.post("/api/upload/clickFunnels", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    console.log(result.data.msg);
    if (result.data.skipped) {
      alert(`Skipped ${result.data.skipped}`);
    }
  };
  const getStoresList = async () => {
    let res = await axios.get("/api/clickfunnels");

    let cus = await axios.get("/api/auth/users/customers");
    // let result = await axios.get("/api/stores");
    // console.log(result.data);
    // setStores(result.data);
    setCustomers(cus.data.users);
    setFunnels(res.data.funnels);
  };
  useEffect(() => {
    getStoresList();
  }, []);

  return (
    <Fragment>
      <div className="inventoryToggle">
        <div
          className={`inventory-toggle-item ${
            active === "upload" ? "inventory-active" : ""
          }`}
          onClick={() => {
            setActive("upload");
          }}
        >
          Upload
        </div>

        <div
          className={`inventory-toggle-item ${
            active === "create" ? "inventory-active" : ""
          }`}
          onClick={() => {
            setActive("create");
          }}
        >
          Create
        </div>
      </div>
      <div
        className="card  "
        style={{
          height: "100%",
          margin: "10vh 10vw",
          boxShadow:
            "0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)"
        }}
      >
        <div style={{ padding: "20px", height: "100%" }}>
          {active === "upload" ? (
            <form encType="multipart/form-data" style={{ height: "100%" }}>
              <Fragment>
                <input
                  type="file"
                  name="file"
                  accept="*.csv"
                  id={`csv_input`}
                  placeholder={`Product Name`}
                  style={{ marginBottom: "20px" }}
                />
                {/* <select
                  placeholder="Stores"
                  id="store_selector"
                  className="form-control form-control-sm"
                  style={{
                    fontSize: "0.9rem",
                    borderRadius: 0,
                    marginBottom: "20px"
                  }}
                  onChange={e => setStore(e.target.value)}
                >
                  <option value={0} selected disabled>
                    {"Choose Funnel"}
                  </option>
                  {stores.map((store, index) =>
                    store.click ? (
                      <option key={index}>{store.shopifyName}</option>
                    ) : (
                      ""
                    )
                  )}
                </select> */}

                <select
                  placeholder="Funnels"
                  id="funnel_selector"
                  className="form-control form-control-sm"
                  style={{
                    fontSize: "0.9rem",
                    borderRadius: 0,
                    marginBottom: "20px"
                  }}
                  onChange={e => setFunnel(e.target.value)}
                >
                  <option value={0} selected disabled>
                    {"Choose Funnel"}
                  </option>
                  {funnels.map((funnel, index) =>
                    funnel.name !== "testFunnel" ? (
                      <option key={index} value={funnel.id}>
                        {funnel.name}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>

                <button
                  type="button"
                  id={`csv_btn`}
                  className="btn btn-success btn-product-add"
                  onClick={() => submit()}
                  style={{ marginLeft: "0px" }}
                >
                  Add
                </button>
              </Fragment>
            </form>
          ) : (
            <Fragment>
              {(document.title = "Create Funnel")}
              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  justifyContent: "space-evenly",
                  height: "100%"
                }}
              >
                <label htmlFor={`product_name`}>Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  id={`product_name`}
                  placeholder={`Product Name`}
                />

                <label htmlFor={`product_quantity`}>Quantity</label>
                <input
                  type="text"
                  className="form-control"
                  id={`product_quantity`}
                  placeholder={`Quantity`}
                />

                <label htmlFor={`product_quantity`}>Price</label>
                <input
                  type="text"
                  className="form-control"
                  id={`product_price`}
                  placeholder={`Price`}
                />
                <br />
                <center>
                  <h4>Product Dimensions</h4>
                </center>

                <label htmlFor={`height`}>Height</label>
                <input
                  type="text"
                  className="form-control"
                  id={`height`}
                  placeholder={`Height`}
                />

                <label htmlFor={`width`}>Width</label>
                <input
                  type="text"
                  className="form-control"
                  id={`width`}
                  placeholder={`Width`}
                />

                <label htmlFor={`length`}>Length</label>
                <input
                  type="text"
                  className="form-control"
                  id={`length`}
                  placeholder={`Length`}
                />

                <label htmlFor={`weight`}>Actual Weight</label>
                <input
                  type="text"
                  className="form-control"
                  id={`weight`}
                  placeholder={`Weight`}
                />
                <br />
                <center>
                  <h4>Store</h4>
                </center>

                <label htmlFor={`height`}>Store Name</label>
                <input
                  type="text"
                  className="form-control"
                  id={`store_name`}
                  placeholder={`Name`}
                />

                <label htmlFor={`height`}>Store Prefix</label>
                <input
                  type="text"
                  className="form-control"
                  id={`store_prefix`}
                  placeholder={`CF (WITHOUT HASHTAG)`}
                />

                <label htmlFor={`height`}>Store Email</label>
                <input
                  type="text"
                  className="form-control"
                  id={`store_email`}
                  placeholder={`care@funnels.in`}
                />

                <label htmlFor={`height`}>Store Channel ID</label>
                <input
                  type="text"
                  className="form-control"
                  id={`store_channel_id`}
                  placeholder={`12345`}
                />

                <label htmlFor={`height`}>Address Threshold</label>
                <input
                  type="text"
                  className="form-control"
                  id={`store_threshold`}
                  placeholder={`35`}
                />

                <label htmlFor={`height`}>Shiprocket API Username</label>
                <input
                  type="text"
                  className="form-control"
                  id={`shiprocket_username`}
                  placeholder={`user`}
                />

                <label htmlFor={`height`}>Shiprocket API Password</label>
                <input
                  type="text"
                  className="form-control"
                  id={`shiprocket_password`}
                  placeholder={`password`}
                />

                <label htmlFor={`height`}>DB URL</label>
                <input
                  type="text"
                  className="form-control"
                  id={`store_SRV`}
                  placeholder={`mongodb://localhost:27017/funnels`}
                />

                <label htmlFor={`height`}>Who is this for?</label>
                <input
                  type="text"
                  list="customersList"
                  className="form-control"
                  id="customers"
                  placeholder="customers Name"
                  autoComplete="off"
                />
                <datalist id="customersList">
                  {customers.map((customer, i) => (
                    <option value={customer.email} key={i}>
                      {customer.name}
                    </option>
                  ))}
                </datalist>
                <br />
                <br />
                <button
                  type="button"
                  id={`csv_btn`}
                  className="btn btn-success btn-product-add"
                  onClick={() => createFunnel(getStoresList)}
                  style={{ marginLeft: "0px" }}
                >
                  Add
                </button>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default ClickFunnels;

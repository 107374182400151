import React, { useState, Fragment, useEffect, useContext } from "react";
import Axios from "axios";
import TagBar from "../../../trackingboard/OrderTracker/components/Items/TagBar"; // eslint-disble-line

function AddZone({ setShowEntry, stores }) {
  const [storesList, setStoresList] = useState(stores);
  // const [priorityList, setPriorityList] = useState(aggregators);
  const [reRender, setReRender] = useState(false);
  const [zones, setZones] = useState([]);

  // map and create priority for each store
  // setStores with updated stores
  // let o;
  // for (o of storesList) console.log(o.courierPriority);
  const submit = async () => {
    let zone = document.getElementById("zone-name").value;

    let zones = document.getElementById("pincodeList").value;

    // let List = priorityList.map((priority, index) => {
    //   let object = {
    //     name: priority.name,
    //     priority: index + 1
    //   };
    //   return object;
    // });

    // let ListOfStores = storesList.map((priority, index) => {
    //   let object = {
    //     name: priority.storeName,
    //     pri: priority.courierPriority,
    //     priority: index + 1
    //   };
    //   return object;
    // });
    let i;
    let j;
    for (i of storesList) {
      let courierPriority = [];
      for (j = 0; j < i.courierPriority.length; j++) {
        let data = {
          name: i.courierPriority[j].name,
          priority: j + 1
        };
        courierPriority.push(data);
      }
      i.courierPriority = courierPriority;
    }

    let data = {
      zone,
      zones,
      //priorityList: List,
      storesList
    };
    // console.log("data storeslist\n", data.storesList);
    await Axios.post("/api/control/AddZone", data);
    setShowEntry(true);
  };

  const modifyPriority = (newPriority, index, storeIndex) => {
    // let oneStore;
    let tempStoreList = storesList;
    // let priorityList = storesList[storeIndex]["courierPriority"];
    let priorityList = [];
    let i;
    for (i of storesList[storeIndex]["courierPriority"]) priorityList.push(i);
    // for (oneStore of storesList) {
    //   if (oneStore.storeName == storeObj.storeName) {
    //     if (newPriority >= 0 && newPriority < priorityList.length) {
    //       console.log("i am inside!!!!");
    //       let temp = oneStore.courierPriority[newPriority];
    //       oneStore.courierPriority[newPriority] =
    //         oneStore.courierPriority[index];
    //       oneStore.courierPriority[index] = temp;
    let temp2 = priorityList[newPriority];
    priorityList[newPriority] = priorityList[index];
    priorityList[index] = temp2;
    setReRender(!reRender);
    // setPriorityList(priorityList);
    tempStoreList[storeIndex]["courierPriority"] = priorityList;
    setStoresList(tempStoreList);
    // break;
    //     }
    //   }
    // }
	};
	// eslint-disable-next-line
  const modifyPriorityStore = (newPriority, index) => {
    // console.log(newPriority, index);
    if (newPriority >= 0 && newPriority < storesList.length) {
      let temp2 = storesList[newPriority];

      storesList[newPriority] = storesList[index];

      storesList[index] = temp2;
      console.log("modiefied !!", storesList);

      setReRender(!reRender);
      setStoresList(storesList);
    }
  };
	// eslint-disable-next-line
  const addZone = () => {
    let typeName = document.getElementById("type-name").value;
    let type = document.getElementById("type").value;

    let object = {
      name: typeName,
      type
    };

    zones.push(object);
    setZones(zones);
    document.getElementById("type-name").value = "";
    document.getElementById("type").value = "Type";
  };

  // console.log(priorityList);

  return (
    <div className="aggregator-add">
      <input
        type="text"
        className="form-control button-height"
        id="zone-name"
        placeholder="Zone Name"
        style={{ marginBottom: "20px" }}
      />

      <div className="form-group">
        <textarea
          className="form-control"
          id="pincodeList"
          rows="3"
          placeholder="Pincodes"
        ></textarea>
      </div>

      {/* <select
        type="text"
        className="form-control button-height"
        id="type"
        placeholder="Type"
        style={{ marginBottom: "20px" }}
        onChange={e => setType(e.target.value)}
      >
        <option>Type</option>
        <option>City</option>
        <option>State</option>
      </select>

      {type ? (
        <Fragment>
          <input
            type="text"
            className="form-control button-height"
            id="type-name"
            placeholder={`${type} Name`}
            style={{ marginBottom: "20px" }}
          />
          <button
            className="add-button"
            style={{ marginTop: "-10px" }}
            onClick={() => addZone()}
          >
            Submit
          </button>
        </Fragment>
      ) : (
        ""
      )} */}

      <div className="priority-list-stores">
        {storesList.map((itemStore, indexStore) => (
          <div
            key={itemStore.storeName}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="priority-store">{itemStore.storeName}</div>
            <div className="priority-list">
              {itemStore["courierPriority"].map((item, index) => (
                <div
                  key={item.name}
                  style={{ display: "flex", padding: "5px" }}
                >
                  <div style={{ marginRight: "20px", minWidth: "30px" }}>
                    {index > 0 ? (
                      <i
                        className="fas fa-chevron-up"
                        onClick={e =>
                          modifyPriority(index - 1, index, indexStore)
                        }
                      ></i>
                    ) : (
                      ""
                    )}
                    {index < itemStore.courierPriority.length - 1 ? (
                      <i
                        className="fas fa-chevron-down"
                        onClick={e =>
                          modifyPriority(index + 1, index, indexStore)
                        }
                      ></i>
                    ) : (
                      ""
                    )}
                  </div>
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <button
        className="add-button"
        style={{ marginTop: "-10px" }}
        onClick={() => submit()}
      >
        Submit
      </button>
    </div>
  );
}

export default AddZone;

import React from "react";

function NDRModal({ address, id, order }) {
  return (
    <div className="address-modal">
      <label htmlFor="exampleFormControlinput1">Name</label>
      <input
        className="reason-input"
        id={`name_${id}`}
        rows="1"
        defaultValue={
          order.shipping_address
            ? order.shipping_address.first_name !== ""
              ? order.shipping_address.first_name
              : order.shipping_address.last_name
            : "No Details"
        }
      />

      <label htmlFor="exampleFormControlinput1">Phone</label>
      <input
        className="reason-input"
        id={`phone_${id}`}
        rows="1"
        defaultValue={
          order.shipping_address
            ? order.shipping_address.phone
              ? order.shipping_address.phone
              : order.billing_address.phone
              ? order.billing_address.phone
              : "No Phone Number Found"
            : "No phone Number Found"
        }
      />

      <label htmlFor="exampleFormControlinput1">Address 1</label>
      <input
        className="reason-input"
        id={`address1_${id}`}
        rows="1"
        defaultValue={address.address1}
      />
      <label htmlFor="exampleFormControlinput1">Address 2</label>
      <input
        className="reason-input"
        id={`address2_${id}`}
        rows="1"
        defaultValue={address.address2}
      />
      <label htmlFor="exampleFormControlinput1">Company</label>
      <input
        className="reason-input"
        id={`company_${id}`}
        rows="1"
        defaultValue={address.company}
      />
      <label htmlFor="exampleFormControlinput1">City</label>
      <input
        className="reason-input"
        id={`city_${id}`}
        rows="1"
        defaultValue={address.city}
      />
      <label htmlFor="exampleFormControlinput1">State</label>
      <input
        className="reason-input"
        id={`province_${id}`}
        rows="1"
        defaultValue={address.province}
      />
      <label htmlFor="exampleFormControlinput1">Zipcode</label>
      <input
        className="reason-input"
        id={`zip_${id}`}
        rows="1"
        defaultValue={address.zip}
      />
    </div>
  );
}

export default NDRModal;

import React, { Fragment, useState, useEffect } from "react";
import useDatePicker from "../../components/global/DatePicker";

export default function FilterBar({
  products,
  filterOrders,
  clearFilters,
  searchOrders,
  selectOrders,
  setDate,
  store,
  issues,
}) {
  const [selectMode, setSelectMode] = useState(false);
  const [show, setShow] = useState(false);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");

  useEffect(() => {
    setDate(fromDate, toDate);
  }, [fromDate, toDate]);

  return (
    <Fragment>
      {show ? (
        <div className="ops-filterBar-container">
          <Fragment>
            <div className="ops-ToolBar">
              <button
                className="operations-select-button"
                type="button"
                onClick={() => {
                  selectOrders(!selectMode);
                  setSelectMode(!selectMode);
                }}
              >
                {!selectMode ? (
                  <i className="fas fa-check-circle"></i>
                ) : (
                  <i className="fas fa-times-circle"></i>
                )}
                {!selectMode ? " Select All" : " Deselect All"}
              </button>
            </div>
            <div className="ops-filterBar">
              <div
                className="ops-filterBar-component filterBar-filters"
                style={{ marginTop: 40 }}
              >
                <DatePicker />
              </div>
            </div>

            <div className="ops-filterBar">
              {/* PRODUCTS / ISSUES / SEARCH */}

              <div className="ops-filterBar-component filterBar-filters">
                {products.length > 0 ? (
                  <select id="filterBar-product">
                    <option>Products</option>
                    {products.map((product) => (
                      <option>{product}</option>
                    ))}
                  </select>
                ) : (
                  ""
                )}
                <select id="filterBar-reason">
                  <option>Reasons</option>
                  <option>AWB is already assigned</option>
                  <option>Cannot reassign courier</option>
                  <option>Unknown ProductDimensions</option>
                  <option>Unknown </option>
                  <option>Error with Decision Engine, Schedule Manually</option>
                  <option>Request failed with status code 504</option>
                  <option>Out Of Stock</option>
                  <option>Pincode not serviceable</option>
                  <option>
                    Product not recognized, Please reimport the product/s from
                    Shopify
                  </option>
                  <option></option>
                  {issues.map((issue) =>
                    typeof issue !== "object" ? <option>{issue}</option> : ""
                  )}
                  {/* <option>Others</option> */}
                </select>
                <div
                  className="ops-filterBar-tools"
                  onClick={() => filterOrders()}
                >
                  <i
                    className="fas fa-search"
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
                <div
                  className="ops-filterBar-tools"
                  onClick={() => {
                    clearDate();
                    clearFilters();
                  }}
                >
                  <i
                    className="far fa-trash-alt"
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
              </div>
              <div className="ops-filterBar-component filterBar-search">
                <input
                  type="text"
                  placeholder="search"
                  style={{ height: "100%" }}
                  onChange={(e) => searchOrders(e.target.value)}
                />
              </div>
            </div>
          </Fragment>
        </div>
      ) : (
        ""
      )}
      <div className="ops-filterBar-toggle" onClick={() => setShow(!show)}>
        <center>
          {show ? (
            <i class="fas fa-chevron-circle-up"></i>
          ) : (
            <i class="fas fa-chevron-circle-down"></i>
          )}
        </center>
      </div>
    </Fragment>
  );
}

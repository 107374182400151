import Axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import NumberCard from "../../global/NumberCard";
import { GlobalConsumer } from "../../../Global-Context/global-context";

export default function Statistics() {
  const [stats, setStats] = useState(null);
  const [previous, setPrevious] = useState(null);
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);

  const build = process.env.REACT_APP_LAST_BUILT;

  const getData = async () => {
    const result = await Axios.get("/api/orders/getPrecompiledData");
    setStats(result.data.yesterday);
    setPrevious(result.data.thedaybefore);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="statshome">
      {stats && previous && user.role !== "support" && !user.customer ? (
        <div>
          <div
            title={new Date(build).toLocaleString()}
            style={{
              padding: "0px 35px",
              color: "#0000009c",
              fontWeight: "bold",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() =>
              (user.master || user.role === "developer") &&
              window.open("https://github.com/teambazingamedia/jarvis", true)
            }
          >
            <center>YESTERDAY'S REPORT</center>
          </div>

          <div className="statsbar">
            <NumberCard
              title={"Orders Received"}
              previous={previous.totalOrdersYesterday}
              number={stats.totalOrdersYesterday}
            />
            <NumberCard
              title={"Shipped"}
              previous={previous.shippedCount}
              number={stats.shippedCount}
            />
            <NumberCard
              title={"Processing"}
              previous={previous.processingCount}
              number={stats.processingCount}
              inverted
            />
            <NumberCard
              title={"Pending Pickups"}
              previous={previous.pickupPending}
              number={stats.pickupPending}
              inverted
            />
            <NumberCard
              title={"Orders At The Operations Dashboard"}
              previous={previous.ordersAtOps}
              number={stats.ordersAtOps}
              inverted
            />
            <NumberCard
              title={"Errors"}
              previous={previous.errors}
              number={stats.errors}
              inverted
            />
            <NumberCard
              title={"Cancelled"}
              previous={previous.cancelled}
              number={stats.cancelled}
              inverted
            />
            <NumberCard
              title={"Flagged"}
              previous={previous.flagged}
              number={stats.flagged}
              inverted
            />

            <NumberCard
              title={"Abandoned Checkouts"}
              previous={previous.abandonedCheckouts}
              number={stats.abandonedCheckouts}
              inverted
            />
            <NumberCard
              title={`Best Store Volume - ${stats.bestStore.name}`}
              previous={previous.bestStore.count}
              number={stats.bestStore.count}
            />
            <NumberCard
              title={`Best Aggregator Volume - ${stats.bestAggregator.name}`}
              previous={previous.bestStore.count}
              number={stats.bestAggregator.count}
            />
            <NumberCard
              title={`Best Product Volume - ${stats.bestProduct.name}`}
              previous={previous.bestProduct.count}
              number={stats.bestProduct.count}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export const checkProducts = (order, productList) => {
  let bool = order.line_items.map(product => {
    if (productList.includes(product.product_id.toString())) {
      return "found";
    }
  });

  if (bool.includes("found")) return true;
  else return false;
};

import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Search = ({ onSearch, reset, searchRef, download }) => {
  return (
    <Stack direction={"row"} columnGap={2} mt={2}>
      <TextField
        fullWidth
        id="outlined-basic"
        label="Dimension name"
        variant="filled"
        size="medium"
        inputRef={searchRef}
      />
      <Button size="large" onClick={onSearch} variant="outlined">
        Search
      </Button>
      <Button size="large" onClick={reset} variant="outlined">
        Reset
      </Button>
      <Button size="large" onClick={download} variant="outlined">
        <FileDownloadIcon />
      </Button>
    </Stack>
  );
};

export default Search;

import React, { useState, useEffect } from "react";
import axios from "axios";

function ComboForm({ count, inventory }) {
  return (
    <div style={{ display: "flex" }}>
      <input
        type="text"
        list="comboList"
        className="form-control"
        style={{ width: "90%" }}
        id={`combo_input_${count}`}
        placeholder={`Product Name`}
        autoComplete="off"
      />
      <input
        style={{ width: "10%" }}
        type="number"
        className="form-control"
        id={`combo_input_quantity_${count}`}
        placeholder={"Quantity"}
        autoComplete="off"
      />
      <datalist id="comboList">
        {inventory.map((product, i) => (
          <option value={product.id} key={i}>
            {product.name}
          </option>
        ))}
      </datalist>
    </div>
  );
}

export default ComboForm;

import React from "react";

const ScannedFails = ({ failScans }) => {
  return (
    <div className="scan-awb-not-successful">
      <h4>FAIL SCANS - {failScans.length}</h4>
      <div className="fail-scan-container">
        <div>
          {/* <h5>AWB</h5> */}
          {failScans.map((fail) => (
            <div className="fail-item">{fail.awb}</div>
          ))}
        </div>
        <div>
          {/* <h5>Message</h5> */}
          {failScans.map((fail) => (
            <div>{fail.message}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScannedFails;

import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import useProductPicker from "../../global/ProductPicker";
import useStoreButton from "../../global/StoreButton";

function AggregatorItem({ aggregator, getAggregators, user }) {
  const [edit, setEdit] = useState(false);
  const [newTarget, setNewTarget] = useState(aggregator.target);
  const [newCourierID, setCourierID] = useState(aggregator.courierID);
  const [newCourierName, setCourierName] = useState(aggregator.courierName);
  const [newFakeDimensions, setNewFakeDimensions] = useState(false);
  const [shippingCostBreakDown, setShippingCostBreakDown] = useState(false);
  const [
    ProductPicker,
    ProductDisplay,
    products,
    setProducts,
  ] = useProductPicker();
  const [shadowfaxAwbCounts, setShadowfaxAwbCounts] = useState(null);

  const [StoreButton, , selected, setSelected] = useStoreButton({
    ignoreDeleted: true,
    multipleMode: true,
  });

  const editAggregators = async (data) => {
    let payload = {
      name: aggregator.name,
      active: aggregator.active,
      master: aggregator.master,
      products,
      scheduled: aggregator.scheduled,
      fakeDimensions: newFakeDimensions,
      target: aggregator.target,
      stores: selected,
    };
    if (aggregator.name === "ShipRocket") {
      payload = {
        name: aggregator.name,
        active: aggregator.active,
        master: aggregator.master,
        products,
        scheduled: aggregator.scheduled,
        fakeDimensions: newFakeDimensions,
        shippingCostBreakDown,
        target: aggregator.target,
        stores: selected,
      };
    }

    if (newCourierID) payload.courierID = newCourierID;
    if (newCourierName) payload.courierName = newCourierName;

    payload = { ...payload, ...data };

    await Axios.post("/api/control/updateAggregator", payload);
    getAggregators();
  };

  const fetchShadowfaxAwbCounts = async () => {
    const { data } = await Axios.get("/api/shadowfax/awbs/awbsCount");
    setShadowfaxAwbCounts({
      unusedAwbCount: data.unusedAwbCount,
      usedAwbCount: data.usedAwbCount,
    });
  };

  useEffect(() => {
    if (aggregator.products) setProducts(aggregator.products);
    if (aggregator.allowedStores) {
      setSelected(aggregator.allowedStores);
    }
    if (aggregator.name === "ShadowFax") {
      fetchShadowfaxAwbCounts();
    }
  }, []);

  return (
    <div className="aggregator">
      <div
        className={`aggregator-head`}
        style={aggregator.active ? {} : { background: "#e74c3c" }}
      >
        <div style={{ display: "flex" }}>
          {aggregator.master ? (
            <span className="aggregator-crown">
              {" "}
              <i className="fas fa-crown"></i>
            </span>
          ) : (
            ""
          )}
          <div>{aggregator.name} </div>
          <div className="aggregator-options">
            {edit ? (
              <i
                className="fas fa-check-circle aggregator-op"
                onClick={() => {
                  setEdit(!edit);
                  editAggregators({ target: newTarget });
                }}
              ></i>
            ) : (
              <i
                className="fas fa-pencil-alt aggregator-op"
                onClick={() => setEdit(!edit)}
              ></i>
            )}
            {aggregator.active ? (
              <i
                className="fas fa-toggle-on aggregator-op"
                onClick={() => editAggregators({ active: false })}
              ></i>
            ) : (
              <i
                className="fas fa-toggle-off aggregator-op"
                onClick={() => editAggregators({ active: true })}
              ></i>
            )}
          </div>
        </div>
      </div>
      <div className="aggregator-body">
        {aggregator.courier_ID ? (
          <p>
            CourierID :{" "}
            {edit ? (
              <input
                type="number"
                defaultValue={aggregator.courier_ID}
                className="aggregator-input"
                onChange={(e) => setCourierID(e.target.value)}
              />
            ) : (
              aggregator.courier_ID
            )}
          </p>
        ) : (
          ""
        )}
        {aggregator.courierName ? (
          <p>
            Courier Name :{" "}
            {edit ? (
              <input
                type="text"
                defaultValue={aggregator.courierName}
                className="aggregator-input"
                onChange={(e) => setCourierName(e.target.value)}
              />
            ) : (
              aggregator.courierName
            )}
          </p>
        ) : (
          ""
        )}
        <p>
          Target :{" "}
          {edit ? (
            <input
              type="number"
              defaultValue={aggregator.target}
              className="aggregator-input"
              onChange={(e) => setNewTarget(e.target.value)}
            />
          ) : (
            aggregator.target
          )}
        </p>
        <p>Scheduled : {aggregator.scheduled}</p>
        {!user.customer ? (
          <p>
            Fake Dimensions :{" "}
            {edit ? (
              <select
                id="fake-dimensions-select"
                onChange={(e) => {
                  e.target.value === "Enabled"
                    ? setNewFakeDimensions(true)
                    : setNewFakeDimensions(false);
                }}
              >
                <option select>Enable / Disable fake dimensions</option>
                <option value="Enabled">Enabled</option>
                <option value="Disabled">Disabled</option>
              </select>
            ) : aggregator.fakeDimensions ? (
              "Enabled"
            ) : (
              "Disabled"
            )}
          </p>
        ) : (
          ""
        )}
        {aggregator.name === "ShipRocket" ? (
          <p>
            Obtain Shipping Cost Breakdown :{" "}
            {edit ? (
              <select
                id="shipping-cost-breakdown-select"
                onChange={(e) => {
                  e.target.value === "Enabled"
                    ? setShippingCostBreakDown(true)
                    : setShippingCostBreakDown(false);
                }}
              >
                <option select>Enable / Disable shipping cost breakdown</option>
                <option value="Enabled">Enabled</option>
                <option value="Disabled">Disabled</option>
              </select>
            ) : aggregator.shippingCostBreakDown ? (
              "Enabled"
            ) : (
              "Disabled"
            )}
          </p>
        ) : (
          ""
        )}
        {aggregator.name === "ShadowFax" && shadowfaxAwbCounts ? (
          <>
            <p>Unused Awbs : {shadowfaxAwbCounts.unusedAwbCount}</p>
            <p>Used Awbs : {shadowfaxAwbCounts.usedAwbCount}</p>
          </>
        ) : null}
        {edit ? (
          <Fragment>
            <StoreButton
              customStoreStyle={{
                height: "50px",
                marginBottom: "20px",
              }}
            />
            <ProductPicker
              customProductSelectionStyle={{
                height: "50px",
                marginBottom: "20px",
              }}
            />
          </Fragment>
        ) : (
          ""
        )}
        {aggregator.allowedStores ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {aggregator.allowedStores.map((store) =>
              store ? (
                <div
                  className="itemTag"
                  style={{ width: "fit-content" }}
                  id={`tag_${store}`}
                >
                  {store.storeName}
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : (
          ""
        )}
        {selected.length > 0 && products.length > 0 ? (
          <hr style={{ borderTop: "1px solid #e8e8e8" }} />
        ) : (
          ""
        )}
        <ProductDisplay />
      </div>
    </div>
  );
}

export default AggregatorItem;

// name:"Test"
// active:false
// master:false
// products:['3331630235748']
// scheduled:0
// target:22

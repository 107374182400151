import React, { Fragment } from "react";

const Variant = ({ variant }) => {
  return (
    <Fragment>
      {variant.title === "Default Title" ? "" : variant.title}
    </Fragment>
  );
};

export default Variant;

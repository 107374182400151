import React, { useState, Fragment } from "react";
import Axios from "axios";

const DisputeItem = ({ dispute, inventory, fetchInventory }) => {
  document.title = "Dispute Board";
  // const [details, setDetails] = useState({})
  const [batches, setBatches] = useState({});

  const submit = async (name, details) => {
    let inventory_id = JSON.parse(
      document.getElementById(`inventoryDropdown_${name}`).value
    ).id;
    let batch = document.getElementById(`batchDropdown_${name}`).value;
    let operation = document.getElementById(`operationDropdown_${name}`).value;
    console.log(`id: ${inventory_id}, batch: ${batch}`);

    await Axios.post(`/api/IMS/resolve/${inventory_id}/${batch}/${operation}`, {
      details
    });
    fetchInventory();
  };

  const fetchBatches = name => {
    let inv = JSON.parse(
      document.getElementById(`inventoryDropdown_${name}`).value
    );
    console.log("HERE", inv);
    let batches = [];
    batches = inv
      ? Object.keys(inv).filter(key => {
          if (key.startsWith("batch_")) return true;
        })
      : "";
    console.log(batches);
    setBatches(batches);
  };

  // const fetchInventoryDetails = async (dispute) => {
  //     let result = await Axios.post('/api/inventory/getInventoryFor', { dispute })
  //     let batches = []

  //     if (result) {

  //         batches = result.data.details.length > 0 ? Object.keys(result.data.details[0]).filter(key => {
  //             if (key.startsWith("batch_")) return true;
  //         }) : '';
  //         console.log("details", result.data.details)
  //         setDetails(result.data.details)
  //         setBatches(batches)
  //     }

  //     console.log(batches)

  // }

  // useEffect(() => {
  //     fetchInventoryDetails(dispute)
  // }, [])
  return dispute ? (
    <div className="card dispute_card">
      <div className="card-header">
        {" "}
        {`Order Number: ${dispute.name} from ${dispute.store.shopifyName}`}
      </div>
      <div className="card-body">
        {dispute.line_items.map((item, i) => (
          <div key={i}>
            {item.inventory ? (
              <div>
                <p>Already Batch Tagged</p>
              </div>
            ) : (
              <Fragment>
                <p>{`(*) Name: ${item.name}`}</p>
                <p>{`Quantity: ${item.quantity}`}</p>
                <p>
                  {item.batch ? `Batch: ${item.batch}` : "No Batch alloted"}
                </p>
                <p>{`Remarks: ${dispute.disputeRemarks}`}</p>
                <div className="form-group">
                  <label htmlFor="inventoryDropdown">
                    Choose Inventory Item
                  </label>
                  <select
                    className="form-control"
                    id={`inventoryDropdown_${item.name}`}
                    onChange={() => fetchBatches(item.name)}
                  >
                    <option key={i} defaultValue disabled>
                      Inventory Item
                    </option>
                    {inventory.map((inventory_item, i) => (
                      <option key={i} value={JSON.stringify(inventory_item)}>
                        {inventory_item.name}
                      </option>
                    ))}
                  </select>
                </div>
                {batches.length > 0 ? (
                  <Fragment>
                    <div className="form-group">
                      <label htmlFor="batchDropdown">Choose Batch</label>
                      <select
                        className="form-control"
                        id={`batchDropdown_${item.name}`}
                      >
                        {batches.map((batch, i) => (
                          <option key={i}>{batch}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="operationDropdown">
                        Choose Operation
                      </label>
                      <select
                        className="form-control"
                        id={`operationDropdown_${item.name}`}
                      >
                        <option value="decrease">DECREASE</option>
                        <option value="increase">INCREASE</option>
                      </select>
                    </div>
                    <button
                      type="button"
                      className="btn btn-success btn-product-add"
                      onClick={() =>
                        submit(item.name, {
                          item_id: dispute.line_items[i].id,
                          order_name: dispute.name,
                          item_name: item.name,
                          products: dispute.line_items,
                          position: i,
                          store: dispute.store
                        })
                      }
                      style={{ margin: 0 }}
                    >
                      Submit
                    </button>
                  </Fragment>
                ) : (
                  ""
                )}
              </Fragment>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default DisputeItem;

import React from "react";
import { Redirect, Route } from "react-router-dom";

import Cryptr from "cryptr";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const getUserDetails = (props) => {
    let info = JSON.parse(localStorage.info);

    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));

    if (details.master || details.access.includes(props.location.pathname))
      return true;
    else return false;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") && getUserDetails(props) ? (
          <Component {...props} />
        ) : localStorage.getItem("user") ? (
          <Redirect to="/" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;

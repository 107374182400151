import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import Spinner from "../res/Spinner";
import useDatePicker from "../../global/DatePicker";

const FlagsModule = ({ number, active, setActive }) => {
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [previous, setPrevious] = useState({});
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");

  const initializaModule = async () => {
    if (!store) {
      alert("Please select a store!");
      return;
    }
    setLoading(true);
    const result = await Axios.post("/api/stats/flagModule", {
      store,
      fromDate,
      toDate,
    });
    setPrevious({
      store,
      fromDate,
      toDate,
    });
    setLoading(false);
    setdata(result.data.data);
  };

  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };

  useEffect(() => {
    getStores();
    return () => clearDate();
  }, []);

  return (
    <Fragment>
      {active === number ? (
        <center>
          <p style={{ borderBottom: "1px solid black", width: "100px" }}>
            {"Flag Module (Needs to be checked once)"}
          </p>
        </center>
      ) : (
        ""
      )}
      <div
        id={`module_${number}`}
        className={`card moduleCard ${
          active === number ? "activeModuleCard" : ""
        }`}
        style={{ marginBottom: "30px" }}
        onClick={(e) => setActive(number)}
      >
        {active === number ? (
          <Fragment>
            {loading ? (
              <div style={{ margin: "20px" }}>
                <Spinner />
              </div>
            ) : (
              <div className="moduleContent">
                <div className="moduleBar">
                  <select
                    placeholder="Stores"
                    id="store_selector"
                    className="form-control form-control-sm"
                    style={{
                      height: "50px",
                      fontSize: "0.9rem",
                      borderRadius: 0,
                    }}
                    onChange={(e) => {
                      setStore(JSON.parse(e.target.value));
                    }}
                  >
                    <option value={JSON.stringify(store)} selected disabled>
                      Choose a store
                    </option>
                    {stores.map((store, index) => (
                      <option value={JSON.stringify(store)} key={index}>
                        {store.storeName}
                      </option>
                    ))}
                  </select>

                  <div style={{ height: "50px" }}>
                    <DatePicker />
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ borderRadius: 0 }}
                    onClick={() => initializaModule(fromDate, toDate)}
                  >
                    Submit
                  </button>
                </div>
                {data ? (
                  <div className="flag-module-filter-info">
                    {`Displaying orders between ${new Date(
                      fromDate
                    ).toLocaleDateString()} & ${new Date(
                      toDate
                    ).toLocaleDateString()} `}
                  </div>
                ) : null}
                <div className="moduleTable">
                  {data ? (
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          {Object.keys(data.all).map((column, i) => (
                            <th scope="col">{column}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(data).map((row, index) => (
                          <tr className={index == 0 ? "thead-light" : ""}>
                            <td scope="col">{row == "all" ? "*" : row}</td>
                            <td scope="col">
                              <div className="flag-breakdown-field">
                                {data[row].flagged.total}

                                <ul className="striped-list flag-breakdown">
                                  {Object.keys(data[row].flagged).map(
                                    (flaggedField, i) => {
                                      if (i == 4) {
                                        return;
                                      } else {
                                        return (
                                          <li className="flag-breakdown-list-item">
                                            <div>{flaggedField}</div>
                                            <div>
                                              {": " +
                                                data[row].flagged[flaggedField]}
                                            </div>
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                                </ul>
                              </div>
                            </td>
                            <td scope="col">{data[row].total}</td>
                            <td>{data[row].percentage + " %"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="moduleInactive">
            <p>{"Flag Module"}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default FlagsModule;

import React, { useState, useEffect } from "react";
import axios from "axios";

const ExperimentItem = ({ experiment }) => {
  const [active, setActive] = useState(experiment.active);
  const [edit, setEdit] = useState(false);

  const flagMap = {
    callCustomer: "Address Issue",
    multipleItems: "Multiple Items",
    multipleQuantity: "Multiple Quantity",
    repeatCustomer: "Repeat Customer",
  };

  const handletoggle = () => {
    setActive(!active);
  };

  const handleEdit = () => {
    if (
      experiment.active ||
      experiment.experimentSetCount + experiment.normalSetCount !==
        experiment.numberOfOrders
    ) {
      alert("Experiment already active. Not possible to edit!");
      return;
    }
    setEdit(!edit);
    console.log("You can edit the experiment");
  };

  const handleDelete = async () => {
    const response = await axios.post(
      `/api/experiment/delete/${experiment._id}`
    );
    alert(response.data.message);
  };

  return (
    <div className="exp">
      <div>{experiment.name.toUpperCase()}</div>
      <div>
        <div>
          <div style={{ marginBottom: "10px" }}>
            TARGET ({experiment.target})
          </div>
          <div class="progress">
            <div
              class="progress-bar progress-bar-success"
              role="progressbar"
              style={{
                backgroundColor: "#2980b9",
                width: `${Math.abs(
                  (experiment.currentCount / experiment.target) * 100
                )}%`,
              }}
            >
              {experiment.currentCount}
            </div>
          </div>{" "}
          <div style={{ margin: "10px 0px" }}>
            DAILY TARGET ({experiment.numberOfOrders})
          </div>
          <div class="progress">
            <div
              class="progress-bar progress-bar-success"
              role="progressbar"
              style={{
                backgroundColor: "#2980b9",
                position: "relative",
                width: `${Math.abs(
                  ((experiment.numberOfOrders / 2 -
                    experiment.experimentSetCount) /
                    experiment.numberOfOrders) *
                    100
                )}%`,
              }}
            >
              <div style={{ position: "absolute" }}>
                E (
                {experiment.numberOfOrders / 2 - experiment.experimentSetCount}){" "}
              </div>
            </div>
            <div
              class="progress-bar progress-bar-warning"
              role="progressbar"
              style={{
                position: "relative",
                backgroundColor: "#16a085",
                width: `${Math.abs(
                  ((experiment.numberOfOrders / 2 - experiment.normalSetCount) /
                    experiment.numberOfOrders) *
                    100
                )}%`,
              }}
            >
              <div style={{ position: "absolute" }}>
                C ({experiment.numberOfOrders / 2 - experiment.normalSetCount})
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <cetner>
          <i class="fab fa-galactic-republic"></i>
          {"   "}
          {experiment.actions}
        </cetner>
      </div>
      {experiment.conditions.forward ? (
        <div>
          <div>
            {experiment.conditions.forward.store
              ? experiment.conditions.forward.store.map((store) => (
                  <div
                    className="tag-item"
                    style={{ marginLeft: "0px", marginRight: "10px" }}
                  >
                    <span>{store.storeName}</span>
                  </div>
                ))
              : ""}
          </div>
          <div style={{ marginTop: "10px" }}>
            {experiment.conditions.forward.products
              ? experiment.conditions.forward.products.map((product) => (
                  <div
                    className="tag-item"
                    style={{ marginLeft: "0px", marginRight: "10px" }}
                  >
                    <span>{product.title}</span>
                  </div>
                ))
              : ""}
          </div>
          <div style={{ marginTop: "10px" }}>
            {experiment.conditions.forward.flags
              ? experiment.conditions.forward.flags.map((flag) => (
                  <div
                    className="tag-item"
                    style={{ marginLeft: 0, marginRight: "10px" }}
                  >
                    <span>{flagMap[flag]}</span>
                  </div>
                ))
              : ""}
          </div>
          {experiment.conditions.reverse ? (
            <div style={{ marginTop: "10px" }}>
              {experiment.conditions.reverse.aggregators
                ? experiment.conditions.reverse.aggregators.map(
                    (aggregator) => (
                      <div
                        className="tag-item"
                        style={{ marginLeft: 0, marginRight: "10px" }}
                      >
                        <span>{aggregator}</span>
                      </div>
                    )
                  )
                : ""}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ExperimentItem;

import React, { useState, useEffect, useRef, Fragment } from "react";
import moment from "moment";
import useDialerLinks from "../../src/components/global/DialerLinks";

export default function DialerItem({ call, ngrokLink, index }) {
  const [openLink, ready, streamLink] = useDialerLinks(call, ngrokLink);

  const audioRef = useRef(null);

  return (
    <Fragment>
      <div className="dialer-item">
        {/* <div className="dialer-item-controls">
        <div className="dialer-item-button">
          <div onClick={() => openLink("stream")}>
            <i class="fas fa-redo"></i>
          </div>
        </div>
        <div className="dialer-item-button">
          <div onClick={() => openLink("download")}>
            <i class="fas fa-arrow-down"></i>
          </div>
        </div>
      </div> */}
        <div className="dialer-item-content">
          <div
            className="dialer-item-header-info"
            style={{ position: "relative" }}
          >
            <div>{call.order}</div>
            {/* <OrderModal
              order={call.order}
              position={{ top: "2px", left: "-10px" }}
              cursor={{ color: "#bdc3c7" }}
            /> */}
          </div>

          {/* ADD LEAD ID / LIST ID / KEY */}
          <div className="dialer-item-body">
            <div className="dialer-item-body-details">
              <div className="dialer-item-body-name">
                <i class="fas fa-headphones"></i> {call.name}
              </div>
              <div>{call.remarks}</div>
              <div style={{ marginTop: "10px" }}>{call.phone}</div>
            </div>
            <div className="dialer-item-body-hr"></div>
            <div className="dialer-item-body-date">
              <i class="far fa-calendar-alt"></i>{" "}
              {moment(call.date).format("Do MMMM YYYY, h:mm:ss a")}
            </div>
          </div>
        </div>
      </div>
      <div className="dialer-foot ">
        {call.GDriveLink ? (
          <iframe
            frameborder="0"
            width="100%"
            height="55"
            src={`${call.GDriveLink.split("/view")[0]}/preview`}
          ></iframe>
        ) : streamLink && ready ? (
          <Fragment>
            <div
              onClick={() => openLink("download")}
              style={{
                padding: "5px",
                paddingLeft: "15px",
                background: "#F3F3F3",
                color: "#a79d9d",
                display: "flex",
                alignItems: "center",
                fontSize: "0.9rem",
                cursor: "pointer",
              }}
              className="dialer-util-button"
              data-tooltip="Download GSM File"
            >
              <i class="fas fa-arrow-down"></i>
            </div>
            <audio
              controls
              id={`play-audio-${call.order}-${call.name}-${index}`}
              style={{ width: "100%", background: "#f3f3f3", padding: "10px" }}
              ref={audioRef}
            >
              <source src={streamLink} type="audio/wav" />
            </audio>
          </Fragment>
        ) : (
          <Fragment>
            {" "}
            <div
              onClick={() => openLink("download")}
              style={{
                padding: "5px",
                paddingLeft: "15px",
                background: "#F3F3F3",
                color: "#a79d9d",
                display: "flex",
                alignItems: "center",
                fontSize: "0.9rem",
                cursor: "pointer",
              }}
              className="dialer-util-button"
              data-tooltip="Download GSM File"
            >
              <i class="fas fa-arrow-down"></i>
            </div>
            <div
              onClick={() =>
                openLink(
                  "stream",
                  `play-audio-${call.order}-${call.name}-${index}`
                )
              }
              style={{
                background: "rgb(243, 243, 243)",
                color: "rgb(167, 157, 157)",
                display: "flex",
                alignItems: "center",
                fontSize: "0.9rem",
                cursor: "pointer",
                position: "absolute",
                height: "50px",
                left: "80px",
                zIndex: "100",
              }}
              className="dialer-util-button"
            >
              <i class="fas fa-play"></i>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

import React from "react";

const Modal = ({ show, setShow, title, content }) => {
  const modalClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={modalClassName}>
      <div className="modal-main1">
        <div className="modal-main1-header">
          <h4>{title}</h4>
          <span onClick={() => setShow()}>
            <i class="fas fa-times"></i>
          </span>
        </div>
        <div className="modal-main1-body">{content}</div>
      </div>
    </div>
  );
};

export default Modal;

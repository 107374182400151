import React from "react";

const CircularProgressBarForCourier = ({
  performance,
  sqSize,
  strokeWidth,
  color,
}) => {
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * parseFloat(performance)) / 5;
  if (performance < 3) {
    color = "#C81912";
  } else if (performance >= 3 && performance < 4) {
    color = "#FEB72B";
  } else {
    color = "#21BF73";
  }

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          stroke: color,
        }}
      />
      <text
        className="circle-text-courier"
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        style={{
          fill: color,
        }}
      >
        {`${performance}`}
      </text>
    </svg>
  );
};

CircularProgressBarForCourier.defaultProps = {
  sqSize: 200,
  performance: 2.5,
  strokeWidth: 10,
};

export default CircularProgressBarForCourier;

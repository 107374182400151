import React, { useState, useEffect } from "react";
import EditLead from "./EditLead";

const CustomerLead = ({
  customer,
  customerIndex,
  setLeads,
  editIndex,
  setEditIndex,
}) => {
  const [edit, setEdit] = useState(false);
  const [editProperty, setEditProperty] = useState(null);

  const handleEdit = (e) => {
    if (e.target.id.includes("name")) {
      setEditProperty("name");
    } else if (e.target.id.includes("email")) {
      setEditProperty("email");
    } else if (e.target.id.includes("phone")) {
      setEditProperty("phone");
    }
    setEdit(true);
    setEditIndex(customerIndex);
  };

  return (
    <tr id={`cutomer-${customerIndex}`}>
      <td className="serial-no-column">{customerIndex + 1 + "."}</td>
      {edit && editProperty === "name" && editIndex === customerIndex ? (
        <EditLead
          customer={customer}
          customerIndex={customerIndex}
          setEdit={setEdit}
          setLeads={setLeads}
          editProperty={editProperty}
        />
      ) : (
        <td onDoubleClick={handleEdit} id={`name-${customerIndex}`}>
          {customer.name}
        </td>
      )}
      {edit && editProperty === "email" && editIndex === customerIndex ? (
        <EditLead
          customer={customer}
          customerIndex={customerIndex}
          setEdit={setEdit}
          setLeads={setLeads}
          editProperty={editProperty}
        />
      ) : (
        <td onDoubleClick={handleEdit} id={`email-${customerIndex}`}>
          {customer.email}
        </td>
      )}
      {edit && editProperty === "phone" && editIndex === customerIndex ? (
        <EditLead
          customer={customer}
          customerIndex={customerIndex}
          setEdit={setEdit}
          setLeads={setLeads}
          editProperty={editProperty}
        />
      ) : (
        <td onDoubleClick={handleEdit} id={`phone-${customerIndex}`}>
          {customer.phone}
        </td>
      )}
    </tr>
  );
};

export default CustomerLead;

import React, { useState, Fragment } from "react";

export default function AddressModal({ order, editAddress, setShowAddress }) {
  const inputs = [
    { title: "First Name", property: "first_name" },
    { title: "Last Name", property: "last_name" },
    { title: "Email", property: "email" },
    { title: "Company", property: "company" },
    { title: "Address 1", property: "address1" },
    { title: "Address 2", property: "address2" },
    { title: "Phone", property: "phone" },
    { title: "City", property: "city" },
    { title: "Zip", property: "zip" },
  ];
  const countries = ["India"];
  const states = [
    "Andra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telagana",
    "Tripura",
    "Uttaranchal",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadar and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadeep",
    "Pondicherry",
  ];
  const copyAddress = (reverse) => {
    let from = "shipping";
    let to = "billing";

    if (reverse) {
      from = "billing";
      to = "shipping";
    }

    inputs.map((item) => {
      document.getElementById(
        `${order.name}-${to}-${item.property}`
      ).value = document.getElementById(
        `${order.name}-${from}-${item.property}`
      ).value;
    });
    document.getElementById(
      `${order.name}-${to}-state`
    ).value = document.getElementById(`${order.name}-${from}-state`).value;
    document.getElementById(
      `${order.name}-${to}-country`
    ).value = document.getElementById(`${order.name}-${from}-country`).value;
  };

  return (
    <Fragment>
      <div className="ops-modal-blur"></div>
      <div className="ops-address-modal">
        <div className="ops-address-modal-head">
          <div>{order.name}</div>
          <div
            style={{
              marginLeft: "auto",
              padding: "0px 10px",
              display: "flex",
            }}
          >
            <div
              data-tooltip="Billing to Shipping"
              className="ops-address-copy"
              style={{ marginRight: "20px", cursor: "pointer" }}
              onClick={() => copyAddress(true)}
            >
              <i className="fas fa-copy"></i>
              <i className="fas fa-arrow-right"></i>
            </div>
            <div
              data-tooltip="Shipping to Billing"
              className="ops-address-copy"
              style={{ marginRight: "20px", cursor: "pointer" }}
              onClick={() => copyAddress()}
            >
              <i className="fas fa-arrow-left"></i>
              <i className="fas fa-copy"></i>
            </div>

            <div style={{ cursor: "pointer" }}>
              <i
                className="fas far fa-times"
                onClick={() => setShowAddress(false)}
              ></i>
            </div>
          </div>
        </div>
        <div className="ops-address-modal-body">
          <div className="ops-address-modal-billing" style={{ width: "100%" }}>
            <div style={{ padding: "10px" }}>Billing</div>
            {inputs.map((item) => (
              <div
                className="ops-address-modal-tooltip"
                data-label={`Billing ${item.title}`}
              >
                <input
                  type="text"
                  id={`${order.name}-billing-${item.property}`}
                  defaultValue={
                    order["billing_address"]
                      ? order["billing_address"][item.property]
                        ? order["billing_address"][item.property]
                        : ""
                      : ""
                  }
                  placeholder={item.title}
                />
              </div>
            ))}
            <div
              className="ops-address-modal-tooltip"
              data-label="Billing State"
            >
              <select
                style={{
                  textAlign: "center",
                  marginRight: "0px",
                  height: "40px",
                  // border: "4px solid #bdc3c7",
                }}
                type="text"
                placeholder="Please enter a state"
                className="utilityBar-search"
                id={`${order.name}-billing-state`}
              >
                <option
                  selected={order.billing_address ? false : true}
                  disabled
                >
                  Please Choose A State
                </option>
                {states.map((s) => (
                  <option
                    value={s}
                    selected={
                      order.billing_address
                        ? order.billing_address.province == s
                        : false
                    }
                  >
                    {s}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="ops-address-modal-tooltip"
              data-label="Billing Country"
            >
              <select
                style={{
                  textAlign: "center",
                  marginRight: "0px",
                  height: "40px",
                  // border: "4px solid #bdc3c7",
                }}
                type="text"
                placeholder="Please enter a country"
                className="utilityBar-search"
                id={`${order.name}-billing-country`}
              >
                <option
                  selected={order.billing_address ? false : true}
                  disabled
                >
                  Please Choose A Country
                </option>
                {countries.map((s) => (
                  <option
                    value={s}
                    selected={
                      order.billing_address
                        ? order.billing_address.country == s
                        : false
                    }
                  >
                    {s}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="ops-address-modal-shipping" style={{ width: "100%" }}>
            <div style={{ padding: "10px" }}>Shipping</div>
            {inputs.map((item) => (
              <div
                className="ops-address-modal-tooltip"
                data-label={`Shipping ${item.title}`}
              >
                <input
                  type="text"
                  id={`${order.name}-shipping-${item.property}`}
                  defaultValue={
                    order["shipping_address"]
                      ? order["shipping_address"][item.property]
                        ? order["shipping_address"][item.property]
                        : ""
                      : ""
                  }
                  placeholder={item.title}
                />
              </div>
            ))}

            <div
              className="ops-address-modal-tooltip"
              data-label="Shipping State"
            >
              <select
                style={{
                  textAlign: "center",
                  marginRight: "0px",
                  height: "40px",
                }}
                type="text"
                placeholder="Please enter a state"
                className="utilityBar-search"
                id={`${order.name}-shipping-state`}
              >
                <option
                  selected={order.shipping_address ? false : true}
                  disabled
                >
                  Please Choose A State
                </option>
                {states.map((s) => (
                  <option
                    value={s}
                    selected={
                      order.shipping_address
                        ? order.shipping_address.province == s
                        : false
                    }
                  >
                    {s}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="ops-address-modal-tooltip"
              data-label="Shipping Country"
            >
              <select
                style={{
                  textAlign: "center",
                  marginRight: "0px",
                  height: "40px",
                }}
                type="text"
                placeholder="Please enter a country"
                className="utilityBar-search"
                id={`${order.name}-shipping-country`}
              >
                <option
                  selected={order.shipping_address ? false : true}
                  disabled
                >
                  Please Choose A Country
                </option>
                {countries.map((s) => (
                  <option
                    value={s}
                    selected={
                      order.shipping_address
                        ? order.shipping_address.country == s
                        : false
                    }
                  >
                    {s}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="ops-address-modal-footer" onClick={() => editAddress()}>
          Submit
        </div>
      </div>
    </Fragment>
  );
}

import axios from "axios";
import React, { useState, useEffect } from "react";
import useAlert from "../../global/Alert";
import useDatePicker from "../../global/DatePicker";
import StoreDropdownButton from "../../operations/res/StoreDropdownButton";
import loadingSRC from "../res/loading.gif";

const RepeatCustomerStats = () => {
  document.title = "Repeat Purchase Calculator";
  const classNamePrefix = "repeat-customer-stats";
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [AlertBox, setNotification] = useAlert();
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState(null);
  const [customerIndex, setCustomerIndex] = useState(null);
  const [uniqueCustomers, setUniqueCustomers] = useState([]);
  const [repeatCustomers, setRepeatCustomers] = useState([]);
  const [repeatPercentage, setRepeatPercentage] = useState(null);

  const getRepeatCustomerStats = async () => {
    if (!store) {
      setNotification("failure", "Please select a store!");
      return;
    }
    const payload = {
      fromDate,
      toDate,
      store,
    };
    setLoading(true);
    const { data } = await axios.post(
      "/api/stats/repeatPurchaseCalculator",
      payload
    );
    setLoading(false);
    if (!data.status) {
      setNotification("failure", "Something went wrong! Contact developers!");
    }
    console.log(data);
    const {
      customerIndex,
      uniqueCustomers,
      repeatCustomers,
      repeatPercentage,
    } = data;
    setCustomerIndex(customerIndex);
    setUniqueCustomers(uniqueCustomers);
    setRepeatCustomers(repeatCustomers);
    setRepeatPercentage(repeatPercentage);
  };

  const reset = () => {
    setStore(null);
    clearDate();
    setLoading(false);
    setCustomerIndex(null);
    setUniqueCustomers([]);
    setRepeatCustomers([]);
    setRepeatPercentage(null);
    setNotification("success", "Reset Successfull!");
  };

  return (
    <div>
      <AlertBox />
      <div className={`${classNamePrefix}-filters`}>
        <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
          <StoreDropdownButton
            store={store}
            setStore={setStore}
            showDeleted={false}
          />
        </div>
        <DatePicker />
        <button onClick={reset} className="btn btn-danger" disabled={loading}>
          Reset
        </button>
        <button
          onClick={getRepeatCustomerStats}
          className="btn btn-danger"
          disabled={loading}
        >
          Submit
        </button>
      </div>
      {loading ? (
        <>
          <AlertBox />
          <div style={{ marginTop: "20px" }}>
            <center>
              <img src={loadingSRC} height="300px" width="400px" />
            </center>
          </div>
        </>
      ) : customerIndex ? (
        <>
          <div className={`${classNamePrefix}-stats`}>
            <div className={`${classNamePrefix}-filtering-info`}>
              {`Filtering ${store.storeName} orders from ${new Date(
                fromDate
              ).toLocaleString()} to ${new Date(toDate).toLocaleString()}`}
            </div>
            <div>UNIQUE CUSTOMERS - {uniqueCustomers.length}</div>
            <div>REPEAT CUSTOMERS - {repeatCustomers.length}</div>
            <div>REPEAT PERCENTAGE - {repeatPercentage}%</div>
          </div>
          {uniqueCustomers.length > 0 ? (
            <div className={`${classNamePrefix}-unique-customer-table`}>
              <div
                className="ordersCard"
                style={{
                  overflowY: "scroll",
                  position: "relative",
                  height: "50vh",
                }}
              >
                <table
                  className="table ordersTable"
                  style={{ overflowY: "scroll" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">Index</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(uniqueCustomers).map((customer, index) => (
                      <tr
                        id={`${classNamePrefix}-${customer.phone}`}
                        className="tracking-row"
                      >
                        <td>{index + 1}</td>
                        <td>{customer.name}</td>
                        <td>{customer.phone}</td>
                        <td>{customer.email}</td>
                        <td>{customer.orders.join(", ")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default RepeatCustomerStats;

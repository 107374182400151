import React, { useState } from "react";
import Axios from "axios";
import { getCourierName } from "../../../trackingboard/OrderTracker/functions/getCourierName";
import ScannedCourierCompanies from "../res/ScannedCourierCompanies";
import ScannedFails from "../res/ScannedFails";
import Modal from "../res/Modal";
let audio = new Audio(require("../res/sounds/tink.wav"));

function ScanAndShipAWB() {
  document.title = "Scan AWB And Ship";
  const [awb, setAwb] = useState({});
  const [testAwb, setTestAwb] = useState({});
  const [testResult, setTestResult] = useState(null);
  const [result, setResult] = useState({});
  const [scannedCount, setScannedCount] = useState(0);
  const [failScans, setFailScans] = useState([]);
  const [couriers, setCouriers] = useState({});
  const [showModal, setShowModal] = useState(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) handleSubmit();
  };

  const handleChange = (e) => {
    let awbItem = { AWBItem: e.target.value };
    setAwb(awbItem);
  };

  const testHandleChange = (e) => {
    setTestAwb({ AWBItem: e.target.value });
  };

  const testHandleKeyDown = (e) => {
    if (e.keyCode === 13) handleTestSubmit();
  };

  const handleTestSubmit = async () => {
    if (testAwb.AWBItem.length !== 0) {
      const response = await Axios.post(
        "/api/warehouse/awbScannedStatus",
        testAwb
      );
      setTestResult(response.data.message);
    }
  };

  const handleSubmit = async () => {
    if (awb.AWBItem.length !== 0) {
      const response = await Axios.post(`/api/warehouse/scanAWB`, awb);
      const {
        awb: responseAWB,
        msg,
        colourCode,
        courier_company_id,
      } = response.data;
      let textColour;
      if (colourCode == 1) {
        textColour = "#2ecc71";
        setScannedCount((scannedCount) => {
          return scannedCount + 1;
        });
        const { name } = getCourierName(courier_company_id);
        setCouriers((couriers) => {
          let index;
          couriers[name]
            ? (index = Object.keys(couriers[name]).length)
            : (index = 0);
          couriers[name] = { ...couriers[name], [index]: responseAWB };
          return couriers;
        });
      } else if (colourCode == 0) {
        textColour = "orange";
        const message = "Already Scanned";
        const fail = {
          awb: responseAWB,
          message,
        };
        setFailScans((failScans) => {
          return [...failScans, fail];
        });
      } else {
        textColour = "#e74c3c";
        let message;
        if (msg == "Please Specify AWB") {
          message = "Empty input";
        } else if (msg == "THE SHIPMENT IS CANCELLED. DO NOT DISPATCH IT!") {
          message = "Cancelled";
        } else if (msg.includes("NOT FOUND and is SAVED")) {
          message = "Missing AWB";
        } else {
          message = "Error";
        }
        const fail = {
          awb: responseAWB,
          message,
        };
        setFailScans((failScans) => {
          return [...failScans, fail];
        });
      }
      document.getElementById("result_msg").style.color = textColour;
      setResult({ message: msg });
      audio.play();
      setAwb({ AWBItem: "" });
    }
  };

  const modalContent = (
    <div>
      <input
        type="text"
        name="test_awb_no"
        id="test_awb_input"
        className="form-control"
        placeholder="Check scanned status of AWB here"
        value={testAwb.AWBItem}
        onChange={(e) => testHandleChange(e)}
        onKeyDown={(e) => testHandleKeyDown(e)}
      ></input>
      <div>
        <h4 id="test_result_msg">{testResult}</h4>
      </div>
    </div>
  );

  const setShow = () => {
    setShowModal(false);
    setTestAwb({ AWBItem: "" });
    setTestResult(null);
  };

  return (
    <div className="scan-awb-frontend">
      <Modal
        show={showModal}
        setShow={setShow}
        title="Check Scanned Status of AWB"
        content={modalContent}
      />
      <div
        className="scan-awb-header-container"
        style={{ background: "#2980b9" }}
      >
        <div className="scan-awb-header">
          <h2>Scan AWB And Ship</h2>
          <h5>Total scan count - {scannedCount + failScans.length}</h5>
        </div>
        <div className="scan-awb-scanned-status-btn">
          <button className="awb-custom-btn" onClick={() => setShowModal(true)}>
            Check Scanned Status
          </button>
        </div>
      </div>
      <div className="scan-awb-body">
        <input
          type="text"
          name="awb_no"
          id="awb_input"
          className="form-control form-control-lg"
          placeholder="Scan AWB to be shipped here"
          value={awb.AWBItem}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
        ></input>
        <div className="scan-awb-message">
          <h4 id="result_msg">{result.message}</h4>
        </div>
        {Object.keys(couriers).length == 0 ? null : (
          <ScannedCourierCompanies
            couriers={couriers}
            scannedCount={scannedCount}
          />
        )}
        {failScans.length == 0 ? null : <ScannedFails failScans={failScans} />}
      </div>
      <audio src="../res/sounds/tink.wav" id="scanned_sound"></audio>
    </div>
  );
}

export default ScanAndShipAWB;

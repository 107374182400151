import React, { useEffect, useState } from "react";
import Axios from "axios";

const Order = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    console.log(params.get("store"));
    const order = encodeURIComponent(params.get("order"));
    const store = params.get("store");
    Axios.get(
      `/api/globalDashboard/trackOrder?order=${order}&store=${store}`
    ).then(result => setMessage(result.data.message));
  }, []);
  return <div>{message}</div>;
};

export default Order;

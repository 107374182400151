import Axios from "axios";
import React, { useEffect, useState } from "react";
import useAlert from "../../global/Alert";

export default function SalarySlips() {
  const [file, setFile] = useState(null);
  const [AlertBox, setNotification] = useAlert();

  const uploadFile = async () => {
    if (!file) return setNotification("failure", "Please choose a file");

    const data = new FormData();
    data.append("file", file);
    console.log(file);

    let input = prompt(`Are you sure you want to upload this? (yes/no)`) || "";

    const fileName = file.name;

    console.log(fileName.slice(fileName.length - 3, fileName.length));

    if (fileName.slice(fileName.length - 3, fileName.length) !== "csv")
      return setNotification("failure", "Please upload a CSV only");

    if (input.toLowerCase() === "yes")
      await Axios.post("/api/upload/mailEmployeesPaySlips", data);
    else setNotification("failure", "Okay, try again when you're ready");
  };

  useEffect(() => {}, []);

  return (
    <div className="salary-uploader">
      <AlertBox />
      <input
        type="file"
        name="file"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <button onClick={() => uploadFile()}>Upload Salary Report</button>
    </div>
  );
}

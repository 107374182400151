import React, { useState } from "react";
import ComboForm from "../res/ComboForm";
import Axios from "axios";
function ComboMapping({ fetchProducts, list, inventory }) {
  document.title = "Map Combos";
  const [count, setCount] = useState(1);
  const [forms, setForms] = useState([
    <ComboForm count={count} inventory={inventory} />
  ]);

  const handleAddClick = () => {
    setCount(prevState => {
      return prevState + 1;
    });
    setForms(prevState => {
      return [
        ...prevState,
        <ComboForm count={count + 1} inventory={inventory} />
      ];
    });
  };

  const handleRemoveClick = () => {
    setCount(prevState => {
      return prevState - 1;
    });
    setForms(prevState => {
      prevState.pop();
      return [...prevState];
    });
  };

  const submitCombo = async () => {
    let products = [];
    let product = JSON.parse(document.getElementById("map_combo_input").value);
    console.log("count", count);
    console.log("Item", document.getElementById("map_combo_input").value);

    for (let i = 1; i <= count; i++) {
      let item = {
        id: document.getElementById(`combo_input_${i}`).value,
        product: {
          ...product,
          combo: true,
          quantity: parseInt(
            document.getElementById(`combo_input_quantity_${i}`).value
          )
        }
      };
      products.push(item);
    }
    let data = {
      inventory: products
    };
    console.log(data);
    await Axios.post("/api/IMS/mapCombo", { data });
    document.getElementById("map_combo_input").value = "";
    setCount(0);
    setForms([]);
    fetchProducts();
  };

  return (
    <div className="card configurationsCard IMS-order-card">
      <div style={{ padding: "20px" }}>
        <form>
          <label htmlFor={`map_bundles_input`}>Search by Shopify Name</label>
          <div className="form-group resourceForm">
            <input
              type="text"
              list="productlist"
              className="form-control"
              id={`map_combo_input`}
              placeholder={`Search Product Name`}
              autoComplete="off"
              //   onKeyDown={e => {
              //     if (e.keyCode === 13) map(e);
              //   }}
            />

            <datalist id="productlist">
              {list.map((product, i) =>
                product.variants.map((variant, i) => (
                  <option
                    value={`${JSON.stringify({
                      store: product.store,
                      name: `${product.title} - ${variant.title}`,
                      product_id: variant.product_id,
                      variant_id: variant.variant_id
                    })}`}
                    key={i}
                  >
                    {`${product.title} - ${variant.title}`}
                  </option>
                ))
              )}
            </datalist>
          </div>
        </form>
        {forms}
        <br />

        <div style={{ display: "flex" }}>
          <button
            type="button"
            className="btn btn-success  btn-product-add "
            style={{ marginLeft: "0px" }}
            onClick={() => handleAddClick()}
          >
            Add product
          </button>
          <button
            type="button"
            className="btn btn-danger  btn-product-rm "
            style={{ marginLeft: "10px" }}
            onClick={() => handleRemoveClick()}
          >
            Remove product
          </button>
        </div>
        <br />
        <button
          type="button"
          id={`map_combo_btn`}
          className="btn btn-success  btn-product-add "
          style={{ marginLeft: "0px", width: "100%" }}
          onClick={e => submitCombo(e)}
        >
          Map
        </button>
      </div>
    </div>
  );
}

export default ComboMapping;

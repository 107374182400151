import React from "react";
import DetailsBody from "./DetailsBody";

function StatsBody({ type, log }) {
  let chosen =
    type === "NDR"
      ? "NDR"
      : type === "Order Confirmation"
      ? "OrderConfirmation"
      : "AbandonedCheckout";

  return (
    <div>
      <div className="call-stats-type">{type}</div>
      <div className="call-stats-body">
        <p>Total: {log[chosen].Total}</p>
        <div className="tooltip-new">
          Answered: {log[chosen].Answered}
          <DetailsBody details={log[chosen].AnsweredBreakdown} />
        </div>

        <div className="tooltip-new">
          Unanswered: {log[chosen].Unanswered}
          <DetailsBody details={log[chosen].UnansweredBreakdown} />
        </div>
      </div>
    </div>
  );
}

export default StatsBody;

export const detectInvalidBarcode = (e) => {
  if (e.keyCode === 13) {
    console.log(`loaded detectInvalidBarcode.js file`);
    e.preventDefault();

    let barcodes = [];
    barcodes = document.getElementById("barcodes").value.split("\n");
    console.log("last barcode = ", barcodes[barcodes.length - 1]);
    if (
      barcodes[barcodes.length - 1].includes("%") ||
      barcodes[barcodes.length - 1].includes("-")
    ) {
      document.getElementById(
        `msg2`
      ).innerHTML = `<span class="scan-bar-badge badge-warning">Please scan the barcode again</span><br />`;
      document.getElementById(
        `msg3`
      ).innerHTML = `<span class="scan-bar-badge badge-warning">${
        barcodes[barcodes.length - 1]
      }</span><br />`;
      document.getElementById("barcodes").value = "";
      for (let i = 0; i < barcodes.length; i++) {
        console.log(barcodes[i]);
        if (i !== barcodes.length - 1)
          document.getElementById("barcodes").value += barcodes[i] + "\n";
      }
    } else {
      document.getElementById(`msg2`).innerHTML = "";
      document.getElementById(`msg3`).innerHTML = "";
      document.getElementById("barcodes").value += "\n";
    }
  }
};

import React, { Fragment, useState } from "react";
import { rejectedPackages } from "../functions/rejectedPackages";

const RejectedPackages = () => {
  document.title = "Scan Barcodes of Rejected Packages";
  const [courierCompany, setCourierCompany] = useState([]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-4 col-sm-4 col-md-4" />
          <div className="col-xs-4 col-sm-4 col-md-4">
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-header">
                  <h4>Select Courier Companies</h4>
                </div>
                <div className="card-body">
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        name="courierCompany"
                        value="All"
                        onClick={() => {
                          setCourierCompany("All");
                        }}
                        data-toggle="modal"
                        data-target="#myModal"
                      />
                      All Courier Companies
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4" />
        </div>
        <form id="modalForm">
          <div
            className="modal"
            id="myModal"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    Scan {courierCompany} RejectedPackages Barcodes
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={e => {
                      document.getElementById("barcodes").value = "";
                      document.getElementById("msg1").innerHTML = "";
                      document.getElementById("msg2").innerHTML = "";
                      document.getElementById("msg3").innerHTML = "";
                    }}
                  >
                    ×
                  </button>
                </div>

                <div
                  className="modal-body"
                  style={{ backgroundColor: "#ced6e0" }}
                >
                  <div id="msg1" />
                  <div id="msg2" />
                  <div id="msg3" />
                  <div className="form-group">
                    <label htmlFor="barcodes">{courierCompany} Barcodes:</label>
                    <textarea
                      className="form-control"
                      style={{ overflowY: "scroll", height: "400px" }}
                      id="barcodes"
                      name="barcodes"
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={e => {
                      document.getElementById("barcodes").value = "";
                      document.getElementById("msg1").innerHTML = "";
                      document.getElementById("msg2").innerHTML = "";
                      document.getElementById("msg3").innerHTML = "";
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={e => rejectedPackages(e, courierCompany)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default RejectedPackages;

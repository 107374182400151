import React, { createContext } from "react";
import axios from "axios";

//Creating context of available global states
const UserContext = createContext({
  orders: [],
  user: {},
  customers: [],
  isAuthenticated: false,
  products: [],
  suppliers: [],
  transporters: [],
  filtered: [],
  fetchOrders: () => {},
  authenticate: () => {},
  filterOrders: () => {},
  signOut: () => {},
  boot: () => {},
  getUser: () => {}
});

export class UserProvider extends React.Component {
  getUser = () => {
    let user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    else return false;
  };

  signOut = () => {
    localStorage.removeItem("user");
    this.setState({ user: {} });
    this.setState({ isAuthenticated: false });
  };
  authenticate = user => {
    console.log("auth", user);
    if (user !== "") {
      localStorage.setItem("user", JSON.stringify(user));
      this.setState({ user });
      this.setState({ isAuthenticated: true });
    }
  };

  sortOrders = orders => {
    orders.data.sort(function(a, b) {
      let nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA > nameB) return -1;
      if (nameA < nameB) return 1;
      return 0;
    });
    return orders;
  };

  filterOrders = (type, value) => {
    let filtered = this.state[type].filter(item => item.name.startsWith(value));
    this.setState({ filtered });
    // document.getElementById("supplierSpan").innerHTML = filtered[0];
  };

  fetchOrders = async () => {
    let result = await axios.get(`/api/inventory/fetch`);
    let auth = await axios.get("/api/auth/users/customers");

    this.setState({ loading: true });
    global.products = result.data.products;
    global.orders = result.data.orders;
    global.suppliers = result.data.suppliers;

    this.setState({ orders: result.data.orders });
    this.setState({ customers: auth.data.users });
    this.setState({ products: result.data.products });
    this.setState({ suppliers: result.data.suppliers });
    this.setState({ transporters: result.data.transporters });
    this.setState({ loading: false });

    console.log("Fetched Data", result.data);
  };

  //State variables
  state = {
    orders: [],
    user: {},
    isAuthenticated: false,
    products: [],
    customers: [],
    suppliers: [],
    transporters: [],
    filtered: [],
    fetchOrders: this.fetchOrders,
    filterOrders: this.filterOrders,
    authenticate: this.authenticate,
    signOut: this.signOut,
    boot: this.boot,
    getUser: this.getUser
  };

  // componentDidMount = () => {
  //   this.boot();
  // };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

//Registering a consumer who can access the global state
export const UserConsumer = UserContext;

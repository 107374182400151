import axios from "axios";
export const recordState = async (type, payload, id) => {
  let data = {
    type,
    payload,
    id
  };
  console.log(data);
  let res = await axios({
    method: "post",
    url: "/api/inventory/record",
    data
  });
  console.log("GGG", res.data);
  return res;
};

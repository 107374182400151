import React, { useEffect, useState, Fragment, useContext } from "react";
import NavBar from "../res/NavBar";
import { scheduleOrdersManually } from "../functions/scheduleOrdersManually";
import { removeOrdersFromDashboard } from "../functions/scheduleOrdersManually";
import Order from "../res/Order";
import Axios from "axios";
import { GlobalConsumer } from "../../../Global-Context/global-context";

const ScheduleOrdersManually = () => {
  const [orders, setOrders] = useState([]);
  const [store, setStore] = useState("start");
  const [inventory, setInventory] = useState([]);

  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user, setUser] = useState(userInfo);

  document.title = "Schedule Multiple Orders Manually";
  const fetchOrders = store => {
    Axios.get(
      `/api/operations/scheduleOrdersManually?store=${JSON.stringify(store)}`
    )
      .then(result => {
        setOrders(result.data.ordersList);
        console.log(result.data.ordersList);
      })
      .catch(error => console.log("failed to fetch orders", store));
  };

  const fetchInventory = async store => {
    let res = await Axios.get(`/api/IMS/getInventoryInStock`);
    console.log(res.data.inventory);
    setInventory(res.data.inventory);
  };

  useEffect(() => {
    fetchOrders(store);
    fetchInventory();
  }, []);

  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} fetchOrders={fetchOrders} />
      {store !== "start" ? (
        <div className="container-fluid">
          <form>
            <br />
            <div className="container-fluid">
              <div>
                <p id="scheduledOrdersList" />
                <p id="unscheduledOrdersList" />
              </div>
              <div className="shadow-lg p-3 mb-5 bg-white rounded">
                <div className="card text-center">
                  <div className="card-header">
                    <h4>Schedule Multiple Orders</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Order ID</th>
                            <th scope="col">Product Details</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Callers Remarks</th>
                            <th scope="col">Shipment Failure Reason</th>
                            <th scope="col">
                              Product Dimensions And Actual Weight
                            </th>
                            <th scope="col">Schedule?</th>
                            <th scope="col">Remove?</th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order, index) => (
                            <Order
                              key={index + order.name + order.id}
                              order={order}
                              store={store}
                              inventory={inventory}
                              index={index}
                              scheduleOrdersManually={scheduleOrdersManually}
                              fetchOrders={fetchOrders}
                              removeOrdersFromDashboard={
                                removeOrdersFromDashboard
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="row"> */}
                    {/* <button
                        type="submit"
                        id="RemoveOrderButton"
                        className="btn btn-primary btn-block btn-lg"
                        style={{ backgroundColor: "#ff3838" }}
                        onClick={e =>
                          removeOrdersFromDashboard(e, fetchOrders, store)
                        }
                      >
                        Remove Order From Dashboard
                      </button> */}
                    {/* </div> */}
                    {/* <div className="row"> */}
                    {/* <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg"
                        style={{ backgroundColor: "#ff3838" }}
                        onClick={e =>
                          scheduleOrdersManually(
                            e,
                            fetchOrders,
                            store,
                            "shiprocket"
                          )
                        }
                      >
                        SHIPROCKET
                      </button> */}
                    {/* <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg"
                        style={{ backgroundColor: "#ff3838" }}
                        onClick={e =>
                          scheduleOrdersManually(e, fetchOrders, store, "ecom")
                        }
                      >
                        ECOM EXPRESS
                      </button> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <button
            type="button"
            id={`inventory_creator`}
            style={{ background: "#FF3838" }}
            className="btn btn-success  btn-product-add-ops btn-inventory-creator-ops"
            onClick={e =>
              scheduleOrdersManually(e, fetchOrders, store, "shiprocket", user)
            }
          >
            Shiprocket
          </button>{" "}
          <button
            type="button"
            id={`inventory_creator`}
            style={{ background: "#FF3838", right: "225px" }}
            className="btn btn-success  btn-product-add-ops btn-inventory-creator-ops"
            onClick={e =>
              scheduleOrdersManually(e, fetchOrders, store, "clickpost", user)
            }
          >
            Clickpost
          </button>{" "}
          <button
            type="button"
            id={`inventory_creator`}
            style={{ background: "#FF3838", right: "400px" }}
            className="btn btn-success  btn-product-add-ops btn-inventory-creator-ops"
            onClick={e =>
              scheduleOrdersManually(e, fetchOrders, store, "goswift", user)
            }
          >
            GoSwift
          </button>{" "}
          <button
            type="button"
            id={`inventory_creator`}
            style={{ background: "#FF3838", right: "575px" }}
            className="btn btn-success  btn-product-add-ops btn-inventory-creator-ops"
            onClick={e =>
              removeOrdersFromDashboard(e, fetchOrders, store, user)
            }
          >
            Remove
          </button>{" "}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>Choose a store</h2>
        </div>
      )}
    </Fragment>
  );
};

export default ScheduleOrdersManually;

import React, { useState, useEffect } from "react";
import axios from "axios";
import StoreDropdownButton from "../../operations/res/StoreDropdownButton";
import ReusableFormRow from "../res/ReusableFormRow";
import "../res/StoreExpenses.css";

const fields = [
  {
    label: "Store Setup Cost",
    required: false,
    name: "storeSetupCost",
    placeHolder: "Store Setup Cost",
  },
  {
    label: "Packaging Cost",
    required: true,
    name: "packagingCost",
    placeHolder: "Packaging Cost",
  },
  {
    label: "Other Expenses Made",
    required: true,
    name: "otherExpensesMade",
    placeHolder: "Other Expenses Made",
  },
  {
    label: "GST",
    required: true,
    name: "GST",
    placeHolder: "GST",
  },
  {
    label: "Customer Refunds",
    required: true,
    name: "customerRefunds",
    placeHolder: "Customer Refunds",
  },
  {
    label: "Labour and rent cost to ship out the product",
    required: true,
    name: "labourAndRent",
    placeHolder: "Labour and rent cost to ship out the product",
  },
  {
    label: "Main Office Overhead",
    required: true,
    name: "mainOfficeOverhead",
    placeHolder: "Main Office Overhead",
  },
  // {
  //   label: "Profits after taxes",
  //   required: true,
  //   name: "profitsAfterTaxes",
  //   placeHolder: "Profits after taxes",
  // },
];

function StoreExpensesMainForm({ setPage, user }) {
  document.title = "Store Expenses";

  // state of the component
  const [store, setStore] = useState(null);
  const [formState, setFormState] = useState({
    storeSetupCost: "",
    packagingCost: "",
    otherExpensesMade: "",
    GST: "",
    customerRefunds: "",
    labourAndRent: "",
    mainOfficeOverhead: "",
    // profitsAfterTaxes: "",
  });
  const [errors, setErrors] = useState({});

  // submit handler
  const handleSubmit = async () => {
    console.log(formState);
    let validForm = true;
    for (const error in errors) {
      if (errors[error].length > 0) {
        validForm = false;
        break;
      }
    }
    if (!validForm) {
      alert(`Not a valid form! Please check again and submit!`);
      return;
    }
    if (!store) {
      alert(`Please select a store!`);
      return;
    }
    console.log(`Final form state`, formState);
    // else sent request to backend
    const expenses = [];
    for (const expense in formState) {
      if (!formState[expense]) continue;
      expenses.push({
        type: expense,
        amount: formState[expense],
        expense: expense === "profitsAfterTaxes" ? false : true, // shows that this is an expense and not a profit
      });
    }
    if (expenses.length === 0) {
      alert(`Please input expenditures to save!`);
      return;
    }
    const payload = {
      user: user.name,
      store,
      expenses,
    };
    console.log(payload);
    let result;
    try {
      result = await axios.post("/api/stores/expenditure", payload);
    } catch (error) {
      alert(`Something went wrong! Contact developers!`);
      return;
    }
    alert(`Inserted expenditures successfully!`);
  };

  const handleReset = () => {
    setFormState({
      storeSetupCost: "",
      packagingCost: "",
      otherExpensesMade: "",
      GST: "",
      customerRefunds: "",
      labourAndRent: "",
      mainOfficeOverhead: "",
      profitsAfterTaxes: "",
    });
    setStore(null);
    setErrors({});
  };

  // created my custom handle change function and pass into reusable form field
  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    if (!isFloatingPointNumber(e.target.value)) {
      setErrors({ ...errors, [e.target.name]: `Enter a valid number!` });
      return;
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const isFloatingPointNumber = (value) => {
    const floatingPointNumberRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;
    return floatingPointNumberRegex.test(value);
  };

  return (
    <div id="store-expenses-container">
      <div id="store-expenses-form">
        <div id="store-expenses-store-selector">
          <StoreDropdownButton
            setStore={setStore}
            store={store}
            showDeleted={false}
          />
        </div>
        {fields.map((field) => (
          <ReusableFormRow
            fieldValues={field}
            handleChange={handleChange}
            errors={errors}
            value={formState[field.name]}
          />
        ))}
        <div id="store-expenses-submit-container">
          <button className="store-expenses-button" onClick={handleSubmit}>
            Submit
          </button>
          <button
            className="store-expenses-button"
            onClick={() => setPage(null)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default StoreExpensesMainForm;

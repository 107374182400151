import React, { useState, useContext, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { register } from "../functions/register";
import setPassword from "../functions/setPassword.js";
import { GlobalConsumer } from "../../Global-Context/global-context";
import Axios from "axios";
import AccessOption from "./AccessOption";
import CallingRoles from "../utils/CallingRoles";
import { types } from "../../res/callTypes";

const Register = () => {
  document.title = "Register";
  const context = useContext(GlobalConsumer);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    agentID: "",
  });
  const [calls, setCalls] = useState(types);
  const [roleTrigger, setRoleTrigger] = useState("");
  const [accessList, setAccessList] = useState([]);
  // const [permissions, setPermissions] = useState([]);

  const { name, email, role, agentID } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (event) => {
    let user = await register({
      name,
      email,
      role,
      permissions: accessList,
      calls,
      active: false,
      agentID,
    });
    const response = await setPassword(email.trim());
    // alert(response);
    console.log(response);
  };

  const fetchAPIs = async () => {
    let res = await Axios.get("/api/auth/accessListV2");
    setAccessList(res.data.list);
  };

  const handleValueChange = (e, option) => {
    option.value = e.target.checked;
    let array = accessList.map((access) => {
      if (access.name === option.name) access = option;
      return access;
    });
    setAccessList(array);
  };

  const toggleAccess = (e) => {
    // let temp = [];
    setRoleTrigger(e.target.value);
    let array = accessList.map((access) => {
      if (e.target.value === "administrator" || e.target.value === "developer")
        access.value = true;
      else {
        if (access.department === e.target.value) access.value = true;
        else access.value = false;
      }
      if (access.name === "Home") access.value = true;

      // temp.push({ name: access.name, link: access.link, value: access.value });

      // setPermissions(temp);
      return access;
    });

    setAccessList(array);
  };

  useEffect(() => {
    fetchAPIs();
  }, []);

  return (
    <div>
      <div className="register-container">
        <div className="register">
          <h1 className="large">Register</h1>
          <p className="lead">
            <i className="fas fa-user" /> Register A User
          </p>
          <form className="form">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Name"
                name="name"
                value={name}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => onChange(e)}
                name="email"
              />
            </div>
            <div className="form-group">
              <select
                className="form-control"
                id="role"
                onChange={(e) => toggleAccess(e)}
              >
                <option selected disabled>
                  Role
                </option>
                <option>warehouse</option>
                <option>developer</option>
                <option>support</option>
                <option>operations</option>
                <option>administrator</option>
              </select>
            </div>
            {!roleTrigger ? (
              <div className="settingOption accessOption">
                <div className="settingTool">
                  <div className="settingTitle">Customer</div>
                </div>
                <label className="custom-toggle access-toggle">
                  <input id="customer_toggle" type="checkbox" />
                  <span className="custom-toggle-slider rounded-circle access-toggle-slider" />
                </label>
              </div>
            ) : roleTrigger === "support" ? (
              <Fragment>
                <div className="form-group">
                  <input
                    id=""
                    className="form-control"
                    type="text"
                    placeholder="Dialer Agent ID"
                    value={agentID}
                    onChange={(e) => onChange(e)}
                    name="agentID"
                  />
                </div>
                <CallingRoles calls={calls} setCalls={setCalls} />
              </Fragment>
            ) : (
              ""
            )}
            <div className="settingOption accessOption">
              <div className="settingTool">
                <div className="settingTitle">
                  Managing Privileges On Certain Dashboards
                </div>
              </div>
              <label className="custom-toggle access-toggle">
                <input id="managing_toggle" type="checkbox" />
                <span className="custom-toggle-slider rounded-circle access-toggle-slider" />
              </label>
            </div>
            <div className="access-list">
              <h5>Access Control</h5>
              {accessList.map((access, i) => (
                <AccessOption
                  option={access}
                  key={i}
                  id={access.name}
                  handleValueChange={handleValueChange}
                />
              ))}
            </div>
            <input
              type="button"
              style={{ marginBottom: "20px" }}
              className="btn btn-primary submitBtn"
              value="Register"
              onClick={() => onSubmit()}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React, { useState } from "react";

function InventoryItem({
  variant,
  product,
  checked,
  mapped,
  setMapped,
  active
}) {
  active !== "normal"
    ? active === "rules"
      ? (document.title = "Set Rules")
      : (document.title = "Map Bundles")
    : (document.title = "Map Inventory");
  const [show, setShow] = useState(checked);

  let store = product.store;
  const handleShow = e => {
    if (e.target.value) setShow(true);
    else setShow(false);
  };
  const handleCheck = e => {
    let temp = [...mapped];
    if (e.target.checked) {
      // push

      let element = JSON.parse(e.target.id);
      if (active !== "normal" && active !== "rules" && active !== "flag") {
        element = {
          ...element,
          ...{
            [active]: true,
            quantity: parseInt(
              document.getElementById(
                `quantity_${element.product_id}+${element.variant_id}`
              ).value
            )
          }
        };
      }

      temp.push(element);
      setMapped(temp);
    } else {
      // pop
      document.getElementById(
        `quantity_${variant.product_id}+${variant.variant_id}`
      ).value = "";

      let element = JSON.parse(e.target.id);

      temp = mapped.filter(
        product => product.variant_id !== element.variant_id
      );

      setMapped(temp);
      setShow(false);
    }
  };

  return (
    <div
      className="card"
      style={{ margin: "20px 20px", border: "1px solid black" }}
    >
      <div className="card-header" style={{ display: "flex" }}>
        <div>
          {`${
            product.store.storeName
              ? product.store.storeName
              : "Map Store Object "
          } - ${product.title.toUpperCase()} - ${
            variant.title === "Default Title"
              ? "(No Variants)"
              : variant.title.toUpperCase()
          }`}
        </div>
        {active !== "normal" && active !== "rules" && active !== "flag" ? (
          show ? (
            <div className="checkbox" style={{ margin: "2px 20px" }}>
              <label>
                <input
                  type="checkbox"
                  id={`${JSON.stringify({
                    store: product.store,
                    name: `${product.title} - ${variant.title}`,
                    product_id: variant.product_id,
                    variant_id: variant.variant_id
                  })}`}
                  checked={checked}
                  className={"inventory_checkbox"}
                  onChange={e => handleCheck(e)}
                />
              </label>
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="checkbox" style={{ margin: "2px 20px" }}>
            <label>
              <input
                type="checkbox"
                id={`${JSON.stringify({
                  store: product.store,
                  name: `${product.title} - ${variant.title}`,
                  product_id: variant.product_id,
                  variant_id: variant.variant_id
                })}`}
                checked={checked}
                className={"inventory_checkbox"}
                onChange={e => handleCheck(e)}
              />
            </label>
          </div>
        )}
      </div>
      <div className="card-body">
        <div style={{ display: "flex" }}>
          <p>Product ID: </p>
          <p className="card-text">{variant.product_id}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p>Variant ID: </p>
          <p className="card-text">{variant.variant_id}</p>
        </div>
        {active !== "normal" && active !== "rules" && active !== "flag" ? (
          <div className="form-group">
            <label style={{ marginRight: "10px" }}>Quantity</label>
            <input
              type="number"
              className="form-control"
              id={`quantity_${variant.product_id}+${variant.variant_id}`}
              onChange={e => handleShow(e)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default InventoryItem;

import React, { Fragment } from "react";
import Variant from "../res/Variant";
import VariantForm from "../res/VariantForm";
import FakeVariantForm from "../res/FakeVariantForm";
const Product = ({
  product,
  index,
  store,
  fetchProducts,
  currentPageNumber
}) => {
  let serialNumber = 0;
  if (currentPageNumber > 1) serialNumber = (currentPageNumber - 1) * 10;
  return (
    <tr>
      <td>{index + 1 + serialNumber}</td>
      <td>{product.title}</td>
      <td>
        {product.variants.map(variant => (
          <Fragment>
            <Variant
              key={index + "variant.variant_id"}
              variant={variant}
              product_id={product.product_id}
            />
            <div style={{ display: "flex" }}>
              <VariantForm
                key={`form_${variant.variant_id}`}
                formId={`form_${variant.variant_id}`}
                variant={variant}
                store={store}
                fetchProducts={fetchProducts}
              />
              <FakeVariantForm
                key={`fake_form_${variant.variant_id}`}
                formId={`fake_form_${variant.variant_id}`}
                variant={variant}
                store={store}
                fetchProducts={fetchProducts}
              />
            </div>
          </Fragment>
        ))}
      </td>
    </tr>
  );
};

export default Product;

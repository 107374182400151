import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import useDatePicker from "../components/global/DatePicker";
import useProductPicker from "../components/global/ProductPicker";
import DialerItem from "./DialerItem";
import loadingSRC from "./loading.gif";

export default function DialerBoard() {
  const [dispositions, setDispositions] = useState({});
  const [users, setUsers] = useState([]);
  const [calls, setCalls] = useState([]);
  const [ngrokLink, setNgrokLink] = useState("");
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [loading, setLoading] = useState(false);
  const [linkBarCSS, setLinkBarCSS] = useState(false);
  const [
    ProductPicker,
    ProductDisplay,
    products,
    ,
    ,
    clearProducts,
    getProductArray,
  ] = useProductPicker();
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);
  const [count, setCount] = useState(0);
  const [initial, setInitial] = useState(true);

  const links = [
    {
      description: "Main dialer home screen",
      link: ngrokLink,
      faIcon: "fas fa-home",
    },
    {
      description: "List exporting section",
      link: "/export_list/",
      faIcon: "far fa-list-alt",
    },
    {
      description: "Shows what agents are doing in real-time",
      link: "/vicidial/realtime_report.php",
      faIcon: "fas fa-chart-line",
    },
    {
      description:
        "Shows number of calls pending in each campaign in real-time",
      link: "/vicidial/AST_timeonVDADallSUMMARY.php",
      faIcon: "fas fa-phone",
    },
    {
      description: "Shows agent's timings and number of calls",
      link: "/vicidial/AST_agent_performance_detail.php",
      faIcon: "fas fa-tachometer-alt",
    },
    {
      description: "Shows campaings",
      link: "/vicidial/admin.php?ADD=10",
      faIcon: "fas fa-book",
    },
    {
      description: "Shows global dispositions",
      link: "/vicidial/admin.php?ADD=321111111111111",
      faIcon: "fas fa-tasks",
    },
    {
      description: "Goes to agent login screen",
      link: "/agc/vicidial.php",
      faIcon: "fas fa-user-secret",
    },
    {
      description: "Goes to in-house agent performance dashboard",
      link: "/support/dialerstats",
      faIcon: "fas fa-calculator",
      inhouse: true,
    },
    {
      description: "Goes to in-house inbound call reports dashboard",
      link: "/support/inboundCallReport",
      faIcon: "fas fa-angle-double-left",
      inhouse: true,
    },
  ];

  const dispoRef = useRef(null);
  const userRef = useRef(null);
  const searchRef = useRef(null);

  const getDispositions = async () => {
    const result = await Axios.get("/api/support/dispositions");
    setDispositions(result.data.dispoMap);
  };

  const getUsers = async () => {
    const result = await Axios.get("/api/auth/users/support");
    setUsers(result.data.users);
  };

  const searchRecordings = async () => {
    setLoading(true);

    const result = await Axios.post("/api/support/getRecordings", {
      input: searchRef.current.value,
    });
    const {
      data: { logs, ngrokLink, pages, final },
    } = result;

    setCount(final || 0);
    setTotal(pages || 1);
    setLoading(false);
    setCalls(logs);
    setNgrokLink(ngrokLink);
  };

  const filterRecordings = async (initial) => {
    const disposition = dispoRef.current ? dispoRef.current.value : null;
    const user = userRef.current ? userRef.current.value : null;
    const date =
      document.getElementById("datePickerRange") &&
      document.getElementById("datePickerRange").value !==
        "Choose a date range";

    if (
      disposition === "Choose a disposition" &&
      user === "Choose an agent" &&
      products.length <= 0 &&
      !date
    ) {
      initial = true;
      setInitial(true);
    }

    setLoading(true);
    const result = await Axios.post("/api/support/getRecordings", {
      products: getProductArray("v"),
      user,
      disposition,
      fromDate,
      toDate,
      initial,
      offset: current,
    });

    if (!initial) setInitial(false);

    const {
      data: { logs, ngrokLink, pages, final },
    } = result;

    setCount(final || 0);
    setTotal(pages || 1);
    setLoading(false);
    setCalls(logs);
    setNgrokLink(ngrokLink);
  };

  const clearData = () => {
    clearDate();
    clearProducts();
    userRef.current.value = "Choose an agent";
    dispoRef.current.value = "Choose a disposition";
    filterRecordings(true);
    setInitial(true);
  };

  useEffect(() => {
    getDispositions();
    getUsers();
    filterRecordings(true);
  }, []);

  useEffect(() => {
    filterRecordings();
  }, [current]);

  return (
    <div className="dialer">
      <div className="dialer-head">
        <div className="dialer-head-utility">
          <div className="dialer-links-search">
            <input
              ref={searchRef}
              placeholder="Search..."
              onKeyDown={(e) => e.key === "Enter" && searchRecordings()}
            />
          </div>
          <div
            className="dialer-links-dropdown"
            onClick={() => setLinkBarCSS(!linkBarCSS)}
          >
            LINKS <i class="fas fa-bars"></i>
          </div>
        </div>
        <div
          className={`dialer-link-bar  ${
            linkBarCSS ? "show-dialer-links" : "hide-dialer-links"
          }`}
        >
          {links.map((link) => (
            <div
              style={{ marginRight: "10px", border: "1px solid #2980B9" }}
              className="dialer-item-utility-button"
              onClick={() => {
                if (!link.inhouse)
                  window.open(
                    `${!link.link.includes("ngrok") ? ngrokLink : ""}${
                      link.link
                    }`
                  );
                else window.open(link.link);
              }}
            >
              <i class={link.faIcon} data-desc={link.description}></i>
            </div>
          ))}
        </div>
        <ProductDisplay />
        <div className="dialer-filters">
          <DatePicker />
          <ProductPicker />
          <select
            id="user-select"
            ref={userRef}
            className="form-control form-control-sm defaultInput"
          >
            <option defaultSelected>Choose an agent</option>
            {users.map((user) => (
              <option value={user.user_id}>{user.name}</option>
            ))}
          </select>
          <select
            id="dispo-select"
            ref={dispoRef}
            className="form-control form-control-sm defaultInput"
          >
            <option defaultSelected>Choose a disposition</option>
            {Object.keys(dispositions).map((dispo) => (
              <option value={dispositions[dispo]}>{dispositions[dispo]}</option>
            ))}
          </select>
        </div>
        <div className="dialer-utility-bar">
          <div className="dialer-utility-tools">
            <div
              className="dialer-item-utility-button"
              onClick={() => {
                filterRecordings();
              }}
            >
              <i class="fas fa-search"></i>
            </div>

            <div
              className="dialer-item-utility-button"
              onClick={() => {
                clearData();
              }}
            >
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>
        {!initial ? (
          <div>
            <center>
              <div class="pagination">
                <a
                  className={`${current}`}
                  onClick={(e) => setCurrent(current - 1)}
                >
                  &laquo;
                </a>
                {current - 1 > 0 ? (
                  <a onClick={(e) => setCurrent(current - 1)}>{current - 1}</a>
                ) : (
                  ""
                )}
                <a className="active">{current}</a>

                {current + 1 < total ? (
                  <a onClick={(e) => setCurrent(current + 1)}>{current + 1}</a>
                ) : (
                  ""
                )}

                {total !== 1 ? (
                  <a
                    className={`${current}`}
                    onClick={(e) => setCurrent(total)}
                  >
                    {total}
                  </a>
                ) : (
                  ""
                )}

                <a
                  className={`${current}`}
                  onClick={(e) => setCurrent(current + 1)}
                >
                  &raquo;
                </a>
              </div>
            </center>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="dialer-body">
        <center>{count}</center>
        <div className="dialer-listings">
          {!loading ? (
            calls.map(
              (call, i) =>
                call.recordingFile && (
                  <DialerItem call={call} index={i} ngrokLink={ngrokLink} />
                )
            )
          ) : (
            <div style={{ marginTop: "20px" }}>
              <center>
                <img src={loadingSRC} height="300px" width="400px" />
              </center>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

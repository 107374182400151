import React, { Fragment } from "react";

const FilterSelector = ({
  options,
  id,
  filtered,
  filterProducts,
  disabled,
  setDisabled,
  setSuperStatusTags,
  showVerbose,
}) => {
  const multiCheck = (e, value) => {
    if (!isNaN(parseFloat(value))) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";

      if (value2send) {
        global.selected.push([...value2send.split(",")]);

        document.getElementById("productSearch").value = "";
        filterProducts();
      } else {
        alert("Choose a product");
      }
    }
  };

  let selector;

  if (id === "statusSelector")
    selector = (
      <select
        placeholder="Products"
        id={id}
        className="form-control form-control-sm"
        style={{
          height: "50px",
          fontSize: "0.9rem",
          borderRadius: 0,
          display: !showVerbose && "none",
        }}
        disabled={disabled.statusSelector}
        onChange={() =>
          setDisabled({
            statusSelector: false,
            superStatusSelector: true,
          })
        }
      >
        <option value={0} selected disabled>
          Status
        </option>
        {Object.keys(options).map((option, index) => (
          <option key={index} value={option}>
            {options[option].toUpperCase()}
          </option>
        ))}
      </select>
    );
  else if (id === "superStatusSelector") {
    selector = (
      <select
        placeholder="Super Status"
        id={id}
        className="form-control form-control-sm"
        style={{ height: "50px", fontSize: "0.9rem", borderRadius: 0 }}
        disabled={disabled.superStatusSelector}
        onChange={(e) => {
          setDisabled({
            statusSelector: true,
            superStatusSelector: false,
          });
          setSuperStatusTags(options[e.target.value]);
        }}
      >
        <option value="" selected disabled>
          Choose a super status
        </option>
        {Object.keys(options).map((superStatus) => (
          <option value={superStatus}>
            {superStatus.charAt(0).toUpperCase() + superStatus.slice(1)}
          </option>
        ))}
      </select>
    );
  } else if (id === "productSelector")
    selector =
      filtered.length > 0 ? (
        <Fragment>
          <input
            type="text"
            id={"productSearch"}
            list="productList"
            autoComplete="off"
            placeholder="Products"
            className="form-control form-control-sm"
            style={{
              height: "50px",
              fontSize: "0.9rem",
              borderRadius: 0,
              width: "100%",
            }}
            onChange={(e) => {
              multiCheck(e, e.target.value);
            }}
          />
          <datalist id="productList">
            {filtered.map((option, index) => (
              <option key={index} data-value={option} value={option[0]}>
                {option[1]}
              </option>
            ))}
          </datalist>
        </Fragment>
      ) : (
        ""
      );
  else if (id === "stateSelector")
    selector = (
      <select
        placeholder="States"
        id={id}
        className="form-control form-control-sm"
        style={{ height: "50px", fontSize: "0.9rem", borderRadius: 0 }}
      >
        <option value={0} selected disabled>
          State
        </option>
        {Object.keys(options).map((option, index) => (
          <option key={index} value={option}>
            {options[option]}
          </option>
        ))}
      </select>
    );
  else if (id === "paymentOptionSelector")
    selector = (
      <select
        id={id}
        className="form-control form-control-sm"
        style={{ height: "50px", fontSize: "0.9rem", borderRadius: 0 }}
      >
        <option value={0} selected disabled>
          Choose a payment method
        </option>
        <option value="paid">Prepaid</option>
        <option value="pending">COD</option>
      </select>
    );
  else if (id === "trafficSourceSelector")
    selector = (
      <select
        id={id}
        className="form-control form-control-sm"
        style={{ height: "50px", fontSize: "0.9rem", borderRadius: 0 }}
      >
        <option value={0} selected disabled>
          Choose a traffic source
        </option>
        <option value="Facebook">Facebook</option>
        <option value="GoogleOrganic">Google-Organic</option>
        <option value="GoogleCpc">Google-Paid</option>
        <option value="TikTok">TikTok</option>
        <option value="Chingari">Chingari</option>
      </select>
    );

  return selector;
};

export default FilterSelector;

import axios from "axios";

export const register = async ({
  name,
  email,
  role,
  permissions,
  calls,
  active,
  agentID,
}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    name,
    email,
    role: document.getElementById("role").value,
    customer: document.getElementById("customer_toggle")
      ? document.getElementById("customer_toggle").checked
      : false,
    employee: document.getElementById("managing_toggle")
      ? document.getElementById("managing_toggle").checked
      : false,
    access: permissions,
    calls,
    active: active,
    agentID: agentID || "unknown",
  });

  try {
    const res = await axios.post("/api/auth/register", body, config);
    alert(res.data.msg);
    return res.data.user;
  } catch (error) {
    const errors = error.response.data.msg;
    console.log("Failed to register", errors);
    alert(errors);
  }
};

import React, { useContext, useState, useEffect, Fragment } from "react";
import { GlobalConsumer } from "../../../Global-Context/global-context";
import Axios from "axios";
import NavBar from "../res/NavBar";
import UtilityBar from "../res/UtilityBar";
import OrderItem from "../res/OrderItem";
import Pagination from "../../../components/global/Pagination";
import Spinner from "../../../components/global/Spinner";
import NDROrderItem from "../res/NDROrderItem";
import UpsellOrderItem from "../res/UpsellOrderItem";
import TagBar from "../res/TagBar";
import moment from "moment";
import LeadOrderItem from "../res/LeadOrderItem";
import img from "../../../OperationsDashboard/Assets/ripple-loading.gif";
import useAlert from "../../../components/global/Alert";
import notifyMe from "../../../res/notifyMe";

function CallingDashboard() {
  document.title = "Calling Dashboard";
  const ORDERSPERPAGE = 25;
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    caller: "",
    store: "",
    call: "",
    from: moment("2019").toLocaleString(),
    to: moment().endOf("day").toLocaleString(),
  });
  const [ndrFilters, setNDRFilters] = useState({
    type: "",
    count: "",
  });
  const [loading, setLoading] = useState(false);
  const [toShow, setToShow] = useState(ORDERSPERPAGE);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(0);
  let [type, setType] = useState("");
  const [order, setOrder] = useState("NTO");
  const [max, setMax] = useState(0);
  const [orders, setOrders] = useState([]);
  const [showOrders, setShowOrders] = useState(orders);
  const [callCount, setCallCount] = useState(0);
  const [ndr, setNDR] = useState(false);
  const [splOrders, setSplOrders] = useState([]);
  const [leadOrders, setLeadOrders] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [semiLoading, setSemiLoading] = useState(false);
  const [callsMade, setCallsMade] = useState([]);
  const [params] = useState(window.location.search.includes("?input="));
  const [AlertBox, setNotification] = useAlert();
  const [toggle, setToggle] = useState(
    localStorage.getItem("toggleMode") || "dialer"
  );

  const [toggleLead, setToggleLead] = useState();
  const [toggleNotifications, setToggleNotifications] = useState(
    localStorage.getItem("toggleNotifications") === "true" ? true : false
  );
  const [intervall, setIntervall] = useState(null);
  // IMPROVE FILTERING BY USING BELOW STATES
  // const [callType, setCallType] = useState("All");
  // const [store, setStore] = useState("All");
  // const [caller, setCaller] = useState("All");
  // const [fromDate, setFromDate] = useState(new Date("2021"));
  // const [toDate, setToDate] = useState(new Date())

  const moderateGetOrders = async (currentCount) => {
    if (orders.length + splOrders.length + leadOrders.length === currentCount) {
      window.scrollTo({
        top: 90,
        left: 0,
        behavior: "smooth",
      });
      setSemiLoading(true);
      await getOrders(user, type, current, true);
      setSemiLoading(false);
      setCurrentCount(0);
      setCallsMade([]);
    }
  };

  const getOrders = async (user, queryObject, current, skipLoading) => {
    let callType;
    const fromDate = new Date(filters.from).toISOString();
    const toDate = new Date(filters.to).toISOString();
    let filterArray = [];
    if (filters.store) filterArray.push({ "store.shopifyName": filters.store });
    if (filters.caller) filterArray.push({ callAssignedTo: filters.caller });
    if (filters.call) {
      filterArray.push({ [filters.call]: true });
      callType = filters.call;
    } else {
      callType = "All";
    }
    if (!skipLoading) setLoading(true);
    console.log("USER", user);

    // let result = await Axios.get(
    //   `/api/support/getOrders_v2?user_id=${user.user_id}&userName=${
    //     user.name
    //   }&queryObject=${JSON.stringify(
    //     queryObject
    //   )}&page=${current}&type=${type}&order=${order}&fromDate=${fromDate}&toDate=${toDate}&ndrFilters=${JSON.stringify(
    //     ndrFilters
    //   )}`
    // );

    let result = await Axios.post(`/api/support/getOrders_v3`, {
      user: {
        id: user.user_id,
        name: user.name,
        master: user.master || false,
        employee: user.employee || false,
        customer: user.customer || false,
        stores: user.stores || [],
      },
      filters: filterArray,
      page: current,
      type: callType,
      order,
      fromDate,
      toDate,
      ndrFilters,
      ordersPerPage: ORDERSPERPAGE,
    });

    if (result.data.dialermode)
      return setNotification(
        "success",
        "Will not load. Please use the dialer (You can still search for orders), Contact Devs if you need to schedule calls on the calling dashboard"
      );

    if (result.data.priorityOrders.length > 0)
      setNotification(
        "success",
        "You have to call a customer back, scroll up to see"
      );
    if (!skipLoading) setLoading(false);
    console.log(`reponse from support/getOrders_v3`);
    console.log(result.data);

    setOrders(result.data.orders);
    setCount(result.data.total);
    setMax(result.data.max);
    setToShow(result.data.orders.length);
    setSplOrders(result.data.priorityOrders);
    setShowOrders(result.data.orders);
    setLeadOrders(result.data.leads);
    await getNDRCalls(user);
    // await getCalls(user);
    await getUsers();
  };

  const getUsers = async () => {
    let result = await Axios.get(`/api/auth/users/support`);

    setUsers(result.data.users);
  };

  const getCalls = async (user) => {
    if (user.logs) {
      let result = await Axios.get(
        `/api/support/getCalls?user_id=${user.user_id}`
      );

      setCallCount(result.data.count);
    }
  };

  const getNDRCalls = async (user) => {
    if (user.logs) {
      let result = await Axios.get(
        `/api/support/getNDRCount?user=${JSON.stringify({
          name: user.name,
          user_id: user.user_id,
        })}`
      );

      setNDR(result.data.ndr);
    }
  };

  const searchFunction = () => {
    getOrders(user, type, 1);
  };

  const searchOrder = async (input, type) => {
    if (input) {
      setNotification("success", "Please wait while we find your order");
      let many = false;
      if (input.includes(",")) {
        input = input.split(",").map((item) => item.trim());
        many = true;
      }
      // HERE
      let result = await Axios.get(
        `/api/orders/searchOrder?customer=${
          user.customer ? user.user_id : null
        }&input=${encodeURIComponent(JSON.stringify(input))}&type=${
          type || null
        }&many=${many}`
      );

      if (result.data.length > 0) {
        setNotification("success", "Please scroll down to find your orders");
        setMax(result.data.length);
        setToShow(result.data.length);
        setShowOrders(result.data);
      } else {
        setNotification(
          "failure",
          `Couldn't find any orders for your search - ${input}`
        );
        return;
      }
    } else {
      setNotification("failure", `No search query found!`);
      return;
      // setShowOrders(orders.filter((order) => !callsMade.includes(order.name)));
    }
  };

  const callMadeFunction = (orderName) => {
    setCallsMade([...callsMade, orderName]);
  };

  const handleToggle = (e) => {
    if (e.target.checked) {
      setToggle("dashboard");
      localStorage.setItem("toggleMode", "dashboard");
    } else {
      setToggle("dialer");
      localStorage.setItem("toggleMode", "dialer");
    }
    window.location.reload();
  };

  const handleToggleLead = async (e) => {
    if (e.target.checked) {
      setToggleLead(true);
    } else {
      setToggleLead(false);
    }

    await Axios.post("/api/support/toggleLeadPusher", {
      value: e.target.checked,
    });
  };

  const getStats = async () => {
    if (user.role === "support" || user.master || user.employee) {
      const int = setInterval(() => processStats(), 120000);
      setIntervall(int);
    }
  };

  const handleNotifications = async (e) => {
    if (e.target.checked) {
      setToggleNotifications(true);
      localStorage.setItem("toggleNotifications", true);
    } else {
      setToggleNotifications(false);
      localStorage.setItem("toggleNotifications", false);
      clearInterval(intervall);
    }
  };

  const getLeadToggle = async () => {
    const result = await Axios.get("/api/support/getLeadPusherToggle");
    setToggleLead(result.data.leadPusherRes.leadPusher);
  };

  const processStats = async (manual) => {
    if ((!toggleNotifications || toggleNotifications === "false") && !manual)
      return;
    const result = await Axios.post("/api/support/fetchOptimalCampaign", {
      user: {
        name: user.name,
        role: user.role,
        employee: user.employee,
        master: user.master,
      },
    });
    const { notification } = result.data;
    notifyMe(notification);
    return notification;
  };

  useEffect(() => {
    if (params) {
      const urlParams = new URLSearchParams(window.location.search);
      const input = urlParams.get("input");
      const type = urlParams.get("type");

      searchOrder(decodeURIComponent(input), type);
    } else if (toggle === "dashboard") moderateGetOrders(currentCount);

    if (currentCount != 0) {
      setCallCount(callCount + 1);
      setCount(count - 1);
    }
  }, [currentCount, params]);

  useEffect(() => {
    if (showOrders.length > 0) {
      const ordersToBeShown = showOrders.filter(
        (order) => !callsMade.includes(order.name)
      );
      setShowOrders(ordersToBeShown);
    }
    if (splOrders.length > 0) {
      const specialOrdersToBeShown = splOrders.filter(
        (order) => !callsMade.includes(order.name)
      );
      setSplOrders(specialOrdersToBeShown);
    }
    if (leadOrders.length > 0) {
      const leadOrdersToBeShown = leadOrders.filter(
        (order) => !callsMade.includes(order.name)
      );
      setLeadOrders(leadOrdersToBeShown);
    }
  }, [callsMade]);

  useEffect(() => {
    getCalls(user);
    getStats();
    getLeadToggle();
  }, []);

  useEffect(() => {
    getStats();
  }, [toggleNotifications]);

  return (
    <div style={{ overflow: "hidden" }}>
      <AlertBox />
      <NavBar
        user={user}
        callCount={callCount}
        getOrders={getOrders}
        setType={setType}
        setFilters={setFilters}
        filters={filters}
        processStats={processStats}
      />

      <div style={{ marginTop: "20px" }}>
        <center
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            Manual Notifications{" "}
          </div>
          <div>
            <label className="custom-toggle">
              <input
                type="checkbox"
                onChange={handleNotifications}
                checked={toggleNotifications}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            {" "}
            Automatic Notifications
          </div>
        </center>
      </div>

      {user.master ? (
        <div style={{ marginTop: "20px" }}>
          <center
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              Lead Pusher Off (Calling Dashboard Mode){" "}
            </div>
            <div>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  onChange={handleToggleLead}
                  checked={toggleLead}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {" "}
              Lead Pusher On (Dialer Mode)
            </div>
          </center>
        </div>
      ) : (
        ""
      )}

      <Pagination
        total={max}
        toShow={toShow}
        getOrders={getOrders}
        user={user}
        type={type}
        current={current}
        setCurrent={setCurrent}
        ordersPerPage={ORDERSPERPAGE}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "0px 20px",
        }}
      >
        {Object.values(filters)
          ? Object.values(filters).map((item, i) =>
              item ? <TagBar item={item} key={i} filters={filters} /> : ""
            )
          : ""}
      </div>

      <UtilityBar
        orders={orders}
        setShowOrders={setShowOrders}
        users={users}
        showOrders={showOrders}
        user={user}
        getOrders={getOrders}
        setType={setType}
        type={type}
        searchFunction={searchFunction}
        filters={filters}
        ndrFilters={ndrFilters}
        setNDRFilters={setNDRFilters}
        setFilters={setFilters}
        current={current}
        setCurrent={setCurrent}
        searchOrder={searchOrder}
      />
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <center
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              (Only allows searching orders) Dialer{" "}
            </div>
            <div>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  onChange={handleToggle}
                  checked={toggle === "dialer" ? false : true}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {" "}
              Dashboard (Loads orders on the dashboard)
            </div>
          </center>
          <center>Total {count} orders</center>
          <center>
            Showing {orders.length + splOrders.length + leadOrders.length}{" "}
            orders on this page
          </center>

          {ndr ? (
            ndr.count > 0 ? (
              <Fragment>
                <center style={{ fontWeight: "bold", margin: "20px" }}>
                  {ndr.count} NDR Calls
                </center>
              </Fragment>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <div style={{ padding: "0px 20px", display: "flex" }}>
            {order === "NTO" ? (
              <div
                onClick={() => setOrder("OTN")}
                style={{ cursor: "pointer" }}
              >
                <i
                  className="fas fa-sort-numeric-down"
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "4px",
                  }}
                ></i>
                <p style={{ marginBottom: "0px" }}>Newest First</p>
              </div>
            ) : (
              <div
                onClick={() => setOrder("NTO")}
                style={{ cursor: "pointer" }}
              >
                <i
                  className="fas fa-sort-numeric-up"
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "4px",
                  }}
                ></i>
                <p style={{ marginBottom: "0px" }}>Oldest First</p>
              </div>
            )}
          </div>

          <div className="orderBoard">
            <center>
              {semiLoading &&
              orders.length + splOrders.length + leadOrders.length ===
                currentCount ? (
                <div>
                  <img
                    src={img}
                    alt="Loading..."
                    style={{
                      margin: "50px 0px",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </center>

            {splOrders.length > 0 ? (
              <div>
                <div className="dividing-line">CALLBACK ORDERS</div>
                {splOrders.map((order, i) =>
                  order !== "" ? (
                    !order.ndr &&
                    !order.ndrResolved &&
                    order.ndrResolved !== false ? (
                      order.upSellOrder ? (
                        <UpsellOrderItem
                          order={order}
                          user={user}
                          getOrders={getOrders}
                          setCurrentCount={setCurrentCount}
                          current={current}
                          type={type}
                          key={order.name + order.customer.id + order._id + i}
                          id={order.name + order.customer.id + order._id + i}
                          callMadeFunction={callMadeFunction}
                        />
                      ) : (
                        <OrderItem
                          order={order}
                          user={user}
                          getOrders={getOrders}
                          setCurrentCount={setCurrentCount}
                          current={current}
                          type={type}
                          key={order.name + order.customer.id + order._id + i}
                          id={order.name + order.customer.id + order._id + i}
                          callMadeFunction={callMadeFunction}
                        />
                      )
                    ) : (
                      <NDROrderItem
                        order={order}
                        user={user}
                        getOrders={getOrders}
                        setCurrentCount={setCurrentCount}
                        current={current}
                        type={type}
                        key={order.name + order.customer.id + order._id + i}
                        id={order.name + order.customer.id + order._id + i}
                        callMadeFunction={callMadeFunction}
                      />
                    )
                  ) : (
                    ""
                  )
                )}
                <div className="dividing-line">NORMAL ORDERS</div>
              </div>
            ) : (
              ""
            )}

            {leadOrders.length > 0
              ? leadOrders.map((order, i) => (
                  <LeadOrderItem
                    order={order}
                    key={i}
                    user={user}
                    setCurrentCount={setCurrentCount}
                    getOrders={getOrders}
                    current={current}
                    type={type}
                    id={order.name}
                    callMadeFunction={callMadeFunction}
                  />
                ))
              : ""}

            {showOrders.map((order, i) =>
              order !== "" ? (
                !order.ndr ? (
                  order.upSellOrder ? (
                    <UpsellOrderItem
                      order={order}
                      user={user}
                      getOrders={getOrders}
                      setCurrentCount={setCurrentCount}
                      current={current}
                      type={type}
                      key={order.name + order.customer.id + order._id + i}
                      id={order.name + order.customer.id + order._id + i}
                      callMadeFunction={callMadeFunction}
                    />
                  ) : (
                    <OrderItem
                      order={order}
                      user={user}
                      getOrders={getOrders}
                      setCurrentCount={setCurrentCount}
                      current={current}
                      type={type}
                      key={order.name + order.customer.id + order._id + i}
                      id={order.name + order.customer.id + order._id + i}
                      callMadeFunction={callMadeFunction}
                    />
                  )
                ) : (
                  <NDROrderItem
                    order={order}
                    user={user}
                    getOrders={getOrders}
                    current={current}
                    setCurrentCount={setCurrentCount}
                    type={type}
                    key={order.name + order.customer.id + order._id + i}
                    id={order.name + order.customer.id + order._id + i}
                    callMadeFunction={callMadeFunction}
                  />
                )
              ) : (
                ""
              )
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default CallingDashboard;

import React, { Fragment } from "react";

const TimeLine = ({ content, hideArrow }) => {
  return (
    <div className="timeline-item">
      <div className="timeline-box">{content}</div>
      {hideArrow ? null : (
        <Fragment>
          <div className="timeline-arrow"></div>
          <div className="timeline-arrow-head arrow-down"></div>
        </Fragment>
      )}
    </div>
  );
};

export default TimeLine;

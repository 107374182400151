import React, { useContext } from "react";
import Axios from "axios";
import { GlobalConsumer } from "../../Global-Context/global-context";

const Bot = () => {
  let context = useContext(GlobalConsumer);
  let user = context.getUser();

  const initiateUpdate = () => {
    let password = prompt("Enter Password");
    Axios.post("https://jarvis.bazingamedia.xyz", { password });
    if (password) alert("Initiated Updated");
  };

  return user ? (
    <div>
      {user.master || user.role === "developer" ? (
        <div
          className="update-button"
          onClick={() => {
            initiateUpdate();
          }}
        >
          <i className="fas fa-arrow-circle-up"></i>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};

export default Bot;

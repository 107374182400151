import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { GlobalConsumer } from "../../Global-Context/global-context";
import Cryptr from "cryptr";
import SearchBar from "./SearchBar";

const MasterBar = () => {
  // let context = useContext(GlobalConsumer);
  let [user, setUser] = useState("");
  const [navigationArray, setNavigationArray] = useState(null);
  const [navigation, setNavigation] = useState(null);
  const [show, setShow] = useState("none");
  const [description, setDescription] = useState("");
  const [currentDepartment, setcurrentDepartment] = useState("");
  const [navigationDesktop, setNavigationDesktop] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");

  const getUser = () => {
    if (localStorage.info) {
      let info = JSON.parse(localStorage.info);
      const cryptr = new Cryptr("myTotalySecretKey");
      let user = JSON.parse(cryptr.decrypt(info));
      setUser(user);
    }
  };

  const showBar = () => {
    if (show === "none") setShow("flex");
    else setShow("none");
  };

  const getNavigation = async () => {
    let result = await Axios.post("/api/auth/navigation", {
      user: { email: user.email },
    });

    setNavigation(result.data.navigation);
    setNavigationDesktop(result.data.navigationDesktop);

    let navigations = await Axios.get(
      `/api/auth/accessListV2?email=${user.email}`
    );
    setNavigationArray(navigations.data.list);

    if (!localStorage.getItem("navigation"))
      localStorage.setItem(
        "navigation",
        JSON.stringify(result.data.navigation)
      );

    if (result.data.navigation) setNavigation(result.data.navigation);
    else {
      console.log("Reverting to the fall back navigation");
      if (localStorage.getItem("navigation"))
        setNavigation(JSON.parse(localStorage.getItem("navigation")));
      else console.log("Something Wrong With The NavBar");
    }
  };

  useEffect(() => {
    getNavigation();
  }, [user]);

  useEffect(() => {
    getUser();
  }, []);

  document.addEventListener(
    "click",
    function (event) {
      if (
        event.target.id !== "dropdownMenuButton" &&
        event.target.id !== "masterbar-heading"
      ) {
        setSelectedMenu("");

        var dropdowns = document.querySelectorAll(".collapse");

        for (var i = 0; i < dropdowns.length; i++) {
          dropdowns[i].classList.add("show");
        }
      }
    },
    true
  );

  if (!user) getUser();

  return user && navigation && navigationArray ? (
    <nav className="MasterBar">
      <SearchBar navigation={navigationArray} />
      {navigationDesktop ? (
        <div className="MasterBar-Navigation desktop">
          {navigationDesktop.map((group, i) => (
            <div
              className="dropdown  MasterBar-Navigation-Head "
              key={i}
              style={
                group.department === "account" ? { marginRight: "auto" } : {}
              }
            >
              {group.department !== "general" ? (
                <button
                  className="btn dropdown-toggle MasterBar-Navigation-Button"
                  type="button"
                  id="dropdownMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    if (selectedMenu === group.department) setSelectedMenu("");
                    else setSelectedMenu(group.department);
                  }}
                  style={{
                    fontSize: "0.8rem",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {group.department === "account"
                    ? user
                      ? user.name
                      : group.department
                    : group.department}
                </button>
              ) : (
                <Link
                  to="/"
                  className="btn  MasterBar-Navigation-Button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setSelectedMenu("")}
                  style={{
                    fontSize: "0.8rem",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  Home
                </Link>
              )}
              <div
                id={`masterbar-dropdown-${group.department}`}
                className={`dropdown-menu right-menu MasterBar-Navigation-Menu ${
                  group.department === selectedMenu ? "show" : ""
                }`}
                aria-labelledby="dropdownMenuButton"
                show={true}
              >
                <div class="panel-group" id={`accordion_${group.department}`}>
                  {Object.keys(group.pages).map((access, i) => {
                    return (
                      <div
                        class="panel panel-default"
                        style={
                          group.department !== "account"
                            ? { minWidth: "20vw" }
                            : {}
                        }
                      >
                        <div
                          data-toggle="collapse"
                          data-parent={`#accordion_${group.department}`}
                          href={`#collapse${i}_${group.department}_${access}`}
                          className="masterbar-heading panel-heading"
                          id="masterbar-heading"
                        >
                          {access}
                        </div>
                        <div
                          id={`collapse${i}_${group.department}_${access}`}
                          className="panel-collapse collapse in show"
                        >
                          {group["pages"][access].map((element, i) => {
                            return (
                              <div>
                                <button
                                  className="dropdown-item-custom MasterBar-Navigation-Button-item"
                                  key={i}
                                  onMouseOver={() => {
                                    setDescription(element.description);
                                    setcurrentDepartment(element.department);
                                  }}
                                  onClick={() => setSelectedMenu("")}
                                  onMouseOut={() => setDescription("")}
                                >
                                  <Link to={element.link}>{element.name}</Link>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
          {description && currentDepartment == currentDepartment ? (
            <div className="tooltip-masterbar-text">{description}</div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <div className="mobile">
        <i
          className="fas fa-bars"
          style={{ cursor: "pointer" }}
          onClick={() => showBar()}
        ></i>
      </div>
      <div className="mobile-menu-custom" style={{ display: show }}>
        <ul className="nav-item-mobile master-ul">
          {navigation.map((group, i) => (
            <div className="dropdown  MasterBar-Navigation-Head " key={i}>
              {group.department !== "general" ? (
                <button
                  className="btn dropdown-toggle MasterBar-Navigation-Button"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    fontSize: "0.8rem",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {group.department === "account"
                    ? user
                      ? user.name
                      : group.department
                    : group.department}
                </button>
              ) : (
                <Link
                  to="/"
                  className="btn  MasterBar-Navigation-Button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    fontSize: "0.8rem",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  Home
                </Link>
              )}
              <div
                className={`dropdown-menu right-menu MasterBar-Navigation-Menu`}
                aria-labelledby="dropdownMenuButton"
              >
                {group.pages.map((access, i) => (
                  <button
                    className="dropdown-item-custom MasterBar-Navigation-Button-item"
                    key={i}
                  >
                    <Link to={access.link} onClick={() => setShow("none")}>
                      {access.name}
                    </Link>
                  </button>
                ))}
              </div>
            </div>
          ))}
        </ul>
      </div>
    </nav>
  ) : (
    ""
  );
};

export default MasterBar;

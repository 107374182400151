import axios from "axios";

const setPassword = async (email) => {
  const body = {
    email,
  };
  try {
    const result = await axios.post("/api/auth/setNewPassword", body);
    return result.data.msg;
  } catch (error) {
    console.log(error);
    return "Something went wrong!";
  }
};

export default setPassword;

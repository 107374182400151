import React, { useState } from "react";
import HistoryElement from "./HistoryElement";

export default function BugComponent({ bug }) {
  const [showTrace, setShowTrace] = useState(false);
  return (
    <div className="bug-component">
      <div
        className="bug-head"
        style={{ background: bug.frequency > 21 ? "#e74c3c" : "#3498db" }}
      >
        <div
          className="bug-frequency"
          style={{
            color: bug.frequency > 21 ? "#e74c3c" : "#3498db",
            border: `2px solid ${bug.frequency > 21 ? "#e74c3c" : "#3498db"} `,
          }}
        >
          {bug.frequency}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setShowTrace((current) => !current)}
        >
          <i class={`fas fa-angle-${showTrace ? "up" : "down"}`} />
        </div>
        <div style={{ width: "100%" }}>{bug.error}</div>
        <div className="bug-status">
          <div>{bug.status}</div>
        </div>
        <div className="bug-date">{new Date(bug.date).toLocaleString()}</div>
      </div>
      {showTrace ? (
        <div className="bug-stack">
          <div className="bug-history">
            {bug.history && bug.history.length > 0
              ? bug.history.map((element) => (
                  <HistoryElement element={element} />
                ))
              : ""}
          </div>
          <div style={{ padding: "0px 10px" }}>{bug.stack}</div>
        </div>
      ) : (
        ""
      )}
      <div className="bug-details" style={{ padding: "20px 20px" }}>
        <div className="bug-pill">{bug.type}</div>
        <div className="bug-pill">{bug.location.slice(1)}</div>
        <div className="bug-pill">{bug.locationType}</div>
      </div>
    </div>
  );
}

import React, { Fragment } from "react";
import { addNewResource } from "../../functions/addNewResource";
const ResourceForm = ({ type }) => {
  return (
    <Fragment>
      <label htmlFor={type}>{type}</label>
      <div className="form-group resourceForm">
        <input
          type="text"
          className="form-control"
          id={`add_${type}`}
          onKeyDown={e => {
            if (e.keyCode === 13) addNewResource(type);
          }}
          placeholder={`${type} Name`}
        />

        <button
          type="button"
          id={`btn_${type}`}
          className="btn btn-success btn-product-add"
          onClick={() => addNewResource(type)}
        >
          Add {type}
        </button>
      </div>
    </Fragment>
  );
};

export default ResourceForm;

import React, { useState, Fragment, useEffect } from "react";
import {
  printManifestsAndLabels,
  printManifestsAndLabelsV3,
} from "../functions/printManifestsAndLabels";
import NavBar from "../res/NavBar";
import Axios from "axios";

const PrintManifestsAndLabels = () => {
  const [store, setStore] = useState("start");
  const [message, setMessage] = useState("");
  const [count, setCount] = useState(0);
  const [countAll, setCountAll] = useState(0);
  const [countAllDelhiWarehouse, setCountAllDelhiWarehouse] = useState(0);
  const [CPcount, setCPcount] = useState(0);
  const [CPcountAll, setCPcountAll] = useState(0);
  const [GoSwiftLabelsCount, setGoSwiftLabelsCount] = useState(0);
  const [processing, setProcessing] = useState(false);
  // const [totalProducts, setToalProducts] = useState([]);

  document.title = "Print Manifests And Labels";

  const handleClickV3 = async (e, platform, warehouse) => {
    e.preventDefault();
    document.getElementById("label-button").disabled = true;
    setProcessing(true);
    try {
      let result = await printManifestsAndLabelsV3(
        setMessage,
        platform,
        warehouse
      );
      console.log("result = ", result);
      if (
        result ===
        "Manifests and Labels have not been successfully generated and printed"
      )
        alert(
          "Manifests and Labels have not been successfully generated and printed, Please Try Again By Refreshing Page"
        );
      else
        alert(
          "Please Visit ViewManifestsAndLabels_V3 to get the pdf link for downloading the manifests and labels"
        );
    } catch (error) {
      console.log("Error in Front End layout (PrintManifestsAndLabels.js)");
      console.log(error);
    }
  };

  const handleClickPost = async (e, store, type) => {
    e.preventDefault();
    document.getElementById("label-button").disabled = true;
    setProcessing(true);
    try {
      let result = await printManifestsAndLabels(
        setMessage,
        store,
        "clickpost",
        type
      );
      console.log("result = ", result);
      if (
        result ===
        "Manifests and Labels have not been successfully generated and printed"
      ) {
        alert("Please Try Again By Refreshing Page");
      }
    } catch (error) {
      console.log("Error in Front End layout (PrintManifestsAndLabels.js)");
      console.log(error);
    }
  };

  const handleGoSwift = async (e, store, type) => {
    e.preventDefault();
    document.getElementById("label-button").disabled = true;
    setProcessing(true);
    try {
      // return alert("GoSwift Labels Generation feature coming soon");
      let result = await printManifestsAndLabels(
        setMessage,
        store,
        "goswift",
        type
      );
      console.log("result = ", result);
      if (
        result ===
        "Manifests and Labels have not been successfully generated and printed"
      ) {
        alert("Please Try Again By Refreshing Page");
      } else
        alert(
          "Please Visit ViewManifestsAndLabels_V3 to get the pdf link for downloading the GoSwift Labels"
        );
    } catch (error) {
      console.log("Error in Front End layout (PrintManifestsAndLabels.js)");
      console.log(error);
      alert("Error");
    }
  };

  const getLabels = async () => {
    const result = await Axios.get("/api/warehouse/getLabels");
    console.log(result.data);
    setProcessing(
      result.data.processing ? result.data.processing.processingLabels : false
    );
  };

  const overrideProcessing = async () => {
    const password = prompt("Enter the secret password");
    if (password !== "Rupesh@123") return alert("Wrong password!");
    const reason = prompt("Please enter the reason");
    if (!reason) return alert("Please input a reason for verification!");
    const result = await Axios.get(
      `/api/warehouse/resetLabels?reason=${reason}`
    );
    if (result) {
      alert("You can now press the button");
      window.location.reload();
    }
  };

  useEffect(() => {
    // fetchProducts(store);

    Axios.get(
      `/api/warehouse/printManifestsAndLabels?store=${encodeURIComponent(
        JSON.stringify(store)
      )}`
    )
      .then((result) => setCount(result.data.countOrders))
      .catch((error) => setCount("please refresh the page to get count of"));

    Axios.get(
      `/api/warehouse/printAllManifestsAndLabels?store=${encodeURIComponent(
        JSON.stringify(store)
      )}`
    )
      .then((result) => setCountAll(result.data.countOrders))
      .catch((error) => setCountAll("please refresh the page to get count of"));

    Axios.get(
      `/api/warehouse/printAllManifestsAndLabelsForDelhiWarehouse?store=${encodeURIComponent(
        JSON.stringify(store)
      )}`
    )
      .then((result) => setCountAllDelhiWarehouse(result.data.countOrders))
      .catch((error) =>
        setCountAllDelhiWarehouse("please refresh the page to get count of")
      );

    Axios.get(
      `/api/warehouse/printManifestsAndLabelsForClickPost?store=${encodeURIComponent(
        JSON.stringify(store)
      )}`
    )
      .then((result) => setCPcount(result.data.countOrders))
      .catch((error) => setCount("please refresh the page to get count of"));

    Axios.get(
      `/api/warehouse/printAllManifestsAndLabelsForClickPost?store=${encodeURIComponent(
        JSON.stringify(store)
      )}`
    )
      .then((result) => setCPcountAll(result.data.countOrders))
      .catch((error) =>
        setCPcountAll("please refresh the page to get count of")
      );

    Axios.get(
      `/api/goswift/warehouse/printAllLabelsForGoSwift?store=${encodeURIComponent(
        JSON.stringify(store)
      )}`
    )
      .then((result) => setGoSwiftLabelsCount(result.data.countOrders))
      .catch((error) =>
        setGoSwiftLabelsCount("please refresh the page to get count of")
      );

    // getCount();
  }, [store]);

  // useEffect(() => {
  //   getLabels();
  // }, []);

  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} />
      {store !== "start" ? (
        <div className="container-fluid">
          <form>
            <br />
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-header">
                  <h3>Want to Generate Manifests And Labels ?</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <p>
                        <strong>(Shiprocket)</strong> There are {count} Orders
                        for whom Manifests and Labels have not been generated
                        and printed. Do you want to print them? If yes, click on
                        the button provided below.
                      </p>
                      {CPcount > 0 ? (
                        <p>
                          <strong>(ClickPost)</strong> There are {CPcount}{" "}
                          Orders for whom Manifests and Labels have not been
                          generated and printed. Do you want to print them? If
                          yes, click on the button provided below.
                        </p>
                      ) : (
                        ""
                      )}
                      <br />
                      {CPcount > 0 ? (
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                          id="label-button"
                          disabled={processing}
                          onClick={(e) => {
                            handleClickPost(e, store, "old");
                          }}
                        >
                          Generate Manifests and Labels For ClickPost
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {!message.clickpost ? (
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-body">
                  <p>{message.status}</p>
                  <a href={message.manifestLink}>{message.manifestLink}</a>
                  <br />
                  <a href={message.labelLink}>{message.labelLink}</a>
                </div>
              </div>
            </div>
          ) : message.clickpost ? (
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-body">
                  <p>Invididual Labels for Orders</p>
                  <a
                    href={message.labelLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click For Labels PDF
                  </a>
                  {/* {message.clickpost.map(link => (
                    <p>
                      {link.order}: <a href={link.link}>{link.link}</a>
                    </p>
                  ))} */}
                </div>
              </div>
            </div>
          ) : (
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-body">
                  <p>No Manifests Generated</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          {processing ? (
            <Fragment>
              <h3>
                Please wait for 15mins, labels are currently being processed
              </h3>
              <h3>Contact developers if not processed within 15mins</h3>
              <h3
                style={{ cursor: "pointer", color: "#3498db" }}
                onClick={() => overrideProcessing()}
              >
                Do you still want to proceed?
              </h3>
            </Fragment>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "60vw",
              margin: "0 auto",
              padding: "50px",
            }}
          >
            {
              <Fragment>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={processing}
                  id="label-button"
                  style={{ marginRight: "20px", marginBottom: "20px" }}
                  onClick={(e) => {
                    handleClickV3(e, "shiprocket");
                  }}
                >
                  Generate All Labels And Manifests(ShipRocket Bengaluru
                  Warehouse)
                  <span
                    style={{
                      background: "white",
                      color: "black",
                      padding: "5px",
                      width: "100%",
                      display: "block",
                      marginTop: "5px",
                    }}
                  >{`${countAll} Orders`}</span>
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={processing}
                  id="label-button"
                  style={{ marginRight: "20px" }}
                  onClick={(e) => {
                    handleClickV3(e, "shiprocket", "delhi");
                  }}
                >
                  Generate All Labels And Manifests(ShipRocket Delhi Warehouse)
                  <span
                    style={{
                      background: "white",
                      color: "black",
                      padding: "5px",
                      width: "100%",
                      display: "block",
                      marginTop: "5px",
                    }}
                  >{`${countAllDelhiWarehouse} Orders`}</span>
                </button>
                {CPcountAll > 0 ? (
                  <button
                    type="submit"
                    disabled={processing}
                    id="label-button"
                    className="btn btn-primary btn-lg"
                    style={{ marginRight: "20px" }}
                    onClick={(e) => {
                      handleClickPost(e, store, "new");
                    }}
                  >
                    Generate All Labels And Manifests(ClickPost) <br />
                    <span
                      style={{
                        background: "white",
                        color: "black",
                        padding: "5px",
                        width: "100%",
                        display: "block",
                        marginTop: "5px",
                      }}
                    >{`${CPcountAll} Orders`}</span>
                  </button>
                ) : (
                  "No Orders On ClickPost"
                )}
                {GoSwiftLabelsCount > 0 ? (
                  <button
                    type="submit"
                    id="label-button"
                    className="btn btn-primary btn-lg"
                    disabled={processing}
                    style={{ marginRight: "20px" }}
                    onClick={(e) => {
                      handleGoSwift(e, store, "new");
                    }}
                  >
                    Generate All Labels (GoSwift) <br />
                    <span
                      style={{
                        background: "white",
                        color: "black",
                        padding: "5px",
                        width: "100%",
                        display: "block",
                        marginTop: "5px",
                      }}
                    >{`${GoSwiftLabelsCount} Orders`}</span>
                  </button>
                ) : (
                  "No Orders On GoSwift"
                )}
              </Fragment>
            }
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PrintManifestsAndLabels;

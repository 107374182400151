import React, { useState, Fragment } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { confirmation } from "../../../utils/confirmation";
import EditForm from "./EditForm";

const Store = ({
  store,
  getStoresList,
  activeStore,
  setActiveStore,
  storeIndex,
}) => {
  console.log("Store", store);
  const [form, setForm] = useState("");
  const [visible, setVisible] = useState(false);

  const deleteStore = async () => {
    if (confirmation()) {
      let res = await Axios.post("/api/stores/delete", { store });
      alert(res.data.msg);
      getStoresList();
    }
  };

  const enableStore = async () => {
    if (confirmation()) {
      let res = await Axios.post("/api/stores/enable", { store });
      alert(res.data.msg);
      getStoresList();
    }
  };

  const pauseStore = async (pause) => {
    if (confirmation()) {
      let res = await Axios.post("/api/stores/pause", { store, pause });
      alert(res.data.msg);
      getStoresList();
    }
  };

  const toggleForm = () => {
    if (visible) {
      setForm("");
      setVisible(false);
    } else {
      setForm(<EditForm store={store} getStoresList={getStoresList} />);
      setVisible(true);
    }
  };

  const toggleForm2 = () => {
    if (activeStore !== storeIndex) {
      setActiveStore(storeIndex);
      return;
    }
    setActiveStore(null);
  };

  // const editStore = async () => {
  //   let confirmation = prompt("Are you sure?");
  //   confirmation = confirmation ? confirmation : "no";
  //   if (confirmation.toLocaleLowerCase() === "yes") {
  //     console.log("yes");
  //     let res = await Axios.post("/api/stores/delete", { store });
  //     getStoresList();
  //   }
  // };
  return (
    <Fragment>
      <div
        className={!store.deleted ? "card" : "card card-paused "}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          cursor: "pointer",
          color: !store.deleted ? "black" : "white",
        }}
      >
        <div
          className="store-card-button"
          data-tooltip="Delete store only if store is closed"
        >
          {" "}
          {!store.deleted ? (
            <i
              className="fas fa-times"
              onClick={() => deleteStore()}
              style={{ marginRight: "10px", fontSize: "25px" }}
            ></i>
          ) : (
            <i
              className="far fa-play-circle"
              onClick={() => enableStore()}
              style={{ marginRight: "10px", fontSize: "25px" }}
            ></i>
          )}
        </div>

        <div style={{ margin: "0 auto" }}>
          <p style={{ textTransform: "capitalize", textAlign: "center" }}>
            {store.storeName ? store.storeName : store}
          </p>
        </div>
        <button
          type="button"
          className="btn btn-light"
          onClick={() => toggleForm2()}
        >
          Edit
        </button>
      </div>
      {/* {form} */}
      {activeStore === storeIndex ? (
        <EditForm store={store} getStoresList={getStoresList} />
      ) : null}
    </Fragment>
  );
};

export default Store;

import React, { useEffect, useState } from "react";
import Axios from "axios";
import Cryptr from "cryptr";
import { confirmation } from "../../../utils/confirmation";

function EditForm({ store, getStoresList }) {
  const [aggregators, setAggregators] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [form, setForm] = useState({});
  const inputFieldIds = {
    storeName: "Store Name",
    storeEmail: "Store Email",
    channelID: "Channel ID",
    shopifyAPIUsername: "Shopify API Username",
    shopifyAPIPassword: "Shopify API Password",
    addressThreshold: "Address Threshold",
    sendGridAPIKey: "SendGrid API Key",
    googleAnalyticsViewId: "Google Analytics View ID",
    smsMobiSenderId: "SmsMobi SenderId",
  };

  const populateFormState = (store) => {
    const temperoryObj = {};
    if (store.accounts) {
      store.accounts.map((account) => {
        let fieldName = account.name;
        if (fieldName.includes("ShipRocket")) fieldName += " Channel ID";
        temperoryObj[fieldName] = account.channelID ? account.channelID : "";
      });
    }
    console.log(temperoryObj);
    setForm({
      ...store,
      ...temperoryObj,
    });
  };

  const edit = (e) => {
    console.log(e);
    console.log("id = ", e.target.id);
    const id = e.target.id;
    console.log(form[id]);
    setForm((form) => {
      form[id] = document.getElementById(id).value;
      return form;
    });
  };

  const fetchUser = () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let user = JSON.parse(cryptr.decrypt(info));
    console.log(user.role);
    setUserRole(user.role);
  };

  const getAggregators = async () => {
    const {
      data: { aggregators = [], encryptedApiKeys },
    } = await Axios.get("/api/control/fetchAggregators");
    if (!aggregators) {
      alert("Unable to fetch aggregators!");
    }
    setAggregators(aggregators);
  };

  const submit = async () => {
    console.log(form);
    const formKeys = Object.keys(form);
    const newStore = {
      storeName: "",
      storeEmail: "",
      shopifyName: "",
      channelID: "",
      shopifyAPIUsername: "",
      shopifyAPIPassword: "",
      shiprocketAPIUsername: "",
      shiprocketAPIPassword: "",
      addressThreshold: "",
      sendGridAPIKey: "",
      googleAnalyticsViewId: "",
      smsMobiSenderId: "",
    };
    for (const key in newStore) {
      newStore[key] = form[key];
    }
    if (!newStore.accounts) {
      newStore.accounts = [];
    }

    // check if there are "***** Channel ID" fields, if it is there  add it into accounts field of store
    for (const key of formKeys) {
      if (/ Channel ID/.test(key)) {
        const aggregator = key.slice(0, -11);
        console.log(aggregator);
        newStore.accounts.push({
          name: aggregator,
          channelID: form[key],
        });
      }
    }

    console.log("final Store object");
    console.log(newStore);

    if (confirmation()) {
      let result = await Axios.post("/api/stores/edit", { newStore });
      if (result) {
        alert(result.data);
        getStoresList();
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    "Initial stuff";
    getAggregators();
    fetchUser();
  }, []);

  useEffect(() => {
    console.log("initiating form population");
    populateFormState(store);
  }, [store]);

  return (
    <div>
      {Object.keys(inputFieldIds).map((field) => {
        return (
          <div>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  {inputFieldIds[field]}
                </span>
              </div>
              <input
                id={field}
                type="text"
                className="form-control"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                required={true}
                onChange={edit}
                defaultValue={form[field]}
              />
            </div>
          </div>
        );
      })}

      {aggregators.map((aggregator) => {
        const field = `${aggregator.name} Channel ID`;
        if (aggregator.name.includes("ShipRocket"))
          return (
            <div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    {field}
                  </span>
                </div>
                <input
                  id={field}
                  type="text"
                  className="form-control"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  required={true}
                  onChange={edit}
                  defaultValue={form[field]}
                />
              </div>
            </div>
          );
      })}
      <button
        className="btn btn-danger"
        style={{ borderRadius: 0, width: "100%", marginBottom: "20px" }}
        onClick={() => submit()}
      >
        Submit
      </button>
    </div>
  );
}

export default EditForm;

import React from "react";
import "./TrackingApp.css";
import NavBar from "./OrderTracker/components/layouts/NavBar";
import { UserProvider } from "./OrderTracker/components/context/user-context";
import Dashboard from "./OrderTracker/components/layouts/Dashboard";

function OrderTracker() {
  document.title = "Tracking Board";
  return (
    <div>
      <UserProvider>
        <NavBar />
        <Dashboard />
      </UserProvider>
    </div>
  );
}

export default OrderTracker;

import React, { useEffect, useState } from "react";
import Axios from "axios";
import useAlert from "../../global/Alert";

const ViewDiscountCoupons = ({ setTab }) => {
  const [discounts, setDiscounts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [AlertBox, setNotification] = useAlert();

  const fetchDiscountCoupons = async () => {
    const { data } = await Axios.get("/api/admin/fetchDiscountCoupons");
    if (!data.status) {
      alert(data.message);
      return;
    }
    setDiscounts(data.discountCoupons);
    setSearchResults(data.discountCoupons);
  };

  const searchCoupons = () => {
    if (searchInput) {
      const regex = RegExp(searchInput, "i");
      const results = discounts.filter((discount) => {
        if (regex.test(discount.discountName)) return true;
        if (
          discount.productsX.length > 0 &&
          regex.test(discount.productsX[0].title)
        )
          return true;
        if (
          discount.productsY &&
          discount.productsY.length > 0 &&
          regex.test(discount.productsY[0].title)
        )
          return true;
        return false;
      });
      setSearchResults(results);
    }
  };

  const handleDelete = async (discountCode) => {
    const confirmation = prompt(`Type "yes" to delete the discount coupon!`);
    if (confirmation.toLowerCase() === "yes") {
      const payload = {
        discountCode,
      };
      const { data } = await Axios.post(
        "/api/admin/deleteDiscountCoupon",
        payload
      );
      if (data.status) {
        const newDiscounts = discounts.filter(
          (d) => !(discountCode === d.discountCode)
        );
        setDiscounts(newDiscounts);
        setSearchResults(newDiscounts);
      }
      setNotification(data.status ? "success" : "failure", data.message);
    } else {
      setNotification("failure", "Could not delete coupon code!");
      return;
    }
  };
  const handleCouponCodeCopy = (index) => {
    const copyTextValue = document.getElementById(`coupon-code-${index}`)
      .innerHTML;
    navigator.clipboard.writeText(copyTextValue);
    setNotification("success", "Coupon code copied!");
  };

  const handleFinalDiscountSummary = async (discount, index) => {
    let productsArray = [];
    if (discount.productsY) {
      productsArray = [...discount.productsX, ...discount.productsY];
    } else {
      productsArray = [...discount.productsX];
    }

    const uniqueVids = {};
    const transformedProductArray = [];
    for (const lineItem of productsArray) {
      if (!uniqueVids[lineItem.product.variant_id]) {
        uniqueVids[lineItem.product.variant_id] = true;
        transformedProductArray.push({
          pid: lineItem.product.product_id,
          vid: lineItem.product.variant_id,
        });
      }
    }

    const payload = {
      productArray: transformedProductArray,
      store: discount.store.shopifyName,
    };
    const { data } = await Axios.post(
      "/api/support/fetchLatestProductPrice",
      payload
    );
    let vidFound;
    for (const vid in uniqueVids) {
      vidFound = data.find((item) => item.vid == vid);
      if (!vidFound) {
        setNotification(
          "failure",
          "Unable to find one of the products on Shopify! Kindly check on shopify if all the variants exist!"
        );
        return;
      }
    }

    const newItemsWithPrice = [];
    for (const product of discount.productsX) {
      const { price } = data.find(
        (item) => item.vid === product.product.variant_id
      );
      product.price = price;
    }
    if (discount.productsY) {
      for (const product of discount.productsY) {
        const { price } = data.find(
          (item) => item.vid === product.product.variant_id
        );
        product.price = price;
      }
    }

    const spanElement = document.getElementById(`discount-summary-${index}`);

    let finalSummary = `BUY `;
    if (discount.discountType === "fixed-amount") {
      // FIXED AMOUNT DISCOUNT SUMMARY

      let total = 0;
      let i = 1;
      for (const lineItem of discount.productsX) {
        finalSummary += `${lineItem.quantity} ${lineItem.product.title.slice(
          0,
          -16
        )}`;
        total += parseFloat(lineItem.price) * parseFloat(lineItem.quantity);
        if (i !== discount.productsX.length) {
          finalSummary += `, `;
        }
        i++;
      }
      finalSummary += `for a TOTAL PRICE of \u20B9${total - discount.value}`;

      if (spanElement) {
        spanElement.innerHTML = finalSummary;
      }
      setTimeout(() => {
        const spanElement = document.getElementById(
          `discount-summary-${index}`
        );
        if (spanElement) {
          spanElement.innerHTML = null;
        }
      }, 60000);
    } else if (discount.discountType === "percentage") {
      // PERCENTAGE DISCOUNT SUMMARY

      let total = 0;
      let i = 1;
      for (const lineItem of discount.productsX) {
        finalSummary += `${lineItem.quantity} ${lineItem.product.title.slice(
          0,
          -16
        )}`;
        total += parseFloat(lineItem.price) * parseFloat(lineItem.quantity);
        if (i !== discount.productsX.length) {
          finalSummary += `, `;
        }
        i++;
      }
      finalSummary += `for a TOTAL PRICE of \u20B9${
        (total * (100 - discount.value)) / 100
      }`;

      if (spanElement) {
        spanElement.innerHTML = finalSummary;
      }
      setTimeout(() => {
        const spanElement = document.getElementById(
          `discount-summary-${index}`
        );
        if (spanElement) {
          spanElement.innerHTML = null;
        }
      }, 60000);
    } else if (discount.discountType === "buy-x-get-y") {
      // BUY X GET Y DISCOUNT SUMMARY

      let total = 0;
      let i = 1;
      for (const lineItem of discount.productsX) {
        finalSummary += `${lineItem.quantity} ${lineItem.product.title.slice(
          0,
          -16
        )}`;
        total += parseFloat(lineItem.price) * parseFloat(lineItem.quantity);
        if (i !== discount.productsX.length) {
          finalSummary += `, `;
        }
        i++;
      }
      finalSummary += `for a TOTAL PRICE of \u20B9${total}`;

      finalSummary += ` and GET `;
      i = 1;
      total = 0;
      for (const lineItem of discount.productsY) {
        finalSummary += `${lineItem.quantity} ${lineItem.product.title.slice(
          0,
          -16
        )}`;
        total += lineItem.quantity * lineItem.price;
        if (i !== discount.productsY.length) {
          finalSummary += `, `;
        }
        i++;
      }
      finalSummary += ` worth \u20B9${total} for free!`;

      if (spanElement) {
        spanElement.innerHTML = finalSummary;
      }
      setTimeout(() => {
        const spanElement = document.getElementById(
          `discount-summary-${index}`
        );
        if (spanElement) {
          spanElement.innerHTML = null;
        }
      }, 60000);
    } else if (discount.discountType === "buy-x-at-fixed-price") {
      // FIXED TOTAL PRICE DISCOUNT SUMMARY

      let total = 0;
      let i = 1;
      for (const lineItem of discount.productsX) {
        finalSummary += `${lineItem.quantity} ${lineItem.product.title.slice(
          0,
          -16
        )}`;
        total += parseFloat(lineItem.price) * parseFloat(lineItem.quantity);
        if (i !== discount.productsX.length) {
          finalSummary += `, `;
        }
        i++;
      }
      finalSummary += `for a TOTAL PRICE of \u20B9${discount.value}`;

      if (spanElement) {
        spanElement.innerHTML = finalSummary;
      }
      setTimeout(() => {
        const spanElement = document.getElementById(
          `discount-summary-${index}`
        );
        if (spanElement) {
          spanElement.innerHTML = null;
        }
      }, 60000);
    }
  };

  useEffect(() => {
    fetchDiscountCoupons();
  }, []);

  useEffect(() => {
    if (!searchInput) {
      setSearchResults(discounts);
    }
  }, [searchInput]);

  const styles = {
    backButtonContainer: {
      marginLeft: "2vw",
      marginTop: "2vh",
      width: "auto",
      color: "#2980b9",
    },
  };

  const Button = ({ content, className, onClickFunction }) => {
    return (
      <button
        className={className ? className : "button-style-1"}
        onClick={onClickFunction}
      >
        {content}
      </button>
    );
  };

  return (
    <>
      <AlertBox />
      <div style={styles.backButtonContainer}>
        <i
          className="fas fa-2x fa-arrow-circle-left back-button-icon"
          onClick={() => setTab("main")}
        ></i>
      </div>
      <div className="reusable-card-container">
        <div className="reusable-card-header">
          <h3>View discounts</h3>
        </div>
        <div className="reusable-card-body">
          <div className="reusable-small-card" style={{ display: "flex" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Search coupons ..."
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            ></input>
            <button
              type="submit"
              style={{
                width: "10%",
                placeItems: "center",
                marginLeft: "1rem",
                backgroundColor: "#eee",
                color: "#000",
              }}
              onClick={searchCoupons}
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
          {searchResults.map((discount, index) => (
            <div className="reusable-small-card">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ width: "70%" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "30% 70%" }}
                  >
                    <div>
                      <b>Name</b>
                    </div>
                    <div>{discount.discountName}</div>
                  </div>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "30% 70%" }}
                  >
                    <div>
                      <b>Store</b>
                    </div>
                    <div>{discount.store.shopifyName}</div>
                  </div>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "30% 70%" }}
                  >
                    <div>
                      <b>Discount type</b>
                    </div>
                    <div>{discount.discountType}</div>
                  </div>
                  {discount.discountType === "fixed-amount" ||
                  discount.discountType === "percentage" ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30% 70%",
                      }}
                    >
                      <div>
                        <b>Discount value</b>
                      </div>
                      {discount.discountType === "fixed-amount" ? (
                        <div>{`\u20B9${discount.value}`}</div>
                      ) : null}
                      {discount.discountType === "percentage" ? (
                        <div>{`${discount.value}%`}</div>
                      ) : null}
                    </div>
                  ) : null}
                  {(discount.discountType === "fixed-amount" ||
                    discount.discountType === "percentage" ||
                    discount.discountType === "buy-x-at-fixed-price") &&
                  discount.productsX.length > 0 ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30% 70%",
                      }}
                    >
                      <div>
                        <b>Products</b>
                      </div>
                      <div>
                        {discount.productsX.map((item) => (
                          <div>{`${item.product.title} X ${item.quantity}`}</div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {discount.discountType === "buy-x-get-y" ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30% 70%",
                      }}
                    >
                      <div>
                        <b>Buy products</b>
                      </div>
                      <div>
                        {discount.productsX.map((item) => (
                          <div>{`${item.product.title} X ${item.quantity}`}</div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {discount.discountType === "buy-x-get-y" ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30% 70%",
                      }}
                    >
                      <div>
                        <b>Get products</b>
                      </div>
                      <div>
                        {discount.productsY.map((item) => (
                          <div>{`${item.product.title} X ${item.quantity}`}</div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div
                    style={{ display: "grid", gridTemplateColumns: "30% 70%" }}
                  >
                    <div>
                      <b>Coupon code</b>
                    </div>
                    <div>
                      <span id={`coupon-code-${index}`}>
                        {discount.discountCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ width: "30%" }}>
                  <Button
                    content={`Fetch latest price`}
                    onClickFunction={() =>
                      handleFinalDiscountSummary(discount, index)
                    }
                  />
                  <Button
                    content={`Copy Coupon Code`}
                    onClickFunction={() => handleCouponCodeCopy(index)}
                  />
                  <Button
                    content={`Delete Coupon`}
                    onClickFunction={() => handleDelete(discount.discountCode)}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "1rem",
                  fontSize: "1.1rem",
                }}
              >
                <b>
                  <span id={`discount-summary-${index}`}></span>
                </b>
              </div>
            </div>
          ))}
          {searchResults.length === 0 ? (
            <div style={{ textAlign: "center", marginBottom: "2rem" }}>
              <b>No discount coupons to display!</b>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ViewDiscountCoupons;

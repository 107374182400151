import React from "react";

const ScanLog = ({ scans, color, header }) => {
  const styles = {
    container: {
      marginTop: "20px",
      padding: "5px",
      textAlign: "center",
      backgroundColor: "#f0f0f073",
    },
    scanContainer: {
      display: "flex",
      columnGap: "5%",
      textAlign: "left",
    },
    scanItem: {
      textAlign: "left",
      width: "20%",
      color,
    },
  };
  return (
    <div style={styles.container}>
      <h4>{header}</h4>
      {scans.map((scan) => (
        <div style={styles.scanContainer}>
          <div style={styles.scanItem}>{scan.awb}</div>
          <div>{scan.message}</div>
        </div>
      ))}
    </div>
  );
};

export default ScanLog;

const types = [
  {
    name: "Address Issue",
    value: "callCustomer",
    active: false,
  },
  {
    name: "Repeat Customer",
    value: "repeatCustomer",
    active: false,
  },
  {
    name: "Multiple Items",
    value: "multipleItems",
    active: false,
  },
  {
    name: "Multiple Quantity",
    value: "multipleQuantity",
    active: false,
  },
  {
    name: "Product Confirmation",
    value: "productConfirmation",
    active: false,
  },
  {
    name: "NDR",
    value: "ndr",
    active: false,
  },
  {
    name: "Abandoned Checkout",
    value: "abandonedCheckout",
    active: false,
  },
  {
    name: "Upsell",
    value: "upsell",
    active: false,
  },
  {
    name: "Leads",
    value: "lead",
    active: false,
  },
  //   {
  //     name: "Order Confirmation",
  //     value: ""
  //   }
];

module.exports = types;

import axios from "axios";

export const sendOrderDetails = async (formLength) => {
  let i = 1;
  let products = [];
  let product = {};
  let supplier = document.getElementById(`supplier`).value;
  let transporter = document.getElementById(`transporter`).value;
  let customer = document.getElementById(`customers`).value;
  const prelaunch = document.getElementById(`prelaunch`).checked;

  while (i <= formLength) {
    let name = document.getElementById(`product_${i}`).value;
    let quantity = document.getElementById(`quantity_${i}`).value;
    let price = document.getElementById(`price_${i}`).value;
    let gst = document.getElementById(`gst_${i}`).value;
    let total = document.getElementById(`total_${i}`).value;

    let prod = global.products.find((product) => product.name === name);

    product = {
      name,
      id: prod.id,
      quantity,
      price,
      gst,
      total,
    };

    products.push(product);
    i++;
  }
  let date = new Date();
  let data = {
    date,
    supplier,
    customer,
    transporter,
    products,
  };

  if (prelaunch == true) data.prelaunch = true;

  let res = await axios({
    method: "post",
    url: "/api/inventory/order",
    data,
  });

  if (res.data === "Added") {
    alert("Successfully Added Order");
    console.log(`${res.data}`);
    return true;
  } else {
    alert("Error While Adding", res.data);
    console.log(`${res.data}`);
    return false;
  }
};

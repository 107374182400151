import Axios from "axios";
import { set } from "lodash";
import React, { useEffect, useState } from "react";

export default function Customer() {
  const [loading, setLoading] = useState(false);
  const [awb_ref_no, setAwb] = useState("");

  const fetchLink = async () => {
    let result = null;

    if (!awb_ref_no) return alert("Please enter your tracking number!");

    try {
      setLoading(true);

      let result = await Axios.get(
        `/api/orders/searchOrderCustomer?awb_ref_no=${awb_ref_no}`
      );

      const {
        link,
        success,
        message = "Something went wrong, please try again later",
      } = result.data;

      if (success) window.open(link, true);
      else alert(message);
    } catch (error) {
      console.log(error);
      alert("Please try again later!");
    }

    setLoading(false);
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        "Redirecting you to your tracking page"
      ) : (
        <div style={{ width: "100%" }}>
          <center style={{ marginBottom: "20px", letterSpacing: "2px" }}>
            PLEASE ENTER YOUR TRACKING NUMBER.
          </center>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <input
              className="tracking_input"
              style={{ width: "70%" }}
              placeholder="Enter the number"
              onKeyDown={(e) => {
                setAwb(e.target.value);
                if (e.key === "Enter") fetchLink();
              }}
            />
            <button
              className="tracking_input"
              type="submit"
              onClick={() => fetchLink()}
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

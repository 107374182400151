import React, { useEffect, useState, useRef, useContext } from "react";
import Axios from "axios";
import { GlobalConsumer } from "../../Global-Context/global-context";
import Select from "react-select";

export default function useProductPicker(modern) {
  const [inventory, setInventory] = useState([]);
  const [category, setCategory] = useState([]);
  const [selected, setSelected] = useState("Choose a product");
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [pmode, setPmode] = useState("P");
  const [chosen, setChosen] = useState(null);

  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "white" : "black",
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 250,
      display: "flex",
      height: "60px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const fetchProducts = async () => {
    let result = await Axios.get(
      `/api/IMS/getInventory${user ? `?user_id=${user.user_id}` : ""}`
    );
    setInventory(result.data.inventory);

    const cat = [];

    result.data.inventory.map((item) => {
      if (item.category && !cat.find((i) => i.label === item.category)) {
        cat.push({
          label: item.category,
          value: JSON.stringify(item.category),
        });
      }
    });

    setCategory(cat);

    let modified = result.data.inventory.map((item) => {
      const obj = {
        label: item.name,
        value: JSON.stringify(item),
      };
      return obj;
    });

    setOptions(modified);
  };

  const getSelectedProducts = () => {
    if (selected == "Choose a product") return;
    let items = JSON.parse(selected) || [];
    items = items.products || [];
    setProducts((current) => [...current, ...items]);
    setSelected("Choose a product");
  };

  const removeProduct = (variant_id) => {
    setProducts((current) =>
      current.filter((item) => item.variant_id !== variant_id)
    );
  };

  const clearProducts = () => {
    setProducts([]);
    setSelected("Choose a product");
  };

  const getProductArray = (type) => {
    if (type === "p") {
      return products.map((product) => product.product_id);
    } else {
      return products.map((product) => product.variant_id);
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    getSelectedProducts();
  }, [selected]);

  const ProductPickerV2 = ({ customProductSelectionStyle }) => {
    return (
      <div style={{ ...customProductSelectionStyle }}>
        <div style={{ display: "flex" }}>
          <div className="product-box-check">
            <div
              style={pmode === "P" ? { background: "rgb(161 188 206)" } : {}}
              onClick={() => setPmode("P")}
            >
              P
            </div>
            <div
              style={pmode === "C" ? { background: "rgb(161 188 206)" } : {}}
              onClick={() => setPmode("C")}
            >
              C
            </div>
          </div>
          {pmode === "P" ? (
            <Select
              label="Choose a product"
              options={options}
              placeholder="Choose a product"
              onChange={(item) => {
                setChosen(item.label);
                setSelected(item.value);
              }}
              styles={customStyles}
            />
          ) : (
            <Select
              label="Choose a category"
              options={category}
              placeholder="Choose a category"
              onChange={(item) => {
                setSelected(item.value);
              }}
              styles={customStyles}
            />
          )}
        </div>
      </div>
    );
  };

  const ProductPicker = ({ customProductSelectionStyle }) => (
    <div
      style={{ width: "100%", height: "100%", ...customProductSelectionStyle }}
    >
      <select
        placeholder="Products"
        id="datePickerRange"
        className="form-control form-control-sm defaultInput"
        value={selected}
        ref={dropdownRef}
        onChange={(e) => {
          setSelected(e.target.value);
        }}
      >
        <option selected disabled>
          Choose a product
        </option>
        {inventory &&
          inventory.length > 0 &&
          inventory.map((product) => (
            <option
              value={JSON.stringify(product)}
              title={product.products[0] ? product.products[0].name : ""}
            >
              {product.name.toUpperCase()}
            </option>
          ))}
      </select>
    </div>
  );

  const ProductDisplay = ({ customTagBarStyle }) => (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        ...customTagBarStyle,
      }}
    >
      {products.map((product) => (
        <div
          className="itemTag"
          style={{ width: "fit-content" }}
          id={`tag_${product}`}
        >
          {`${product.name} - ${product.store.storeName || product.store}`}
          <div
            className="cross"
            onClick={() => removeProduct(product.variant_id)}
          >
            x
          </div>{" "}
        </div>
      ))}
    </div>
  );

  const OptionDisplay = ({ customTagBarStyle }) =>
    chosen ? (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          ...customTagBarStyle,
        }}
      >
        <div
          className="itemTag"
          style={{ width: "fit-content" }}
          id={`tag_${chosen}`}
        >
          {chosen}
          <div className="cross" onClick={() => setChosen(null)}>
            x
          </div>{" "}
        </div>
      </div>
    ) : (
      ""
    );

  return [
    modern ? ProductPickerV2 : ProductPicker,
    ProductDisplay,
    products,
    setProducts,
    removeProduct,
    clearProducts,
    getProductArray,
    chosen,
    OptionDisplay,
  ];
}

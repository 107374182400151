import React, { Fragment, useState } from "react";
import Axios from "axios";
import { getCourierName } from "../../../trackingboard/OrderTracker/functions/getCourierName";
import ScannedCourierCompanies from "../res/ScannedCourierCompanies";
import ScannedFails from "../res/ScannedFails";
import Modal from "../res/Modal";
import Counter from "../res/Counter";
import Spinner from "../../global/Spinner";
let audio = new Audio(require("../res/sounds/tink.wav"));

// The name of the function is wrong dont mind that
function ScanAndShipAWBForward({ setPage }) {
  document.title = "Scan rejected forward AWB";
  const [awb, setAwb] = useState({});
  const [result, setResult] = useState({});
  const [scannedCount, setScannedCount] = useState(0);
  const [failScans, setFailScans] = useState([]);
  const [couriers, setCouriers] = useState({});
  const [loading, setLoading] = useState(false);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) handleSubmit();
  };

  const handleChange = (e) => {
    let awbItem = { AWBItem: e.target.value };
    setAwb(awbItem);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (awb.AWBItem.length !== 0) {
      const { data } = await Axios.get(
        `/api/warehouse/unsetDispatchedAWB/${awb.AWBItem}`
      );
      if (data.status) {
        setScannedCount((scannedCount) => {
          return scannedCount + 1;
        });
        const { name } = getCourierName(data.courierCompanyId);
        setCouriers((couriers) => {
          let index;
          couriers[name]
            ? (index = Object.keys(couriers[name]).length)
            : (index = 0);
          couriers[name] = { ...couriers[name], [index]: data.awb };
          return couriers;
        });
        audio.play();
        setAwb({ AWBItem: "" });
      } else {
        const fail = {
          awb: data.awb,
          message: data.message,
        };
        setFailScans((failScans) => {
          return [...failScans, fail];
        });
        audio.play();
        setAwb({ AWBItem: "" });
      }
    }
    setLoading(false);
  };

  const styles = {
    backButtonContainer: {
      marginLeft: "2vw",
      marginTop: "2vh",
      width: "auto",
      color: "#e74c3c",
    },
  };

  return (
    <Fragment>
      <div style={styles.backButtonContainer}>
        <i
          className="fas fa-2x fa-arrow-circle-left back-button-icon"
          onClick={() => setPage("main")}
        ></i>
      </div>
      <div className="scan-awb-frontend">
        {/* <div className="scan-awb-header-container">
          <div className="scan-awb-header-rejected">
            <h2>Rejected Packages Forward</h2>
            <h5>Total scan count - {scannedCount + failScans.length}</h5>
            <Counter count={scannedCount + failScans.length} />
          </div>
        </div> */}
        <div
          className="scanning-forward-header-container"
          style={{ backgroundColor: "#E74C3C" }}
        >
          <div className="reverse-scanning-header">
            <h2>Rejected Packages Forward</h2>
          </div>
          <Counter count={scannedCount + failScans.length} />
        </div>
        <div className="scan-awb-body">
          <div>
            <input
              type="text"
              name="awb_no"
              id="awb_input"
              className="form-control form-control-lg"
              placeholder="Scan AWB that was rejected here"
              value={awb.AWBItem}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
            ></input>
            {loading ? (
              <div>
                <Spinner />
              </div>
            ) : null}
          </div>
          <div className="scan-awb-message">
            <h4 id="result_msg">{result.message}</h4>
          </div>
          {Object.keys(couriers).length == 0 ? null : (
            <ScannedCourierCompanies
              couriers={couriers}
              scannedCount={scannedCount}
            />
          )}
          {failScans.length == 0 ? null : (
            <ScannedFails failScans={failScans} />
          )}
        </div>
        <audio src="../res/sounds/tink.wav" id="scanned_sound"></audio>
      </div>
    </Fragment>
  );
}

export default ScanAndShipAWBForward;

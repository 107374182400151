import React, { createContext } from "react";
import axios from "axios";
import moment from "moment";
//Creating context of available global states
const UserContext = createContext({
  users: [],
  total: "",
  filtered: {},
  customRange: "",
  setDate: () => {},
  fetchOrders: () => {},
  permuteOrders: () => {},
  computeTotal: () => {},
});

export class CallerProvider extends React.Component {
  computeTotal = () => {
    let type = document.getElementById("call-type-selector-all").value;
    let reason = document.getElementById("call-status-selector-all").value;
    if (type == "Call Type" && reason == "Call Status") {
      alert("Please select Call Type and Call Status!");
      return;
    }

    let count = 0;
    this.state.users.map((user) => {
      Object.keys(user.logs).map((day) => {
        count += user.logs[day][type][reason];
      });
    });
    let user = this.state.users[0];
    user ? console.log("total Count", count) : console.log("load");
    this.setState({ total: count });
  };

  permuteOrders = async (user) => {
    this.setState({ filtered: {} });

    const from = this.state.fromDate || moment("2019");
    const to = this.state.toDate || moment().endOf("day");

    let result;

    user.name !== "Team"
      ? (result = await axios.post("/api/support/fetchLogs", {
          from,
          to,
          name: user.name,
        }))
      : (result = await axios.post("/api/support/fetchAllLogs", {
          from,
          to,
        }));

    this.setState({ filtered: result.data });
    console.log("Stats", result.data);
  };

  setDate = (fromDate, toDate) => {
    console.log(
      `(CALLER TRACKING)\nFrom Date:${new Date(
        fromDate
      ).toLocaleString()}\nTo Date:${new Date(toDate).toLocaleString()}`
    );
    this.setState({ fromDate, toDate });
  };

  fetchOrders = async () => {
    let result = await axios.get(`/api/callerDashboard/fetchUsers`);
    // add in team
    let teamLogsArray = [];
    let flag;
    result.data.map((user) => {
      user.logs.map((log) => {
        if (teamLogsArray.length == 0) {
          teamLogsArray.push({
            date: log.date,
            [user.name]: log.count,
          });
        } else {
          flag = 0;
          teamLogsArray.map((dateLog, index) => {
            if (dateLog.date == log.date) {
              flag = 1;
              teamLogsArray[index][user.name] = log.count;
            }
          });
          if (flag == 0) {
            teamLogsArray.push({
              date: log.date,
              [user.name]: log.count,
            });
          }
        }
      });
    });

    const teamObject = {
      name: "Team",
      logs: teamLogsArray,
    };
    result.data.unshift(teamObject);
    this.setState({ users: result.data });
    console.log("GT11", result.data);
  };

  //   State Variables
  state = {
    fromDate: "",
    toDate: "",
    customRange: "",
    users: [],
    filtered: {},
    total: "",
    fetchOrders: this.fetchOrders,
    setDate: this.setDate,
    permuteOrders: this.permuteOrders,
    computeTotal: this.computeTotal,
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

//Registering a consumer who can access the global state
export const UserConsumer = UserContext;

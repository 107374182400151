import React from "react";
import Axios from "axios";
import { copy } from "../../utils/copyfunction";
import { confirmation } from "../../utils/confirmation";

function MappedItem({
  product,
  inventoryID,
  fetchInventory,
  number,
  user,
  hideCross,
  mappedToItem,
}) {
  const handleClick = async (e) => {
    let element = JSON.parse(e.target.id);
    console.log(element);
    if (confirmation()) await Axios.post("/api/IMS/unMap", element);
    else alert("Wrong input");
    fetchInventory();
  };

  return (
    <div
      className="itemTag custom-tooltip"
      style={{
        borderColor: hideCross ? "#2C3E50" : "#EB5F51",
        background: hideCross ? "#2C3E50" : "white",
        color: hideCross ? "white" : "black",
      }}
    >
      <span
        className="tooltiptext"
        id={`tool_${product.variant_id}-${number}`}
      >{`Product ID:${product.product_id} Variant ID:${
        product.variant_id
      } Store:${
        product.store.storeName ? product.store.storeName : product.store
      }`}</span>

      {!mappedToItem ? (
        <div
          className="cross"
          id={`${JSON.stringify({
            store: product.store,
            inventory_id: inventoryID,
            product_id: product.product_id,
            variant_id: product.variant_id,
          })}`}
          style={{
            marginRight: "15px",
            display: hideCross ? "none" : "block",
          }}
          onClick={(e) => handleClick(e)}
        >
          x
        </div>
      ) : (
        ""
      )}

      {!mappedToItem ? (
        <div
          id={`${product.variant_id}-${number}`}
          onClick={(e) => copy(e)}
        >{`${product.name} ${product.combo ? "- (combo)" : ""}${
          product.bundle ? "- (bundle)" : ""
        }`}</div>
      ) : (
        <div id={`${product.variant_id}-${number}`}>
          <div>{`${product.name} ${product.combo ? "- (combo)" : ""}${
            product.bundle ? "- (bundle)" : ""
          }`}</div>
          <div>
            <center>({mappedToItem.id})</center>
          </div>
        </div>
      )}

      <div id="snackbar">Copied To Clipboard</div>
    </div>
  );
}

export default MappedItem;

import React, { useState, useEffect, Fragment, useContext } from "react";
import Axios from "axios";
import Spinner from "./res/Spinner";
import useDatePicker from "../global/DatePicker";
import { GlobalConsumer } from "../../Global-Context/global-context";
import TagBar from "../../trackingboard/OrderTracker/components/Items/TagBar";

function StandardModule({ name, number, active, setActive, columns }) {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [data, setData] = useState(false);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [pastData, setPastData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [user] = useState(userInfo);

  const refreshvalues = async () => {
    setStore({});
    global.selected = [];
    clearDate();
  };

  const multiCheck = (e, value) => {
    if (!isNaN(parseFloat(value))) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";

      if (value2send) {
        global.selected.push([...value2send.split(",")]);

        document.getElementById("productSearch").value = "";

        let productList = global.selected.map((item) => item[0]);

        let newArray = products.filter((product) => {
          return !productList.includes(product[0].toString());
        });

        setFiltered(newArray);
      } else {
        alert("Choose a product");
      }
    }
  };
  const getProducts = async () => {
    const userData = user.user_id;
    let result = await Axios.get(
      `/api/product/getAllFavourites?user=${userData}`
    );

    let dummy = [];
    for (let i of result.data.products)
      dummy.push([parseFloat(i.product_id), i.title]);
    setProducts(dummy);
    setFiltered(dummy);
  };

  const initializaModule = async (from, to) => {
    let result = {};
    let store = JSON.parse(document.getElementById("store_selector").value);

    if (!store) return alert("Please choose a store");

    if (active === number) {
      switch (name) {
        case "Cancellation Module":
          setLoading(true);
          result = await Axios.post("/api/stats/cancellationModule", {
            store,
            from,
            to,
          });
          setLoading(false);
          break;

        case "Delivery Module":
          setLoading(true);
          let selectedProducts = global.selected;

          let productsList = selectedProducts.map((item) => {
            return parseInt(item[0]);
          });

          if (
            Object.keys(
              JSON.parse(document.getElementById("store_selector").value)
            ).length !== 0
          ) {
            result = await Axios.post("/api/stats/deliveryModule", {
              store,
              from,
              to,
              pidArray: productsList ? productsList : [],
            });
            setLoading(false);
          } else {
            alert("Please select a store!");
            setLoading(false);
          }

          break;

        case "Calling Module":
          setLoading(true);
          result = await Axios.post("/api/stats/callingModule", {
            store,
            from,
            to,
          });
          setLoading(false);
          break;

        default:
        // code block
      }
      if (Object.keys(result).length !== 0) {
        setData(result.data.data);

        setPastData(result.data.pastData);
      }
    }
  };
  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };

  useEffect(() => {
    getStores();
    getProducts();
    return () => {
      clearDate();
    };
  }, [active]);

  return (
    <Fragment>
      {active === number ? (
        <center>
          <p style={{ borderBottom: "1px solid black", width: "100px" }}>
            {name}
          </p>
        </center>
      ) : (
        ""
      )}
      <div
        id={`module_${number}`}
        className={`card moduleCard ${
          active === number ? "activeModuleCard" : ""
        }`}
        style={{ marginBottom: "30px" }}
        onClick={(e) => setActive(number)}
      >
        {active === number ? (
          <Fragment>
            {loading ? (
              <div style={{ margin: "20px" }}>
                <Spinner />
              </div>
            ) : (
              <div className="moduleContent">
                {name == "Delivery Module" ? (
                  global.selected.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "10px 10px 0px 10px",
                          width: "50%",
                        }}
                      >
                        {global.selected
                          ? global.selected.map((item, i) => (
                              <TagBar
                                item={item}
                                key={i}
                                permuteOrders={getStores}
                                setFiltered={setFiltered}
                                filtered={filtered}
                              />
                            ))
                          : ""}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginTop: "10px",
                        }}
                      ></div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <div className="moduleBar">
                  <select
                    placeholder="Stores"
                    id="store_selector"
                    className="form-control form-control-sm"
                    style={{
                      height: "50px",
                      fontSize: "0.9rem",
                      borderRadius: 0,
                    }}
                  >
                    <option value={JSON.stringify(store)} selected disabled>
                      Choose a store
                    </option>
                    {stores.map((store, index) => (
                      <option value={JSON.stringify(store)} key={index}>
                        {store.storeName}
                      </option>
                    ))}
                  </select>
                  {name == "Delivery Module" ? (
                    <div
                      style={{
                        height: "50px",
                        fontSize: "0.9rem",
                        borderRadius: 0,
                        width: "100%",
                      }}
                    >
                      <input
                        type="text"
                        id={"productSearch"}
                        list="productList"
                        autoComplete="off"
                        placeholder="Products"
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          multiCheck(e, e.target.value);
                        }}
                        style={{
                          height: "50px",
                          fontSize: "0.9rem",
                          borderRadius: 0,
                          width: "100%",
                        }}
                      />
                      <datalist id="productList">
                        {filtered.map((option, index) => (
                          <option
                            key={index}
                            data-value={option}
                            value={option[0]}
                          >
                            {option[1]}
                          </option>
                        ))}
                      </datalist>
                    </div>
                  ) : (
                    ""
                  )}

                  <div style={{ height: "50px" }}>
                    <DatePicker />
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ borderRadius: 0 }}
                    onClick={() => initializaModule(fromDate, toDate)}
                  >
                    Submit
                  </button>
                  {name == "Delivery Module" ? (
                    <button
                      className="btn btn-danger"
                      style={{ borderRadius: 0 }}
                      onClick={() => refreshvalues()}
                    >
                      Clear
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                {data || pastData ? (
                  <div className="flag-module-filter-info">
                    {`Displaying orders between ${new Date(
                      fromDate
                    ).toLocaleDateString()} & ${new Date(
                      toDate
                    ).toLocaleDateString()} `}
                  </div>
                ) : (
                  ""
                )}
                <div className="moduleTable">
                  {data || pastData ? (
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          {columns.map((column, i) => (
                            <th scope="col" key={i}>
                              {column}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {data ? (
                          <tr className="thead-light">
                            <th scope="row">*</th>
                            {columns.map((column, i) => (
                              <td key={i}>{data[column]}</td>
                            ))}
                          </tr>
                        ) : (
                          ""
                        )}
                        {pastData ? (
                          <Fragment>
                            {Object.keys(pastData).map((day, i) => {
                              return (
                                <tr>
                                  {day ? (
                                    <Fragment>
                                      <th scope="row">{day}</th>
                                      {columns.map((column, i) => {
                                        return (
                                          <td key={i}>
                                            {typeof pastData[day] === "object"
                                              ? pastData[day][column]
                                              : pastData[day]}
                                          </td>
                                        );
                                      })}
                                    </Fragment>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              );
                            })}{" "}
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </div>

                <div className="moduleTable"></div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="moduleInactive">
            <p>{name}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default StandardModule;

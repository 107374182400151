const axios = require("axios");

/**
 * This function checks if an array of orders is older then 1 month if so it will return an object containing the old orders
 * @param {Array} orders is the array of orders
 */
const oldOrderCheck = async (orders, store) => {
  try {
    const { data } = await axios.post("/api/operations/oldOrderCheck", {
      store,
      orders,
    });
    console.log(data);
    return data.oldOrders;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default oldOrderCheck;

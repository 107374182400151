import React, { useState, useEffect, createContext, Fragment } from "react";
import CreateExperiment from "../res/CreateExperiment";
import ViewExperiments from "../res/ViewExperiments";

const Experiments = () => {
	document.title = "Experiments";
	const [createExperiment, setCreateExperiment] = useState(false);
	const [viewExperiments, setViewExperiments] = useState(false);
	const [loading, setLoading] = useState(false);

	return (
		<div className="experiments-container">
			<div className="experiments-top-main-controls">
				{createExperiment ? (
					<button
						className="experiments-custom-button"
						onClick={() => setCreateExperiment(false)}
					>
						<i className="fas fa-times"></i> Discard Experiment
					</button>
				) : (
					<Fragment>
						<button
							className="experiments-custom-button"
							onClick={() => {
								setCreateExperiment(true);
								setViewExperiments(false);
							}}
						>
							<i className="fas fa-plus"></i> Create Experiment
						</button>
						<button
							className="experiments-custom-button"
							onClick={() => setViewExperiments(true)}
						>
							View Experiments
						</button>
						<button className="experiments-custom-button">
							Experiment Statistics
						</button>
					</Fragment>
				)}
			</div>
			<div className="experiments-body">
				{createExperiment ? (
					<CreateExperiment setCreateExperiment={setCreateExperiment} />
				) : null}
				{viewExperiments ? <ViewExperiments /> : null}
			</div>
		</div>
	);
};

export default Experiments;

import React, { useState, useContext } from "react";
import Axios from "axios";
import useAlert from "./Alert";
import { GlobalConsumer } from "../../Global-Context/global-context";

export default function useGlobalSearchBar() {
  const [details, setDetails] = useState(null);
  const [AlertBox, setNotification] = useAlert();
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);

  const dashboardStyle = {
    width: "100%",
    height: "100%",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
    padding: "20px",
  };

  const getOrderDetails = async (value) => {
    let input = value;
    let many = false;

    if (value.includes(",")) {
      input = value.split(",").map((item) => item.trim());
      many = true;
    }

    setNotification("success", "Please wait while we find your order");
    // HERE

    let result = await Axios.get(
      `/api/orders/searchOrder?customer=${
        user.customer ? user.user_id : null
      }&input=${encodeURIComponent(JSON.stringify(input))}&many=${many}`
    );

    if (result.data.length > 0)
      setNotification("success", "Here's what we could find");
    else
      setNotification(
        "failure",
        `Couldn't find any orders for your search - ${value}`
      );

    setDetails(result.data);
  };

  const DashboardSearchBar = () => (
    <div style={{ width: "100%", height: "100%" }}>
      <AlertBox />
      <input
        onKeyDown={(e) => {
          if (e.key === "Enter") getOrderDetails(e.target.value);
        }}
        style={dashboardStyle}
        placeholder="(SINGLE / COMMA SEPARATED) Order / AWB / Phone Number / Email"
      />
    </div>
  );

  const FloatingSearchBar = () => (
    <div></div>
    // <div className="search-button">
    //   <input
    //     onKeyDown={(e) => {
    //       if (e.key === "Enter") getOrderDetails(e.target.value);
    //     }}
    //     placeholder="Search Order/AWB/Phone number across all stores"
    //   />
    // </div>
  );

  return [
    DashboardSearchBar,
    FloatingSearchBar,
    details,
    setDetails,
    getOrderDetails,
  ];
}

import React, { useState, useEffect } from "react";
import Axios from "axios";
import RtoOrder from "../res/RtoOrder";

const RtoOrders = () => {
  document.title = "RTO Orders";
  const [orders, setOrders] = useState([]);
  const fetchOrders = async () => {
    let result = await Axios.get("/api/warehouse/retrieveAwbs");
    let awbs = [];
    console.log(result.data);

    for (let i = 0; i < result.data.msg.length; i++) {
      if (
        result.data.msg[i].awbsFound &&
        result.data.msg[i].awbsFound.length > 0
      ) {
        awbs.push(...result.data.msg[i].awbsFound);
      }
      if (
        result.data.msg[i].awbsNotFound &&
        result.data.msg[i].awbsNotFound.length > 0
      ) {
        awbs.push(...result.data.msg[i].awbsNotFound);
      }
    }
    console.log(awbs);
    setOrders(awbs);
    // setOrders(result.data.msg[0].awbsFound);
  };

  useEffect(() => {
    fetchOrders();
  }, []);
  return orders ? (
    <div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">OrderID</th>
            <th scope="col">Product</th>
            <th scope="col">Damaged</th>
            <th scope="col">Usable</th>
            <th scope="col">Missing</th>
          </tr>
        </thead>
        <tbody>
          {orders
            ? orders.map((order, i) => (
                <RtoOrder
                  key={i}
                  store={order.store}
                  order={order}
                  fetchOrders={fetchOrders}
                />
              ))
            : ""}
        </tbody>
      </table>
    </div>
  ) : (
    ""
  );
};

export default RtoOrders;

export default function (notification) {
  if (!window.Notification) {
    alert("Browser does not support notifications.");
  } else {
    // Check if permission is already granted
    if (Notification.permission === "granted") {
      // Show notification here
      new Notification(notification, {
        silent: true,
      });
    } else {
      // Request permission from user
      Notification.requestPermission()
        .then(function (p) {
          if (p === "granted") {
            // Show notification here
            new Notification(notification, { silent: true });
          } else {
            alert("User blocked notifications.");
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  }
}

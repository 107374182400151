export const copyShippingAddress = () => {
  console.log(`loaded copyShippingAddress file`);

  // copy shippingAddress in billingAddress
  document.getElementById("b_fname").value = document.getElementById(
    "s_fname"
  ).value;
  document.getElementById("b_lname").value = document.getElementById(
    "s_lname"
  ).value;
  document.getElementById("b_email").value = document.getElementById(
    "s_email"
  ).value;
  document.getElementById("b_company").value = document.getElementById(
    "s_company"
  ).value;
  document.getElementById("b_address1").value = document.getElementById(
    "s_address1"
  ).value;
  document.getElementById("b_address2").value = document.getElementById(
    "s_address2"
  ).value;
  document.getElementById("b_phone").value = document.getElementById(
    "s_phone"
  ).value;
  document.getElementById("b_city").value = document.getElementById(
    "s_city"
  ).value;
  document.getElementById("b_zip").value = document.getElementById(
    "s_zip"
  ).value;
};

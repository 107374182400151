import React from "react";

const CustomTagBarAggregator = ({
  item,
  selectedAggregators,
  setSelectedAggregators,
}) => {
  const handleClick = (item) => {
    let itemRemovedArray = selectedAggregators.filter(
      (selectedItem) => !(selectedItem == item)
    );
    setSelectedAggregators([...new Set(itemRemovedArray)]);
  };
  return (
    <div className="tag-item">
      <span>
        {item.length < 20 ? (
          item + "  "
        ) : (
          <span>
            {item.slice(0, 18) + " "}
            <i class="fas fa-ellipsis-h tooltip-nav">
              <span className="tooltip-nav-text">{item}</span>
            </i>
            {"  "}
          </span>
        )}
      </span>
      <span onClick={() => handleClick(item)}>
        <i class="fas fa-times"></i>
      </span>
    </div>
  );
};

export default CustomTagBarAggregator;

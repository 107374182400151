import React, { Fragment, useState, useEffect } from "react";
import { barcodeScan } from "../functions/barcodeScan";

const BarcodeScanNew = () => {
  document.title = "Scan Barcode";

  const [courierCompany, setCourierCompany] = useState([]);
  useEffect(() => {
    setCourierCompany("All");
  }, []);

  return (
    <Fragment>
      <div className="card mx-auto" style={{ width: "40%", marginTop: "20px" }}>
        <div className="card-body" style={{ padding: "0px" }}>
          <div className="jumbotron jumbotron-scan-bar">
            <h2 className="card-title">Scan {courierCompany} Barcodes</h2>
            <form>
              <div id="msg1" />
              <div id="msg2" />
              <div id="msg3" />
              <div className="form-group">
                <label htmlFor="barcodes">{courierCompany} Barcodes:</label>
                <textarea
                  className="form-control"
                  style={{ overflowY: "scroll", height: "300px" }}
                  id="barcodes"
                  name="barcodes"
                />
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary scan-bar-btn"
                  onClick={(e) => barcodeScan(e, courierCompany)}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BarcodeScanNew;

import React, { useState } from "react";
import Axios from "axios";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CopyContent from "../../common/CopyContent";

export const LabelsAndManifestsList = ({ labelAndManifestDetails, index }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{labelAndManifestDetails.message}</td>
      <td>
        <ul>
          {labelAndManifestDetails.details.split(", ").map((link, i) => {
            if (i === 0) link = link.slice(15);
            else if (i === 1) link = link.slice(12);

            return (
              <li>
                <a href={link}>{link}</a>
              </li>
            );
          })}
        </ul>
      </td>
    </tr>
  );
};

export const GlobalLabelsAndManifestsList = ({
  labelAndManifestDetails,
  index,
}) => {
  const [open, setOpen] = useState(false);
  const handleLinkClick = async (link, message) => {
    let result = await Axios.post("/api/warehouse/markViewed", {
      message,
      link,
    });
    window.open(link);
  };

  const parseLink = (link, text) => {
    let splitLink = link.split("/");
    return (
      <p>
        {text}
        <span className="fake-link">{splitLink[splitLink.length - 1]}</span>
      </p>
    );
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <p>{new Date(labelAndManifestDetails.message).toString()}</p>
        {labelAndManifestDetails.moreDetails ? (
          <>
            <h4>{labelAndManifestDetails.moreDetails.product}</h4>
            <h4>{labelAndManifestDetails.moreDetails.courier}</h4>
            <Button
              aria-describedby={`LabelDetailPopover${index}`}
              variant="contained"
              onClick={() => setOpen(true)}
            >
              <Typography variant={`button`}>More details</Typography>
            </Button>
            <Popover
              id={`LabelDetailPopover${index}`}
              open={open}
              anchorEl={`LabelDetailPopover${index}`}
              onClose={() => setOpen(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography
                  variant={"h6"}
                  sx={{ textAlign: "center" }}
                >{`Orders (${labelAndManifestDetails.moreDetails.orders.length})`}</Typography>
                <Table>
                  <TableHead>
                    <TableRow sx={{ textAlign: "center" }}>
                      <TableCell>Order Name</TableCell>
                      <TableCell>AWB</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {labelAndManifestDetails.moreDetails.orders.map((order) => (
                      <TableRow>
                        <TableCell>
                          {order.name}
                          <CopyContent content={order.name} />
                        </TableCell>
                        <TableCell>
                          {order.awb_ref_no}
                          <CopyContent content={order.awb_ref_no} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Popover>
          </>
        ) : null}
      </td>
      <td>
        <ul>
          {!labelAndManifestDetails.viewed ? (
            <div className="new-tag">New</div>
          ) : (
            ""
          )}
          {labelAndManifestDetails.details.split(", ").map((link, i) => {
            if (i === 0) link = link.slice(15);
            else if (i === 1) link = link.slice(12);

            return (
              <li>
                <p
                  onClick={() =>
                    handleLinkClick(link, labelAndManifestDetails.message)
                  }
                  className={`anchor-mimic ${
                    labelAndManifestDetails.viewed ? "viewed-link" : ""
                  }`}
                >
                  {i === 1
                    ? parseLink(link, "Label:- ")
                    : parseLink(link, "Manifest:- ")}
                </p>
              </li>
            );
          })}
        </ul>
      </td>
    </tr>
  );
};

export const LabelsAndManifestsListShipyaari = ({
  labelAndManifestDetails,
  index,
}) => {
  const handleLinkClick = async (link, message) => {
    let result = await Axios.post("/api/warehouse/markViewed", {
      message,
      link,
    });
    window.open(link);
  };

  const parseLink = (link, text) => {
    let splitLink = link.split("/");
    return (
      <p>
        {text}
        <span className="fake-link">{splitLink[splitLink.length - 1]}</span>
      </p>
    );
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{new Date(labelAndManifestDetails.message).toString()}</td>
      <td>
        {labelAndManifestDetails.shipyaari ? (
          <p>SHIPYAARI</p>
        ) : (
          <p>SHADOWFAX</p>
        )}
        <ul>
          {!labelAndManifestDetails.viewed ? (
            <div className="new-tag">New</div>
          ) : (
            ""
          )}
          {labelAndManifestDetails.details.split(", ").map((link, i) => {
            if (i === 0) link = link.slice(15);
            else if (i === 1) link = link.slice(12);

            return (
              <li>
                <p
                  onClick={() =>
                    handleLinkClick(link, labelAndManifestDetails.message)
                  }
                  className={`anchor-mimic ${
                    labelAndManifestDetails.viewed ? "viewed-link" : ""
                  }`}
                >
                  {i === 0
                    ? parseLink(link, "Manifest:- ")
                    : parseLink(link, "Label:- ")}
                </p>
              </li>
            );
          })}
        </ul>
      </td>
    </tr>
  );
};

import React from "react";
import Dashboard from "./layouts/Dashboard";
import NavBar from "./res/NavBar";

const Inventory = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default Inventory;

import React, { useEffect, useState, Fragment } from "react";
import resolveStatus from "../../trackingboard/OrderTracker/utils/resolveStatus";
import Axios from "axios";
import constructDialerLink from "../../utils/constructDialerLink";

export default function OrderDetails() {
  const [showDetails, setShowDetails] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(true);
  const [order, setOrder] = useState("");
  const [details, setDetails] = useState("");
  const [displayTrue, setDisplayTrue] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");

  const host = window.location.origin;

  const getScheduledLink = (order) => {
    if (order.scheduledThrough.includes("ShipRocket"))
      return `https://app.shiprocket.in/order/${order.order_id}`;
    if (order.scheduledThrough.includes("ClickPost"))
      return `https://dashboard.clickpost.in/#/track-order`;
    if (order.scheduledThrough.includes("GoSwift"))
      return `https://app.goswift.in/track/${order.order_id}`;
    if (order.scheduledThrough.includes("ShipYaari"))
      return `https://seller.shipyaari.com/avn_ci/siteadmin/parentclientconsignments/index/0/avnshippingid/${order.order_id}`;
    if (order.scheduledThrough.includes("ShadowFax"))
      return `https://tracker.shadowfax.in/#/`;
    else return "";
  };

  const getDetails = async (order, oldStatus) => {
    let result = await Axios.post(`/api/orders/getOrderDetails`, {
      name: order.name,
      store: order.store,
    });
    if (result.data.success) {
      setDetails(result.data.details);
      setCurrentStatus(result.data.details.status_code);
    }

    if (oldStatus && result.data.details.status_code) {
      if (oldStatus !== result.data.details.status_code) {
        alert(
          `Status code changed from ${resolveStatus(
            oldStatus
          )} to ${resolveStatus(result.data.details.status_code)}`
        );
      } else alert("No change in status code");
    }
  };

  const refreshStatus = async () => {
    const awb_ref_no = details.awb_ref_no || null;
    const order_id = details.order_id || null;

    let result = null;

    if (order_id)
      result = await Axios.post("/api/operations/batchUpdateOrderDetails", {
        orderIds: [order_id],
      });
    else if (awb_ref_no)
      result = await Axios.post("/api/operations/batchUpdateOrderDetailsAWBs", {
        awbs: [awb_ref_no],
      });

    getDetails(order, currentStatus);
  };

  const generateOrderLinks = () => {
    return (
      <Fragment>
        <Fragment>
          <a
            href={`https://${order.store.shopifyName}.myshopify.com/admin/${
              details.abandonedCheckout ? "checkouts" : "orders"
            }/${details.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >{`Link To Shopify Order`}</a>
        </Fragment>
        <br />
        {details.abandonedOrderReference ? (
          <Fragment>
            <a
              href={`https://${
                order.store.shopifyName
              }.myshopify.com/admin/checkouts/${details.abandonedOrderReference.slice(
                1
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`Link To Original Abandoned Order`}</a>
            <br />
          </Fragment>
        ) : (
          ""
        )}
        {details.manifestedShopifyDetails ? (
          <Fragment>
            <a
              href={`https://${order.store.shopifyName}.myshopify.com/admin/orders/${details.manifestedShopifyDetails.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`Link To Manifested Order`}</a>
            <br />
          </Fragment>
        ) : (
          ""
        )}
        <Fragment>
          {details.scheduledThrough ? (
            <a
              href={getScheduledLink(details)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {details.scheduledThrough ? "Link To Scheduled Order" : ""}
            </a>
          ) : (
            ""
          )}
        </Fragment>
      </Fragment>
    );
  };

  const transformData = (option, value) => {
    if (typeof details[option] !== "object") {
      switch (option) {
        case "created_at":
          return (
            <Fragment>
              <Fragment>{`Created On Shopify On ${new Date(
                value
              ).toLocaleString()}`}</Fragment>
              <br />
              {/* ORDER LINKS */}
              {generateOrderLinks()}
            </Fragment>
          );
        case "sentToSupportOn":
          return `Sent To The Calling Dashboard On ${new Date(
            value
          ).toLocaleString()}`;
        case "sentToOpsOn":
          return `Sent To The Operations Dashboard On ${new Date(
            value
          ).toLocaleString()}`;
        case "labelGenerated":
          return `Label ${!details.labelGenerated ? "Not " : ""} Generated`;
        case "manifestGenerated":
          return `Manifest ${
            !details.manifestGenerated ? "Not " : ""
          } Generated`;
        case "printedByWarehouse":
          return `${
            !details.printedByWarehouse ? "Not " : ""
          } Printed By Warehouse`;
        case "labelAndManifestGeneratedOn":
          return (
            <a
              href={`${host}/warehouse/labelsAndManifests_V3?date=${new Date(
                value
              ).toLocaleString()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Label And Manifest Generated On {new Date(value).toLocaleString()}
            </a>
          );
        case "scheduledOn":
          return `Scheduled On ${new Date(value).toLocaleString()}`;
        case "awbScannedOn":
          return `Dispatched On ${new Date(value).toLocaleString()}`;
        case "webhookNotifiedOn":
          return `Webhook Notified On ${new Date(value).toLocaleString()}`;
        case "pickedUpOn":
          return `Picked Up On ${new Date(value).toLocaleString()}`;
        case "inTransitOn":
          return `In Transit On ${new Date(value).toLocaleString()}`;
        case "deliveredOn":
          return `Delivered On ${new Date(value).toLocaleString()}`;
        case "rtoInitiatedOn":
          return `RTO Initiated On ${new Date(value).toLocaleString()}`;
        case "awb_ref_no":
          return `AWB ${value}`;
        case "order_id":
          return `${details.scheduledThrough || ""} Order ID ${value}`;
        case "callCustomer":
          return `Address Issue`;
        case "repeatCustomer":
          return `Repeat Customer`;
        case "multipleItems":
          return `Multiple Items`;
        case "multipleQuantity":
          return `Multiple Quantity`;
        case "ndr":
          return `NDR`;
        case "upSell":
          return `Upsell`;
        case "reattempt":
          return `You can schedule this order in the ${value}`;
        case "callAssignedTo":
          return `Call is assigned to  ${value}`;
        case "status_code":
          return resolveStatus(value).includes("At Calling Dashboard") ? (
            <a
              href={`${host}/support/callingdashboard?input=${encodeURIComponent(
                order.name
              )}&type=order`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Current Status, At Calling Dashboard
            </a>
          ) : (
            `Current Status, ${resolveStatus(value)}`
          );
        case "generatedBySystem":
          return value
            ? `Order Is Already Scheduled`
            : "Order Not Scheduled Yet";
        case "cancelledOrder":
          return `Order Was Cancelled At The Calling Dashboard`;
        case "outOfStock":
          return `Items Are Out Of Stock`;
        case "notifyOperations":
          return `Order Is On Operations Board`;
        case "manifestedAbandonedOrder":
          return `Order Has Been Manifested (Abandoned)`;
        case "pincodeNotServiceable":
          return `Pincode Not Serviceable`;
        case "shipmentCancelled":
          return `Order Has Been Cancelled At The Operations Board`;
        default:
          return "";
      }
    } else {
      if (option === "callTrail") {
        return (
          <div className="order-details-content-calltrail">
            <div>
              <center>Call Trail</center>
            </div>
            {details[option].map((callEvent) => (
              <div>
                <div style={{ background: "#34495e", color: "white" }}>
                  {callEvent.name}
                </div>
                <div>{callEvent.remarks}</div>
                <div>{new Date(callEvent.date).toLocaleString()}</div>
                {callEvent.name !== "Jarvis" &&
                callEvent.recordingFile &&
                details.ngrokLink ? (
                  <Fragment>
                    <div>
                      <a
                        href={
                          constructDialerLink(details.ngrokLink, callEvent)
                            .streamLink
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Listen to recording
                      </a>
                    </div>
                    <div>
                      <a
                        href={
                          constructDialerLink(details.ngrokLink, callEvent)
                            .downloadLink
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download recording
                      </a>
                    </div>
                  </Fragment>
                ) : (
                  callEvent.name !== "Jarvis" &&
                  callEvent.recordingFile && (
                    <div>
                      Please contact developers to correct the recording (
                      {callEvent.recordingFile})
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        );
      } else if (option === "removedBy") {
        return `Order Has Been Removed At The Operations Board  ${
          details[option].user.name ? `By ${details[option].user.name}` : ""
        }  ${
          details[option].date
            ? `at ${new Date(details[option].date).toLocaleString()}`
            : ""
        } ${
          details["remarksForOrderRemoval"]
            ? `because ${details["remarksForOrderRemoval"]}`
            : ""
        }`;
      } else if (option === "updatedToPrepaidBy") {
        return (
          <Fragment>
            Amount Collected From Customer: {details.updatedToPrepaidBy.amount}
            <br />
            Amount Collected By: {details.updatedToPrepaidBy.name}
          </Fragment>
        );
      }
    }
  };

  const handleDisplayMode = () => {
    if (!displayTrue)
      if (prompt("Enter True Mode?") === "Bazinga@Media1234")
        setDisplayTrue(true);
      else setDisplayTrue(false);
  };

  function copy(text) {
    /* Get the text field */
    navigator.clipboard.writeText(text);
  }

  useEffect(() => {
    if (order) getDetails(order);
  }, [order]);

  const OrderModal = ({ order, position }) =>
    showOrderModal ? (
      <div className="order-details-tab" style={{ ...position }}>
        {showDetails ? (
          <div className="order-details-body">
            <div className="order-details-head">
              <div>
                <i
                  className="far fa-times-circle"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDetails(false);
                    setDisplayTrue(false);
                  }}
                ></i>
              </div>
              <div
                onDoubleClick={() => handleDisplayMode()}
                style={{ display: "flex", width: "100%" }}
              >
                <div>
                  {" "}
                  <i
                    class="far fa-copy"
                    onClick={() => copy(order.name)}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                  ></i>
                  {order.name}
                </div>
                {details.awb_ref_no || details.order_id ? (
                  <div
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    onClick={() => refreshStatus()}
                  >
                    Refresh Status <i class="fas fa-sync"></i>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {details ? (
              <div className="order-details-content">
                {displayTrue
                  ? Object.keys(details).map((detail) =>
                      typeof details[detail] !== "object" ? (
                        <div className="order-details-content-row">
                          <div>{detail}</div>
                          <div>{details[detail]}</div>
                        </div>
                      ) : (
                        ""
                      )
                    )
                  : Object.keys(details).map((detail) =>
                      transformData(detail, details[detail]) ? (
                        <div className="order-details-content-row">
                          <div>
                            {typeof transformData(detail, details[detail]) ===
                              "string" && (
                              <i
                                class="far fa-copy order-details-copy-button"
                                onClick={() =>
                                  copy(transformData(detail, details[detail]))
                                }
                              ></i>
                            )}

                            {transformData(detail, details[detail])}
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div>
            <div className="global-order-button">
              <i
                className="far fa-copy"
                onClick={() => {
                  copy(order.name);
                }}
              ></i>
              <i
                className="fas fa-info"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setOrder(order);
                  setShowDetails(!showDetails);
                }}
              ></i>
            </div>
          </div>
        )}
      </div>
    ) : (
      ""
    );

  return [OrderModal, setShowOrderModal];
}

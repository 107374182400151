import React, { useEffect, useState, Fragment } from "react";

import axios from "axios";

const UploadRemittance = () => {
  document.title = "Upload Remittance";
  const [store, setStore] = useState("");
  const [aggregator, setAggregator] = useState("");
  const aggregators = [
    "ShipRocket",
    "ClickPost-BlueDart",
    "ClickPost-Shadowfax",
    "GoSwift",
  ];

  const handleUploads = () => {
    let input = document.getElementById("upload-remittance-csv");
    for (let i = 0; i < input.files.length; i++) {
      console.log(`File ${i + 1} : ${input.files[i].name}`);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    document.getElementById("remittance-submit-button").disabled = true;
    const urls = {
      ShipRocket: "shipRocket",
      "ClickPost-BlueDart": "clickPost/bluedart",
      "ClickPost-Shadowfax": "clickPost/shadowfax",
      GoSwift: "goSwift",
    };
    let input = document.getElementById("upload-remittance-csv").files[0];
    if (!input) {
      alert(`Please upload a file!`);
      document
        .getElementById("remittance-submit-button")
        .removeAttribute("disabled");
      return;
    }
    const formData = new FormData();
    formData.append("file", input);
    console.log(formData);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let csv = await axios.post(
      `/api/upload/remittance/${urls[aggregator]}`,
      formData,
      config
    );
    // console.log(csv);
    let blob = new Blob([csv.data], { type: "text/csv" });
    let a = document.createElement("a");
    a.download = `${aggregator} Remittance.csv`;
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
    document
      .getElementById("remittance-submit-button")
      .removeAttribute("disabled");
    // alert("Upload Success!");
  };

  return (
    <div>
      <div className="card configurationsCard">
        <nav
          className="navbar jarvisBar "
          style={{ backgroundColor: "#2980b9", marginBottom: "10px" }}
        >
          <div className="dropdown">
            <button
              className="btn  dropdown-toggle storeButton"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "black" }}
            >
              {aggregator == "" ? "Choose aggregator" : aggregator}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {aggregators.map((aggregator, index) => (
                <button
                  key={index}
                  className="dropdown-item"
                  onClick={() => setAggregator(aggregator)}
                >
                  {aggregator}
                </button>
              ))}
            </div>
          </div>
          <span
            className="navbar-text"
            style={{
              position: "fixed",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            {aggregator !== "" ? aggregator.toUpperCase() : ""}
          </span>
        </nav>
        {aggregator !== "" ? (
          <Fragment>
            <div style={{ margin: "30px 5%", padding: "0 2vw" }}>
              <div class="remittance-flex">
                <form>
                  <div className="form-group">
                    <label htmlFor="upload-remittance-csv">
                      <b>Upload Remittance CSV below</b>
                    </label>
                    <input
                      type="file"
                      className="form-control flex-item-rem"
                      id="upload-remittance-csv"
                      name="filesUploaded[]"
                      placeholder="Upload your files here"
                      encty
                      onChange={handleUploads}
                    />
                    <button
                      type="submit"
                      id="remittance-submit-button "
                      className="btn btn-primary mb-2 btn-lg flex-item-rem"
                      onClick={(e) => submit(e)}
                      style={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: "10px",
                        borderRadius: "0px",
                        backgroundColor: "#2980b9",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Fragment>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h3>Choose an aggregator</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadRemittance;

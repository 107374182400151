import React, { useState, useContext } from "react";
import StandardModule from "./home/StandardModule";
import { GlobalConsumer } from "../Global-Context/global-context";
import AbandonedModule from "./home/components/AbandonedModule";
import DeliveryPercentagePincodeModule from "./home/components/DeliveryPercentagePincodeModule";
import FlagsModule from "./home/components/FlagsModule";

const Home = () => {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  global.selected = [];
  const [user, setUser] = useState(userInfo);
  const modules = [
    {
      name: "Cancellation Module",
      columns: ["Cancelled", "Total", "Percentage"],
    },
    {
      name: "Delivery Module",
      columns: ["Percentage", "Delivered", "Attempted Orders"],
    },
    {
      name: "Calling Module",
      columns: ["Delivery", "Cancellation"],
    },
  ];
  const [active, setActive] = useState(-1);
  document.title = "Home";
  return (
    <div style={{ textAlign: "center" }}>
      <abbr
        title={`Build Date: ${new Date(process.env.REACT_APP_LAST_BUILT)}`}
        style={{
          textDecoration: "none",
          border: "none",
        }}
      >
        <span
          onClick={() => setActive(-1)}
          style={{ cursor: "pointer", display: "block", marginTop: "50px" }}
        >
          {" "}
          Welcome To Jarvis 4.1
        </span>
      </abbr>
      {user.master || user.employee ? (
        <div className="home-modules">
          <FlagsModule number={0} active={active} setActive={setActive} />
          {modules.map((object, i) => (
            <StandardModule
              number={i + 1}
              columns={object.columns}
              name={object.name}
              active={active}
              setActive={setActive}
            />
          ))}
          <AbandonedModule number={4} active={active} setActive={setActive} />
          <DeliveryPercentagePincodeModule
            number={6}
            active={active}
            setActive={setActive}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Home;

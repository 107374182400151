import Axios from "axios";
import React, { useEffect, useState } from "react";
import useAlert from "../components/global/Alert";
import useDatePicker from "../components/global/DatePicker";
import DialerInboundItem from "./DialerInboundItem";
import loadingSRC from "./loading.gif";

const DialerInboundBoard = () => {
  const inboundCallReportApi = "/api/support/prepInboundCallStats";
  const [loading, setLoading] = useState(false);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [AlertBox, setNotification] = useAlert();
  const [logs, setLogs] = useState({});

  const fetchInitialData = async () => {
    setLoading(true);
    const {
      data: { status, message, phoneNumberLogs },
    } = await Axios.get(inboundCallReportApi);
    if (!status) {
      setNotification(message);
      setLoading(false);
      return;
    }
    setLogs(phoneNumberLogs);
    setLoading(false);
  };

  const fetchReport = async () => {
    setLoading(true);
    console.log(fromDate.format("YYYY-MM-DD"));
    console.log(toDate.format("YYYY-MM-DD"));
    const startDate = fromDate.format("YYYY-MM-DD");
    const endDate = toDate.format("YYYY-MM-DD");
    const {
      data: { status, message, phoneNumberLogs },
    } = await Axios.get(
      `${inboundCallReportApi}?start=${startDate}&end=${endDate}`
    );
    if (!status) {
      setNotification(message);
      setLoading(false);
      return;
    }
    setLogs(phoneNumberLogs);
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return loading ? (
    <>
      <AlertBox />
      <div style={{ marginTop: "20px" }}>
        <center>
          <img src={loadingSRC} height="300px" width="400px" />
        </center>
      </div>
    </>
  ) : (
    <>
      <AlertBox />
      <div className="dialer-inbound-filters">
        <DatePicker />
        <div>
          <button
            className="dialer-inbound-filter-submit"
            onClick={fetchReport}
          >
            Fetch Report
          </button>
        </div>
      </div>
      <div className="dialer-inbound-logs-body">
        {logs ? (
          <div className="dialer-inbound-column-headers">
            <div>INBOUND UNIQUE PHONE NUMBER</div>
            <div className="dialer-inbound-column-subheaders">
              <div>CALL DATE</div>
              <div>LEAD ID</div>
              <div>CAMPAIGN ID</div>
              <div>DURATION</div>
              <div>STATUS</div>
              <div>USER</div>
              <div>TYPE</div>
            </div>
          </div>
        ) : null}
        {logs ? (
          Object.keys(logs).map((log) => (
            <DialerInboundItem phone={log} log={logs[log]} />
          ))
        ) : (
          <div style={{ marginTop: "20px" }}>
            <center>{`No call logs to show`}</center>
          </div>
        )}
      </div>
    </>
  );
};

export default DialerInboundBoard;

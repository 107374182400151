import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";

export const submitRTO = async (store, order, fetchOrders) => {
  let products = [];
  let confirmed = false;
  order.line_items.map((product) => {
    if (
      parseInt(document.getElementById(`damaged_${product.variant_id}`).value) <
        0 ||
      parseInt(document.getElementById(`usable_${product.variant_id}`).value) <
        0 ||
      parseInt(
        document.getElementById(`missing_${product.variant_id}`).value < 0
      )
    )
      return alert("Quantity Entered cannot be negative");
    // if (
    //   parseInt(
    //     document.getElementById(`damaged_${product.variant_id}`).value
    //   ) === 0 &&
    //   parseInt(
    //     document.getElementById(`usable_${product.variant_id}`).value
    //   ) === 0 &&
    //   parseInt(
    //     document.getElementById(`missing_${product.variant_id}`).value
    //   ) === 0
    // )
    //   return alert("Please specify the Damaged/Usable/Missing Quantity");
    if (
      parseInt(document.getElementById(`damaged_${product.variant_id}`).value) +
        parseInt(
          document.getElementById(`usable_${product.variant_id}`).value
        ) +
        parseInt(
          document.getElementById(`missing_${product.variant_id}`).value
        ) >
      parseInt(product.quantity)
    ) {
      if (!confirmation()) {
        confirmed = false;
        return alert("Try Again!");
      } else {
        confirmed = true;
        products.push({
          name: product.name,
          inventory: product.inventory ? product.inventory : null,
          details: {
            product_id: product.product_id,
            variant_id: product.variant_id,
          },
          damaged:
            document.getElementById(`damaged_${product.variant_id}`).value
              .length === 0
              ? "0"
              : document.getElementById(`damaged_${product.variant_id}`).value,
          usable:
            document.getElementById(`usable_${product.variant_id}`).value
              .length === 0
              ? "0"
              : document.getElementById(`usable_${product.variant_id}`).value,
          missing:
            document.getElementById(`missing_${product.variant_id}`).value
              .length === 0
              ? "0"
              : document.getElementById(`missing_${product.variant_id}`).value,
        });
      }
    } else {
      confirmed = true;
      products.push({
        name: product.name,
        inventory: product.inventory ? product.inventory : null,
        details: {
          product_id: product.product_id,
          variant_id: product.variant_id,
        },
        damaged:
          document.getElementById(`damaged_${product.variant_id}`).value
            .length === 0
            ? "0"
            : document.getElementById(`damaged_${product.variant_id}`).value,
        usable:
          document.getElementById(`usable_${product.variant_id}`).value
            .length === 0
            ? "0"
            : document.getElementById(`usable_${product.variant_id}`).value,
        missing:
          document.getElementById(`missing_${product.variant_id}`).value
            .length === 0
            ? "0"
            : document.getElementById(`missing_${product.variant_id}`).value,
      });
    }
  });

  if (confirmed) {
    console.log("orderDetails", {
      orderName: order.orderName,
      products,
    });

    let response = await Axios.post("/api/warehouse/updateInventory_v2", {
      store,
      orderName: order.orderName,
      products,
    });
    alert(response.data.msg);
    fetchOrders();
    confirmed = false;
  }
};

import React, { useState, useEffect } from "react";
import StoreButton from "../res/StoreButton";
import Axios from "axios";
import ProductDimensions from "../res/ProductDimensions";

export default function UpdateProducts({ user }) {
  const [searchInput, setSearchInput] = useState("");
  const [products, setProducts] = useState("");
  const [filtered, setFiltered] = useState("");

  const searchProducts = (input) => {
    setSearchInput(input);
    let found = products.filter((product) => {
      return (
        product.store.storeName.toLowerCase().includes(input) ||
        product.store.shopifyName.toLowerCase().includes(input) ||
        product.product_id.toString().includes(input) ||
        product.title.toLowerCase().includes(input)
      );
    });
    setFiltered(found);
  };

  const fetchProducts = async () => {
    let result = await Axios.get(
      `/api/product/getAllFavourites?user=${user.user_id}`
    );

    result.data.products.sort((a, b) => {
      let missingA = a.variants.find(
        (variant) => !variant.actualWeight || !variant.fakeActualWeight
      );

      let missingB = b.variants.find(
        (variant) => !variant.actualWeight || !variant.fakeActualWeight
      );

      if (missingA) {
        return -1;
      }
      if (missingB) {
        return 1;
      }
      return 0;
    });

    setProducts(result.data.products);
    setFiltered(result.data.products);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (searchInput) {
      searchProducts(searchInput);
    }
  }, [products]);

  return (
    <div className="operations-productsBoard">
      <div className="operations-productsBoard-nav">
        <div className="operations-productsBoard-search">
          <input
            placeholder="Enter Title / ID / Store / Shopify ..."
            onChange={(e) => searchProducts(e.target.value)}
          />
        </div>
      </div>
      <div className="operations-productsBoard-body">
        {filtered
          ? filtered.map((product) => (
              <ProductDimensions
                product={product}
                fetchProducts={fetchProducts}
                user={user}
              />
            ))
          : ""}
      </div>
    </div>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import useAlert from "../../global/Alert";

const SmsBlastTab = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [AlertBox, setNotification] = useAlert();
  // const [type, setType] = useState(null);

  const fetchCampaigns = async () => {
    const { data } = await axios.get("/api/smsMobi/viewCampaigns");
    console.log(data);
    setCampaigns(data.campaigns);
  };

  const handleCampaignSelect = (e) => {
    console.log(e.target.value);
    const campaignObj = JSON.parse(e.target.value);
    console.log(campaignObj);
    setSelectedCampaign(campaignObj);
  };

  const blast = async () => {
    if (!selectedCampaign) {
      setNotification("failure", "Please a select a campaign!");
      return;
    }

    const payload = {
      productDetails: selectedCampaign.campaignMessage.productDetails,
      code: selectedCampaign.campaignMessage.voucherCode,
      store: selectedCampaign.store,
      campaignId: selectedCampaign.campaignId,
    };

    const smsToBeSent = document.getElementById("blast-sms-number-of-sms")
      .value;
    if (smsToBeSent) {
      payload.smsToBeSent = smsToBeSent;
    }

    let contactNumbers = document
      .getElementById("blast-sms-contact-numbers")
      .value.split("\n")
      .map((number) => {
        const final = `91${number.trim()}`;
        return final;
      });
    contactNumbers = contactNumbers.filter(
      (contactNumber) => contactNumber !== "91"
    );

    if (contactNumbers.length > 0) payload.contactNumbers = contactNumbers;

    console.log(`These are the contact numbers - `, contactNumbers);

    // make request to notify customer
    const { data } = await axios.post("/api/smsMobi/notifyCustomers", payload);
    console.log(data);
    setNotification(data.status ? "success" : "failure", data.message);
  };

  useEffect(() => {
    if (campaigns.length === 0) {
      fetchCampaigns();
    }
  });

  // FOR PHASE 2
  // useEffect(() => {
  //   if (type === "existing") {
  //     document.getElementById("blast-sms-product-details").value =
  //       selectedCampaign.campaignMessage.productDetails;
  //     document.getElementById("blast-sms-voucher-code").value =
  //       selectedCampaign.campaignMessage.voucherCode;
  //     document.getElementById("blast-sms-number-of-sms").value =
  //       selectedCampaign.perDay;
  //   }
  // }, [type]);

  return (
    <>
      <AlertBox />
      <div className="sms-marketing-tabs">
        <h3 style={{ textAlign: "center" }}>BLAST SMS</h3>
        <div className="campaign-item-grid">
          <div>Select Campaign</div>
          <div>
            <select
              className="form-control form-control-sm"
              onChange={handleCampaignSelect}
            >
              <option value={0}>Choose a campaign</option>
              {campaigns.map((campaign) => (
                <option value={JSON.stringify(campaign)}>
                  {campaign.campaignName}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* FOR PHASE 2*/}
        {/* {selectedCampaign ? (
        <div className="campaign-item-grid">
          <div>
            <input
              id="existing-campaign-details"
              type="checkbox"
              style={{ marginRight: "0.5rem" }}
              onClick={() => setType("existing")}
              checked={type === "existing" ? true : false}
            ></input>
            <label htmlFor="existing-campaign-details">
              Existing campaign details
            </label>
          </div>
          <div>
            <input
              id="modify-campaign-details"
              type="checkbox"
              style={{ marginRight: "0.5rem" }}
              onClick={() => setType("modify")}
              checked={type === "modify" ? true : false}
            ></input>
            <label htmlFor="modify-campaign-details">
              Modify campaign details
            </label>
          </div>
        </div>
      ) : null} */}
        <div className="campaign-item-grid">
          <div>Product Details</div>
          <div>
            <input
              id="blast-sms-product-details"
              className="form-control form-control-sm"
              type="text"
            ></input>
          </div>
        </div>
        <div className="campaign-item-grid">
          <div>Voucher Code</div>
          <div>
            <input
              id="blast-sms-voucher-code"
              className="form-control form-control-sm"
              type="text"
            ></input>
          </div>
        </div>
        <div className="campaign-item-grid">
          <div>Number of SMS to be sent</div>
          <div>
            <input
              id="blast-sms-number-of-sms"
              className="form-control form-control-sm"
              type="number"
            ></input>
          </div>
        </div>
        <div className="campaign-item-grid">
          <div>Contact Number</div>
          <div>
            <textarea
              id="blast-sms-contact-numbers"
              className="form-control form-control-sm"
            ></textarea>
          </div>
        </div>
        <div className="sms-campaign-final-buttons">
          <button className="sms-campaign-button" onClick={blast}>
            Notify Customers
          </button>
        </div>
      </div>
    </>
  );
};

export default SmsBlastTab;

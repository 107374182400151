import React from "react";
import SpinnerGif from "../../res/OSpinner.gif";

const Spinner = () => {
  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img src={SpinnerGif} alt="Loading..." width="90px" />
    </div>
  );
};

export default Spinner;

import React from "react";

const NavBar = () => {
  return (
    <nav className="navbar storeBar">
      <span className="navbar-text">Stores panel</span>
    </nav>
  );
};

export default NavBar;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import useDatePicker from "../../global/DatePicker";

function SLABreach() {
  const [DatePicker, fromDate, toDate] = useDatePicker("2020");
  const [stores, setStores] = useState([]);
  const [store] = useState(null);
  const [breaches, setBreaches] = useState([]);

  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };

  const modules = ["RTO Module", "Test Module"];

  const checkSLABreach = async (from, to) => {
    let store = document.getElementById("store_selector").value;
    if (!store) return alert("Please choose a store");
    let module = document.getElementById("module_selector").value;
    let SLA = document.getElementById("SLA_Input").value;

    let data = { from, to, store, module, SLA };
    if (store !== "" && module !== "" && SLA !== "") {
      let result = await Axios.post("/api/operations/breachChecker", data);
      setBreaches(result.data.breaches);

      document.getElementById("store_selector").value = "Choose Store";
      document.getElementById("module_selector").value = "Choose Module";
      document.getElementById("SLA_Input").value = "";
    } else {
      alert("Input all fields");
    }
  };

  useEffect(() => {
    getStores();
  }, []);
  return (
    <div>
      <div className="card configurationsCard IMS-order-card">
        <div style={{ padding: "20px" }}>
          <center>SLA Breach</center>
          <div className="moduleBar">
            <select
              placeholder="Stores"
              id="store_selector"
              className="form-control form-control-sm"
              style={{
                height: "50px",
                fontSize: "0.9rem",
                borderRadius: 0,
              }}
            >
              <option value={JSON.stringify(store)} selected disabled>
                Choose Store
              </option>
              {stores.map((store, index) => (
                <option value={JSON.stringify(store)} key={index}>
                  {store.storeName}
                </option>
              ))}
            </select>
            <select
              placeholder="Choose Module"
              id="module_selector"
              className="form-control form-control-sm"
              style={{
                height: "50px",
                fontSize: "0.9rem",
                borderRadius: 0,
              }}
            >
              <option value={JSON.stringify(store)} selected disabled>
                Choose Module
              </option>
              {modules.map((module, index) => (
                <option key={index}>{module}</option>
              ))}
            </select>
            <div>
              <DatePicker />
            </div>
          </div>
          <div style={{ margin: "10px", display: "flex" }}>
            <input
              className="form-control"
              placeholder="SLA/TAT"
              type="text"
              id="SLA_Input"
              style={{ height: "50px", borderRadius: 0 }}
            />
            <button
              className="btn btn-danger"
              style={{ borderRadius: 0 }}
              onClick={() => checkSLABreach(fromDate, toDate)}
            >
              Submit
            </button>
          </div>
          <div style={{ margin: "10px" }}>
            {breaches.length > 0 ? (
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>

                    <th scope="col">Order Name</th>
                    <th scope="col">Days</th>
                  </tr>
                </thead>
                <tbody>
                  {breaches.map((breach, i) => (
                    <tr>
                      <th scope="row">{i}</th>
                      <td>{breach.name}</td>
                      <td className="custom-tooltip">
                        {breach.days}
                        <span className="tooltiptext-SLA">{`(${breach.start_date}) ${breach.start_reason} - (${breach.end_date}) ${breach.end_reason}`}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SLABreach;

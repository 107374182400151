export const orderAction = (e, action, store, Courier, user) => {
  console.log(store, action);
  const orderName = document.querySelector("#orderName");
  const status = document.querySelector("#status");

  e.preventDefault();

  document.getElementById("button-div").classList.add("button-div-disable");

  user = { user_id: user.user_id, name: user.name };

  const data = {
    store,
    orderName: encodeURIComponent(orderName.value),
    user,
  };
  // console.log("user = ", user);
  // return;
  let fetchRequest;

  if (action === "cancelAnOrder") {
    console.log("Called Cancel Order Endpoint");
    /*
		if (Courier === "shiprocket") {
      fetchRequest = fetch("/api/operations/cancelAnOrder", {
        method: "POST",
        body: JSON.stringify(data), // orderNames can be `string` or {object}!
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else if (Courier === "ecom") {
      fetchRequest = fetch("/api/ecom/operations/cancelAnOrder", {
        method: "POST",
        body: JSON.stringify(data), // orderNames can be `string` or {object}!
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else if (Courier === "click") {
      fetchRequest = fetch("/api/clickpost/operations/cancelAnOrder", {
        method: "POST",
        body: JSON.stringify(data), // orderNames can be `string` or {object}!
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else if (Courier === "goswift") {
      // return alert("GoSwift cancel an order will be released recently");

      fetchRequest = fetch("/api/goswift/operations/cancelAnOrder", {
        method: "POST",
        body: JSON.stringify(data), // orderNames can be `string` or {object}!
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
		*/
    // console.log("frontend = ", JSON.stringify(data));
    fetchRequest = fetch("/api/operations/cancelOrder_V2", {
      method: "POST",
      body: JSON.stringify(data), // orderNames can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    });
  } else if (action === "scheduleAnOrderManually") {
    if (Courier === "shiprocket") {
      fetchRequest = fetch(
        `/api/orders/createShipmentForAnOrder?orderName=${encodeURIComponent(
          orderName.value
        )}&store=${JSON.stringify(store)}&user=${JSON.stringify(user)}`
      );
    } else if (Courier === "ecom") {
      fetchRequest = fetch(
        `/api/ecom/operations/scheduleAnOrderManually?orderName=${encodeURIComponent(
          orderName.value
        )}&store=${JSON.stringify(store)}&user=${JSON.stringify(user)}`
      );
    } else if (Courier === "click") {
      fetchRequest = fetch(
        `/api/clickpost/operations/scheduleAnOrderManually?orderName=${encodeURIComponent(
          orderName.value
        )}&store=${JSON.stringify(store)}&user=${JSON.stringify(user)}`
      );
    } else if (Courier === "goswift") {
      // return alert("GoSwift Schedule An Order will be released recently");
      fetchRequest = fetch(
        `/api/goswift/operations/scheduleAnOrderManually?orderName=${encodeURIComponent(
          orderName.value
        )}&store=${JSON.stringify(store)}&user=${JSON.stringify(user)}`
      );
    }
    console.log("Called Schedule An Order Endpoint");
  } else {
    console.log("No fetch request for this route", action);
  }

  fetchRequest
    .then((response) => {
      console.log("response", response);
      response.json().then((data) => {
        status.textContent = data.status;
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateCustomerAddress = (e, orderID, store, length) => {
  console.log(`loaded updateCustomerAddress file`, length);
  // let products = [];
  // for (let i = 0; i < length; i++) {
  //   let item = { name: document.getElementById(`product_${i}`).value };
  //   products.push(item);
  // }
  e.preventDefault();
  // console.log("Updated products", products);
  console.log("orderID = ", orderID);

  // billing address
  const b_fname = document.getElementById("b_fname").value;
  const b_lname = document.getElementById("b_lname").value;
  const b_company = document.getElementById("b_company").value;
  const b_address1 = document.getElementById("b_address1").value;
  const b_address2 = document.getElementById("b_address2").value;
  const b_phone = document.getElementById("b_phone").value;
  const b_city = document.getElementById("b_city").value;
  const b_zip = document.getElementById("b_zip").value;
  // shipping address
  const s_fname = document.getElementById("s_fname").value;
  const s_lname = document.getElementById("s_lname").value;
  const s_email = document.getElementById("s_email").value;
  const s_company = document.getElementById("s_company").value;
  const s_address1 = document.getElementById("s_address1").value;
  const s_address2 = document.getElementById("s_address2").value;
  const s_phone = document.getElementById("s_phone").value;
  const s_city = document.getElementById("s_city").value;
  const s_zip = document.getElementById("s_zip").value;

  const url = `/api/operations/address?orderID=${orderID}`;

  const data = {
    b_fname,
    b_lname,
    b_company,
    b_address1,
    b_address2,
    b_phone,
    b_city,
    b_zip,
    s_fname,
    s_lname,
    s_email,
    s_company,
    s_address1,
    s_address2,
    s_phone,
    s_city,
    s_zip,
    store
    // products
  };

  console.log("data = ", data);

  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      response.json().then(data => {
        console.log(data.msg);
        document.getElementById(`msg`).innerHTML =
          '<span class="badge badge-success">Successfully Updated Customer Address</span><br />';
        setTimeout(() => {
          document.getElementById(`msg`).innerHTML = "";
        }, 3000);
        alert(data.msg);
      });
    })
    .catch(err => console.log(err.message));
};

import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cryptr from "cryptr";

const MasterRoute = ({ component: Component, ...rest }) => {
  const getUser = (props) => {
    if (localStorage.user && localStorage.info) {
      let info = JSON.parse(localStorage.info);

      const cryptr = new Cryptr("myTotalySecretKey");
      let details = JSON.parse(cryptr.decrypt(info));

      if (details.master || details.access.includes(props.location.pathname))
        return true;
      else return false;
    } else {
      alert("Not signed in");
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        getUser(props) ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default MasterRoute;

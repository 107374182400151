import React, { useState, useRef, useEffect, useContext } from "react";
import useDatePicker from "../components/global/DatePicker";
import useProductPicker from "../components/global/ProductPicker";
import CircularProgressBar from "../components/global/CircularProgressBar";
import useAlert from "../components/global/Alert";
import { GlobalConsumer } from "../../src/Global-Context/global-context";
import { Bar } from "react-chartjs-2";

import Axios from "axios";

export default function DialerStats() {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [userData] = useState(userInfo);

  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [searched, setSearched] = useState(
    window.location.search.includes("?searched=")
  );

  const [AlertBox, setNotification] = useAlert();
  const [
    ProductPicker,
    ProductDisplay,
    products,
    ,
    ,
    clearProducts,
    getProductArray,
  ] = useProductPicker();

  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState([]);
  const [date, setDate] = useState(null);
  const [viewed, setViewed] = useState(false);
  const userRef = useRef(null);

  const getUsers = async () => {
    const result = await Axios.get("/api/auth/users/support");
    setUsers(result.data.users);
  };

  const getStats = async () => {
    const result = await Axios.get(
      `/api/support/fetchAgentStats?${searched ? "searched=true" : ""}`
    );
    setStats(result.data.stats);
    setDate(result.data.date.updatedOn);
    setViewed(result.data.date.viewed || false);
  };

  const clean = (x) => {
    if (isNaN(x)) return 0;
    else return x;
  };

  const submit = async () => {
    try {
      const user = document.getElementById("user-select").value;
      setNotification(
        "success",
        "This might take a while.. We'll send you a mail once its done"
      );

      const result = await Axios.post(
        "/api/support/generateRealTimeAgentStats",
        {
          products: getProductArray("v"),
          user,
          fromDate,
          toDate,
          userData: {
            email: userData.email,
          },
        }
      );
      setStats(result.data.stats);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadOrders = async () => {
    const user = document.getElementById("user-select").value;
    setNotification(
      "success",
      "This might take upto 10 minutes, we'll send you an email with the report."
    );

    const result = await Axios.post("/api/support/generateRawData", {
      products: getProductArray("v"),
      user,
      fromDate,
      toDate,
      userData: {
        email: userData.email,
      },
    });

    if (result.data.file) window.open(result.data.file, true);
  };

  useEffect(() => {
    getUsers();
    getStats();
  }, []);

  useEffect(() => {
    getStats();
  }, [searched]);

  return (
    <div>
      <AlertBox />
      <center>
        <div
          className="tool-button tooltip-nav"
          onClick={() => downloadOrders()}
        >
          <i className="fas fa-arrow-circle-down"></i>
          <span className="tooltip-nav-text">Download Raw Data</span>
        </div>
      </center>
      <center style={{ margin: "20px" }}>
        {date
          ? ` Last Updated On ${new Date(date).toLocaleString()}`
          : "Not Updated Recently"}
      </center>
      <center
        style={{ margin: "20px", cursor: "pointer" }}
        onClick={() => setSearched((current) => !current)}
      >
        Display{" "}
        <b>
          {!searched
            ? `${!viewed ? "unviewed" : "previously"} searched`
            : "current"}
        </b>{" "}
        results
      </center>
      <div
        style={{
          margin: "0px 80px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        {products.length > 0 ? (
          <div style={{ padding: "20px" }}>
            <ProductDisplay />
          </div>
        ) : (
          ""
        )}
        <div style={{ display: "flex", height: "60px" }}>
          <DatePicker />
          <ProductPicker />
          <select
            id="user-select"
            ref={userRef}
            className="form-control form-control-sm defaultInput"
          >
            <option defaultSelected>Choose an agent</option>
            {users.map((user) => (
              <option value={user.name}>{user.name}</option>
            ))}
          </select>
          <div
            style={{
              background: "#2980b9",
              padding: "20px 20px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => submit()}
          >
            Submit
          </div>
        </div>
      </div>
      {/* <div>
        <Bar
          data={{
            datasets: [
              {
                barPercentage: 1,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
                data: [10, 20, 30, 40, 50, 60, 70],
              },
            ],
          }}
          width={100}
          height={50}
          options={{ maintainAspectRatio: false }}
        />
      </div> */}
      <div>
        {stats.map((userStats) => (
          <div className="dialer-stats-user">
            <div className="dialer-stats-user-details">{userStats.name}</div>
            <div className="dialer-stats-bar">
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="dialer-stats-bar-section">
                      <div
                        className="dialer-stats-element"
                        formula={`Delivered OC Orders (${userStats.deliveredOC}) / Total Confirmed OC Orders (${userStats.oconfirmed}) (Percentage of orders delivered out of the confirmed 'order confirmation' orders)`}
                      >
                        <CircularProgressBar
                          percentage={`${
                            parseFloat(
                              clean(
                                userStats.deliveredOC / userStats.oconfirmed
                              )
                            ) * 100
                          }%`}
                          sqSize={115}
                          color={"#1abc9c"}
                        />
                        <center>
                          Delivered Percentage (OC) ({userStats.deliveredOC})
                        </center>
                      </div>
                      <div
                        className="dialer-stats-element"
                        formula={`Cancelled OC Orders (${userStats.cancelledOC}) / Every OC Order (${userStats.oc}) (Percentage of orders cancelled out of all the attempted 'order confirmation' orders)`}
                      >
                        <CircularProgressBar
                          percentage={`${
                            parseFloat(
                              clean(userStats.cancelledOC / userStats.oc)
                            ) * 100
                          }%`}
                          sqSize={115}
                          color={"#E74C3C"}
                        />
                        <center>
                          Cancelled Percentage (OC) ({userStats.cancelledOC})
                        </center>
                      </div>
                    </div>
                    <div className="dialer-stats-numbers">
                      <center className="dialer-stats-numbers-label">
                        Total Confirmed (OC) ({userStats.oconfirmed})
                      </center>
                      <center className="dialer-stats-numbers-label">
                        Order Confirmation Calls ({userStats.oc})
                      </center>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="dialer-stats-bar-section">
                      <div
                        className="dialer-stats-element"
                        formula={`Delivered AB Orders (${userStats.deliveredAB}) / Total Manifested AB Orders (${userStats.manifestedAB}) (Percentage of orders delivered out of all the manifested abandoned orders)`}
                      >
                        <CircularProgressBar
                          percentage={`${
                            parseFloat(
                              clean(
                                userStats.deliveredAB / userStats.manifestedAB
                              )
                            ) * 100
                          }%`}
                          sqSize={115}
                          color={"#1abc9c"}
                        />
                        <center>
                          Delivered Percentage (AB) ({userStats.deliveredAB})
                        </center>
                      </div>
                      <div
                        className="dialer-stats-element"
                        formula={`Cancelled AB Orders (${userStats.cancelledAB}) / Every AB order (${userStats.abandoned}) (Percentage of orders cancelled out of all the attempted abandoned orders)`}
                      >
                        <CircularProgressBar
                          percentage={`${
                            parseFloat(
                              clean(userStats.cancelledAB / userStats.abandoned)
                            ) * 100
                          }%`}
                          sqSize={115}
                          color={"#E74C3C"}
                        />
                        <center>
                          Cancelled Percentage (AB) ({userStats.cancelledAB})
                        </center>
                      </div>
                    </div>
                    <div className="dialer-stats-numbers">
                      <center className="dialer-stats-numbers-label">
                        Total Manifested (AB) ({userStats.manifestedAB})
                      </center>
                      <center className="dialer-stats-numbers-label">
                        Abandoned Calls ({userStats.abandoned})
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "15px", background: "#f1f1f1" }}>
              <center>{userStats.total}</center>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { Fragment } from "react";
import Axios from "axios";

function BatchUpdateOrderDetailsAWB() {
  document.title = "Batch Update ShipRocket Orders(Awbs)";
  const handleSubmit = async () => {
    if (document.getElementById("awbs_text_area").value === "") {
      alert("Please enter awbs in the text area");
      return;
    }
    let awbs = document.getElementById("awbs_text_area").value.split("\n");
    awbs = awbs.map((awb) => awb.trim());
    console.log(`This is the awbs array : `, awbs);
    let response = await Axios.post(
      `/api/operations/batchUpdateOrderDetailsAWBs`,
      { awbs }
    );

    let updateUnsuccessful = response.data.unsuccessfulupdate;
    let updateSuccessful = response.data.successfulupdate;
    let unsuccessfulMsg = "";
    if (updateUnsuccessful.length === 0) alert(`SUCCESSFUL UPDATE!`);
    else {
      updateUnsuccessful.forEach((orderId, index) => {
        if (index === updateUnsuccessful.length - 1)
          unsuccessfulMsg += orderId + ".";
        else unsuccessfulMsg += orderId + ", ";
      });
      alert(
        `SUCCESSFULL update count  : ${updateSuccessful.length}\nUNSUCCESSFULL update count : ${updateUnsuccessful.length}\nFailed order ids : ${unsuccessfulMsg}`
      );
    }
  };

  return (
    <Fragment>
      <div style={{ padding: "20px" }}>
        <h2>Batch Update Order Details - AWB</h2>
        <div className="form-group">
          <label for="awbs_text_area">
            Place Your List of AWBs(ShipRocket) here
          </label>
          <textarea
            className="form-control"
            id="awbs_text_area"
            rows="3"
            style={{ height: "70vh", borderRadius: "0px" }}
          ></textarea>
          <button
            type="submit"
            className="btn btn-primary mb-2 btn-lg"
            style={{ borderRadius: "0px", marginTop: "10px" }}
            onClick={handleSubmit}
          >
            Update Orders Details
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default BatchUpdateOrderDetailsAWB;

import Axios from "axios";
import React, { Component } from "react";

export const addStore = async (getStoresList, choosenStore, setMessage) => {
  if (choosenStore === "Shopify") {
    let storeName = document.getElementById("StoreName").value;
    // let mongoSRV = document.getElementById("MongoURI").value;
    let channelID = document.getElementById("ChannelID").value;
    let storeEmail = document.getElementById("StoreEmail").value;
    let shopifyName = document.getElementById("ShopifyName").value;
    let shopifyAPIUsername = document.getElementById("ShopifyAPIUsername")
      .value;
    let shopifyAPIPassword = document.getElementById("ShopifyAPIPassword")
      .value;
    let user = document.getElementById("customers").value;

    let shiprocketAPIUsername = document.getElementById("ShiprocketAPIUsername")
      .value;
    let shiprocketAPIPassword = document.getElementById("ShiprocketAPIPassword")
      .value;
    let addressThreshold = parseInt(
      document.getElementById("AddressThreshold").value
    );
    let sendGridAPIKey = document.getElementById("SendGridAPIKey").value;
    let googleAnalyticsViewId = document.getElementById("GoogleAnalyticsViewId")
      .value;
    let smsMobiSenderId = document.getElementById("SmsMobiSenderId").value;
    let store = {
      storeName,
      mongoSRV: `mongodb://localhost:27017/${shopifyName}?authSource=admin`,
      storeEmail,
      channelID,
      shopifyName,
      shopifyAPIUsername,
      shopifyAPIPassword,
      shiprocketAPIUsername,
      shiprocketAPIPassword,
      addressThreshold,
      sendGridAPIKey,
      googleAnalyticsViewId,
      user,
      smsMobiSenderId,
    };
    if (
      storeName !== "" &&
      // mongoSRV !== "" &&
      channelID !== "" &&
      storeEmail !== "" &&
      shopifyName !== "" &&
      shopifyAPIUsername !== "" &&
      shopifyAPIPassword !== "" &&
      shiprocketAPIUsername !== "" &&
      shiprocketAPIPassword !== "" &&
      addressThreshold !== "" &&
      sendGridAPIKey !== "" &&
      // googleAnalyticsViewId !== "" &&
      user !== ""
    ) {
      let result = await Axios.post("/api/stores/", store);
      if (result) {
        alert(result.data.msg);
        await getStoresList();
        document.getElementById("StoreForm").reset();
        let message = (
          <ul
            style={{
              padding: "20px",
              listStyleType: "none",
            }}
          >
            <li>
              {`Order Creation - https://bazingamedia.xyz/api/orders/new_order/${shopifyName}/shiprocket`}
            </li>
            <li>
              {`Product Creation - https://bazingamedia.xyz/api/product/newProduct/${shopifyName}`}
            </li>
            <li>
              {`Product Updation - https://bazingamedia.xyz/api/product/productUpdate/${shopifyName}`}
            </li>
            <li>
              {`Product Deletion - https://bazingamedia.xyz/api/product/deleteProduct/${shopifyName}`}
            </li>
          </ul>
        );
        setMessage({
          message,
          shopifyName,
        });
        // window.open(
        //   `https://${shopifyName}.myshopify.com/admin/settings/notifications`,
        //   "_blank"
        // );

        // let data = {
        //   "webhook": {
        //     "topic": "carts/create",
        //     "address": `https://bazingamedia.xyz/api/orders/new_order/${shopifyName}/shiprocket`,
        //     "format": "json"
        //   }
        // }

        // let res = await Axios.post(`https://${shopifyAPIUsername}:${shopifyAPIPassword}@${shopifyName}.myshopify.com/admin/api/2019-10/webhooks.json`, data)
        // console.log("RESPONSE", res)

        // if(res.data){}
        let products = await Axios.get(
          `/api/product/saveAllProducts/?store=${shopifyName}`
        );

        let orders = await Axios.get(
          `/api/orders/save_all_orders/?store=${shopifyName}`
        );

        if (products) alert("Products have been imported");
        else alert("Failed to import products");
        if (orders) alert("Orders have been imported");
        else alert("Failed to import orders");

        return true;
      }
    } else {
      alert("Enter All the Fields");
      return false;
    }
  } else {
    let storeName = document.getElementById("StoreName").value;
    // let mongoSRV = document.getElementById("MongoURI").value;
    let channelID = document.getElementById("ChannelID").value;
    let storeEmail = document.getElementById("StoreEmail").value;
    let shopifyName = document.getElementById("ShopifyName").value;

    let user = document.getElementById("customers").value;

    let shiprocketAPIUsername = document.getElementById("ShiprocketAPIUsername")
      .value;
    let shiprocketAPIPassword = document.getElementById("ShiprocketAPIPassword")
      .value;
    let addressThreshold = parseInt(
      document.getElementById("AddressThreshold").value
    );

    let store = {
      storeName,
      mongoSRV: `mongodb://localhost:27017/${shopifyName}?authSource=admin`,
      storeEmail,
      channelID,
      shopifyName,
      shiprocketAPIUsername,
      shiprocketAPIPassword,
      addressThreshold,
      user,
      click: true,
    };
    if (
      storeName !== "" &&
      // mongoSRV !== "" &&
      channelID !== "" &&
      storeEmail !== "" &&
      shopifyName !== "" &&
      shiprocketAPIUsername !== "" &&
      shiprocketAPIPassword !== "" &&
      addressThreshold !== "" &&
      user !== ""
    ) {
      let result = await Axios.post("/api/stores/", store);
      if (result) {
        alert(result.data.msg);
        await getStoresList();
        document.getElementById("StoreForm").reset();

        return true;
      }
    } else {
      alert("Enter All the Fields");
      return false;
    }
  }
};

import React, { Fragment } from "react";
import resolveStatus from "../../utils/resolveStatus";
// import { getCourierName } from "../../functions/getCourierName";
import { getCourierName } from "../../../../utils/helpers/courier";
import useOrderModal from "../../../../components/global/OrderDetails";

import moment from "moment";

const OrderItem = ({ order, user, number, selectedColumnsObject }) => {
  const [OrderModal, setShowOrder] = useOrderModal();

  return (
    <Fragment>
      <tr id={order.name} className="tracking-row">
        <td scope="row">{number + 1}</td>

        <td scope="row" style={{ textAlign: "left", position: "relative" }}>
          <a
            href={`https://${order.store.shopifyName}.myshopify.com/admin/orders/${order.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${order.name}`}
          </a>
          {user.customer
            ? ""
            : ` ${
                order.scheduledThrough
                  ? order.scheduledThrough === "ShipYaari"
                    ? "(SY)"
                    : order.scheduledThrough === "GoSwift"
                    ? "(G)"
                    : order.scheduledThrough.includes("ClickPost")
                    ? "(C)"
                    : order.scheduledThrough === "ShadowFax"
                    ? "(SF)"
                    : "(S)"
                  : ""
              }`}
          {order.experiment
            ? order.experiment.belongsToExpSet
              ? " (Experiment Set)"
              : " (Control Set)"
            : ""}
          <br />
          {order.manifestedOrderReference
            ? `(${order.manifestedOrderReference})`
            : ""}
          {order.abandonedOrderReference
            ? `(${order.abandonedOrderReference})`
            : ""}
          <OrderModal
            order={order}
            position={{ top: "2px", left: "-10px" }}
            cursor={{ color: "#bdc3c7" }}
          />
        </td>

        <td>
          {
            order.scheduledThrough && order.scheduledThrough === "GoSwift" ? (
              order.awb_ref_no ? (
                <a
                  href={`https://app.goswift.in/track/${
                    order.tracking_id ? order.tracking_id : "-"
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.awb_ref_no}
                </a>
              ) : (
                "-"
              )
            ) : order.scheduledThrough &&
              order.scheduledThrough.toLowerCase().includes("shadowfax") ? (
              order.awb_ref_no ? (
                <a
                  href="https://tracker.shadowfax.in/#/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.awb_ref_no}
                </a>
              ) : (
                "-"
              )
            ) : order.scheduledThrough &&
              order.scheduledThrough.toLowerCase().includes("shiprocket") &&
              order.awb_ref_no ? (
              <a
                // href={`https://track.aftership.com/${
                //   getCourierName(order.courier_company_id).link
                // }/${order.awb_ref_no}`}
                href={`https://shiprocket.co/tracking/${order.awb_ref_no}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {order.awb_ref_no}
              </a>
            ) : (
              "-"
            )
            // : order.awb_ref_no ? (
            //   <a
            //     href={`https://www.delhivery.com/track/package/${order.awb_ref_no}`}
            //     target="_blank"
            //     rel="noopener noreferrer"
            //   >
            //     {order.awb_ref_no}
            //   </a>
            // ) : (
            //   "-"
            // )
          }
        </td>

        {user.customer ? (
          <td>
            {order.status_code
              ? resolveStatus(order.status_code)
              : "Order Received On Store"}
          </td>
        ) : (
          <td>
            <div className="hover-span-container">
              {order.status_code
                ? resolveStatus(order.status_code)
                : "Order Received On Store"}

              {order.status_code === 102 ? (
                <span className="hover-span">
                  {order.remarks
                    ? new RegExp("order cancelled -", "i").test(order.remarks)
                      ? `${order.name} - ${order.remarks.replace(
                          new RegExp("order cancelled - ", "i"),
                          ""
                        )}`
                      : null
                    : null}
                </span>
              ) : null}
              {order.status_code === 103 ? (
                <span className="hover-span">
                  {order.reasonForOrderCancellation
                    ? `${
                        order.name
                      } - ${order.reasonForOrderCancellation.replace(
                        new RegExp("order cancelled - ", "i"),
                        ""
                      )} - ${
                        order.cancelled_by.user.name
                          ? order.cancelled_by.user.name
                          : order.cancelled_by.user.email
                      }\n`
                    : null}
                </span>
              ) : null}
              {order.status_code === 105 ? (
                <span className="hover-span">
                  {order.remarksForOrderRemoval
                    ? `${order.name} - ${order.remarksForOrderRemoval.replace(
                        new RegExp("order cancelled - ", "i"),
                        ""
                      )} - ${
                        order.removedBy ? order.removedBy.user.name : null
                      }\n`
                    : null}
                </span>
              ) : null}
            </div>
          </td>
        )}
        {selectedColumnsObject.contact.status ? (
          <td>
            {order.email
              ? order.email
              : order.customer.email
              ? order.customer.email
              : order.phone
              ? order.phone
              : order.customer.phone}
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.created_on.status ? (
          <td>
            {moment(new Date(order.created_at)).format("DD/MM/YYYY, hh:mm A")}
          </td>
        ) : (
          ""
        )}

        {selectedColumnsObject.scheduledOn.status ? (
          <td>
            {order.scheduledOn
              ? moment(new Date(order.scheduledOn)).format(
                  "DD/MM/YYYY, hh:mm A"
                )
              : "-"}
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.pickedUpOn.status ? (
          <td>
            {order.pickedUpOn
              ? moment(new Date(order.pickedUpOn)).format("DD/MM/YYYY, hh:mm A")
              : "-"}
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.deliveredOn.status ? (
          <td>
            {order.deliveredOn
              ? moment(new Date(order.deliveredOn)).format(
                  "DD/MM/YYYY, hh:mm A"
                )
              : "-"}
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.turnAroundTime.status ? (
          <td>
            {order.deliveredOn && order.pickedUpOn
              ? moment
                  .duration(
                    moment(order.deliveredOn).diff(moment(order.pickedUpOn))
                  )
                  .days() +
                " days " +
                moment
                  .duration(
                    moment(order.deliveredOn).diff(moment(order.pickedUpOn))
                  )
                  .hours() +
                " hours"
              : "-"}
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.products.status ? (
          <td>
            <ul>
              {order.line_items.map((item, i) => (
                <li style={{ listStyleType: "none" }}>
                  {`#${i + 1}`} {item.name} x{" "}
                  <span style={{ fontWeight: "bold" }}>{item.quantity}</span>
                </li>
              ))}
            </ul>
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.total_price.status ? (
          <td>{order.total_price}</td>
        ) : (
          ""
        )}
        {selectedColumnsObject.shipping_address.status ? (
          <td style={{ maxWidth: "400px" }}>
            {order.shipping_address.name +
              " " +
              order.shipping_address.phone +
              "\n" +
              order.shipping_address.province +
              " " +
              order.shipping_address.city +
              "\n" +
              order.shipping_address.address1 +
              "\n" +
              order.shipping_address.address2 +
              "\n" +
              order.shipping_address.company +
              " " +
              order.shipping_address.zip}
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.billing_address.status ? (
          <td style={{ maxWidth: "400px" }}>
            {order.billing_address.name +
              " " +
              order.billing_address.phone +
              "\n" +
              order.billing_address.province +
              " " +
              order.billing_address.city +
              "\n" +
              order.billing_address.address1 +
              "\n" +
              order.billing_address.address2 +
              "\n" +
              order.billing_address.company +
              " " +
              order.billing_address.zip}
          </td>
        ) : (
          ""
        )}
        {selectedColumnsObject.courier_company_id.status ? (
          <td>
            {order.courier_company_id
              ? getCourierName(order.courier_company_id)
              : "-"}
          </td>
        ) : (
          ""
        )}
      </tr>
    </Fragment>
  );
};

export default OrderItem;

import React, { useEffect, useState, Fragment, useContext } from "react";
import { GlobalConsumer } from "../../../Global-Context/global-context";

import NavBar from "../res/NavBar";
import Axios from "axios";

const PickupRequestGeneration = () => {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);
  const [store, setStore] = useState("start");
  document.title = "Generate Pickup Request";

  const generatePickupRequest = async (e, store) => {
    e.preventDefault();
    try {
      let orderName = document.getElementById("orderName").value;
      if (!orderName) return alert("Please Specify OrderID");
      const result = await Axios.post(
        `/api/operations/generatePickupForShipment`,
        {
          orderName,
          store
        }
      );
      console.log("result from backend = ", result.data);
      if (result.data.msg) {
        alert(result.data.msg);
      }
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {}, []);
  console.log(user);
  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} />
      {store !== "start" ? (
        <div className="container-fluid">
          <form className="form-horizontal">
            <br />
            <div>
              <p id="status" name="status" />
            </div>
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-header">
                  <h3>Generate A Pickup Request</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label
                      className="control-label col-sm-6"
                      htmlFor="orderName"
                    >
                      OrderID:
                    </label>
                    <div className="col-sm-2">
                      <input
                        type="text"
                        className="form-control"
                        id="orderName"
                        name="orderName"
                        placeholder="Enter OrderID"
                        required
                        onKeyPress={e => {
                          if (e.charCode === 13)
                            generatePickupRequest(e, store);
                        }}
                      />
                    </div>
                  </div>
                  {/* <br /> */}
                  {/* <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-primary mb-2 btn-lg"
                        onClick={e => reprint(e, store)}
                      >
                        GeneratePickupRequest
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>Choose a store</h2>
        </div>
      )}
    </Fragment>
  );
};

export default PickupRequestGeneration;

import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomerLead from "../res/CustomerLead";
import Spinner from "../../global/Spinner";

const UploadLeads = () => {
  document.title = "Upload Leads";

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [leads, setLeads] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);
    let inputFile = document.getElementById("upload-leads-input");
    inputFile = inputFile.files[0];
    if (!inputFile) {
      alert(`Please upload a file!`);
      return;
    }
    const formData = new FormData();
    formData.append("file", inputFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post("/api/upload/leadCsv", formData, config);
    setLeads(response.data.Details);
    setEditMode(true);
    setLoading(false);
    document.getElementById("upload-leads-container").className += " edit-mode";
  };

  const getStoresList = async () => {
    let result = await axios.get("/api/stores");
    result.data.map((store) => {
      delete store.useNewRefreshButton;
    });
    setStores(result.data);
  };

  const fetchProducts = async (store) => {
    if (!store) return;
    let storeStringified = JSON.stringify(store);
    let productList = await axios.get("/api/product", {
      params: { store: storeStringified },
    });
    productList = productList.data.products;
    let options = [];
    for (let i = 0; i < productList.length; i++) {
      let title = productList[i].title;
      productList[i].variants.forEach((variant) => {
        if (variant.title === "Default Title") {
          options.push({
            title,
            variant_id: variant.variant_id,
            product_id: variant.product_id,
          });
        } else {
          options.push({
            title: title + " " + variant.title,
            variant_id: variant.variant_id,
            product_id: variant.product_id,
          });
        }
      });
    }
    setProducts(options);
  };

  const handleOption = () => {
    let selectedOption = document.querySelector(
      `#productList option[value="${
        document.getElementById("productSearch").value
      }"]`
    );
    if (!selectedOption) {
      setProduct(null);
      return;
    }
    products.forEach((product) => {
      if (selectedOption.value === product.title) setProduct(product);
    });
  };

  const handleFinalSubmit = async () => {
    if (!store) {
      alert("Please select a store!");
      return;
    }
    if (!product) {
      alert("Please select a product!");
      return;
    }
    setLoading(true);
    let body = {
      details: leads,
      store,
      product,
    };
    let response = await axios.post("/api/orders/saveLeads", body);

    if (!response.data.success) {
      alert(response.data.message);
      return;
    }

    alert(response.data.message);
    setEditMode(false);
    setEditIndex(null);
    setLeads([]);
    setProduct(null);
    setProducts([]);
    setStore(null);
    setStores([]);

    setLoading(false);

    document.getElementById("upload-leads-container").className =
      "card upload-leads-container";
  };

  useEffect(() => {
    getStoresList();
    if (store) {
      fetchProducts(store);
    }
  }, [store]);

  return !loading ? (
    <div id="upload-leads-container" className="card upload-leads-container">
      <div className="card-header upload-leads-header">
        {editMode ? (
          <div className="upload-leads-final-controls">
            <div className="dropdown chooseStoreNormal">
              <button
                className="btn  dropdown-toggle storeButton"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {store ? store.storeName : "Choose store"}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {stores.map((storeItem, index) => (
                  <button
                    key={index}
                    className="dropdown-item"
                    onClick={() => {
                      setStore(storeItem);
                    }}
                  >
                    {storeItem.storeName}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <input
                type="text"
                id="productSearch"
                list="productList"
                autoComplete="off"
                placeholder="Products"
                className="form-control form-control-sm"
                onChange={handleOption}
              />
              <datalist id="productList">
                {products.map
                  ? products.map((option, index) => (
                      <option
                        key={index}
                        data-value={option}
                        value={option.title}
                      >
                        {option.title}
                      </option>
                    ))
                  : null}
              </datalist>
            </div>
            <button
              className="btn btn-primary final-submit-button"
              onClick={handleFinalSubmit}
            >
              Submit
            </button>
          </div>
        ) : (
          <span>
            {"Upload leads CSV below  "}
            <i className="fas fa-long-arrow-alt-down"></i>
          </span>
        )}
      </div>
      {!editMode ? (
        <div className="card-body upload-leads-body">
          <div className="upload-leads-controls">
            <input
              id="upload-leads-input"
              className="form-control"
              type="file"
              placeholder="Leads csv"
            />
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div className="upload-leads-body-edit-mode">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {leads.map((lead, index) => (
                <CustomerLead
                  customer={lead}
                  key={index}
                  customerIndex={index}
                  setLeads={setLeads}
                  editIndex={editIndex}
                  setEditIndex={setEditIndex}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default UploadLeads;

import React, { useState, useContext } from "react";
import { GlobalConsumer } from "../../Global-Context/global-context";
import Navigation from "./Navigation";
import MainBoard from "./MainBoard";
import Dimensions from "./Dimensions";

import Operation from "./Operation";
import UpdateProducts from "../layouts/UpdateProducts";

function Dashboard() {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);
  const [activeTab, setActiveTab] = useState("Issues");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navigation setActiveTab={setActiveTab} />
      {activeTab === "Issues" ? <MainBoard /> : ""}
      {activeTab === "Schedule Orders" ? (
        <Operation operation={"Schedule"} bulk={true} user={user} />
      ) : (
        ""
      )}
      {activeTab === "Cancel Orders" ? (
        <Operation operation={"Cancel"} bulk={true} user={user} />
      ) : (
        ""
      )}
      {activeTab === "Update Products" ? <UpdateProducts user={user} /> : ""}
      {activeTab === "Dimensions" ? <Dimensions user={user} /> : ""}
    </div>
  );
}

export default Dashboard;

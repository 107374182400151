import React, { Fragment } from "react";
// import { submitImage } from "../functions/submitImage";

const DamagedImage = ({ store, order, fetchOrders }) => {
  return (
    <Fragment>
      <tr>
        <td>{store.storeName}</td>
        <td>{order.name}</td>
        <td>{order.awb_ref_no}</td>
        <td>
          {order.line_items.map((product, i) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0px"
              }}
              key={i}
            >
              <div>
                {product.name} X {product.quantity}
              </div>
              <div>
                <img
                  className="img-fluid img-thumbnail"
                  src={`data:image/jpeg;base64,${product.damagedProductImage}`}
                  alt="no img"
                  style={{
                    height: "200px",
                    maxHeight: "250px",
                    width: "300px",
                    maxWidth: "350px"
                  }}
                />
              </div>
            </div>
          ))}
        </td>
        <td>{order.packageRejectedOn}</td>
        <td>{order.uploadedImageOn}</td>
        <td align="right">{order.SR_Credit}</td>
      </tr>
    </Fragment>
  );
};

export default DamagedImage;

import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import useDatePicker from "../../global/DatePicker";
import useDatePerspective from "../../global/DatePerspective";
import TagBar from "../../../trackingboard/OrderTracker/components/Items/TagBar";
import Spinner from "../../home/res/Spinner";

function StrikeRateAnalysis() {
  const [DatePicker, fromDate, toDate] = useDatePicker("2020");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [total, setTotal] = useState("");
  const [result, setResult] = useState(false);
  const [alertMsg20March, setAlertMsg20March] = useState(true);
  const [DatePerspectiveFilter, perspectiveOption] = useDatePerspective();

  document.title = "Strike Rate Analysis";

  const submitDetails = async () => {
    let store = JSON.parse(document.getElementById("store_selector").value);
    if (!store) return alert("Please choose a store");
    let selectedProducts = global.selected;
    let productsList = selectedProducts.map((item) => {
      return parseInt(item[0]);
    });

    let data = {
      store,
      pidArray: productsList ? productsList : [],
      fromDate,
      toDate,
      perspectiveOption,
    };

    setLoading(true);

    let result = await Axios.post("/api/operations/strikeRateAnalysis", data);
    setLoading(false);
    setTotal(result.data.data.totalDelivery);
    setResult(result.data.data);

    if (result.data.fromDateLesserThan20MarchMsg && alertMsg20March) {
      alert(result.data.fromDateLesserThan20MarchMsg);
      setAlertMsg20March(false);
    }
    if (result.data.data.others !== result.data.data.othersCountUsingQuery)
      console.warn(
        `got ${result.data.data.others} using subtraction but ${result.data.data.othersCountUsingQuery} using query`
      );
  };
  const updateProducts = async (value) => {
    let store = value;
    let productList = await Axios.get(
      `/api/globalDashboard/fetchProducts/${encodeURIComponent(store)}`
    );
    setProducts(
      productList
        ? Array.isArray(productList.data)
          ? productList.data
          : []
        : []
    );
    setFiltered(
      productList
        ? Array.isArray(productList.data)
          ? productList.data
          : []
        : []
    );
    setStore(JSON.parse(value));
  };

  const multiCheck = (e, value) => {
    if (!isNaN(parseFloat(value))) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";

      if (value2send) {
        global.selected.push([...value2send.split(",")]);

        document.getElementById("productSearch").value = "";

        let productList = global.selected.map((item) => item[0]);

        let newArray = products.filter((product) => {
          return !productList.includes(product[0].toString());
        });

        setFiltered(newArray);
      } else {
        alert("Choose a product");
      }
    }
  };

  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };

  useEffect(() => {
    global.selected = [];
    getStores();
  }, []);
  return (
    <div className="stats-content">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        {global.selected
          ? global.selected.map((item, i) => (
              <TagBar
                item={item}
                key={i}
                permuteOrders={getStores}
                setFiltered={setFiltered}
                filtered={filtered}
              />
            ))
          : ""}
      </div>
      <div className="stats-filter">
        <DatePerspectiveFilter />
        <select
          placeholder="Stores"
          id="store_selector"
          className="form-control form-control-sm"
          style={{
            height: "100%",
            fontSize: "0.9rem",
            borderRadius: 0,
          }}
          onChange={(e) => updateProducts(e.target.value)}
        >
          <option value={JSON.stringify(store)} selected disabled>
            {store ? store.storeName : "Choose Store"}
          </option>
          {stores.map((store, index) => (
            <option value={JSON.stringify(store)} key={index}>
              {store.storeName}
            </option>
          ))}
        </select>

        <input
          type="text"
          id={"productSearch"}
          list="productList"
          autoComplete="off"
          placeholder="Products"
          className="form-control form-control-sm"
          style={{
            height: "100%",
            fontSize: "0.9rem",
            borderRadius: 0,
            width: "100%",
          }}
          onChange={(e) => {
            multiCheck(e, e.target.value);
          }}
        />
        <datalist id="productList">
          {filtered.map((option, index) => (
            <option key={index} data-value={option} value={option[0]}>
              {option[1]}
            </option>
          ))}
        </datalist>

        <DatePicker />
        <button
          className="btn btn-danger"
          style={{ borderRadius: 0 }}
          onClick={() => submitDetails(fromDate, toDate)}
        >
          Submit
        </button>
      </div>

      {!loading ? (
        <Fragment>
          {total ? (
            <Fragment>
              <div
                className="stats-total"
                style={{
                  margin: "0px",
                  color: "black",
                  marginTop: "30px",
                }}
              >
                <center>
                  <strong>{total}</strong> Orders{" "}
                </center>
              </div>
            </Fragment>
          ) : (
            ""
          )}

          <div
            className="stats-body stats-grid-container"
            style={{ marginTop: "40px", display: "flex" }}
          >
            {result ? (
              <table className="table  ordersTable">
                <thead style={{ background: "#e74c3c" }}>
                  <tr>
                    <th scope="col">Total Deliveries={result.totalDelivery}</th>
                    <th>Number</th>
                    <th>Percent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Delivery Attempt 1</td>
                    <td>{result.attempt1Delivery}</td>
                    <td>{result.attempt1DeliveryPercent}%</td>
                  </tr>
                  <tr>
                    <td>Delivery Attempt 2</td>
                    <td>{result.attempt2Delivery}</td>
                    <td>{result.attempt2DeliveryPercent}%</td>
                  </tr>
                  <tr>
                    <td>Delivery Attempt 3</td>
                    <td>{result.attempt3Delivery}</td>
                    <td>{result.attempt3DeliveryPercent}%</td>
                  </tr>
                  <tr>
                    <td>Others</td>
                    <td>{result.others}</td>
                    <td>{result.othersPercent}%</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              ""
            )}
          </div>
        </Fragment>
      ) : (
        <div style={{ margin: "20px" }}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default StrikeRateAnalysis;

import React, { useState } from "react";

function Navigation() {
  const [bottom, setBottom] = useState(false);
  window.onscroll = function(ev) {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      // you're at the bottom of the pageale
      setBottom(true);
    } else {
      setBottom(false);
    }
  };
  function topFunction() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setBottom(false);
  }
  return bottom ? (
    <div className="navigation-arrow" onClick={() => topFunction()}>
      <i className="fas fa-arrow-up"></i>
    </div>
  ) : (
    ""
  );
}

export default Navigation;

import React, { useState, useRef } from "react";
import Axios from "axios";
import Counter from "../res/Counter";
import Spinner from "../../global/Spinner";
let audio = new Audio(require("../res/sounds/tink.wav"));

const ReverseScan = ({ setPage, mode }) => {
  document.title = "Reverse Scan";

  const [result, setResult] = useState(null);
  const [scannedCount, setScannedCount] = useState(0);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputElement = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !inputElement.current.readOnly) {
      inputElement.current.readOnly = true;
      console.log(inputElement.current.value);
      const awbScanned = inputElement.current.value;
      setScannedCount((scannedCount) => scannedCount++);
      setScannedBarcodes((scannedBarcodes) => {
        const newScannedBarcodes = [...scannedBarcodes, awbScanned];
        return newScannedBarcodes;
      });
      audio.play();
      inputElement.current.readOnly = false;
      inputElement.current.value = "";
      setScannedCount((count) => {
        return count + 1;
      });
    }
  };

  const handleSubmit = async () => {
    inputElement.current.readOnly = true;
    setLoading(true);
    const rtoScanUrl =
      mode === "Meesho"
        ? `/api/warehouse/reverseScanMeesho`
        : // : `/api/warehouse/barcode`; // old route(deprecated)
          `/api/warehouse/saveScannedRTOs`;
    let finalScannedBarcodes = scannedBarcodes.filter(
      (scannedBarcode) => scannedBarcode.length !== 0
    );
    finalScannedBarcodes = finalScannedBarcodes.map((scannedBarcode) =>
      scannedBarcode.trim().toUpperCase()
    );
    const payload = {
      scannedBarcodes: finalScannedBarcodes,
    };
    const { data } = await Axios.post(rtoScanUrl, payload);
    setLoading(false);
    if (!data.status) {
      alert(`SOMETHING WENT WRONG! CONTACT DEVELOPERS!`);
      inputElement.current.readOnly = false;
      return;
    }
    setResult(data);
    inputElement.current.readOnly = false;
  };

  const removeBarcode = (barcodeToBeRemoved) => {
    const newBarcode = scannedBarcodes.filter(
      (barcode) => !(barcode === barcodeToBeRemoved)
    );
    setScannedBarcodes(newBarcode);
    setScannedCount((count) => {
      return count - 1;
    });
  };

  const styles = {
    backButtonContainer: {
      marginLeft: "2vw",
      marginTop: "2vh",
      width: "auto",
      color: "#E74C37",
    },
  };

  return (
    <>
      <div style={styles.backButtonContainer}>
        <i
          className="fas fa-2x fa-arrow-circle-left back-button-icon"
          onClick={() => setPage("main")}
        ></i>
      </div>
      <div className="scan-awb-frontend">
        <div style={{ background: "#E74C3C", display: "flex" }}>
          <div className="reverse-scanning-header">
            <h2>
              {mode === "Meesho"
                ? "Reverse Scan (Meesho)"
                : "Reverse Scan (Jarvis)"}
            </h2>
          </div>
          <Counter count={scannedCount} />
        </div>
        <div className="scan-awb-body">
          <input
            type="text"
            name="awb_no"
            id="awb_input"
            className="form-control form-control-lg"
            placeholder="Scan RTO package"
            onKeyDown={(e) => handleKeyDown(e)}
            ref={inputElement}
          ></input>
          <div id="reverse-scan-submit-container">
            <button
              id="reverse-scan-submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit After Scanning All
            </button>
            {loading ? (
              <div id="reverse-scan-spinner">
                <Spinner />
              </div>
            ) : null}
          </div>
          {result ? (
            mode === "Meesho" ? (
              <div className="reverse-scan-result-container">
                <p>{result.message}</p>
              </div>
            ) : (
              <div className="reverse-scan-result-container">
                <p>{result.msg1}</p>
                <p>{result.msg2}</p>
                {result.msg3
                  .split("<br/>")
                  .map((awbNotFound) => awbNotFound.replace(",", ""))
                  .map((finalAwbNotFound) => (
                    <p>{finalAwbNotFound}</p>
                  ))}
              </div>
            )
          ) : null}
        </div>

        {scannedBarcodes.length > 0 ? (
          <div className="reverse-scanning-tags-container">
            {scannedBarcodes.map((barcode) => (
              <div className="reverse-scanning-tag">
                {barcode}
                <span
                  className="scanning-tag-close"
                  onClick={() => removeBarcode(barcode)}
                >
                  <b>X</b>
                </span>
              </div>
            ))}
          </div>
        ) : null}
        <audio src="../res/sounds/tink.wav" id="scanned_sound"></audio>
      </div>
    </>
  );
};

export default ReverseScan;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import DamagedOrder from "../res/DamagedOrder";

const UploadImages = () => {
  const [orders, setOrders] = useState([]);
  document.title = "Upload Damaged Product Images";
  const fetchOrders = async () => {
    let result = await Axios.get("/api/warehouse/retrieveAllDamagedOrders");
    let damagedOrders = [];
    console.log("check this = ", result.data.msg);

    for (let i = 0; i < result.data.msg.length; i++) {
      if (
        result.data.msg[i].damagedOrders &&
        result.data.msg[i].damagedOrders.length > 0
      ) {
        damagedOrders.push(...result.data.msg[i].damagedOrders);
      }
    }
    console.log("this = ", damagedOrders);
    setOrders(damagedOrders);
  };

  useEffect(() => {
    fetchOrders();
  }, []);
  return (
    <div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">
              <center>Order Number</center>
            </th>
            <th scope="col">
              <center>AWB</center>
            </th>
            <th scope="col">
              <center>Product Damaged</center>
            </th>
          </tr>
        </thead>
        <tbody>
          {orders
            ? orders.map(order => (
                <DamagedOrder
                  store={order.store}
                  order={order}
                  fetchOrders={fetchOrders}
                />
              ))
            : ""}
        </tbody>
      </table>
    </div>
  );
};

export default UploadImages;

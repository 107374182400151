import * as React from "react";
import Container from "@mui/material/Container";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Dimension from "../res/dimensions/Dimension";
import Search from "../res/dimensions/SearchDimension";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Dimensions = ({ user }) => {
  const [page, setPage] = React.useState(1);
  const [totalPageCount, setTotalPageCount] = React.useState(0);
  const [dimensions, setDimensions] = React.useState([]);
  const searchInput = React.useRef(null);
  const limit = 8;

  const fetchDimensions = async (page = 1, limit = 8, dimensionName = "") => {
    try {
      const dimensionsApi = `/api/dimensions`;
      const config = {
        params: {
          page,
          limit,
          dimensionName,
        },
      };
      const {
        data: { result, count },
      } = await axios.get(dimensionsApi, config);
      setDimensions([...result]);
      setTotalPageCount(
        count % limit === 0
          ? parseInt(count / limit)
          : parseInt(count / limit) + 1
      );
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchPageCount = async () => {
    try {
      const fetchPageCountUrl = "/api/dimensions/count";
      const {
        data: { result: count },
      } = await axios.get(fetchPageCountUrl);
      setTotalPageCount(
        count % limit === 0
          ? parseInt(count / limit)
          : parseInt(count / limit) + 1
      );
    } catch (err) {
      console.error(err.message);
    }
  };

  const onSearch = async () => {
    try {
      fetchDimensions(1, limit, searchInput.current.value);
    } catch (err) {
      console.error(err.message);
    }
  };

  const reset = () => {
    searchInput.current.value = "";
    fetchDimensions();
    setPage(1);
  };

  const download = async () => {
    const dimensionsCsvUrl = "/api/dimensions/download";
    const result = await axios.get(dimensionsCsvUrl);
    console.log(result);
    let blob = new Blob([result.data.dimensionsCSV], { type: "text/csv" });
    let a = document.createElement("a");
    a.download = `Dimensions${new Date().toLocaleDateString()}.csv`;
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download Dimensions CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );
    document.body.appendChild(a);
    a.click();
  };

  const handlePage = async (e, value) => {
    value <= totalPageCount &&
      fetchDimensions(value, limit, searchInput.current.value);
    setPage(value);
  };

  React.useEffect(() => {
    fetchDimensions();
    fetchPageCount();
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Search
          onSearch={onSearch}
          reset={reset}
          download={download}
          searchRef={searchInput}
        />
        {totalPageCount > 0 && (
          <Stack justifyContent={"center"} alignItems={"center"} mt={3}>
            <Pagination
              page={page}
              count={totalPageCount}
              color="primary"
              onChange={handlePage}
            />
          </Stack>
        )}
        <Grid container rowSpacing={4} columnSpacing={4} mt={1} mb={5}>
          {dimensions.length > 0 ? (
            dimensions.map((dimension) => (
              <Grid item xs={6} md={3}>
                <Dimension
                  dimension={dimension}
                  user={user.name}
                  onSearch={onSearch}
                />
              </Grid>
            ))
          ) : (
            <Typography variant="h3">No Dimensions</Typography>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Dimensions;

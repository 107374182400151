import React, { useContext } from "react";
import { UserConsumer } from "../context/user-context";

const ProductForm = ({ id, cycleSuggestion }) => {
  const context = useContext(UserConsumer);

  const calcTotal = () => {
    let quantity = parseFloat(document.getElementById(`quantity_${id}`).value);
    let price = parseFloat(document.getElementById(`price_${id}`).value);
    let gst = parseFloat(document.getElementById(`gst_${id}`).value);

    let totalAmount = quantity * price;
    let tax = (gst / 100) * totalAmount;
    totalAmount = totalAmount + tax;

    document.getElementById(`total_${id}`).value = isNaN(totalAmount)
      ? 0
      : totalAmount;
  };

  return (
    <div className="grid-container">
      <div className="grid-item">
        <div className="form-group">
          <label htmlFor="Product">Product</label>
          <input
            type="text"
            list="productList"
            className="form-control"
            autoComplete="off"
            id={`product_${id}`}
            placeholder="Product"
            onFocus={() => context.fetchOrders()}
            onChange={e => context.filterOrders("products", e.target.value)}
            onKeyDown={e => {
              cycleSuggestion(`product_${id}`, e, context);
            }}
          />

          <datalist id="productList">
            {context.products.map((product, i) => (
              <option value={product.name} key={i} />
            ))}
          </datalist>
        </div>
      </div>
      <div className="grid-item">
        <div className="form-group">
          <label htmlFor="Quantity">Quantity</label>
          <input
            type="text"
            className="form-control"
            id={`quantity_${id}`}
            placeholder="Quantity"
            onChange={() => calcTotal()}
          />
        </div>
      </div>
      <div className="grid-item">
        <div className="form-group">
          <label htmlFor="Price">Price Per Piece</label>
          <input
            type="text"
            className="form-control"
            id={`price_${id}`}
            placeholder="price"
            onChange={() => calcTotal()}
          />
        </div>
      </div>
      <div className="grid-item">
        <div className="form-group">
          <label htmlFor="GST">GST</label>
          <input
            type="text"
            className="form-control"
            id={`gst_${id}`}
            placeholder="GST"
            onChange={() => calcTotal()}
          />
        </div>
      </div>
      <div className="grid-item">
        <div className="form-group">
          <label htmlFor="Total">Total</label>
          <input
            type="text"
            className="form-control"
            id={`total_${id}`}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default ProductForm;

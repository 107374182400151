export const copy = e => {
  /* Get the text field */
  var copyText = document.getElementById(`tool_${e.target.id}`).innerHTML;

  const el = document.createElement("textarea");
  document.body.appendChild(el);

  el.setAttribute("id", "copytext");
  document.getElementById(`copytext`).value = copyText;
  /* Select the text field */
  document.getElementById(`copytext`).select();
  document
    .getElementById(`copytext`)
    .setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");

  /* Alert the copied text */
  // alert("Copied the text: " + el.value);
  snackbar();
  el.setAttribute("style", "visibility: hidden");
  document.getElementById("copytext").remove();
};

function snackbar() {
  // Get the snackbar DIV
  var x = document.getElementById("snackbar");

  // Add the "show" class to DIV
  x.className = "show";

  // After 3 seconds, remove the show class from DIV
  setTimeout(function() {
    x.className = x.className.replace("show", "");
  }, 3000);
}

// PLACE SNACKBAR
{
  /* <div id="snackbar">Copied To Clipboard</div> */
}

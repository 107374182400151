import React, { useState, useEffect, Fragment } from "react";
import Product from "./Product";
import Axios from "axios";

export default function ProductDimensions({ product, fetchProducts, user }) {
  const [currentVariant, setCurrentVariant] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [volumetricWeight, setVolumetricWeight] = useState("");
  const [productWeight, setProductWeight] = useState("");
  const [lengthF, setLengthF] = useState("");
  const [widthF, setWidthF] = useState("");
  const [heightF, setHeightF] = useState("");
  const [actualWeightF, setActualWeightF] = useState("");
  const [volumetricWeightF, setVolumetricWeightF] = useState("");
  const [productWeightF, setProductWeightF] = useState("");
  const [selectVariants, setSelectVariants] = useState(false);
  const [missingDimensions, setMissingDimensions] = useState(false);
  const [hoverData, setHoverData] = useState(null);

  const submitDimensions = async (type) => {
    let finalLength, finalWidth, finalHeight, finalActualWeight, name;
    if (type === "fake") {
      finalLength = lengthF;
      finalWidth = widthF;
      finalHeight = heightF;
      finalActualWeight = actualWeightF;
      name = "fakeDimensions";
    } else {
      finalLength = length;
      finalWidth = width;
      finalHeight = height;
      finalActualWeight = actualWeight;
      name = "originalDimensions";
    }

    if (!finalLength || !finalWidth || !finalHeight || !finalActualWeight)
      return alert("Please input all details");

    user = {
      user_id: user.user_id,
      name: user.name,
    };

    let result = await Axios.post(
      `/api/operations/product/${currentVariant.variant_id}`,
      {
        store: product.store,
        length: finalLength,
        width: finalWidth,
        height: finalHeight,
        actualWeight: finalActualWeight,
        name,
        product_id: product.product_id,
        selectVariants,
        user,
      }
    );
    alert(`Updated Successfully!`);
    setSelectVariants(false);
    document
      .getElementById("ops-variant-card")
      .setAttribute("style", "pointer-events:auto");
    fetchProducts();
  };

  const checkDimensions = (variant) => {
    if (!variant.actualWeight || !variant.fakeActualWeight) {
      if (!missingDimensions) setMissingDimensions(true);
      return true;
    }
  };

  const displayHoverDescription = (variant) => {
    const hoverDiv = document.querySelector(".tooltip-nav-text-ops-dashboard");
    if (variant.updateHistory || variant.createdOn || variant.updatedOn) {
      if (hoverDiv) {
        hoverDiv.style.visibility = "visible";
      }
    }
  };

  const hideHoverDescription = () => {
    const hoverDiv = document.querySelector(".tooltip-nav-text-ops-dashboard");
    if (hoverDiv) {
      hoverDiv.style.visibility = "hidden";
      setHoverData(null);
    }
  };

  useEffect(() => {
    let volumetricWeight = parseFloat((length * width * height) / 5000);
    setVolumetricWeight(volumetricWeight || "");

    if (volumetricWeight > actualWeight) setProductWeight(volumetricWeight);
    else setProductWeight(actualWeight);
  }, [length, width, height, actualWeight]);

  useEffect(() => {
    let volumetricWeightF = parseFloat((lengthF * widthF * heightF) / 5000);
    setVolumetricWeightF(volumetricWeightF || "");

    if (volumetricWeightF > actualWeightF) setProductWeight(volumetricWeightF);
    else setProductWeightF(actualWeightF);
  }, [lengthF, widthF, heightF, actualWeightF]);

  return (
    <div className="ops-productDimensions">
      {hoverData ? (
        <div className={`tooltip-nav-text-ops-dashboard`}>
          {hoverData.createdOn ? (
            <div>{`Created Date: ${new Date(
              hoverData.createdOn
            ).toLocaleString()}`}</div>
          ) : null}
          {hoverData.updatedOn ? (
            <div>{`Updated Date: ${new Date(
              hoverData.updatedOn
            ).toLocaleString()}`}</div>
          ) : null}
          {hoverData.updateHistory ? (
            <div>{`Dimensions updated by ${
              hoverData.updateHistory.user.name
            } on ${new Date(
              hoverData.updateHistory.date
            ).toLocaleString()}`}</div>
          ) : null}
        </div>
      ) : null}
      <div className="ops-productDimensions-head">
        <div style={{ background: "#2c3e50" }}>{product.store.storeName}</div>{" "}
        <div onClick={() => setCurrentVariant("")}>
          {product.title + ` ( ${product.product_id} ) `}
          <sub>
            {missingDimensions ? `Some dimensions are not entered` : ""}
          </sub>
        </div>
        <div
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setSelectVariants(!selectVariants);
          }}
        >
          {!selectVariants ? (
            <Fragment>
              Select all variants <i className="fas fa-check-square"></i>
            </Fragment>
          ) : (
            <Fragment>
              Deselect variants <i className="fas fa-times"></i>
            </Fragment>
          )}
        </div>
      </div>
      <div className="ops-productDimensions-variants">
        {product.variants.map((variant) => (
          <div
            id="ops-variant-card"
            style={selectVariants ? { pointerEvents: "none" } : {}}
            onClick={() => {
              setLength(variant.length || "");
              setWidth(variant.width || "");
              setHeight(variant.height || "");
              setActualWeight(variant.actualWeight || "");
              setLengthF(variant.fakeLength || "");
              setWidthF(variant.fakeWidth || "");
              setHeightF(variant.fakeHeight || "");
              setActualWeightF(variant.fakeActualWeight || "");
              setCurrentVariant(variant);
            }}
            onMouseEnter={() => {
              setHoverData(variant);
            }}
            onMouseOver={() => displayHoverDescription(variant)}
            onMouseLeave={() => hideHoverDescription()}
          >
            <p
              className={`ops-productDimensions-variant-block ${
                currentVariant.title === variant.title || selectVariants
                  ? "ops-productDimensions-variant-block-active"
                  : ""
              }`}
            >
              {variant.title}
              {variant.deleted ? (
                <div className="variant-tag">{"Old"}</div>
              ) : null}
              {variant.mapped ? null : (
                <div className="variant-tag">{"Not Mapped"}</div>
              )}
              {checkDimensions(variant) ? (
                <div className="variant-tag">{"No Dimensions"}</div>
              ) : null}
            </p>
          </div>
        ))}
      </div>
      <div style={{ padding: "10px", height: "40px", textAlign: "center" }}>
        {currentVariant
          ? currentVariant.title + " | Variant ID: " + currentVariant.variant_id
          : ""}
      </div>
      {currentVariant ? (
        <div className="ops-productDimensions-body">
          <div style={{ flexGrow: "1" }}>
            <div className="ops-productDimensions-input-div">
              <input
                type="text"
                id="trueLength"
                value={length}
                placeholder="True Length"
                onChange={(e) => setLength(e.target.value)}
              />
              <input
                type="text"
                id="trueWidth"
                value={width}
                placeholder="True Width"
                onChange={(e) => setWidth(e.target.value)}
              />
              <input
                type="text"
                id="trueHeight"
                value={height}
                placeholder="True Height"
                onChange={(e) => setHeight(e.target.value)}
              />
              <input
                type="text"
                id="trueActualWeight"
                value={actualWeight}
                placeholder="True Actual Weight"
                onChange={(e) => setActualWeight(e.target.value)}
              />
              <input
                type="text"
                placeholder="True Volumetric Weight"
                value={volumetricWeight}
                disabled
              />
              <input
                type="text"
                value={productWeight}
                placeholder="True Product Weight"
                disabled
              />
            </div>
            <button
              className="ops-productDimensions-update-button"
              onClick={() => submitDimensions("true")}
            >
              Update
            </button>
          </div>
          {!user.customer ? (
            <div style={{ flexGrow: "1" }}>
              <div className="ops-productDimensions-input-div">
                <input
                  type="text"
                  id="fakeLength"
                  value={lengthF}
                  onChange={(e) => setLengthF(e.target.value)}
                  placeholder="Fake Length"
                />
                <input
                  type="text"
                  id="fakeWidth"
                  value={widthF}
                  onChange={(e) => setWidthF(e.target.value)}
                  placeholder="Fake Width"
                />
                <input
                  type="text"
                  id="fakeHeight"
                  value={heightF}
                  onChange={(e) => setHeightF(e.target.value)}
                  placeholder="Fake Height"
                />
                <input
                  type="text"
                  id="fakeActualWeight"
                  value={actualWeightF}
                  onChange={(e) => setActualWeightF(e.target.value)}
                  placeholder="Fake Actual Weight"
                />
                <input
                  type="text"
                  value={volumetricWeightF}
                  placeholder="Fake Volumetric Weight"
                  disabled
                />
                <input
                  type="text"
                  value={productWeightF}
                  placeholder="Fake Product Weight"
                  disabled
                />
              </div>
              <button
                className="ops-productDimensions-update-button"
                onClick={() => submitDimensions("fake")}
              >
                Update
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

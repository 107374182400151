import React, { useState } from "react";
import axios from "axios";
import Timeline from "../res/Timeline";

const AwbHistory = () => {
  document.title = "AWB History";
  const [input, setInput] = useState(null);
  const [history, setHistory] = useState(null);

  const handleSubmit = async () => {
    if (!input) {
      alert("PLease enter an AWB to search!");
      return;
    }

    const { data: historyObj } = await axios.get(
      `/api/warehouse/awbHistory?awb=${input}`
    );
    console.log(historyObj);
    setHistory(historyObj.result);
  };

  return (
    <div className="awb-history-container">
      <h3>AWB History</h3>
      <div className="awb-history-search">
        <input
          type="text"
          className="form-control"
          placeholder="Enter AWB here"
          onChange={(e) => setInput(e.target.value)}
        ></input>
        <div>
          <button onClick={handleSubmit}>Search</button>
        </div>
      </div>
      <div className="awb-history-display">
        {history ? (
          <div>
            <Timeline content={"Scheduled"} />
            {Object.keys(history).map((index) => {
              let content = history[index];
              let hideArrow = false;
              if (index == Object.keys(history).length - 1) {
                hideArrow = true;
                content += " (current)";
              } else {
                content += " (old)";
              }

              return <Timeline content={content} hideArrow={hideArrow} />;
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AwbHistory;

import axios from "axios";
export const createFunnel = async getStoresList => {
  let name = document.getElementById("store_name").value;
  let product = document.getElementById("product_name").value;
  let quantity = document.getElementById("product_quantity").value;
  let price = document.getElementById("product_price").value;

  let length = document.getElementById("length").value;
  let width = document.getElementById("width").value;
  let height = document.getElementById("height").value;
  let weight = document.getElementById("weight").value;

  let storeName = document.getElementById("store_name").value;
  let storePrefix = document.getElementById("store_prefix").value;
  let mongoSRV = document.getElementById("store_SRV").value;
  let storeEmail = document.getElementById("store_email").value;
  let channelID = document.getElementById("store_channel_id").value;
  let shopifyName = document.getElementById("store_name").value;
  let shiprocketAPIUsername = document.getElementById("shiprocket_username")
    .value;
  let shiprocketAPIPassword = document.getElementById("shiprocket_password")
    .value;
  let addressThreshold = document.getElementById("store_threshold").value;
  let user = document.getElementById("customers").value;
  let funnel = {
    name,
    product,
    quantity,
    price
  };

  let productDimensions = {
    length,
    width,
    height,
    weight
  };

  let store = {
    storeName,
    mongoSRV,
    storeEmail,
    storePrefix,
    channelID,
    shopifyName,
    shiprocketAPIUsername,
    shiprocketAPIPassword,
    addressThreshold,
    user,
    click: true
  };
  if (
    name !== "" &&
    product !== "" &&
    quantity !== "" &&
    price !== "" &&
    length !== "" &&
    width !== "" &&
    height !== "" &&
    weight !== "" &&
    storeName !== "" &&
    storePrefix !== "" &&
    mongoSRV !== "" &&
    channelID !== "" &&
    storeEmail !== "" &&
    shopifyName !== "" &&
    shiprocketAPIUsername !== "" &&
    shiprocketAPIPassword !== "" &&
    addressThreshold !== "" &&
    user !== ""
  ) {
    let storeResult = await axios.post("/api/stores/", store);
    if (storeResult) {
      let res = await axios.post("/api/clickfunnels", {
        funnel,
        productDimensions
      });
      await getStoresList();
      alert(storeResult.data.msg);
    }
  }
};

import React, { useState, useEffect, Fragment } from "react";
import StoreButton from "../res/StoreButton";
import Axios from "axios";
import AggregatorButton from "../res/AggregatorButton";
import oldOrderCheck from "../res/oldOrderCheck";

export default function Operation({ operation, bulk, user }) {
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState("");
  const [orders, setOrders] = useState("");

  const fetchStores = async () => {
    let result = await Axios.get("/api/stores?ignoreDeleted=true");

    // SHINE X PRO STORE ONLY
    result.data = result.data.filter(
      (store) => store.shopifyName === "shinexpro"
    );

    setStores(result.data);
  };

  const addOrders = (input, bulk) => {
    if (!bulk) setOrders(input);
    else {
      let temp = input.split("\n");
      setOrders(temp);
    }
  };

  const performOperation = async (aggregator) => {
    let API = "";
    console.log(
      `Request for ${
        bulk ? "bulk" : ""
      } ${operation} ${orders} through ${aggregator}`
    );

    const input =
      (aggregator.includes("GoSwift") &&
        prompt(
          "ARE YOU SURE YOU WANT TO SCHEDULE THIS/THESE ORDER/ORDERS THROUGH GOSWIFT (yes/no)"
        )) ||
      "no";

    if (aggregator.includes("GoSwift") && input.toLocaleLowerCase() !== "yes")
      return alert("Not scheduling");

    if (bulk) {
      user = {
        user_id: user.user_id,
        name: user.name,
      };
      let payload;
      if (operation === "Cancel") {
        const cancelReason = prompt("Reason for cancellation?");
        if (cancelReason === "") {
          alert("Please enter a reason for cancellation!");
          return;
        }
        payload = {
          orders,
          user,
          store,
          aggregator,
          cancelReason,
        };
      } else {
        payload = {
          orders,
          user,
          store,
          aggregator,
        };
      }

      // ALL BULK ENDPOINTS
      if (operation.includes("Cancel")) {
        API = "/api/operations/cancelBulkOrders";
      } else if (operation.includes("Schedule")) {
        API = "/api/operations/scheduleBulkOrders";
      } else return alert("Something Went Wrong While Performing Operation");

      const result = await Axios.post(API, payload);
      if (result.data.status === false) {
        alert(result.data.message);
      } else {
        if (operation.includes("Cancel")) {
          alert(result.data.message);
        } else {
          alert(
            `Scheduled orders - ${
              result.data.scheduled.length == 0
                ? result.data.scheduled.length
                : result.data.scheduled
            }\nFailed to schedule orders - ${
              result.data.notScheduled.length == 0
                ? result.data.notScheduled.length
                : result.data.notScheduled
            }`
          );
        }
      }
    } else {
      const cancelPayload = {
        orderName: orders,
        store,
        user,
      };

      let result = "";

      // ALL SINGLE ENDPOINTS
      if (aggregator.includes("ShipRocket")) {
        // SHIPROCKET
        if (operation.includes("Cancel")) {
          API = "/api/operations/cancelAnOrder";
        } else if (operation.includes("Schedule")) {
          API = "/api/orders/createShipmentForAnOrder";
        }
      } else if (aggregator.includes("ClickPost")) {
        // CLICKPOST
        if (operation.includes("Cancel")) {
          API = "/api/clickPost/operations/cancelAnorder";
        } else if (operation.includes("Schedule")) {
          API = "/api/clickPost/operations/scheduleAnOrderManually";
        }
      } else if (aggregator.includes("GoSwift")) {
        // GOSWIFT
        if (operation.includes("Cancel")) {
          API = "/api/goswift/operations/cancelAnorder";
        } else if (operation.includes("Schedule")) {
          API = "/api/goswift/operations/scheduleAnOrderManually";
        }
      } else return alert("Something Went Wrong While Performing Operation");

      if (operation.includes("Schedule"))
        result = await Axios.get(
          `${API}?orderName=${encodeURIComponent(
            orders
          )}&store=${JSON.stringify(store)}&user=${JSON.stringify({
            user_id: user.user_id,
            name: user.name,
          })}&aggregator=${aggregator}`
        );
      else result = await Axios.post(API, cancelPayload);
      if (result) alert(result.data.status);
    }
  };

  const cancelOrders = async () => {
    const cancelReason = prompt("Reason for cancellation?");
    if (!cancelReason) {
      alert("Please enter a reason for cancellation!");
      return;
    }
    const cancelPayload = {
      orders,
      store,
      user: {
        user_id: user.user_id,
        email: user.email,
        name: user.name,
      },
      cancelReason,
    };
    const result = await Axios.post(
      "/api/operations/cancelOrder_V2",
      cancelPayload
    );
    alert(result.data.status);
  };

  const scheduleOrdersViaDecisionEngine = async () => {
    let finalOrders = orders;

    // check if old orders are there
    const oldOrderData = await oldOrderCheck(orders, store);
    console.log(oldOrderData);
    if (oldOrderData.length > 0) {
      console.log(oldOrderData);
      const answer = prompt(
        `Some orders are older than one month - ${oldOrderData}.\n Enter "all" to proceed with all orders.\n Enter "skip" to proceed with all orders except the old ones above.`
      );
      if (!answer) {
        alert("Could not schedule orders!");
        return;
      } else if (answer.toLowerCase() === "skip") {
        finalOrders = orders.filter((order) => !oldOrderData.includes(order));
      } else if (answer.toLowerCase() === "all") {
        finalOrders = orders;
      } else {
        alert("Wrong option. Could not schedule orders. Try again");
        return;
      }
    }

    const payload = {
      orders: finalOrders,
      user: {
        user_id: user.user_id,
        email: user.email,
        name: user.name,
      },
      store,
      escalateToDecisionEngine: true,
    };

    const { data } = await Axios.post(
      "/api/operations/scheduleBulkOrders",
      payload
    );

    if (data.status) {
      alert(
        `SCHEDULED: ${data.scheduled}\n\n NOT SCHEDULED: ${data.notScheduled}`
      );
    } else {
      alert(data.message);
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  return (
    <div className="operations-op">
      <div className="operations-op-nav">
        <StoreButton stores={stores} store={store} setStore={setStore} />
      </div>
      <div style={{ padding: "30px 20px" }}>
        {store ? (
          <div>
            <h3 style={{ marginBottom: "20px" }}>
              {operation === "Schedule"
                ? `${bulk ? "Bulk" : ""} Schedule Order${bulk ? "s" : ""}`
                : `${bulk ? "Bulk" : ""} Cancel Order${bulk ? "s" : ""}`}
            </h3>
            <div className="operations-op-card">
              {!bulk ? (
                <input
                  placeholder="Enter the order.."
                  onChange={(e) => addOrders(e.target.value)}
                />
              ) : (
                <textarea
                  placeholder="Enter the orders.."
                  onChange={(e) => addOrders(e.target.value, true)}
                />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {orders ? (
          operation == "Cancel" ? (
            <button
              className="btn btn-secondary operations-store-button"
              style={{ marginTop: "20px" }}
              onClick={cancelOrders}
            >
              Cancel Orders
            </button>
          ) : (
            <div style={{ marginTop: "20px" }}>
              {/* <AggregatorButton
                scheduleOrders={performOperation}
                operation={operation}
              /> */}
              <button
                className="btn btn-secondary operations-store-button"
                style={{ marginTop: "20px" }}
                onClick={scheduleOrdersViaDecisionEngine}
              >
                Schedule
              </button>
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

import axios from "axios";
import Cryptr from "cryptr";
import React, { useState, useEffect } from "react";
import StoreDropdownButton from "../../operations/res/StoreDropdownButton";
import ReusableFormRow from "../res/ReusableFormRow";
import "../res/StoreExpenses.css";

const fields = [
  {
    label: "Google Ads expenditure",
    required: true,
    name: "googleAdsExpenditure",
    placeHolder: "Google Ads Expenditure",
  },
  {
    label: "Facebook Ads Expenditure",
    required: true,
    name: "facebookAdsExpenditure",
    placeHolder: "Facebook Ads Expenditure",
  },
];

function StoreAdSpends({ setPage, user }) {
  const [store, setStore] = useState(null);
  const [formState, setFormState] = useState({
    googleAdsExpenditure: "",
    facebookAdsExpenditure: "",
  });
  const [saved, setSaved] = useState({});
  const [errors, setErrors] = useState({});

  // submit handler
  const handleSubmit = async () => {
    console.log(formState);
    let validForm = true;
    for (const error in errors) {
      if (errors[error].length > 0) {
        validForm = false;
        break;
      }
    }
    if (!validForm) {
      alert(`Not a valid form! Please check again and submit!`);
      return;
    }
    if (!store) {
      alert(`Please select a store!`);
      return;
    }
    console.log(`Final form state`, formState);
    // else sent request to backend
    const expenses = [];
    for (const expense in formState) {
      if (!formState[expense]) continue;
      expenses.push({
        type: expense,
        amount: formState[expense],
        expense: true, // shows that this is an expense and not a profit
      });
    }
    if (expenses.length === 0) {
      alert(`Please input expenditures to save!`);
      return;
    }
    const payload = {
      user: user.name,
      store,
      expenses,
    };
    console.log(payload);
    let result;
    try {
      result = await axios.post("/api/stores/expenditure", payload);
    } catch (error) {
      alert(`Something went wrong! Contact developers!`);
      return;
    }
    console.log(result.data);
    setSaved({
      ...saved,
      [store.storeName]: expenses,
    });
    handleReset();
    // setFormState({});
  };

  const handleReset = () => {
    setFormState({
      googleAdsExpenditure: "",
      facebookAdsExpenditure: "",
    });
    setStore(null);
    setErrors({});
  };

  // created my custom handle change function and pass into reusable form field
  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    if (!isFloatingPointNumber(e.target.value)) {
      setErrors({ ...errors, [e.target.name]: `Enter a valid number!` });
      return;
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const isFloatingPointNumber = (value) => {
    const floatingPointNumberRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;
    return floatingPointNumberRegex.test(value);
  };

  const camelToNormalSentence = (sentence) => {
    const result = sentence.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  return (
    <div id="store-expenses-ad-container">
      <div id="store-expenses-form">
        <div id="store-expenses-store-selector">
          <StoreDropdownButton
            setStore={setStore}
            store={store}
            showDeleted={false}
          />
        </div>
        {fields.map((field) => (
          <ReusableFormRow
            fieldValues={field}
            handleChange={handleChange}
            errors={errors}
            value={formState[field.name]}
          />
        ))}
        <div id="store-expenses-submit-container">
          <button className="store-expenses-button" onClick={handleSubmit}>
            Submit
          </button>
          <button
            className="store-expenses-button"
            onClick={() => setPage(null)}
          >
            Back
          </button>
        </div>
      </div>
      <div id="store-expenses-ad-saved-expenditure">
        <div>
          <b>{`Ad expenditures saved today`}</b>
        </div>
        <div>
          {Object.keys(saved).length > 0 ? (
            <>
              {Object.keys(saved).map((savedStore) => {
                return (
                  <div className="store-expenses-ad-saved-expenditure-store">
                    <div>
                      <b>{savedStore}</b>
                    </div>
                    <div>
                      {saved[savedStore].map((adExpense) => (
                        <div className="store-expenses-ad-saved-expenditure-item">
                          <div className="store-expenses-ad-saved-expenditure-type">
                            {camelToNormalSentence(adExpense.type)}
                          </div>
                          <div className="store-expenses-ad-saved-expenditure-amount">
                            {adExpense.amount}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
              <div className="store-expenses-ad-saved-expenditure-total">{`TOTAL \u20B9${Object.keys(
                saved
              ).reduce((total, savedStore) => {
                saved[savedStore].map((adExpense) => {
                  total += parseFloat(adExpense.amount);
                  return null;
                });
                return total;
              }, 0)}`}</div>
            </>
          ) : (
            <div>{`You have not saved any ads expenditure today!`}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StoreAdSpends;

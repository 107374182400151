import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { GlobalConsumer } from "../../Global-Context/global-context";
import Axios from "axios";
import InventoryItem from "../res/InventoryItem_Board";
import DisputeItem from "../res/DisputeItem";
const InventoryBoard_v2 = () => {
  document.title = "Inventory Board";
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);
  const [inventory, setInventory] = useState([]);
  const [showInventory, setShowInventory] = useState([]);
  const [disputes, setDisputes] = useState([]);
  const [showDateForReport, setShowDateForReport] = useState(false);
  const [active, setActive] = useState("inventory");
  let stock_a = 0;
  let stock_b = 0;

  const dateRef = useRef(null);

  const sortfunction = (a, b) => {
    let batches_a = Object.keys(a).filter((key) => {
      if (key.startsWith("batch_")) return true;
    });

    batches_a.forEach((batch) => (stock_a += a[batch].quantity));

    let batches_b = Object.keys(b).filter((key) => {
      if (key.startsWith("batch_")) return true;
    });

    batches_b.forEach((batch) => (stock_b += b[batch].quantity));

    if (stock_a > stock_b) {
      stock_a = 0;
      stock_b = 0;
      return 1;
    }
    if (stock_b > stock_a) {
      stock_a = 0;
      stock_b = 0;
      return -1;
    }
    stock_a = 0;
    stock_b = 0;
    return 0;
  };

  const fetchInventory = async () => {
    let res = await Axios.post(`/api/IMS/fetchInventory`, { user });
    let result = await Axios.get(`/api/IMS/fetchDisputes`);
    console.log("DISPUTES", result.data.orders);
    setDisputes(result.data.orders);
    let inventory = res.data.inventory
      ? res.data.inventory.sort((a, b) => sortfunction(a, b))
      : [];
    setInventory(inventory);
    setShowInventory(inventory);
  };

  const handleOnHoldItems = async () => {
    let quantity = parseInt(document.getElementById("onHold_quantity").value);
    let id = document.getElementById("onHold_input").value;
    await Axios.post(`/api/IMS/handleOnHoldItems`, { quantity, id });

    fetchInventory();
  };

  const handleSearch = () => {
    const input = document.getElementById("searchInput").value;
    console.log(input);
    let list = inventory.filter((inventory) => {
      if (inventory.name.toLowerCase().includes(input.toLowerCase()))
        return true;
      const productFiltering = inventory.products.find((product) =>
        product.variant_id.toString().includes(input)
      );
      if (productFiltering) return true;
      return false;
    });
    setShowInventory(list);
  };

  const handleReset = () => {
    document.getElementById("searchInput").value = "";
    setShowInventory(inventory);
  };

  const downloadInventoryReport = async () => {
    const date = dateRef.current.value;
    if (!date) return alert("Please try again");
    const result = await Axios.post(`/api/IMS/inventoryReportGenerator`, {
      date,
    });
    if (result.data.csv) {
      let blob = new Blob([result.data.csv], { type: "text/csv" });
      var a = document.createElement("a");
      a.download = `Inventory Report (${new Date(
        date
      ).toLocaleDateString()}).csv`;
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("style", "display:none;");

      a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
        ":"
      );

      document.body.appendChild(a);
      a.click();
      return;
    } else if (result.data.msg) {
      alert(result.data.msg);
    } else {
      alert("Something went wrong!");
    }
  };

  useEffect(() => {
    fetchInventory();
  }, []);
  return (
    <Fragment>
      <div>
        <center>
          <div
            className="inventoryReport"
            onClick={() => setShowDateForReport(!showDateForReport)}
          >
            {showDateForReport ? "Cancel" : "Download Inventory Report"}
          </div>
          {showDateForReport ? (
            <div>
              <input
                type="date"
                id="birthday"
                name="birthday"
                ref={dateRef}
                className="inventoryDate"
                style={{ marginRight: "none" }}
              />
              <button
                style={{
                  padding: "10px",
                  background: "#c0392b",
                  color: "white",
                }}
                onClick={() => downloadInventoryReport()}
              >
                Download
              </button>
            </div>
          ) : (
            ""
          )}
        </center>
      </div>
      <div className="inventoryToggle">
        <div
          className={`inventory-toggle-item ${
            active === "inventory" ? "inventory-active" : ""
          }`}
          onClick={() => {
            fetchInventory();
            setActive("inventory");
          }}
        >
          Inventory
        </div>

        {user.customer ? (
          ""
        ) : (
          <Fragment>
            <div
              className={`inventory-toggle-item ${
                active === "on hold" ? "inventory-active" : ""
              }`}
              onClick={() => {
                fetchInventory();
                setActive("on hold");
              }}
            >
              On Hold
            </div>{" "}
            <div
              className={`inventory-toggle-item ${
                active === "disputes" ? "inventory-active" : ""
              }`}
              onClick={() => {
                fetchInventory();
                setActive("disputes");
              }}
            >
              Disputes
            </div>
          </Fragment>
        )}
      </div>

      {active === "inventory" ? (
        <Fragment>
          <div
            className="configurationsCard IMS-order-card"
            style={{
              boxShadow: "none",
              margin: "5vh 10vw",
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <input
              type="text"
              placeholder="Search by Inventory Name/Variant ID"
              aria-label="Search"
              id="searchInput"
              onKeyDown={(e) => {
                if (e.keyCode === 13) handleSearch();
              }}
              style={{
                height: "60px",
                width: "100%",
                border: "none",
                padding: "10px",
                outline: "none",
                boxShadow:
                  "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
              }}
            />
            <button
              className="btn btn-danger"
              style={{ width: "10%", borderRadius: "0px" }}
              onClick={handleSearch}
            >
              Submit
            </button>
            <button
              className="btn btn-danger"
              style={{ width: "10%", borderRadius: "0px" }}
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </Fragment>
      ) : (
        ""
      )}
      {active === "inventory" ? (
        showInventory && showInventory.length > 0 ? (
          <div style={{ padding: "0px 5vw" }}>
            {showInventory.map((item, i) => (
              <InventoryItem
                item={item}
                key={i}
                fetchInventory={fetchInventory}
                user={user}
                inventory={inventory}
              />
            ))}
          </div>
        ) : (
          ""
        )
      ) : active === "disputes" ? (
        <div className="card configurationsCard IMS-order-card">
          <div style={{ padding: "20px" }}>
            {disputes.map((dispute) => (
              <DisputeItem
                dispute={dispute}
                inventory={inventory}
                fetchInventory={fetchInventory}
                key={dispute.name}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="card configurationsCard IMS-order-card">
          <div style={{ padding: "20px", display: "flex" }}>
            <input
              type="text"
              list="comboList"
              className="form-control"
              style={{ width: "70%", borderRadius: "0px", height: "60px" }}
              id={`onHold_input`}
              placeholder={`Product Name`}
              autoComplete="off"
            />
            <input
              style={{ width: "15%", borderRadius: "0px", height: "60px" }}
              type="number"
              className="form-control"
              id={`onHold_quantity`}
              placeholder={"Quantity"}
              autoComplete="off"
            />
            <button
              style={{ width: "15%", borderRadius: "0px", height: "60px" }}
              type="button"
              className="btn btn-danger"
              onClick={() => handleOnHoldItems()}
            >
              Submit
            </button>
            <datalist id="comboList">
              {inventory.map((product, i) => (
                <option value={product.id} key={i}>
                  {product.name}
                </option>
              ))}
            </datalist>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default InventoryBoard_v2;

import React, { useEffect, useState } from "react";
import Axios from "axios";
import UtilityBar from "./components/UtilityBar";
import BugComponent from "./components/BugComponent";

export default function BugTracker() {
  const [bugs, setBugs] = useState([]);
  const [filteredBugs, setFilteredBugs] = useState([]);
  const getBugs = async () => {
    const result = await Axios.get("/api/bugs/");
    setBugs(result.data.bugs || []);
    setFilteredBugs(result.data.bugs || []);
  };

  useEffect(() => {
    getBugs();
  }, []);

  return (
    <div className="bug-board">
      <UtilityBar bugs={bugs} setFilteredBugs={setFilteredBugs} />
      {filteredBugs.map((bug) => (
        <BugComponent bug={bug} />
      ))}
    </div>
  );
}

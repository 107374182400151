import React, { useContext, useEffect } from "react";
import Order from "../res/Order";
import { UserConsumer } from "../context/user-context";

const Dashboard = () => {
  document.title = "Purchase Order Board";
  const context = useContext(UserConsumer);

  useEffect(() => {
    context.fetchOrders();
  }, []);

  return (
    <div className="container-fluid" style={{ marginTop: "20px" }}>
      {context.orders.map((order, key) => (
        <Order key={key} order={order} fetchOrders={context.fetchOrders} />
      ))}
    </div>
  );
};

export default Dashboard;

import React from "react";

function ReusableFormRow({
  fieldValues: { label, required, name, placeHolder },
  handleChange,
  errors,
  value,
}) {
  return (
    <div className="reusable-form-row">
      <label htmlFor={name}>
        <b>{label}</b>
        {/* {required ? (
          <span className="required-form-field-asterisk">*</span>
        ) : null} */}
      </label>
      <input
        name={name}
        className="form-control form-control-sm"
        placeHolder={placeHolder}
        onChange={handleChange}
        value={value}
      ></input>
      {/* {errors[name] && errors[name].length > 0 ? ( */}
      <div className="reusable-form-row-error-span-container">
        <span className="reusable-form-row-error-span">{errors[name]}</span>
      </div>
      {/* ) : null} */}
    </div>
  );
}

export default ReusableFormRow;

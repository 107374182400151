import React from "react";

function Modal({ address, id, disabled }) {
  const states = [
    "Andra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telagana",
    "Tripura",
    "Uttaranchal",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadar and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadeep",
    "Pondicherry",
  ];
  // const handleSelection = (value) => {};
  return (
    <div className="address-modal">
      <label htmlFor="exampleFormControlinput1">Address 1</label>
      <input
        className="reason-input"
        id={`address1_${id}`}
        rows="1"
        defaultValue={address ? address.address1 : ""}
        disabled={disabled}
      />
      <label htmlFor="exampleFormControlinput1">Address 2</label>
      <input
        className="reason-input"
        id={`address2_${id}`}
        rows="1"
        defaultValue={address ? address.address2 : ""}
        disabled={disabled}
      />
      <label htmlFor="exampleFormControlinput1">Company</label>
      <input
        className="reason-input"
        id={`company_${id}`}
        rows="1"
        defaultValue={address ? address.company : ""}
        disabled={disabled}
      />
      <label htmlFor="exampleFormControlinput1">City</label>
      <input
        className="reason-input"
        id={`city_${id}`}
        rows="1"
        defaultValue={address ? address.city : ""}
        disabled={disabled}
      />
      <label htmlFor="exampleFormControlinput1">State</label>
      {/* <input
        className="reason-input"
        id={`province_${id}`}
        rows="1"
				defaultValue={address ? address.province : ""}
			
			disabled={disabled}/> */}
      <select
        style={{
          textAlign: "center",
          marginRight: "0px",
          border: "4px solid #bdc3c7",
        }}
        type="text"
        placeholder="Please enter a state"
        className="utilityBar-search"
        id={`province_${id}`}
        disabled={disabled}
      >
        <option selected={address ? false : true} disabled>
          Please Choose A State
        </option>
        {states.map((s) => (
          <option
            value={s}
            selected={address ? (address.province == s ? true : false) : false}
          >
            {s}
          </option>
        ))}
      </select>
      <label htmlFor="exampleFormControlinput1">Zipcode</label>
      <input
        className="reason-input"
        id={`zip_${id}`}
        rows="1"
        defaultValue={address ? address.zip : ""}
        disabled={disabled}
      />
    </div>
  );
}

export default Modal;

import React, { Fragment } from "react";
import Axios from "axios";

const AddAWB = () => {
  document.title = "Non Scannable Barcodes";
  const submitAWB = e => {
    e.preventDefault();
    console.log({
      awb: document.getElementById("awb_input").value
    });
    Axios.post("/api/warehouse/nonScannableBarcodes", {
      awb: document.getElementById("awb_input").value
    })
      .then(data => {
        alert(data.data.msg);
      })
      .catch(error => {
        alert(error);
      });
  };
  return (
    <Fragment>
      <div className='container-fluid'>
        <form className='form-horizontal'>
          <br />
          <div>
            <p id='status' name='status' />
          </div>
          <div className='shadow-lg p-3 mb-5 bg-white rounded'>
            <div className='card'>
              <div className='card-header'>
                <h3>Enter the old AWB number </h3>
              </div>
              <div className='card-body'>
                <div className='form-group'>
                  <label className='control-label col-sm-2' htmlFor='awb_input'>
                    AWB:
                  </label>
                  <div className='col-sm-4'>
                    <input
                      type='text'
                      className='form-control'
                      id='awb_input'
                      name='awb_input'
                      placeholder='Enter AWB'
                      required
                    />
                  </div>
                </div>
                <br />
                <div className='form-group'>
                  <div
                    className='col-sm-offset-2 col-sm-10'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "30%"
                    }}
                  >
                    <button
                      type='button'
                      className='btn btn-primary mb-2 btn-lg'
                      onClick={e => submitAWB(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default AddAWB;

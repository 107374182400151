import axios from "axios";

export const printManifestsAndLabels = async (
  setMessage,
  store,
  platform,
  type
) => {
  console.log("loaded Manifests file");
  try {
    if (platform && platform === "clickpost") {
      let result = await axios.post(
        type === "old"
          ? `/api/warehouse/generateManifestsAndLabelsforClickPost`
          : `/api/warehouse/generateAllManifestsAndLabelsforClickPost`,
        {
          data: store,
        },
        {
          responseType: "blob",
        }
      );

      console.log(result.data);
      const file = new Blob([result.data], {
        type: "application/pdf",
      });
      //Build a URL from the file
      console.log(file);
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
      setMessage({ clickpost: true, labelLink: fileURL });
      return fileURL;
    } else if (platform && platform === "goswift") {
      let result = await axios.post(
        `/api/goswift/warehouse/generateBulkLabelsForGoSwift`,
        {
          data: store,
        }
      );
      setMessage(result.data.msg);
      return result.data.msg.status;
    } else {
      let result = await axios.post(
        `/api/warehouse/generateManifestsAndLabels`,
        {
          data: store,
        }
      );
      setMessage(result.data.msg);
      return result.data.msg.status;
    }
  } catch (error) {
    console.log("Error in Front End function (printManifestsAndLabels.js)");
    console.log(error);
  }
};

export const printManifestsAndLabelsV3 = async (
  setMessage,
  platform,
  warehouse
) => {
  console.log("loaded global labels and manifests file");
  try {
    if (platform !== "shiprocket") {
      let result = await axios.post(
        `/api/warehouse/generateAllManifestsAndLabelsforClickPost`,
        {
          responseType: "blob",
        }
      );

      console.log(result.data);
      const file = new Blob([result.data], {
        type: "application/pdf",
      });
      //Build a URL from the file
      console.log(file);
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
      setMessage({ clickpost: true, labelLink: fileURL });
      return fileURL;
    } else {
      let result;
      if (!warehouse) {
        result = await axios.post(
          // `/api/warehouse/generateManifestsAndLabelsV3`
          `/api/warehouse/generateManifestsAndLabelsV4`,
          {
            warehouse: "bengaluru",
          }
        );
        setMessage(result.data.msg);
      } else {
        result = await axios.post(
          // `/api/warehouse/tempGenerateManifestsAndLabelsV4?warehouse=delhi`
          `/api/warehouse/generateManifestsAndLabelsV4`,
          {
            warehouse: "delhi",
          }
        );
      }
      setMessage(result.data.msg);
      return result.data.msg.status;
    }
  } catch (error) {
    console.log("Error in Front End function (printManifestsAndLabels.js)");
    console.log(error);
  }
};

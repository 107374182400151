import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";

export default function useDialerLinks(callEvent, ngrokLink) {
  const [ready, setReady] = useState(false);
  const [streamLink, setStreamLink] = useState(null);

  const downloadLink = `
	${ngrokLink}/astVlogger/asttecs/voicefiles/${new Date(
    callEvent.date
  ).getFullYear()}/${moment(callEvent.date).format("MMMM")}/${
    new Date(callEvent.date).getDate() < 10
      ? "0" + new Date(callEvent.date).getDate()
      : new Date(callEvent.date).getDate()
  }/${callEvent.recordingFile}.gsm`;

  const prepLinks = async () => {
    await Axios.get(
      `${ngrokLink}/astVlogger/asttecs/voiceexport.php?file=${callEvent.recordingFile}`
    );
  };

  const openLink = async (type, id) => {
    await prepLinks();
    if (type === "stream") {
      const link = `${ngrokLink}/astVlogger/asttecs/voicefiles/converted/${callEvent.recordingFile}.wav`;
      setStreamLink(link);
      setTimeout(() => document.getElementById(id).play(), 500);
      setReady(true);
    } else window.open(downloadLink, true);
  };

  return [openLink, ready, streamLink];
}

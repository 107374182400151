import React, { useState, useContext, useEffect } from "react";
import NavBar from "../res/NavBar";
import ProductForm from "../res/ProductForm";
import { sendOrderDetails } from "../../functions/sendOrderDetails";
import { UserConsumer } from "../context/user-context";
import { Redirect } from "react-router-dom";

const AddInventory = () => {
  document.title = "Create Purchase Order";
  var i = 0;

  const context = useContext(UserConsumer);
  const [done, setDone] = useState(false);

  const cycleSuggestion = (type, e, context) => {
    if (e.keyCode === 39 && i < context.filtered.length) {
      document.getElementById(type).value = context.filtered[i];
      ++i;
      if (i === context.filtered.length) i = 0;
    }
  };

  const [count, setCount] = useState(1);
  const [forms, setForms] = useState([
    <ProductForm key={count} id={count} cycleSuggestion={cycleSuggestion} />,
  ]);

  const handleAddClick = () => {
    setCount((prevState) => {
      return prevState + 1;
    });
    setForms((prevState) => {
      return [
        ...prevState,
        <ProductForm
          key={count + 1}
          id={count + 1}
          cycleSuggestion={cycleSuggestion}
        />,
      ];
    });
  };

  const handleRemoveClick = () => {
    setCount((prevState) => {
      return prevState - 1;
    });
    setForms((prevState) => {
      prevState.pop();
      return [...prevState];
    });
  };

  useEffect(() => {
    context.fetchOrders();
  }, []);

  const submitOrder = async () => {
    let res = await sendOrderDetails(forms.length);
    setDone(res);
  };
  if (done) {
    return <Redirect to="/" />;
  }

  return (
    <div className="card configurationsCard IMS-order-card">
      <div style={{ padding: "20px" }}>
        <form>
          <div className="form-group">
            <label htmlFor="supplier">Supplier</label>&nbsp;
            {/* <span id="supplierSpan" style={{ visibility: "hidden" }} /> */}
            <input
              type="text"
              list="supplierList"
              className="form-control"
              id="supplier"
              placeholder="Supplier's Name"
              autoComplete="off"
              // onKeyDown={e => {
              //   cycleSuggestion("supplier", e, context);
              // }}
              onChange={(e) =>
                context.filterOrders("suppliers", e.target.value)
              }
            />
            <datalist id="supplierList">
              {context.suppliers.map((supplier, i) => (
                <option value={supplier.name} key={i} />
              ))}
            </datalist>
          </div>

          <div className="form-group">
            <label htmlFor="transporter">Transporter</label>
            <input
              type="text"
              list="transporterList"
              className="form-control"
              id="transporter"
              placeholder="Transporter's Name"
              autoComplete="off"
              // onKeyDown={e => {
              //   cycleSuggestion("transporter", e, context);
              // }}
              onChange={(e) =>
                context.filterOrders("transporters", e.target.value)
              }
            />
            <datalist id="transporterList">
              {context.transporters.map((transporter, i) => (
                <option value={transporter.name} key={i} />
              ))}
            </datalist>
          </div>

          <div className="form-group">
            <label htmlFor="customers">Who is this for?</label>
            <input
              type="text"
              list="customersList"
              className="form-control"
              id="customers"
              placeholder="customers Name"
              autoComplete="off"
              // onKeyDown={e => {
              //   cycleSuggestion("transporter", e, context);
              // }}
              onChange={(e) =>
                context.filterOrders("customers", e.target.value)
              }
            />
            <datalist id="customersList">
              {context.customers.map((customer, i) => (
                <option value={customer.email} key={i}>
                  {customer.name}
                </option>
              ))}
            </datalist>
          </div>

          <div className="form-group">
            <input
              id="prelaunch"
              type="checkbox"
              style={{ marginRight: "5px" }}
            ></input>
            <label htmlFor="prelaunch" className="form-check-label">
              Pre Launch
            </label>
          </div>

          {forms}
        </form>

        <button
          type="button"
          className="btn btn-success btn-product-add"
          onClick={() => handleAddClick()}
        >
          Add product
        </button>
        <button
          type="button"
          className="btn btn-danger btn-product-rm"
          onClick={() => handleRemoveClick()}
        >
          Remove product
        </button>
      </div>
      <button
        type="button"
        className="btn btn-default btn-submit"
        onClick={() => submitOrder()}
      >
        Submit Purchase
      </button>
    </div>
  );
};

export default AddInventory;

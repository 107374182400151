import React, { useState, useEffect, Fragment } from "react";
import Tags from "../res/Tags";
import useDatePicker from "../../global/DatePicker";
import Axios from "axios";
import Spinner from "../../../trackingboard/OrderTracker/components/Items/Spinner";
import { Parser } from "json2csv";
import moment from "moment";
import Cryptr from "cryptr";

function RtoStats() {
  const [store, setStore] = useState();
  const [stores, setStores] = useState([]);
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  document.title = "RTO Stats";

  const fetchProducts = async (store) => {
    let productList = await Axios.get(
      `/api/globalDashboard/fetchProducts/${encodeURIComponent(store)}`
    );
    setProducts(productList.data);
    setFiltered(productList.data);
  };

  const getUser = () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));
    console.log(details);
    setUser({
      user_id: details.user_id,
      name: details.name,
      role: details.role,
      email: details.email,
    });
  };

  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };

  //Get all the couriers dynamically dependiong on store
  const getCouriers = async (store) => {
    store = new Object(store).toString(); // eslint-disable-line
    store = JSON.parse(JSON.stringify(store));
    let courierPartners = await Axios.get(
      `/api/warehouse/rtoGetCourierNames/${encodeURIComponent(store)}`
    );

    setCouriers(courierPartners.data);
  };

  const multiCheck = (e, value) => {
    if (!isNaN(parseFloat(value))) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";
      if (value2send) {
        global.selected.push([...value2send.split(",")]);

        document.getElementById("productSearch").value = "";

        let productList = global.selected.map((item) => item[0]);

        let newArray = products.filter((product) => {
          return !productList.includes(product[0].toString());
        });

        setFiltered(newArray);
      }
    }
  };

  const handleStoreChange = (e) => {
    let store = e.target.value;
    console.log(`from handle store function ${store}`);
    setStore(store);
  };

  const reducePage = () => {
    if (page !== 1) setPage((page) => page - 1);
  };

  const increasePage = () => {
    const extra = result.length % 20 === 0 ? 0 : 1;
    if (page !== parseInt(result.length / 20) + extra)
      setPage((page) => page + 1);
  };

  const downloadCSV = async () => {
    setLoading(true);
    document.getElementById("stats-container").style.visibility = "visible";
    let store = Array.isArray(
      JSON.parse(document.getElementById("store_selector").value)
    )
      ? JSON.parse(document.getElementById("store_selector").value)
      : [JSON.parse(document.getElementById("store_selector").value)];
    let selectedProducts = global.selected;
    let productsList = selectedProducts.map((item) => {
      return item[1];
    });

    let courier =
      document.getElementById("courier_selector").value === "All"
        ? []
        : [
            JSON.parse(document.getElementById("courier_selector").value)
              .courier_company_id,
          ];
    const dataToBackend = {
      store,
      productsList,
      fromDate,
      toDate,
      courier,
      user,
    };
    const { data } = await Axios.post("/api/warehouse/rtoStatsDownload", {
      ...dataToBackend,
    });
    if (!data.status) {
      alert(data.message);
      return;
    }
    alert("CSV has been sent to your email!");
    setLoading(false);
  };

  const submitDetails = async () => {
    setLoading(true);
    setPage(1);
    document.getElementById("stats-container").style.visibility = "visible";
    let store = Array.isArray(
      JSON.parse(document.getElementById("store_selector").value)
    )
      ? JSON.parse(document.getElementById("store_selector").value)
      : [JSON.parse(document.getElementById("store_selector").value)];
    let selectedProducts = global.selected;
    let productsList = selectedProducts.map((item) => {
      return item[1];
    });

    let courier =
      document.getElementById("courier_selector").value === "All"
        ? []
        : [
            JSON.parse(document.getElementById("courier_selector").value)
              .courier_company_id,
          ];
    const dataToBackend = {
      store,
      productsList,
      fromDate,
      toDate,
      courier,
      user,
    };
    const { data } = await Axios.post("/api/warehouse/rtoStats", {
      ...dataToBackend,
    });
    // receive the csv sent from backend
    if (!data.status) {
      alert(data.message);
      return;
    }
    setResult(data.results);
    document.getElementById("download-csv-button").style.visibility = "visible";
    setLoading(false);
  };

  useEffect(() => {
    global.selected = [];
    getStores();
    getUser();
  }, []);

  useEffect(() => {
    if (store) {
      fetchProducts(store);
      getCouriers(store);
    }
  }, [store]);

  const styles = {
    customTable: {
      overflowX: "scroll",
    },
  };

  return (
    <div className="stats-content">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        {global.selected
          ? global.selected.map((item, i) => (
              <Tags
                item={item}
                key={i}
                permuteOrders={getStores}
                setFiltered={setFiltered}
                filtered={filtered}
              />
            ))
          : ""}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="stats-filter"
          style={{ width: "100%", marginRight: "1vw" }}
        >
          <select
            placeholder="Stores"
            id="store_selector"
            className="form-control form-control-sm"
            style={{
              height: "100%",
              fontSize: "0.9rem",
              borderRadius: 0,
            }}
            onChange={(e) => handleStoreChange(e)}
          >
            <option value={JSON.stringify(stores)} selected>
              {store ? store.storeName : "All stores"}
            </option>
            {stores.map((store, index) => (
              <option value={JSON.stringify(store)} key={index}>
                {store.storeName}
              </option>
            ))}
          </select>

          <input
            type="text"
            id={"productSearch"}
            list="productList"
            autoComplete="off"
            placeholder="Products"
            className="form-control form-control-sm"
            style={{
              height: "100%",
              fontSize: "0.9rem",
              borderRadius: 0,
              width: "100%",
            }}
            onChange={(e) => {
              multiCheck(e, e.target.value);
            }}
          />
          <datalist id="productList">
            {filtered.map
              ? filtered.map((option, index) => (
                  <option key={index} data-value={option} value={option[0]}>
                    {option[1]}
                  </option>
                ))
              : null}
          </datalist>

          <select
            placeholder="Couriers"
            id="courier_selector"
            className="form-control form-control-sm"
            style={{
              height: "100%",
              fontSize: "0.9rem",
              borderRadius: 0,
            }}
          >
            <option value="All" selected>
              All Couriers
            </option>
            {couriers.map
              ? couriers.map((courier, index) => (
                  <option value={JSON.stringify(courier)} key={index}>
                    {courier.name}
                  </option>
                ))
              : null}
          </select>

          <DatePicker />
          <button
            className="btn btn-danger"
            style={{ borderRadius: 0 }}
            onClick={(e) => submitDetails(e)}
          >
            Submit
          </button>
        </div>
        <div>
          <div
            id="download-csv-button"
            className="tool-button tooltip-nav bg-danger"
            onClick={downloadCSV}
            style={{ visibility: "hidden", height: "100%" }}
          >
            <i
              className="fas fa-arrow-circle-down"
              style={{ color: "white" }}
            ></i>
            <span className="tooltip-nav-text">Download CSV</span>
          </div>
        </div>
      </div>

      <div id="stats-container" style={{ visibility: "hidden" }}>
        <Fragment>
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div style={{ margin: "20px" }}>
                <center id="order-result">{`${result.length} orders found!`}</center>
                <center id="page-number">{`Page - ${page}`}</center>
              </div>
              {!store ? (
                <div
                  className="ordersCard"
                  style={{ overflowX: "scroll", position: "relative" }}
                >
                  <table
                    className="table ordersTable"
                    style={styles.customTable}
                  >
                    <thead>
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Store</th>
                        <th scope="col">Order Number</th>
                        <th scope="col">AWB</th>
                        <th scope="col">Product</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">RTO Recieved On</th>
                        <th scope="col">Courier</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result
                        .slice((page - 1) * 20, page * 20)
                        .map((order, index) => (
                          <tr id={order.name} className="tracking-row">
                            <td>{index + 1 + (page - 1) * 20}</td>
                            <td>{order.store.storeName}</td>
                            <td>{order.name}</td>
                            <td>{order.awb_ref_no}</td>
                            <td>
                              {order.line_items.length === 1
                                ? order.line_items[0].name
                                : order.line_items.map(
                                    (product) => product.title + ", "
                                  )}
                            </td>
                            <td>
                              {order.line_items.length === 1
                                ? order.line_items[0].quantity
                                : order.line_items.map(
                                    (product) => product.quantity + ", "
                                  )}
                            </td>
                            <td>
                              {moment(new Date(order.rtoReceivedOn)).format(
                                "DD/MM/YYYY, hh:mm A"
                              )}
                            </td>
                            <td>{order.courierName}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className="ordersCard"
                  style={{ overflowX: "scroll", position: "relative" }}
                >
                  <table className="table ordersTable">
                    <thead>
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Order Number</th>
                        <th scope="col">AWB</th>
                        <th scope="col">Product</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">RTO Recieved On</th>
                        <th scope="col">Courier</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result
                        .slice((page - 1) * 20, page * 20)
                        .map((order, index) => (
                          <tr id={order.name} className="tracking-row">
                            <td>{index + 1 + (page - 1) * 20}</td>
                            <td>{order.name}</td>
                            <td>{order.awb_ref_no}</td>
                            <td>
                              {order.line_items.length === 1
                                ? order.line_items[0].name
                                : order.line_items.map(
                                    (product) => product.title + ", "
                                  )}
                            </td>
                            <td>
                              {order.line_items.length === 1
                                ? order.line_items[0].quantity
                                : order.line_items.map(
                                    (product) => product.quantity + ", "
                                  )}
                            </td>
                            <td>
                              {moment(new Date(order.rtoReceivedOn)).format(
                                "DD/MM/YYYY, hh:mm A"
                              )}
                            </td>
                            <td>{order.courierName}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="pagination">
                <button className="pagination-button" onClick={reducePage}>
                  <i className="fas fa-arrow-circle-left"></i>
                </button>
                <button className="pagination-button" onClick={increasePage}>
                  <i className="fas fa-arrow-circle-right"></i>
                </button>
              </div>
            </Fragment>
          )}
        </Fragment>
      </div>
    </div>
  );
}

export default RtoStats;

import React, { useState } from "react";
import Axios from "axios";
import { confirmation } from "../../utils/confirmation";
import ProductSubstitute from "./ProductSubstitute";

function Product({ product, order, store, fetchOrders }) {
  const [editQuantity, setEditQuantity] = useState(false);
  const [showSubstituteProduct, setShowSubstituteProduct] = useState(false);

  const modifyQuantity = async () => {
    if (!confirmation()) return;
    let quantity = document.getElementById(
      `item-quantity-${order.name}-${product.variant_id}`
    ).value;

    let data = {
      name: order.name,
      item: {
        product_id: parseFloat(product.product_id),
        variant_id: parseFloat(product.variant_id),
      },
      quantity: parseInt(quantity),
      store,
    };

    await Axios.post("/api/operations/editQuantity", data);
    fetchOrders(store);

    setEditQuantity(false);
  };
  return (
    <div className="ops-product">
      {!product.product_id || !product.variant_id ? (
        <div
          style={{
            padding: "10px 10px",
            fontStyle: "italic",
            background: "#ecf0f1",
            color: "#2980B9",
          }}
        >
          No Product ID/Variant ID, Try Substituting{" "}
          <i class="fas fa-arrow-circle-right"></i>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          background: "#2980b9",
          color: "white",
          display: "flex",
          position: "relative",
        }}
      >
        <div style={{ padding: "15px", width: "100%" }}>
          {product.product_id}
        </div>
        <div
          style={{
            padding: "15px",
            background: "rgb(52, 116, 158)",
            width: "100%",
          }}
        >
          {product.variant_id}
        </div>

        <div
          className="ops-product-substitute-button"
          id="out-of-stock-dropdown-menu-button"
          onClick={() => setShowSubstituteProduct(!showSubstituteProduct)}
        >
          {showSubstituteProduct ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-pencil-alt fa-xs"></i>
          )}
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        {product.name ? product.name : product.title} X{" "}
        {!editQuantity ? (
          <div
            className="ops-product-quantity"
            onDoubleClick={() => setEditQuantity(true)}
          >
            {product.quantity}
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <input
              type="number"
              id={`item-quantity-${order.name}-${product.variant_id}`}
              className="ops-product-quantity-input"
              defaultValue={product.quantity}
            />
            <div
              style={{
                padding: "5px",
                background: "#33749E",
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => modifyQuantity()}
            >
              <i className="fas fa-check-circle"></i>
            </div>
          </div>
        )}
      </div>
      {showSubstituteProduct ? (
        <div>
          <ProductSubstitute
            product={product}
            store={order.store}
            order={order.name}
            fetchOrders={fetchOrders}
            setShowSubstituteProduct={setShowSubstituteProduct}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Product;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const ProductDetails = ({
  store,
  lineItems,
  setLineItems,
  discountCoupon,
  setDiscountCoupon,
  setDiscountCode,
  setNotification,
  flags,
  setFlags,
}) => {
  const productInput = useRef(null);
  const quantityInput = useRef(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);

  const fetchProducts = async (store) => {
    if (!store) return;
    let productList = await axios.get("/api/product", {
      params: { store },
    });
    productList = productList.data.products;
    let options = [];
    for (let i = 0; i < productList.length; i++) {
      if (productList[i].deleted) continue;
      let title = productList[i].title;
      productList[i].variants.map((variant) => {
        if (!variant.deleted) {
          if (variant.title == "Default Title") {
            options.push({
              title: `${title} (${variant.variant_id})`,
              product_id: variant.product_id,
              variant_id: variant.variant_id,
            });
          } else {
            options.push({
              title: `${title} ${variant.title} (${variant.variant_id})`,
              product_id: variant.product_id,
              variant_id: variant.variant_id,
            });
          }
        }
      });
    }
    setFilteredProducts(options);
  };

  const selectProduct = (e) => {
    const productOptions = document.getElementById("productList").children;
    let i = productOptions.length;
    while (i--) {
      const option = productOptions[i];
      const product = JSON.parse(option.dataset.value);
      if (product.title === e.target.value) {
        setSelectedProduct(product);
      }
    }
  };

  const selectQuantity = (e) => {
    setSelectedQuantity(quantityInput.current.value);
  };

  const addLineItems = () => {
    if (!selectedProduct) {
      setNotification("failure", "Please select a product to add!");
      return;
    }
    if (!selectedQuantity) {
      setNotification("failure", "Please select the quantity of the product!");
      return;
    }
    const lineItem = {
      product: selectedProduct,
      quantity: selectedQuantity,
    };
    setLineItems([...lineItems, lineItem]);
    productInput.current.value = "";
    quantityInput.current.value = "";
  };

  const removeLineItem = (itemToBeremoved) => {
    if (discountCoupon) {
      setDiscountCode("");
      setDiscountCoupon(null);
      setLineItems([]);
      setFlags({
        ...flags,
        discountCoupon: false,
      });
      setNotification("success", "Removed discount and line items is reset!");
      return;
    }
    const newLineItems = lineItems.filter(
      (item) => !(item.product.title === itemToBeremoved.product.title)
    );
    setLineItems(newLineItems);
  };

  useEffect(() => {
    if (store) {
      fetchProducts(store);
    }
  }, [store]);

  return (
    <div>
      <div className="input-container">
        <div className="input-grouping">
          <label htmlFor="variantInput" className="input-container-label">
            Variant ID{" "}
          </label>
          <input
            type="text"
            name="products"
            id="experiment-product-forward"
            list="productList"
            autoComplete="off"
            placeholder="Product title (variant id)"
            className="form-control form-control-sm"
            onChange={selectProduct}
            ref={productInput}
          />
          <datalist id="productList" style={{ width: "100%" }}>
            {filteredProducts.map
              ? filteredProducts.map((option, index) => (
                  <option
                    key={index}
                    data-value={JSON.stringify(option)}
                    value={option.title}
                  ></option>
                ))
              : null}
          </datalist>
        </div>
        <div className="input-grouping">
          <label htmlFor="quantityInput" className="input-container-label">
            Quantity{" "}
          </label>
          <input
            id="quantityInput"
            placeholder="Enter quantity"
            type="number"
            className="form-control form-control-sm"
            onChange={selectQuantity}
            ref={quantityInput}
          ></input>
        </div>
        <button
          className="find-details-button"
          style={{
            width: "30%",
            margin: "0 auto",
            border: "2px solid #2980b9",
            backgroundColor: "#fff",
            color: "#000",
          }}
          onClick={addLineItems}
        >
          Add Item
        </button>
      </div>
      <div className="items-container">
        {lineItems.length > 0
          ? lineItems.map((item) => {
              return (
                <div className="tag-item" style={{ margin: "1% 1%" }}>
                  <span>{`${item.product.title} x ${item.quantity}`}</span>
                  <span
                    onClick={() => removeLineItem(item)}
                    style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  >
                    <i className="fas fa-trash"></i>
                  </span>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ProductDetails;

import React, { useEffect, useState } from "react";
import Cryptr from "cryptr";
import StoreExpensesMainForm from "./StoreExpensesMainForm";
import StoreAdSpends from "./StoreAdSpends";
import ViewStoreExpensesAndProfits from "./ViewStoreExpensesAndProfits";

import "../res/StoreExpenses.css";

const storeExpensesPages = [
  {
    name: "View Store Expenses and Profits",
    value: "viewStoreExpensesAndProfits",
  },
  {
    name: "Store Expenses Main Form",
    value: "storeExpensesMainForm",
  },
  {
    name: "Store Ad Spends",
    value: "storeAdSpends",
  },
];

function StoreExpenses() {
  const [currentPage, setCurrentPage] = useState(null);
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));
    const userObj = {
      user_id: details.user_id,
      name: details.name,
      role: details.role,
      email: details.email,
    };
    setUser(userObj);
  };

  const validatedUser = () => {
    if (user.role === "administrator" || user.role === "developer") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div id="store-expenses-index">
      {!currentPage ? (
        <div id="store-expenses-index-container">
          {storeExpensesPages.map((page) => {
            return (
              <div
                className="store-expenses-box"
                onClick={() => {
                  if (
                    page.value === "viewStoreExpensesAndProfits" &&
                    !validatedUser()
                  ) {
                    alert("You are not allowed to view this page");
                  } else {
                    setCurrentPage(page.value);
                  }
                }}
              >
                <div>
                  <b>{page.name}</b>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      {currentPage === "viewStoreExpensesAndProfits" ? (
        <ViewStoreExpensesAndProfits setPage={setCurrentPage} user={user} />
      ) : null}
      {currentPage === "storeExpensesMainForm" ? (
        <StoreExpensesMainForm setPage={setCurrentPage} user={user} />
      ) : null}
      {currentPage === "storeAdSpends" ? (
        <StoreAdSpends setPage={setCurrentPage} user={user} />
      ) : null}
    </div>
  );
}

export default StoreExpenses;

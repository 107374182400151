import React, { useState, useEffect } from "react";

export default function useAlert() {
  const [status, setStatus] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("This is an alert...");
  const [persistent, setPersistent] = useState(false);

  let borderColor = "white";

  if (status === "success") borderColor = "#2ecc71";
  if (status === "failure") borderColor = "#e74c3c";

  const alertStyle = {
    borderLeft: `10px solid ${borderColor}`,
  };

  const timeOut = () => {
    setTimeout(() => setShowAlert(false), 10000);
  };

  const setNotification = (status, message, persistent = false) => {
    setStatus(status);
    setMessage(message);
    setPersistent(persistent);
    setShowAlert(true);
  };

  useEffect(() => {
    if (showAlert && !persistent) timeOut();
  }, [showAlert]);

  const AlertBox = ({}) => {
    if (!showAlert) return <></>;
    return (
      <div className="global-alert" style={alertStyle}>
        <div>{message}</div>
        <div
          style={{
            marginLeft: "auto",
            color: "#DC3545",
            cursor: "pointer",
            paddingLeft: "20px",
          }}
          onClick={() => setShowAlert(false)}
        >
          <i className="fas fa-times-circle"></i>
        </div>
      </div>
    );
  };

  return [AlertBox, setNotification];
}

import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CopyContent from "../../../components/common/CopyContent";
import Divider from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { grey } from "@mui/material/colors";
import EditDimension from "./EditDimension";
import Box from "@mui/material/Box";

const Dimension = ({
  dimension: {
    name,
    length,
    breadth,
    height,
    weight,
    updatedBy,
    createdOn,
    updatedOn,
  },
  user,
  onSearch,
}) => {
  return (
    <Card
      sx={{
        minHeight: 200,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <CardContent sx={{ p: 1 }}>
        <Box sx={{ fontSize: 16, textAlign: "center", p: 1 }}>
          <Typography
            variant="h7"
            component="span"
            sx={{ overflowWrap: "break-word" }}
          >
            {name}
            <CopyContent content={name} />
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: grey[100], mb: 1 }} />
        <Grid container>
          <Grid container direction={"column"} xs={6} textAlign={"center"}>
            <Grid item>
              <Typography color="text.secondary" gutterBottom>
                Length
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" gutterBottom>
                Breadth
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" gutterBottom>
                Height
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" gutterBottom>
                Weight
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction={"column"} textAlign={"center"} xs={6}>
            <Grid container item justifyContent={"right"} alignItems={"right"}>
              <Typography>{length}</Typography>
              <Typography ml={1} mr={4} color="text.secondary">
                cm
              </Typography>
            </Grid>
            <Grid container item justifyContent={"right"} alignItems={"right"}>
              <Typography gutterBottom>{breadth}</Typography>
              <Typography ml={1} mr={4} color="text.secondary">
                cm
              </Typography>
            </Grid>
            <Grid container item justifyContent={"right"} alignItems={"right"}>
              <Typography gutterBottom>{height}</Typography>
              <Typography ml={1} mr={4} color="text.secondary">
                cm
              </Typography>
            </Grid>
            <Grid container item justifyContent={"right"} alignItems={"right"}>
              <Typography gutterBottom>{weight}</Typography>
              <Typography ml={1} mr={4} color="text.secondary">
                kg
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: grey[50] }} />
      </CardContent>
      <CardActions>
        <Grid container columnSpacing={1}>
          <Grid item xs={6} textAlign={"center"}>
            <EditDimension
              name={name}
              length={length}
              breadth={breadth}
              height={height}
              weight={weight}
              user={user}
              onSearch={onSearch}
            />
          </Grid>
          <Grid item xs={6} textAlign={"center"}>
            <Button size="small" variant={"outlined"}>
              <DeleteForeverIcon fontSize="small" />
              Delete
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default Dimension;

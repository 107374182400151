// resusable component - store dropdown button

import React, { useState, useEffect } from "react";
import axios from "axios";

const StoreDropdownButton = ({ store, setStore, showDeleted = true }) => {
  const [stores, setStores] = useState([]);

  const getStoresList = async () => {
    let allStores;
    let { data } = await axios.get("/api/stores");
    allStores = data;
    if (!showDeleted) {
      allStores = data.filter((store) => !store.deleted);
    }

    // SHINE X PRO STORE ONLY
    allStores = allStores.filter((store) => store.shopifyName === "shinexpro");

    setStores(allStores);
    // const filterDeletedStores = result.data.filter((store) => !store.deleted);
    // setStores(filterDeletedStores);
  };

  useEffect(() => {
    getStoresList();
  }, []);

  return (
    <div className="dropdown chooseStoreNormal">
      <button
        className="btn  dropdown-toggle storeButton"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {store ? store.storeName : "Choose store"}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {stores.map((storeItem, index) => (
          <button
            key={index}
            className="dropdown-item"
            onClick={() => {
              setStore(storeItem);
            }}
          >
            {storeItem.storeName}
          </button>
        ))}
      </div>
    </div>
  );
};

export default StoreDropdownButton;

import React, { useEffect, useState, Fragment } from "react";
import Axios from "axios";
import Cryptr from "cryptr";
import CallingSettings from "./CallingSettings";

function NavBar({
  callCount,
  user,
  getOrders,
  setType,
  setFilters,
  filters,
  processStats,
}) {
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [callTypes, setCallTypes] = useState();
  const [breakdown, setBreakdown] = useState("");
  const [total, setTotal] = useState("Calculating...");
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    let users = await Axios.get("/api/auth/users/support");
    setUsers(users.data.users);
  };

  const handleShow = async (user) => {
    setBreakdown("");
    setTotal("Calculating..");
    if (show.name === user.name) {
      setCallTypes("");
      setShow("");
      return;
    }
    setShow(user);
    setCallTypes(user.callTypes);
    let result = await Axios.get(
      `/api/support/fetchCallBreakdownV3?user_id=${user.user_id}&name=${user.name}`
    );
    setBreakdown(result.data.typesList);
    let final = 0;
    Object.keys(result.data.typesList).map(
      (count) => (final += result.data.typesList[count])
    );
    setTotal(final);
  };

  const importAbandonedOrders = async () => {
    await Axios.get("/api/orders/getAbandonedCheckoutsAutomated");
    alert("Abandoned orders will be imported");
  };

  const downloadCSV = async () => {
    let result = await Axios.get("/api/support/retrieveFakeAttempts");
    let blob = new Blob([result.data], { type: "text/csv" });
    var a = document.createElement("a");
    a.download = "FakeAttempts.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
  };

  const downloadDialerOrders = async () => {
    let result = await Axios.get(
      `/api/support/generateDialerCSV?email=${user.email}`
    );
    const { fileURL } = result.data;
    if (!fileURL) return alert("Something went wrong!");
    window.open(fileURL, "1");
    window.open(
      "https://192.168.10.250/vicidial/admin_listloader_fourth_gen.php",
      "2"
    );
  };

  const downloadNDRCSV = async () => {
    let result = await Axios.get("/api/operations/shiprocketCSVForNdr");
    let blob = new Blob([result.data], { type: "text/csv" });
    var a = document.createElement("a");
    a.download = "NdrCSV.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
  };

  const downloadDetailedNDRCSV = async () => {
    let date =
      prompt(
        "Enter FROM and TO in the following exact format :- MM/DD-MM/DD"
      ) || "";
    const dateRegex = new RegExp(/\d\d\/\d\d-\d\d\/\d\d/);

    if (!date || !dateRegex.test(date))
      return alert("Please enter a valid date in DD/MM-DD/MM and try again");
    date = date.trim().split("-");
    date = date.map((item) => (item += `/${new Date().getFullYear()}`));
    const [from, to] = date;
    if (!from || !to)
      return alert("Please enter a valid date in DD/MM-DD/MM and try again");

    if (!user.email) alert("Please log in again or contact the developers");

    await Axios.post("/api/support/supportDailyReport", {
      from,
      to,
      email: user.email,
    });
  };

  const submit = async () => {
    // get the user info for emailing
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let user = JSON.parse(cryptr.decrypt(info));
    user = { email: user.email };

    document.getElementById("get_ndr_calls").disabled = true;
    const result = await Axios.get(
      "/api/orders/importShipRocketNDRPendingOrders",
      { params: { user } }
    );
    if (result.data.msg) {
      alert(result.data.msg);
      // document.getElementById("csv_btn").disabled = false;
      return;
    }
    alert("Imported Orders. Check your email for NDR report!");

    document.getElementById("get_ndr_calls").disabled = false;
    setType({ value: "ndr", type: "ndr" });
    // setFilters([...filters, query]);
    filters.call = "ndr";
    setFilters(filters);
  };

  const toggleActive = async (user) => {
    let result = await Axios.post("/api/support/pauseCaller", {
      user_id: user.user_id,
      active: user.active ? user.active : false,
    });
    getUsers();
  };

  const resolveDialerLink = async () => {
    const result = await Axios.get("/api/support/getDialerLink");

    window.open("http://192.168.10.250/", "1");
    if (result.data.link) window.open(result.data.link, "2");
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Fragment>
      <nav className="navbar navbar-light bg-light">
        <div className="callingboard-button-bar">
          <button
            type="button"
            className="btn btn-primary callingboard-nav-buttons"
            disabled
            style={{
              height: "50px",
              borderRadius: "0px",
            }}
          >
            {user ? user.name : "Not logged in"}{" "}
            <span className="badge badge-light callBadge">
              {callCount ? callCount : 0}
            </span>
            <span className="sr-only">Calls</span>
          </button>
          {user.master || user.employee ? (
            <button
              className="callingboard-nav-buttons tooltip-nav"
              id="get_ndr_calls"
              onClick={() => submit()}
            >
              Get NDR Orders
              <span className="tooltip-nav-text">
                Distribute NDR orders among customer support team
              </span>
            </button>
          ) : (
            ""
          )}

          {/* {user.master || user.employee ? (
            <button
              className="callingboard-nav-buttons tooltip-nav"
              id="get_ndr_calls"
              onClick={() => submit()}
            >
              Get NDR Orders
              <span className="tooltip-nav-text">
                Distribute NDR orders among customer support team
              </span>
            </button>
          ) : (
            ""
          )}

          {user.master || user.employee ? (
            <button
              className="callingboard-nav-buttons tooltip-nav"
              id="get_ndr_calls"
              onClick={() => downloadDetailedNDRCSV()}
            >
              Generate Detailed NDR Report
              <span className="tooltip-nav-text">
                Generates A Detailed NDR Report Consisting Of Actions, Outcome,
                etc
              </span>
            </button>
          ) : (
            ""
          )}

          <button
            className="callingboard-nav-buttons tooltip-nav"
            type="button"
            onClick={() => downloadCSV()}
          >
            Get NDR Report
            <span className="tooltip-nav-text">
              Get the order details of fake delivery attempts made by courier
            </span>
          </button>

          <button
            className="callingboard-nav-buttons tooltip-nav"
            type="button"
            onClick={() => downloadNDRCSV()}
          >
            Get NDR CSV
            <span className="tooltip-nav-text">
              Get the order details of NDR calls made today by calling team
            </span>
          </button> */}

          {user.master || user.employee ? (
            <button
              className="callingboard-nav-buttons tooltip-nav"
              type="button"
              onClick={() => downloadDialerOrders()}
            >
              Get Dialer Uploads
              <span className="tooltip-nav-text">
                Get the list of Order Confirmation, NDR, Abandoned Checkouts
                orders that can be directly uploaded to the dialer
              </span>
            </button>
          ) : (
            ""
          )}

          <button
            className="callingboard-nav-buttons tooltip-nav"
            type="button"
            onClick={() => resolveDialerLink()}
          >
            Go to Dialer
            <span className="tooltip-nav-text">
              Takes you to the Astecs Dialer
            </span>
          </button>
          <button
            className="callingboard-nav-buttons tooltip-nav"
            type="button"
            onClick={() => {
              processStats(true);
            }}
          >
            Get Call Count
            <span className="tooltip-nav-text">
              Gets current call count from the dialer
            </span>
          </button>
          {user.master || user.employee ? (
            <button
              className="callingboard-nav-buttons tooltip-nav"
              type="button"
              onClick={() => {
                importAbandonedOrders();
              }}
            >
              Import abandoned orders
              <span className="tooltip-nav-text">
                Imports abandoned orders from our all stores
              </span>
            </button>
          ) : (
            ""
          )}
        </div>
        {user.master || user.employee || user.privilegedCaller ? (
          <div className="callingboard-user-bar">
            {users.map((user) => (
              <Fragment key={user.email}>
                <div>
                  <div
                    onClick={() => toggleActive(user)}
                    className="callingboard-user-button"
                    style={{ background: user.active ? "#079992" : "#DC3545" }}
                  >
                    {user.active ? (
                      <i
                        className="far fa-play-circle"
                        style={{ marginRight: "10px" }}
                      ></i>
                    ) : (
                      <i
                        className="far fa-pause-circle"
                        style={{ marginRight: "10px" }}
                      ></i>
                    )}
                    {user.name}
                  </div>
                  <div
                    style={{
                      marginRight: "10px",
                      // background: "#079992",
                      textAlign: "center",
                      cursor: "pointer",
                      color: "#079992",
                      fontSize: "0.7em",
                      // borderTop: "1px solid"
                    }}
                    onClick={() => {
                      handleShow(user);
                    }}
                  >
                    {show.name === user.name ? (
                      <i className="fas fa-chevron-up"></i>
                    ) : (
                      <i className="fas fa-chevron-down"></i>
                    )}
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        ) : (
          ""
        )}
      </nav>
      {show ? (
        <CallingSettings
          loggedInAs={user}
          user={show}
          total={total}
          callTypes={callTypes}
          setCallTypes={setCallTypes}
          breakdown={breakdown}
          loading={loading}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default NavBar;

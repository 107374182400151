import React, { useState, useEffect } from "react";
import Axios from "axios";

const NavBar = ({ store, setStore, fetchOrders }) => {
  const [stores, setStores] = useState([]);

  const getStoresList = async () => {
    let result = await Axios.get("/api/stores");
    console.log(result.data);
    setStores(result.data);
  };
  useEffect(() => {
    getStoresList();
  }, []);

  return (
    <nav className="navbar jarvisBar">
      <div className="dropdown chooseStorePurple">
        <button
          className="btn  dropdown-toggle storeButton"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {store.storeName ? store.storeName : "Choose store"}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {stores.map((storeItem, index) => (
            <button
              key={index}
              className="dropdown-item"
              onClick={() => {
                setStore(storeItem);
                fetchOrders
                  ? fetchOrders(storeItem)
                  : console.log("Not ScheduleOrdersManually");
              }}
            >
              {storeItem.storeName}
            </button>
          ))}
        </div>
      </div>
      <span
        className="navbar-text"
        style={{
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, 0)"
        }}
      >
        {store.storeName ? <h2>{store.storeName.toUpperCase()}</h2> : ""}
      </span>
    </nav>
  );
};

export default NavBar;

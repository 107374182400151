import React, { useEffect, useState, Fragment } from "react";
import NavBar from "../res/NavBar";
import Axios from "axios";
import { updateCustomerAddress } from "../functions/updateCustomerAddress";
import { copyShippingAddress } from "../functions/copyShippingAddress";
// import UpdateProduct from "../res/UpdateProduct";

const CustomerAddressForm = () => {
  document.title = "Customer Address Form";
  const params = new URLSearchParams(window.location.search);
  const orderID = encodeURIComponent(params.get("orderID"));
  const store = encodeURIComponent(params.get("store"));

  const fetchCustomerAddress = () => {
    Axios.get(`/api/operations/address?orderID=${orderID}&store=${store}`)
      .then(response => {
        console.log(response.data);
        setAddress(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchCustomerAddress();
  }, []);
  const [address, setAddress] = useState("");
  console.log("address = ", address);
  return (
    <Fragment>
      {address ? (
        <div className="container-fluid">
          <br />
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <div className="card text-center">
              <div className="card-header">
                <h4>Update Customer's Address</h4>
              </div>
              <div className="card-body">
                <div id="msg"></div>
                <form
                  className="form-horizontal"
                  name="customerAddressForm"
                  id="customerAddressForm"
                >
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="panel panel-primary">
                        <div className="panel-heading">
                          Customer's Billing Address
                        </div>
                        <div className="panel-body">
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_fname"
                            >
                              First Name:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_fname"
                                name="b_fname"
                                defaultValue={
                                  address.customerBillingAddress.first_name
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_lname"
                            >
                              Last Name:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_lname"
                                name="b_lname"
                                defaultValue={
                                  address.customerBillingAddress.last_name
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_email"
                            >
                              Email:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_email"
                                name="b_email"
                                defaultValue={
                                  address.customerBillingAddress.email
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_company"
                            >
                              Company:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_company"
                                name="b_company"
                                defaultValue={
                                  address.customerBillingAddress.company
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_address1"
                            >
                              Address1:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_address1"
                                name="b_address1"
                                defaultValue={
                                  address.customerBillingAddress.address1
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_address2"
                            >
                              Address2:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_address2"
                                name="b_address2"
                                defaultValue={
                                  address.customerBillingAddress.address2
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_phone"
                            >
                              Phone:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_phone"
                                name="b_phone"
                                defaultValue={
                                  address.customerBillingAddress.phone
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_city"
                            >
                              City:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_city"
                                name="b_city"
                                defaultValue={
                                  address.customerBillingAddress.city
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="b_zip"
                            >
                              Zip:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="b_zip"
                                name="b_zip"
                                defaultValue={
                                  address.customerBillingAddress.zip
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/*aaa  */}
                        {/* {address.productNames.map((product, i) => (
                          <UpdateProduct product={product} inc={i} />
                        ))} */}
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <i
                        className="fa fa-arrow-left copy-arrow"
                        aria-hidden="true"
                        onClick={e => copyShippingAddress()}
                      ></i>
                    </div>

                    <div className="col-sm-5">
                      <div className="panel panel-primary">
                        <div className="panel-heading">
                          Customer's Shipping Address
                        </div>
                        <div className="panel-body">
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_fname"
                            >
                              First Name:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_fname"
                                name="s_fname"
                                defaultValue={
                                  address.customerShippingAddress.first_name
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_lname"
                            >
                              Last Name:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_lname"
                                name="s_lname"
                                defaultValue={
                                  address.customerShippingAddress.last_name
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_email"
                            >
                              Email:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_email"
                                name="s_email"
                                defaultValue={
                                  address.customerShippingAddress.email
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_company"
                            >
                              Company:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_company"
                                name="s_company"
                                defaultValue={
                                  address.customerShippingAddress.company
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_address1"
                            >
                              Address1:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_address1"
                                name="s_address1"
                                defaultValue={
                                  address.customerShippingAddress.address1
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_address2"
                            >
                              Address2:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_address2"
                                name="s_address2"
                                defaultValue={
                                  address.customerShippingAddress.address2
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_phone"
                            >
                              Phone:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_phone"
                                name="s_phone"
                                defaultValue={
                                  address.customerShippingAddress.phone
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_city"
                            >
                              City:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_city"
                                name="s_city"
                                defaultValue={
                                  address.customerShippingAddress.city
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group form-head">
                            <label
                              className="control-label col-sm-3"
                              htmlFor="s_zip"
                            >
                              Zip:
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="s_zip"
                                name="s_zip"
                                defaultValue={
                                  address.customerShippingAddress.zip
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={e =>
                      updateCustomerAddress(
                        e,
                        orderID,
                        store,
                        address.productNames.length
                      )
                    }
                  >
                    Update Customer's Address
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default CustomerAddressForm;

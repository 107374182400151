import React, { useState, Fragment } from "react";
import LeadProduct from "../res/LeadProduct";
import Modal from "./Modal";
import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";
import resolveStatus from "../../../trackingboard/OrderTracker/utils/resolveStatus";
import { Link } from "react-router-dom";
import CallBackModal from "./CallBackModal";

function LeadOrderItem({ order, user, id, setCurrentCount, callMadeFunction }) {
  const [showModal, setShowModal] = useState(false);
  const [showOrder, setShowOrder] = useState(true);
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const callBack = async (submit) => {
    if (submit) {
      let date = document.getElementById(
        `datePicker-${order.name}-${order.store.storeName}`
      ).value;
      let comment = document.getElementById(
        `comments-${order.name}-${order.store.storeName}`
      ).value;
      if (!date) return alert("Please choose a date and time!");

      if (!comment) comment = "No comments";

      let result = await Axios.post("/api/support/lead/handleCallBack", {
        date,
        comment,
        order: order.name,
        user: {
          name: user.name,
        },
      });

      if (result.data.msg === "Handled CallBack") {
        alert("CallBack set!");
        setShowModal(false);

        document.getElementById(`cb-button-${order.name}`).innerHTML =
          "Submitted Callback!";
        document.getElementById(`cb-button-${order.name}`).disabled = true;
      }
    } else {
      setShowModal(true);
    }
  };

  const handleSelection = (value) => {
    if (value.includes("Order confirmed")) setAction("schedule");
    else if (value.includes("Other Cases") || value.includes("Order cancelled"))
      setAction("reason");
    else setAction("");
  };
  const submitOrder = async () => {
    document.getElementById(`order-button_${id}`).disabled = true;
    document.getElementById(`order-button_${id}`).innerHTML = "Submitting...";

    let remarks = document.getElementById(`reason-selector_${order.name}`)
      .value;
    if (remarks.includes("Choose an option")) {
      document.getElementById(`order-button_${id}`).disabled = false;
      document.getElementById(`order-button_${id}`).innerHTML = "Submit";
      return alert("Please select an option");
    }

    let data = {
      id: order.name,
      remarks,
      user_id: user.user_id,
      priorityCall: order.priorityCall ? true : false,
    };
    if (remarks.includes("Other Cases")) {
      let speacialReason = document.getElementById("speacial-reason").value;
      if (speacialReason) data.note = `Other Cases - ${speacialReason}`;
      else {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Enter A Reason");
      }
    } else if (remarks.includes("Order cancelled")) {
      let speacialReason = document.getElementById("speacial-reason").value;
      if (speacialReason) data.note = `Order cancelled - ${speacialReason}`;
      else {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Enter A Reason");
      }
    }

    let result = await Axios.post("/api/support/lead/updateOrderRemarks", data);
    document.getElementById(`order-button_${id}`).disabled = false;
    document.getElementById(`order-button_${id}`).innerHTML = "Submitted!";

    if (remarks.includes("Order confirmed")) {
      let address1 = document.getElementById(`address1_${id}`).value;
      let address2 = document.getElementById(`address2_${id}`).value;
      let company = document.getElementById(`company_${id}`).value;
      let city = document.getElementById(`city_${id}`).value;
      let zip = document.getElementById(`zip_${id}`).value;
      let province = document.getElementById(`province_${id}`).value;

      // if (
      //   address1 === order.shipping_address.address1 &&
      //   address2 === order.shipping_address.address2 &&
      //   company === order.shipping_address.company &&
      //   city === order.shipping_address.city &&
      //   zip === order.shipping_address.zip &&
      //   province === order.shipping_address.province
      // )
      //   alert("Haven't Changed The Existing Address..");
      const address = {
        address1,
        address2,
        company,
        city,
        zip,
        province,
      };
      // console.log("address",address);
      let data = {
        id: order.name,
        user_id: user.user_id,
        address,
        store: order.store,
      };
      if (confirmation) {
        const result = await Axios.post(
          "/api/support/lead/updateAddress",
          data
        );
        const scheduleResult = Axios.post("/api/support/lead/schedule", data);
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submitted!";
      } else {
        alert("Not Scheduling");
      }
    }
    // setShowOrder(false);
    callMadeFunction(order.name);
    setCurrentCount((current) => current + 1);
  };
  return showOrder ? (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div className="order-item-date" style={{ background: "#2980b9" }}>
          {new Date(order.created_at).toDateString()}
        </div>
      </div>

      <div className="order-item">
        <div
          className="order-header"
          style={{
            borderColor: "#2980b9",
            background: "#3398DB",
            color: "white",
          }}
        >
          <div
            className={`store-name order-header-item`}
            style={{ background: "#2980b9" }}
            onClick={() => setShow(!show)}
          >
            {order.store.storeName}
          </div>
          <div
            className="order-name order-header-item "
            style={{ background: "#3498db" }}
          >
            {order.name}
          </div>
        </div>

        <div className="order-history">
          {order.callAssignedTo && user.master ? (
            <div className="caller-info">
              {" "}
              <center>{`${order.callAssignedTo} `}</center>
            </div>
          ) : (
            ""
          )}
          {order.remarks ? (
            <div className={`order-info`}>
              <center>{`Previously, ${order.remarks} - called ${
                order.count ? order.count : "1"
              } times`}</center>
            </div>
          ) : (
            ""
          )}
        </div>
        {order.callBack ? (
          <div className="order-history-priority">
            {order.callBack.comment} (Call back at,{" "}
            {new Date(order.callBack.date).toLocaleString()})
          </div>
        ) : (
          ""
        )}

        <div className={`order-details`}>
          <ul className="customer-details">
            <li>
              <center>
                <strong>
                  {order.customer.name ? order.customer.name : "No Name Found"}-
                  {order.customer.phone
                    ? order.customer.phone
                    : "No Phone Found"}
                  -
                  {order.customer.email
                    ? order.customer.email
                    : "No Email Found"}
                </strong>
              </center>
            </li>
          </ul>
        </div>
        <div className={`products-section`}>
          {order.line_items.map((product) => (
            <LeadProduct
              product={product}
              key={product.product_id + order.name}
              id={product.product_id + order.name}
              order={order}
            />
          ))}
        </div>
        {action === "schedule" ? (
          <Modal address={order.customer.shipping_address} id={id} />
        ) : action === "reason" ? (
          <div style={{ padding: "20px" }}>
            <input
              className="reason-input"
              type="text"
              placeholder="Please Enter The Reason"
              aria-label="Search"
              id="speacial-reason"
            />
          </div>
        ) : (
          ""
        )}
        <div className="callingboard-toolbar">
          <button
            id={`cb-button-${order.name}`}
            className="callback-button"
            onClick={() => callBack()}
          >
            Call Back
          </button>
          <span
            id={`serviceability-span-${order.name}`}
            style={{
              visibility: "visible",
              fontWeight: "bold",
            }}
          ></span>
        </div>
        {order.callAssignedTo === user.name || user.master ? (
          <div className="order-footer">
            <select
              className="reason-selector"
              id={`reason-selector_${order.name}`}
              onChange={(e) => handleSelection(e.target.value)}
            >
              <option selected>Choose an option</option>
              <option value="Order confirmed and address updated">
                Order confirmed or address to be updated
              </option>
              <option value="Order cancelled">Order cancelled</option>
              <option value="Not picking up calls">Not picking up calls</option>
              <option value="Call disconnected by customer">
                Call disconnected by customer
              </option>
              <option value="Switched off">Switched off</option>
              <option value="No incoming calls or out of order">
                No incoming calls or out of order
              </option>
              <option value="Number busy">Number busy</option>
              <option value="Other Cases">Other Cases</option>
            </select>
            <button
              id={`order-button_${id}`}
              className="order-button"
              onClick={() => submitOrder()}
            >
              {action === "schedule" ? "Schedule" : "Submit"}
            </button>
          </div>
        ) : (
          <div
            className="order-footer"
            style={{
              padding: "15px",
              background: "#079991",
              color: "white",
            }}
          >
            <ul style={{ listStyleType: "none", marginBottom: "0px" }}>
              {order.reattempt ? (
                <li>{`You can schedule this order in the ${order.reattempt}`}</li>
              ) : (
                ""
              )}
              {order.callAssignedTo !== user.name ? (
                <li>
                  {order.callAssignedTo !== undefined &&
                  order.callAssignedTo !== ""
                    ? `Call Assigned To ${order.callAssignedTo}`
                    : "Not Assigned To Anyone"}
                </li>
              ) : (
                ""
              )}
              {order.cancelledOrder ? (
                <li>Order Was Cancelled At The Calling Dashboard</li>
              ) : (
                ""
              )}
              {order.manifestedLeadOrder ? (
                <li>Order Has Been Scheduled(Abandoned)</li>
              ) : (
                ""
              )}
              {order.pincodeNotServiceable ? (
                <li>Pincode Not Serviceable</li>
              ) : (
                ""
              )}
              {order.shipmentCancelled ? (
                <li>Order Has Been Cancelled At The Operations Board</li>
              ) : (
                ""
              )}
            </ul>
          </div>
        )}
      </div>
      {showModal ? (
        <CallBackModal
          order={order}
          setShowModal={setShowModal}
          callBack={callBack}
        />
      ) : (
        ""
      )}
    </Fragment>
  ) : (
    ""
  );
}

export default LeadOrderItem;

import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { GlobalConsumer } from "../../Global-Context/global-context";

const Logout = () => {
  const context = useContext(GlobalConsumer);
  context.signOut();
  if (!context.isAuthenticated) return <Redirect to="/login" />;
  return <div>Logout</div>;
};

export default Logout;

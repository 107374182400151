import React, { useState, Fragment } from "react";
import Product from "../res/Product";
import Modal from "./Modal";
import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";
import { Link } from "react-router-dom";
import NDRModal from "./NDRModal";
import moment from "moment";
import CallBackModal from "../res/CallBackModal";
import useOrderModal from "../../../components/global/OrderDetails";

function NDROrderItem({ order, user, id, setCurrentCount, callMadeFunction }) {
  const [showModal, setShowModal] = useState(false);
  const [OrderModal] = useOrderModal();
  const [showOrder, setShowOrder] = useState(true);

  let ndrCallMadeOn = moment(order.ndrCallMadeOn);
  let currentDate = moment();

  const callDifference = currentDate.diff(ndrCallMadeOn, "hours");

  order.scheduledThrough = order.scheduledThrough
    ? order.scheduledThrough
    : "ShipRocket";
  const [action, setAction] = useState("");

  const handleFocusDate = () => {
    document.getElementById("datePickerTo").type = "date";
  };
  const handleBlurDate = () => {
    document.getElementById("datePickerTo").type = "text";
  };

  const AddressIssue = order.callCustomer ? " Address Issue." : "";
  const CCOutOfStock = order.callConfirmationOutOfStock
    ? " Call Confirmation For Out Of Stock."
    : "";
  const CallConfirmation = order.productConfirmation
    ? "Product Confirmation."
    : "";
  const repeatCustomer = order.repeatCustomer ? " Repeat Customer." : "";
  const abandonedCheckout = order.abandonedCheckout
    ? "Abandoned Checkout."
    : "";
  const multipleItems = order.multipleItems ? " Multiple Items." : "";
  const multipleQuantity = order.multipleQuantity ? " Multiple Quantity." : "";
  const ndr = order.ndr ? "NDR." : "";

  const reasons =
    multipleQuantity +
    multipleItems +
    repeatCustomer +
    CallConfirmation +
    AddressIssue +
    CCOutOfStock +
    abandonedCheckout;

  const called = order.ndrResolved ? true : false;

  const handleSelection = (value) => {
    setAction(value);
  };

  const callBack = async (submit) => {
    if (submit) {
      let date = document.getElementById(
        `datePicker-${order.name}-${order.store.storeName}`
      ).value;
      let comment = document.getElementById(
        `comments-${order.name}-${order.store.storeName}`
      ).value;
      let store = order.store;
      if (!date) return alert("Please choose a date and time!");

      if (!comment) comment = "No comments";
      console.log(date, comment, store, order.name);

      let result = await Axios.post("/api/support/handleCallBack", {
        date,
        comment,
        store,
        order: order.name,
        user: {
          name: user.name,
        },
      });

      if (result.data.msg === "Handled CallBack") {
        alert("CallBack set!");
        setShowModal(false);
        console.log(
          "DD",
          document.getElementById(`cb-button-NDR-${order.name}`).innerHTML
        );
        document.getElementById(`cb-button-NDR-${order.name}`).innerHTML =
          "Submitted Callback!";
        document.getElementById(`cb-button-NDR-${order.name}`).disabled = true;
      }
    } else {
      setShowModal(true);
    }
  };

  const submitOrder = async () => {
    // IF DIFFERENT USER - THEN STOP

    if (document.getElementById(`order-button_${id}`)) {
      document.getElementById(`order-button_${id}`).disabled = true;
      document.getElementById(`order-button_${id}`).innerHTML = "Submitting...";
    }

    let remarks = document.getElementById(`reason-selector_${order.name}`)
      .value;
    let fakeAttempt = document.getElementById(`fake-attempt-box_${order.name}`)
      .checked;
    console.log(remarks);
    if (remarks.includes("Choose an option")) {
      document.getElementById(`order-button_${id}`).disabled = false;
      document.getElementById(`order-button_${id}`).innerHTML = "Submit";
      return alert("Please select an option");
    }

    let data = {
      name: order.name,
      option: remarks,
      user_id: user.user_id,
      store: order.store,
      fakeAttempt,
      priorityCall: order.priorityCall ? true : false,
    };

    if (remarks.includes("Edit Details")) {
      let address1 = document.getElementById(`address1_${id}`).value;
      let address2 = document.getElementById(`address2_${id}`).value;
      let company = document.getElementById(`company_${id}`).value;
      let city = document.getElementById(`city_${id}`).value;
      let zip = document.getElementById(`zip_${id}`).value;
      let province = document.getElementById(`province_${id}`).value;
      let name = document.getElementById(`name_${id}`).value;
      let phone = document.getElementById(`phone_${id}`).value;
      let date =
        document.getElementById("datePickerTo").value !== ""
          ? document.getElementById("datePickerTo").value
          : moment().add(1, "day");
      let reasonToReattempt = document.getElementById("reasonToReattempt")
        .value;
      if (!reasonToReattempt) {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Enter Reason To Reattempt");
      }
      console.log(reasonToReattempt);

      const address = {
        address1,
        address2,
        company,
        city,
        zip,
        province,
      };

      if (confirmation) {
        let address =
          address1 +
          " " +
          address2 +
          " " +
          company +
          " " +
          city +
          " " +
          zip +
          " " +
          province;

        if (order.scheduledThrough !== "ShipRocket") {
          if (order.scheduledThrough === "ShipYaari") {
            data.address = `${name}(${phone}), ${address1}, ${address2}, ${company}, ${city}, ${province} - ${zip}`;
          } else {
            data.address = {
              address,
              name,
              phone,
            };
          }
        } else
          data.address = {
            address1,
            address2: address2 + " " + city + " " + zip,
            name,
            phone,
          };
        data.reasonToReattempt = reasonToReattempt;
        data.deferred_date = date;
      }
    } else if (
      remarks.includes("Postpone Delivery") ||
      remarks === "Re-attempt"
    ) {
      if (!document.getElementById("datePickerTo").value) {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Choose A Date");
      }

      let deferred_date = document.getElementById("datePickerTo").value;
      let reasonToReattempt = document.getElementById("reasonToReattempt")
        .value;
      if (!reasonToReattempt) {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Enter Reason To Reattempt");
      }
      console.log(reasonToReattempt);
      data.reasonToReattempt = reasonToReattempt;
      data.deferred_date = deferred_date;
    } else if (remarks.includes("Cancelled")) {
      if (!document.getElementById("speacial-reason").value) {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Mention A Reason");
      }

      let reason = document.getElementById("speacial-reason").value;

      data.reason = reason;
    }
    console.log(`Final `, data);

    let result = await Axios.post("/api/support/handleNDR_v2", data);

    if (document.getElementById(`order-button_${id}`)) {
      document.getElementById(`order-button_${id}`).disabled = false;
      document.getElementById(`order-button_${id}`).innerHTML = "Submitted!";
    }
    // setShowOrder(false);
    callMadeFunction(order.name);
    setCurrentCount((current) => current + 1);
  };

  const order_info = {
    width: "100%",
  };

  // REVERT AFTER EXPERIMENT
  return showOrder ? (
    <Fragment>
      <div
        className="order-item-date"
        style={{ background: "#FFC107", color: "black", fontWeight: "bolder" }}
      >
        {order.scheduledThrough ? order.scheduledThrough : "ShipRocket"}
      </div>
      <div style={{ display: "inline-block" }}>
        <div className="order-item-date">
          Shopify, {new Date(order.created_at).toDateString()}
        </div>
        {order.sentToSupportOn ? (
          <div
            className="order-item-date"
            style={{ background: "#e9ecef", color: "#1289a7" }}
          >
            Dashboard, {new Date(order.sentToSupportOn).toDateString()}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="order-item-date"
        style={{ background: "white", color: "black" }}
      >
        {order.ndrCount ? order.ndrCount : ""}
      </div>
      <div className="order-item " id={id}>
        <div
          className="order-header order-header-ndr"
          style={{ position: "relative" }}
        >
          <div className="store-name  order-header-item store-name-ndr">
            {order.store.storeName}
          </div>
          <div className="order-name  order-header-item order-name-ndr">
            {order.name}

            <span className="payment_status_new">
              {order.financial_status === "paid" ? "(PREPAID)" : "(COD)"}
            </span>
          </div>
          <OrderModal
            order={order}
            position={{ top: "0px", left: "180px" }}
            cursor={{ color: "white" }}
          />
          <div className="order-issues order-header-item ">NDR</div>
        </div>

        {order.description ? (
          <div className="order-history" style={{ flexDirection: "column" }}>
            {order.assigned_to ? (
              <div className="caller-info">
                {" "}
                <center>{`${order.assigned_to.name} `}</center>
              </div>
            ) : (
              ""
            )}

            <div className="order-info" style={!user.master ? order_info : {}}>
              <center>{` ${order.description} `}</center>
            </div>
          </div>
        ) : (
          ""
        )}
        {order.callBack ? (
          <div className="order-history-priority">
            {order.callBack.comment} (Call back at,{" "}
            {new Date(order.callBack.date).toLocaleString()})
          </div>
        ) : (
          ""
        )}
        <div className="order-details">
          <ul className="customer-details">
            <li>
              <center>
                <strong>
                  {order.shipping_address
                    ? order.shipping_address.first_name !== ""
                      ? order.shipping_address.first_name
                      : order.shipping_address.last_name
                    : "No Details"}
                  -
                  {order.shipping_address
                    ? order.shipping_address.phone
                      ? order.shipping_address.phone
                      : order.billing_address.phone
                      ? order.billing_address.phone
                      : "No Phone Number Found"
                    : "No phone Number Found"}
                  -
                  {order.email
                    ? order.email
                    : order.customer.email
                    ? order.customer.email
                    : "No Email Found"}
                </strong>
              </center>
            </li>
          </ul>
        </div>
        <div className="products-section">
          {order.line_items.map((product) => (
            <Product
              product={product}
              key={product.product_id + order.name}
              id={product.product_id + order.name}
              order={order}
              user={user}
            />
          ))}
        </div>
        {action === "Edit Details And Reattempt" ? (
          <Fragment>
            <NDRModal address={order.shipping_address} id={id} order={order} />
            <div style={{ padding: "20px" }}>
              <input
                className="form-control datepicker "
                placeholder="To"
                type="text"
                onFocus={() => handleFocusDate()}
                onBlur={() => handleBlurDate()}
                id="datePickerTo"
                style={{ height: "50px", borderRadius: 0, width: "100%" }}
              />
              <br />
              <input
                className="reason-input"
                placeholder="Reason"
                type="text"
                id="reasonToReattempt"
                style={{ height: "50px", borderRadius: 0, width: "100%" }}
              />
            </div>
          </Fragment>
        ) : action === "Postpone Delivery" || action === "Re-attempt" ? (
          <div style={{ padding: "20px" }}>
            <input
              className="form-control datepicker "
              placeholder="To"
              type="text"
              onFocus={() => handleFocusDate()}
              onBlur={() => handleBlurDate()}
              id="datePickerTo"
              style={{ height: "50px", borderRadius: 0, width: "100%" }}
            />
            <br />
            <input
              className="reason-input"
              placeholder="Reason"
              type="text"
              id="reasonToReattempt"
              style={{ height: "50px", borderRadius: 0, width: "100%" }}
            />
          </div>
        ) : action === "Cancelled" ? (
          <div style={{ padding: "20px" }}>
            <input
              className="reason-input"
              type="text"
              placeholder="Please Enter The Reason"
              aria-label="Search"
              id="speacial-reason"
            />
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            width: "100%",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id={`fake-attempt-box_${order.name}`}
            />
            <label className="form-check-label" htmlFor="inlineCheckbox1">
              Fake Attempt?
            </label>
          </div>
          {/* <button
            id={`cb-button-NDR-${order.name}`}
            className="callback-button"
            onClick={() => callBack()}
          >
            Call Back
          </button> */}
        </div>
        <div style={{ padding: "20px" }}>
          <b>PLEASE CHOOSE THE DISPOSITION HERE FOR NDR CALLS</b>
        </div>
        {!called ? (
          <div className="order-footer">
            {/* UNCOMMENT FOR NDR */}
            <select
              className="reason-selector"
              id={`reason-selector_${order.name}`}
              onChange={(e) => handleSelection(e.target.value)}
            >
              <option selected>Choose an option</option>
              <option>Switched Off</option>
              <option>Phone Not Answered</option>
              <option>Number Busy</option>
              <option>Self Pickup</option>
              <option>Call Disconnected By Customer</option>
              <option>Not Reachable</option>
              {order.scheduledThrough === "ShipRocket" ? (
                <option>Auto Re-attempt By Shiprocket</option>
              ) : (
                ""
              )}
              <option>Customer In Contact With Delivery Guy</option>
              <option>Fake Attempt</option>
              <option>Postpone Delivery</option>
              <option>Edit Details And Reattempt</option>
              <option>Re-attempt</option>
              <option>Language Issue</option>
              <option>Initiate RTO</option>
              <option>Delivered</option>
              <option>Cancelled</option>
            </select>
            <button
              id={`order-button_${id}`}
              className="order-button"
              onClick={() => submitOrder()}
            >
              Submit
            </button>
          </div>
        ) : (
          <div
            className="order-footer"
            style={{
              padding: "15px",
              background: "#079991",
              color: "white",
            }}
          >
            <ul style={{ listStyleType: "none", marginBottom: "0px" }}>
              <li>Do Not Schedule This Order.</li>

              {order.callAssignedTo !== user.name ? (
                <li>
                  {order.callAssignedTo !== undefined &&
                  order.callAssignedTo !== ""
                    ? `Call Assigned To ${order.callAssignedTo}`
                    : "Not Assigned To Anyone"}
                </li>
              ) : (
                ""
              )}
              {order.generatedBySystem ? (
                <li>Order Is Already Scheduled</li>
              ) : (
                ""
              )}
              {order.cancelledOrder ? (
                <li>Order Was Cancelled At The Calling Dashboard</li>
              ) : (
                ""
              )}
              {order.outOfStock ? <li>Items Are Out Of Stocl</li> : ""}
              {order.notifyOperations ? (
                <li>Order Is On Operations Board</li>
              ) : (
                ""
              )}
              {order.remarksForOrderRemoval ? (
                <li>Order Has Been Cancelled</li>
              ) : (
                ""
              )}
              {order.manifestedAbandonedOrder ? (
                <li>Order Has Been Scheduled(Abandoned)</li>
              ) : (
                ""
              )}
              {order.pincodeNotServiceable ? (
                <li>Pincode Not Serviceable</li>
              ) : (
                ""
              )}
              {order.shipmentCancelled ? (
                <li>Order Has Been Cancelled At The Operations Board</li>
              ) : (
                ""
              )}
            </ul>
          </div>
        )}
      </div>
      {showModal ? (
        <CallBackModal
          order={order}
          setShowModal={setShowModal}
          callBack={callBack}
        />
      ) : (
        ""
      )}
    </Fragment>
  ) : (
    ""
  );
}

{
  /* <div
          style={{
            padding: "10px",
            fontWeight: "600",
            fontStyle: "italic",
            textAlign: "center",
          }}
        >
          {order.uploadedToDialer &&
            "This order has been uploaded to the dialer, Please contact the Devs"}
        </div> */
}

export default NDROrderItem;

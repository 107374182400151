import React, { Fragment } from "react";
import { submitImage } from "../functions/submitImage";

const DamagedOrder = ({ store, order, fetchOrders }) => {
  return (
    <Fragment>
      <tr>
        <td>{order.name}</td>
        <td>{order.awb_ref_no}</td>
        <td>
          {order.damagedLineItems.map((product, i) => (
            // console.log(product, " +  ", i)

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "20px"
              }}
              key={i}
            >
              <div>
                {product.name} X {product.quantity}
              </div>
              <div>
                <form id={order.name + "_" + product.variant_id}>
                  <input
                    type="file"
                    name="damagedProductImage"
                    id={`damagedProductImage_${order.name}_${product.variant_id}`}
                  />

                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={e =>
                      submitImage(
                        e,
                        "damagedProductImage" +
                          "_" +
                          order.name +
                          "_" +
                          product.variant_id,
                        store,
                        order.name,
                        product.product_id,
                        product.variant_id,
                        fetchOrders
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          ))}
        </td>
      </tr>
    </Fragment>
  );
};

export default DamagedOrder;

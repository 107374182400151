import axios from "axios";

export const login = async (email, password) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });
  // console.log("Initiated Login", body);

  try {
    const res = await axios.post("api/auth/login", body, config);

    window.accessList = res.data.access;

    return res.data;
  } catch (error) {
    const errors = error.response.data.msg;
    console.log("Failed to authenticate", errors);
    alert(errors);
  }
};

import React from "react";
import { updateState } from "../../functions/updateState";

const Order = ({
  order: { supplier, transporter, id, status, products, total, date },
  fetchOrders
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <span className="badge">{id.toUpperCase()}</span>
      </div>
      <div className="card-body">
        <div style={{ display: "flex" }}>
          <h5>Supplier: </h5>
          <p className="card-text">{supplier}</p>
        </div>
        <div style={{ display: "flex" }}>
          <h5>Transporter: </h5>
          <p className="card-text">{transporter}</p>
        </div>
        <br />
        <h5>Purchased Products: </h5>

        <div className="itemList">
          {products.map((product, i) => (
            <li key={i}>
              {product.name} X {product.quantity} @ Rs.{product.price}
            </li>
          ))}
        </div>
        <br />
        <div style={{ display: "flex" }}>
          <h5>Total amount paid: </h5>
          <p className="card-text">Rs. {total}</p>
        </div>
        <br />
      </div>
      <div className="card-footer text-muted">
        <div className="statusContainer">
          <div className="statusBox" style={{ fontWeight: "bold" }}>
            {status}
          </div>

          <button
            type="button"
            className="btn updateButton"
            style={{ width: "30%" }}
            onClick={() => updateState(status, id, fetchOrders, products)}
          >
            Update
          </button>
        </div>
        <div
          style={{
            width: "100%",
            padding: ".75rem 1.25rem "
          }}
        >
          Ordered on: {new Date(date).toDateString()}
        </div>
      </div>
    </div>
  );
};

export default Order;

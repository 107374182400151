import React, { useState, Fragment, useEffect, useContext } from "react";
import Axios from "axios";
import TagBar from "../../../trackingboard/OrderTracker/components/Items/TagBar";

function AddAggregator({ user, setShowEntry }) {
  const [master, setMaster] = useState(true);
  const [products, setProducts] = useState([]);
  const [filterd, setFiltered] = useState([]);
  const [fakeDimensions, setFakeDimensions] = useState(false);

  const fetchProducts = async () => {
    let result = await Axios.get(
      `/api/product/getAllFavourites?user=${user.user_id}`
    );
    console.log(result.data.products);

    setFiltered(result.data.products);
    setProducts(result.data.products);
  };

  const submit = async () => {
    let name = document.getElementById("agg-name").value;
    let numberOfOrders = document.getElementById("numberOfOrders")
      ? document.getElementById("numberOfOrders").value
      : "";
    let courierID = document.getElementById("courierID").value
      ? document.getElementById("courierID").value
      : "";

    let courierName = document.getElementById("courierName").value
      ? document.getElementById("courierName").value
      : "";

    let productList = global.selected;
    // ? global.selected.map(item => item.product_id)
    // : [];
    // console.log("TR", global.selected);
    console.log("Fake dimensions : ", fakeDimensions);

    let data = {
      numberOfOrders,
      productList,
      name,
      master,
      courierID,
      courierName,
      fakeDimensions,
    };

    let result = await Axios.post("/api/control/addAggregator", data);
    setShowEntry(true);
  };

  const multiCheck = (e, value) => {
    if (value) {
      let value2send = document.getElementById("product-filter")
        ? document.getElementById("product-filter").value
        : "";

      value2send = JSON.parse(value2send);
      console.log("RR", value2send);

      if (value2send) {
        global.selected.push(value2send);

        document.getElementById("product-filter").value = "Choose Product";

        let productList = global.selected.map((item) => item.product_id);

        let newArray = filterd.filter((product) => {
          return !productList.includes(product.product_id);
        });

        console.log(newArray);
        setFiltered(newArray);
      } else {
        alert("Choose a product");
      }
    }
  };

  const handleDelete = (item) => {
    let index = global.selected.findIndex(
      (globalItem) => globalItem.product_id === item.product_id
    );

    setFiltered([item, ...filterd]);

    if (index > -1) {
      global.selected.splice(index, 1);
    }
  };

  const handleFakeDimensionsToggle = () => {
    setFakeDimensions((fakeDimensions) => !fakeDimensions);
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <div className="aggregator-add">
      {/* <label for="exampleInputEmail1">Name</label> */}
      <input
        type="text"
        className="form-control button-height"
        id="agg-name"
        placeholder="Name"
        style={{ marginBottom: "20px" }}
      />
      <input
        type="number"
        className="form-control button-height"
        id="courierID"
        placeholder="Courier Company ID?"
      />
      <input
        type="text"
        className="form-control button-height"
        id="courierName"
        placeholder="Courier Account Name?"
      />
      {!master ? (
        <button
          className="close-button"
          onClick={() => {
            global.selected = [];
            setFiltered(products);
            setMaster(true);
          }}
        >
          <i className="fas fa-times"></i> Remove Conditions
        </button>
      ) : (
        <button className="add-button" onClick={() => setMaster(false)}>
          <i className="fas fa-plus"></i> Add Conditions
        </button>
      )}
      {master ? (
        ""
      ) : (
        <div style={{ marginTop: "20px" }}>
          {/* <label for="NumberOfOrdersPerDay">Number Of Orders Per Day</label> */}
          <input
            type="number"
            className="form-control button-height"
            id="numberOfOrders"
            placeholder="Number Of Orders Per Day"
          />
          <br />

          <div>
            {/* <label for="NumberOfOrdersPerDay">Choose a product</label> */}
            <select
              className="form-control button-height"
              id="product-filter"
              placeholder="Choose Product"
              onChange={(e) => {
                multiCheck(e, e.target.value);
              }}
            >
              <option value="Choose Product" selected disabled>
                Choose Product
              </option>
              {filterd
                ? filterd.map((product) => (
                    <option
                      value={JSON.stringify({
                        product_id: product.product_id,
                        title: product.title,
                      })}
                    >{`${product.title}`}</option>
                  ))
                : ""}
            </select>
          </div>
          <div style={{ display: "flex", margin: "20px 0px" }}>
            {global.selected
              ? global.selected.map((item, i) => (
                  <div className="itemTag" id={`tag_${item.product_id}`}>
                    {item.title}
                    <div className="cross" onClick={() => handleDelete(item)}>
                      x
                    </div>{" "}
                  </div>
                ))
              : ""}
          </div>
          {!user.customer ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <label style={{ flex: "1" }}>
                Substitute fake dimensions (Fake_Dimensions)
              </label>
              <label className="custom-toggle">
                <input
                  id="fake-dimensions-toggle"
                  type="checkbox"
                  onClick={handleFakeDimensionsToggle}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  style={{ color: "#000" }}
                />
              </label>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <button
        className="add-button"
        style={{ marginTop: "-10px" }}
        onClick={() => submit()}
      >
        Submit
      </button>
    </div>
  );
}

export default AddAggregator;

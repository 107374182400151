import React, { useEffect, useState } from "react";
import Axios from "axios";
import img from "../../../assets/ripple.gif";
import useAlert from "../../../components/global/Alert";

export default function GetLabels() {
  const [processing, setProcessing] = useState([]);
  const [clients, setClients] = useState(null);
  const [ours, setOurs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [AlertBox, setNotification] = useAlert();

  document.title = "Print Labels/Manifests";

  const getLabels = async () => {
    // textToSpeech(`loading dashboard, please wait for some time`);
    setLoading(true);
    const result = await Axios.get("/api/warehouse/getLabels");
    setProcessing(result.data.processing ? result.data.processing : []);
    setClients(result.data.clients);
    setOurs(result.data.ours);
    setLoading(false);
    // textToSpeech(
    //   `loaded dashboard successfully, you can proceed to obtain labels and manifests`
    // );
  };

  const generateLabels = async (aggregator, type, stores) => {
    setProcessing((current) => [...current, aggregator]);
    if (processing.includes(aggregator))
      return alert("Please wait for labels to be generated");
    switch (aggregator) {
      case "ShipRocket":
      case "ShipRocket Two":
        await getLabelsShipRocket(stores, aggregator);
        break;
      case "ClickPost - BlueDart":
        await getLabelsClickPost(stores, aggregator);
        break;
      case "ClickPost - ShadowFax":
        await getLabelsClickPost(stores, aggregator);
        break;
      case "GoSwift":
        await generateLabelsGoSwift(stores, aggregator);
        break;
      case "ShipYaari":
        await getLabelsShipyaari(stores, aggregator);
        break;
      case "ShadowFax":
        await getLabelsShadowFax(stores, aggregator);
        break;
      default:
        alert("Please contact devs for integration of new partner");
    }
    getLabels();
  };

  const getLabelsShipRocket = async (storesToConsider, aggregator) => {
    await Axios.post(`/api/warehouse/generateManifestsAndLabelsV4`, {
      storesToConsider,
      aggregator,
    });
    alert("Labels Generated successfully, check in View Labels");
  };

  const generateLabelsGoSwift = async (storesToConsider) => {
    await Axios.post(`/api/goswift/warehouse/generateBulkLabelsForGoSwift`, {
      storesToConsider,
    });

    alert("Labels Generated successfully, check in View Labels");
  };

  const getLabelsClickPost = async (storesToConsider, aggregator) => {
    try {
      let result = await Axios.post(
        `/api/warehouse/generateAllManifestsAndLabelsforClickPost`,
        {
          storesToConsider,
          aggregator,
        },
        {
          responseType: "blob",
        }
      );

      const file = new Blob([result.data], {
        type: "application/pdf",
      });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      alert("Labels Generated successfully, check in View Labels");
      window.open(fileURL, "1");
      window.open(
        "https://bazingamedia.xyz/warehouse/labelsAndManifests_V3",
        "2"
      );
    } catch (error) {
      if (error.message !== "Request failed with status code 504")
        alert("Something went wrong, contact developers");
    }
  };

  const getLabelsShipyaari = async (storesToConsider) => {
    const { data } = await Axios.post(
      `/api/shipyaari/warehouse/generateBulkLabelsAndManifestsShipyaari`,
      {
        storesToConsider,
      }
    );
    alert(data.message);
  };

  // const getLabelsShadowFax = async (storesToConsider) => {
  //   try {
  //     const result = await Axios.post(
  //       `/api/shadowfax/warehouse/generateBulkLabelsAndManifestsShadowfax`,
  //       {
  //         storesToConsider,
  //       }
  //     );
  //     console.log("react = ", result.data.awbs);
  //     if (result.data.awbs.length > 0) {
  //       navigator.clipboard.writeText(result.data.awbs.toString());
  //       setNotification(
  //         "success",
  //         `Awbs have been mailed to your mail(prasad@bazingamedia.in), please copy awbs and paste in shadowfax direct account or you can directly press Ctrl+V in shadowfax direct account`,
  //         true
  //       );
  //     } else setNotification("failure", `Nothing to display`, true);
  //   } catch (error) {
  //     setNotification("failure", error.message, true);
  //   }
  // };

  const getLabelsShadowFax = async (storesToConsider) => {
    const { data } = await Axios.post(
      `/api/shadowfax/warehouse/generateBulkLabelsAndManifestsShadowfax`,
      {
        storesToConsider,
      }
    );
    alert(data.message);
  };

  useEffect(() => {
    getLabels();
  }, []);

  return !loading ? (
    <div className="label-division">
      <AlertBox />
      <div className="label-container">
        <div style={{ fontWeight: "bold" }}>BAZINGA MEDIA</div>
        {ours
          ? Object.keys(ours).map((aggregator) =>
              aggregator !== "stores" ? (
                ours[aggregator] !== 0 ? (
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      className="label-button"
                      style={
                        processing.includes(aggregator)
                          ? { background: "#34495e", cursor: "default" }
                          : {}
                      }
                      onClick={() =>
                        generateLabels(aggregator, "ours", ours["stores"])
                      }
                    >
                      <div>{aggregator}</div>

                      <div>
                        {!processing.includes(aggregator)
                          ? `${ours[aggregator]} orders`
                          : `Processing ${ours[aggregator]} orders, Please wait and refresh....`}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            )
          : "NO ORDERS FROM BAZINGA MEDIA"}
      </div>
      <div className="label-container">
        <div style={{ fontWeight: "bold" }}>CLIENTS</div>
        {clients
          ? Object.keys(clients).map((aggregator) =>
              aggregator !== "stores" ? (
                clients[aggregator] !== 0 ? (
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      className="label-button"
                      style={
                        processing.includes(aggregator)
                          ? { background: "#34495e", cursor: "default" }
                          : {}
                      }
                      onClick={() =>
                        generateLabels(aggregator, "clients", clients["stores"])
                      }
                    >
                      <div>{aggregator}</div>

                      <div>
                        {!processing.includes(aggregator)
                          ? `${clients[aggregator]} orders`
                          : `Processing ${clients[aggregator]} orders, Please wait and refresh....`}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            )
          : "NO ORDERS FROM CLIENTS"}
      </div>
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={img}
        alt="Loading..."
        style={{
          marginTop: "30vh",
        }}
      />
      <center>FETCHING COUNT...</center>
    </div>
  );
}

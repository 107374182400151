import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Cryptr from "cryptr";
import uuidv1 from "uuid/v1";

import useDatePicker from "../../global/DatePicker";
import SmsFilterTags from "./SmsFilterTags";
import ConfirmationPage from "./ConfirmationPage";
import useProductPicker from "../../global/ProductPicker";
import Spinner from "../../global/Spinner";

const SmsPopulateData = ({ setCurrentTab }) => {
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [size, setSize] = useState(null);
  const [temporaryCampaignId, setTemporaryCampaignId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [
    ProductPicker,
    ProductDisplay,
    products,
    setProducts,
  ] = useProductPicker();

  const getUserDetails = async () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));
  };

  const getStoresList = async () => {
    let result = await axios.get("/api/stores");
    setStores(result.data);
  };

  const addStore = (e) => {
    const store = JSON.parse(e.target.value);
    setSelectedStores([...selectedStores, store]);
    const newStoresList = stores.filter(
      (storeItem) => !(storeItem.storeAnchor === store.storeAnchor)
    );
    setStores(newStoresList);
    e.target.value = 0;
  };

  const removeStore = (store) => {
    const newSelectedStores = selectedStores.filter(
      (selectedStore) => !(selectedStore.storeAnchor === store.storeAnchor)
    );
    setSelectedStores(newSelectedStores);
    setStores([store, ...stores]);
  };

  const handleReset = () => {
    getStoresList();
    setSelectedStores([]);
    setProducts([]);
    clearDate();
    document.getElementById("deliveredCheckBox").checked = false;
    document.getElementById("price").value = "";
  };

  const handleSubmit = async () => {
    setLoading(true);
    const delivered = document.getElementById("deliveredCheckBox").checked;
    const price = document.getElementById("price").value;
    const payload = {
      stores: selectedStores,
      products,
      delivered,
      fromDate,
      toDate,
      price,
    };
    console.log("imp = ", payload);
    const temporaryId = uuidv1();
    setTemporaryCampaignId(temporaryId);
    const { data } = await axios.get(
      `/api/smsMobi/populatingData/${temporaryId}`,
      {
        params: payload,
      }
    );
    if (!data.status) {
      setLoading(false);
      alert(data.message);
      return;
    }
    setLoading(false);
    setSize(data.size);
    setShowConfirmationPage(true);
  };

  useEffect(() => {
    getStoresList();
    getUserDetails();
  }, []);

  return (
    <Fragment>
      {!showConfirmationPage ? (
        <div className="sms-marketing-tabs">
          <div className="sms-populate-data-top-header">
            Please choose filters and click on submit to get info about the data
            that can be populated
          </div>
          <div className="sms-marketing-filter-container-flexbox">
            <select
              className="form-control form-control-sm sms-marketing-filter"
              onChange={addStore}
            >
              <option value={0}>Choose store(s)</option>
              {stores.map((store) => (
                <option value={JSON.stringify(store)}>{store.storeName}</option>
              ))}
            </select>
            <ProductPicker />
            <DatePicker />
            <input
              id="price"
              type="number"
              placeholder="Enter Price Criteria"
            />
          </div>
          <div>
            <div className="sms-marketing-populating-data-filter-tags-container">
              {selectedStores.map((store) => (
                <SmsFilterTags item={store} removeFilter={removeStore} />
              ))}
            </div>
            <ProductDisplay />
          </div>
          <div>
            <fieldset>
              <input
                id="deliveredCheckBox"
                type="checkbox"
                style={{ marginRight: "0.5rem" }}
              ></input>
              <label htmlFor="deliveredCheckBox">Delivered ordered only</label>
            </fieldset>

            <div className="sms-campaign-final-buttons">
              <button
                onClick={handleReset}
                className="sms-campaign-populate-button"
              >
                Reset
              </button>
              <button
                onClick={handleSubmit}
                className="sms-campaign-populate-button"
              >
                Preview Data
              </button>
              <div className="spinner-container">
                {loading ? <Spinner /> : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ConfirmationPage
          temporaryCampaignId={temporaryCampaignId}
          size={size}
          setShowConfirmationPage={setShowConfirmationPage}
          setCurrentTab={setCurrentTab}
        />
      )}
    </Fragment>
  );
};

export default SmsPopulateData;

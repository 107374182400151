import React, { createContext } from "react";
import axios from "axios";
import Cryptr from "cryptr";

//Creating context of available global states
const GlobalContext = createContext({
  user: false,
  access: [],
  isAuthenticated: false,
  authenticate: () => {},
  signOut: () => {},
  boot: () => {},
  getUser: () => {},
  getAccess: () => {}
});

export class GlobalProvider extends React.Component {
  getUser = () => {
    if (localStorage.info) {
      let info = JSON.parse(localStorage.info);
      const cryptr = new Cryptr("myTotalySecretKey");
      let user = JSON.parse(cryptr.decrypt(info));

      // this.setState({ isAuthenticated: true });
      return user;
    } else return false;
  };

  signOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("info");
    axios.defaults.headers.common["Authorization"] = null;
    this.setState({ user: {} });
    this.setState({ isAuthenticated: false });
  };
  authenticate = data => {
    if (data !== "") {
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("info", JSON.stringify(data.details));
      axios.defaults.headers.common["Authorization"] = data.user;
      const cryptr = new Cryptr("myTotalySecretKey");
      let user = JSON.parse(cryptr.decrypt(data.details));
      this.setState({ user });
      this.setState({ isAuthenticated: true });
    }
  };
  getAccess = async () => {
    let res = await axios.get("/api/auth/access", {
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.user)
      }
    });
    console.log("global", res.data.access);
    this.setState({ access: res.data.access });
    return res.data.access;
  };
  //State variables
  state = {
    user: false,
    isAuthenticated: false,
    authenticate: this.authenticate,
    signOut: this.signOut,
    access: [],
    boot: this.boot,
    getUser: this.getUser,
    getAccess: this.getAccess
  };

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

//Registering a consumer who can access the global state
export const GlobalConsumer = GlobalContext;

import React, { useState, Fragment } from "react";
import Product from "../res/Product";
import Modal from "./Modal";
import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";
import resolveStatus from "../../../trackingboard/OrderTracker/utils/resolveStatus";
import useOrderModal from "../../../components/global/OrderDetails";
import CallBackModal from "./CallBackModal";

function OrderItem({
  order,
  user,
  getOrders,
  current,
  setCurrentCount,
  type,
  id,
  callMadeFunction,
}) {
  const [acceptance, setAcceptance] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOrder, setShowOrder] = useState(true);

  const [action, setAction] = useState("");
  const [total_price, setTotal_price] = useState(order.total_price);
  // const [amount, setAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddress, setShowAddress] = useState(false);

  const [OrderModal] = useOrderModal();

  const AddressIssue = order.callCustomer ? " Address Issue." : "";
  const CCOutOfStock = order.callConfirmationOutOfStock
    ? " Call Confirmation For Out Of Stock."
    : "";
  const CallConfirmation = order.productConfirmation
    ? " Product Confirmation."
    : "";
  const repeatCustomer = order.repeatCustomer ? " Repeat Customer." : "";
  const abandonedCheckout = order.abandonedCheckout
    ? " Abandoned Checkout."
    : "";
  const multipleItems = order.multipleItems ? " Multiple Items." : "";
  const multipleQuantity = order.multipleQuantity ? " Multiple Quantity." : "";
  const ndr = order.ndr ? " NDR." : "";
  const upSell = order.upSellOrder ? " Upsell." : "";

  const reasons =
    multipleQuantity +
    multipleItems +
    repeatCustomer +
    CallConfirmation +
    AddressIssue +
    CCOutOfStock +
    abandonedCheckout +
    ndr +
    upSell;

  const called =
    order.generatedBySystem ||
    order.cancelledOrder ||
    order.shipmentCancelled ||
    order.outOfStock ||
    order.notifyOperations ||
    order.remarksForOrderRemoval ||
    order.reasonForOrderCancellation ||
    order.manifestedAbandonedOrder ||
    order.pincodeNotServiceable
      ? true
      : false;

  const handleSelection = (value) => {
    if (value.includes("Order confirmed")) setAction("schedule");
    else if (value.includes("Other Cases") || value.includes("Order cancelled"))
      setAction("reason");
    else setAction("");
  };

  const getAcceptance = async () => {
    let temp = {
      style: {
        width: "30%",
        color: "white",
        background: "#1189A7",
      },
      percentage: "",
      event: "",
    };

    if (!acceptance) {
      setLoading(true);
      let result = await Axios.post(
        "/api/orders/retrieveAcceptanceRateOfACustomer",
        { name: order.name, store: order.store }
      );
      if (result.data.status) {
        setLoading(false);

        temp["style"]["background"] = result.data.acceptanceRate
          ? parseFloat(result.data.acceptanceRate) > 60
            ? "#1189A7"
            : "#e74c3c"
          : "#e74c3c";
        temp["percentage"] = result.data.acceptanceRate.toString();
        temp["event"] = result.data.previousOrderStatus;

        setAcceptance({ ...acceptance, ...temp });
      }
    }
  };

  const converToPrepaid = async (orderName, store) => {
    if (!confirmation()) return;
    let amount = prompt("Enter the amount paid by the customer");

    if (!amount) {
      alert("please enter an amount to proceed!");
      return;
    }
    if (parseFloat(amount) > total_price)
      return alert("Entered amount cannot be greater than total price!");
    if (parseFloat(amount) === 0) {
      alert("Amount collected cannot be 0!");
      return;
    }

    const zip = document.getElementById(`zip_${id}`)
      ? document.getElementById(`zip_${id}`).value
      : order.shipping_address.zip;

    let result = await Axios.post("/api/orders/transformCODtoPrepaid", {
      orderName,
      store,
      partialAmountRecieved: amount,
      user: {
        id: user.user_id,
        name: user.name,
        amount,
      },
      zip,
    });

    if (!result.data.status) {
      alert(result.data.message);
      return;
    } else {
      alert(result.data.message);
      if (total_price === 0) return;
      setTotal_price(result.data.balanceAmount);
    }
  };

  const makeCall = (order) => {
    if (order.priorityCall) return true;
    if (!order.reattempt) return true;
    if (order.uploadedToDialer) return true;

    // I think in the below part of this function Adith is trying to determine if the order can be handled based on the reattempt time of the order
    // but would not that have already been handled while seqarching for all the orders? possibly because in dialer mode they search for the order
    let scheduledTime = order.reattempt;
    const indiaTime = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    let currentTime = new Date(indiaTime).getHours();
    let makeCall;

    if (
      scheduledTime === "afternoon" &&
      currentTime > 13 &&
      currentTime <= 20
    ) {
      makeCall = true;
    } else if (
      scheduledTime === "morning" &&
      currentTime >= 8 &&
      currentTime <= 13
    ) {
      makeCall = true;
    } else makeCall = false;

    return makeCall;
  };

  const submitOrder = async () => {
    document.getElementById(`order-button_${id}`).disabled = true;
    document.getElementById(`order-button_${id}`).innerHTML = "Submitting...";

    let remarks = document.getElementById(
      `reason-selector_${order.name}_${order.customer.id}`
    ).value;

    // commenting this piece of code so that orders can be handled from calling dashboard as well even though they are uploaded to dialer
    // if (order.uploadedToDialer && !remarks.includes("Order cancelled"))
    //   return alert(
    //     "This order seems to be uploaded to the dialer, please check with the developers"
    //   );

    // IF DIFFERENT USER AND NOT A CANCELLATION REQUEST - THEN STOP
    if (
      order.callAssignedTo !== user.name &&
      !remarks.includes("Order cancelled")
    )
      return alert(`PLEASE ASK ${order.callAssignedTo} TO HANDLE THIS CALL`);

    if (remarks.includes("Choose an option")) {
      document.getElementById(`order-button_${id}`).disabled = false;
      document.getElementById(`order-button_${id}`).innerHTML = "Submit";
      return alert("Please select an option");
    }

    let data = {
      id: order.name,
      remarks,
      user_id: user.user_id,
      user_name: user.name,
      store: order.store,
      priorityCall: order.priorityCall ? true : false,
    };
    if (remarks.includes("Other Cases")) {
      let speacialReason = document.getElementById("speacial-reason").value;
      if (speacialReason) data.note = `Other Cases - ${speacialReason}`;
      else {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Enter A Reason");
      }
    } else if (remarks.includes("Order cancelled")) {
      let speacialReason = document.getElementById("speacial-reason").value;
      if (speacialReason) data.note = `Order cancelled - ${speacialReason}`;
      else {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Enter A Reason");
      }
    }

    await Axios.post("/api/support/updateOrderRemarks2", data);

    document.getElementById(`order-button_${id}`).disabled = false;
    document.getElementById(`order-button_${id}`).innerHTML = "Submitted!";

    if (remarks.includes("Order confirmed")) {
      let address1 = document.getElementById(`address1_${id}`).value;
      let address2 = document.getElementById(`address2_${id}`).value;
      let company = document.getElementById(`company_${id}`).value;
      let city = document.getElementById(`city_${id}`).value;
      let zip = document.getElementById(`zip_${id}`).value;
      let province = document.getElementById(`province_${id}`).value;

      if (
        address1 === order.shipping_address.address1 &&
        address2 === order.shipping_address.address2 &&
        company === order.shipping_address.company &&
        city === order.shipping_address.city &&
        zip === order.shipping_address.zip &&
        province === order.shipping_address.province
      )
        alert("Haven't Changed The Existing Address..");
      const address = {
        address1,
        address2,
        company,
        city,
        zip,
        province,
      };

      let data = {
        id: order.name,
        user_id: user.user_id,
        user_name: user.name,
        store: order.store,
        address,
      };

      if (confirmation) {
        // await Axios.post("/api/support/updateAddress", data);
        await Axios.post("/api/support/schedule", data);

        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submitted!";
      } else {
        alert("Not Scheduling");
      }
    }
    // setShowOrder(false);
    callMadeFunction(order.name);
    setCurrentCount((current) => current + 1);
  };

  const updateAddress = async () => {
    let remarks = document.getElementById(
      `reason-selector_${order.name}_${order.customer.id}`
    ).value;

    if (remarks.includes("Order confirmed")) {
      let address1 = document.getElementById(`address1_${id}`).value;
      let address2 = document.getElementById(`address2_${id}`).value;
      let company = document.getElementById(`company_${id}`).value;
      let city = document.getElementById(`city_${id}`).value;
      let zip = document.getElementById(`zip_${id}`).value;
      let province = document.getElementById(`province_${id}`).value;

      if (
        address1 === order.shipping_address.address1 &&
        address2 === order.shipping_address.address2 &&
        company === order.shipping_address.company &&
        city === order.shipping_address.city &&
        zip === order.shipping_address.zip &&
        province === order.shipping_address.province
      )
        alert("Haven't Changed The Existing Address..");
      const address = {
        address1,
        address2,
        company,
        city,
        zip,
        province,
      };

      let data = {
        id: order.name,
        user_id: user.user_id,
        user_name: user.name,
        store: order.store,
        address,
      };

      if (confirmation) {
        await Axios.post("/api/support/updateAddress", data);
        alert("Address Updated");
      } else {
        alert("Not Scheduling");
      }
    } else alert("Please choose order confirmed and address updated option");
  };

  const order_info = {
    width: "100%",
  };

  const callBack = async (submit) => {
    if (submit) {
      let date = document.getElementById(
        `datePicker-${order.name}-${order.store.storeName}`
      ).value;
      let comment = document.getElementById(
        `comments-${order.name}-${order.store.storeName}`
      ).value;
      let store = order.store;
      if (!date) return alert("Please choose a date and time!");

      if (!comment) comment = "No comments";

      let result = await Axios.post("/api/support/handleCallBack", {
        date,
        comment,
        store,
        order: order.name,
        user: {
          name: user.name,
        },
      });

      if (result.data.msg === "Handled CallBack") {
        alert("CallBack set!");
        setShowModal(false);

        document.getElementById(`cb-button-${order.name}`).innerHTML =
          "Submitted Callback!";
        document.getElementById(`cb-button-${order.name}`).disabled = true;
      }
    } else {
      setShowModal(true);
    }
  };

  const transerCall = async (name, store) => {
    if (confirmation()) {
      let result = await Axios.post(
        "/api/support/forwardOrderFromCallingToOps",
        {
          name,
          store,
        }
      );

      alert(result.data.msg);
      getOrders(user, type, current, true);
    }
  };

  const checkPincodeServiceability = async (name, store) => {
    const { user_id, name: user_name } = user;
    const zip = document.getElementById(`zip_${id}`)
      ? document.getElementById(`zip_${id}`).value
      : order.shipping_address.zip;
    const serviceabilityButton = document.getElementById(
      `check-serviceability-button-${name}`
    );
    serviceabilityButton.disabled = true;
    serviceabilityButton.style.cursor = "not-allowed";
    let result = await Axios.get("/api/orders/orderServiceabilityCheck", {
      params: {
        zip,
        name,
        store,
        user_id,
        user_name,
      },
    });

    if (result.data.prepaid_serviceable || result.data.cod_serviceable) {
      let resultString = `COD : ${
        result.data.cod_serviceable ? "YES" : "NO"
      } || PREPAID : ${result.data.prepaid_serviceable ? "YES" : "NO"}`;
      let spanElement = document.getElementById(`serviceability-span-${name}`);
      spanElement.innerHTML = resultString;
      spanElement.style.visibility = "visible";
      setTimeout(() => {
        spanElement.innerHTML = "";
        serviceabilityButton.disabled = false;
        serviceabilityButton.style.cursor = "pointer";
      }, 4000);
    } else {
      let resultString = `PINCODE NOT SERVICEABLE`;
      let spanElement = document.getElementById(`serviceability-span-${name}`);
      spanElement.innerHTML = resultString;
      spanElement.style.visibility = "visible";
      setTimeout(() => {
        spanElement.innerHTML = "";
        serviceabilityButton.disabled = false;
        serviceabilityButton.style.cursor = "pointer";
      }, 4000);
    }
  };

  const fetchLatestProductPrice = async () => {
    const productArray = order.line_items.map((lineItem) => ({
      pid: lineItem.product_id,
      vid: lineItem.variant_id,
    }));

    const payload = {
      productArray,
      store: order.store.shopifyName,
    };
    const { data } = await Axios.post(
      "/api/support/fetchLatestProductPrice",
      payload
    );

    let priceString = "";
    order.line_items.map((lineItem) => {
      const pid = lineItem.product_id;
      const productPriceObject = data.find((priceObj) => priceObj.pid === pid);
      priceString += `${lineItem.presentment_title} - ${productPriceObject.price}\n`;
    });

    let spanElement = document.getElementById(
      `serviceability-span-${order.name}`
    );
    spanElement.innerHTML = priceString;
    spanElement.style.visibility = "visible";
    setTimeout(() => {
      spanElement.innerHTML = "";
    }, 4000);
  };

  return showOrder ? (
    <Fragment>
      <div style={{ display: "flex" }} onMouseEnter={() => getAcceptance()}>
        <div>
          <div className="order-item-date">
            Shopify, {new Date(order.created_at).toDateString()}
          </div>
          {order.sentToSupportOn ? (
            <div
              className="order-item-date"
              style={{ background: "#e9ecef", color: "#1289a7" }}
            >
              Dashboard, {new Date(order.sentToSupportOn).toDateString()}
            </div>
          ) : (
            ""
          )}
        </div>

        <button
          className="transfer-button"
          onClick={() => transerCall(order.name, order.store)}
        >
          Transfer to Operations
          <i
            className="far fa-arrow-alt-circle-right"
            style={{ margin: "0 10px" }}
          ></i>
        </button>

        <div
          className="acceptance-button"
          style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
        >
          {loading ? (
            <div className="acceptance-info">
              {loading ? "Getting Acceptance Rate..." : ""}
            </div>
          ) : (
            ""
          )}
          {acceptance.event ? (
            <div className="acceptance-info">{acceptance.event}</div>
          ) : (
            ""
          )}

          {acceptance.percentage ? (
            <div className="acceptance-info" style={acceptance.style}>
              {`${acceptance.percentage.toString()}`}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="order-item" id={id} onMouseEnter={() => getAcceptance()}>
        <div className="order-header" style={{ position: "relative" }}>
          <div
            className={`store-name order-header-item ${
              !makeCall(order) ? "order-header-item-deferred" : ""
            }`}
            onClick={() => setShow(!show)}
          >
            {order.store.storeName}
          </div>
          <div className="order-name order-header-item ">
            {order.name}
            {/* </Link> */}
            <span className="payment_status_new">
              {order.financial_status === "paid" ? "(PREPAID)" : "(COD)"}
            </span>
          </div>
          <div className="order-issues order-header-item ">{reasons}</div>{" "}
          <OrderModal
            order={order}
            position={{ top: "0px", left: "180px" }}
            cursor={{ color: "white" }}
          />
        </div>

        <div className="order-history">
          {order.callAssignedTo ? (
            <div className="caller-info">
              {" "}
              <center>{`${order.callAssignedTo} `}</center>
            </div>
          ) : (
            ""
          )}
          {order.remarks ? (
            <div
              className={`order-info ${
                !makeCall(order) && !show ? "hide-content" : ""
              }`}
              style={!user.master ? order_info : {}}
            >
              <center>{`Previously, ${order.remarks} - called ${
                order.count ? order.count : "1"
              } times`}</center>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            padding: "10px",
            fontWeight: "600",
            fontStyle: "italic",
            textAlign: "center",
          }}
        >
          {order.uploadedToDialer &&
            "This order has been uploaded to the dialer, Please contact the Devs"}
        </div>
        {order.callBack ? (
          <div className="order-history-priority">
            {order.callBack.comment} (Call back at,{" "}
            {new Date(order.callBack.date).toLocaleString()})
          </div>
        ) : (
          ""
        )}

        <div
          className={`order-details ${
            !makeCall(order) && !show ? "hide-content" : ""
          }`}
        >
          <ul className="customer-details">
            <li>
              <center>
                <strong>
                  {order.shipping_address
                    ? order.shipping_address.first_name !== ""
                      ? order.shipping_address.first_name
                      : order.shipping_address.last_name
                    : "No Details"}
                  -
                  {order.shipping_address
                    ? order.shipping_address.phone
                      ? order.shipping_address.phone
                      : order.billing_address.phone
                      ? order.billing_address.phone
                      : "No Phone Number Found"
                    : "No phone Number Found"}
                  -
                  {order.email
                    ? order.email
                    : order.customer.email
                    ? order.customer.email
                    : "No Email Found"}
                </strong>
              </center>
            </li>
          </ul>
        </div>
        <div
          className={`products-section ${
            !makeCall(order) && !show ? "hide-content" : ""
          }`}
        >
          {order.line_items.map((product) => (
            <Product
              product={product}
              key={product.product_id + order.name}
              id={product.product_id + order.name}
              order={order}
              user={user}
            />
          ))}
        </div>
        <div
          className={!makeCall(order) && !show ? "hide-content" : ""}
          style={{ padding: "0px 20px", fontWeight: "600" }}
        >
          Total Amount: {total_price}
        </div>

        {action !== "schedule" ? (
          <button
            id={`check-serviceability-button`}
            className="callback-button"
            style={{ marginLeft: "20px" }}
            onClick={() => setShowAddress(!showAddress)}
          >
            {showAddress ? "Hide Address" : "Show Address"}
          </button>
        ) : (
          ""
        )}

        {action !== "schedule" && showAddress ? (
          <Modal address={order.shipping_address} id={id} disabled={true} />
        ) : (
          ""
        )}
        {action === "schedule" ? (
          <Modal address={order.shipping_address} id={id} disabled={false} />
        ) : action === "reason" ? (
          <div style={{ padding: "20px" }}>
            <input
              className="reason-input"
              type="text"
              placeholder="Please Enter The Reason"
              aria-label="Search"
              id="speacial-reason"
            />
          </div>
        ) : (
          ""
        )}
        <div
          className={
            !makeCall(order) && !show ? "hide-content" : "callingboard-toolbar"
          }
        >
          <button
            id={`cb-button-${order.name}`}
            className="callback-button"
            onClick={() => callBack()}
          >
            Call Back
          </button>
          <button
            id={`check-serviceability-button-${order.name}`}
            className="callback-button"
            onClick={() => checkPincodeServiceability(order.name, order.store)}
          >
            Serviceability
          </button>

          {order.abandonedCheckout ? (
            <button
              id={`check-price-button`}
              className="callback-button"
              onClick={() => fetchLatestProductPrice()}
            >
              Latest Price
            </button>
          ) : null}

          <button
            id={`check-serviceability-button`}
            className="callback-button"
            onClick={() => converToPrepaid(order.name, order.store)}
            style={order.abandonedCheckout ? { background: "#2c3e50" } : {}}
          >
            Convert To Prepaid
          </button>

          <span
            id={`serviceability-span-${order.name}`}
            style={{
              visibility: "visible",
              fontWeight: "bold",
              paddingTop: "1rem",
            }}
          ></span>
        </div>
        {(!called && makeCall(order)) || (user.master && !called) ? (
          <div className="order-footer">
            <select
              className="reason-selector"
              id={`reason-selector_${order.name}_${order.customer.id}`}
              onChange={(e) => handleSelection(e.target.value)}
            >
              <option selected>Choose an option</option>
              <option value="Order confirmed and address updated">
                Order confirmed or address to be updated
              </option>
              <option value="Order cancelled">Order cancelled</option>
              <option value="Not picking up calls">Not picking up calls</option>
              <option value="Call disconnected by customer">
                Call disconnected by customer
              </option>
              <option value="Switched off">Switched off</option>
              <option value="No incoming calls or out of order">
                No incoming calls or out of order
              </option>
              <option value="Number busy">Number busy</option>
              <option value="Other Cases">Other Cases</option>
            </select>
            <button
              style={{
                background: "#e74c3c",
                color: "white",
                padding: "0px 50px",
                width: "50%",
              }}
              id={`order-button-address_${id}`}
              className="order-button-address"
              onClick={() => updateAddress()}
            >
              UPDATE ADDRESS
            </button>
            <button
              id={`order-button_${id}`}
              className="order-button"
              onClick={() => submitOrder()}
            >
              {action === "schedule" ? "Schedule" : "Submit"}
            </button>
          </div>
        ) : (
          <div
            className="order-footer"
            style={{
              padding: "15px",
              background: "#079991",
              color: "white",
            }}
          >
            <ul style={{ listStyleType: "none", marginBottom: "0px" }}>
              {order.reattempt ? (
                <li>{`You can schedule this order in the ${order.reattempt}`}</li>
              ) : (
                ""
              )}
              {order.callAssignedTo !== user.name ? (
                <li>
                  {order.callAssignedTo !== undefined &&
                  order.callAssignedTo !== ""
                    ? `Call Assigned To ${order.callAssignedTo}`
                    : "Not Assigned To Anyone"}
                </li>
              ) : (
                ""
              )}
              {order.status_code ? resolveStatus(order.status_code) : ""}

              {order.generatedBySystem ? (
                <li>Order Is Already Scheduled</li>
              ) : (
                ""
              )}
              {order.cancelledOrder ? (
                <li>Order Was Cancelled At The Calling Dashboard</li>
              ) : (
                ""
              )}
              {order.outOfStock ? <li>Items Are Out Of Stocl</li> : ""}
              {order.notifyOperations ? (
                <li>Order Is On Operations Board</li>
              ) : (
                ""
              )}
              {order.remarksForOrderRemoval ? (
                <li>Order Has Been Cancelled</li>
              ) : (
                ""
              )}
              {order.manifestedAbandonedOrder ? (
                <li>
                  Order Should Have Been Scheduled(Abandoned) - Transfer To Ops
                  If On Calling Dashboard
                </li>
              ) : (
                ""
              )}
              {order.pincodeNotServiceable ? (
                <li>Pincode Not Serviceable</li>
              ) : (
                ""
              )}
              {order.shipmentCancelled ? (
                <li>Order Has Been Cancelled At The Operations Board</li>
              ) : (
                ""
              )}
            </ul>
          </div>
        )}
      </div>
      {showModal ? (
        <CallBackModal
          order={order}
          setShowModal={setShowModal}
          callBack={callBack}
        />
      ) : (
        ""
      )}
    </Fragment>
  ) : (
    ""
  );
}

export default OrderItem;

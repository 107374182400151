import React, { useState, useEffect } from "react";
import Axios from "axios";

const NavBar = ({ store, setStore, getStatus }) => {
  const [stores, setStores] = useState([]);

  const getStoresList = async () => {
    let result = await Axios.get("/api/stores");

    // SHINE X PRO STORE ONLY
    result.data = result.data.filter(
      (store) => store.shopifyName === "shinexpro"
    );

    setStores(result.data);
  };
  useEffect(() => {
    getStoresList();
  }, []);

  return (
    <nav className="navbar jarvisBar">
      <div className="dropdown chooseStorePurple">
        <button
          className="btn  dropdown-toggle storeButton"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {store ? store.storeName : "Choose store"}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {stores.map((store, index) => (
            <button
              key={index}
              className="dropdown-item"
              onClick={() => {
                setStore(store);
                getStatus(store);
              }}
            >
              {store.storeName}
            </button>
          ))}
        </div>
      </div>
      <span
        className="navbar-text"
        style={{
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        Configurations Panel
      </span>
    </nav>
  );
};

export default NavBar;

import React from "react";

const DialerInboundItem = ({ phone, log }) => {
  return (
    <div className="dialer-inbound-log-data">
      <div>{phone}</div>
      <div>
        {log.map((log) => {
          return (
            <div className="dialer-inbound-log-subdata">
              <div>{log.call_date}</div>
              <div>{log.lead_id}</div>
              <div>{log.campaign_id}</div>
              <div>{log.length_in_sec}</div>
              <div>{log.status}</div>
              <div>{log.user}</div>
              <div>
                {log.type === "in" ? (
                  <span>
                    <i
                      className="fas fa-arrow-left"
                      style={{ color: "#2980b9" }}
                    ></i>
                    {`    ${log.type}`}
                  </span>
                ) : (
                  <span>
                    <i
                      className="fas fa-arrow-right"
                      style={{ color: "#27ae60" }}
                    ></i>
                    {`    ${log.type}`}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DialerInboundItem;

import React, { useRef, useState, useEffect } from "react";
import axios from "axios";

export default function useStoreButton({
  ignoreDeleted = false,
  multipleMode = false,
}) {
  const dropdownRef = useRef(null);
  const [stores, setStores] = useState([]);
  const [selected, setSelected] = useState([]);

  const getStores = async () => {
    let result = await axios.get(`/api/stores?ignoreDeleted=${ignoreDeleted}`);
    setStores(result.data);
  };

  const constructStoreResult = (e) => {
    const value = JSON.parse(e.target.value);
    if (multipleMode) {
      if (
        value &&
        !selected.find((item) => item.shopifyName === value.shopifyName)
      )
        setSelected((selected) => [...selected, value]);
    } else {
      setSelected(value);
    }
  };

  const removeStore = (storeAnchor) => {
    setSelected((array) =>
      array.filter((item) => item.storeAnchor !== storeAnchor)
    );
  };

  useEffect(() => {
    getStores();
  }, []);

  const StoreDisplay = ({ customTagBarStyle }) => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          ...customTagBarStyle,
        }}
      >
        {multipleMode &&
          selected.map((store) =>
            store ? (
              <div
                className="itemTag"
                style={{ width: "fit-content" }}
                id={`tag_${store}`}
              >
                {store.storeName}
                <div
                  className="cross"
                  onClick={() => removeStore(store.storeAnchor)}
                >
                  x
                </div>{" "}
              </div>
            ) : (
              ""
            )
          )}
      </div>
    );
  };

  const StoreButton = ({ customStoreStyle }) => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          ...customStoreStyle,
        }}
      >
        <select
          placeholder="Stores"
          id="storeList"
          className="form-control form-control-sm defaultInput"
          value={selected}
          ref={dropdownRef}
          onChange={(e) => constructStoreResult(e)}
        >
          <option value={null} selected>
            Choose a Store
          </option>
          {stores.map((store) => (
            <option value={JSON.stringify(store)}>{store.storeName}</option>
          ))}
        </select>
      </div>
    );
  };

  return [StoreButton, StoreDisplay, selected, setSelected];
}

import React, { Fragment } from "react";
import { fakeProductDimension } from "../functions/productDimension";

const FakeVariantForm = ({ formId, variant, store, fetchProducts }) => {
  return (
    <Fragment>
      <form name={formId} id={formId}>
        <div className="container">
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <div id={`${formId}_msg`} />
            <div className="row">
              <div className="col-xs-1 col-sm-1 col-md-1" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_fakeLength`}>Fake Length:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_fakeLength`}
                    name="fakeLength"
                    defaultValue={variant.fakeLength ? variant.fakeLength : ""}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_fakeWidth`}>Fake Width:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_fakeWidth`}
                    name="fakeWidth"
                    defaultValue={variant.fakeWidth ? variant.fakeWidth : ""}
                  />
                </div>
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1" />
            </div>
            <div className="row">
              <div className="col-xs-1 col-sm-1 col-md-1" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_fakeHeight`}>Fake Height:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_fakeHeight`}
                    name="fakeHeight"
                    defaultValue={variant.fakeHeight ? variant.fakeHeight : ""}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_fakeActualWeight`}>Fake AW:</label>
                  &nbsp;
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_fakeActualWeight`}
                    name="fakeActualWeight"
                    defaultValue={
                      variant.fakeActualWeight ? variant.fakeActualWeight : ""
                    }
                  />
                </div>
              </div>
              <div className="col-xs-1 col-sm-1" />
            </div>
            <div className="row">
              <div className="col-xs-1 col-sm-1 col-md-1" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_fakeVW`}>Fake VoluW:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_fakeVW`}
                    name="fakeVW"
                    disabled
                    value={
                      variant.fakeLength &&
                      variant.fakeWidth &&
                      variant.fakeHeight
                        ? (
                            (variant.fakeLength *
                              variant.fakeWidth *
                              variant.fakeHeight) /
                            5000
                          ).toFixed(2)
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_fakePW`}>Fake PW:&nbsp;</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_fakePW`}
                    name="fakePW"
                    disabled
                    value={
                      variant.fakeLength &&
                      variant.fakeWidth &&
                      variant.fakeHeight &&
                      (
                        (variant.fakeLength *
                          variant.fakeWidth *
                          variant.fakeHeight) /
                        5000
                      ).toFixed(3) > variant.fakeActualWeight
                        ? (
                            (variant.fakeLength *
                              variant.fakeWidth *
                              variant.fakeHeight) /
                            5000
                          ).toFixed(2)
                        : variant.fakeActualWeight
                    }
                  />
                </div>
              </div>
              <div className="col-xs-1 col-sm-1" />
            </div>
            <div className="row">
              <div className="col-xs-1 col-sm-1" />
              <div className="col-xs-10 col-sm-10 col-md-10">
                <div className="input-group input-group-md mb-3">
                  <button
                    type="submit"
                    className="form-control btn btn-default btn-md"
                    onClick={e =>
                      fakeProductDimension(e, formId, store, fetchProducts)
                    }
                    style={{ backgroundColor: "#ff7f50", color: "white" }}
                  >
                    <i className="fas fa-check" />
                    Add/Update Fake Dimensions
                  </button>
                </div>
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1" />
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default FakeVariantForm;

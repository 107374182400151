import React, { useState, Fragment } from "react";

function CourierModal({
  orders,
  selected,
  surfaceOrders,
  setSelectedCouriers,
  setCourierModal,
  scheduleOrders,
  courierPrices,
}) {
  const [loading, setLoading] = useState(false);
  function handleCourierSelection(e) {
    const { name, courier } = JSON.parse(e.target.value);
    setSelectedCouriers((selectedCouriers) => {
      return {
        ...selectedCouriers,
        [name]: courier.courier_company_id,
      };
    });
  }

  const schedule = () => {
    scheduleOrders("ShipRocket");
    setLoading(true);
  };

  return (
    <Fragment>
      <div className="ops-modal-blur"></div>
      <div className="ops-address-modal">
        <div className="ops-address-modal-head mb-4 justify-content-center">
          <h4 className="text-uppercase mt-2">
            Please select couriers for all orders
          </h4>
          <span
            style={{
              color: "red",
              position: "absolute",
              top: "0",
              right: "0.25rem",
              fontSize: "175%",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedCouriers({});
              setCourierModal(false);
            }}
          >
            <i className="far fa-times-circle"></i>
          </span>
        </div>
        <div className="container">
          {orders.length > 0
            ? orders.map((order, index) => {
                if (
                  surfaceOrders.indexOf(order.name) >= 0 &&
                  selected.indexOf(order.name) >= 0
                )
                  return (
                    <div
                      key={`courier-modal-item-${index}`}
                      className="row justify-content-center m-3"
                    >
                      <div className="col-2 align-bottom">{order.name}</div>
                      <div className="col-4">
                        {courierPrices[order.name].length === 0 ? (
                          <span>No surface courier found!</span>
                        ) : (
                          <select
                            className="form-select form-select-lg m-1 p-2"
                            onChange={handleCourierSelection}
                          >
                            {courierPrices[order.name].map((courier) => {
                              if (courier.is_surface)
                                return (
                                  <option
                                    value={JSON.stringify({
                                      name: order.name,
                                      courier,
                                    })}
                                  >
                                    {courier.courier_name +
                                      " - \u20B9" +
                                      courier.rate +
                                      " (" +
                                      courier.edd +
                                      " days)"}
                                  </option>
                                );
                            })}
                            <option value="" selected disabled hidden>
                              Please choose a courier
                            </option>
                          </select>
                        )}
                      </div>
                    </div>
                  );
              })
            : null}
        </div>
        <div className="mb-3">
          <button
            disabled={loading}
            style={{
              backgroundColor: "#2980b9",
              padding: "0.75rem 5rem",
              borderRadius: "5px",
              color: "white",
              textTransform: "uppercase",
              cursor: loading ? "not-allowed" : "pointer",
            }}
            onClick={() => schedule()}
          >
            Submit
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default CourierModal;

import React, { useContext, useEffect, useState } from "react";
import SideBar from "./CallingTracker/res/SideBar";
import Dashboard from "./CallingTracker/components/layouts/Dashboard";
import { UserConsumer } from "./CallingTracker/components/context/caller-context";

const CallingTracker = () => {
  document.title = "Calling Tracker";
  const context = useContext(UserConsumer);
  // context.computeTotal();
  let index;
  const [currentUser, setCurrentUser] = useState("");
  const [userIndex, setUserIndex] = useState("");

  useEffect(() => {
    context.fetchOrders();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <SideBar
        users={context.users}
        setCurrentUser={setCurrentUser}
        setUserIndex={setUserIndex}
      />
      <Dashboard
        user={currentUser}
        userIndex={userIndex}
        total={context.total}
      />
    </div>
  );
};

export default CallingTracker;

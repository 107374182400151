import React, { Fragment } from "react";
import { submitRTO } from "../functions/submitRTO";

// import Axios from "axios";
import { useState } from "react";

import Axios from "axios";

const RtoOrder = ({ store, order, fetchOrders }) => {
  const [input, setInput] = useState("");

  const postdata = async (store, name, reportIssue) => {
    try {
      // console.log(store);
      // console.log("name is ",name);
      // console.log("reportIsuue",reportIssue);
      const result = await Axios.post("/api/warehouse/updateRtoOrders", {
        store: store,
        name: name,
        reportIssue: reportIssue,
      });

      //console.log("result is ", result);

      alert("Substitute name taken");
    } catch (error) {
      console.log("errr is ", error);
      alert("Could not rectify Please retry");
    }
  };
  return (
    <Fragment>
      {order.line_items.map((product, i) => (
        <tr key={i}>
          {i === 0 ? (
            <td scope="col" rowSpan={order.line_items.length}>
              {order.orderName}
            </td>
          ) : (
            ""
          )}
          <td scope="col">
            {product.name} x {product.quantity}
          </td>
          <td scope="col">
            <input
              type="number"
              className="form-control"
              id={`damaged_${product.variant_id}`}
              placeholder={"Damaged"}
              defaultValue={0}
            />
          </td>
          <td scope="col">
            {" "}
            <input
              type="number"
              className="form-control"
              id={`usable_${product.variant_id}`}
              placeholder={"Usable"}
              defaultValue={0}
            />
          </td>
          <td scope="col">
            <input
              type="number"
              className="form-control"
              id={`missing_${product.variant_id}`}
              placeholder={"Missing"}
              defaultValue={0}
            />
          </td>
          <td scope="col">
            <button
              type="button"
              className="btn btn-link "
              style={{ color: "blue" }}
              data-toggle="modal"
              data-target="#myModal"
            >
              Report Issue
            </button>
          </td>
        </tr>
      ))}

      <tr>
        <td colSpan={6}>
          {/* modal code */}
          <button
            type="button"
            className="btn btn-success rto-btn"
            onClick={() => submitRTO(store, order, fetchOrders)}
          >
            Submit
          </button>

          <div id="myModal" className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Report Issue</h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Issue"
                    onChange={(e) => {
                      setInput(e.target.value.trim());
                    }}
                  ></input>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      if (!input) alert("Product name cannot be empty!");
                      else postdata(store, order.orderName, input);
                    }}
                  >
                    Sumbit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

export default RtoOrder;

import React, { useRef } from "react";

export default function UtilityBar({ bugs, setFilteredBugs }) {
  const searchRef = useRef("");
  const filterBugs = () => {
    const filtered = bugs.filter(
      (bug) =>
        bug.error.includes(searchRef.current.value.trim().toLowerCase()) ||
        bug.type.includes(searchRef.current.value.trim().toLowerCase())
    );
    setFilteredBugs(filtered);
  };
  return (
    <div className="bug-search">
      <input
        style={{ width: "100%" }}
        placeholder={`Search...`}
        ref={searchRef}
        onChange={() => filterBugs()}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Axios from "axios";
import RuleItem from "../res/RuleItem";

function ManageRules() {
  const [rules, setRules] = useState([]);
  const fetchRules = async () => {
    let res = await Axios.get("/api/IMS/getRulesAndFlags");
    console.log("Fetched Rules", res.data.rules);
    setRules(res.data.rules);
  };
  useEffect(() => {
    fetchRules();
  }, []);
  return (
    <div className="card configurationsCard IMS-order-card">
      <div>
        <div className="card-header">Flags</div>
        <div className="card-body">
          {rules.map(rule => (
            <RuleItem type={"flagged"} rule={rule} fetchRules={fetchRules} />
          ))}
        </div>
      </div>
      <div>
        <div className="card-header">Rules</div>
        <div className="card-body">
          {" "}
          {rules.map(rule => (
            <RuleItem type={"mutation"} rule={rule} fetchRules={fetchRules} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ManageRules;

import React from "react";

export default function NumberCard({
  title,
  number,
  previous,
  inverted,
  data,
}) {
  const increase = number - previous;
  const increasePercentage = `${parseInt((increase / previous) * 100) || 0}`;
  const equation = inverted ? increasePercentage < 0 : increasePercentage > 0;

  return (
    <div className="number-card">
      <div>
        <div
          className="number-card-number"
          style={{
            color: equation ? "#16a085" : "#e74c3c",
          }}
        >
          <div>
            <div style={{ position: "relative" }}>{number}</div>
            <div
              style={{
                fontSize: "12px",
                right: "-35px",
                top: "35px",
              }}
            >
              {" "}
              {increasePercentage > 0 ? (
                <i
                  class="fas fa-caret-up"
                  style={{ paddingTop: "5px", paddingRight: "2px" }}
                ></i>
              ) : (
                <i
                  class="fas fa-caret-down"
                  style={{ paddingTop: "1px", paddingRight: "2px" }}
                ></i>
              )}
              {"   "}
              {Math.abs(increasePercentage)}% (
              {`${increase < 0 ? "" : "+"}${increase}`})
            </div>
          </div>
        </div>
        <div className="number-card-text">{title}</div>
      </div>
    </div>
  );
}

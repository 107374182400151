import React, { useState, useRef, Fragment } from "react";
import axios from "axios";
import CustomerDataForm from "./CustomerDataForm";
import StoreDropdownButton from "./StoreDropdownButton";

const CustomerDetails = ({
  setCustomerId,
  store,
  customer,
  setCustomerType,
  setCustomer,
  setUneditedCustomerDetails,
  setOrderName,
  setAddressId,
  setExistingCustomerDifferentStore,
  setNotification,
}) => {
  const initializeCustomer = {
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    company: "",
    city: "",
    province: "",
    zip: "",
    country: "",
  };
  const input = useRef(null);
  const [showDraftForm, setShowDraftForm] = useState(null);
  const [searchCustomerStore, setSearchCustomerStore] = useState(null);

  const fetchCustomerDetails = async () => {
    if (!searchCustomerStore) {
      setNotification(
        "failure",
        "Select the store to fetch order details from!"
      );
      return;
    }
    setCustomer(initializeCustomer);
    if (!input.current.value) {
      setNotification(
        "failure",
        "Enter old order/customer details to link to new draft order"
      );
      return;
    }
    setOrderName(input.current.value);
    const { data } = await axios.post(
      "/api/operations/getCustomerDetailsForDraft",
      {
        input: input.current.value,
        store,
        searchCustomerStore,
      }
    );
    if (!data.status) {
      setNotification("failure", data.message);
      return;
    }
    setCustomer(data.details);
    setUneditedCustomerDetails(data.details);
    setCustomerId(data.details.customer_id);
    setAddressId(data.details.id);
    setExistingCustomerDifferentStore(data.existingCustomerDifferentStore);
  };

  const handleDraftForm = (value) => {
    setShowDraftForm(value);
    setCustomerType(value);
    setCustomer(initializeCustomer);
  };

  return (
    <div>
      {/* <h4 style={{ textAlign: "center" }}>Customer Details</h4> */}
      <div className="draft-criteria">
        <button
          className="button-pill"
          onClick={() => handleDraftForm("existing")}
          style={{
            border: "2px solid #2980b9",
            backgroundColor: "#fff",
            color: "#000",
          }}
        >
          Existing Customer or Order
        </button>
        <button
          className="button-pill"
          onClick={() => handleDraftForm("new")}
          style={{
            border: "2px solid #2980b9",
            backgroundColor: "#fff",
            color: "#000",
          }}
        >
          New Customer
        </button>
      </div>
      {showDraftForm ? (
        <Fragment>
          {showDraftForm === "existing" ? (
            <div className="draft-criteria">
              <label>Order details from</label>
              <div style={{ border: "0.5px solid grey", borderRadius: "5px" }}>
                <StoreDropdownButton
                  setStore={setSearchCustomerStore}
                  store={searchCustomerStore}
                />
              </div>
              <input
                id="existing-details-input"
                type="text"
                placeholder="Enter order name"
                className="form-control form-control-sm"
                ref={input}
              ></input>
              <button
                className="find-details-button"
                onClick={fetchCustomerDetails}
              >
                Find Details
              </button>
            </div>
          ) : null}
          <CustomerDataForm customer={customer} setCustomer={setCustomer} />
        </Fragment>
      ) : (
        <div className="draft-order-no-store-div">
          {"Please choose existing or new customer!"}
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;

import Axios from "axios";
import React, { useState } from "react";

const CampaignItem = ({ data }) => {
  const [active, setActive] = useState(data.active);

  const activateCampaign = async (campaignId) => {
    setActive(true);
    console.log("activate campaign -", campaignId);
    await Axios.get(`/api/smsMobi/activateCampaign/${campaignId}`);
  };

  const deactivateCampaign = async (campaignId) => {
    setActive(false);
    await Axios.get(`/api/smsMobi/deactivateCampaign/${campaignId}`);
  };

  return (
    <>
      <div className="campaign-item">
        <div
          className={
            active
              ? "campaign-item-header-active"
              : "campaign-active-header-not-active"
          }
        >
          <div>
            <h3>{`${data.campaignName}`}</h3>
          </div>
          <div className="campaign-item-header-controls">
            {active ? (
              <i
                className="fas fa-toggle-on fa-2x aggregator-op"
                onClick={() => deactivateCampaign(data.campaignId)}
              ></i>
            ) : (
              <i
                className="fas fa-toggle-off fa-2x aggregator-op"
                onClick={() => activateCampaign(data.campaignId)}
              ></i>
            )}
          </div>
        </div>
        <div className="campaign-item-body">
          <div className="campaign-item-grid">
            <div>
              <b>ID</b>
            </div>
            <div>{data.campaignId}</div>
          </div>
          {/* <div className="campaign-item-grid">
        <div>Name</div>
        <div>{data.campaignName}</div>
      </div> */}
          <div className="campaign-item-grid">
            <div>
              <b>Size</b>
            </div>
            <div>{data.campaignSize}</div>
          </div>
          <div className="campaign-item-grid">
            <div>
              <b>Start Date</b>
            </div>
            <div>{new Date(data.startDate).toDateString()}</div>
          </div>
          <div className="campaign-item-grid">
            <div>
              <b>Duration</b>
            </div>
            <div>{data.campaignDuration} days</div>
          </div>
          <div className="campaign-item-grid">
            <div>
              <b>End Date</b>
            </div>
            <div>{new Date(data.endDate).toDateString()}</div>
          </div>
          <div className="campaign-item-grid">
            <div>
              <b>SMS per Day</b>
            </div>
            <div>{data.perDay}</div>
          </div>
          <div className="campaign-item-grid">
            <div>
              <b>Campaign Product</b>
            </div>
            <div>{data.campaignMessage.productDetails}</div>
          </div>
          <div className="campaign-item-grid">
            <div>
              <b>Campaign Voucher Code</b>
            </div>
            <div>{data.campaignMessage.voucherCode}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignItem;

import React, { useState, UseEffect, Fragment, useEffect } from "react";
import Axios from "axios";
import Spinner from "../../global/Spinner";

function DispatchedAndPending() {
  const [store, setStore] = useState("All");
  const [stores, setStores] = useState([]);
  const [aggregator, setAggregator] = useState("All");
  const [aggregators, setAggregators] = useState([]);
  const [loading, setLoading] = useState(false);
  document.title = "Dispatched and Pending";

  const getStores = async () => {
    let storeList = await Axios.get("/api/stores");
    storeList = JSON.parse(JSON.stringify(storeList.data));
    setStores(storeList);
  };

  const getAggregators = async () => {
    let aggregatorList = await Axios.get("/api/control/fetchAggregators");
    setAggregators(aggregatorList.data.aggregators);
  };

  const handleStoreChange = (e) => {
    let selectedStore = e.target.value;
    if (selectedStore !== "All") {
      selectedStore = JSON.parse(selectedStore);
    }
    setStore(selectedStore);
  };

  const handleAggregatorChange = (e) => {
    setAggregator(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let data = {
      store,
      aggregator,
    };
    console.log(`The data submitted => `, data);
    let csv = await Axios.post("/api/warehouse/dispatchedAndPending", data);
    let blob = new Blob([csv.data.csv], { type: "text/csv" });
    let a = document.createElement("a");
    a.download = "Dispatched&Pending.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download Dispatched and Pending CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
    setLoading(false);
    setAggregator("All");
    setStore("All");
  };

  useEffect(() => {
    getStores();
    getAggregators();
  }, []);

  return !loading ? (
    <div className="stats-content">
      <div
        className="stats-filter"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <select
          placeholder="Stores"
          id="store-selector"
          className="form-control"
          style={{
            height: "100%",
            fontSize: "0.9rem",
            borderRadius: 0,
          }}
          onChange={(e) => handleStoreChange(e)}
        >
          <option value="All" selected>
            {"All stores"}
          </option>
          {stores.map((CurrStore, index) => (
            <option key={index} value={JSON.stringify(CurrStore)}>
              {CurrStore.storeName}
            </option>
          ))}
        </select>
        <select
          placeholder="Aggregator"
          id="aggregator-selector"
          style={{
            height: "100%",
            fontSize: "0.9rem",
            borderRadius: 0,
          }}
          onChange={(e) => handleAggregatorChange(e)}
          className="form-control form-control-sm"
        >
          <option value="All" selected>
            {"All aggregators"}
          </option>
          {aggregators.map((aggregator, index) => (
            <option key={index} value={aggregator.name}>
              {aggregator.name}
            </option>
          ))}
        </select>
        <button
          className="btn btn-danger"
          style={{ borderRadius: 0 }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default DispatchedAndPending;

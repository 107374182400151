import React, { createContext } from "react";
import axios from "axios";
import dissolveStatus from "../../utils/dissolveStatus";
import { checkProducts } from "../../utils/checkProducts";
import { statusArray, statusAggregations } from "../../../../res/resourceArray";
import moment from "moment";

// REMEMBER TO ADD PERSPECTIVE OPTION ON ALL THE NEW DOWNLOAD BUTTONS THAT ARE ADDED

//Creating context of available global states
const UserContext = createContext({
  orders: [],
  showOrders: [],
  status: [],
  store: "",
  stamp: "",
  verboseMode: false,
  search: false,
  loading: false,
  products: [],
  filtered: [],
  fromDate: moment("2019"),
  toDate: moment().endOf("day"),
  customRange: "",
  offset: 0,
  limit: 20,
  count: 0,
  page: 1,
  aggregators: [],
  selectedColumnsObject: {},
  superStatusTags: [],
  disabled: {},
  statusArray: [],
  statusAggregations: [],
  setDisabled: () => {},
  setSuperStatusTags: () => {},
  handleLimitChange: () => {},
  increaseOffset: () => {},
  decreaseOffset: () => {},
  resetOffset: () => {},
  filterProducts: () => {},
  downloadFacebook: () => {},
  fetchInitialOrders: () => {},
  fetchAggregators: () => {},
  setDate: () => {},
  downloadFullCSV: () => {},
  downloadInventoryCSV: () => {},
  queryFilter: () => {},
  downloadRTOandDelivered: () => {},
  deliveryCalculation: () => {},
  setFiltered: () => {},
  searchOrder: () => {},
  fetchOrders: () => {},
  filterOrders: () => {},
  filterByDate: () => {},
  permuteOrders: () => {},
  download: () => {},
  saleableProductCSV: () => {},
  nonSaleableProductCSV: () => {},
  selectedColumns: (e, d) => {},
  setPerspectiveOption: () => {},
  fetchAllOrders: () => {},
  toggleLoading: () => {},
  downloadSpecificOrders: () => {},
  setSearchedOrders: () => {},
  setMode: () => {},
  searchedOrders: [],
  perspectiveOption: "created_at",
});
// [...new Set(array)]
export class UserProvider extends React.Component {
  //Fetch data to be display from API call
  sortOrders = (orders) => {
    orders.data.sort(function (a, b) {
      let nameA = a.number,
        nameB = b.number;
      if (nameA > nameB) return -1;
      if (nameA < nameB) return 1;
      return 0;
    });
    return orders;
  };

  setMode = (mode) => {
    this.setState({ verboseMode: mode });
  };

  componentWillUnmount() {
    this.setMode(false);
  }

  toggleLoading = (loading) => {
    this.setState({ loading });
  };

  setPerspectiveOption = (perspectiveOption) => {
    this.setState({ perspectiveOption });
  };

  setSearchedOrders = (orders) => {
    this.setState({ searchedOrders: orders });
  };

  fetchAggregators = async () => {
    let result = await axios.get("/api/control/fetchAggregators");
    console.log(result.data.aggregators);
    this.setState({ aggregators: result.data.aggregators });
  };

  fetchOrders = async (store) => {
    global.selected = [];

    this.setState({ search: false });

    let stamp = await axios.post(
      `/api/statusUpdaterV2/getTimeStamp/${encodeURIComponent(
        JSON.stringify(store)
      )}`
    );

    this.setState({ stamp: stamp.data.stamp });
    this.setState({ store });
    this.setState({ loading: true });
    let result = await axios.get(
      `/api/globalDashboard/fetchOrders/${encodeURIComponent(
        JSON.stringify(store)
      )}`
    );

    result = this.sortOrders(result);
    let products = [];
    if (!store.click) {
      products = await axios.get(
        `/api/globalDashboard/fetchProducts/${encodeURIComponent(
          JSON.stringify(store)
        )}`
      );
      products = products.data;
    }

    this.setState({ products: products });
    this.setState({ orders: result.data });
    this.setState({ showOrders: result.data });
    this.setState({ loading: false });
    this.setState((prevState) => ({ page: prevState.page }));
    this.setState({ filtered: this.state.products });
  };

  downloadSpecificOrders = () => {
    alert("YO");
  };

  setFiltered = (array) => {
    this.setState({ filtered: array });
  };

  filterProducts = () => {
    let productList = global.selected.map((item) => item[0]);

    let newArray = this.state.products.filter((product) => {
      return !productList.includes(product[0].toString());
    });

    this.setState({ filtered: newArray });
  };

  resetOffset = () => {
    this.setState({
      offset: 0,
    });
    this.setState({
      page: 1,
    });
  };

  increaseOffset = (store) => {
    const current = this.state.offset;
    let newOffset =
      current + this.state.limit < 0 ? 0 : current + this.state.limit;
    this.setState({
      offset: newOffset,
    });

    this.queryFilter(newOffset);
    this.setState((prevState) => ({
      page: prevState.page + 1 > 1 ? prevState.page + 1 : 1,
    }));
  };

  decreaseOffset = (store) => {
    const current = this.state.offset;
    let newOffset =
      current - this.state.limit < 0 ? 0 : current - this.state.limit;
    this.setState({
      offset: newOffset,
    });
    this.setState((prevState) => ({
      page: prevState.page - 1 > 1 ? prevState.page - 1 : 1,
    }));
    if (current !== 0) this.queryFilter(newOffset);
  };

  handleLimitChange = ({ target: { value: limit = 20 } }) => {
    this.setState({ limit: Number(limit) }, () =>
      this.queryFilter(this.state.offset)
    );
    this.resetOffset();
  };

  queryFilter = async (offset) => {
    const perspectiveOption = this.state.perspectiveOption;

    console.log(this.state.perspectiveOption);

    let fromDate = this.state.fromDate ? this.state.fromDate : new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();
    let abandonedMode = document.getElementById("abandonedMode").checked;
    let aggregator = document.getElementById("aggregator-selector").value;
    let paymentMethod = document.getElementById("paymentOptionSelector").value;
    let trafficSource = document.getElementById("trafficSourceSelector").value;
    console.log("trafficSource = ", trafficSource);

    document.getElementById("order-result").innerHTML = ``;

    let abandonedCheckoutCondition = abandonedMode
      ? { abandonedOrderReference: { $exists: true } }
      : {
          abandonedCheckout: { $exists: false },
        };

    let productList = global.selected.map((item) => {
      return { "line_items.product_id": parseInt(item[0]) };
    });

    let store = this.state.store;

    let andArray = [
      abandonedCheckoutCondition,

      {
        "store.shopifyName": store.shopifyName,
      },
    ];

    if (paymentMethod !== "0") {
      andArray.push({
        financial_status: paymentMethod,
      });
    }

    if (trafficSource !== "0") {
      andArray.push({
        customSource: trafficSource,
      });
    }

    let status_code = null;
    if (
      !document.getElementById("statusSelector").disabled &&
      !document.getElementById("superStatusSelector").disabled
    ) {
      // both status selectors not used
      status_code = parseInt(document.getElementById("statusSelector").value);
      if (status_code) {
        if (status_code === 999)
          andArray.push({
            status_code: { $exists: false },
          });
        else
          andArray.push({
            status_code,
          });
      }
    } else if (
      !document.getElementById("statusSelector").disabled &&
      document.getElementById("superStatusSelector").disabled
    ) {
      // status selector is used
      status_code = parseInt(document.getElementById("statusSelector").value);
      if (status_code) {
        if (status_code === 999)
          andArray.push({
            status_code: { $exists: false },
          });
        else
          andArray.push({
            status_code,
          });
      }
    } else {
      if (this.state.superStatusTags.length > 0) {
        andArray.push({
          status_code: { $in: this.state.superStatusTags },
        });
      }
    }

    if (productList.length !== 0)
      andArray.push({
        $or: productList,
      });

    if (!["All", "Aggregator"].includes(aggregator))
      andArray.push({
        scheduledThrough: aggregator,
      });

    let condition = {
      $and: andArray,
    };

    let data = {
      condition,
      store,
      fromDate,
      toDate,
      perspectiveOption,
    };

    this.setState({ loading: true });

    let result = await axios.post(`/api/globalDashboard/fetchOrders_V2`, {
      ...data,
      offset: offset,
      limit: this.state.limit,
    });
    if (result.data.success) {
      alert(result.data.msg);
      result.data = this.state.orders;
    }
    this.setState({
      showOrders: result.data.orders || [],
    });
    this.setState({ loading: false });

    let orders = (result.data.orders || []).filter((order) => {
      return (
        (productList.length !== 0 ? checkProducts(order, productList) : true) &&
        (status_code !== 0
          ? status_code !== 999
            ? order.status_code
              ? order.status_code === status_code
              : false
            : !order.status_code
          : true) &&
        (Date.parse(fromDate) || Date.parse(toDate)
          ? new Date(order.created_at).setHours(0, 0, 0, 0) >=
              new Date(fromDate).setHours(0, 0, 0, 0) &&
            new Date(order.created_at).setHours(0, 0, 0, 0) <=
              new Date(toDate).setHours(0, 0, 0, 0)
          : true)
      );
    });

    document.getElementById(
      "order-result"
    ).innerHTML = `Found ${result.data.total} orders`;

    this.setState({
      count: result.data.total,
    });

    document.getElementById(
      "FilterLog"
    ).innerHTML = `Filtering between ${moment(fromDate).format(
      "DD/MM/YYYY"
    )} and ${moment(toDate).format("DD/MM/YYYY")}`;

    window.scrollTo({
      top: 90,
      left: 0,
      behavior: "smooth",
    });
  };

  fetchInitialOrders = async (store) => {
    console.log(`Fetch initial orders is called!`);
    if (store === "All Stores") return this.setState({ store: "" });

    let fromDate = new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();
    const perspectiveOption = this.state.perspectiveOption;

    global.selected = [];
    store = store ? store : this.state.store;
    this.setState({ search: false });
    this.setState((prevState) => ({
      page: prevState.page ? prevState.page : 1,
    }));
    if (document.getElementById("order-result"))
      document.getElementById("order-result").innerHTML = ``;

    let stamp = await axios.post(
      `/api/statusUpdaterV2/getTimeStamp/${encodeURIComponent(
        JSON.stringify(store)
      )}`
    );

    this.setState({ stamp: stamp.data.stamp });

    this.setState({ store });
    this.setState({ loading: true });
    let result = await axios.post(`/api/globalDashboard/fetchOrders_V2`, {
      store,
      fromDate,
      toDate,
      offset: this.state.offset,
      limit: this.state.limit,
      perspectiveOption,
    });
    result = this.sortOrders({ data: result.data.orders || [] });
    let products = [];
    if (!store.click) {
      products = await axios.get(
        `/api/globalDashboard/fetchProducts/${encodeURIComponent(
          JSON.stringify(store)
        )}`
      );
      products = products.data;
    }

    this.setState({
      showOrders: result.data || [],
      orders: result.data || [],
      products: products || [],
      loading: false,
      filtered: products || [],
    });
    window.scrollTo({
      top: 90,
      left: 0,
      behavior: "smooth",
    });
  };

  searchOrder = async (order, store, lookup) => {
    this.setState({ search: false, offset: 0 });

    this.setState({ store });
    this.setState({ loading: true });
    let data = {
      order,
      store,
    };

    if (lookup) {
      order = decodeURIComponent(order);
      data = {
        order,
        store,
        lookup: true,
      };
    }

    let result = await axios.post(`/api/globalDashboard/searchOrder`, data);

    this.setState({ orders: result.data.orders });
    this.setState({ showOrders: result.data.orders });
    this.setState({ loading: false });
  };

  deliveryCalculation = (orders) => {
    let A = [];
    let B = [];
    let C = [];
    let D = [];
    let E = [];
    let F = [];
    let G = [];
    let H = [];
    let I = orders.length;

    orders.map((order) => {
      if (order.status_code === 7) A.push(order);
      else if (order.status_code === 15) B.push(order);
      else if (order.status_code === 16) C.push(order);
      else if (order.status_code === 17) D.push(order);
      else if (order.status_code === 36) E.push(order);
      else if (order.status_code === 102) F.push(order);
      else if (order.status_code === 103) G.push(order);
      else if (order.status_code === 105) H.push(order);
    });

    A = A.length > 0 ? A.length : 0;
    B = B.length > 0 ? B.length : 0;
    C = C.length > 0 ? C.length : 0;
    D = D.length > 0 ? D.length : 0;
    E = E.length > 0 ? E.length : 0;
    F = F.length > 0 ? F.length : 0;
    G = G.length > 0 ? G.length : 0;
    H = H.length > 0 ? H.length : 0;

    let J = parseFloat(I - (F + G + H));
    let Actual = parseFloat(A / (A + B + C + D + E));

    let Extrapolated = ((Actual * J) / I) * 100;

    if (isNaN(Actual)) Actual = 0;
    if (isNaN(Extrapolated)) Extrapolated = 0;

    Actual *= 100;

    return { Actual, Extrapolated };
  };

  setDate = (fromDate, toDate) => {
    console.log(
      `(TRACKING BOARD)\nFrom Date:${new Date(
        fromDate
      ).toLocaleString()}\nTo Date:${new Date(toDate).toLocaleString()}`
    );
    this.setState({ fromDate, toDate });
  };

  permuteOrders = () => {
    this.setState({ search: true });
  };

  // DONE TILL HERE

  downloadFacebook = async () => {
    if (global.selected.length <= 0) {
      document.getElementById("productSearch").focus();
      return alert("Please select products first");
    }
    let products = global.selected.map((product) => product[1]);

    // date perspective
    let perspectiveOption = document.getElementById("date-perspective-filter")
      .value;

    let fromDate = this.state.fromDate ? this.state.fromDate : new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();

    if (
      prompt(
        `Products selected ${products} from ${moment(fromDate).format(
          "DD/MM/YY"
        )} to ${moment(toDate).format("DD/MM/YY")} ("yes" to proceed)`
      ) !== "yes"
    )
      return;

    products = global.selected.map((product) => parseInt(product[0]));
    let result = await axios.post(
      "/api/globalDashboard/printFacebookCustomAudience",
      {
        store: this.state.store,
        products,
        fromDate,
        toDate,
        perspectiveOption,
      }
    );
    if (result.data.msg) return alert("No data to export");
    let blob = new Blob([result.data], { type: "text/csv" });
    var a = document.createElement("a");
    a.download = "FacebookCustomAudience.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
  };

  download = async () => {
    let data = [];
    this.state.showOrders.map((order) => {
      order.status_code = order.status_code
        ? statusArray[parseInt(order.status_code)]
        : "Order Received On Store";
      order.products = "";
      order.awb_ref_no = order.awb_ref_no ? order.awb_ref_no : "-";
      order.line_items.map((product) => (order.products += product.title));
      data.push(order);
    });

    let result = await axios.post(
      "/api/globalDashboard/print",
      this.state.showOrders
    );

    let blob = new Blob([result.data], { type: "text/csv" });
    var a = document.createElement("a");
    a.download = "my.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
  };

  fetchAllOrders = async (user) => {
    const perspectiveOption = this.state.perspectiveOption;

    let fromDate = this.state.fromDate ? this.state.fromDate : new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();
    let abandonedMode = document.getElementById("abandonedMode").checked;
    let aggregator = document.getElementById("aggregator-selector").value;
    let paymentMethod = document.getElementById("paymentOptionSelector").value;
    // Todo:Prasad = collect the TrafficSouce here
    let trafficSource =
      !user.customer && document.getElementById("trafficSourceSelector").value;
    console.log("trafficSource = ", trafficSource);

    let abandonedCheckoutCondition = abandonedMode
      ? { abandonedOrderReference: { $exists: true } }
      : {
          abandonedCheckout: { $exists: false },
        };

    let productList = global.selected.map((item) => {
      return { "line_items.product_id": parseInt(item[0]) };
    });

    let store = this.state.store;

    let andArray = [
      abandonedCheckoutCondition,

      {
        "store.shopifyName": store.shopifyName,
      },
    ];

    if (paymentMethod !== "0") {
      andArray.push({
        financial_status: paymentMethod,
      });
    }

    // Todo:Prasad = push into query
    if (trafficSource !== "0") {
      andArray.push({
        customSource: trafficSource,
      });
    }

    let status_code = null;
    if (
      !document.getElementById("statusSelector").disabled &&
      !document.getElementById("superStatusSelector").disabled
    ) {
      // both status selectors not used
      status_code = parseInt(document.getElementById("statusSelector").value);
      if (status_code) {
        if (status_code === 999)
          andArray.push({
            status_code: { $exists: false },
          });
        else
          andArray.push({
            status_code,
          });
      }
    } else if (
      !document.getElementById("statusSelector").disabled &&
      document.getElementById("superStatusSelector").disabled
    ) {
      // status selector is used
      status_code = parseInt(document.getElementById("statusSelector").value);
      if (status_code) {
        if (status_code === 999)
          andArray.push({
            status_code: { $exists: false },
          });
        else
          andArray.push({
            status_code,
          });
      }
    } else {
      if (this.state.superStatusTags.length > 0) {
        andArray.push({
          status_code: { $in: this.state.superStatusTags },
        });
      }
    }

    if (productList.length !== 0)
      andArray.push({
        $or: productList,
      });

    if (!["All", "Aggregator"].includes(aggregator))
      andArray.push({
        scheduledThrough: aggregator,
      });

    let condition = {
      $and: andArray,
    };

    let data = {
      condition,
      store,
      fromDate,
      toDate,
      perspectiveOption,
      allStores: true,
      user: {
        user_id: user.user_id || null,
        customer: user.customer,
        stores: user.stores,
      },
    };

    this.setState({ loading: true });

    let result = await axios.post(`/api/globalDashboard/fetchOrders_V2`, {
      ...data,
    });
    if (result.data.success) {
      alert(result.data.msg);
      result.data = this.state.orders;
    }
    this.setState({
      showOrders: result.data.orders || [],
    });
    this.setState({ loading: false });
  };

  downloadFullCSV = async (all, user, columns, gstRate) => {
    let fromDate = this.state.fromDate ? this.state.fromDate : new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();
    const perspectiveOption = this.state.perspectiveOption;

    let abandonedMode = document.getElementById("abandonedMode")
      ? document.getElementById("abandonedMode").checked
        ? document.getElementById("abandonedMode").checked
        : false
      : false;

    let products = [];
    let status_code = "";
    products = global.selected
      ? global.selected.map((product) => product[1])
      : [];

    if (!all) {
      status_code =
        parseInt(document.getElementById("statusSelector").value) !== 0 &&
        parseInt(document.getElementById("statusSelector").value) !== ""
          ? parseInt(document.getElementById("statusSelector").value)
          : "";
    }

    if (
      prompt(
        `Products selected ${products} from ${moment(fromDate).format(
          "DD/MM/YY"
        )} to ${moment(toDate).format("DD/MM/YY")} ("yes" to proceed)`
      ) !== "yes"
    )
      return;

    if (all) {
      if (document.getElementById("start-message"))
        document.getElementById("start-message").innerHTML =
          "Please wait while we prepare the CSV. It can take upto 5 minutes. You will receive a mail on its completion";
    }

    products = global.selected
      ? global.selected.map((product) => parseInt(product[0]))
      : [];

    const conditions = this.fetchFilters();

    let data = {
      store: this.state.store,
      products,
      fromDate,
      toDate,
      status_code,
      abandonedMode,
      conditions,
      perspectiveOption,
      columns,
      gstRate,
    };

    if (user)
      data.user = {
        user_id: user.user_id,
        name: user.name,
        email: user.email,
      };
    let result = await axios.post("/api/globalDashboard/getFullCSV", data);

    if (!result.data.success) {
      alert("No orders to export");
      return;
    }
    if (!result.data.csv)
      alert("You will be redirected, check your email for link otherwise");

    if (result.data.csv) {
      let blob = new Blob([result.data.csv], { type: "text/csv" });
      var a = document.createElement("a");
      a.download = "Details.csv";
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("style", "display:none;");
      a.textContent = "Download CSV";

      a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
        ":"
      );

      document.body.appendChild(a);
      a.click();
      return;
    }
    var a = document.createElement("a");
    a.download = "Details.csv";
    a.href = result.data.msg;
    a.target = "_blank";
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
  };

  downloadConsolidatedOrdersCSV = async (all, user) => {
    let fromDate = this.state.fromDate ? this.state.fromDate : new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();
    const perspectiveOption = this.state.perspectiveOption;

    let abandonedMode = document.getElementById("abandonedMode")
      ? document.getElementById("abandonedMode").checked
        ? document.getElementById("abandonedMode").checked
        : false
      : false;

    const aggregator = document.getElementById("aggregator-selector").value;

    let products = [];
    products = global.selected
      ? global.selected.map((product) => product[1])
      : [];

    if (
      prompt(
        `Products selected ${products} from ${moment(fromDate).format(
          "DD/MM/YY"
        )} to ${moment(toDate).format("DD/MM/YY")} ("yes" to proceed)`
      ) !== "yes"
    )
      return;

    if (all) {
      if (document.getElementById("start-message"))
        document.getElementById("start-message").innerHTML =
          "Please wait while we prepare the CSV. It can take upto 5 minutes!";
    }

    products = global.selected
      ? global.selected.map((product) => parseInt(product[0]))
      : [];

    const conditions = this.fetchFilters();

    let data = {
      store: this.state.store,
      products,
      fromDate,
      toDate,
      abandonedMode,
      aggregator,
      conditions,
      perspectiveOption,
    };

    if (user)
      data.user = {
        user_id: user.user_id,
        name: user.name,
        email: user.email,
      };
    let result = await axios.post(
      "/api/consolidatedOrdersData/getConsolidatedOrdersData",
      data
    );

    if (result.data.csv) {
      let blob = new Blob([result.data.csv], { type: "text/csv" });
      var a = document.createElement("a");
      a.download = "Consolidated Orders Data.csv";
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("style", "display:none;");
      a.textContent = "Download Consolidated Orders CSV";

      a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
        ":"
      );

      document.body.appendChild(a);
      a.click();
      return;
    } else if (result.data.msg) {
      alert(result.data.msg);
    } else {
      alert("Something went wrong!");
    }
  };

  downloadRTOandDelivered = async () => {
    // NOT BEING USED
    let products = global.selected.map((product) => product[1]);

    // date perspective
    let perspectiveOption = document.getElementById("date-perspective-filter")
      .value;

    let fromDate = this.state.fromDate ? this.state.fromDate : new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();

    fromDate = moment(fromDate);
    toDate = moment(toDate).add(1, "days");

    if (!fromDate.isValid()) fromDate = new Date("2019");
    if (!toDate.isValid()) toDate = new Date();

    if (
      prompt(
        `Products selected ${products} from ${moment(fromDate).format(
          "DD/MM/YY"
        )} to ${moment(toDate).format("DD/MM/YY")} ("yes" to proceed)`
      ) !== "yes"
    )
      return;

    products = global.selected.map((product) => parseInt(product[0]));
    let result = await axios.post("/api/globalDashboard/printRTOandDelivered", {
      store: this.state.store,
      products,
      fromDate: new Date(moment(fromDate).format("YYYY-MM-DD")).toISOString(),
      toDate: new Date(moment(toDate).format("YYYY-MM-DD")).toISOString(),
    });
    if (result.data.msg) return alert("No data to export");
    let blob = new Blob([result.data], { type: "text/csv" });
    var a = document.createElement("a");
    a.download = "DeliveredAndRTOOrders.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
  };

  downloadInventoryCSV = async () => {
    let result = await axios.get("/api/warehouse/inventoryCSV");
    if (result.data.msg) return alert("No data to export");
    let blob = new Blob([result.data], { type: "text/csv" });
    var a = document.createElement("a");
    a.download = "Inventory.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );

    document.body.appendChild(a);
    a.click();
  };

  saleableProductCSV = async () => {
    let days = parseInt(prompt("Enter For last how many days \n INPUT(1/2)"));
    let threshold = parseInt(prompt("Enter Threshold \nINPUT(2/2)"));
    if (Number.isNaN(days) || Number.isNaN(threshold))
      return alert("Enter Valid Input");

    if (
      days &&
      threshold &&
      typeof days == "number" &&
      typeof threshold == "number"
    ) {
      let payload = {
        threshold: threshold,
        lastDays: days,
      };
      let result = await axios.post("/api/operations/saleableProduct", payload);
      alert("Saleable products CSV, Happy Analysing!");
      let blob = new Blob([result.data], { type: "text/csv" });
      let a = document.createElement("a");
      a.download = "SaleableProduct.csv";
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("style", "display:none;");
      a.textContent = "Download Saleable product CSV";

      a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
        ":"
      );
      document.body.appendChild(a);
      a.click();
    }
  };

  nonSaleableProductCSV = async () => {
    let days = parseInt(prompt("Enter For last how many days \n INPUT(1/2)"));
    let threshold = parseInt(prompt("Enter Threshold \nINPUT(2/2)"));
    if (Number.isNaN(days) || Number.isNaN(threshold))
      return alert("Enter Valid Input");

    if (
      days &&
      threshold &&
      typeof days == "number" &&
      typeof threshold == "number"
    ) {
      let payload = {
        threshold: threshold,
        lastDays: days,
      };
      // alert("Processing can take upto 30 seconds!");
      let result = await axios.post(
        "/api/operations/nonSaleableProduct",
        payload
      );
      alert("Non-Saleable products CSV, Happy Analysing!");
      let blob = new Blob([result.data], { type: "text/csv" });
      let a = document.createElement("a");
      a.download = "NonSaleableProduct.csv";
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("style", "display:none;");
      a.textContent = "Download Non-Saleable product CSV";
      a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
        ":"
      );
      document.body.appendChild(a);
      a.click();
    }
  };
  selectedColumns = (e, column, operation) => {
    let columnOptions = this.state.selectedColumnsObject;
    let status = "";

    if (operation == "add") status = true;
    else status = false;

    for (let option in columnOptions) {
      console.log(
        columnOptions[option].value,
        column,
        columnOptions[option].value === column
      );
      if (columnOptions[option].value === column)
        columnOptions[option].status = status;
    }

    console.log(columnOptions);

    this.setState({
      selectedColumnsObject: {
        ...columnOptions,
      },
    });
  };
  filterOrders = (id) => {
    this.setState({ search: true });

    let orders = [];
    if (id === "" || id === null || id === undefined) {
      this.setState({ search: false });
      this.setState({ showOrders: this.state.orders });
    } else {
      if (id.startsWith("--")) {
        orders = this.state.orders.filter((order) => {
          if (order.status_code)
            return order.status_code === dissolveStatus(id.slice(2));
        });
      } else {
        orders = this.state.orders.filter((order) => {
          //For ID and AWB search
          return (
            (order.awb_ref_no
              ? order.awb_ref_no.toString().includes(id.toUpperCase())
              : false) ||
            order.name.includes(id.toUpperCase()) ||
            (order.email ? order.email.includes(id) : false) ||
            (order.phone ? order.phone.includes(id) : false) ||
            (order.shipping_address.phone
              ? order.shipping_address.phone.includes(id)
              : false)
          );
        });
      }

      this.setState({ showOrders: orders });
    }
  };
  downloadDeliveredOrdersCSV = async (user) => {
    let products = [];
    let status_code = "";
    let scheduledThrough = "";

    let store = "";

    let fromDate = this.state.fromDate ? this.state.fromDate : new Date("2019");
    let toDate = this.state.toDate ? this.state.toDate : new Date();

    if (global.selected.length !== 0) {
      global.selected.forEach((element) => {
        products.push(element[1]);
      });
    }
    if (this.state.store !== "") {
      store = this.state.store.storeAnchor;
      scheduledThrough = document.getElementById("aggregator-selector").value;

      status_code = document.getElementById("statusSelector").value;
    }

    let payload = {
      fromDate,
      toDate,
      products,
      scheduledThrough,
      status_code,
      store,
    };

    if (user)
      payload.user = {
        user_id: user.user_id,
        name: user.name,
        email: user.email,
      };

    if (
      prompt(
        `Products selected ${products} from ${moment(fromDate).format(
          "DD/MM/YY"
        )} to ${moment(toDate).format("DD/MM/YY")} ("yes" to proceed)`
      ) !== "yes"
    )
      return;

    let result = await axios.post(
      "/api/operations/getDeliveredOrders",
      payload
    );

    alert(
      "Please wait while you will be automatically redirected to url!. A link to your email will be sent."
    );

    if (result.data.msg) {
      var a = document.createElement("a");
      a.download = "Details.csv";
      a.href = result.data.msg;
      a.target = "_blank";
      a.setAttribute("style", "display:none;");
      a.textContent = "Download CSV";

      a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
        ":"
      );

      document.body.appendChild(a);
      a.click();
    } else {
      alert("Error! Please Retry");
    }
  };

  setSuperStatusTags = (tags) => {
    this.setState({
      superStatusTags: tags,
    });
  };

  setDisabled = (disabledObj) => {
    this.setState({ disabled: disabledObj });
  };

  fetchFilters = () => {
    // fetch all the filters and put into one query
    const filterQueries = [];

    // aggregator filter
    const aggregator = document.getElementById("aggregator-selector").value;
    if (!["All", "Aggregator"].includes(aggregator)) {
      const aggregatorQuery = {
        scheduledThrough: aggregator,
      };
      filterQueries.push(aggregatorQuery);
    }

    // payment filter
    const paymentMethod = document.getElementById("paymentOptionSelector")
      .value;
    if (paymentMethod !== "0") {
      filterQueries.push({
        financial_status: paymentMethod,
      });
    }
    // store filter
    if (this.state.store) {
      const storeQuery = {
        "store.shopifyName": this.state.store.shopifyName,
      };
      filterQueries.push(storeQuery);
    }

    // product filter
    const productList = global.selected.map((item) => {
      return { "line_items.product_id": parseInt(item[0]) };
    });
    if (productList.length !== 0)
      filterQueries.push({
        $or: productList,
      });

    // status code filter
    let status_code = null;
    if (
      !document.getElementById("statusSelector").disabled &&
      !document.getElementById("superStatusSelector").disabled
    ) {
      // both status selectors not used
      status_code = parseInt(document.getElementById("statusSelector").value);
      if (status_code) {
        if (status_code === 999)
          filterQueries.push({
            status_code: { $exists: false },
          });
        else
          filterQueries.push({
            status_code,
          });
      }
    } else if (
      !document.getElementById("statusSelector").disabled &&
      document.getElementById("superStatusSelector").disabled
    ) {
      // status selector is used
      status_code = parseInt(document.getElementById("statusSelector").value);
      if (status_code) {
        if (status_code === 999)
          filterQueries.push({
            status_code: { $exists: false },
          });
        else
          filterQueries.push({
            status_code,
          });
      }
    } else {
      if (this.state.superStatusTags.length > 0) {
        filterQueries.push({
          status_code: { $in: this.state.superStatusTags },
        });
      }
    }
    return filterQueries;
  };

  //State variables
  state = {
    orders: [],
    products: [],
    showOrders: [],
    store: "",
    stamp: "",
    filtered: [],
    searchedOrders: [],
    search: true,
    fromDate: "",
    toDate: "",
    customRange: "",
    offset: 0,
    aggregators: [],
    limit: 20,
    selectedColumnsObject: {
      shipping_address: { status: false, value: "Shipping Address" },
      billing_address: { status: false, value: "Billing Address" },
      total_price: { status: false, value: "Total Price" },
      contact: { status: false, value: "Contact" },
      created_on: { status: false, value: "Created On" },
      scheduledOn: { status: false, value: "Scheduled On" },
      pickedUpOn: { status: false, value: "Picked Up On" },
      deliveredOn: { status: false, value: "Delivered On" },
      turnAroundTime: { status: false, value: "Turn Around Time" },
      products: { status: false, value: "Products" },
      courier_company_id: { status: false, value: "Courier Partner" },
    },
    superStatusTags: [],
    disabled: {
      statusSelector: false,
      superStatusSelector: false,
    },
    statusArray,
    statusAggregations,
    setDisabled: this.setDisabled,
    setSuperStatusTags: this.setSuperStatusTags,
    handleLimitChange: this.handleLimitChange,
    increaseOffset: this.increaseOffset,
    decreaseOffset: this.decreaseOffset,
    resetOffset: this.resetOffset,
    setDate: this.setDate,
    queryFilter: this.queryFilter,
    deliveryCalculation: this.deliveryCalculation,
    setFiltered: this.setFiltered,
    fetchOrders: this.fetchOrders,
    searchOrder: this.searchOrder,
    filterProducts: this.filterProducts,
    fetchInitialOrders: this.fetchInitialOrders,
    fetchAggregators: this.fetchAggregators,
    filterOrders: this.filterOrders,
    download: this.download,
    downloadFullCSV: this.downloadFullCSV,
    downloadConsolidatedOrdersCSV: this.downloadConsolidatedOrdersCSV,
    downloadInventoryCSV: this.downloadInventoryCSV,
    downloadFacebook: this.downloadFacebook,
    filterByDate: this.filterByDate,
    permuteOrders: this.permuteOrders,
    checkStatus: this.checkStatus,
    loading: false,
    downloadRTOandDelivered: this.downloadRTOandDelivered,
    saleableProductCSV: this.saleableProductCSV,
    nonSaleableProductCSV: this.nonSaleableProductCSV,
    downloadDeliveredOrdersCSV: this.downloadDeliveredOrdersCSV,
    selectedColumns: this.selectedColumns,
    fetchFilters: this.fetchFilters,
    perspectiveOption: "created_at",
    setPerspectiveOption: this.setPerspectiveOption,
    fetchAllOrders: this.fetchAllOrders,
    toggleLoading: this.toggleLoading,
    downloadSpecificOrders: this.downloadSpecificOrders,
    setSearchedOrders: this.setSearchedOrders,
    setMode: this.setMode,
    verboseMode: false,
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

//Registering a consumer who can access the global state
export const UserConsumer = UserContext.Consumer;

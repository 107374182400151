import React, { useContext, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { UserConsumer } from "../context/caller-context";
import Spinner from "../../../OrderTracker/components/Items/Spinner";

const PendingCalls = () => {
  const context = useContext(UserConsumer);
  const users = context.users;
  const [callBreakdownList, setCallBreakdownList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsersBreakdownList = async () => {
    setLoading(true);
    if (users.length !== 0) {
      let result,
        final = [];
      let config;
      // users.map(user => {
      //     if (user.name == "Team") return;
      //     console.log(user.name);
      //     result = await axios.get(
      //         `api/support/fetchCallBreakdown?name=${user.name}`
      //       );
      //       console.log(result.data);
      //       setCallBreakdownList((callBreakdownList) => [
      //         ...callBreakdownList,
      //         result,
      //       ]);
      // })
      for (let i = 0; i < users.length; i++) {
        // console.log(users[i].name);
        if (users[i].name == "Team") continue;
        config = {
          params: {
            name: users[i].name,
          },
        };
        result = await axios.get(`/api/support/fetchCallBreakdownV2`, {
          params: { name: users[i].name },
        });
        result.data.name = users[i].name;
        final.push(result.data);
      }
      console.log(final);
      let total = {
        callBreakdown: {
          "Abandoned Checkout": 0,
          "Address Issue": 0,
          Leads: 0,
          "Multiple Items": 0,
          "Multiple Quantity": 0,
          NDR: 0,
          "Repeat Customer": 0,
          Upsell: 0,
          "Order Confirmation": 0,
        },
      };
      const typeList = Object.keys(total.callBreakdown);
      final.map((userBreakdown) => {
        typeList.map((type) => {
          total.callBreakdown[type] += userBreakdown.callBreakdown[type];
        });
      });
      total.name = "Total";
      final.unshift(total);
      setCallBreakdownList([...final]);
      console.log(callBreakdownList);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("Inside the useEffect");
    console.log("The list of all users : ", users);
    getUsersBreakdownList();
  }, []);
  return loading ? (
    <Spinner />
  ) : (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        className="card card-5 stat-card "
        style={{ display: "flex", width: "100%", height: "100%" }}
      >
        <ul>
          {callBreakdownList.map((breakdown) => {
            return (
              <li className="dayLog">
                <div>
                  <b>{breakdown.name}</b>

                  {Object.keys(breakdown.callBreakdown).map((type) => {
                    return (
                      <li>{`${type} : ${breakdown.callBreakdown[type]}`}</li>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PendingCalls;

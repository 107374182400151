export const barcodeScan = (e, courierCompany) => {
  console.log(`loaded barcodeScan.js file`);
  e.preventDefault();

  document.getElementById("barcodeSubmitButton").disabled = true;

  const url = `/api/warehouse/barcode`;
  let scannedBarcodes = [];
  scannedBarcodes = document.getElementById("barcodes").value.split("\n");
  scannedBarcodes = scannedBarcodes.filter(
    (scannedBarcode) => scannedBarcode.length !== 0
  );
  scannedBarcodes = scannedBarcodes.map((scannedBarcode) =>
    scannedBarcode.trim().toUpperCase()
  );

  const data = {
    scannedBarcodes,
  };

  console.log("data = ", data);

  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      response.json().then((data) => {
        console.log(data);
        document.getElementById(
          `msg1`
        ).innerHTML = `<span class="scan-bar-badge badge-success">${data.msg1}</span><br />`;
        document.getElementById(
          `msg2`
        ).innerHTML = `<span class="scan-bar-badge badge-warning">${data.msg2}</span><br />`;
        document.getElementById(
          `msg3`
        ).innerHTML = `<span class="scan-bar-badge badge-warning">${data.msg3}</span><br />`;
      });
      document.getElementById("barcodeSubmitButton").disabled = false;
    })
    .catch((err) => {
      console.log(err.message);
      document.getElementById("barcodeSubmitButton").disabled = false;
    });
};

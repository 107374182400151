import React, { useState, useEffect } from "react";
import Axios from "axios";
import NavBar from "../res/NavBar";

function FavoriteProducts() {
  document.title = "Favourite Products";
  const [products, setProducts] = useState([]);
  const [store, setStore] = useState("");
  const favourite = async all => {
    let product_id = document.getElementById("favourite_input").value;
    if (all) {
      if (
        prompt("Are you sure you want to favourite all products?") === "yes"
      ) {
        let res = await Axios.post("/api/product/favourite", {
          product_id,
          store,
          all: true
        });
        getProducts();
      }
    } else {
      let res = await Axios.post("/api/product/favourite", {
        product_id,
        store
      });
    }
    alert("done");
    document.getElementById("favourite_input").value = "";
  };

  const unfavourite = async all => {
    let product_id = document.getElementById("favourite_input").value;
    if (all) {
      if (
        prompt("Are you sure you want to unfavourite all products?") === "yes"
      ) {
        let res = await Axios.post("/api/product/unFavourite", {
          product_id,
          store,
          all: true
        });
        getProducts();
      }
    } else {
      let res = await Axios.post("/api/product/unFavourite", {
        product_id,
        store
      });
    }
  };

  const getProducts = async store => {
    let result = await Axios.get(`/api/product?store=${JSON.stringify(store)}`);
    console.log("Products", result.data.products);
    setProducts(result.data.products);
  };

  useEffect(() => {
    getProducts(store);
  }, []);

  console.log("Store", store);
  return (
    <div>
      <NavBar store={store} setStore={setStore} fetchOrders={getProducts} />
      {store ? (
        <form>
          <div className="form-group resourceForm">
            <input
              type="text"
              list="productlist"
              className="form-control"
              id={`favourite_input`}
              placeholder={`Search Product Name`}
              autoComplete="off"
            />

            <datalist id="productlist">
              {products.map((product, i) =>
                !product.favourite ? (
                  <option value={product.product_id} key={i}>
                    {product.title}
                  </option>
                ) : (
                  ""
                )
              )}
            </datalist>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px"
            }}
          >
            <div
              style={{
                marginBottom: "20px"
              }}
            >
              <button
                type="button"
                id={`favourite_btn`}
                className="btn btn-success btn-product-add"
                onClick={() => favourite()}
              >
                Favourite
              </button>
              <button
                type="button"
                id={`favourite_btn_all`}
                className="btn btn-success btn-product-add"
                onClick={() => favourite("all")}
              >
                Favourite All
              </button>
            </div>

            <div>
              <button
                type="button"
                id={`unfavourite_btn`}
                className="btn btn-success btn-product-add"
                onClick={() => unfavourite()}
              >
                UnFavourite
              </button>
              <button
                type="button"
                id={`unfavourite_btn_all`}
                className="btn btn-success btn-product-add"
                onClick={() => unfavourite("all")}
              >
                UnFavourite All
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div>
          <center>
            <h2>Choose a store to begin favouriting</h2>
          </center>
        </div>
      )}
    </div>
  );
}

export default FavoriteProducts;

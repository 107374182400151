import React, { useState, Fragment } from "react";
import moment from "moment";

function DatePicker({
  permuteOrders,
  setFromDate,
  setToDate,
  isContextBased,
  setDate,
}) {
  const [type, setType] = useState("preset");
  const handleFocusDate = () => {
    document.getElementById("datePickerFrom").type = "datetime-local";
    document.getElementById("datePickerTo").type = "datetime-local";
  };
  const handleBlurDate = () => {
    document.getElementById("datePickerFrom").type = "text";
    document.getElementById("datePickerTo").type = "text";
  };

  const handleChoice = () => {
    if (document.getElementById("datePickerRange").value === "Custom Range")
      setType("custom");
    else {
      let fromDate = document.getElementById("datePicker")
        ? new Date(document.getElementById("datePicker").value)
        : new Date("2019");

      let toDate = document.getElementById("datePickerTo")
        ? new Date(document.getElementById("datePickerTo").value)
        : new Date();

      if (document.getElementById("datePickerRange").value === "Today") {
        fromDate = moment().startOf("day");
        toDate = moment().endOf("day");

        if (isContextBased) {
          setDate(fromDate, toDate);
        } else {
          setFromDate(fromDate);
          setToDate(toDate);
        }
      } else if (
        document.getElementById("datePickerRange").value === "Yesterday"
      ) {
        toDate = moment().subtract(1, "days").endOf("day");

        fromDate = moment().subtract(1, "days").startOf("day");

        if (isContextBased) {
          console.log(fromDate, toDate);
          setDate(fromDate, toDate);
        } else {
          setFromDate(fromDate);
          setToDate(toDate);
        }
      } else if (
        document.getElementById("datePickerRange").value === "This Week"
      ) {
        fromDate = moment()
          // .subtract(7, "days")
          .startOf("week");
        toDate = moment().endOf("week");

        if (isContextBased) {
          setDate(fromDate, toDate);
        } else {
          setFromDate(fromDate);
          setToDate(toDate);
        }
      } else if (
        document.getElementById("datePickerRange").value === "Last Week"
      ) {
        fromDate = moment().subtract(1, "weeks").startOf("week");
        toDate = moment().subtract(1, "weeks").endOf("week");

        if (isContextBased) {
          setDate(fromDate, toDate);
        } else {
          setFromDate(fromDate);
          setToDate(toDate);
        }
      } else if (
        document.getElementById("datePickerRange").value === "This Month"
      ) {
        fromDate = moment().startOf("month");
        toDate = moment().endOf("month");

        if (isContextBased) {
          setDate(fromDate, toDate);
        } else {
          setFromDate(fromDate);
          setToDate(toDate);
        }
      } else if (
        document.getElementById("datePickerRange").value === "Last Month"
      ) {
        fromDate = moment().subtract(1, "months").startOf("month");
        toDate = moment().subtract(1, "months").endOf("month");

        if (isContextBased) {
          setDate(fromDate, toDate);
        } else {
          setFromDate(fromDate);
          setToDate(toDate);
        }
      }
    }
    // permuteOrders();
  };

  return type === "custom" ? (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <input
        className="form-control datepicker "
        placeholder="From"
        type="text"
        onFocus={() => handleFocusDate()}
        onBlur={() => handleBlurDate()}
        id="datePickerFrom"
        style={{
          height: "100%",
          borderRadius: 0,
          width: "100%",
          minWidth: "25vw",
        }}
        onChange={(e) => {
          if (!isContextBased) setFromDate(e.target.value);
          else setDate(e.target.value, new Date(), "custom");
          //   permuteOrders();
        }}
      />

      <input
        className="form-control datepicker "
        placeholder="To"
        type="text"
        onFocus={() => handleFocusDate()}
        onBlur={() => handleBlurDate()}
        id="datePickerTo"
        style={{
          height: "100%",
          borderRadius: 0,
          width: "100%",
          minWidth: "25vw",
        }}
        onChange={(e) => {
          if (!isContextBased) setToDate(e.target.value);
          else setDate(e.target.value, new Date("2019"), "custom");

          //   permuteOrders();
        }}
      />
      <button
        style={{
          fontSize: "9px",
          width: "20px",
          background: "#DC3545",
          color: "white",
        }}
        onClick={() => setType("preset")}
      >
        <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
      </button>
    </div>
  ) : (
    <div style={{ width: "100%", height: "100%" }}>
      <select
        placeholder="Products"
        id="datePickerRange"
        className="form-control form-control-sm "
        style={{
          height: "100%",
          fontSize: "0.9rem",
          borderRadius: 0,
          width: "100%",
          minWidth: "25vw",
        }}
        onChange={() => handleChoice()}
      >
        <option value={0} selected disabled>
          Choose a date range
        </option>
        <option>Today</option>
        <option>Yesterday</option>
        <option>This Week</option>
        <option>Last Week</option>
        <option>This Month</option>
        <option>Last Month</option>
        <option>Custom Range</option>
      </select>
    </div>
  );
}

export default DatePicker;

import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/home/res/Spinner";

const ForgotPassword = (props) => {
  document.title = "Reset Password";
  const history = useHistory();
  const [resetPasswordRandomString, setResetPasswordRandomString] = useState(
    null
  );
  const [showPage, setShowPage] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleResetString = () => {
    const {
      match: {
        params: { randomString },
      },
    } = props;
    console.log(randomString);
    setResetPasswordRandomString(randomString);
  };

  const handleShowPage = async () => {
    console.log(
      "checking if random string is set before sending to showResetPass: ",
      resetPasswordRandomString
    );
    if (resetPasswordRandomString !== null) {
      const {
        data: { showPage },
      } = await axios.post("/api/auth/showResetPasswordPage", {
        resetPasswordRandomString,
      });
      console.log("show page : ", showPage);
      setShowPage(showPage);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = document.getElementById("email-address").value;
    const password = document.getElementById("new-password").value;
    const confirmPassword = document.getElementById("confirm-new-password")
      .value;
    if (password !== confirmPassword) {
      alert("Password entered does not match! Try again!");
      return;
    }
    if (password.length < 4) {
      alert("Password is too short! try again!");
      return;
    }
    const response = await axios.post("/api/auth/resetPassword", {
      email,
      password,
      resetPasswordRandomString,
    });
    if (response.data.success) {
      setTimeout(() => {
        history.push("/login");
      }, 2000);
      alert(response.data.msg);
    } else {
      alert(response.data.msg);
    }
  };

  useEffect(() => {
    handleResetString();
    handleShowPage();
  }, [resetPasswordRandomString]);

  return loading == true ? (
    <div className="login-container">
      <Spinner />
    </div>
  ) : showPage == null ? (
    <div className="login-container">
      <Spinner />
    </div>
  ) : showPage == true ? (
    <div className="login-container">
      <div className="login">
        <h1 className="large text-primary">Reset Password</h1>
        <form className="form">
          <div className="form-group">
            <input
              id="email-address"
              type="email"
              placeholder="Email Address"
              name="email"
            />
          </div>
          <div className="form-group">
            <input
              id="new-password"
              type="password"
              placeholder="New Password"
              minLength="4"
            />
          </div>
          <div className="form-group">
            <input
              id="confirm-new-password"
              type="password"
              placeholder="Confirm New Password"
              minLength="4"
            />
          </div>
          <button
            type="submit"
            style={{ width: "100%" }}
            className="btn btn-primary"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  ) : (
    <Redirect to="/login"></Redirect>
  );
};

export default ForgotPassword;

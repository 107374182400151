import React from "react";

const SmsMarketingTabs = ({ setCurrentTab }) => {
  return (
    <div className="sms-marketing-top-tabs">
      <button
        className="bz-pill-buttons-lg"
        onClick={() => setCurrentTab("campaignDataTab")}
      >
        Create Campaign
      </button>
      <button
        className="bz-pill-buttons-lg"
        onClick={() => setCurrentTab("viewCampaignsTab")}
      >
        View Campaigns
      </button>
      <button
        className="bz-pill-buttons-lg"
        onClick={() => setCurrentTab("blastSMSTab")}
      >
        Blast SMS
      </button>
    </div>
  );
};

export default SmsMarketingTabs;

import React, { useState, useRef } from "react";
import DatePickerReact from "react-date-picker";
import moment from "moment";

export default function useDatePicker(searchFromDate) {
  let lastMonth;
  if (!searchFromDate) {
    lastMonth = moment().subtract(1, "months");
  } else {
    lastMonth = moment(searchFromDate);
  }

  const [fromDate, setFromDate] = useState(lastMonth.startOf("day"));
  const [toDate, setToDate] = useState(moment().endOf("day"));
  const [type, setType] = useState("Choose a date range");

  const dropdownRef = useRef(null);

  const handleChoice = () => {
    const choice = dropdownRef.current.value;

    if (!choice.includes("Custom")) {
      if (choice === "Today") {
        // TODAY
        setFromDate(moment().startOf("day"));
        setToDate(moment().endOf("day"));
      } else if (choice === "Yesterday") {
        // YESTERDAY
        setFromDate(moment().subtract(1, "days").startOf("day"));
        setToDate(moment().subtract(1, "days").endOf("day"));
      } else if (choice === "This Week") {
        // THIS WEEK
        setFromDate(moment().startOf("week"));
        setToDate(moment().endOf("week"));
      } else if (choice === "Last Week") {
        // LAST WEEK
        setFromDate(moment().subtract(1, "weeks").startOf("week"));
        setToDate(moment().subtract(1, "weeks").endOf("week"));
      } else if (choice === "This Month") {
        // THIS MONTH
        setFromDate(moment().startOf("month"));
        setToDate(moment().endOf("month"));
      } else if (choice === "Last Month") {
        // LAST MONTH
        setFromDate(moment().subtract(1, "months").startOf("month"));
        setToDate(moment().subtract(1, "months").endOf("month"));
      }
    } else {
      // CUSTOM RESET
      setFromDate(lastMonth.startOf("day"));
      setToDate(moment().endOf("day"));
    }

    setType(choice);
  };

  const handleCustomChoiceFromDate = (date) => {
    // ONLY FROM DATE
    if (date) setFromDate(moment(date).startOf("day"));
    else setFromDate(lastMonth.startOf("day"));
  };

  const handleCustomChoiceToDate = (date) => {
    // ONLY TO DATE
    if (date) setToDate(moment(date).endOf("day"));
    else setToDate(moment().endOf("day"));
  };

  const clearDate = () => {
    setType("Choose a date range");
    setFromDate(lastMonth.startOf("day"));
    setToDate(moment().endOf("day"));
  };

  const DatePicker = () =>
    type.includes("Custom") ? (
      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        <DatePickerReact
          onChange={handleCustomChoiceFromDate}
          value={new Date(fromDate)}
        />

        <DatePickerReact
          onChange={handleCustomChoiceToDate}
          value={new Date(toDate)}
        />

        <button
          style={{
            fontSize: "9px",
            width: "20px",
            background: "#DC3545",
            color: "white",
          }}
          onClick={() => {
            setType("");
            setFromDate(lastMonth.startOf("day"));
            setToDate(moment().endOf("day"));
          }}
        >
          <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
        </button>
      </div>
    ) : (
      <div style={{ width: "100%", height: "100%" }}>
        <select
          placeholder="Products"
          id="datePickerRange"
          className="form-control form-control-sm defaultInput"
          value={type}
          ref={dropdownRef}
          onChange={() => handleChoice()}
        >
          <option selected disabled>
            Choose a date range
          </option>
          <option>Today</option>
          <option>Yesterday</option>
          <option>This Week</option>
          <option>Last Week</option>
          <option>This Month</option>
          <option>Last Month</option>
          <option>Custom Range</option>
        </select>
      </div>
    );

  return [DatePicker, fromDate, toDate, clearDate];
}

import React, { useEffect, useState, Fragment } from "react";
import Product from "../res/Product";
import NavBar from "../res/NavBar";
import Axios from "axios";

const Products = () => {
  const [store, setStore] = useState("");
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState([]);
  document.title = "Add/Update ProductDimensions";
  const fetchProducts = (store, page) => {
    if (!page) page = 1;
    Axios.get(
      `/api/product/paginateProducts/?store=${JSON.stringify(
        store
      )}&page=${page}`
    )
      .then(response => {
        console.log(response);
        setProducts(response.data.products);
        setTotalProducts(response.data.totalProducts);
        setCurrentPageNumber(page);
      })
      .catch(err => {
        console.log(err.message.msg);
      });
  };
  const createList = totalProductsCount => {
    let elements = [];
    let pagination = totalProductsCount / 10;
    if (totalProductsCount % 10 != 0) {
      pagination = pagination + 1;
    }
    for (let i = 1; i <= pagination; i++) {
      elements.push(
        <li className="page-item">
          <a className="page-link" onClick={() => fetchProducts(store, i)}>
            {i}
          </a>
        </li>
      );
    }
    return elements;
  };
  useEffect(() => {
    // fetchProducts(store);
  }, []);

  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} fetchOrders={fetchProducts} />
      {store ? (
        <div className="container-fluid">
          <nav aria-label="Page navigation example">
            <ul
              style={{ display: "flex", flexWrap: "wrap", cursor: "pointer" }}
              className="pagination"
            >
              {/* <li className="page-item">
                <a className="page-link" href="#">
                  Previous
                </a>
              </li> */}
              {createList(totalProducts)}
              {/* <li className="page-item">
                <a className="page-link" href="#">
                  Next
                </a>
              </li> */}
            </ul>
          </nav>

          <br />
          <div>
            <center>PageNumber: {currentPageNumber}</center>
          </div>
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <div className="card text-center">
              <div className="card-header">
                <h4>Add/Update Product Dimensions</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Product Variants</th>
                      </tr>
                    </thead>
                    <tbody style={{ width: "100%" }}>
                      {products.map((product, index) => (
                        <Product
                          key={index}
                          product={product}
                          index={index}
                          store={store}
                          fetchProducts={fetchProducts}
                          currentPageNumber={currentPageNumber}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation example">
            <ul
              style={{ display: "flex", flexWrap: "wrap", cursor: "pointer" }}
              className="pagination"
            >
              {/* <li className="page-item">
                <a className="page-link" href="#">
                  Previous
                </a>
              </li> */}
              {createList(totalProducts)}
              {/* <li className="page-item">
                <a className="page-link" href="#">
                  Next
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>Choose a store</h2>
        </div>
      )}
    </Fragment>
  );
};

export default Products;

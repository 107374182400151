import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import SurfaceProductsTagbar from "../../settings/res/SurfaceProductsTagbar";

const NavBar = ({ store, setStore }) => {
  const [stores, setStores] = useState([]);

  const getStoresList = async () => {
    let result = await axios.get("/api/stores");
    setStores(result.data);
  };
  useEffect(() => {
    getStoresList();
  }, []);

  return (
    <nav className="navbar jarvisBar">
      <div className="dropdown chooseStorePurple">
        <button
          className="btn  dropdown-toggle storeButton"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {store ? store.storeName : "Choose store"}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {stores.map((store, index) => (
            <button
              key={index}
              className="dropdown-item"
              onClick={() => {
                setStore(store);
              }}
            >
              {store.storeName}
            </button>
          ))}
        </div>
      </div>
      <span
        className="navbar-text"
        style={{
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        Surface Product Configuration
      </span>
    </nav>
  );
};

const SurfaceConfiguration = ({ show }) => {
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [surfaceProducts, setSurfaceProducts] = useState([]);

  const multiCheck = (e) => {
    const selectedProduct = products.find(
      (product) => product.product_id == e.target.value
    );
    if (selectedProduct) {
      e.target.blur();
      e.target.value = "";
      const newArray = filtered.filter((product) => {
        if (product.product_id == selectedProduct.product_id) {
          return false;
        } else return true;
      });
      setFiltered(newArray);
      setSurfaceProducts((surfaceProducts) => {
        return [...surfaceProducts, selectedProduct];
      });
    }
  };

  const fetchProducts = async (store) => {
    const params = { store: JSON.stringify(store) };
    let productList = await axios.get(`/api/product/`, { params });
    productList = productList.data.products.map((product) => {
      return {
        title: product.title,
        product_id: product.product_id,
        surface: product.surface ? true : false,
      };
    });
    const newArr = [];
    const filteredProductList = productList.filter((product) => {
      if (!product.surface) {
        return true;
      } else {
        newArr.push(product);
        return false;
      }
    });
    setProducts(productList);
    setFiltered(filteredProductList);
    setSurfaceProducts(newArr);
  };

  const removeSelectedProduct = async (product) => {
    const newArr = surfaceProducts.filter((surfaceProduct) => {
      if (product.product_id === surfaceProduct.product_id) {
        return false;
      } else {
        return true;
      }
    });
    setFiltered((filtered) => [...filtered, product]);
    setSurfaceProducts(newArr);
    const body = {
      store,
      product_id: parseFloat(product.product_id),
    };
    const response = await axios.post(
      "/api/product/deleteSurfaceProduct",
      body
    );
    alert(response.data.message);
  };

  const selectedProductSubmit = async () => {
    const products = surfaceProducts.map((product) => {
      return parseFloat(product.product_id);
    });
    const body = {
      store,
      products,
    };
    const response = await axios.post("/api/product/saveSurfaceProducts", body);
    alert(response.data.message);
  };

  useEffect(() => {
    if (store) {
      fetchProducts(store);
    }
  }, [store]);

  return (
    <Fragment>
      <div style={{ color: "#9B59B6", marginLeft: "2vw", marginTop: "2vh" }}>
        <i
          className="fas fa-2x fa-arrow-circle-left back-button-icon"
          onClick={() => show(false)}
        ></i>
      </div>
      <div className="surface-product-config-container">
        <NavBar store={store} setStore={setStore} />
        {!store ? (
          <div style={{ marginBottom: "2vh" }}>
            Please choose a store to proceed!
          </div>
        ) : (
          <div className="settings-surface-products-container">
            <label htmlFor="surfaceProducts">Surface Products</label>
            <input
              type="text"
              list="productList"
              autoComplete="off"
              className="form-control"
              placeholder="Search product here ..."
              id="surfaceProducts"
              onChange={(e) => {
                multiCheck(e);
              }}
            />
            <datalist id="productList">
              {filtered.map
                ? filtered.map((option, index) => (
                    <option
                      key={index}
                      data-value={option}
                      value={option.product_id}
                    >
                      {option.title}
                    </option>
                  ))
                : null}
            </datalist>
            <SurfaceProductsTagbar
              surfaceProducts={surfaceProducts}
              removeSelectedProduct={removeSelectedProduct}
            />
            <button onClick={selectedProductSubmit}>
              Save Surface Products
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SurfaceConfiguration;

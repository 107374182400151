import React from "react";

const CustomTagBarProperty = ({ item }) => {
  const itemKeys = Object.keys(item);
  return (
    <div className="tag-item">
      <span>
        {`${item[itemKeys[0]]} ${item.equality ? item.equality : "-"} ${
          item[itemKeys[1]]
        } `}
      </span>
    </div>
  );
};

export default CustomTagBarProperty;

import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../global/Spinner";

function TemporaryProductNames() {
  const [name, setName] = useState("");
  const [swapName, setSwapName] = useState("");
  const [temporaryNames, setTemporaryNames] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchTemporaryProductNames() {
    setLoading(true);
    const { data } = await axios.get(`/api/operations/temporaryProductNames`);
    if (!data.status) {
      alert(`Error - ${data.message}`);
      return;
    }
    setTemporaryNames(data.temporaryNames);
    setLoading(false);
  }

  async function saveNewTemporaryProductName() {
    if (!name || !swapName) {
      alert(`Inputs cannot be empty!`);
      return;
    }

    let newName = name.replace("(ESSENTIAL PRODUCTS) ", "").trim();
    let newSwapName = swapName.replace("(ESSENTIAL PRODUCTS) ", "").trim();
    const payload = {
      name: newName,
      swapName: newSwapName,
    };
    const { data } = await axios.post(
      `/api/operations/temporaryProductNames`,
      payload
    );
    if (!data.status) {
      alert(`Error - ${data.message}`);
      return;
    }
    setName("");
    setSwapName("");
    fetchTemporaryProductNames();
  }

  // async function handleEdit(toBeEditedName) {

  // }

  async function handleDelete(toBeDeletedName) {
    const { data } = await axios.post(
      `/api/operations/temporaryProductNames/delete`,
      {
        name: toBeDeletedName,
      }
    );
    if (!data.status) {
      alert(`Error - ${data.message}`);
      return;
    }
    fetchTemporaryProductNames();
  }

  useEffect(() => {
    fetchTemporaryProductNames();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <div className="temporary-product-names-container">
        <div className="temporary-product-names-add-new">
          <input value={name} onChange={(e) => setName(e.target.value)}></input>
          <div>SWAP WITH</div>
          <input
            value={swapName}
            onChange={(e) => setSwapName(e.target.value)}
          ></input>
          <div className="save-btn" onClick={saveNewTemporaryProductName}>
            SAVE
          </div>
        </div>
        <div className="temporary-product-names-all">
          {temporaryNames.map((temporaryNameObj, index) => (
            <div key={index} className={"temporary-product-names-obj"}>
              <div className={"temporary-product-names-obj-description"}>
                {`${index + 1}) ${temporaryNameObj.name}`} {` => `}{" "}
                {`${temporaryNameObj.swapName}`}
              </div>
              <div className="temporary-product-names-obj-controls">
                <span>
                  {/* <i
                    class="fas fa-edit"
                    onClick={() => handleEdit(temporaryNameObj)}
                  ></i> */}
                </span>
                <span>
                  <i
                    class="fas fa-trash-alt"
                    onClick={() => handleDelete(temporaryNameObj.name)}
                  ></i>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TemporaryProductNames;

import React, { useContext, Fragment } from "react";
import scroll from "../../res/scroll.gif";
import { UserConsumer } from "../context/caller-context";
import StatsBody from "../../res/StatsBody";

const CallLog = ({ user }) => {
  const context = useContext(UserConsumer);

  const filtered = context.filtered ? context.filtered : {};

  const callCountTotal = (object) => {
    let total = 0;
    Object.keys(object).map((type) => {
      if (!["date", "count"].includes(type)) {
        Object.keys(object[type]).map((subtype) => {
          if (object[type][subtype]) total += object[type][subtype];
        });
      }
    });
    return { calculatedTotal: total, givenTotal: object.count };
  };

  return (
    <div
      className="card card-5 stat-card "
      style={{ display: "flex", width: "100%" }}
    >
      <div style={{ padding: "0" }}>
        {Object.keys(filtered).length > 0 ? (
          <div>
            {filtered.map((log) => {
              return (
                <div
                  className="log-body"
                  style={
                    log.final
                      ? { border: "8px solid #00B894", borderBottom: "0px" }
                      : {}
                  }
                >
                  {log.OrderConfirmation.Total !== 0 ||
                  log.NDR.Total !== 0 ||
                  log.AbandonedCheckout.Total !== 0 ? (
                    <Fragment>
                      <div className="call-stats-head">{log.date}</div>

                      <div>
                        {log.OrderConfirmation.Total > 0 ? (
                          <StatsBody type={"Order Confirmation"} log={log} />
                        ) : (
                          ""
                        )}
                        {log.AbandonedCheckout.Total > 0 ? (
                          <StatsBody type={"Abandoned Checkout"} log={log} />
                        ) : (
                          ""
                        )}
                        {log.NDR.Total > 0 ? (
                          <StatsBody type={"NDR"} log={log} />
                        ) : (
                          ""
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        ) : user.logs ? (
          <ul>
            {Object.keys(user.logs).map((day) => {
              return day ? (
                <li className="dayLog" key={day}>
                  {callCountTotal(user.logs[day]).calculatedTotal}{" "}
                  {callCountTotal(user.logs[day]).calculatedTotal !== 1
                    ? "calls"
                    : "call"}{" "}
                  on {new Date(user.logs[day]["date"]).toDateString()}
                </li>
              ) : (
                ""
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </div>

      <img className="scrollGIF" src={scroll} />
    </div>
  );
};

export default CallLog;

import React, { Fragment, useState, useEffect } from "react";
import CourierItem from "../res/CourierItem";

const CourierList = ({ couriers, previousCourier, handleSubmit }) => {
  const [mode, setMode] = useState("Surface");
  const [courierList, setCourierList] = useState([]);

  const handleMode = (e) => {
    const modes = document.getElementsByClassName("courier-mode-tab-buttons");
    Object.values(modes).map((button) => {
      button.className = "courier-mode-tab-buttons";
    });
    e.target.className += " active";
    setMode(e.target.innerHTML);
  };

  useEffect(() => {
    setCourierList(couriers);
  }, [couriers]);
  return (
    <Fragment>
      <div className="courier-mode-tabs nav">
        <button
          className="courier-mode-tab-buttons"
          onClick={(e) => handleMode(e)}
        >
          Surface
        </button>
        <button
          className="courier-mode-tab-buttons"
          onClick={(e) => handleMode(e)}
        >
          Air
        </button>
      </div>
      <div className="courier-mode-tab-content">
        {mode == "Surface"
          ? courierList.filter((courier) => courier.is_surface).length == 0
            ? "No surface couriers available!"
            : courierList.map((courier) => {
                if (courier.is_surface) {
                  return (
                    <CourierItem
                      courier={courier}
                      previousCourier={previousCourier}
                      key={courier.courier_company_id}
                      handleSubmit={handleSubmit}
                    />
                  );
                }
              })
          : courierList.filter((courier) => !courier.is_surface).length == 0
          ? "No air couriers available!"
          : courierList.map((courier) => {
              if (!courier.is_surface) {
                return (
                  <CourierItem
                    courier={courier}
                    previousCourier={previousCourier}
                    key={courier.courier_company_id}
                    handleSubmit={handleSubmit}
                  />
                );
              }
            })}
      </div>
    </Fragment>
  );
};

export default CourierList;

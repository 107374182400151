exports.getCourierName = (courierCompanyId) => {
  const courierList = {
    1: "Blue Dart",
    4: "Amazon Shipping 5Kg",
    6: "DTDC Surface",
    10: "Delhivery",
    18: "DTDC 5kg",
    22: "Bluedart Surface 500 gms- Select",
    24: "Xpressbees 2kg",
    25: "Xpressbees 5kg",
    29: "Amazon Shipping 1Kg",
    32: "Amazon Shipping 2Kg",
    33: "Xpressbees",
    35: "Aramex International",
    39: "Delhivery Surface 5 Kgs",
    43: "Delhivery Surface",
    46: "Shadowfax Reverse",
    48: "Ekart Logistics",
    51: "Xpressbees Surface",
    54: "Ekart Logistics Surface",
    55: "Blue Dart Surface",
    58: "Shadowfax Surface",
    60: "Ecom Premium and ROS",
    61: "Delhivery Reverse",
    69: "Kerry Indev Express Surface",
    82: "DTDC 2kg",
    95: "Shadowfax Local",
    97: "Dunzo Local",
    100: "Delhivery Surface 10 Kgs",
    101: "Delhivery Surface 20 Kgs",
    106: "Borzo",
    107: "Borzo 5 Kg",
    125: "Xpressbees Reverse",
    130: "Ekart 10kg",
    138: "Delhivery Reverse 5kg",
    140: "SRX Premium",
    142: "Amazon Surface 500gm Prepaid",
    144: "Xpressbees Reverse 2kg",
    145: "Xpressbees Reverse 5 kg",
    146: "Kerry Indev 2kg Surface",
    150: "Xpressbees Reverse 1kg",
    154: "Kerry Indev Express",
    159: "Xpressbees 10kg",
    170: "Ekart 2Kg",
    171: "Ekart 5Kg",
    181: "Amazon Shipping 10Kg",
    182: "Amazon Shipping 20Kg",
    195: " Amazon Surface 500gm COD",
    196: "DTDC 500GMS",
    207: "DTDC Surface 10Kg",
    208: "DTDC Surface 20Kg",
    235: "DTDC Express",
    240: "SRX Priority",
    242: "Aramex International GPX",
    524: "Sm Shadowfax Surface 3Kg",
  };
  return courierList[courierCompanyId];
};

import React, { useState, useEffect } from "react";
import Axios from "axios";
import DamagedImage from "../res/DamagedImage.js";

const DamagedProductImage = () => {
  const [orders, setOrders] = useState([]);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [ordersLength, setOrdersLength] = useState(-1);
  const [totalDamagedImages, setTotalDamagedImages] = useState(0);
  document.title = "Damaged Product Images";
  const fetchOrders = async () => {
    let damagedOrders = [];
    let data = {
      params: {
        limit,
        offset,
      },
    };
    let result = await Axios.get(
      "/api/operations/retrieveDamagedProductImages",
      data
    );
    if (result.data.err) return alert(result.data.err);
    if (result.data.images.length >= 1)
      damagedOrders.push(...result.data.images);
    setOrders(damagedOrders);
    setOrdersLength(damagedOrders.length);
    setTotalDamagedImages(result.data.totalDamagedImages);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };
  const handleDisablingOfPaginationButtons = () => {
    if (offset === 0) {
      document.getElementById("previous-page").disabled = true;
    } else {
      document.getElementById("previous-page").disabled = false;
    }
    if (ordersLength < limit) {
      document.getElementById("next-page").disabled = true;
    } else {
      document.getElementById("next-page").disabled = false;
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [limit, offset]);
  useEffect(() => {
    handleDisablingOfPaginationButtons();
  }, [limit, offset, ordersLength]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "20px",
          marginBottom: "15px",
          marginTop: "21px",
          height: "7vh",
          marginLeft: "20px",
        }}
      >
        <div
          style={{
            margin: "0px",
            background: "white",
            color: "black",
            marginRight: "20px",
            height: "100%",
            width: "100%",
            boxShadow:
              "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
          }}
        >
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <strong>{totalDamagedImages}</strong> Damaged Products
          </div>
        </div>
        <select
          className="form-control form-control-sm"
          style={{
            height: "100%",
            fontSize: "0.9rem",
            borderRadius: 0,
            width: "7%",
            paddingLeft: 30,
          }}
          onChange={(event) => handleLimitChange(event)}
          value={limit}
        >
          <option defaultValue>20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">
              <center>Store</center>
            </th>
            <th scope="col">
              <center>OrderID</center>
            </th>
            <th scope="col">
              <center>AWB</center>
            </th>
            <th scope="col">
              <center>Product Damaged</center>
            </th>
            <th scope="col">
              <center>AWB Scanned On</center>
            </th>
            <th scope="col">
              <center>Image Uploaded On</center>
            </th>
            <th scope="col">
              <center>SR Credited Amount</center>
            </th>
          </tr>
        </thead>
        <tbody>
          {orders
            ? orders.map((order) => (
                <DamagedImage
                  order={order}
                  store={order.store}
                  fetchOrders={fetchOrders}
                />
              ))
            : ""}
        </tbody>
      </table>
      <div className="pagination">
        <button
          id="previous-page"
          className="pagination-button"
          onClick={() => setOffset(offset - limit > 0 ? offset - limit : 0)}
        >
          {offset !== 0 ? <i className="fas fa-arrow-circle-left"></i> : ""}
        </button>
        <button
          id="next-page"
          className="pagination-button"
          onClick={() => setOffset(orders.length >= limit ? offset + limit : 0)}
        >
          {ordersLength >= limit ? (
            <i className="fas fa-arrow-circle-right"></i>
          ) : (
            ""
          )}
        </button>
      </div>
    </div>
  );
};

export default DamagedProductImage;

import React, { useContext, useState } from "react";
import Axios from "axios";
import { GlobalConsumer } from "../../Global-Context/global-context";

function RuleItem({ fetchRules, rule, type }) {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user, setUser] = useState(userInfo);
  const handleClick = async e => {
    let element = JSON.parse(e.target.id);
    console.log(element);
    if (prompt("Type CONFIRMED to proceed") === "CONFIRMED") {
      let res = await Axios.post("/api/IMS/unMapRule", element);
      if (res.data.msg) fetchRules();
    }
  };

  return rule[type] && user ? (
    user.master ? (
      <div className="itemTag">
        <div
          className="cross"
          id={`${JSON.stringify({
            store: rule.store,
            type,
            product_id: rule.product_id,
            variant_id: rule.variant_id
          })}`}
          style={{ marginRight: "15px" }}
          onClick={e => handleClick(e)}
        >
          x
        </div>
        <div>{`${rule.name} ${
          type === "mutation"
            ? `|ORIGINAL: ${rule.original_quantity}| |SUBSTITUTED: ${rule.mutated_quantity}|`
            : ""
        }`}</div>
      </div>
    ) : user.stores ? (
      user.stores.includes(rule.store) ? (
        <div className="itemTag">
          <div
            className="cross"
            id={`${JSON.stringify({
              store: rule.store,
              type,
              product_id: rule.product_id,
              variant_id: rule.variant_id
            })}`}
            style={{ marginRight: "15px" }}
            onClick={e => handleClick(e)}
          >
            x
          </div>
          <div>{`${rule.name} ${
            type === "mutation"
              ? `|ORIGINAL: ${rule.original_quantity}| |SUBSTITUTED: ${rule.mutated_quantity}|`
              : ""
          }`}</div>
        </div>
      ) : (
        ""
      )
    ) : (
      ""
    )
  ) : (
    ""
  );
}

export default RuleItem;

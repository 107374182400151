import React, { useEffect, useContext, useState } from "react";
import { userInfo } from "os";
import { GlobalConsumer } from "../../../Global-Context/global-context";
import AccessOption from "../res/AccessOption";
import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";

const Access = () => {
  document.title = "Edit Access";
  let context = useContext(GlobalConsumer);
  const [user, setUser] = useState();
  const [users, setUsers] = useState();
  const [name, setName] = useState();
  const [accessList, setAccessList] = useState();

  const fetchUsers = async () => {
    let userList = await Axios.get("/api/auth/userList");
    let accessList = await Axios.get("/api/auth/accessListV2");

    setAccessList(accessList.data.list);
    setUsers(userList.data.list);
  };

  const handleEdit = (checked, option) => {
    let temp = [];
    if (checked) {
      submitAccess(option, "add");
    } else {
      submitAccess(option, "remove");
    }
  };
  const handleEditApiList = (checked, option) => {
    if (checked) {
      submitApiList(option, "remove");
    } else {
      submitApiList(option, "add");
    }
  };
  const submitApiList = async (option, operation) => {
    let result = await Axios.post("/api/auth/upateApiList", {
      link: option.link,
      operation,
    });
    console.log("option", result.data.list);
    setAccessList(result.data.list);
  };
  const deleteUser = async () => {
    if (confirmation()) {
      const response = await Axios.post("/api/auth/deleteUser", {
        user_id: user.user_id,
      });
      alert(response.data.msg);
    }
  };
  const resetUser = () => {
    setUser();
  };
  const submitAccess = async (option, operation) => {
    let result = await Axios.post("/api/auth/updateAccessV2", {
      link: option.link,
      operation,
      user: {
        name: user.name,
        email: user.email,
        user_id: user.user_id,
      },
    });
    fetchUsers();
  };

  const submitEdit = async () => {
    let result = await Axios.post("/api/auth/editCredentials", {
      role: document.getElementById("roleOfUser").value,
      name: document.getElementById("nameOfUser").value.trim(),
      email: user.email,
    });

    if (result.data.success) {
      let updatedUser = {
        ...user,
        role: document.getElementById("roleOfUser").value,
        name: document.getElementById("nameOfUser").value.trim(),
      };
      setUser(updatedUser);
      fetchUsers();
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setAccessList("");
    fetchUsers();
  }, [user]);

  return users ? (
    <div className="card configurationsCard access-card">
      <div className="name-bar">
        <div className="dropdown ">
          {user && (
            <div className="reset-icon-access">
              <i
                class="fa fa-arrow-left"
                aria-hidden="true"
                onClick={resetUser}
              ></i>
            </div>
          )}
          <button
            className="btn  dropdown-toggle user-list-btn"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {user ? user.name : "Choose User"}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {users.map((user, index) => (
              <button
                key={index}
                className="dropdown-item"
                onClick={() => {
                  setUser(user);
                  setName(user.name);
                }}
              >
                {user.name}
              </button>
            ))}
          </div>
          {user && (
            <div className="delete-icon-access">
              <i
                class="fa fa-trash"
                aria-hidden="true"
                onClick={deleteUser}
              ></i>
            </div>
          )}
        </div>
      </div>
      {user ? (
        <div className="access-list">
          <div style={{ paddingTop: "20px" }}>
            <div className="settingOption accessOption">
              <div className="settingTool">
                <div className="settingTitle">Change Role</div>
              </div>

              <div className="form-group">
                <select
                  className="form-control"
                  id="roleOfUser"
                  // onChange={e => toggleAccess(e)}
                >
                  <option selected disabled defaultValue>
                    {user.role}
                  </option>
                  <option>warehouse</option>
                  <option>developer</option>
                  <option>support</option>
                  <option>operations</option>
                  <option>administrator</option>
                </select>
              </div>
            </div>
            <div className="settingOption accessOption">
              <div className="settingTool">
                <div className="settingTitle">Change Name</div>
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  id="nameOfUser"
                  onChange={(e) => setName(e.target.value)}
                  defaultValue={user.name}
                  value={name}
                  style={{ width: "153px" }}
                />
              </div>
            </div>
            <button
              className="access-button"
              onClick={() => {
                submitEdit();
              }}
              style={{ textAlign: "center" }}
            >
              SUBMIT
            </button>
          </div>

          {accessList
            ? accessList.map((access, i) => (
                <AccessOption
                  access={access}
                  key={i}
                  checked={access.users.includes(user.email) || false}
                  handleEdit={handleEdit}
                />
              ))
            : ""}
        </div>
      ) : (
        ""
      )}
      {users && !user && (
        <div>
          <div className="name-bar" style={{ borderTop: "1px solid white" }}>
            EDIT PAGES
          </div>
          {accessList
            ? accessList.map((access, i) => (
                <AccessOption
                  access={access}
                  key={i}
                  checked={!access.disabled}
                  handleEdit={handleEditApiList}
                />
              ))
            : ""}
        </div>
      )}
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <p>Can't retreive users</p>
    </div>
  );
};

export default Access;

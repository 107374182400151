import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/home/res/Spinner";

const SetPassword = (props) => {
  document.title = "Set Password";
  const history = useHistory();

  const [passwordRandomString, setPasswordRandomString] = useState(null);
  const [showPage, setShowPage] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const handleResetString = () => {
    const {
      match: {
        params: { randomString },
      },
    } = props;
    setPasswordRandomString(randomString);
  };

  const handleShowPage = async () => {
    if (passwordRandomString !== null) {
      const {
        data: { showPage, email },
      } = await axios.post("/api/auth/showSetPasswordPage", {
        passwordRandomString,
      });
      setEmail(email);
      setShowPage(showPage);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = document.getElementById("email-address").value;
    const password = document.getElementById("new-password").value;
    const confirmPassword = document.getElementById("confirm-new-password")
      .value;
    if (password !== confirmPassword) {
      alert("Password entered does not match! Try again!");
      return;
    }
    if (password.length < 4) {
      alert("Password is too short! try again!");
      return;
    }
    const response = await axios.post("/api/auth/setPassword", {
      email,
      password,
      passwordRandomString,
    });
    if (response.data.success) {
      setTimeout(() => {
        history.push("/login");
      }, 2000);
      alert(response.data.msg);
    } else {
      alert(response.data.msg);
    }
  };

  useEffect(() => {
    handleResetString();
    handleShowPage();
  }, [passwordRandomString]);

  return loading == true ? (
    <div className="login-container">
      <Spinner />
    </div>
  ) : showPage == null ? (
    <div className="login-container">
      <Spinner />
    </div>
  ) : showPage == true ? (
    <div className="login-container">
      <div className="login">
        <h1 className="large text-primary">Set Password</h1>
        <form className="form">
          <div className="form-group">
            <input
              id="email-address"
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              disabled={true}
            />
          </div>
          <div className="form-group">
            <input
              id="new-password"
              type="password"
              placeholder="New Password"
              minLength="4"
            />
          </div>
          <div className="form-group">
            <input
              id="confirm-new-password"
              type="password"
              placeholder="Confirm New Password"
              minLength="4"
            />
          </div>
          <button
            type="submit"
            style={{ width: "100%" }}
            className="btn btn-primary"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  ) : (
    <Redirect to="/login"></Redirect>
  );
};

export default SetPassword;

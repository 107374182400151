import React from "react";

const SmsFilterTags = ({ item, removeFilter }) => {
  console.log(item);
  const handleClick = (item) => {
    removeFilter(item);
  };
  return (
    <div className="sms-marketing-tag blue">
      {`${item.storeName}`}
      <div
        className="sms-marketing-tag-close"
        onClick={() => handleClick(item)}
      >
        x
      </div>{" "}
    </div>
  );
};

export default SmsFilterTags;

import React from "react";
import ResourceForm from "../res/ResourceForm";

const AddResource = () => {
  document.title = "Add Resources";
  return (
    <div className="card configurationsCard IMS-order-card">
      <div style={{ padding: "20px" }}>
        <form>
          <ResourceForm type={"transporters"} />
          <ResourceForm type={"suppliers"} />
          <ResourceForm type={"products"} />
        </form>
      </div>
    </div>
  );
};

export default AddResource;

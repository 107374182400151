import React, { useState } from "react";
import { confirmation } from "../../../utils/confirmation";
import Axios from "axios";

function Product({ product, order, id, user }) {
  const [quantity, setQuantity] = useState(product.quantity);

  const handleProduct = async (type) => {
    if (type === "Update") {
      // handle empty input
      if (!quantity) {
        alert(`Please enter a quantity to update!`);
        setQuantity(product.quantity);
        return;
      }

      // handle no change to input
      if (quantity == product.quantity) {
        alert(
          `Quantity entered same as previous quantity(${product.quantity}) cannot update!`
        );
        return;
      }

      if (confirmation()) {
        let data = {
          store: order.store,
          type,
          id: order.name,
          productID: product.id,
          user: {
            user_id: user.user_id,
            name: user.name,
          },
          quantity: parseInt(quantity),
        };
        console.log(data);
        let result = await Axios.put("/api/support/updateProduct", data);
      }
    } else if (type === "Delete") {
      if (confirmation()) {
        let data = {
          store: order.store,
          type,
          id: order.name,
          user: {
            user_id: user.user_id,
          },
          productID: product.id,
          quantity: parseInt(product.quantity),
        };
        console.log(data);
        let result = await Axios.put("/api/support/updateProduct", data);
      }
    }
  };

  return (
    <div>
      <center>{`${product.name ? product.name : product.title} (${
        product.price
      })`}</center>
      <input
        type="text"
        disabled={order.ndr ? true : false}
        // defaultValue={product.quantity}
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
        className="productInput"
        id={`product_quantity_${id}`}
      />
      {!order.ndr ? (
        <div style={{ marginTop: "5px", display: "flex" }}>
          <button
            type="button"
            className="product-button product-submit"
            onClick={() => handleProduct("Update")}
          >
            <i className="fas fa-check fa-control" />
          </button>
          <button
            type="button"
            className="product-button product-cancel"
            onClick={() => handleProduct("Delete")}
          >
            <i className="fas fa-times fa-control" />
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Product;

import React from "react";

function StoreButton({ store, stores, setStore }) {
  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle operations-store-button"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {store ? store.storeName : "Select"}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {stores.map(store => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            key={store.shopifyName}
            className="dropdown-item operations-store-button-item"
            onClick={() => setStore(store)}
          >
            {store.storeName}
          </a>
        ))}
      </div>
    </div>
  );
}

export default StoreButton;

import React from "react";

const StoreForm = ({ text, type, placeholder }) => {
  return (
    <div>
      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            {text}
          </span>
        </div>
        <input
          id={type}
          placeholder={placeholder ? placeholder : ""}
          defaultValue={placeholder ? placeholder : ""}
          type="text"
          className="form-control"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          required={true}
        />
      </div>
    </div>
  );
};

export default StoreForm;

import React from "react";

const CustomTagBarProduct = ({
  item,
  selectedProducts,
  setSelectedProducts,
  setFilteredProducts,
}) => {
  const handleClick = (item) => {
    let itemRemovedArray = selectedProducts.filter(
      (selectedItem) => !(selectedItem.title == item.title)
    );
    setSelectedProducts(itemRemovedArray);
    setFilteredProducts((filteredProducts) => {
      return [item, ...filteredProducts];
    });
  };
  return (
    <div className="tag-item">
      <span>
        {item.title.length < 20 ? (
          item.title + "  "
        ) : (
          <span>
            {item.title.slice(0, 18) + " "}
            <i class="fas fa-ellipsis-h tooltip-nav">
              <span className="tooltip-nav-text">{item.title}</span>
            </i>
            {"  "}
          </span>
        )}
      </span>
      <span onClick={() => handleClick(item)}>
        <i class="fas fa-times"></i>
      </span>
    </div>
  );
};

export default CustomTagBarProduct;

import React from "react";

const Spinner = () => {
  return (
    <div
      className="d-flex justify-content-center"
      // style={{ marginTop: "20px" }}
    >
      <div
        className="spinner-border"
        style={{ color: "#00B894" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;

import React, { useEffect, useState } from "react";

export default function DatePerspective() {
  const dropdownOptions = [
    { name: "Shopify date", value: "created_at" },
    { name: "Scheduled date", value: "scheduledOn" },
    { name: "NDR assigned date", value: "ndrCallAssignedOn" },
    { name: "Delivered date", value: "deliveredOn" },
  ];

  const [perspectiveOption, setPerspectiveOption] = useState("created_at");

  const setDatePerspectiveFilter = (perspective) => {
    setPerspectiveOption(perspective);
  };

  const DatePerspectiveFilter = () => {
    return (
      <select
        id="date-perspective-filter"
        onChange={(e) => setDatePerspectiveFilter(e.target.value)}
        className="form-control form-control-sm"
        style={{
          height: "100%",
          fontSize: "0.9rem",
          borderRadius: 0,
          width: "100%",
        }}
        value={perspectiveOption}
      >
        {dropdownOptions.map((option) => (
          <option value={option.value}>{option.name}</option>
        ))}
      </select>
    );
  };

  return [DatePerspectiveFilter, perspectiveOption];
}

import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import loading from "../../../assets/ripple.gif";
function StatsDetails({ name, request, show, count }) {
  let [details, setDetails] = useState("");
  const getDetails = async () => {
    if (show === name) {
      if (!details[name]) {
        details = details || {};
        request.products = global.selected;
        let result = await Axios.post("/api/stats/detailedStats", request);
        console.log(result.data);
        details[name] = result.data;
        setDetails(details);
      }
    }
  };

  useEffect(() => {
    setDetails("");
  }, []);

  useEffect(() => {
    getDetails();
  }, [show]);

  return show === name ? (
    <Fragment>
      <ul className="tooltiptext-new">
        <li
          style={{
            padding: "10px",
            listStyleType: "none",
            background: "#E74C3C",
            color: "white",
            textAlign: "center",
          }}
        >
          {name} ( {count} )
        </li>
        {details[name] ? (
          details[name].map((type) => (
            <li className="details-log">
              {type.name}: {type.count}
            </li>
          ))
        ) : (
          <div>
            <img
              src={loading}
              alt="Loading..."
              style={{
                position: "",
                width: "90px",
                height: "90px",
                display: "block",
                margin: "auto",
              }}
            />
          </div>
        )}
      </ul>
    </Fragment>
  ) : (
    ""
  );
}

export default StatsDetails;

import React, { useEffect, useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";

import axios from "axios";
import NavBar from "../res/NavBar";
import SettingOption from "../res/SettingOption";
import SettingForm from "../res/SettingForm";
import SurfaceProductsTagbar from "../res/SurfaceProductsTagbar";

const Settings = ({ location }) => {
  document.title = "Settings";
  const [config, setConfig] = useState(true);
  const [store, setStore] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [surfaceProducts, setSurfaceProducts] = useState([]);

  const submit = async () => {
    let value = document.getElementById("clickpost_target").value;
    let callCountValue = document.getElementById("callCountSettings").value;
    if (!callCountValue) callCountValue = 4;
    if (!value && callCountValue) {
      // ClickPost target input empty
      console.log(`first input box empty going through bypass route :)`);
      let body = { value, store };
      body.value = callCountValue;
      await axios({
        method: "post",
        url: `/api/settings/setCallCountSettings`,
        data: body,
        headers: {
          "content-type": `application/json`,
        },
      });
      alert("Settings updated!");
      return;
    }
    let body = { value, store };
    console.log(body);
    await axios({
      method: "post",
      url: `/api/settings/setClickPostTarget`,
      data: body,
      headers: {
        "content-type": `application/json`,
      },
    });
    console.log("normal way ;p");
    body.value = callCountValue;
    await axios({
      method: "post",
      url: `/api/settings/setCallCountSettings`,
      data: body,
      headers: {
        "content-type": `application/json`,
      },
    });
    alert("Settings updated!");
  };
  const handleValueChange = async (e) => {
    if (e.target.id === "Fake_Dimensions" && e.target.checked)
      setRedirect(true);
    setConfig({
      ...config,
      [e.target.id]: {
        name: config[e.target.id].name,
        value: e.target.checked,
      },
    });
    const body = {
      store: store.storeAnchor,
      target: [e.target.id],
      value: {
        name: config[e.target.id].name,
        value: e.target.checked,
      },
      response: "no",
    };
    if (e.target.id === "Automatic_Fulfilment" && e.target.checked) {
      if (prompt("Schedule Held Order? (yes,no)") === "yes")
        body.response = "yes";
    }
    await axios({
      method: "post",
      url: `/api/settings/`,
      data: body,
      headers: {
        "content-type": `application/json`,
      },
    });
  };

  const handleNameChange = async (e, name) => {
    setConfig({
      ...config,
      [e.target.id]: {
        name: name,
        value: config[e.target.id].value,
      },
    });
    const body = {
      store: store.storeAnchor,
      target: [e.target.id],
      value: {
        name: name,
        value: config[e.target.id].value,
      },
    };
    if (name !== config[e.target.id].name)
      await axios({
        method: "post",
        url: `/api/settings/`,
        data: body,
        headers: {
          "content-type": `application/json`,
        },
      });
  };

  const getStatus = async (store) => {
    // localStorage.setItem("settings", JSON.stringify(store));

    await axios
      .get(`/api/settings?store=${store.storeAnchor}`, {})
      .then((result) => {
        console.log("Fetched configurations from database", result.data);
        setConfig(result.data);
      });
  };

  const multiCheck = (e) => {
    const selectedProduct = JSON.parse(e.target.value);
    e.target.blur();
    e.target.value = "";
    const newArray = filtered.filter((product) => {
      if (product.product_id == selectedProduct.product_id) {
        return false;
      } else return true;
    });
    setFiltered(newArray);
    setSurfaceProducts((surfaceProducts) => {
      return [...surfaceProducts, selectedProduct];
    });
  };

  const fetchProducts = async (store) => {
    const params = { store: JSON.stringify(store) };
    let productList = await axios.get(`/api/product/`, { params });
    productList = productList.data.products.map((product) => {
      return {
        title: product.title,
        product_id: product.product_id,
        surface: product.surface ? true : false,
      };
    });
    const newArr = [];
    const filteredProductList = productList.filter((product) => {
      if (!product.surface) {
        return true;
      } else {
        newArr.push(product);
        return false;
      }
    });
    setProducts(productList);
    setFiltered(filteredProductList);
    setSurfaceProducts(newArr);
  };

  const removeSelectedProduct = async (product) => {
    const newArr = surfaceProducts.filter((surfaceProduct) => {
      if (product.product_id === surfaceProduct.product_id) {
        return false;
      } else {
        return true;
      }
    });
    setFiltered((filtered) => [...filtered, product]);
    setSurfaceProducts(newArr);
    const body = {
      store,
      product_id: parseFloat(product.product_id),
    };
    const response = await axios.post(
      "/api/product/deleteSurfaceProduct",
      body
    );
    alert(response.data.message);
  };

  const selectedProductSubmit = async () => {
    const products = surfaceProducts.map((product) => {
      return parseFloat(product.product_id);
    });
    const body = {
      store,
      products,
    };
    const response = await axios.post("/api/product/saveSurfaceProducts", body);
    alert(response.data.message);
  };

  useEffect(() => {
    let localStore = localStorage.getItem("settings");
    localStore = JSON.parse(localStore);
    setStore(localStore);
    if (localStore) getStatus(localStore);
  }, []);

  useEffect(() => {
    if (store) {
      fetchProducts(store);
    }
  }, [store]);

  return (
    <div>
      <div className="card configurationsCard">
        <NavBar store={store} setStore={setStore} getStatus={getStatus} />
        {store ? (
          <Fragment>
            <div>
              {Object.keys(config).map((option, index) => (
                <SettingOption
                  key={index}
                  id={option}
                  option={config[option].name}
                  handleValueChange={handleValueChange}
                  handleNameChange={handleNameChange}
                  status={config[option].value}
                />
              ))}
              <div style={{ margin: "0 5%", padding: "0 2vw" }}>
                {showAddForm === false ? (
                  <button
                    className="btn "
                    style={{ background: "#9b59b6", color: "white" }}
                    onClick={() => setShowAddForm((prevState) => !prevState)}
                  >
                    <i className="fas fa-plus-circle" /> Add
                  </button>
                ) : (
                  <SettingForm
                    setShowAddForm={setShowAddForm}
                    store={store}
                    getStatus={getStatus}
                  />
                )}
              </div>
            </div>
            <div style={{ margin: "30px 5%", padding: "0 2vw" }}>
              <div className="form-group">
                <label htmlFor="clickpost_target">ClickPost Target</label>
                <input
                  type="number"
                  className="form-control"
                  id="clickpost_target"
                  placeholder="Please enter the daily target"
                />
                <label htmlFor="callCountSettings">
                  Call Count Settings(No of Re-attempts)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="callCountSettings"
                  placeholder="Please enter the number of re-attempts"
                />
                <button
                  type="submit"
                  className="btn btn-primary mb-2 btn-lg"
                  onClick={() => submit()}
                  style={{
                    marginTop: "10px",
                    borderRadius: "0px",
                    background: "#9B59B6",
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="settings-surface-products-container">
                <label htmlFor="surfaceProducts">Surface Products</label>
                <input
                  type="text"
                  list="productList"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Search product here ..."
                  id="surfaceProducts"
                  onChange={(e) => {
                    multiCheck(e);
                  }}
                />
                <datalist id="productList">
                  {filtered.map
                    ? filtered.map((option, index) => (
                        <option
                          key={index}
                          data-value={option}
                          value={JSON.stringify(option)}
                        >
                          {option.title}
                        </option>
                      ))
                    : null}
                </datalist>
                <SurfaceProductsTagbar
                  surfaceProducts={surfaceProducts}
                  removeSelectedProduct={removeSelectedProduct}
                />
                <button onClick={selectedProductSubmit}>
                  Save Surface Products
                </button>
              </div>
              {redirect ? <Redirect to="/admin/selectProducts" /> : ""}
            </div>
          </Fragment>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h3>Choose a store</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;

import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";

export const submitImage = async (
  e,
  formId,
  store,
  orderName,
  product_id,
  variant_id,
  fetchOrders
) => {
  // console.log(store, orderName, product_id, variant_id);
  e.preventDefault();

  console.log(document.getElementById(formId).files[0]);
  if (!document.getElementById(formId).files[0])
    return alert("please upload the file");

  const formData = new FormData();

  formData.append(
    "damagedProductImage",
    document.getElementById(formId).files[0]
  );

  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  const url = `/api/warehouse/uploadDamagedProductImages/${encodeURIComponent(
    orderName
  )}/${product_id}/${variant_id}/${encodeURIComponent(JSON.stringify(store))}`;
  console.log(url);
  Axios.post(url, formData, config)
    .then(response => {
      alert("The file is successfully uploaded");
      fetchOrders();
    })
    .catch(error => {
      alert(error);
    });
};

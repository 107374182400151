import React from "react";

function DetailsBody({ details }) {
  return (
    <ul className="tooltiptext-new">
      {Object.keys(details).map(type => {
        return (
          <li className="details-log">
            {type}: {details[type]}
          </li>
        );
      })}
    </ul>
  );
}

export default DetailsBody;

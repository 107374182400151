import React, { Fragment, useState } from "react";
import { updateOrderDetails } from "../functions/updateOrderDetails";
import NavBar from "../res/NavBar";

const UpdateOrderDetails = () => {
  const [store, setStore] = useState("start");
  document.title = "UpdateOrderDetails";
  console.log(store);
  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} />
      {store !== "start" ? (
        <div className="container-fluid">
          <form>
            <br />
            <div>
              <p id="status" name="status" />
            </div>
            <div className="shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card">
                <div className="card-header">
                  <h3>Want to Update Order Details ?</h3>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-2"
                      htmlFor="orderName"
                    >
                      Order ID (ShipRocket):
                    </label>
                    <div className="col-sm-2">
                      <input
                        type="text"
                        className="form-control"
                        id="orderName"
                        name="orderName"
                        placeholder="ShipRocket OrderID"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="control-label col-sm-2" htmlFor="account">
                      ShipRocket Account:
                    </label>
                    <div className="col-sm-2">
                      <select id="account" name="account">
                        <option value="Select" selected>
                          Select Account
                        </option>
                        <option value="ShipRocket">ShipRocket</option>
                        <option value="ShipRocket Two">ShipRocket Two</option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-primary mb-2 btn-lg"
                        onClick={(e) => updateOrderDetails(e, store)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>Choose a store</h2>
        </div>
      )}
    </Fragment>
  );
};

export default UpdateOrderDetails;

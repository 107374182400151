import React, { useContext, useEffect } from "react";
import { UserConsumer } from "../components/context/caller-context";
import useDatePicker from "../../../components/global/DatePicker";

const FilterSelector = ({ user }) => {
  const context = useContext(UserConsumer);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");

  useEffect(() => {
    context.setDate(fromDate, toDate);
  }, [fromDate, toDate]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "100%" }}>
        <DatePicker />
      </div>
      <button
        type="button"
        className="btn btn-danger"
        style={{ borderRadius: "0px", padding: "10px" }}
        onClick={() => context.permuteOrders(user)}
      >
        Submit
      </button>
    </div>
  );
};

export default FilterSelector;

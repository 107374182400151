import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CustomTagBarProduct from "./CustomTagBarProduct";
import CustomTagBarAggregator from "./CustomTagBarAggregator";
import CustomTagBarProperty from "./CustomTagBarProperty";

const CreateExperiment = ({ setCreateExperiment }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [aggregators, setAggregators] = useState([]);
  const [selectedAggregators, setSelectedAggregators] = useState([]);
  const [matchByOptions, setMatchByOptions] = useState(["Quantity", "Price"]);
  const [state, setState] = useState({});
  const [showHiddenOptions, setShowHiddenOptions] = useState({
    matchByOption: false,
    edit: false,
  });
  // const [showEqualityOptions, setShowEqualityOptions] = useState({});

  const fetchProducts = async (store) => {
    if (!store) return;
    let productList = await axios.get("/api/product", {
      params: { store },
    });
    productList = productList.data.products;
    let options = [];
    for (let i = 0; i < productList.length; i++) {
      let title = productList[i].title;
      productList[i].variants.map((variant) => {
        if (variant.title == "Default Title") {
          options.push({
            title,
            variant_id: variant.variant_id,
          });
        } else {
          options.push({
            title: title + " " + variant.title,
            variant_id: variant.variant_id,
          });
        }
      });
    }
    setProducts(options);
    setFilteredProducts(options);
  };

  const fetchStores = async () => {
    let { data } = await axios.get(`/api/stores`);
    setStores(data);
  };

  const fetchAggregators = async () => {
    let { data } = await axios.get(`/api/control/fetchAggregators`);
    setAggregators(data.aggregators);
  };

  const handleChange = (e) => {
    let value;
    let name = e.target.name;
    if (name === "matchByOption" || name === "edit") {
      // if (e.target.checked === false) {
      //   // handle unchecking
      //   setShowHiddenOptions((obj) => {
      //     obj[name] = !obj[name];
      //     return obj;
      //   });
      //   setState((prevState) => {
      //     delete prevState[name];
      //     return prevState;
      //   });
      //   return;
      // }
      setShowHiddenOptions((obj) => {
        obj[name] = !obj[name];
        return obj;
      });
    } else if (name === "matchByOptionProperty" || name === "editProperty") {
      name = name.slice(0, -8); // remove "Property"(length - 8)
      if (!(e.target.value === 0)) {
        const input = prompt(`Enter the ${e.target.value} below:`);
        value = {
          property: e.target.value,
          value: parseInt(input),
        };
      }
    } else if (name === "matchByOptionEquality" || name === "editEquality") {
      if (!(e.target.value === 0)) {
        name = name.slice(0, -8); // remove "Equality"(length - 8)
        let value = state[name];
        let equality = e.target.value.slice(0, 2);
        if (equality === ">>") equality = ">";
        if (equality === "<<") equality = "<";
        value = { ...value, equality };
        setState({
          ...state,
          [name]: value,
        });
        return;
      }
    } else {
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleMultiple = (e) => {
    // here we handle the multiple input select options like products,..
    if (e.target.name == "products") {
      const product = JSON.parse(e.target.value);
      e.target.value = "";
      if (product) {
        setSelectedProducts([...selectedProducts, product]);
        setFilteredProducts((filteredProducts) => {
          const newFilteredArray = filteredProducts.filter((filterProduct) => {
            if (filterProduct.title == product.title) {
              return false;
            } else {
              return true;
            }
          });
          return newFilteredArray;
        });
        e.target.blur();
      }
    } else if (e.target.name == "aggregators") {
      const aggregator = e.target.value;
      if (aggregator) {
        setSelectedAggregators((selectedAggregators) => [
          ...new Set([...selectedAggregators, aggregator]),
        ]);
        console.log(e.target);
        e.target.blur();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state.name) {
      alert("Please enter the name of the experiment!");
      document.getElementsByName("name")[0].focus();
      return;
    } else if (!state.numberOfOrders) {
      alert("Please enter the number of orders for the experiment!");
      document.getElementsByName("numberOfOrders")[0].focus();
      return;
    } else if (!state.store) {
      alert("Please select a store for the experiment!");
      document.getElementsByName("store")[0].focus();
      return;
    } else if (!state.actions) {
      alert("Please select an action!");
      document.getElementsByName("actions")[0].focus();
      return;
    }

    const data = {
      name: state.name,
      numberOfOrders: state.numberOfOrders,
      target: state.finalTarget,
      conditions: {
        forward: {
          store: [JSON.parse(state.store)],
          products: selectedProducts,
          matchByOption: state.matchByOption ? state.matchByOption : null,
          flags: {
            callCustomer: state.callCustomer ? state.callCustomer : false,
            multipleItems: state.multipleItems ? state.multipleItems : false,
            multipleQuantity: state.multipleQuantity
              ? state.multipleQuantity
              : false,
            repeatCustomer: state.repeatCustomer ? state.repeatCustomer : false,
          },
        },
        reverse: {
          aggregators: selectedAggregators,
        },
      },
      leads: state.includeLeads ? state.includeLeads : false,
      actions: state.actions ? state.actions : "",
      addOns: {
        whatsappMessage: state.whatsappMessage ? state.whatsappMessage : false,
        edit: state.edit ? state.edit : null,
      },
    };

    // Converting flag object to array
    data.conditions.forward.flags = Object.keys(
      data.conditions.forward.flags
    ).filter((flag) => data.conditions.forward.flags[flag]);

    const response = await axios.post("/api/experiment/create", data);
    alert(response.data.msg);
    setCreateExperiment(false);
  };

  useEffect(() => {
    fetchStores();
    fetchAggregators();
    if (state.store) {
      fetchProducts(state.store);
    }
  }, [state.store]);

  return (
    <div className="create-experiment">
      <form>
        <div className="create-experiment-input">
          <label className="main-label">
            Experiment Name<span>*</span>
          </label>
          <input
            name="name"
            className="form-control"
            type="text"
            placeholder="Enter experiment name"
            onChange={handleChange}
          />
        </div>
        <div className="create-experiment-input">
          <label className="main-label">
            Order Count Per Day<span>*</span>
          </label>
          <input
            name="numberOfOrders"
            className="form-control"
            type="number"
            placeholder="Number of orders per day"
            onChange={handleChange}
          />
        </div>
        <div className="create-experiment-input">
          <label className="main-label">
            Final Target<span>*</span>
          </label>
          <input
            name="finalTarget"
            className="form-control"
            type="number"
            placeholder="Total experiment target"
            onChange={handleChange}
          />
        </div>
        <div className="create-experiment-input">
          <label className="main-label">
            Stores<span>*</span>
          </label>
          <select
            name="store"
            id="experiment-store-forward"
            className="form-control"
            onChange={handleChange}
          >
            {!state.store ? <option>Choose Store</option> : null}
            {stores.map((store) => (
              <option value={JSON.stringify(store)}>{store.storeName}</option>
            ))}
          </select>
        </div>
        <div className="create-experiment-input">
          <label className="main-label">Product</label>
          <input
            type="text"
            name="products"
            id="experiment-product-forward"
            list="productList"
            autoComplete="off"
            placeholder="Products"
            className="form-control form-control-sm"
            onChange={(e) => handleMultiple(e)}
          />
          <datalist id="productList">
            {filteredProducts.map
              ? filteredProducts.map((option, index) => (
                  <option
                    key={index}
                    data-value={option}
                    value={JSON.stringify(option)}
                  >
                    {option.title}
                  </option>
                ))
              : null}
          </datalist>
        </div>
        <div className="tagbar">
          {selectedProducts
            ? selectedProducts.map((item, index) => (
                <CustomTagBarProduct
                  item={item}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  setFilteredProducts={setFilteredProducts}
                />
              ))
            : ""}
        </div>
        <div className="create-experiment-input">
          <label className="main-label" for="experiment-matchByOption">
            Match by
          </label>
          <div className="form-check">
            <input
              name="matchByOption"
              id="experiment-matchByOption"
              className="form-check-input"
              type="checkbox"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        {showHiddenOptions.matchByOption ? (
          <div className="create-experiment-input">
            <label className="main-label">Match by Property</label>
            <div className="flex-elements">
              <div className="flex-element">
                <select
                  className="form-control form-control-sm"
                  name="matchByOptionProperty"
                  onChange={(e) => handleChange(e)}
                >
                  <option value={0}>Choose match by property</option>
                  {matchByOptions.map((option) => (
                    <option>{option}</option>
                  ))}
                </select>
              </div>
              {state.matchByOption ? (
                <div className="flex-element">
                  <select
                    className="form-control form-control-sm"
                    name="matchByOptionEquality"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value={0}>Choose equality</option>
                    <option
                      value={`==${state.matchByOption.value}`}
                    >{`= ${state.matchByOption.value}`}</option>
                    <option
                      value={`>>${state.matchByOption.value}`}
                    >{`> ${state.matchByOption.value}`}</option>
                    <option value={`>=${state.matchByOption.value}`}>
                      {`>= ${state.matchByOption.value}`}
                    </option>
                    <option value={`<<${state.matchByOption.value}`}>
                      {`< ${state.matchByOption.value}`}
                    </option>
                    <option value={`<=${state.matchByOption.value}`}>
                      {`<= ${state.matchByOption.value}`}
                    </option>
                  </select>
                </div>
              ) : null}
              <div className="tagbar">
                {state.matchByOption && state.matchByOption.equality ? (
                  <CustomTagBarProperty item={state.matchByOption} />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <div className="create-experiment-input">
          <label className="main-label">Flags</label>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="form-check">
              <input
                name="callCustomer"
                className="form-check-input"
                type="checkbox"
                id="experiment-flag-addressIssue-forward"
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                for="experiment-flag-addressIssue-forward"
              >
                Address Issue
              </label>
            </div>
            <div className="form-check">
              <input
                name="multipleItems"
                className="form-check-input"
                type="checkbox"
                id="experiment-flag-multipleItems-forward"
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                for="experiment-flag-multipleItems-forward"
              >
                Multiple Items
              </label>
            </div>
            <div className="form-check">
              <input
                name="multipleQuantity"
                className="form-check-input"
                type="checkbox"
                id="experiment-flag-multipleQuantity-forward"
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                for="experiment-flag-multipleQuantity-forward"
              >
                Multiple Quantity
              </label>
            </div>
            <div className="form-check">
              <input
                name="repeatCustomer"
                className="form-check-input"
                type="checkbox"
                id="experiment-flag-repeatCustomer-forward"
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                for="experiment-flag-repeatCustomer-forward"
              >
                Repeat Customer
              </label>
            </div>
          </div>
        </div>
        <div className="create-experiment-input">
          <label className="main-label">Shipping Aggregator</label>
          <select
            name="aggregators"
            id="experiment-aggregator-reverse"
            className="form-control"
            onChange={handleMultiple}
          >
            <option selected="selected" disabled>
              Choose an aggregator
            </option>
            {aggregators.map((aggregator) => (
              <option value={aggregator.name}>{aggregator.name}</option>
            ))}
          </select>
        </div>
        <div className="tagbar">
          {selectedAggregators
            ? selectedAggregators.map((item, index) => (
                <CustomTagBarAggregator
                  item={item}
                  selectedAggregators={selectedAggregators}
                  setSelectedAggregators={setSelectedAggregators}
                />
              ))
            : ""}
        </div>
        <div className="create-experiment-input">
          <label className="main-label">Leads</label>
          <div style={{ display: "flex" }}>
            <div className="form-check">
              <input
                name="includeLeads"
                className="form-check-input"
                type="checkbox"
                id="experiment-leads"
                onChange={handleChange}
              />
              <label className="form-check-label" for="experiment-leads">
                Include leads
              </label>
            </div>
          </div>
        </div>
        <div className="create-experiment-input">
          <label className="main-label">
            Actions<span>*</span>
          </label>
          <select
            name="actions"
            className="form-control"
            onChange={handleChange}
          >
            {!state.actions ? <option>Choose action</option> : null}
            <option value="send to the calling dashboard">
              Send to the calling dashboard
            </option>
            <option value="send to the operations dashboard">
              Send to the operations dashboard
            </option>
            <option value="Schedule directly">Schedule directly</option>
          </select>
        </div>
        <div className="create-experiment-input">
          <label className="main-label">Add ons</label>
          <div className="flex-elements">
            <div className="form-check flex-element">
              <input
                name="whatsappMessage"
                className="form-check-input"
                type="checkbox"
                id="experiment-addOn-whatsapp"
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                for="experiment-addOn-whatsapp"
              >
                Whatsapp message
              </label>
            </div>
            <div className="form-check flex-element">
              <input
                name="edit"
                className="form-check-input"
                type="checkbox"
                id="experiment-addOn-edit"
                onChange={handleChange}
              />
              <label className="form-check-label" for="experiment-addOn-edit">
                Edit
              </label>
            </div>
          </div>
        </div>
        {showHiddenOptions.edit ? (
          <div className="create-experiment-input">
            <label className="main-label">Edit Property</label>
            <div className="flex-elements">
              <div className="flex-element">
                <select
                  className="form-control form-control-sm"
                  name="editProperty"
                  onChange={(e) => handleChange(e)}
                >
                  <option value={0}>Choose edit property</option>
                  {matchByOptions.map((option) => (
                    <option>{option}</option>
                  ))}
                </select>
              </div>
              {/* {state.edit ? (
                <div className="flex-element">
                  <select
                    className="form-control form-control-sm"
                    name="editEquality"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value={0}>Choose equality</option>
                    <option
                      value={`==${state.edit.value}`}
                    >{`= ${state.edit.value}`}</option>
                    <option
                      value={`>>${state.edit.value}`}
                    >{`> ${state.edit.value}`}</option>
                    <option value={`>=${state.edit.value}`}>
                      {`>= ${state.edit.value}`}
                    </option>
                    <option value={`<<${state.edit.value}`}>
                      {`< ${state.edit.value}`}
                    </option>
                    <option value={`<=${state.edit.value}`}>
                      {`<= ${state.edit.value}`}
                    </option>
                  </select>
                </div>
              ) : null} */}
              <div className="tagbar">
                {state.edit ? <CustomTagBarProperty item={state.edit} /> : null}
              </div>
            </div>
          </div>
        ) : null}
        <button
          className="experiments-custom-button"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateExperiment;

import React, { useState, Fragment } from "react";
import Axios from "axios";

function LabelAndManifestsListCP({ labelAndManifestDetails, index }) {
  const [orders, setOrders] = useState();

  const getLink = async (file, type) => {
    let config = {};
    if (type === "link")
      config = {
        responseType: "blob",
      };
    let result = await Axios.get(
      `/api/warehouse/resolvePDF?file_name=${file}&type=${type}`,
      config
    );

    if (type === "link") {
      console.log(result.data);
      const file = new Blob([result.data], {
        type: "application/pdf",
      });
      //Build a URL from the file
      console.log(file);
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    } else {
      console.log(result.data);
      setOrders(result.data);
    }
  };
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{new Date(labelAndManifestDetails.message).toString()}</td>
      <td>
        <p>
          {labelAndManifestDetails.goswift
            ? "GoSwift"
            : labelAndManifestDetails.clickpost
            ? "ClickPost"
            : "ShadowFax"}
        </p>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <button
              type="submit"
              className="btn btn-primary"
              style={{ borderRadius: "0px", width: "150px" }}
              onClick={() => {
                getLink(labelAndManifestDetails.file_name, "link");
              }}
            >
              Get Link
            </button>
          </li>
          <li>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                borderRadius: "0px",
                width: "150px",
                marginTop: "10px",
              }}
              onClick={() => {
                getLink(labelAndManifestDetails.file_name, "orders");
              }}
            >
              Get Orders
            </button>
          </li>
        </ul>
        {orders ? (
          <Fragment>
            <ul style={{ listStyleType: "none" }}>
              {orders.map((order) => (
                <li>{order.name}</li>
              ))}
            </ul>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                borderRadius: "0px",
                width: "150px",
              }}
              onClick={() => {
                setOrders([]);
              }}
            >
              Close Orders
            </button>
          </Fragment>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
}

export default LabelAndManifestsListCP;

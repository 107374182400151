import React, { useState, useEffect, Fragment, useContext } from "react";
import useDatePicker from "../../global/DatePicker";
import Spinner from "../../home/res/Spinner";
import Axios from "axios";
import { GlobalConsumer } from "../../../Global-Context/global-context";

function StrikeRateCSV() {
  const [adminFilterReact, setAdminFiterReact] = useState("");
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [loading, setLoading] = useState(false);
  const [responseToShow, SetResponseToShow] = useState("");
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [initialMount, setInitialMount] = useState(true);
  const [alertMsg20March, setAlertMsg20March] = useState(true);
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user] = useState(userInfo);
  const [responseLength, setResponseLength] = useState(-1);
  const [
    filterTotalWithFromAndToDate,
    setFilterTotalWithFromAndToDate,
  ] = useState({
    total: 0,
    fromDate: "Not Specified",
    toDate: "Not Specified",
  });

  document.title = "Strike Rate CSV";
  const switchContext = (e) => {
    console.log(e.target.value);
    if (e.target.value === "Support Perspective")
      setAdminFiterReact("created_at");
    else if (e.target.value === "Operations Perspective")
      setAdminFiterReact("pickedUpOn");
    else if (e.target.value === "scheduledOn Perspective")
      setAdminFiterReact("scheduledOn");
  };
  const submitDetails = async (from, to) => {
    setLoading(true);
    document.getElementById("strikeRateSubmit").disabled = "true";

    let adminFilter = adminFilterReact ? adminFilterReact : "";

    let data = {
      fromDate,
      toDate,
      user: { role: user.role },
      adminFilter,
    };

    let result = await Axios.post("/api/operations/strikeRateCSV", data);

    setLoading(false);

    document.getElementById("strikeRateSubmit").removeAttribute("disabled");

    if (result.data.msg) {
      alert(result.data.msg);
      return;
    }

    if (result.data.fromDateLesserThan20MarchMsg)
      alert(
        "Strike Rate CSV Ready, Happy Analysing!\n" +
          result.data.fromDateLesserThan20MarchMsg
      );
    else alert("Strike Rate CSV Ready, Happy Analysing!");

    let blob = new Blob([result.data.csv], { type: "text/csv" });
    let a = document.createElement("a");
    a.download = "StrikeRate.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download StrikeRate CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );
    document.body.appendChild(a);
    a.click();
  };

  const displayResults = async () => {
    let adminFilter = adminFilterReact ? adminFilterReact : "";

    let data = {
      fromDate,
      toDate,
      user: { role: user.role },
      adminFilter,
      offset,
      limit,
    };

    let resultDisplay = await Axios.post(
      "/api/operations/strikeRateDisplay",
      data
    );

    document.getElementById("displayResults").removeAttribute("disabled");
    if (resultDisplay.data.msg) {
      alert(resultDisplay.data.msg);
      return;
    }

    SetResponseToShow(resultDisplay.data);
    setResponseLength(resultDisplay.data.raw.length);
    setFilterTotalWithFromAndToDate(
      resultDisplay.data.filterTotalWithFromAndToDate
    );

    if (resultDisplay.data.fromDateLesserThan20MarchMsg && alertMsg20March) {
      alert(resultDisplay.data.fromDateLesserThan20MarchMsg);
      setAlertMsg20March(false);
    }
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const handleDisablingOfPaginationButtons = () => {
    if (
      document.getElementById("previous-page") &&
      document.getElementById("next-page")
    ) {
      if (offset === 0) {
        document.getElementById("previous-page").disabled = true;
      } else {
        document.getElementById("previous-page").disabled = false;
      }

      if (responseLength < limit) {
        document.getElementById("next-page").disabled = true;
      } else {
        document.getElementById("next-page").disabled = false;
      }
    }
  };

  useEffect(() => {
    handleDisablingOfPaginationButtons();
  }, [limit, offset, responseLength]);

  useEffect(() => {
    if (!initialMount) displayResults();
    else setInitialMount(false);
  }, [offset, limit]);

  {
    return (
      <div className="stats-content">
        <h1 style={{ textAlign: "center" }}>Strike Rate CSV</h1>
        {user.role === "administrator" ? (
          <div className="form-group">
            <select
              className="form-control"
              id="role"
              onChange={(e) => switchContext(e)}
            >
              <option selected disabled>
                Role
              </option>
              <option>Support Perspective</option>
              <option>Operations Perspective</option>
              <option>scheduledOn Perspective</option>
            </select>
          </div>
        ) : (
          ""
        )}
        <div style={{ display: "flex", height: "8vh", flexDirection: "row" }}>
          <DatePicker />
          <button
            id="strikeRateSubmit"
            className="btn btn-info"
            style={{ borderRadius: 0, marginLeft: "10px", width: "180px" }}
            onClick={() => submitDetails(fromDate, toDate)}
          >
            Download CSV
          </button>
          <button
            id="displayResults"
            className="btn btn-info"
            style={{ borderRadius: 0, marginLeft: "10px", width: "180px" }}
            onClick={() => displayResults()}
          >
            Show Here
          </button>
        </div>
        <div style={{ padding: "10px" }}>{loading ? <Spinner /> : ""}</div>

        <div>
          {responseToShow ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "15px",
                  marginTop: "21px",
                  height: "7vh",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    background: "white",
                    color: "black",
                    marginRight: "20px",
                    width: "100%",
                    justifyContent: "center",
                    boxShadow:
                      "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <div style={{ textAlign: "center", marginTop: "15px" }}>
                    <strong>{filterTotalWithFromAndToDate.total}</strong> Orders
                    from{" "}
                    <strong>{filterTotalWithFromAndToDate.fromDate}</strong> to{" "}
                    <strong>{filterTotalWithFromAndToDate.toDate}</strong>
                  </div>
                </div>

                <select
                  className="form-control form-control-sm"
                  style={{
                    height: "100%",
                    fontSize: "0.9rem",
                    borderRadius: 0,
                    width: "7%",
                  }}
                  onChange={(event) => handleLimitChange(event)}
                  value={limit}
                >
                  <option defaultValue>20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>

              <table className="table  ordersTable">
                <thead>
                  <tr>
                    {responseToShow.headersForTable.map((eachHeader) => (
                      <th scope="col">{eachHeader}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {responseToShow.raw.map((eachRes) => (
                    <tr>
                      <td>{eachRes.store.storeName}</td>
                      <td>{eachRes.name}</td>
                      <td>{eachRes.awb_ref_no}</td>
                      <td>{eachRes.newField}</td>
                      <td>{eachRes.deliveredOn}</td>
                      <td>{eachRes.deliveryDetails[0].attempt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  id="previous-page"
                  className="pagination-button"
                  onClick={() =>
                    setOffset(offset - limit > 0 ? offset - limit : 0)
                  }
                >
                  {offset !== 0 ? (
                    <i className="fas fa-arrow-circle-left"></i>
                  ) : (
                    ""
                  )}
                </button>
                <button
                  id="next-page"
                  className="pagination-button"
                  onClick={() =>
                    setOffset(
                      responseToShow.raw.length >= limit ? offset + limit : 0
                    )
                  }
                >
                  {responseLength >= limit ? (
                    <i className="fas fa-arrow-circle-right"></i>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          ) : (
            <h3>Select Date to Proceed</h3>
          )}
        </div>
      </div>
    );
  }
}

export default StrikeRateCSV;

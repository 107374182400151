import React, { useState } from "react";

function ProductItem({ variant, product, checked, mapped, setMapped }) {
  const handleCheck = e => {
    let temp = [...mapped];
    if (e.target.checked) {
      // push

      let element = JSON.parse(e.target.id);

      console.log("Element", element);
      temp.push(element);
      setMapped(temp);
    } else {
      // pop
      let element = JSON.parse(e.target.id);
      temp = mapped.filter(
        product =>
          product.product_id !== element.product_id &&
          product.variant_id !== element.variant_id
      );
      setMapped(temp);
    }
  };

  return (
    <div
      className="card"
      style={{ margin: "20px 20px", border: "1px solid black" }}
    >
      <div className="card-header" style={{ display: "flex" }}>
        <div className="checkbox" style={{ margin: "2px 20px" }}>
          <label>
            <input
              type="checkbox"
              id={`${JSON.stringify({
                store: product.store,
                name: `${product.title} - ${variant.title}`,
                product_id: variant.product_id,
                variant_id: variant.variant_id
              })}`}
              checked={checked}
              className={"inventory_checkbox"}
              onChange={e => handleCheck(e)}
            />
          </label>
        </div>
        <div>
          {`${
            product.store.shopifyName
              ? product.store.shopifyName
              : "Map Store Object "
          } - ${product.title.toUpperCase()} - ${
            variant.title === "Default Title"
              ? "(No Variants)"
              : variant.title.toUpperCase()
          }`}
        </div>
      </div>
      <div className="card-body">
        <div style={{ display: "flex" }}>
          <p>Product ID: </p>
          <p className="card-text">{variant.product_id}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p>Variant ID: </p>
          <p className="card-text">{variant.variant_id}</p>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;

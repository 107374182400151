import React, { Fragment } from "react";
import axios from "axios";

const SRCredits = () => {
  document.title = "ShipRocket SRCredits";
  const submit = async () => {
    document.getElementById("csv_btn").disabled = true;
    var formData = new FormData();
    var csv_file = document.querySelector("#srCredits_csv_input");
    formData.append("file", csv_file.files[0]);

    const result = await axios.post(
      "/api/upload/shipRocket/srCredits",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // if (result.data.msg) {
    //   alert(result.data.msg);
    //   document.getElementById("csv_btn").disabled = false;
    //   return;
    // }
    // console.log(result.data);
    // let blob = new Blob([result.data], { type: "text/csv" });
    // let a = document.createElement("a");
    // a.download = "SRCreditsUploadStatus.csv";
    // a.href = window.URL.createObjectURL(blob);
    // a.setAttribute("style", "display:none;");
    // a.textContent = "Download CSV";

    // a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
    //   ":"
    // );

    // document.body.appendChild(a);
    // a.click();
    // document.getElementById("csv_btn").disabled = false;
    console.log(result.data);
    alert(result.data.message);
  };
  return (
    <div className="card configurationsCard IMS-order-card">
      <div style={{ padding: "20px" }}>
        <form encType="multipart/form-data">
          <Fragment>
            <label htmlFor={`srCredits_csv_input`}>Upload SRCredits CSV</label>
            <div className="form-group resourceForm">
              <input
                type="file"
                name="file"
                accept="*.csv"
                id={`srCredits_csv_input`}
                placeholder={`ShipRocket SRCredits CSV File`}
              />

              <button
                type="button"
                id={`csv_btn`}
                className="btn btn-success btn-product-add"
                onClick={() => submit()}
              >
                Add
              </button>
            </div>
          </Fragment>
        </form>
      </div>
    </div>
  );
};

export default SRCredits;

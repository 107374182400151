import React, { Fragment } from "react";
import BoardOrderitem from "./BoardOrderItem";
import img from "../Assets/ripple-loading.gif";
function Home({
  orders,
  setSelected,
  selected,
  setSelectedR,
  selectedR,
  removeOrders,
  fetchOrders,
  loading,
}) {
  return loading ? (
    <div style={{ marginTop: "20px" }}>
      <img
        src={img}
        alt="Loading..."
        style={{
          position: "absolute",
          width: "150px",
          left: "45vw",
          marginTop: "30vh",
        }}
      />
    </div>
  ) : (
    <Fragment>
      <div className="ops-board-content">
        {orders.length > 0 ? (
          <div className="ops-bar-notification">{orders.length} orders</div>
        ) : (
          ""
        )}
        {orders.map((order) => (
          <BoardOrderitem
            key={order.name}
            order={order}
            setSelected={setSelected}
            selected={selected}
            setSelectedR={setSelectedR}
            selectedR={selectedR}
            removeOrders={removeOrders}
            fetchOrders={fetchOrders}
          />
        ))}
      </div>
    </Fragment>
  );
}

export default Home;

import React from "react";
import { Link } from "react-router-dom";

const AccessOption = ({ access, checked, id, handleEdit }) => {
  return (
    <div className="settingOption accessOption">
      <div className="settingTool">
        <div className="settingTitle">
          <Link to={access.link} className="accessName" target="_blank">
            {access.name}
          </Link>
        </div>
      </div>
      <label className="custom-toggle access-toggle">
        <input
          id={id}
          defaultChecked={checked}
          type="checkbox"
          onChange={(e) => handleEdit(e.target.checked, access)}
        />
        <span className="custom-toggle-slider rounded-circle access-toggle-slider" />
      </label>
    </div>
  );
};

export default AccessOption;

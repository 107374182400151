import React, { Fragment, useState, useEffect } from "react";
import Axios from "axios";
import NavBar from "../res/NavBar";
import { LabelsAndManifestsList } from "../res/LabelsAndManifestsList";
import LabelAndManifestsListCP from "../res/LabelsAndManifestsListCP";

const LabelsAndManifests = () => {
  const [store, setStore] = useState("start");
  document.title = "Labels And Manifests";
  const fetchLabelsAndManifests = () => {
    Axios.get(
      `/api/warehouse/labelsAndManifests?store=${encodeURIComponent(
        JSON.stringify(store)
      )}`
    )
      .then(result => {
        setLabelsAndManifests(result.data.labelsAndManifestsList);
        // console.log(result.data.labelsAndManifestsList);
      })
      .catch(error => console.log("failed to fetch orders"));
  };
  useEffect(() => {
    fetchLabelsAndManifests();
  }, [store]);
  const [labelsAndManifests, setLabelsAndManifests] = useState([]);

  return (
    <Fragment>
      <NavBar store={store} setStore={setStore} />
      {store !== "start" ? (
        <div className='container-fluid'>
          <div className='row'>
            <div className='shadow-lg p-3 mb-5 bg-white rounded'>
              <div className='card'>
                <div className='card-header'>
                  <h4>List of Labels and Manifests Generated By Application</h4>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <table className='table table-hover'>
                      <thead>
                        <tr>
                          <th scope='col'>No</th>
                          <th scope='col'>Date</th>
                          <th scope='col'>Manifests And Labels</th>
                        </tr>
                      </thead>
                      <tbody>
                        {labelsAndManifests.map(
                          (labelAndManifestDetails, index) =>
                            labelAndManifestDetails.clickpost ? (
                              <LabelAndManifestsListCP
                                key={index}
                                labelAndManifestDetails={
                                  labelAndManifestDetails
                                }
                                index={index}
                              />
                            ) : (
                              <LabelsAndManifestsList
                                key={index}
                                labelAndManifestDetails={
                                  labelAndManifestDetails
                                }
                                index={index}
                              />
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>Choose a store</h2>
        </div>
      )}
    </Fragment>
  );
};
export default LabelsAndManifests;

import axios from "axios";

const forgotPassword = async (email) => {
  // 1. check if the email given is already there in the db
  // 2. if email is present in the db, then sent a link to the respective email to reset password
  // 3. if email is not present in db, then alert the error
  // 4. the email sent to the user will contain a link to a page like login where the user can reset the password
  // 5. the url of the reset password page will be appended with a string. This page will only be valid for 5 minutes. with this page, there will be an associated user so that we reset the password only for that user
  // 6. Now go figure!
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    email,
  };
  try {
    let data = await axios.post("/api/auth/forgotPassword", body, config);
    return data.data.msg;
  } catch (error) {
    console.log(error);
  }
};

export default forgotPassword;

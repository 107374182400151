import React, { Fragment } from "react";
import Axios from "axios";

function BatchUpdateOrderDetails() {
  document.title = "Batch Update ShipRocket OrderIDs";
  const handleSubmit = async () => {
    if (document.getElementById("shiprocketorderids").value === "") {
      alert("Please enter order id in the text area");
      return;
    }
    const orderIds = document
      .getElementById("shiprocketorderids")
      .value.split("\n");
    console.log(`This is the order ids array : `, orderIds);
    //hit the shiprocket api to fetch order details of each order id
    let response = await Axios.post(`/api/operations/batchUpdateOrderDetails`, {
      orderIds,
    });
    console.log(response);
    let updateUnsuccessful = response.data.unsuccessfullupdate;
    let updateSuccessful = response.data.successfullupdate;
    let unsuccessfulMsg = "";
    if (updateUnsuccessful.length === 0) alert(`SUCCESSFUL UPDATE!`);
    else {
      updateUnsuccessful.map((orderId, index) => {
        if (index === updateUnsuccessful.length - 1)
          unsuccessfulMsg += orderId + ".";
        else unsuccessfulMsg += orderId + ", ";
      });
      alert(
        `SUCCESSFULL update count  : ${updateSuccessful.length}\nUNSUCCESSFULL UPDATE\ncount : ${updateUnsuccessful.length}\norder ids : ${unsuccessfulMsg}`
      );
    }
  };

  return (
    <Fragment>
      <div style={{ padding: "20px" }}>
        <h2>Batch Update Order Details</h2>
        <div className="form-group">
          <label for="shiprocketOrderIds">
            Place Your List Of OrderIds(ShipRocket) here
          </label>
          <textarea
            className="form-control"
            id="shiprocketorderids"
            rows="3"
            style={{ height: "70vh", borderRadius: "0px" }}
          ></textarea>
          <button
            type="submit"
            className="btn btn-primary mb-2 btn-lg"
            style={{ borderRadius: "0px", marginTop: "10px" }}
            onClick={handleSubmit}
          >
            Update Orders Details
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default BatchUpdateOrderDetails;

// ===========================================================
// BATCH UPDATE MULTIPLE SHIPROCKET ORDER IDS
// shiprocket order id example : 33457268
// take multi line input containing shiprocket order ids
// loop through all the order ids
// hit the endpoint for each orderid and update

// --> in the frontend
// let input = document.findElementById('input').value
// // convert our input to list format if it is not like that
// input.map(id => {
//     await Axios.get('/updateOrderDetails/${id}/${store}')
// })

// ==============================================================
// BATCH UPDATE MULTIPLE AWBS
// take multi line input containing multiple awbs
// loop through a;; stores and find the order for that awb
// from the order reference the shiprocket order id
// update

import React, { Fragment, useState, useEffect } from "react";
import Axios from "axios";
import Spinner from "../../global/Spinner";

function BatchReprintManifests() {
  const [links, setLinks] = useState(null);
  const [loading, setLoading] = useState(false);
  document.title = "Reprint Manifests And Labels";

  const handleSubmit = async () => {
    setLoading(true);
    if (!document.getElementById("awbArray").value) {
      alert("Please enter AWBs ");
      return;
    }
    const awbArray = document.getElementById("awbArray").value.split("\n");
    let response = await Axios.post(
      `/api/warehouse/reprintManifestAndLabelsShiprocket`,
      { awbArray }
    );
    if (!response.data.status) {
      alert(response.data.message);
      return;
    }
    alert(response.data.message);
    setLinks(response.data.finalDownloadLinks);
    setLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <Fragment>
      <div style={{ padding: "20px" }}>
        <h2>Reprint Manifests And Labels</h2>
        <div className="form-group">
          <label for="awbArray">Scan/Type AWB's below one after another</label>
          <textarea
            className="form-control"
            id="awbArray"
            rows="3"
            style={{ height: "70vh", borderRadius: "0px" }}
          ></textarea>
          <div style={{ display: "flex" }}>
            <button
              type="submit"
              className="btn btn-primary mb-2 btn-lg"
              style={{
                borderRadius: "0px",
                marginTop: "10px",
                marginRight: "10px",
              }}
              onClick={handleSubmit}
            >
              Reprint Manifests And Labels
            </button>
            {loading ? <Spinner /> : null}
          </div>
        </div>
      </div>
      {links ? (
        <div className="shadow-lg p-3 mb-5 bg-white rounded">
          <div className="card">
            <div className="card-body">
              {links.shiprocket ? (
                <div>
                  <p>
                    <a href={links.shiprocket.labelsLink} target="_blank">
                      ShipRocket labels download link
                    </a>
                  </p>
                  <p>
                    <a href={links.shiprocket.manifestsLink} target="_blank">
                      ShipRocket manifests Download link
                    </a>
                  </p>
                </div>
              ) : null}
              {links.shipyaari ? (
                <div>
                  {Object.keys(links.shipyaari).map((store) => (
                    <div>
                      <p>
                        <a
                          href={links.shipyaari[store].labelsLink}
                          target="_blank"
                        >
                          {`Shipyaari labels download link - ${store}`}
                        </a>
                      </p>
                      <p>
                        <a
                          href={links.shipyaari[store].manifestsLink}
                          target="_blank"
                        >
                          {`Shipyaari manifests download link - ${store}`}
                        </a>
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
              {links.shadowfax ? (
                <div>
                  <p>
                    <a href={links.shadowfax.labelsLink} target="_blank">
                      Shadowfax labels download link
                    </a>
                  </p>
                  <p>
                    <a href={links.shadowfax.manifestsLink} target="_blank">
                      Shadowfax manifests Download link
                    </a>
                  </p>
                </div>
              ) : null}
              {links.shiprocketTwo ? (
                <div>
                  <p>
                    <a href={links.shiprocketTwo.labelsLink} target="_blank">
                      ShipRocketTwo labels download link
                    </a>
                  </p>
                  <p>
                    <a href={links.shiprocketTwo.manifestsLink} target="_blank">
                      ShipRocketTwo manifests Download link
                    </a>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

export default BatchReprintManifests;

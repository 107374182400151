import axios from "axios";
import React, { useState } from "react";
import StoreDropdownButton from "../../operations/res/StoreDropdownButton";
import Spinner from "../../home/res/Spinner";

// for the view expenses there should be a date picker and a pie chart
const now = new Date();
const yearOptions = [];
const bazingaStartDate = 2017;
for (
  let yearOption = bazingaStartDate;
  yearOption <= now.getFullYear();
  yearOption++
) {
  yearOptions.push(yearOption);
}
const monthOptions = [
  {
    name: "January",
    value: 1,
  },
  {
    name: "February",
    value: 2,
  },
  {
    name: "March",
    value: 3,
  },
  {
    name: "April",
    value: 4,
  },
  {
    name: "May",
    value: 5,
  },
  {
    name: "June",
    value: 6,
  },
  {
    name: "July",
    value: 7,
  },
  {
    name: "August",
    value: 8,
  },
  {
    name: "September",
    value: 9,
  },
  {
    name: "October",
    value: 10,
  },
  {
    name: "November",
    value: 11,
  },
  {
    name: "December",
    value: 12,
  },
];

function ViewStoreExpensesAndProfits({ setPage }) {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState(null);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [expenditures, setExpenditures] = useState(null);
  const [total, setTotal] = useState(null);

  const handleSubmit = async () => {
    if (!store || !year || !month) {
      alert(`Please select all the filters`);
      return;
    }
    const query = {
      params: {
        store: store.storeAnchor,
        year,
        month,
      },
    };
    const url = `/api/stores/expenditure`;
    setLoading(true);
    const { data } = await axios.get(url, query);
    setLoading(false);
    console.log(data);
    setExpenditures(data.totalExpenditure);
    setTotal(data.TOTAL);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div id="store-expenses-view-container">
      <div id="store-expenses-view-filters">
        <StoreDropdownButton
          store={store}
          setStore={setStore}
          showDeleted={false}
        />
        <select
          id="store-expenses-view-filter-year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          <option value="">Select a year</option>
          {yearOptions.map((yearOption) => (
            <option value={yearOption}>{yearOption}</option>
          ))}
        </select>
        <select
          id="store-expenses-view-filter-month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        >
          <option value="">Select a month</option>
          {monthOptions.map((monthOption) => (
            <option value={monthOption.value}>{monthOption.name}</option>
          ))}
        </select>
        <div id="store-expenses-view-filter-button-container">
          <button className="store-expenses-button" onClick={handleSubmit}>
            Submit
          </button>
          {/* <button className="store-expenses-button" onClick={handleReset}>
            Reset
          </button> */}
          <button
            className="store-expenses-button"
            onClick={() => setPage(null)}
          >
            Back
          </button>
        </div>
      </div>
      {expenditures && !loading ? (
        <>
          <div id="store-expenses-view-expenses">
            <div id="store-expenses-view-store-info">
              <div>{`Showing ${store.storeName} expenses and profits for ${
                monthOptions.find((option) => option.value == month).name
              } ${year}`}</div>
            </div>
            {Object.keys(expenditures).map((expenditure) => (
              <div className="store-expenses-view-expense-item">
                <div className="store-expenses-view-expense-item-label">
                  {expenditure}
                </div>
                <div
                  className={`store-expenses-view-expense-item-amount-${
                    expenditure === "Delivered Revenue" ? "green" : "red"
                  }`}
                >
                  {`\u20B9${numberWithCommas(
                    parseInt(expenditures[expenditure])
                  )}`}
                </div>
              </div>
            ))}
            <div
              id={`store-expenses-view-expense-total-${
                total > 0 ? "green" : "red"
              }`}
            >{`TOTAL ${
              total > 0 ? "PROFIT" : "LOSS"
            }   \u20B9${numberWithCommas(parseInt(Math.abs(total)))}`}</div>
          </div>
        </>
      ) : loading ? (
        <Spinner />
      ) : null}
    </div>
  );
}

export default ViewStoreExpensesAndProfits;

import React, { useState, useEffect } from "react";
import setAuthToken from "./Auth/utils/setAuthToken";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import SpeechToText from "speech-to-text";

import OldHome from "./components/Home";
import Home from "./components/HomeTwo";

import Settings from "./components/settings/layouts/Settings";

import Access from "./components/admin/layouts/Access";

import Stores from "./components/stores/layouts/Stores";

// ADMIN BOARD
import AggregatorDashboard from "./components/admin/layouts/AggregatorDashboard";
import ProfitCalculator from "./components/admin/layouts/ProfitCalculator";
import FavoriteProducts from "./components/admin/layouts/FavoriteProducts";
import SLABreach from "./components/admin/layouts/SLABreach";
import BillingBoard from "./components/admin/layouts/BillingBoard";
import Axios from "axios";
import StatsModule_v2 from "./components/admin/layouts/StatsModule_v2";
import StatsCSV from "./components/admin/layouts/StatsCSV";
import FakeDimensions from "./components/admin/layouts/FakeDimensions";
import EmailConfigurations from "./components/admin/layouts/EmailConfigurations";
import BugTracker from "./BugTracker/layouts/BugTracker";

// please paste operations components here
import OrderAction from "./components/operations/layouts/OrderAction";
import ScheduleOrdersManually from "./components/operations/layouts/ScheduleOrdersManually";
import Products from "./components/operations/layouts/Products";
import CustomerAddressForm from "./components/operations/layouts/CustomerAddressForm";
import UpdateOrderDetails from "./components/operations/layouts/UpdateOrderDetails";
import WeightDiscrepancy from "./components/operations/layouts/ShipRocketWeightDiscrepancy";
import SRCredits from "./components/operations/layouts/ShipRocketSRCredits";
import ShippingCharges from "./components/operations/layouts/ShipRocketShippingCharges";
import AbandonedCheckouts from "./components/operations/layouts/AbandonedCheckouts";
import DamagedProductImage from "./components/operations/layouts/DamagedProductImages";
import PickupRequestGeneration from "./components/operations/layouts/PickupRequestGeneration";
import ReassignCourier from "./components/operations/layouts/ReassignCourier";
import BatchUpdateOrderDetails from "./components/operations/layouts/BatchUpdateOrderDetails";
import StrikeRateCSV from "./components/operations/layouts/StrikeRateCSV";
import StrikeRateAnalysis from "./components/operations/layouts/StrikeRateAnalysis";
import cancelGoSwiftDuplicateOrders from "./components/operations/layouts/cancelGoSwiftDuplicateOrders";
import UploadRemittance from "./components/operations/layouts/UploadRemittance";
import OperationsDashboard from "./OperationsDashboard/layouts/Dashboard";
import UploadLeads from "./components/operations/layouts/UploadLeads";
import UploadOrdersCsv from "./components/operations/layouts/UploadOrdersCsv";
import Experiments from "./components/operations/layouts/Experiments";
import UpdateStatusCodes from "./components/operations/layouts/UpdateStatusCodes";
import DraftOrder from "./components/operations/layouts/DraftOrder";
import UploadShadowFaxAwbs from "./components/operations/layouts/UploadShadowFaxAwbs";

// please paste warehouse components here
import PrintManifestsAndLabels from "./components/warehouse/layouts/PrintManifestsAndLabels";
import AddAWB from "./components/warehouse/layouts/AddAWB";
import ScanAndShipAWB from "./components/warehouse/layouts/ScanAndShipAWB";
import ScanAndShipAWBForward from "./components/warehouse/layouts/ScanAndShipAWBForward";
import BarcodeScan from "./components/warehouse/layouts/BarcodeScan";
import BarCodeSampleScan from "./components/warehouse/layouts/BarCodeSampleScan";
import RtoOrders from "./components/warehouse/layouts/RtoOrders";
import RtoStats from "./components/warehouse/layouts/RtoStats";
import LabelsAndManifests from "./components/warehouse/layouts/LabelsAndManifests";
import GlobalLabelsAndManifests from "./components/warehouse/layouts/LabelsAndManifestsV3";
import RejectedPackages from "./components/warehouse/layouts/RejectedPackages";
import UploadImages from "./components/warehouse/layouts/UploadImages";
import LabelsAndManifestsStatus from "./components/warehouse/layouts/LabelsAndManifestsStatus";
import ReprintLabelAndManifest from "./components/warehouse/layouts/ReprintLabelAndManifest";
import PickupGenerationList from "./components/warehouse/layouts/PickupGenerationList";
import DispatchedAndPending from "./components/warehouse/layouts/DispatchedAndPending";
import BulkReprintManifests from "./components/warehouse/layouts/BulkReprintManifests";
import AllScanning from "./components/warehouse/layouts/AllScanning";

// TRACKING BOARD
import OrderTracker from "./trackingboard/OrderTracker";
import CallingTracker from "./trackingboard/CallingTracker";
import { CallerProvider } from "./trackingboard/CallingTracker/components/context/caller-context";
import Order from "./trackingboard/OrderTracker/components/layouts/Order";

// CALLING BOARD
import SupportSettings from "./supportsettings/Dashboard";

// IMS
import "./IMS/IMS.css";
import { UserProvider } from "./IMS/components/context/user-context";
import Inventory from "./IMS/components/Inventory";
import AddInventory from "./IMS/components/layouts/AddInventory";
import AddResource from "./IMS/components/layouts/AddResource";

// Auth
import Login from "./Auth/components/Login";
import Logout from "./Auth/components/Logout";
import ForgotPassword from "./Auth/components/ForgotPassword";
import SetPassword from "./Auth/components/SetPassword";
import Register from "./Auth/components/Register";
import PrivateRoute from "./Auth/routing/PrivateRoute";
import MasterRoute from "./Auth/routing/MasterRoute";

// Global-Context
import { GlobalProvider } from "./Global-Context/global-context";
import { GlobalConsumer } from "./Global-Context/global-context";

// global
import MasterBar from "./components/global/MasterBar";
import Bot from "./components/global/Bot";
import Navigation from "./components/global/Navigation";
import useGlobalSearchBar from "./components/global/GlobalSearchBar";

// marketing
import FBAdvertisement from "./components/marketing/layouts/FBAdvertisement";
import ThresholdCalculator from "./components/marketing/layouts/ThresholdCalculator";
import SmsMobi from "./components/marketing/layouts/SmsMobi";

import ClickFunnels from "./ClickFunnels/layouts/ClickFunnels";

import InventoryDashboard from "./Inventory_2.0/layouts/Dashboard";
import InventoryBoard_v2 from "./Inventory_2.0/layouts/InventoryBoard_v2";

import CallingDashboard from "./CallingDashboard/components/layouts/Dashboard";

import ClickFunnelsWebhook from "./ClickFunnels/layouts/ClickFunnelsWebhook";
import GetLabels from "./components/warehouse/layouts/GetLabels";
import AwbHistory from "./components/warehouse/layouts/AwbHistory";
import OrderFlagger from "./components/admin/layouts/OrderFlagger";
import SalarySlips from "./components/admin/layouts/SalarySlips";
import DialerBoard from "./dialerboard/DialerBoard";
import useAlert from "./components/global/Alert";
import axios from "axios";
import Discounts from "./components/admin/layouts/Discounts";
import DialerStats from "./dialerboard/DialerStats";
import SendSMS from "./components/global/SendSMS";
import Customer from "./components/customer/Customer";
import DialerInboundBoard from "./dialerboard/DialerInboundBoard";
import RepeatCustomerStats from "./components/admin/layouts/RepeatCustomerStats";
import StoreExpenses from "./components/stores/layouts/StoreExpenses";
// import DraftShipment from "./components/operations/layouts/DraftShipment";
import TemporaryProductNames from "./components/operations/layouts/TemporaryProductNames";
import LinkShipmentAndOrder from "./components/operations/layouts/LinkShipmentAndOrder";
import GetLabelsV2 from "./components/warehouse/layouts/GetLabelsV2";

//routerHistory file

// const sse = new EventSource("http://127.0.0.1:5000/subscribe");
// sse.addEventListener("notice", function (e) {
//   console.log(e.data);
// });

// /* Similarly, this will listen for events
//  * with the field `event: update`
//  */
// sse.addEventListener("update", function (e) {
//   console.log(e.data);
// });

// /* The event "message" is a special case, as it
//  * will capture events without an event field
//  * as well as events that have the specific type
//  * `event: message` It will not trigger on any
//  * other event type.
//  */
// sse.addEventListener("message", function (e) {
//   console.log(e.data);
// });

function App() {
  const [, FloatingSearchBar] = useGlobalSearchBar();
  const [updated, setUpdated] = useState(false);
  const [AlertBox, setNotification] = useAlert();
  const [command, setCommand] = useState("");

  if (localStorage.user) {
    setAuthToken(localStorage.user);
  }

  // axios.interceptors.request.use(
  //   function (config) {
  //     // Do something before request is sent
  //     // if (!window.location.host.includes("localhost"))
  //     //   setTimeout(
  //     //     () =>
  //     //       alert(
  //     //         "Our server was not able to process your request on time, its been over 2 minuts. Please try again after sometime"
  //     //       ),
  //     //     120000
  //     //   );
  //     console.log(config);
  //     if (config.request) config.request.onerror(() => console.log("errr"));
  //     return config;
  //   },
  //   function (error) {
  //     // Do something with request error
  //     console.log("GG", error);
  //     return Promise.reject(error);
  //   }
  // );

  // axios.interceptors.response.use(
  //   function (response) {
  //     console.log("JJ", response);
  //     return response;
  //   },
  //   (error) => {
  //     console.log("JJggggg", error);
  //     return new Error(error);
  //   }
  // );

  // useEffect(() => {
  //   const onAnythingSaid = (text) => {
  //     console.log("ANYTHINGSAID");
  //     console.log(text);
  //   };

  //   const onEndEvent = () => {
  //     console.log("ENDEVENT");
  //     voiceEngine.startListening();
  //   };

  //   const onFinalised = (text) => {
  //     console.log("FINALIZED");
  //     setCommand(text);
  //   };

  //   const voiceEngine = new SpeechToText(
  //     onFinalised,
  //     onEndEvent,
  //     onAnythingSaid
  //   );

  //   voiceEngine.startListening();
  // }, []);

  // useEffect(() => {
  //   console.log(command);
  //   if (command.includes("tracking board")) {
  //     window.location.href = "http://localhost:3000/operations/orderboard";
  //   }
  //   if (command.includes("operations board")) {
  //     window.location.href = "http://localhost:3000/operations/dashboard";
  //   }
  //   if (command.includes("operations board")) {
  //     window.location.href = "http://localhost:3000/operations/dashboard";
  //   }
  //   if (command.includes("idiot")) {
  //     var msg = new SpeechSynthesisUtterance("I'm offended!");
  //     window.speechSynthesis.speak(msg);
  //     alert("I'm offended!");
  //   }
  // }, [command]);

  return (
    <GlobalProvider>
      <Router>
        <GlobalConsumer>
          {({ getUser, isAuthenticated, user, signOut }) => {
            if (user === false && !isAuthenticated) {
              console.log("Welcome,", getUser().name);
            } else {
              console.log("Welcome,", user.name);
            }
          }}
        </GlobalConsumer>
        <div className="App">
          <Switch>
            <Route path="/login" exact component={Login} />

            <Route path="*" component={MasterBar} />
          </Switch>
          <Bot />
          {/* <SendSMS /> */}
          <FloatingSearchBar />
          <Navigation />
          <AlertBox />

          <Route path="/tracking" component={Customer} />

          <PrivateRoute path="/" exact component={Home} />
          <Route path="/oldHomePage" exact component={OldHome} />

          {/* AUTH */}

          <Route path="/logout" exact component={Logout} />
          <Route
            path="/forgotPassword/:randomString"
            exact
            component={ForgotPassword}
          />

          {/* MARKETING */}
          <PrivateRoute
            path="/marketing/thresholdCalculator"
            exact
            component={ThresholdCalculator}
          />
          <PrivateRoute
            path="/marketing/inventoryMapper"
            exact
            component={InventoryDashboard}
          />
          <PrivateRoute
            path="/marketing/FBAds"
            exact
            component={FBAdvertisement}
          />
          <PrivateRoute
            path="/marketing/SMSMarketing"
            exact
            component={SmsMobi}
          />
          {/* OPERATIONS */}

          <PrivateRoute
            path="/operations/dashboard"
            exact
            component={OperationsDashboard}
          />

          <PrivateRoute
            path="/operations/damagedProductImages"
            exact
            component={DamagedProductImage}
          />

          <PrivateRoute
            path="/operations/cancelAnOrder"
            exact
            component={OrderAction}
          />

          <PrivateRoute
            path="/operations/updateOrderDetails"
            exact
            component={UpdateOrderDetails}
          />
          <PrivateRoute
            path="/operations/scheduleAnOrderManually"
            exact
            component={OrderAction}
          />

          <PrivateRoute
            path="/operations/scheduleOrdersManually"
            exact
            component={ScheduleOrdersManually}
          />

          <PrivateRoute
            path="/operations/products"
            exact
            component={Products}
          />

          <Route
            path="/operations/address"
            exact
            component={CustomerAddressForm}
          />

          <PrivateRoute
            path="/operations/weightDiscrepancy"
            exact
            component={WeightDiscrepancy}
          />

          <PrivateRoute
            path="/operations/srCredits"
            exact
            component={SRCredits}
          />

          <PrivateRoute
            path="/operations/shippingCharges"
            exact
            component={ShippingCharges}
          />

          <PrivateRoute
            path="/operations/generatePickup"
            exact
            component={PickupRequestGeneration}
          />

          <PrivateRoute
            path="/operations/reassignCourier"
            exact
            component={ReassignCourier}
          />

          <PrivateRoute
            path="/operations/bulkUpdateOrderDetails"
            exact
            component={BatchUpdateOrderDetails}
          />

          <PrivateRoute
            path="/operations/updateStatusCodesWithAwbs"
            exact
            component={UpdateStatusCodes}
          />

          <PrivateRoute
            path="/operations/cancelGoSwiftDuplicateOrders"
            exact
            component={cancelGoSwiftDuplicateOrders}
          />

          <PrivateRoute
            path="/operations/uploadRemittance"
            exact
            component={UploadRemittance}
          />

          <PrivateRoute
            path="/operations/uploadLeads"
            exact
            component={UploadLeads}
          />

          <PrivateRoute
            path="/operations/UploadOrdersCsv"
            exact
            component={UploadOrdersCsv}
          />

          <PrivateRoute
            path="/operations/experiments"
            exact
            component={Experiments}
          />

          <PrivateRoute
            path="/operations/draftOrder"
            exact
            component={DraftOrder}
          />

          {/* <PrivateRoute
            path="/operations/draftShipment"
            exact
            component={DraftShipment}
          /> */}

          <PrivateRoute
            path="/operations/uploadShadowfaxAwbs"
            exact
            component={UploadShadowFaxAwbs}
          />

          <PrivateRoute
            path="/operations/temporaryProductNames"
            exact
            component={TemporaryProductNames}
          />

          <PrivateRoute
            path="/operations/linkShipmentAndOrder"
            exact
            component={LinkShipmentAndOrder}
          />

          {/* WAREHOUSE */}

          <PrivateRoute
            path="/warehouse/awbHistory"
            exact
            component={AwbHistory}
          />

          <PrivateRoute
            path="/warehouse/printManifestsAndLabels"
            exact
            component={PrintManifestsAndLabels}
          />

          <PrivateRoute
            path="/warehouse/getLabels"
            exact
            component={GetLabels}
          />

          <PrivateRoute
            path="/warehouse/getLabelsV2"
            exact
            component={GetLabelsV2}
          />

          {/* <PrivateRoute path="/warehouse/rtoStats" exact component={RTOStats} /> */}

          <PrivateRoute
            path="/warehouse/scanBarcode"
            exact
            component={BarcodeScan}
          />

          {/* sample testing route for bar code scanner */}
          <PrivateRoute
            path="/warehouse/scanBarcodesample"
            exact
            component={BarCodeSampleScan}
          />

          <PrivateRoute
            path="/warehouse/labelsAndManifests"
            exact
            component={LabelsAndManifests}
          />

          <PrivateRoute
            path="/warehouse/labelsAndManifests_V3"
            exact
            component={GlobalLabelsAndManifests}
          />

          <PrivateRoute
            path="/warehouse/rtoOrders"
            exact
            component={RtoOrders}
          />

          <PrivateRoute
            path="/warehouse/dispatchedAndPending"
            exact
            component={DispatchedAndPending}
          />

          <PrivateRoute path="/warehouse/rtoStats" exact component={RtoStats} />

          <PrivateRoute
            path="/warehouse/scanAndShipAWB"
            exact
            component={ScanAndShipAWB}
          />

          <PrivateRoute
            path="/warehouse/allScans"
            exact
            component={AllScanning}
          />

          <PrivateRoute path="/warehouse/addAWB" exact component={AddAWB} />

          <PrivateRoute
            path="/warehouse/rejectedPackages"
            exact
            component={RejectedPackages}
          />

          <PrivateRoute
            path="/warehouse/uploadImages"
            exact
            component={UploadImages}
          />

          <PrivateRoute
            path="/warehouse/labelsAndManifestsStatus"
            exact
            component={LabelsAndManifestsStatus}
          />

          <PrivateRoute
            path="/warehouse/reprintLabelAndManifest"
            exact
            component={ReprintLabelAndManifest}
          />
          <PrivateRoute
            path="/warehouse/bulkReprintManifests"
            exact
            component={BulkReprintManifests}
          />
          <PrivateRoute
            path="/warehouse/pickupGeneratorList"
            exact
            component={PickupGenerationList}
          />
          <PrivateRoute
            path="/operations/strikeRateCSV"
            exact
            component={StrikeRateCSV}
          />
          <PrivateRoute
            path="/operations/strikeRateAnalysis"
            exact
            component={StrikeRateAnalysis}
          />
          {/* <PrivateRoute
            path="/warehouse/scanAndShipAWB"
            exact
            component={ScanAndShipAWB}
          /> */}

          <PrivateRoute
            path="/store/expenses"
            exact
            component={StoreExpenses}
          />

          <MasterRoute path="/stores" exact component={Stores} />
          <MasterRoute
            path="/admin/salarySlips"
            exact
            component={SalarySlips}
          />
          <MasterRoute path="/orderflagger" exact component={OrderFlagger} />

          <MasterRoute path="/admin/access" exact component={Access} />

          <MasterRoute path="/settings" exact component={Settings} />

          <PrivateRoute path="/statsV2" exact component={StatsModule_v2} />

          <PrivateRoute path="/statsCSV" exact component={StatsCSV} />

          <PrivateRoute
            path="/repeatPurchaseCalculator"
            exact
            component={RepeatCustomerStats}
          />

          {/* CALLING BOARD */}

          <PrivateRoute
            exact
            path="/support/callingdashboard"
            component={CallingDashboard}
          />

          <PrivateRoute
            exact
            path="/support/dialerboard"
            component={DialerBoard}
          />

          <PrivateRoute
            exact
            path="/support/inboundCallReport"
            component={DialerInboundBoard}
          />

          <PrivateRoute
            exact
            path="/support/dialerstats"
            component={DialerStats}
          />

          <PrivateRoute
            exact
            path="/support/supportsettings"
            component={SupportSettings}
          />

          {/* TRACKING BOARD */}
          <Route exact path="/order" component={Order} />
          <PrivateRoute
            path="/operations/orderboard/:store?/:id?"
            component={OrderTracker}
          />
          <PrivateRoute
            exact
            path="/operations/pullAbandonedCheckouts"
            component={AbandonedCheckouts}
          />

          <CallerProvider>
            <PrivateRoute
              exact
              path="/operations/callingboard"
              component={CallingTracker}
            />
          </CallerProvider>
          {/* CLICK FUNNELS */}
          <PrivateRoute
            path="/admin/clickFunnels"
            exact
            component={ClickFunnels}
          />
          <PrivateRoute
            path="/admin/clickfunnelswebhook"
            exact
            component={ClickFunnelsWebhook}
          />
          <PrivateRoute
            path="/admin/selectProducts"
            exact
            component={FakeDimensions}
          />

          {/* INVENTORY BOARD */}

          <PrivateRoute
            path="/admin/inventory_v2"
            exact
            component={InventoryBoard_v2}
          />

          {/* ADMIN BOARD */}

          <PrivateRoute path="/admin/bugTracker" exact component={BugTracker} />

          <PrivateRoute
            path="/admin/profitCalculator"
            exact
            component={ProfitCalculator}
          />

          <PrivateRoute
            path="/admin/aggregators"
            exact
            component={AggregatorDashboard}
          />

          <PrivateRoute
            path="/admin/favouriteProducts"
            exact
            component={FavoriteProducts}
          />

          <PrivateRoute
            path="/admin/SLABreachChecker"
            exact
            component={SLABreach}
          />

          <PrivateRoute
            path="/admin/billingBoard"
            exact
            component={BillingBoard}
          />

          <PrivateRoute
            path="/admin/emailConfigurations"
            exact
            component={EmailConfigurations}
          />

          <PrivateRoute
            path="/admin/discountCoupons"
            exact
            component={Discounts}
          />

          <UserProvider>
            <PrivateRoute path="/admin/dashboard" exact component={Inventory} />
            <MasterRoute path="/admin/addUser" exact component={Register} />
            <Route
              path="/setPassword/:randomString"
              exact
              component={SetPassword}
            />
            <MasterRoute
              path="/admin/addOrder"
              exact
              component={AddInventory}
            />
            <PrivateRoute
              path="/admin/addResource"
              exact
              component={AddResource}
            />
          </UserProvider>
        </div>
      </Router>
    </GlobalProvider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Cryptr from "cryptr";

import StoreDropdownButton from "../res/StoreDropdownButton";
import CustomerDetails from "../res/CustomerDetails";
import ProductDetails from "../res/ProductDetails";
import DraftOrderDetails from "../res/DraftOrderDetails";
import Spinner from "../../global/Spinner";
import useAlert from "../../global/Alert";

const initializeCustomer = {
  first_name: "",
  last_name: "",
  name: "",
  email: "",
  phone: "",
  address1: "",
  address2: "",
  company: "",
  city: "",
  province: "",
  zip: "",
  country: "",
};

const DraftOrder = () => {
  document.title = "Draft Order";

  // All state
  const [user, setUser] = useState(null);
  const [store, setStore] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerType, setCustomerType] = useState(null);
  const [customer, setCustomer] = useState(initializeCustomer);
  const [uneditedCustomerDetails, setUneditedCustomerDetails] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [orderName, setOrderName] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [flags, setFlags] = useState({
    paid: false,
    skipFlagging: false,
    partialPayment: false,
  });
  const [partialPaymentAmount, setPartialPaymentAmount] = useState(0);
  const [prepaidRemark, setPrepaidRemark] = useState("");
  const [partialPaymentRemark, setPartialPaymentRemark] = useState("");
  const [
    existingCustomerDifferentStore,
    setExistingCustomerDifferentStore,
  ] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [discountCoupon, setDiscountCoupon] = useState(null);
  const [AlertBox, setNotification] = useAlert();
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);

  // Partial payment amount handling
  const handlePartialPaymentAmount = (e) => {
    const amount = parseFloat(e.target.value);
    if (amount < 0) {
      setNotification("failure", "Amount cannot be negative!");
      return;
    }

    // check if partial payment amount greater than order total
    let total = 0;
    lineItems.map((item) => {
      const price = parseFloat(item.quantity) * parseFloat(item.price);
      total += price;
    });
    if (amount > total) {
      setNotification("failure", "Amount cannot be greater than total price!");
      return;
    }

    setPartialPaymentAmount(amount);
  };

  const handlePrepaidRemark = (e) => {
    setPrepaidRemark(e.target.value);
  };

  const handlePartialPaymentRemark = (e) => {
    setPartialPaymentRemark(e.target.value);
  };

  // handle full reset
  const resetComponent = () => {
    setStore(null);
    setCustomerId(null);
    setCustomerType(null);
    setCustomer(initializeCustomer);
    setOrderName(null);
    setAddressId(null);
    setUneditedCustomerDetails(null);
    setLineItems([]);
    setFlags({
      paid: false,
      skipFlagging: false,
      partialPayment: false,
      discountCoupon: false,
    });
    setPartialPaymentAmount(0);
    setPrepaidRemark("");
    setExistingCustomerDifferentStore(false);
    setDiscountCode("");
    setDiscountCoupon(null);
    setLoading(false);
    setNotification("success", "Draft order reset!");
  };

  // demo video
  const handleDemo = () => {
    const loomVideoUrl = `https://www.loom.com/share/8807b8c1a22949ae8e216e72cb739eec`;
    window.open(loomVideoUrl, "_blank");
  };

  const handleDuplicateCustomer = () => {
    const shopifyCustomerUrl = `https://${store.shopifyName}.myshopify.com/admin/customers`;
    window.open(shopifyCustomerUrl, "_blank");
  };

  // final submit to draft the order
  const handleSubmit = async () => {
    setLoading(true);

    if (!store) {
      setNotification("failure", "Please select a store to draft an order!");
      setLoading(false);
      return;
    }
    if (lineItems.length === 0) {
      setNotification("failure", "Please add a product to order!");
      setLoading(false);
      return;
    }

    // if prepaid order do not proceed without remarks
    if (flags.paid && !prepaidRemark) {
      setNotification(
        "failure",
        "Please give a remark for making order prepaid!"
      );
      setLoading(false);
      return;
    }

    // new handling customer creation or updation
    let finalCustomerId = customerId;
    const customerDetails = {
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      // phone: customer.phone, // customer updation problem testing
      addresses: [
        {
          address1: customer.address1,
          address2: customer.address2,
          first_name: customer.first_name,
          last_name: customer.last_name,
          name: customer.first_name.trim() + " " + customer.last_name.trim(),
          city: customer.city,
          province: customer.province,
          country: "India",
          zip: customer.zip,
          phone: customer.phone,
          default: true,
        },
      ],
    };
    if (!customerType) {
      setNotification(
        "failure",
        "Please add an existing customer or create a new customer!"
      );
      setLoading(false);
      return;
    } else if (customerType === "new") {
      // add new customer to shopify
      const payload = {
        newCustomer: true,
        store,
        customerDetails,
      };
      const createResponse = await axios.post(
        "/api/operations/addOrUpdateCustomerOnShopify",
        payload
      );
      setNotification(
        createResponse.data.status ? "success" : "failure",
        createResponse.data.message
      );
      if (!createResponse.data.status) {
        setLoading(false);
        return;
      }
      finalCustomerId = createResponse.data.id;
      setCustomerId(finalCustomerId);
    } else if (customerType === "existing") {
      // existing customer but from different store - to be treated as new customer
      if (existingCustomerDifferentStore) {
        const payload = {
          newCustomer: false,
          customerDetails,
          store,
          existingCustomerDifferentStore,
        };
        const updateResponse = await axios.post(
          "/api/operations/addOrUpdateCustomerOnShopify",
          payload
        );
        finalCustomerId = updateResponse.data.id;
        setCustomerId(finalCustomerId);
        setNotification(
          updateResponse.data.status ? "success" : "failure",
          updateResponse.data.message
        );
        if (!updateResponse.data.status) {
          setLoading(false);
          return;
        }
      } else {
        // existing customer same store
        let edited = false;
        Object.keys(customer).map((key) => {
          if (customer[key] !== uneditedCustomerDetails[key]) {
            edited = true;
          }
        });
        if (edited) {
          // update customer details
          const payload = {
            newCustomer: false,
            customerId,
            addressId,
            customerDetails,
            store,
          };
          const updateResponse = await axios.post(
            "/api/operations/addOrUpdateCustomerOnShopify",
            payload
          );

          setNotification(
            updateResponse.data.status ? "success" : "failure",
            updateResponse.data.message
          );
          if (!updateResponse.data.status) {
            setLoading(false);
            handleDuplicateCustomer();
            return;
          }
        } else {
          // not edited so proceed with the customer id
          console.log(
            "customer details not edited and proceeding with customer id!"
          );
        }
      }
    }

    if (!finalCustomerId) {
      setNotification(
        "failure",
        "Customer ID not found for creating draft order!"
      );
      setLoading(false);
      return;
    }

    const paymentPending = !flags.paid;
    const skipFlagging = flags.skipFlagging;
    const partialPayment = flags.partialPayment;
    const payload = {
      store,
      customer: {
        id: finalCustomerId,
      },
      products: lineItems,
      skipFlagging,
      oldOrderName: orderName,
      paymentPending,
      partialPayment,
      discountCoupon,
      partialPaymentAmount,
      partialPaymentRemark,
      prepaidRemark,
      user,
      totalPrice,
    };

    const response = await axios.post("/api/operations/draftOrder", payload);
    setNotification(
      response.data.status ? "success" : "failure",
      response.data.message
    );
    if (response.data.status) {
      window.open(response.data.link, "_blank");
      resetComponent();
    }
    setLoading(false);
  };

  const fetchUser = () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));
    console.log(details);
    setUser(details.name);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="reusable-card-container">
      <AlertBox />
      <div className="reusable-card-header">
        <StoreDropdownButton
          setStore={setStore}
          store={store}
          showDeleted={false}
        />
        <div className="help-icon">
          <div className="help-icon" onClick={resetComponent}>
            <span>
              <i class="fas fa-redo fa-2x"></i>
            </span>
          </div>
          <div className="help-icon" onClick={handleDemo}>
            <span>
              <i class="far fa-question-circle fa-2x"></i>
            </span>
          </div>
        </div>
      </div>
      {store ? (
        store.deleted ? (
          <div className="draft-order-no-store-div">
            {`Store - ${store.storeName} has been deleted. Choose another store to draft an order!`}
          </div>
        ) : (
          <div>
            <div className="reusable-card-body">
              <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                <h5>CUSTOMER DETAILS</h5>
              </div>
              <CustomerDetails
                setCustomerId={setCustomerId}
                store={store}
                customer={customer}
                setCustomer={setCustomer}
                setUneditedCustomerDetails={setUneditedCustomerDetails}
                setCustomerType={setCustomerType}
                setOrderName={setOrderName}
                setAddressId={setAddressId}
                setExistingCustomerDifferentStore={
                  setExistingCustomerDifferentStore
                }
                setNotification={setNotification}
              />
              <hr
                style={{
                  backgroundColor: "#ddd",
                  width: "95%",
                  margin: "0.8rem auto",
                  opacity: "0.3",
                }}
              ></hr>
              <div style={{ textAlign: "center" }}>
                <h5>PRODUCT DETAILS</h5>
              </div>
              <ProductDetails
                store={store}
                lineItems={lineItems}
                setLineItems={setLineItems}
                setDiscountCode={setDiscountCode}
                discountCoupon={discountCoupon}
                setDiscountCoupon={setDiscountCoupon}
                setNotification={setNotification}
                flags={flags}
                setFlags={setFlags}
              />
              <hr
                style={{
                  backgroundColor: "#ddd",
                  width: "95%",
                  margin: "0.8rem auto",
                  opacity: "0.3",
                }}
              ></hr>
              <div className="draft-order-final-flags-container">
                <div>
                  <input
                    id="draft-order-skip-flagging-checkbox"
                    type="checkbox"
                    checked={flags.skipFlagging}
                    onClick={() => {
                      const skipFlagging = !flags.skipFlagging;
                      setFlags({ ...flags, skipFlagging });
                    }}
                  ></input>
                  <label htmlFor="draft-order-skip-flagging-checkbox">
                    Skip Calling/Operations Dashboard
                  </label>
                </div>
                <div>
                  <input
                    id="draft-order-paid-status"
                    type="checkbox"
                    checked={flags.paid}
                    onClick={() => {
                      const paid = !flags.paid;
                      setFlags({ ...flags, paid });
                    }}
                    disabled={flags.partialPayment}
                  ></input>
                  <label htmlFor="draft-order-paid-status">Prepaid Order</label>
                </div>
                <div>
                  <input
                    id="draft-order-partial-payment"
                    type="checkbox"
                    checked={flags.partialPayment}
                    onClick={() => {
                      if (flags.partialPayment) {
                        setPartialPaymentAmount(0);
                      }
                      const partialPayment = !flags.partialPayment;
                      setFlags({ ...flags, partialPayment });
                    }}
                    disabled={flags.paid}
                  ></input>
                  <label htmlFor="draft-order-partial-payment">
                    Partial Payment
                  </label>
                </div>
                <div>
                  <input
                    id="draft-order-discount-coupon"
                    type="checkbox"
                    checked={flags.discountCoupon}
                    onClick={() => {
                      if (flags.discountCoupon) {
                        setDiscountCode("");
                        setLineItems([]);
                        setDiscountCoupon(null);
                      }
                      const discountCoupon = !flags.discountCoupon;
                      setFlags({ ...flags, discountCoupon });
                    }}
                  ></input>
                  <label htmlFor="draft-order-discount-coupon">
                    Discount Coupon
                  </label>
                </div>
              </div>
              {flags.paid ? (
                <div
                  style={{
                    width: "80%",
                    margin: "0 auto 0.5rem auto",
                    display: "grid",
                    gridTemplateColumns: "20% 50% 30%",
                  }}
                >
                  Prepaid Remark{" "}
                  <input
                    type="text"
                    placeholder="Enter remark for why the the order is prepaid"
                    className="form-control form-control-sm"
                    onChange={handlePrepaidRemark}
                  ></input>
                </div>
              ) : null}
              {flags.partialPayment ? (
                <>
                  <div
                    style={{
                      width: "80%",
                      margin: "0 auto 0.5rem auto",
                      display: "grid",
                      gridTemplateColumns: "20% 50% 30%",
                    }}
                  >
                    Amount{" "}
                    <input
                      type="number"
                      placeholder="Enter partial payment amount"
                      className="form-control form-control-sm"
                      value={partialPaymentAmount}
                      onChange={handlePartialPaymentAmount}
                    ></input>
                  </div>
                  <div
                    style={{
                      width: "80%",
                      margin: "0 auto 0.5rem auto",
                      display: "grid",
                      gridTemplateColumns: "20% 50% 30%",
                    }}
                  >
                    Partial Payment Remark{" "}
                    <input
                      type="text"
                      placeholder="Enter remark for why the the order is partial payment"
                      className="form-control form-control-sm"
                      onChange={handlePartialPaymentRemark}
                    ></input>
                  </div>
                </>
              ) : null}
              {flags.discountCoupon ? (
                <div
                  style={{
                    width: "80%",
                    margin: "0 auto 0.5rem auto",
                    display: "grid",
                    gridTemplateColumns: "20% 50% 30%",
                  }}
                >
                  Coupon Code{" "}
                  <input
                    id="discount-code-input"
                    type="text"
                    placeholder="Enter discount coupon code"
                    className="form-control form-control-sm"
                    // style={{ width: "50%", marginLeft: "1rem" }}
                  ></input>
                  <button
                    style={{
                      marginLeft: "1rem",
                      padding: "0px 1%",
                      border: "2px solid rgb(41, 128, 185)",
                      borderRadius: "20px",
                      height: "2rem",
                      // width: "30%",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      const code = document.getElementById(
                        "discount-code-input"
                      ).value;
                      if (code) {
                        setDiscountCode(code);
                      }
                    }}
                  >
                    Apply Discount
                  </button>
                </div>
              ) : null}
            </div>

            <DraftOrderDetails
              lineItems={lineItems}
              setLineItems={setLineItems}
              setNotification={setNotification}
              discountCoupon={discountCoupon}
              setDiscountCoupon={setDiscountCoupon}
              discountCode={discountCode}
              setDiscountCode={setDiscountCode}
              store={store}
              partialPaymentAmount={partialPaymentAmount}
              flags={flags}
              setLoading={setLoading}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
            />
            <div className="final-submit-container">
              <button className="final-submit-button" onClick={handleSubmit}>
                DRAFT ORDER
              </button>
              {loading ? (
                <div className="final-submit-spinner-container">
                  <Spinner />
                </div>
              ) : null}
            </div>
          </div>
        )
      ) : (
        <div className="draft-order-no-store-div">
          {"Choose a store to draft an order!"}
        </div>
      )}
    </div>
  );
};

export default DraftOrder;

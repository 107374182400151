import React from "react";

const CustomTagBarAggregator = ({
  item,
  selectedItems,
  setSelectedItems,
  statusArray,
}) => {
  const handleClick = (item) => {
    let itemRemovedArray = selectedItems.filter(
      (selectedItem) => !(selectedItem == item)
    );
    setSelectedItems([...new Set(itemRemovedArray)]);
  };

  const statusWithDescription = item + " - " + statusArray[item];

  return (
    <div className="tag-item-super-status">
      <span>
        {statusWithDescription.length < 20 ? (
          statusWithDescription + "  "
        ) : (
          <span>
            {statusWithDescription.slice(0, 18)}
            <i class="fas fa-ellipsis-h tooltip-nav">
              <span className="tooltip-nav-text">{statusWithDescription}</span>
            </i>
            {"  "}
          </span>
        )}
      </span>
      <span className="tag-item-cross" onClick={() => handleClick(item)}>
        <i class="fas fa-times"></i>
      </span>
    </div>
  );
};

export default CustomTagBarAggregator;

import React, { useState } from "react";
import BatchItem from "./BatchItem";
import MappedItem from "./MappedItem";
import Axios from "axios";
import { confirmation } from "../../utils/confirmation";

const InventoryItem = ({ item, fetchInventory, user, inventory }) => {
  const [showMapping, setShowMapping] = useState(false);
  const [hideBody, setHideBody] = useState(item.mappedTo ? true : false);

  let total = 0;
  let batches = Object.keys(item).filter((key) => {
    if (key.startsWith("batch_")) return true;
  });

  let inventory_list = batches.filter((batch) => {
    total += item[batch].quantity;
    return item[batch].quantity !== 0;
  });

  const archiveAndMap = async (reMapTo, restore) => {
    if (!confirmation()) return;
    if (!confirmation()) return;

    const result = await Axios.post("/api/IMS/archiveAndMap", {
      inventory_id: item.id,
      remapped_id: reMapTo ? reMapTo : null,
      restore: restore ? restore : null,
    });
    if (result.data) alert(result.data.message || "Something went wrong!");
    fetchInventory();
    setShowMapping(false);
    if (reMapTo) setHideBody(true);
    if (restore) setHideBody(false);
  };

  return batches.length > 0 ? (
    <div
      className={`card inventoryCard ${
        inventory_list.length > 0 ? "" : "red-border"
      }`}
      style={{ marginBottom: "20px" }}
    >
      <div
        className={`card-header ${
          inventory_list.length > 0 ? "" : "outOfStock"
        } ${item.archived ? "inventory-archived" : ""}`}
        style={{ display: "flex" }}
      >
        <div
          style={{ padding: "5px", cursor: "pointer" }}
          onClick={() => setHideBody((current) => !current)}
        >
          <span
            style={{ fontWeight: "bold" }}
          >{`( Stock Available - ${total}  )`}</span>{" "}
          {item.name.toUpperCase()}
        </div>
        <div style={{ marginLeft: "auto" }}>
          {item.archived ? (
            <div>
              <button
                style={{
                  padding: "10px",
                  border: "none",
                  borderRadius: "20px",
                  background: "white",
                }}
                onClick={() => setShowMapping((existing) => !existing)}
              >
                Remap
              </button>
              <button
                style={{
                  padding: "10px",
                  border: "none",
                  borderRadius: "20px",
                  background: "white",
                  marginLeft: "10px",
                }}
                onClick={() => archiveAndMap(null, true)}
              >
                Resotre
              </button>
            </div>
          ) : (
            <button
              style={{
                padding: "10px",
                border: "none",
                borderRadius: "20px",
                background: "white",
              }}
              onClick={() => archiveAndMap()}
            >
              Archieve
            </button>
          )}
          {showMapping ? (
            <select
              className="inventory-listing"
              onChange={(e) => archiveAndMap(e.target.value)}
            >
              <option defaultValue>Choose a listing to map to</option>
              {inventory.map((listing) =>
                listing.id !== item.id ? (
                  <option value={listing.id}>{listing.name}</option>
                ) : (
                  ""
                )
              )}
            </select>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        {item.mappedTo ? (
          <div style={{ padding: "15px", background: "#ECF0F5" }}>
            Remapped to: {`${item.mappedTo.name} (${item.mappedTo.id}) `}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="card-body">
        {!hideBody ? (
          <div>
            Current Batch: {item.current_batch}
            <hr />
            {batches.map((batch, i) => (
              <BatchItem
                batch={batch}
                item={item}
                key={item.id + batch + i}
                fetchInventory={fetchInventory}
                user={user}
                id={item.id}
              />
            ))}
          </div>
        ) : (
          ""
        )}
        <div className="ShopifyNameBar ">
          {item.products.length > 0 ? (
            item.products.map((product, i) => (
              <MappedItem
                key={item.id + i}
                product={product}
                inventoryID={item.id}
                user={user}
                fetchInventory={fetchInventory}
                number={i}
                hideCross={item.archived ? true : false}
              />
            ))
          ) : (
            <p>No Mapping</p>
          )}
        </div>
        {item.archivedAssociations ? (
          <div>
            <div style={{ padding: "20px 10px", fontWeight: "bold" }}>
              Mapped from other archived inventory
            </div>
            <div className="ShopifyNameBar ">
              {item.archivedAssociations.length > 0 ? (
                item.archivedAssociations.map((product, i) => (
                  <MappedItem
                    key={item.id + i}
                    product={product}
                    inventoryID={item.id}
                    user={user}
                    fetchInventory={fetchInventory}
                    number={i}
                    hideCross={item.archived ? true : false}
                    mappedToItem={product.reference}
                  />
                ))
              ) : (
                <p>No Mapping</p>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    ""
  );
};

export default InventoryItem;

import React, { useEffect, useState } from "react";
import axios from "axios";
import CampaignItem from "./CampaignItem";

const SmsViewCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = async () => {
    const { data } = await axios.get("/api/smsMobi/viewCampaigns");
    setCampaigns(data.campaigns);
  };

  useEffect(() => {
    if (campaigns.length === 0) {
      fetchCampaigns();
    }
  }, []);
  return (
    <div className="sms-marketing-tabs">
      <h2 className="sms-populate-data-top-header">ALL CAMPAIGNS</h2>
      <div>
        {campaigns.map((campaign) => (
          <CampaignItem data={campaign} />
        ))}
      </div>
    </div>
  );
};

export default SmsViewCampaigns;

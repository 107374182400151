import React, { useState } from "react";

const DisplayLink = () => {
  const [linkVisibility, setLinkVisibility] = useState(false);
  const [link, setLink] = useState("");
  const [buttonText, setButtonText] = useState("Copy to Clipboard");

  const changeButtonText = () => {
    const TIME_TO_REVERT_BUTTON_TEXT = 2000;
    setButtonText("Copied to Clipboard");
    setTimeout(() => {
      setButtonText("Copy to Clipboard");
    }, TIME_TO_REVERT_BUTTON_TEXT);
  };

  const ShowLink = ({}) => {
    if (!linkVisibility) return <></>;
    return (
      <div
        className="card"
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          margin: "4% 1% 3% 1%",
          padding: "2% 1% 3% 1%",
          justifyContent: "center",
        }}
      >
        <div style={{ overflowWrap: "anywhere", textAlign: "center" }}>
          {link}
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            id="copy-button"
            style={{
              marginTop: "2%",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(link);
              changeButtonText();
            }}
          >
            <span className="ion" style={{ margin: 10 }}>
              {" "}
              {buttonText}{" "}
            </span>
          </button>
        </div>
      </div>
    );
  };
  return { linkVisibility, setLinkVisibility, ShowLink, setLink };
};

export default DisplayLink;

import React from "react";

const SearchForm = ({ store, searchOrder }) => {
  // const find = () => {
  //   var order = document.getElementById(form);
  //   if (order === null) alert("Order does not exist");
  //   else order.scrollIntoView();
  // };

  const handleChange = e => {
    if (e.key === "Enter") searchOrder(e.target.value, store);
  };

  return (
    <div className="active-pink-3 active-pink-4 searchForm">
      <input
        type="text"
        placeholder="Press enter to search"
        aria-label="Search"
        id="searchInput"
        onKeyPress={e => handleChange(e)}
        style={{
          height: "60px",
          width: "100%",
          padding: "20px",
          outline: "none",
          border: "5px solid #DC3545;"
        }}
      />
    </div>
  );
};

export default SearchForm;

import React, { useState, useEffect } from "react";
import NavBar from "../res/NavBar";
import Store from "../res/Store";
import StoreForm from "../res/StoreForm";
import { addStore } from "../functions/addStore";

import axios from "axios";
const Stores = () => {
  document.title = "Stores";
  const [stores, setStores] = useState([]);
  const [message, setMessage] = useState();
  const [activeStore, setActiveStore] = useState(null);

  const [customers, setCustomers] = useState([]);
  const getStoresList = async () => {
    let result = await axios.get("/api/stores?type=full", {});
    let res = await axios.get("/api/auth/users/customers");
    console.log("CUSTOMER", res.data);
    setCustomers(res.data.users);
    setStores(result.data);
  };

  const handleCLick = async (store) => {
    await addStore(getStoresList, "Shopify", setMessage);
  };

  useEffect(() => {
    getStoresList();
  }, []);
  // {user.active ? (
  //   <i
  //     class="far fa-play-circle"
  //     style={{ marginRight: "10px" }}
  //   ></i>
  // ) : (
  //   <i
  //     class="far fa-pause-circle"
  //     style={{ marginRight: "10px" }}
  //   ></i>
  // )}
  return (
    <div>
      <div className="card configurationsCard storeCard">
        <NavBar />
        <div className="container">
          {stores.map((store, index) => (
            <Store
              key={index}
              storeIndex={index}
              store={store}
              getStoresList={getStoresList}
              activeStore={activeStore}
              setActiveStore={setActiveStore}
            />
          ))}
          <div
            className="card container"
            style={{ padding: "10px", marginBottom: "10px" }}
          >
            <form className="needs-validation" id="StoreForm">
              <StoreForm text={"Store Name: "} type={"StoreName"} />
              <StoreForm text={"Store Email: "} type={"StoreEmail"} />
              <StoreForm text={"Shopify Name: "} type={"ShopifyName"} />
              <StoreForm text={"Channel ID: "} type={"ChannelID"} />
              <StoreForm
                text={"Shopify API Username: "}
                type={"ShopifyAPIUsername"}
              />
              <StoreForm
                text={"Shopify API Password: "}
                type={"ShopifyAPIPassword"}
              />
              <StoreForm
                text={"Shiprocket API Username:"}
                type={"ShiprocketAPIUsername"}
              />
              <StoreForm
                text={"Shiprocket API Password: "}
                type={"ShiprocketAPIPassword"}
              />
              <StoreForm
                text={"Address Threshold: "}
                type={"AddressThreshold"}
              />
              <StoreForm text={"SendGrid API Key: "} type={"SendGridAPIKey"} />
              <StoreForm
                text={"Google Analytics ViewId: "}
                type={"GoogleAnalyticsViewId"}
              />
              <StoreForm text={"SmsMobi SenderId: "} type={"SmsMobiSenderId"} />
            </form>

            <div className="form-group">
              <label htmlFor="customers">Who is this for?</label>
              <input
                type="text"
                list="customersList"
                className="form-control"
                id="customers"
                placeholder="customers Name"
                autoComplete="off"
              />
              <datalist id="customersList">
                {customers.map((customer, i) => (
                  <option value={customer.email} key={i}>
                    {customer.name}
                  </option>
                ))}
              </datalist>
            </div>
            <button
              type="button"
              className="btn "
              style={{ width: "100%", background: "#2ecc71", color: "white" }}
              onClick={() => handleCLick()}
            >
              Add Store
            </button>
            {message ? (
              <div>
                {message.message}
                <a
                  href={`https://${message.shopifyName}.myshopify.com/admin/settings/notifications`}
                  target="_blank"
                >
                  <button
                    type="button"
                    className="btn "
                    style={{
                      width: "100%",
                      background: "#2ecc71",
                      color: "white",
                    }}
                    // onClick={() => handleCLick()}
                  >
                    Link To WebHooks
                  </button>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stores;

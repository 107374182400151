import React, { useEffect, useState, Fragment, useContext } from "react";
import { UserConsumer } from "../context/user-context";
import { GlobalConsumer } from "../../../../Global-Context/global-context";
import Axios from "axios";
import useCsvColumnModal from "../../../../components/global/CsvColumnModal";

// REMEMBER TO ADD PERSPECTIVE OPTION ON ALL THE NEW DOWNLOAD BUTTONS THAT ARE ADDED

const NavBar = () => {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();

  const [user] = useState(userInfo);
  const [stores, setStores] = useState([]);
  const [CsvColumnModal, selectedColumns] = useCsvColumnModal();
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [csvModalFunction, setCsvModalFunction] = useState(null);

  const getStoresList = async () => {
    let result = await Axios.get("/api/stores");

    // SHINE X PRO STORE ONLY
    result.data = result.data.filter(
      (store) => store.shopifyName === "shinexpro"
    );

    setStores(result.data);
  };

  const handleCsvModal = (functionToBeCalled, params) => {
    setShowCsvModal(true);
    setCsvModalFunction({ function: functionToBeCalled, params });
  };

  useEffect(() => {
    getStoresList();
  }, []);

  return (
    <UserConsumer>
      {({
        store,
        downloadFullCSV,
        fetchInitialOrders,
        downloadInventoryCSV,
        searchedOrders,
        setMode,
        verboseMode,
      }) => (
        <Fragment>
          <CsvColumnModal
            show={showCsvModal}
            setShow={setShowCsvModal}
            functionToBeCalled={csvModalFunction}
            searchedOrders={searchedOrders}
          />
          <nav className="navbar  navbar-light trackBar">
            <div className="toolNav">
              <div className="dropdown tracking-dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle storeButton"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {store ? store.storeName : "Choose store"}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    className="dropdown-item"
                    onClick={() => fetchInitialOrders("All Stores")}
                  >
                    All Stores
                  </button>
                  {stores.map((store, i) => {
                    if (!store.deleted)
                      return (
                        <button
                          key={i}
                          className="dropdown-item"
                          onClick={() => fetchInitialOrders(store)}
                        >
                          {store.storeName}
                        </button>
                      );
                  })}
                </div>
              </div>
              <center
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  Simple Mode
                </div>
                <div>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      checked={verboseMode}
                      onChange={(e) => setMode(e.target.checked)}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      style={{ borderColor: "white !important" }}
                    />
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Verbose Mode
                </div>
              </center>
              {!store && !user.customer ? (
                <div style={{ display: "flex" }}>
                  <div
                    className="tool-button tooltip-nav"
                    onClick={() => downloadInventoryCSV()}
                  >
                    <i className="fas fa-arrow-circle-down"></i>
                    <span className="tooltip-nav-text">
                      Download Inventory CSV
                    </span>
                  </div>

                  <div
                    className="tool-button tooltip-nav"
                    id="downloadFullCSVAll"
                    onClick={() =>
                      handleCsvModal(downloadFullCSV, ["all", user])
                    }
                    // onClick={() => downloadFullCSV("all", user)}
                    style={{ marginLeft: "20px" }}
                  >
                    <i className="fas fa-arrow-circle-down"></i>
                    <span className="tooltip-nav-text">
                      Download Orders CSV
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              {store ? (
                user.role !== "support" ||
                (user.name && user.name === "Anusha Prakash") ? (
                  <div className="toolbar-nav">
                    {!user.customer ? (
                      <div
                        className="tool-button tooltip-nav"
                        onClick={() => downloadInventoryCSV()}
                      >
                        <i className="fas fa-arrow-circle-down"></i>
                        <span className="tooltip-nav-text">
                          Download Inventory CSV
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className="tool-button tooltip-nav"
                      onClick={() =>
                        handleCsvModal(downloadFullCSV, ["", user])
                      }
                      // onClick={() => downloadFullCSV("", user)}
                    >
                      <i className="fas fa-arrow-circle-down"></i>
                      <span className="tooltip-nav-text">
                        Download Orders CSV
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </nav>
        </Fragment>
      )}
    </UserConsumer>
  );
};

export default NavBar;

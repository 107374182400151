import Modal from "../warehouse/res/Modal";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import Cryptr from "cryptr";
import useAlert from "./Alert";

export default function useCsvColumnModal() {
  const [user, setUser] = useState(null);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [savedCsvTemplates, setSavedCsvTemplates] = useState([]);

  const CsvColumnModal = ({
    show,
    functionToBeCalled,
    setShow,
    setCsvColumns, // optional function reference to set outside state variable with the selected cols
    searchedOrders,
  }) => {
    const styles = {
      td: {
        border: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
      },
      table: {
        borderCollapse: "collapse",
        width: "100%",
      },
      button: {
        width: "50%",
        padding: "0.5rem 0.75rem",
        backgroundColor: "#dc3545",
        color: "#fff",
        marginTop: "1vh",
      },
      smallButton: {
        width: "40%",
        padding: "0.25rem 0.5rem",
        backgroundColor: "#dc3545",
        color: "#fff",
        margin: "0.5rem",
      },
      options: {
        display: "flex",
        padding: "0.5rem",
        justifyContent: "center",
      },
      optionsBox: {
        display: "flex",
        margin: "0.5rem",
      },
      optionLabel: {
        marginLeft: "1vw",
      },
      checkBox: {
        marginTop: "0.3rem",
      },
      customTemplateSelect: {
        display: "flex",
        textAlign: "left",
        marginBottom: "0.5rem",
      },
    };

    const fetchUser = async () => {
      let info = JSON.parse(localStorage.info);
      const cryptr = new Cryptr("myTotalySecretKey");
      let details = JSON.parse(cryptr.decrypt(info));
      const userObj = {
        user_id: details.user_id,
        name: details.name,
        role: details.role,
        email: details.email,
      };
      setUser(userObj);
      fetchColumns(userObj);
    };

    const fetchColumns = async (user) => {
      const query = {
        params: {
          user: user ? user : null,
        },
      };
      const { data } = await Axios.get(
        `/api/globalDashboard/fetchCsvTemplates`,
        query
      );
      console.log(data.templates);
      if (!data.status) {
        alert(data.message);
        return;
      }

      // set all the columns
      const allColumns = data.templates.find(
        (template) => template.name === "allColumns"
      ).csvFields;
      setColumns(allColumns);

      // set the saved custom templates for a particular user
      const savedTemplates = data.templates.filter(
        (template) => template.user === user.name
      );
      if (savedTemplates) {
        setSavedCsvTemplates(savedTemplates);
      }
    };

    const saveCsvTemplate = async () => {
      const elements = document.getElementsByClassName("column-modal-input");
      const labelsArray = [];
      for (const element of elements) {
        if (element.checked) {
          labelsArray.push(element.id.slice(4));
        }
      }

      if (labelsArray.length === 0) {
        alert("Please select columns for the template CSV!");
        return;
      }

      const templateName = prompt(`Enter a name for your custom csv template!`);
      if (!templateName) {
        alert(`You have to give a custom name to the csv template to save it!`);
        return;
      }

      // save the labels of the template for the user
      const body = {
        user: user,
        labels: labelsArray,
        templateName,
      };
      const { data } = await Axios.post(
        `/api/globalDashboard/saveCsvTemplate`,
        body
      );
      alert(data.message);
    };

    const setCustomCsvTemplate = (labels) => {
      const parsedCsvLabels = JSON.parse(JSON.stringify(labels));
      console.log(parsedCsvLabels);
      const elements = document.getElementsByClassName("column-modal-input");
      for (const element of elements) {
        if (parsedCsvLabels.includes(element.id.slice(4))) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      }
    };

    const handleAllSelection = (selectFlag) => {
      const elements = document.getElementsByClassName("column-modal-input");
      for (const element of elements) {
        if (selectFlag) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      }
      document.getElementById("customCsvTemplateSelect").value = 0;
    };

    const handleDownload = async () => {
      const elements = document.getElementsByClassName("column-modal-input");
      const labelsArray = [];
      for (const element of elements) {
        if (element.checked) {
          labelsArray.push(element.id.slice(4));
        }
      }

      if (labelsArray.length === 0) {
        alert("Please select columns for the CSV!");
        return;
      }

      // if labels contain gst column ask user gst %
      let gstRate;
      if (labelsArray.includes("GST %") || labelsArray.includes("GST Amount")) {
        gstRate = prompt(
          `Please enter the GST rate below. For 18% enter just "18".`
        );
        if (!gstRate) {
          alert("Please enter the GST rate or deselect GST columns!");
          return;
        }
      }

      setSelectedColumns(labelsArray);
      setShow(false);

      // return console.log(labelsArray);

      await functionToBeCalled.function(
        ...functionToBeCalled.params,
        labelsArray,
        gstRate
      );
    };

    const downloadSearchedOrders = async () => {
      if (searchedOrders.length <= 0)
        return alert("You haven't searched for any orders");

      const elements = document.getElementsByClassName("column-modal-input");
      const labelsArray = [];
      for (const element of elements) {
        if (element.checked) {
          labelsArray.push(element.id.slice(4));
        }
      }

      if (labelsArray.length === 0) {
        alert("Please select columns for the CSV!");
        return;
      }
      setSelectedColumns(labelsArray);
      setShow(false);

      const orderArr = searchedOrders.map((order) => ({
        name: order.name,
        store: order.store,
      }));

      const result = await Axios.post("/api/globalDashboard/getSpecificCSV", {
        orderArr,
        columns: labelsArray,
        user: {
          user_id: user.user_id,
          name: user.name,
          email: user.email,
        },
      });
      alert("Your file will be downloaded soon, we will also send you a mail");
      if (result.data.success) window.open(result.data.msg);
    };

    useEffect(() => {
      if (!user) {
        fetchUser();
      }
    });

    const CsvColumnModalContent = (
      <div id="csvColumnModalDiv">
        <div style={styles.customTemplateSelect}>
          <div>Custom CSV Templates</div>
          <div style={{ marginLeft: "auto" }}>
            <select
              id="customCsvTemplateSelect"
              className=" form-control"
              onChange={(e) => setCustomCsvTemplate(e.target.value)}
            >
              <option value={0}>Choose a saved csv template</option>
              {savedCsvTemplates.map((template) => {
                return (
                  <option value={template.csvLabels}>{template.name}</option>
                );
              })}
            </select>
          </div>
        </div>
        <table>
          <tr>
            <th style={styles.td}>COLUMN LABEL</th>
            <th style={styles.td}>DESCRIPTION</th>
            <th style={styles.td}>SELECT</th>
          </tr>
          <tbody>
            {columns.map((col) => {
              return (
                <tr>
                  <td style={styles.td}>
                    <label htmlFor={`row-${col.label}`}>{col.label}</label>
                  </td>
                  <td style={styles.td}>{col.description}</td>
                  <td style={styles.td}>
                    <input
                      id={`row-${col.label}`}
                      className="column-modal-input"
                      type="checkbox"
                    ></input>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <div style={styles.options}>
            <button
              onClick={() => saveCsvTemplate()}
              style={styles.smallButton}
            >
              Save CSV template
            </button>
            <button
              onClick={() => handleAllSelection(true)}
              style={styles.smallButton}
            >
              Select all columns
            </button>
            <button
              onClick={() => handleAllSelection(false)}
              style={styles.smallButton}
            >
              Deselect all columns
            </button>
          </div>
          <button style={styles.button} onClick={() => handleDownload()}>
            Download
          </button>
          <button
            style={styles.button}
            onClick={() => downloadSearchedOrders()}
          >
            Download Searched Orders
          </button>
        </div>
      </div>
    );

    return (
      <Modal
        show={show}
        setShow={setShow}
        title="Select the columns for CSV"
        content={CsvColumnModalContent}
      />
    );
  };
  return [CsvColumnModal, selectedColumns];
}

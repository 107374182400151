import React, { useEffect, useState, Fragment } from "react";
import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";

function ProductSubstitution({
  product,
  inventory,
  store,
  order,
  fetchOrders
}) {
  const [show, setShow] = useState(false);

  const substituteProduct = async (product, store) => {
    product = {
      product_id: product.product_id,
      variant_id: product.variant_id,
      name: product.name
    };
    let inventory = document.getElementById("inventory-selector").value;
    console.log(product, inventory, store, order);

    if (confirmation()) {
      if (confirmation()) {
        let result = await Axios.post("/api/operations/substituteProduct", {
          product,
          inventory,
          store,
          order
        });

        setShow(false);
        fetchOrders(store);
      }
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!show ? (
        <button
          type="button"
          className="btn btn-primary"
          style={{
            width: "100%",
            background: "#45aaf2",
            borderColor: "#45aaf2",
            marginBottom: "20px"
          }}
          onClick={() => setShow(true)}
        >
          Substitute Product
        </button>
      ) : (
        <Fragment>
          {/* <select
            style={{ width: "200px", margin: "20px auto" }}
            id="product-selector"
          >
            {products.map((product, index) => (
              <option
                key={index + product.variant_id}
                value={JSON.stringify({
                  product_id: product.product_id,
                  variant_id: product.variant_id
                })}
              >
                {product.name}
              </option>
            ))}
          </select> */}
          <select
            style={{ width: "200px", margin: "20px auto" }}
            id="inventory-selector"
          >
            {inventory.map(item =>
              item.products.map(product => (
                <option
                  key={item.id + product.variant_id}
                  value={JSON.stringify({
                    product_id: product.product_id,
                    variant_id: product.variant_id,
                    name: product.name
                  })}
                >
                  {product.name}
                </option>
              ))
            )}
          </select>
          <button
            type="button"
            className="btn btn-primary"
            style={{
              width: "100%",
              background: "#45aaf2",
              borderColor: "#45aaf2",
              marginBottom: "20px"
            }}
            onClick={() => substituteProduct(product, store)}
          >
            Submit
          </button>
          <i
            className="fas fa-times-circle"
            style={{
              color: "#fc5c65",
              cursor: "pointer",
              marginBottom: "20px"
            }}
            onClick={() => setShow(false)}
          ></i>
        </Fragment>
      )}
    </div>
  );
}

export default ProductSubstitution;

import React, { useState, useEffect } from "react";
import Axios from "axios";

export default function AggregatorButton({
  scheduleOrders,
  operation,
  fetchActivatedAggregators,
  store,
}) {
  const [aggregators, setAggregators] = useState([]);

  const fetchAggregators = async () => {
    let result;
    if (!fetchActivatedAggregators)
      result = await Axios.get("/api/control/fetchAggregators");
    else
      result = await Axios.get(
        `/api/control/fetchAggregators?fetchActivatedAggregators=yes&storeAnchor=${store.storeAnchor}`
      );
    setAggregators(result.data.aggregators);
    console.log(result.data.aggregators);
  };

  useEffect(() => {
    fetchAggregators();
  }, []);
  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle operations-store-button"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {operation ? operation : "Schedule"}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {aggregators.map((aggregator) => (
          <div
            key={aggregator.name}
            id={aggregator.name}
            className="dropdown-item operations-store-button-item"
            onClick={(e) => scheduleOrders(e.target.id)}
          >
            {aggregator.name}
          </div>
        ))}
      </div>
    </div>
  );
}

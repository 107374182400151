import React, { useState, useEffect, Fragment, useContext } from "react";
import Axios from "axios";
import { GlobalConsumer } from "../../../Global-Context/global-context";
import useDatePicker from "../../global/DatePicker";

function ProfitCalculator() {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();

  document.title = "Profit Calculator";
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [profit, setProfit] = useState("0");
  const [details, setDetails] = useState("");
  const [store, setStore] = useState({});
  const [user, setUser] = useState(userInfo);

  const updateProducts = async (value) => {
    setStore({ shopifyName: value });
    let productList = await Axios.get(
      `/api/globalDashboard/fetchProducts/${JSON.stringify({
        shopifyName: value,
      })}`
    );
    setProducts(productList.data);
  };
  const calculate = async (from, to) => {
    let data = {
      product: document.getElementById("product_selector").value,
      shopifyName: document.getElementById("store_selector").value,
      from,
      to,
      user_id: user.user_id,
    };
    let result = await Axios.post("/api/orders/calculateProfit", data);
    console.log(result.data);
    console.log(result.data.logs);
    console.log(result.data.details);
    setDetails(result.data.details);
    setProfit(result.data.TOTAL);
  };

  const fetchInventory = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    let productList = await Axios.get(
      `/api/globalDashboard/fetchProducts/${JSON.stringify(storeList.data[1])}`
    );
    setProducts(productList.data);
    setStores(storeList.data);
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  return store ? (
    <Fragment>
      <div style={{ display: "flex" }}>
        <select
          placeholder="Stores"
          id="store_selector"
          className="form-control form-control-sm"
          style={{ height: "50px", fontSize: "0.9rem", borderRadius: 0 }}
          onChange={(e) => updateProducts(e.target.value)}
        >
          <option value={0} selected disabled>
            Choose a store
          </option>
          {stores.map((store, index) => (
            <option value={store.shopifyName} key={index}>
              {store.storeName}
            </option>
          ))}
        </select>

        <select
          placeholder="Products"
          id="product_selector"
          className="form-control form-control-sm"
          style={{ height: "50px", fontSize: "0.9rem", borderRadius: 0 }}
        >
          <option value={0} selected disabled>
            Products
          </option>

          {products
            ? products.map((product, index) => (
                <option key={index} value={product[0]}>
                  {`${product[1]} (${product[0]})`}
                </option>
              ))
            : ""}
        </select>

        <div>
          <DatePicker />
        </div>

        <button
          className="btn btn-danger"
          style={{ borderRadius: 0 }}
          onClick={() => calculate(fromDate, toDate)}
        >
          Calculate
        </button>
      </div>
      {details ? (
        <div style={{ padding: "20px" }}>
          <p>
            <b>Revenue: </b>
            {details.totalRevenue}
          </p>
          <p>
            <b>Shipping Cost: </b> {details.totalShippingCost}
          </p>
          <p>
            <b>GST: </b> {details.totalGST}
          </p>
          {details.totalServiceFee ? (
            <p>
              <b>Service Fee: </b> {details.totalServiceFee}
            </p>
          ) : (
            ""
          )}
          <p>
            <b>Profit: </b>
            {profit}
          </p>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  ) : (
    ""
  );
}

export default ProfitCalculator;

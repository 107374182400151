import React, { Fragment, useState } from "react";
import SmsMarketingTabs from "../res/SmsMarketingTabs";
import SmsPopulateData from "../res/SmsPopulateData";
import SmsBlastTab from "../res/SmsBlastTab";
import SmsViewCampaigns from "../res/SmsViewCampaigns";
import "../res/SmsMobi.css";

const SmsMobi = () => {
  const [currentTab, setCurrentTab] = useState("mainTab");

  const styles = {
    backButton: {
      marginLeft: "2vw",
      marginTop: "2vh",
      color: "#2980B9",
    },
  };

  return (
    <Fragment>
      {currentTab !== "mainTab" ? (
        <div>
          <i
            className="fas fa-2x fa-arrow-circle-left back-button-icon"
            style={styles.backButton}
            onClick={() => setCurrentTab("mainTab")}
          ></i>
        </div>
      ) : null}
      <div className="sms-marketing-container">
        {currentTab === "mainTab" ? (
          <SmsMarketingTabs setCurrentTab={setCurrentTab} />
        ) : null}
        {currentTab === "campaignDataTab" ? (
          <SmsPopulateData setCurrentTab={setCurrentTab} />
        ) : null}
        {currentTab === "viewCampaignsTab" ? <SmsViewCampaigns /> : null}
        {currentTab === "blastSMSTab" ? <SmsBlastTab /> : null}
      </div>
    </Fragment>
  );
};

export default SmsMobi;

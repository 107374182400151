import React, { Fragment, useState } from "react";
import Axios from "axios";

const BatchItem = ({ batch, item, fetchInventory, user, id }) => {
  const [mode, setMode] = useState("read");

  const last_updated_by = item[batch].last_updated_by
    ? item[batch].last_updated_by
    : "";

  const changeMode = async () => {
    if (mode === "read") setMode("write");
    else if (mode === "write") {
      let quantity = document.getElementById(`quantity_${id}_${batch}`).value;
      let price = document.getElementById(`price_${id}_${batch}`).value;
      let gst = document.getElementById(`gst_${id}_${batch}`).value;
      console.log("Submitted", quantity, price, gst);
      await Axios.post("/api/IMS/updateBatchDetails", {
        id,
        batch,
        quantity,
        price,
        user: user.name,
        gst,
        time: new Date().toLocaleString()
      });
      fetchInventory();
      setMode("read");
    }
  };

  return (
    <Fragment>
      <div>
        <p>* {batch} *</p>
        <br />
        Quantity:{" "}
        <input
          className="form-control"
          style={{ fontWeight: "bold" }}
          id={`quantity_${id}_${batch}`}
          defaultValue={item[batch].quantity}
          disabled={mode === "write" ? false : true}
        />
        <br />
        Price:{" "}
        <input
          className="form-control"
          id={`price_${id}_${batch}`}
          defaultValue={item[batch].price}
          disabled={mode === "write" ? false : true}
        />
        <br />
        GST:{" "}
        <input
          className="form-control"
          id={`gst_${id}_${batch}`}
          defaultValue={item[batch].gst}
          disabled={mode === "write" ? false : true}
        />
        <br />
        {last_updated_by && !user.customer ? (
          <Fragment>
            <p>
              Last Updated By, {last_updated_by.name} on {last_updated_by.time}
            </p>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "50px" }}>
                <p>From:</p>
                <p>Quantity: {last_updated_by.from_quantity}</p>
                <p>GST: {last_updated_by.from_gst}</p>
                <p>Price: {last_updated_by.from_price}</p>
              </div>
              <div>
                <p>To:</p>
                <p>Quantity: {last_updated_by.to_quantity}</p>
                <p>GST: {last_updated_by.to_gst}</p>
                <p>Price: {last_updated_by.to_price}</p>
              </div>
            </div>
          </Fragment>
        ) : (
          ""
        )}
        {user.master || user.employee ? (
          <button
            type="button"
            id={`btn_${id}`}
            className="btn btn-success btn-product-add"
            onClick={() => changeMode()}
            style={{ margin: 0 }}
          >
            {mode === "write" ? "Submit" : "Edit"}
          </button>
        ) : (
          ""
        )}
      </div>
      <br />
    </Fragment>
  );
};

export default BatchItem;

import React from "react";

function CallingRoles({ calls, setCalls }) {
  const handleValueChange = e => {
    let array = calls;
    if (e.target.checked) {
      let call = JSON.parse(e.target.id);
      array = calls.map(type => {
        if (type.name === call.name) type.active = true;
        return type;
      });
    } else {
      let call = JSON.parse(e.target.id);
      array = calls.map(type => {
        if (type.name === call.name) type.active = false;
        return type;
      });
    }

    setCalls([...array]);
  };
  return (
    <div>
      {calls.map(type => (
        <div className="settingOption accessOption">
          <div className="settingTool">
            <div className="settingTitle">{type.name}</div>
          </div>
          <label className="custom-toggle access-toggle">
            <input
              id={JSON.stringify(type)}
              type="checkbox"
              onChange={e => handleValueChange(e)}
            />
            <span className="custom-toggle-slider rounded-circle access-toggle-slider" />
          </label>
        </div>
      ))}
    </div>
  );
}

export default CallingRoles;

import React, { useContext } from "react";
import { UserConsumer } from "../components/context/caller-context";

const SideBar = ({ users, setCurrentUser, setUserIndex }) => {
  const context = useContext(UserConsumer);

  let date = document.getElementById("datePicker");
  if (date) date.value = "";

  const handleClick = (user, i) => {
    context.filtered = {};
    let array = document.getElementsByClassName("sideItem");
    for (let x in array) {
      if (x < array.length)
        console.log(array[x].classList.remove("activeItem"));
    }

    document.getElementById(`${user.name}_li`).classList.add("activeItem");
    setCurrentUser(user);
    setUserIndex(i);
  };

  const handleAll = (user) => {
    context.filtered = {};
    let array = document.getElementsByClassName("sideItem");
    for (let x in array) {
      if (x < array.length)
        console.log(array[x].classList.remove("activeItem"));
    }

    document.getElementById(`All_li`).classList.add("activeItem");
    setCurrentUser(user);
  };

  const handlePendingCalls = (user) => {
    console.log("The pending calls will be handled here");
    let array = document.getElementsByClassName("sideItem");
    for (let x in array) {
      if (x < array.length)
        console.log(array[x].classList.remove("activeItem"));
    }

    document.getElementById(`PendingCalls_li`).classList.add("activeItem");
    setCurrentUser(user);
  };

  return (
    <div className="sideBar">
      <ul>
        <li
          id={`All_li`}
          className="sideItem"
          onClick={() => {
            handleAll("All");
          }}
        >
          All
        </li>
        <li
          id={`PendingCalls_li`}
          className="sideItem"
          onClick={() => {
            handlePendingCalls("PendingCalls");
          }}
        >
          Pending Calls
        </li>
        {users.map((user, i) => (
          <li
            className="sideItem"
            id={`${user.name}_li`}
            key={user.email}
            onClick={() => handleClick(user, i)}
          >
            {user.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;

import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

const PickupGenerationList = () => {
  const [products, setProducts] = useState([]);
  document.title = "Pickup Generation List";
  useEffect(() => {
    axios
      .post("/api/warehouse/pickupGeneratorList", {})
      .then(res => res.data)
      .then(data => {
        setProducts(data.productList);
      })
      .catch(error => console.error(error));
  }, []);
  return (
    <div className="pickuplist-table-wrapper">
      <table className="table  pickuplist-table">
        <thead className="pickuplist-table-head">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Product Name</th>
            <th scope="col">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {products ? (
            products.map((product, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{product.name}</td>
                <td>{product.quantity}</td>
              </tr>
            ))
          ) : (
            <td
              colSpan="3"
              className=" pickuplist-empty alert alert-primary"
              role="alert"
            >
              Such empty. Please reload
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default PickupGenerationList;

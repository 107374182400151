import React, { useState, useEffect } from "react";

const EditLead = ({
  customer,
  customerIndex,
  setEdit,
  setLeads,
  editProperty,
}) => {
  const [text, setText] = useState(customer[editProperty]);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const modifyLeadState = () => {
    setLeads((leads) => {
      leads[customerIndex][editProperty] = text;
      return leads;
    });
  };

  const handleSubmit = (e) => {
    if (e.keyCode == 13) {
      setEdit(false);
    }
  };

  useEffect(() => {
    document.getElementsByClassName("edit-lead-form-control")[0].focus();
  });

  useEffect(() => {
    modifyLeadState();
  }, [text]);

  return (
    <td className="edit-component">
      <input
        className="form-control edit-lead-form-control"
        onChange={handleChange}
        onKeyDown={handleSubmit}
        value={text}
      ></input>
    </td>
  );
};

export default EditLead;

import React, { useState, useRef, useEffect, createRef, Fragment } from "react";
import { Link } from "react-router-dom";

export default function SearchBar({ navigation }) {
  const [filtered, setFiltered] = useState(navigation);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [current, setCurrent] = useState(0);

  const searchRef = useRef(null);
  const globalSearchRef = useRef(null);

  // DYNAMIC REFERENCING OF DROPDOWN ELEMENTS
  const filterRef = useRef(filtered.map(() => createRef()));

  const filterSearch = (input) => {
    // FILTERS DROPDOWN BASED ON CURRENT INPUT
    setSearchInput(input);
    let searchResult = navigation.filter(
      (page) =>
        page.name.toLowerCase().includes(input.toLowerCase()) && !page.disabled
    );
    setFiltered(searchResult);
  };

  const keyControl = (code) => {
    // DROPDOWN NAVIGATION
    if (code === 40) {
      // NAVIGATE DOWNWARDS
      if (current < filtered.length - 1)
        setCurrent((current) => (current += 1));
    } else if (code === 38) {
      // NAVIGATE UPWARDS
      if (current > 0) setCurrent((current) => (current -= 1));
      filterRef["current"][current]["current"].scrollIntoView();
    } else if (code === 13) {
      // SELECT CURRENT OPTION
      if (filterRef["current"][current]["current"])
        filterRef["current"][current]["current"].click();
    }
  };

  useEffect(() => {
    // IMMEDIATE FOCUS ON INPUT FOR BETTER UX
    if (showSearch) searchRef.current.focus();
  }, [showSearch]);

  useEffect(() => {
    if (filterRef["current"])
      if (filterRef["current"][current])
        if (filterRef["current"][current]["current"])
          filterRef["current"][current]["current"].scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
  }, [filterRef["current"][current]]);

  function handleOuterClick(e) {
    if (
      e.target &&
      e.target.id !== "MasterBar-search-toggle" &&
      searchRef.current &&
      !searchRef.current.contains(e.target) &&
      e.target.id !== globalSearchRef.current.id
    ) {
      setShowSearch(false);
      document.removeEventListener("click", handleOuterClick, true);
    }
  }

  useEffect(() => {
    if (showSearch) {
      document.addEventListener("click", handleOuterClick, true);
    }
  }, [showSearch]);

  return (
    <div style={{ padding: "20px", color: "#95a5a6" }}>
      <div
        id="MasterBar-search-toggle"
        className="MasterBar-search-button"
        onClick={() => setShowSearch(!showSearch)}
      >
        {showSearch ? (
          <i id="MasterBar-search-toggle" className="far fa-times-circle"></i>
        ) : (
          <i className="fas fa-search"></i>
        )}
      </div>
      {showSearch ? (
        <Fragment>
          <div className="MasterBar-search-box" style={{ top: "125px" }}>
            <input
              autoComplete="off"
              id="masterbar-search-global-search"
              placeholder="(SINGLE / COMMA SEPARATED) Order / AWB / Phone Number / Email"
              ref={globalSearchRef}
              style={{ minWidth: "450px" }}
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  window.open(
                    `${
                      window.location.origin
                    }/operations/orderboard?input=${encodeURIComponent(
                      e.target.value
                    )}`
                  );
              }}
            />
          </div>
          <div className="MasterBar-search-box">
            <input
              autoComplete="off"
              id="masterbar-search-input"
              placeholder="Search A Page.."
              ref={searchRef}
              onChange={(e) => {
                filterSearch(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Escape") {
                  setShowSearch(false);
                  setSearchInput("");
                }
              }}
              onKeyDown={(e) => {
                if (searchInput) keyControl(e.keyCode);
              }}
            />

            {filtered.length > 0 && searchInput ? (
              <div className="MasterBar-search-dropdown">
                {filtered.map((page, i) => (
                  <Link
                    key={page.link}
                    ref={filterRef.current[i]}
                    to={page.link}
                    className={i === current ? "MasterBar-search-active" : ""}
                    onClick={() => {
                      setShowSearch(false);
                      setSearchInput("");
                    }}
                  >
                    {page.name}
                  </Link>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </Fragment>
      ) : (
        ""
      )}
    </div>
  );
}

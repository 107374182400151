import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { toggleProductDimensionsVisible } from "../functions/scheduleOrdersManually";
import { removeOrders } from "../functions/scheduleOrdersManually";
import ProductSubstitution from "../res/ProductSubstitution";
import Axios from "axios";

const Order = ({
  order,
  index,
  store,
  scheduleOrdersManually,
  fetchOrders,
  removeOrdersFromDashboard,
  inventory,
}) => {
  const [edit, setEdit] = useState(true);

  const handleClick = async (order, item) => {
    setEdit(!edit);
    if (!edit) {
      let quantity = document.getElementById(
        `item-quantity-${order.name}-${item.variant_id}`
      ).value;

      let data = {
        name: order.name,
        item: {
          product_id: parseFloat(item.product_id),
          variant_id: parseFloat(item.variant_id),
        },
        quantity: parseInt(quantity),
        store: order.store,
      };

      await Axios.post("/api/operations/editQuantity", data);
      fetchOrders(order.store);
    }
  };

  return (
    <Fragment>
      <tr>
        <td>{index + 1}</td>
        <td>
          <Link
            className="background-on-hover"
            style={{ color: "black", textDecoration: "none" }}
            to={`/operations/orderboard/${encodeURIComponent(
              store.storeAnchor
            )}/${encodeURIComponent(order.name)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {order.name}
          </Link>
          <br />
          <Link
            to={`/operations/address?orderID=${encodeURIComponent(
              order.name
            )}&store=${encodeURIComponent(store.storeAnchor)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Edit address
          </Link>
          <br />
          <span>{new Date(order.created_at).toDateString()}</span>
        </td>
        <td>
          <div className="form-group" style={{ width: "100%" }}>
            {order.line_items.map((item, index) => (
              <Fragment>
                <div className="row" key={index}>
                  <div
                    className="col-sm-10"
                    style={{ width: "100%", margin: "0 auto", padding: "0px" }}
                  >
                    <div
                      className=" shadow p-3  rounded"
                      style={{ marginBottom: "0px !important" }}
                    >
                      <div className="card-body">
                        {edit ? (
                          <p>
                            {item.title} x {item.quantity}
                          </p>
                        ) : (
                          <div>
                            <p>{item.title}</p>
                            <input
                              type="number"
                              id={`item-quantity-${order.name}-${item.variant_id}`}
                              defaultValue={item.quantity}
                              style={{ textAlign: "center" }}
                            />
                          </div>
                        )}

                        <p>P: {item.product_id}</p>
                        <p>V: {item.variant_id}</p>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{ width: "100%", margin: "10px auto" }}
                    onClick={() => handleClick(order, item)}
                  >
                    {edit ? "Edit Quantity" : "Submit"}
                  </button>
                </div>
                {order.outOfStock ? (
                  <ProductSubstitution
                    product={item}
                    store={order.store}
                    order={order.name}
                    fetchOrders={fetchOrders}
                    inventory={inventory}
                    key={
                      order.name +
                      order.line_items.length +
                      order.line_items[0].id +
                      item.variant_id
                    }
                  />
                ) : (
                  ""
                )}
              </Fragment>
            ))}
          </div>
        </td>
        <td>
          {order.productIdNotFound ? "Unknown Product," : ""}
          {order.multipleItems ? "Multiple Products," : ""}
          {order.multipleQuantity ? " Multiple Quantity" : ""}
        </td>
        <td>{order.remarks}</td>
        <td>
          {typeof order.shipmentFailureReason === "string" ? (
            <ul>
              <li>{order.shipmentFailureReason}</li>
            </ul>
          ) : typeof order.shipmentFailureReason === "object" &&
            order.shipmentFailureReason ? (
            <ul>
              {
                //   console.log(
                //   "this = ",
                //   order.shipmentFailureReason,
                //   typeof order.shipmentFailureReason
                // )
              }
              {Object.keys(order.shipmentFailureReason).map((oneKey, i) => {
                return <li key={i}>{order.shipmentFailureReason[oneKey]}</li>;
              })}
            </ul>
          ) : (
            ""
          )}
        </td>
        <td>
          <div
            className="form-group"
            id={`${order.name}_productDimensions`}
            name={`${order.name}_productDimensions`}
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-*-12">
                <input
                  className="form-control"
                  id={`${order.name}_length`}
                  name={`${order.name}_length`}
                  type="text"
                  placeholder="Length"
                  style={{ width: "80%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-*-12">
                <input
                  className="form-control"
                  id={`${order.name}_width`}
                  name={`${order.name}_width`}
                  type="text"
                  placeholder="Width"
                  style={{ width: "80%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-*-12">
                <input
                  className="form-control"
                  id={`${order.name}_height`}
                  name={`${order.name}_height`}
                  type="text"
                  placeholder="Height"
                  style={{ width: "80%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-*-12">
                <input
                  className="form-control"
                  id={`${order.name}_deadWeight`}
                  name={`${order.name}_deadWeight`}
                  type="text"
                  placeholder="Actual Weight"
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </div>
        </td>
        <td>
          <input
            type="checkbox"
            id={`${order.name}_orderName`}
            name={`${order.name}_orderName`}
            value={order.name}
            onChange={() =>
              toggleProductDimensionsVisible(
                order.name,
                order.line_items,
                order.line_items.length,
                store
              )
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            id={`${order.name}_orderNameDetails`}
            name={`${order.name}_orderName`}
            value={order.name}
            onChange={() => removeOrders(order.name)}
          />
        </td>
        {/* <td style={{ display: "flex", flexDirection: "column" }}>
          <button
            type="submit"
            className="btn btn-primary btn-md"
            style={{ backgroundColor: "#ff3838", marginBottom: "20px" }}
            onClick={e =>
              scheduleOrdersManually(e, fetchOrders, store, "shiprocket")
            }
          >
            SHIPROCKET
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-md"
            style={{ backgroundColor: "#ff3838", marginBottom: "20px" }}
            onClick={e =>
              scheduleOrdersManually(e, fetchOrders, store, "clickpost")
            }
          >
            CLICKPOST
          </button>

          <button
            type="submit"
            id="RemoveOrderButton"
            className="btn btn-primary btn-md"
            style={{ backgroundColor: "#ff3838" }}
            onClick={e => removeOrdersFromDashboard(e, fetchOrders, store)}
          >
            Remove
          </button>
        </td> */}
      </tr>
      <tr>
        <td colSpan="10">
          <input
            className="form-control"
            id={`${order.name}_remarks`}
            name={`${order.name}_remarks`}
            type="text"
            placeholder="Remarks"
            style={{ display: "none" }}
          />
        </td>
      </tr>
    </Fragment>
  );
};

export default Order;

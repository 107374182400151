import * as React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const CopyContent = ({ content }) => {
  const [showSuccess, setShowSuccess] = React.useState(false);

  const dismiss = () => {
    setTimeout(() => setShowSuccess(false), 1500);
  };

  const copy = () => {
    navigator.clipboard.writeText(content);
    setShowSuccess(true);
    dismiss();
  };

  return !showSuccess ? (
    <ContentCopyIcon
      sx={{ pl: 1, cursor: "pointer" }}
      color={"primary"}
      onClick={copy}
    />
  ) : (
    <CheckCircleOutlineIcon sx={{ pl: 1 }} color={"success"} />
  );
};

export default CopyContent;

import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useContext,
} from "react";
import Axios from "axios";
import TagBar from "../../../trackingboard/OrderTracker/components/Items/TagBar";
import Spinner from "../../home/res/Spinner";
import StatCard from "../res/StatCard";
import CircularProgressBar from "../../global/CircularProgressBar";
import useDatePicker from "../../global/DatePicker";
import useDatePerspective from "../../global/DatePerspective";
import useAlert from "../../global/Alert";
import { GlobalConsumer } from "../../../Global-Context/global-context";
import Select from "react-select";

function StatsModule_v2() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [variants, setVariants] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [displayInventory, setDisplayInventory] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [total, setTotal] = useState("");
  const [stats, setStats] = useState({});
  const [dateFilter, setDateFilter] = useState("");
  const [deliveryRate, setDeliveryRate] = useState("");
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2019");
  const [DatePerspectiveFilter, perspectiveOption] = useDatePerspective();
  const [allProducts, setAllProducts] = useState({});
  const [selectedMode, setSelectedMode] = useState("");
  const [AlertBox, setNotification] = useAlert();
  const [dropdownType, setDropdownType] = useState("products");
  const [experiments, setExperiments] = useState([]);
  const [options, setOptions] = useState([]);
  const [pmode, setPmode] = useState("P");

  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();

  const [user] = useState(userInfo);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "white" : "black",
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 250,
      display: "flex",
      height: "60px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const storeSelector = useRef(null);
  const extraOptions = [
    {
      name: "Manifested Abandoned Checkout",
      id: "abandonedCheck",
      description:
        "Only includes the Abandoned Orders that were converted to actual orders.",
    },
    {
      name: "Order Confirmation Calls",
      id: "orderConfirmation",
      description:
        "Only includes order confirmation orders that were confirmed and scheduled on the Calling Dashboard.",
    },
    {
      name: "NDR",
      id: "NDR",
      description:
        "Only includes all the NDR orders are present/ were resolved on the Calling Dashboard",
    },
    {
      name: "Experiment (Control)",
      id: "experimentNormal",
      description:
        "Only includes the normal orders associated with an experiment (If an experiment exists)",
    },
    {
      name: "Experiment (Experiment)",
      id: "experimentControl",
      description:
        "Only includes the orders that satisfies the experiment's conditions (If an experiment exists)",
    },
  ];

  const clearDetails = () => {
    storeSelector.current.value = "Choose Store";
    if (document.getElementById("productSearch"))
      document.getElementById("productSearch").disabled = false;
    if (document.getElementById("variantsSwitch"))
      document.getElementById("variantsSwitch").checked = false;
    setSelectedStores([]);
    setAllProducts({});
    setProducts([]);
    setFiltered([]);
    setDropdownType("products");
    global.selected = [];
    clearDate();
    setTotal("");
    setStats({});
    setDisplayInventory(false);
  };

  const submitDetails = async () => {
    if (selectedStores.length < 1)
      return setNotification("failure", "Please choose a store to proceed");

    let otherDetails = document.getElementById("otherDetails").checked;

    const experimentName =
      document.getElementById("experimentName") &&
      document.getElementById("experimentName").value;

    if (selectedMode.includes("experiment") && !experimentName)
      return setNotification("failure", "Please choose an experiment");

    let data = {
      perspectiveOption,
      selectedStores,
      products: global.selected || [],
      fromDate,
      toDate,
      selectedMode,
      otherDetails,
      allProducts,
      dropdownType,
      experimentName,
    };

    console.log(data);

    setLoading(true);
    setNotification(
      "success",
      "Please wait while we pull up some stats for you, this might take a while..."
    );
    let result = await Axios.post("/api/stats/statsV3", data);
    setLoading(false);
    console.log(result.data);
    setStats(result.data.Categories);
    setTotal(result.data.totalOrders);
    setDateFilter(result.data.date);
    setDeliveryRate(result.data.Actual);
  };

  const addInventoryProducts = (inventory) => {
    if (!inventory) return;
    const item = JSON.parse(inventory);

    if (!item.products) {
      {
        if (pmode === "P") {
          if (!global.selected.find((product) => product[0] === item[0])) {
            global.selected.push([...item]);
          }
        } else {
          const items = [];
          products.map((product) => {
            if (product.category) {
              const prods = product.products.map((p) => [
                dropdownType === "products"
                  ? p.product_id.toString()
                  : p.variant_id.toString(),
                p.name,
                p.store.shopifyName,
                p.store.storeName,
              ]);
              items.push(...prods);
            }
          });
          global.selected.push(...items);
        }

        if (document.getElementById("productSearch"))
          document.getElementById("productSearch").value = "";

        if (item[0] == "Include all products for all stores") {
          const allProductsObj = {};
          stores.map((store) => {
            allProductsObj[store.shopifyName] = true;
          });
          setAllProducts((current) => ({
            ...current,
            ...allProductsObj,
          }));
        } else {
          setAllProducts((current) => ({
            ...current,
            [item[2]]: true,
          }));
        }
      }
    } else {
      for (const {
        variant_id,
        product_id,
        store = {},
        name,
      } of item.products) {
        global.selected.push([
          dropdownType === "products"
            ? product_id.toString()
            : variant_id.toString(),
          name,
          store.shopifyName || store,
          store.storeName || store,
        ]);
      }
      if (document.getElementById("productSearch"))
        document.getElementById("productSearch").value = "";

      setFiltered((current) =>
        current.filter((product) => product.name !== item.name)
      );
      setOptions((current) =>
        current.filter((product) => product.label !== item.name)
      );
    }
  };

  const addStoreAndUpdateProducts = async (value) => {
    setNotification(
      "success",
      "Please wait while we fetch the products for this store..."
    );

    if (value === "allStoresOnly") {
      setSelectedStores(stores);
      let result = await Axios.get(`/api/IMS/getInventory`);
      setProducts((currentList) => [...currentList, ...result.data.inventory]);
      setFiltered((currentList) => [...currentList, ...result.data.inventory]);
      setDisplayInventory(true);

      const cat = [];

      let temp = result.data.inventory.map((item) => {
        if (item.category && !cat.find((i) => i.label === item.category)) {
          cat.push({
            label: item.category,
            value: JSON.stringify(item.category),
          });
        }

        const obj = {
          label: item.name,
          value: JSON.stringify(item),
        };

        return obj;
      });

      setCategory(cat);

      temp = [
        ...stores.map((store) => ({
          label: `Include all products for ${store.storeName}`,
          value: JSON.stringify([
            `Include all products for ${store.storeName}`,
            `Include all products for ${store.storeName}`,
            store.shopifyName,
            store.storeAnchor,
          ]),
        })),
        ...temp,
      ];

      setOptions(temp);

      return;
    }
    setDisplayInventory(false);
    if (value === "allStores") {
      setSelectedStores(stores);
      multiCheck(
        null,
        `Include all products for all stores,Include all products for all stores,All selected,All selected`
      );
      if (document.getElementById("productSearch"))
        document.getElementById("productSearch").disabled = true;
      return;
    }
    let store = JSON.parse(value);
    storeSelector.current.value = "Choose Store";

    setSelectedStores((current) => {
      if (
        !current.find(
          (currentStore) => currentStore.storeAnchor === store.storeAnchor
        )
      )
        return [...current, store];
      else return current;
    });

    if (
      selectedStores.find(
        (currentStore) => currentStore.storeAnchor === store.storeAnchor
      )
    )
      return;

    let productList = await Axios.get(
      `/api/globalDashboard/fetchProductsV2/${encodeURIComponent(
        JSON.stringify(store)
      )}`
    );

    const type = dropdownType;

    // in case, bug is caused due to this change, blame me(prasad) adith
    setProducts((currentList) => [...currentList, ...productList.data[type]]);

    setFiltered((currentList) => [...currentList, ...productList.data[type]]);
  };

  const removeStoreAndUpdateProducts = async (store) => {
    storeSelector.current.value = "Choose Store";
    setSelectedStores((current) =>
      current.filter(
        (currentStore) => currentStore.shopifyName !== store.shopifyName
      )
    );

    const filteredProducts = products.filter((product) => {
      return product[2] !== store.shopifyName;
    });
    // in case, bug is caused due to this change, blame me(prasad) adith
    setProducts(filteredProducts);
    setFiltered(filteredProducts);
  };

  const removeStoreOnProductRemoval = (item) => {
    let moreProductsExist = global.selected.find(
      (product) => product[2] == item[2]
    );
    if (!moreProductsExist) {
      // setSelectedStores((current) =>
      //   current.filter((currentStore) => currentStore.shopifyName !== item[2])
      // );
      // const filteredProducts = filtered.filter((product) => {
      //   return product[2] !== item[2];
      // });
      // setFiltered(filteredProducts);
      // setProducts(filteredProducts);
    }
  };

  const multiCheck = (e, value) => {
    if (!isNaN(parseFloat(value))) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";

      if (value2send) {
        global.selected.push([...value2send.split("|")]);

        if (document.getElementById("productSearch"))
          document.getElementById("productSearch").value = "";

        let productList = global.selected.map((item) => item[0]);

        let newArray = products.filter((product) => {
          return !productList.includes(product[0].toString());
        });

        setFiltered(newArray);
      } else {
        setNotification("failure", "Choose a product!");
      }
    } else {
      if (value.split(",").length < 4) return;
      if (
        !global.selected.find((product) => product[0] === value.split(",")[0])
      ) {
        global.selected.push([...value.split(",")]);
      }
      if (document.getElementById("productSearch"))
        document.getElementById("productSearch").value = "";

      let productList = global.selected.map((item) => item[0]);

      let newArray = products.filter((product) => {
        return !productList.includes(product[0].toString());
      });

      setFiltered(newArray);

      if (value.split(",")[0] == "Include all products for all stores") {
        const allProductsObj = {};
        stores.map((store) => {
          allProductsObj[store.shopifyName] = true;
        });
        setAllProducts((current) => ({
          ...current,
          ...allProductsObj,
        }));
      } else {
        setAllProducts((current) => ({
          ...current,
          [value.split(",")[2]]: true,
        }));
      }
    }
  };

  const downloadCSV = async () => {
    // if (selectedStores.length === stores.length)
    //   return alert(
    //     "Generating a report for all stores and all products seems to have lead to crashes in the past, please try submitting this query instead. We will notify you when a fix is ready"
    //   );
    let otherDetails = document.getElementById("otherDetails").checked;
    const experimentName =
      document.getElementById("experimentName") &&
      document.getElementById("experimentName").value;

    if (selectedMode.includes("experiment") && !experimentName)
      return setNotification("failure", "Please choose an experiment");

    if (selectedStores.length < 1)
      return setNotification("failure", "Please select at least 1 store");

    let data = {
      perspectiveOption,
      selectedStores,
      products: global.selected || [],
      fromDate,
      selectedMode,
      otherDetails,
      toDate,
      allProducts,
      dropdownType,
      experimentName,
    };
    setNotification(
      "success",
      "Please wait while we generate a CSV for you, this might take a while..."
    );
    let result = await Axios.post("/api/stats/statsCSV", data);
    if (result.data.msg) {
      setNotification("success", result.data.msg);
      return;
    }
    setNotification("success", "Please find the CSV below...");

    let blob = new Blob([result.data.statsCSV], { type: "text/csv" });
    let a = document.createElement("a");
    a.download = "stats.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download stats CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );
    document.body.appendChild(a);
    a.click();
  };

  const downloadOrders = async () => {
    // if (selectedStores.length === stores.length)
    //   return alert(
    //     "Generating a report for all stores and all products seems to have lead to crashes in the past, please try submitting this query instead. We will notify you when a fix is ready"
    //   );
    let otherDetails = document.getElementById("otherDetails").checked;
    const experimentName =
      document.getElementById("experimentName") &&
      document.getElementById("experimentName").value;

    if (selectedMode.includes("experiment") && !experimentName)
      return setNotification("failure", "Please choose an experiment");

    if (selectedStores.length < 1)
      return setNotification("failure", "Please select at least 1 store");

    let data = {
      perspectiveOption,
      selectedStores,
      products: global.selected || [],
      fromDate,
      selectedMode,
      otherDetails,
      toDate,
      allProducts,
      dropdownType,
      experimentName,
    };
    setNotification(
      "success",
      "Please wait while we generate a CSV for you, this might take a while..."
    );
    let result = await Axios.post("/api/stats/statsOrders", data);
    if (result.data.msg) {
      setNotification("success", result.data.msg);
      return;
    }
    setNotification("success", "Please find the CSV below...");

    let blob = new Blob([result.data.statsCSV], { type: "text/csv" });
    let a = document.createElement("a");
    a.download = "stats.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download stats CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );
    document.body.appendChild(a);
    a.click();
  };

  const downloadProducts = async () => {
    // if (selectedStores.length === stores.length)
    //   return alert(
    //     "Generating a report for all stores and all products seems to have lead to crashes in the past, please try submitting this query instead. We will notify you when a fix is ready"
    //   );
    let otherDetails = document.getElementById("otherDetails").checked;
    const experimentName =
      document.getElementById("experimentName") &&
      document.getElementById("experimentName").value;

    if (selectedMode.includes("experiment") && !experimentName)
      return setNotification("failure", "Please choose an experiment");

    if (selectedStores.length < 1)
      return setNotification("failure", "Please select at least 1 store");

    let data = {
      perspectiveOption,
      selectedStores,
      products: global.selected || [],
      fromDate,
      selectedMode,
      otherDetails,
      toDate,
      allProducts,
      dropdownType,
      user: {
        email: user.email,
      },
      experimentName,
    };
    setNotification(
      "success",
      "Please wait while we generate a CSV for you, this might take a while... You will also receive an email"
    );
    try {
      let result = await Axios.post("/api/stats/productStatsV2", data);
      if (result.data.msg) {
        setNotification("success", result.data.msg);
        return;
      }
      let blob = new Blob([result.data.statsCSV], { type: "text/csv" });
      let a = document.createElement("a");
      a.download = "stats.csv";
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("style", "display:none;");
      a.textContent = "Download stats CSV";

      a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
        ":"
      );
      document.body.appendChild(a);
      a.click();
      setNotification("success", "Please find the CSV below...");
    } catch (error) {
      console.log(error);
      setNotification("failure", error.message);
    }
  };

  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };

  const getExperiments = async () => {
    const exp = await Axios.get("/api/experiment/fetch");
    setExperiments(exp.data.experiments || []);
  };

  useEffect(() => {
    global.selected = [];
    getStores();
  }, []);

  useEffect(() => {
    if (selectedMode.includes("experiment")) getExperiments();
  }, [selectedMode]);

  return (
    <div className="stats-content">
      <AlertBox />
      <br />
      <center style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <div className="stats-csv" onClick={() => downloadCSV()}>
            <i className="fas fa-arrow-circle-down"></i>
          </div>
          <center>Download CSV</center>
        </div>
        <div>
          <div className="stats-csv" onClick={() => downloadOrders()}>
            <i className="fas fa-arrow-circle-down"></i>
          </div>
          <center>Download Considered Orders</center>
        </div>
        <div>
          <div className="stats-csv" onClick={() => downloadProducts()}>
            <i className="fas fa-arrow-circle-down"></i>
          </div>
          <center>Download Product CSV</center>
        </div>
      </center>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        {selectedStores
          ? selectedStores.map((item) => (
              <div
                className="itemTag"
                id={`tag_${item.shopifyName}`}
                style={{ borderColor: "#2980b9" }}
              >
                {item.storeName}
                <div
                  className="cross"
                  onClick={() => removeStoreAndUpdateProducts(item)}
                  style={{ background: "#2980b9" }}
                >
                  x
                </div>{" "}
              </div>
            ))
          : ""}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        {global.selected
          ? global.selected.map((item, i) => (
              <TagBar
                item={item}
                key={i}
                permuteOrders={getStores}
                setFiltered={setFiltered}
                filtered={filtered}
                callOnDelete={removeStoreOnProductRemoval}
                setAllProducts={setAllProducts}
              />
            ))
          : ""}
      </div>
      <div className="stats-filter">
        <DatePerspectiveFilter />
        <select
          disabled={displayInventory}
          placeholder="Stores"
          id="store_selector"
          className="form-control form-control-sm"
          ref={storeSelector}
          style={{
            height: "100%",
            fontSize: "0.9rem",
            borderRadius: 0,
          }}
          onInput={(e) => addStoreAndUpdateProducts(e.target.value)}
        >
          <option selected defaultValue>
            Choose Store
          </option>
          <option value="allStores"> All stores and all products</option>
          <option value="allStoresOnly"> All stores (Specific Products)</option>
          {stores.map((store, index) => (
            <option value={JSON.stringify(store)} key={index}>
              {store.storeName}
            </option>
          ))}
        </select>

        {/* PRODUCTS */}
        {!displayInventory &&
        selectedStores.length > 0 &&
        filtered.length > 0 ? (
          <Fragment>
            <input
              type="text"
              id={"productSearch"}
              list="productList"
              autoComplete="off"
              placeholder="Products"
              className="form-control form-control-sm"
              style={{
                height: "100%",
                fontSize: "0.9rem",
                borderRadius: 0,
                width: "100%",
              }}
              onChange={(e) => {
                multiCheck(e, e.target.value);
              }}
            />
            <datalist id="productList">
              {selectedStores.length > 0
                ? selectedStores.map((store) => (
                    <option
                      value={[
                        `Include all products for ${store.storeName}`,
                        `Include all products for ${store.storeName}`,
                        store.shopifyName,
                        store.storeAnchor,
                      ]}
                    >
                      Include all products for {store.storeName}
                    </option>
                  ))
                : ""}
              {filtered.map((option, index) => (
                <option
                  key={index}
                  data-value={option.join("|")}
                  value={option[0]}
                >
                  {`(${option[2]}) - ${option[1]}`}
                </option>
              ))}
            </datalist>
          </Fragment>
        ) : selectedStores.length > 0 && filtered.length > 0 ? (
          <div style={{ display: "flex" }}>
            <div className="product-box-check">
              <div
                style={{ background: pmode === "P" ? "#2980b9" : "" }}
                onClick={() => setPmode("P")}
              >
                P
              </div>
              <div
                style={{ background: pmode === "C" ? "#2980b9" : "" }}
                onClick={() => setPmode("C")}
              >
                C
              </div>
            </div>
            {pmode === "P" ? (
              <Select
                label="Choose a product"
                options={options}
                placeholder="Choose a product"
                onChange={(e) => addInventoryProducts(e.value)}
                styles={customStyles}
              />
            ) : (
              <Select
                label="Choose a category"
                options={category}
                placeholder="Choose a category"
                onChange={(e) => addInventoryProducts(e.value)}
                styles={customStyles}
              />
            )}
          </div>
        ) : (
          ""
        )}

        <DatePicker />

        <button
          className="btn btn-danger"
          style={{ borderRadius: 0 }}
          onClick={() => submitDetails()}
        >
          Submit
        </button>
        <button
          className="btn btn-danger"
          style={{ borderRadius: 0 }}
          onClick={() => clearDetails()}
        >
          Clear
        </button>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 0 }}>
        {extraOptions.map((option) => (
          <div className="stats-mode" data-tooltip={option.description}>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={option.id}
                onChange={(e) => {
                  if (e.target.checked) setSelectedMode(e.target.id);
                  else setSelectedMode("");
                }}
                disabled={[option.id, ""].includes(selectedMode) ? false : true}
              />
              <label className="form-check-label" for="abandonedCheck">
                {option.name} Mode
              </label>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginBottom: "50px", display: "flex" }}>
        <div
          className="stats-mode"
          data-tooltip={
            "Includes rarely occurring status codes (Ex: e-Payment Failed)"
          }
        >
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="otherDetails"
            />
            <label className="form-check-label" for="otherDetails">
              Include Other Statues
            </label>
          </div>
        </div>

        <div
          className="stats-mode"
          data-tooltip={"Switches product dropdown to include variants"}
        >
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="variantsSwitch"
              disabled={selectedStores.length <= 0 ? false : true}
              onChange={(e) => {
                if (e.target.checked) setDropdownType("variants");
                else setDropdownType("products");
              }}
            />
            <label className="form-check-label" for="variantsSwitch">
              Switch To Variants Dropdown
            </label>
          </div>
        </div>

        {selectedMode.includes("experiment") && experiments.length > 0 ? (
          <div className="stats-mode">
            <select
              id={"experimentName"}
              style={{ height: "100%" }}
              className="form-control form-control-sm"
            >
              <option value="">Choose an experiment</option>
              {experiments.map((experiment) => (
                <option>{experiment.name}</option>
              ))}
            </select>
          </div>
        ) : (
          ""
        )}
      </div>

      {!loading ? (
        <Fragment>
          <div className="progressbar-container">
            {stats
              ? Object.keys(stats).map((category) => (
                  <div className="card-block">
                    <CircularProgressBar
                      percentage={stats[category].percentage}
                      sqSize={115}
                      color={"#E74C3C"}
                    />
                    <p
                      className="card-title"
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      {stats[category].name}
                    </p>
                  </div>
                ))
              : ""}
          </div>
          {total && dateFilter ? (
            <Fragment>
              <div
                className="stats-total"
                style={{
                  margin: "0px",
                  background: "#e74c3c",
                  color: "white",
                }}
              >
                <center>
                  <strong>{total}</strong> Orders between{" "}
                  <strong>
                    {new Date(dateFilter.fromDate).toDateString()}
                  </strong>{" "}
                  and{" "}
                  <strong>{new Date(dateFilter.toDate).toDateString()}</strong>
                </center>
              </div>
              <div className="stats-total">
                <center>{deliveryRate}%</center>
              </div>
            </Fragment>
          ) : (
            ""
          )}

          <div className="stats-body stats-grid-container">
            {stats
              ? Object.keys(stats).map((category) => (
                  <StatCard
                    category={stats[category]}
                    selectedStores={selectedStores}
                    dateFilter={dateFilter}
                    perspectiveOption={perspectiveOption}
                    allProducts={allProducts}
                    selectedMode={selectedMode}
                    dropdownType={dropdownType}
                  />
                ))
              : ""}
          </div>
        </Fragment>
      ) : (
        <div style={{ margin: "20px" }}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default StatsModule_v2;

import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Cryptr from "cryptr";
import CourierList from "../res/CourierList";
import Spinner from "../../global/Spinner";

const ReassignCourier = () => {
  const [orderName, setOrderName] = useState(null);
  const [store, setStore] = useState("");
  const [stores, setStores] = useState([]);
  const [user, setUser] = useState(null);
  const [couriers, setCouriers] = useState([]);
  const [previouslyAssignedCourierId, setPreviouslyAssignedCourierId] =
    useState(null);
  const [showCourierOptions, setShowCourierOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  document.title = "Reassign Courier";

  const getStoresList = async () => {
    let result = await axios.get("/api/stores");

    // SHINE X PRO STORE ONLY
    result.data = result.data.filter(
      (store) => store.shopifyName === "shinexpro"
    );

    setStores(result.data);
  };

  const getUserDetails = () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));
    console.log(details);
    setUser({
      user_id: details.user_id,
      name: details.name,
    });
  };

  const handleOptions = async () => {
    setShowCourierOptions(true);
    setLoading(true);
    const orderName = document.getElementById("re-assign-courier-input").value;
    if (!orderName) {
      alert("Please enter an order ID to see options");
      return;
    }

    let body = {
      orderName: orderName,
      store: store,
    };
    let courierOptions = await axios.post(
      `/api/operations/reassignCourierOptions`,
      body
    );

    if (!courierOptions.data.status) {
      setOrderName(null);
      setShowCourierOptions(false);
      setCouriers([]);
      alert(courierOptions.data.msg);
      return;
    }
    setOrderName(orderName);
    setCouriers(courierOptions.data.couriers);
    setPreviouslyAssignedCourierId(courierOptions.data.previousCourierId);
    setShowCourierOptions(true);
    setLoading(false);
  };

  const handleSubmit = async (courierToBeReassigned) => {
    if (!orderName) {
      alert("Please enter an order ID to re-assign");
      return;
    }
    if (courierToBeReassigned) {
      const confirmation = prompt(
        `Enter 'yes' to reassign order: ${orderName} to courier: ${courierToBeReassigned.courier_name.toUpperCase()} with shipping rate: \u20B9${
          courierToBeReassigned.rate
        }.`
      );
      if (!confirmation) {
        alert("Re-assign FAILED!");
        return;
      }
      if (confirmation.toLowerCase() !== "yes") {
        alert(`Enter 'yes' to re-assign!`);
        return;
      }
    }
    let body = {
      orderName,
      store,
      courier_id: courierToBeReassigned.courier_company_id,
      user,
    };
    let reassignMessage = await axios.post(
      `/api/operations/reassignCourier`,
      body
    );
    if (!reassignMessage.data.status) {
      alert(reassignMessage.data.msg);
      return;
    }

    // generate pickup for shipment
    let generatePickupMessage = await axios.post(
      `/api/operations/generatePickupForShipment`,
      {
        orderName,
        store,
      }
    );

    const alertMessage = `Reassign: ${reassignMessage.data.msg}\n\nGenerate pick up: ${generatePickupMessage.data.msg}`;
    alert(alertMessage);
  };

  useEffect(() => {
    getStoresList();
    getUserDetails();
  }, []);

  return (
    <Fragment>
      <div className="card re-assign-courier-container-div">
        <div className="card-header re-assign-courier-container-header">
          <h4 className="">Re-Assign Courier</h4>
          <div className="dropdown chooseStoreNormal">
            <button
              className="btn  dropdown-toggle storeButton"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {store.storeName ? store.storeName : "Choose store"}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {stores.map((storeItem, index) => (
                <button
                  key={index}
                  className="dropdown-item"
                  onClick={() => {
                    setStore(storeItem);
                  }}
                >
                  {storeItem.storeName}
                </button>
              ))}
            </div>
          </div>
        </div>
        {!store ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <h3>Choose a store</h3>
          </div>
        ) : (
          <div className="card-body re-assign-courier-container-body">
            <div className="form-group row re-assign-courier-controls">
              <label
                htmlFor="re-assign-courier-input"
                className="col-form-label"
              >
                ORDER ID :
              </label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Shopify OrderID"
                  id="re-assign-courier-input"
                />
              </div>
              <div className="re-assign-courier-container-buttons-div">
                <button className="btn btn-primary" onClick={handleOptions}>
                  Courier Options
                </button>
              </div>
            </div>
            <div>
              {showCourierOptions ? (
                loading ? (
                  <Spinner />
                ) : (
                  <CourierList
                    couriers={couriers}
                    previousCourier={previouslyAssignedCourierId}
                    handleSubmit={handleSubmit}
                  />
                )
              ) : null}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ReassignCourier;

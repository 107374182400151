import React, { Fragment } from "react";
import { productDimension } from "../functions/productDimension";

const VariantForm = ({ formId, variant, store, fetchProducts }) => {
  return (
    <Fragment>
      <form name={formId} id={formId}>
        <div className="container">
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <div id={`${formId}_msg`} />
            <div className="row">
              <div className="col-xs-1 col-sm-1 col-md-1" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_length`}>Length:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_length`}
                    name="length"
                    defaultValue={variant.length ? variant.length : ""}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_width`}>Width:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_width`}
                    name="width"
                    defaultValue={variant.width ? variant.width : ""}
                  />
                </div>
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1" />
            </div>
            <div className="row">
              <div className="col-xs-1 col-sm-1 col-md-1" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_height`}>Height:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_height`}
                    name="height"
                    defaultValue={variant.height ? variant.height : ""}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_actualWeight`}>A W:</label>&nbsp;
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_actualWeight`}
                    name="actualWeight"
                    defaultValue={
                      variant.actualWeight ? variant.actualWeight : ""
                    }
                  />
                </div>
              </div>
              <div className="col-xs-1 col-sm-1" />
            </div>
            <div className="row">
              <div className="col-xs-1 col-sm-1 col-md-1" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_vw`}>Volu W:</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_vw`}
                    name="vw"
                    disabled
                    value={
                      variant.length && variant.width && variant.height
                        ? (
                            (variant.length * variant.width * variant.height) /
                            5000
                          ).toFixed(2)
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2" />
              <div className="col-xs-4 col-sm-4 col-md-4">
                <div className="input-group input-group-sm mb-3">
                  <label htmlFor={`${formId}_pw`}>P W:&nbsp;</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    id={`${formId}_pw`}
                    name="pw"
                    disabled
                    value={
                      variant.length &&
                      variant.width &&
                      variant.height &&
                      (
                        (variant.length * variant.width * variant.height) /
                        5000
                      ).toFixed(3) > variant.actualWeight
                        ? (
                            (variant.length * variant.width * variant.height) /
                            5000
                          ).toFixed(2)
                        : variant.actualWeight
                    }
                  />
                </div>
              </div>
              <div className="col-xs-1 col-sm-1" />
            </div>
            <div className="row">
              <div className="col-xs-1 col-sm-1" />
              <div className="col-xs-10 col-sm-10 col-md-10">
                <div className="input-group input-group-md mb-3">
                  <button
                    type="submit"
                    className="form-control btn btn-default btn-md"
                    onClick={e =>
                      productDimension(e, formId, store, fetchProducts)
                    }
                    style={{ backgroundColor: "#ff7f50", color: "white" }}
                  >
                    <i className="fas fa-check" />
                    Add/Update Dimensions
                  </button>
                </div>
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1" />
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default VariantForm;

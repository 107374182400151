import React from "react";

export default function SelectedItem({ object, item, handleClick }) {
  return object ? (
    <div
      className="itemTag"
      style={{ width: "fit-content" }}
      id={`tag_${item}`}
    >
      {`${item.name} - ${item.store.storeName || item.store}`}
      <div className="cross" onClick={() => handleClick(item, "product")}>
        x
      </div>{" "}
    </div>
  ) : (
    <div
      className="itemTag"
      style={{ width: "fit-content" }}
      id={`tag_${item}`}
    >
      {item}
      <div className="cross" onClick={() => handleClick(item, "state")}>
        x
      </div>{" "}
    </div>
  );
}

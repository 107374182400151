import React, { useState } from "react";
import DiscountCoupons from "./DiscountCoupons";
import ViewDiscountCoupons from "./ViewDiscountCoupons";

const Discounts = () => {
  const [tab, setTab] = useState("main");
  return (
    <>
      {tab === "main" ? (
        <div
          style={{
            display: "flex",
            marginTop: "2rem",
            justifyContent: "center",
            columnGap: "2rem",
          }}
        >
          <button
            style={{
              fontSize: "1.5rem",
              height: "6vh",
              width: "25%",
              backgroundColor: "#2980b9",
              color: "white",
              borderRadius: "20px",
            }}
            onClick={() => setTab("create")}
          >
            Create Discount Code
          </button>
          <button
            style={{
              fontSize: "1.5rem",
              height: "6vh",
              width: "25%",
              backgroundColor: "#2980b9",
              color: "white",
              borderRadius: "20px",
            }}
            onClick={() => setTab("view")}
          >
            View Discount Codes
          </button>
        </div>
      ) : null}
      {tab === "create" ? <DiscountCoupons setTab={setTab} /> : null}
      {tab === "view" ? <ViewDiscountCoupons setTab={setTab} /> : null}
    </>
  );
};

export default Discounts;

import React from "react";
import { Link } from "react-router-dom";

const AccessOption = ({ option, id, handleValueChange }) => {
  return (
    <div className="settingOption accessOption">
      <div className="settingTool">
        <div className="settingTitle">
          <Link to={option.link} className="accessName" target="_blank">
            {option.name}
          </Link>
        </div>
      </div>
      <label className="custom-toggle access-toggle">
        <input
          id={id}
          checked={option.value}
          type="checkbox"
          onChange={e => handleValueChange(e, option)}
        />
        <span className="custom-toggle-slider rounded-circle access-toggle-slider" />
      </label>
    </div>
  );
};

export default AccessOption;

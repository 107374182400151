import React, { useState, useEffect } from "react";
import ExperimentItem from "./ExperimentItem";
import axios from "axios";

const ViewExperiments = () => {
	const [experiments, setExperiments] = useState([]);

	const fetchExperiments = async () => {
		const response = await axios.get("/api/experiment/fetch");
		setExperiments(response.data.experiments);
	};

	useEffect(() => {
		fetchExperiments();
	}, []);

	return (
		<div className="view-experiments-container">
			{experiments.length === 0 ? (
				<div className="no-experiments-found">
					No experiments found in DB! Please create an experiment!
				</div>
			) : (
				experiments.map((experiment) => (
					<ExperimentItem experiment={experiment} />
				))
			)}
		</div>
	);
};

export default ViewExperiments;

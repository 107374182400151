import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import qs from "querystring";

import useDisplayLink from "./DisplayLink";
import useDropdown from "../../components/global/Dropdown";

import "./clickfunnel.css";

const fetchStores = () =>
  axios({
    method: "get",
    url: "/api/stores",
  });

const fetchProducts = (store = {}) => {
  if (!store) return [];
  return axios({
    method: "get",
    url: `/api/product?store=${JSON.stringify(store)}`,
  });
};

const getProductsWithVariants = (list) =>
  list.products.reduce((accumulator, { title, variants }) => {
    const variantList = variants.map(({ variant_id, title: variantTitle }) => ({
      name: `${title}-${variantTitle}`,
      value: variant_id,
    }));
    return [...accumulator, ...variantList];
  }, []);

const ClickFunnelsWebhook = () => {
  document.title = "ClickFunnels Dashboard";

  const { setLinkVisibility, ShowLink, setLink } = useDisplayLink();

  const {
    Dropdown: StoreDropdown,
    setValues: setStoreValues,
    selected: selectedStore,
  } = useDropdown("Select Store");

  const {
    Dropdown: ProductDropdown,
    setValues: setProductValues,
    selected: selectedProduct,
  } = useDropdown("Select Product");

  const [stores, setStores] = useState([]);

  const fetchLink = async () => {
    const funnelName = document.getElementById("clickFunnelName").value;
    if (!selectedProduct.name) {
      alert("Please select product!");
      return;
    }
    if (!funnelName) {
      alert("Please give a funnel name!");
      return;
    }
    const response = await axios.post("/api/clickfunnels/createwebhookurl", {
      store: selectedStore.name,
      productName: selectedProduct.name,
      variantID: selectedProduct.value,
      funnelName,
    });
    setLinkVisibility(true);
    setLink(response.data.url);
  };

  useEffect(() => {
    (async () => {
      if (stores.length > 0) return;
      const store = await fetchStores();
      if (Array.isArray(store.data)) {
        setStores(store.data);
        setStoreValues(
          store.data.map((storeData) => ({
            name: storeData.storeName,
            value: storeData.storeAnchor,
            ...storeData,
          }))
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setProductValues([]);
      setLinkVisibility(false);
      const currentStore = stores.filter(
        (store) => store.storeName === selectedStore.name
      );
      const productList = await fetchProducts(currentStore[0]);

      const productWithVariants = await getProductsWithVariants(
        productList.data
      );
      setProductValues(productWithVariants);
    })();
  }, [selectedStore]);

  return (
    <div className="container">
      <div
        className="col-md-auto card"
        style={{
          height: "100%",
          margin: "10vh 10vw",
          boxShadow:
            "0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)",
        }}
      >
        <div style={{ textAlign: "center", fontSize: 25, marginTop: "3%" }}>
          ClickFunnels Dashboard
        </div>
        <Fragment>
          <div
            className="form-group"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              margin: "20 auto",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <div style={{ margin: "0.5rem 0 1rem 0" }}>
              <input
                id="clickFunnelName"
                className="form-control"
                placeholder="Enter funnel name"
                type="text"
                style={{
                  margin: "0 auto",
                  width: "60%",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                // flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
                columnGap: "1vw",
              }}
            >
              <StoreDropdown
                left="5vw"
                right="5vw"
                top="1vw"
                bottom="1vw"
                name="Select Store"
              />
              <ProductDropdown
                left="5vw"
                right="5vw"
                top="1vw"
                bottom="1vw"
                name="Select Product"
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                style={{
                  width: "fit-content",
                  marginTop: 20,
                  backgroundColor: "#00b894",
                  color: "white",
                  padding: "0 3rem",
                }}
                type="Submit"
                value="Add"
                onClick={fetchLink}
                className="form-control"
                id={`get_webhook`}
              />
            </div>
            <ShowLink />
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default ClickFunnelsWebhook;

import React, { useState, useRef, Fragment } from "react";
import Axios from "axios";
import { getCourierName } from "../../../trackingboard/OrderTracker/functions/getCourierName";
import ScannedCourierCompanies from "../res/ScannedCourierCompanies";
import ScannedFails from "../res/ScannedFails";
import Modal from "../res/Modal";
import Counter from "../res/Counter";
let audio = new Audio(require("../res/sounds/tink.wav"));

const PostPackScan = ({ setPage, mode }) => {
  document.title = "Post Packaging Scan";

  const [testResult, setTestResult] = useState(null);
  const [result, setResult] = useState({});
  const [scannedCount, setScannedCount] = useState(0);
  const [failScans, setFailScans] = useState([]);
  const [couriers, setCouriers] = useState({});
  const [showModal, setShowModal] = useState(null);

  const inputElement = useRef(null);
  const testInputElement = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !inputElement.current.readOnly) handleSubmit();
  };

  const testHandleKeyDown = (e) => {
    if (e.keyCode === 13) handleTestSubmit();
  };

  const handleTestSubmit = async () => {
    const testAwb = { AWBItem: testInputElement.current.value };
    if (testAwb.AWBItem.length !== 0) {
      const response = await Axios.post(
        `/api/warehouse/${
          mode === "Meesho" ? "awbScannedStatusMeesho" : "awbScannedStatus"
        }`,
        testAwb
      );
      testInputElement.current.value = "";
      setTestResult(response.data.message);
    }
  };

  const handleSubmit = async () => {
    const awb = { AWBItem: inputElement.current.value };
    console.log("awb = ", awb.AWBItem);
    inputElement.current.readOnly = true;

    if (awb.AWBItem.length === 0) return;

    let response = null;

    if (mode === "Meesho") {
      response = await Axios.post(`/api/warehouse/scanAWBMeesho`, awb);
    } else {
      response = await Axios.post(`/api/warehouse/scanAWB`, awb);
    }
    const {
      awb: responseAWB,
      msg,
      colourCode,
      courier_company_id,
    } = response.data;

    let textColour;
    if (colourCode == 1) {
      textColour = "#2ecc71";
      setScannedCount((scannedCount) => {
        return scannedCount + 1;
      });
      if (mode !== "Meesho") {
        const { name } = getCourierName(courier_company_id);
        setCouriers((couriers) => {
          let index;
          couriers[name]
            ? (index = Object.keys(couriers[name]).length)
            : (index = 0);
          couriers[name] = { ...couriers[name], [index]: responseAWB };
          return couriers;
        });
      } else {
        const name = "Meesho";
        setCouriers((couriers) => {
          let index;
          couriers[name]
            ? (index = Object.keys(couriers[name]).length)
            : (index = 0);
          couriers[name] = { ...couriers[name], [index]: responseAWB };
          return couriers;
        });
      }
    } else if (colourCode == 0) {
      textColour = "orange";
      const message = "Already Scanned";
      const fail = {
        awb: responseAWB,
        message,
      };
      setFailScans((failScans) => {
        return [...failScans, fail];
      });
    } else {
      textColour = "#e74c3c";
      let message;
      if (msg == "Please Specify AWB") {
        message = "Empty input";
      } else if (msg == "THE SHIPMENT IS CANCELLED. DO NOT DISPATCH IT!") {
        message = "Cancelled";
      } else if (msg.includes("NOT FOUND and is SAVED")) {
        message = "Missing AWB";
      } else {
        message = "Error";
      }
      const fail = {
        awb: responseAWB,
        message,
      };
      setFailScans((failScans) => {
        return [...failScans, fail];
      });
    }
    document.getElementById("result_msg").style.color = textColour;
    setResult({ message: msg });
    audio.play();
    inputElement.current.value = "";
    inputElement.current.readOnly = false;
  };

  const modalContent = (
    <div>
      <input
        type="text"
        name="test_awb_no"
        id="test_awb_input"
        className="form-control"
        placeholder="Check scanned status of AWB here"
        onKeyDown={(e) => testHandleKeyDown(e)}
        ref={testInputElement}
      ></input>
      <div>
        <h4 id="test_result_msg">{testResult}</h4>
      </div>
    </div>
  );

  const setShow = () => {
    setShowModal(false);
    testInputElement.current.value = "";
    setTestResult(null);
  };

  const styles = {
    backButtonContainer: {
      marginLeft: "2vw",
      marginTop: "2vh",
      width: "auto",
      color: "#00b894",
    },
  };

  return (
    <Fragment>
      <div style={styles.backButtonContainer}>
        <i
          className="fas fa-2x fa-arrow-circle-left back-button-icon"
          onClick={() => setPage("main")}
        ></i>
      </div>
      <div className="scan-awb-frontend">
        <Modal
          show={showModal}
          setShow={setShow}
          title="Check Scanned Status of AWB"
          content={modalContent}
        />
        {/* <div
          className="scan-awb-header-container"
          style={{ background: "#1abc9c" }}
        > */}
        {/* <div className="scan-awb-header">
            <h2>
              {mode === "Meesho" && "(MEESHO)"} Scan AWB And Ship
              {mode === "Meesho" && "(MEESHO)"}
            </h2>
            <h5>Total scan count - {scannedCount + failScans.length}</h5>
          </div> */}
        <div className="scanning-forward-header-container">
          <div className="reverse-scanning-header">
            <h2>
              Scan AWB And Ship
              {mode === "Meesho" && "(MEESHO)"}
            </h2>
          </div>
          <div>
            <button
              className="awb-custom-btn-2"
              onClick={() => setShowModal(true)}
            >
              Check Scanned Status
            </button>
          </div>
          <Counter count={scannedCount + failScans.length} />
        </div>
        {/* </div> */}
        <div className="scan-awb-body">
          <input
            type="text"
            name="awb_no"
            id="awb_input"
            className="form-control form-control-lg"
            placeholder="Scan AWB to be shipped here"
            onKeyDown={(e) => handleKeyDown(e)}
            ref={inputElement}
          ></input>
          <div className="scan-awb-message">
            <h4 id="result_msg">{result.message}</h4>
          </div>
          {Object.keys(couriers).length == 0 ? null : (
            <ScannedCourierCompanies
              couriers={couriers}
              scannedCount={scannedCount}
            />
          )}
          {failScans.length == 0 ? null : (
            <ScannedFails failScans={failScans} />
          )}
        </div>
        <audio src="../res/sounds/tink.wav" id="scanned_sound"></audio>
      </div>
    </Fragment>
  );
};

export default PostPackScan;

import React, { useState, useEffect } from "react";
import "./style.css";
const CustomModal = () => {
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const Modal = ({}) => (
    <div className={showHideClassName} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => setShow(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShow(false)}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return [show, Modal, setMessage, setShow];
};

export default CustomModal;

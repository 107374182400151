import React, { useState, useEffect } from "react";
import Axios from "axios";
import resolveStatus from "../../../trackingboard/OrderTracker/utils/resolveStatus";
function BillingBoard() {
  const [customers, setCustomers] = useState([]);
  const [logs, setLogs] = useState([]);
  const [details, setDetails] = useState({});

  const calculateBilling = async () => {
    let customer = document.getElementById("customer_selector").value;

    let data = { customer };
    document.getElementById("total_value").innerHTML = `Calculating..`;
    let result = await Axios.post("/api/admin/calculateBilling", data);
    setLogs(result.data.logs);
    setDetails(result.data.details);
    document.getElementById(
      "total_value"
    ).innerHTML = `Pending: ${result.data.TOTAL}`;
    console.log(result.data.logs);
  };

  const getCustomers = async () => {
    let result = await Axios.get("/api/auth/users/customers");
    console.log("Customers", result.data.users);
    setCustomers(result.data.users);
  };

  const submitCOGS = async () => {
    let cogs = document.getElementById("cogs_input").value;
    console.log("COGS", cogs);
    let customer = document.getElementById("customer_selector").value;
    let data = {
      customer,
      type: "COGS",
      value: parseInt(cogs)
    };
    if (customer === "Choose Customer") alert("Choose a customer first");
    else {
      let result = await Axios.post("/api/admin/addEntry", data);
      document.getElementById("cogs_input").value = "";
    }
  };

  const submitREM = async () => {
    let customer = document.getElementById("customer_selector").value;
    let remmittance = document.getElementById("remmittance_input").value;
    console.log("Remmittance", remmittance);

    let data = {
      customer,
      type: "remmittance",
      value: parseInt(remmittance)
    };

    if (customer === "Choose Customer") return alert("Choose a customer first");
    else {
      let result = await Axios.post("/api/admin/addEntry", data);
      document.getElementById("remmittance_input").value = "";
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);
  return (
    <div>
      <div className="card configurationsCard IMS-order-card">
        <div style={{ padding: "20px" }}>
          <center>Customer Billing</center>
          <div style={{ margin: "10px" }}>
            <label>Customer</label>
            <select
              placeholder="Customers"
              id="customer_selector"
              className="form-control form-control-sm"
              style={{
                height: "50px",
                fontSize: "0.9rem",
                borderRadius: 0
              }}
            >
              <option selected disabled>
                Choose Customer
              </option>
              {customers.map((customer, index) => (
                <option value={JSON.stringify(customer)} key={index}>
                  {customer.name}
                </option>
              ))}
            </select>
            <br />
            <label>Cost of goods</label>
            <div style={{ display: "flex" }}>
              <input
                className="form-control"
                placeholder="Cost of Goods"
                type="text"
                id="cogs_input"
                style={{ height: "50px", borderRadius: 0 }}
              />
              <button
                className="btn btn-danger"
                style={{ borderRadius: 0 }}
                onClick={() => submitCOGS()}
              >
                Submit
              </button>
            </div>
            <br />
            <label>Remittance</label>
            <div style={{ display: "flex" }}>
              <input
                className="form-control"
                placeholder="Remittance"
                type="text"
                id="remmittance_input"
                style={{ height: "50px", borderRadius: 0 }}
              />
              <button
                className="btn btn-danger"
                style={{ borderRadius: 0 }}
                onClick={() => submitREM()}
              >
                Submit
              </button>
            </div>
            <br />
            {/* <label>Service Fee</label>
            <input
              className="form-control"
              placeholder="Fee"
              type="text"
              id="fee_input"
              style={{ height: "50px", borderRadius: 0 }}
            /> */}
            <br />

            <button
              className="btn btn-danger"
              style={{ borderRadius: 0 }}
              onClick={() => calculateBilling()}
            >
              Calculate
            </button>
            <br />
            <div>
              <br />
              {logs.length > 0 ? (
                <div>
                  <p>Revenue: {details.totalRevenue}</p>
                  <p>Shipping Cost: {details.totalShippingCost}</p>
                  <p>GST: {details.totalGST}</p>
                  <p>Service Fee: {details.totalServiceFee}</p>
                  <ul style={{ listStyleType: "none" }}>
                    {details.remmittanceArr
                      ? details.remmittanceArr.map((item, i) => (
                          <li>
                            {"  "}-Remmittance #{i + 1}: {item}
                          </li>
                        ))
                      : ""}
                  </ul>
                  <p>Total Remmittance: {details.totalRemittance}</p>
                  <ul style={{ listStyleType: "none" }}>
                    {details.COGSArr
                      ? details.COGSArr.map((item, i) => (
                          <li>
                            {"  "}-COGS #{i + 1}: {item}
                          </li>
                        ))
                      : ""}
                  </ul>
                  <p>Total COGS: {details.totalCOGS}</p>
                </div>
              ) : (
                ""
              )}

              <span id="total_value"></span>
            </div>
            {/* <div>
              {logs
                ? logs.map(log => {
                    return (
                      <ul style={{ listStyleType: "none" }}>
                        <li>Order: {log.order}</li>
                        <li>Status: {resolveStatus(log.status)}</li>
                        <li>GST: {log.gstPerOrder}</li>
                        <li>Shipping Cost: {log.shippingCostPerOrder}</li>
                        <li>Service Fee: {log.serviceFeePerOrder}</li>
                        <li>Revenue: {log.revenuePerOrder}</li>
                      </ul>
                    );
                  })
                : ""}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingBoard;

import React from "react";
import SurfaceProductTag from "./SurfaceProductTag";

const SurfaceProductsTagbar = ({ surfaceProducts, removeSelectedProduct }) => {
  return (
    <div className="settings-surface-product-tag-bar">
      {surfaceProducts.map((surfaceProduct) => (
        <SurfaceProductTag
          surfaceProduct={surfaceProduct}
          removeSelectedProduct={removeSelectedProduct}
        />
      ))}
    </div>
  );
};

export default SurfaceProductsTagbar;

import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import AddZone from "../res/AddZone";
import AddAggregator from "../res/AddAggregator";
import AggregatorItem from "../res/AggregatorItem";
import Axios from "axios";
import { GlobalConsumer } from "../../../Global-Context/global-context";
import ZoneItem from "../res/ZoneItem";
import EditLoadbalancer from "./EditLoadbalancer";
import PickupAddressToggle from "../res/PickupAddressToggle";
import SurfaceConfiguration from "../res/SurfaceConfiguration";
import useProductPicker from "../../global/ProductPicker";
import ChannelCreation from "../res/ChannelCreation";

const ToggleLoadbalancer = lazy(() => import("./ToggleLoadbalancer"));
const ToggleLoadBalancer = lazy(() => import("./ToggleLoadbalancer"));

function AggregatorDashboard() {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user, setUser] = useState(userInfo);
  const [list, setList] = useState([]);
  const [stores, setStores] = useState([]);

  const [aggregators, setAggregators] = useState([]);
  const [zones, setZones] = useState([]);

  const [showEntry, setShowEntry] = useState(true);
  const [showZones, setShowZones] = useState(false);

  const [
    showSurfaceProductConfiguration,
    setShowSurfaceProductConfiguration,
  ] = useState(false);

  const [showChannelCreation, setShowChannelCreation] = useState(false);

  global.selected = [];
  const toggleView = () => {
    setShowEntry(!showEntry);
  };

  const getAggregators = async () => {
    let result = await Axios.get("/api/control/fetchAggregators");

    const temp = result.data.aggregators.map((aggregator) => {
      if (aggregator.allowedStores) {
        aggregator.allowedStores = aggregator.allowedStores.map(
          (store) => (store = stores.find((st) => st.storeAnchor === store))
        );
      }
      return aggregator;
    });

    setAggregators(temp);
  };

  const getZones = async () => {
    let result = await Axios.get("/api/control/fetchZones");
    setZones(result.data.zones);
  };
  const getStores = async () => {
    let allStores = await Axios.get("/api/stores");
    setStores(allStores.data);
  };

  useEffect(() => {
    getZones();
    getStores();
  }, [showEntry]);

  useEffect(() => {
    getAggregators();
  }, [stores]);

  return showChannelCreation ? (
    <ChannelCreation show={setShowChannelCreation} />
  ) : showSurfaceProductConfiguration ? (
    <SurfaceConfiguration show={setShowSurfaceProductConfiguration} />
  ) : !showZones ? (
    <div>
      <div style={{ padding: "20px" }}>
        {showEntry ? (
          <button className="add-button" onClick={() => toggleView()}>
            <i className="fas fa-plus"></i> Add Aggregator
          </button>
        ) : (
          <button className="close-button" onClick={() => toggleView()}>
            <i className="fas fa-times"></i> Close
          </button>
        )}
        <button
          className="switch-button"
          onClick={() => setShowZones(!showZones)}
        >
          <i className="fas fa-exchange-alt"></i> Zone Panel
        </button>
        <button
          className="add-button"
          onClick={() => {
            setShowSurfaceProductConfiguration(true);
          }}
        >
          Surface Products
        </button>
        <button
          className="add-button"
          onClick={() => {
            setShowChannelCreation(true);
          }}
        >
          <i className="fas fa-plus"></i> Create Shipyaari Channel
        </button>
      </div>
      {showEntry ? (
        <Suspense fallback={<div>Loading...</div>}>
          <div
            style={{
              display: "flex",
              justifyItems: "center",
              flexDirection: "column",
            }}
          >
            <PickupAddressToggle />
            {/* <ToggleLoadBalancer />
            <EditLoadbalancer /> */}
          </div>
        </Suspense>
      ) : null}
      {showEntry ? (
        aggregators.length > 0 ? (
          aggregators.map((aggregator) => (
            <AggregatorItem
              aggregator={aggregator}
              getAggregators={getAggregators}
              user={user}
            />
          ))
        ) : (
          <div className="aggregator-body">No Aggregators</div>
        )
      ) : (
        <AddAggregator user={user} setShowEntry={setShowEntry} />
      )}
    </div>
  ) : (
    <div>
      <div style={{ padding: "20px" }}>
        {showEntry ? (
          <button className="add-button" onClick={() => toggleView()}>
            <i className="fas fa-plus"></i> Add Zone
          </button>
        ) : (
          <button className="close-button" onClick={() => toggleView()}>
            <i className="fas fa-times"></i> Close
          </button>
        )}
        <button
          className="switch-button"
          onClick={() => setShowZones(!showZones)}
        >
          <i className="fas fa-exchange-alt"></i> Aggregator Panel
        </button>
      </div>
      {showEntry ? (
        zones.length > 0 ? (
          zones.map((zone) => (
            <ZoneItem
              zone={zone}
              getZones={getZones}
              user={user}
              stores={stores.map((item) => {
                let object = {
                  storeAnchor: item.storeAnchor,
                  storeName: item.storeName,
                  storeEmail: item.storeEmail,
                  channelID: item.channelID,
                  shopifyName: item.shopifyName,
                  courierPriority: aggregators,
                };
                return object;
              })}
            />
          ))
        ) : (
          <div className="aggregator-body">No Zones</div>
        )
      ) : (
        <AddZone
          user={user}
          setShowEntry={setShowEntry}
          // stores={stores}
          aggregators={aggregators.map((item) => {
            let object = {
              name: item.name,
            };
            return object;
          })}
          stores={stores.map((item) => {
            let object = {
              storeAnchor: item.storeAnchor,
              storeName: item.storeName,
              storeEmail: item.storeEmail,
              channelID: item.channelID,
              shopifyName: item.shopifyName,
              courierPriority: aggregators,
            };
            return object;
          })}
        />
      )}
    </div>
  );
}

export default AggregatorDashboard;

import React, { useState, Fragment } from "react";
import Product from "../res/Product";
import Modal from "./Modal";
import Axios from "axios";
import { confirmation } from "../../../utils/confirmation";
import resolveStatus from "../../../trackingboard/OrderTracker/utils/resolveStatus";
import { Link } from "react-router-dom";
import CallBackModal from "./CallBackModal";

function OrderItem({
  order,
  user,
  getOrders,
  current,
  type,
  id,
  setCurrentCount,
  callMadeFunction,
}) {
  const [acceptance, setAcceptance] = useState({
    style: {
      width: "20%",
      color: "white",
      background: "#1189A7",
    },
    percentage: "",
    event: "",
  });
  const [loading, setLoading] = useState(false);
  const [showOrder, setShowOrder] = useState(true);

  const [action, setAction] = useState("");
  const [total_price, setTotal_price] = useState(order.total_price);
  // const [amount, setAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const AddressIssue = order.callCustomer ? " Address Issue." : "";
  const upSell = order.upSellOrder ? " Upsell." : "";

  const reasons = AddressIssue + upSell;

  const called =
    order.generatedBySystem ||
    order.cancelledOrder ||
    order.outOfStock ||
    order.notifyOperations ||
    order.remarksForOrderRemoval ||
    order.manifestedAbandonedOrder ||
    order.pincodeNotServiceable
      ? true
      : false;

  const handleSelection = (value) => {
    if (value.includes("Order confirmed")) setAction("schedule");
    else if (value.includes("Other Cases") || value.includes("Order cancelled"))
      setAction("reason");
    else setAction("");
  };

  const getAcceptance = async () => {
    // Give ordername store
    // Get last order accepted true or false && get acceptance percentage
    let temp = acceptance;
    // setLoading(true);
    // let result = await Axios.post("prasads endpoint");
    // temp["style"]["background"] = result.data.acceptancePercentage
    //   ? result.data.acceptancePercentage > 60
    //     ? "#e74c3c"
    //     : "#1189A7"
    //   : "#1189A7";
    // temp["percentage"] = 60;
    // temp["event"] = "Accepted Last Order";
    // setAcceptance({ ...acceptance, ...temp });
  };

  const converToPrepaid = async (orderName, store) => {
    if (!confirmation()) return;
    let amount = prompt("Enter the amount paid by the customer");
    console.log(amount);
    if (!amount) return;
    if (parseInt(amount) > total_price)
      return alert("Please enter a valid value");

    let result = await Axios.post("/api/orders/transformCODtoPrepaid", {
      orderName,
      store,
      partialAmountRecieved: amount,
      user: {
        id: user.user_id,
        name: user.name,
      },
    });

    // alert(result.data);

    if (result.data.msg !== "Order updated!") {
      let amount = "";
      amount = prompt("Please enter a proper amount!");
      if (amount) converToPrepaid(orderName, store);
    }

    if (result.data.msg.includes("PINCODE")) alert("Pincode Not Serviceable!");
    else if (result.data.msg.includes("Order updated!")) {
      alert("Order updated!");
      if (total_price === 0) return;
      setTotal_price(result.data.balanceAmount);
    }
  };

  const submitOrder = async () => {
    document.getElementById(`order-button_${id}`).disabled = true;
    document.getElementById(`order-button_${id}`).innerHTML = "Submitting...";

    console.log(document.getElementById(`order-button_${id}`));

    let remarks = document.getElementById(
      `reason-selector_${order.name}_${order.customer.id}`
    ).value;
    console.log(remarks);
    if (remarks.includes("Choose an option")) {
      document.getElementById(`order-button_${id}`).disabled = false;
      document.getElementById(`order-button_${id}`).innerHTML = "Submit";
      return alert("Please select an option");
    }

    let data = {
      id: order.name,
      remarks,
      user_id: user.user_id,
      store: order.store,
      priorityCall: order.priorityCall ? true : false,
    };
    if (remarks.includes("Other Cases")) {
      let speacialReason = document.getElementById("speacial-reason").value;
      if (speacialReason) data.note = `Other Cases - ${speacialReason}`;
      else {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Enter A Reason");
      }
    } else if (remarks.includes("Order cancelled")) {
      let speacialReason = document.getElementById("speacial-reason").value;
      if (speacialReason) data.note = `Order cancelled - ${speacialReason}`;
      else {
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submit";
        return alert("Please Enter A Reason");
      }
    }

    console.log("Submitting", data, "as remarks");
    let result = await Axios.post("/api/support/updateOrderRemarks", data);

    document.getElementById(`order-button_${id}`).disabled = false;
    document.getElementById(`order-button_${id}`).innerHTML = "Submitted!";

    if (remarks.includes("Order confirmed")) {
      let address1 = document.getElementById(`address1_${id}`).value;
      let address2 = document.getElementById(`address2_${id}`).value;
      let company = document.getElementById(`company_${id}`).value;
      let city = document.getElementById(`city_${id}`).value;
      let zip = document.getElementById(`zip_${id}`).value;
      let province = document.getElementById(`province_${id}`).value;

      if (
        address1 === order.shipping_address.address1 &&
        address2 === order.shipping_address.address2 &&
        company === order.shipping_address.company &&
        city === order.shipping_address.city &&
        zip === order.shipping_address.zip &&
        province === order.shipping_address.province
      )
        alert("Haven't Changed The Existing Address..");
      const address = {
        address1,
        address2,
        company,
        city,
        zip,
        province,
      };

      console.log("Address", address);

      let data = {
        id: order.name,
        user_id: user.user_id,
        store: order.store,
        address,
      };

      if (confirmation) {
        await Axios.post("/api/support/updateAddress", data);
        Axios.post("/api/support/schedule", data);
        document.getElementById(`order-button_${id}`).disabled = false;
        document.getElementById(`order-button_${id}`).innerHTML = "Submitted!";
      } else {
        alert("Not Scheduling");
      }
    }
    // setShowOrder(false);
    callMadeFunction(order.name);
    setCurrentCount((current) => current + 1);
  };

  const order_info = {
    width: "100%",
  };

  const callBack = async (submit) => {
    if (submit) {
      let date = document.getElementById(
        `datePicker-${order.name}-${order.store.storeName}`
      ).value;
      let comment = document.getElementById(
        `comments-${order.name}-${order.store.storeName}`
      ).value;
      let store = order.store;
      if (!date) return alert("Please choose a date and time!");

      if (!comment) comment = "No comments";
      console.log(date, comment, store, order.name);

      let result = await Axios.post("/api/support/handleCallBack", {
        date,
        comment,
        store,
        order: order.name,
        user: {
          name: user.name,
        },
      });

      if (result.data.msg === "Handled CallBack") {
        alert("CallBack set!");
        setShowModal(false);
        console.log(
          "DD",
          document.getElementById(`cb-button-${order.name}`).innerHTML
        );
        document.getElementById(`cb-button-${order.name}`).innerHTML =
          "Submitted Callback!";
        document.getElementById(`cb-button-${order.name}`).disabled = true;
      }
    } else {
      setShowModal(true);
    }
  };

  const transerCall = async (name, store) => {
    if (confirmation()) {
      let result = await Axios.post(
        "/api/support/forwardOrderFromCallingToOps",
        {
          name,
          store,
        }
      );

      alert(result.data.msg);
      getOrders(user, type, current, true);
    }
  };

  const checkPincodeServiceability = async (name, store) => {
    console.log(name, store);
    let result = await Axios.get("/api/orders/orderServiceabilityCheck", {
      params: {
        name,
        store,
      },
    });
    console.log(result);
    // if (result.data.pincode_serviceable) {
    //   alert(`
    //   PINCODE serviceable : YES\n
    //   COD serviceable : ${result.data.cod_serviceable ? "YES" : "NO"}\n
    //   PREPAID serviceable : ${result.data.prepaid_serviceable ? "YES" : "NO"}
    //   `);
    // } else {
    //   alert(`PINCODE NOT SERVICEABLE`);
    // }
    if (result.data.pincode_serviceable) {
      let resultString = `PINCODE : YES || COD : ${
        result.data.cod_serviceable ? "YES" : "NO"
      } || PREPAID : ${result.data.prepaid_serviceable ? "YES" : "NO"}`;
      let spanElement = document.getElementById(`serviceability-span-${name}`);
      spanElement.innerHTML = resultString;
      spanElement.style.visibility = "visible";
      setTimeout(() => {
        spanElement.innerHTML = "";
      }, 4000);
    } else {
      let resultString = `PINCODE NOT SERVICEABLE`;
      let spanElement = document.getElementById(`serviceability-span-${name}`);
      spanElement.innerHTML = resultString;
      spanElement.style.visibility = "visible";
      setTimeout(() => {
        spanElement.innerHTML = "";
      }, 4000);
    }
  };

  return showOrder ? (
    <Fragment>
      <div style={{ display: "flex" }} onMouseEnter={() => getAcceptance()}>
        <div className="order-item-date" style={{ background: "#2980b9" }}>
          {new Date(order.created_at).toDateString()}
        </div>
        {/* <button
          className="transfer-button"
          onClick={() => transerCall(order.name, order.store)}
        >
          Transfer to Operations
          <i
            className="far fa-arrow-alt-circle-right"
            style={{ margin: "0 10px" }}
          ></i>
        </button> */}
        <div
          className="acceptance-button"
          onClick={() => transerCall(order.name, order.store)}
          style={{ marginLeft: "auto" }}
        >
          {loading ? (
            <div className="acceptance-info">
              {loading ? "Getting Acceptance Rate..." : ""}
            </div>
          ) : (
            ""
          )}
          {acceptance.event ? (
            <div className="acceptance-info">{acceptance.event}</div>
          ) : (
            ""
          )}

          {acceptance.percentage ? (
            <div className="acceptance-info" style={acceptance.style}>
              {acceptance.percentage}%
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="order-item" id={id} onMouseEnter={() => getAcceptance()}>
        <div
          className="order-header"
          style={{
            borderColor: "#2980b9",
            background: "#3398DB",
            color: "white",
          }}
        >
          <div
            className={`store-name order-header-item`}
            style={{ background: "#2980b9" }}
            onClick={() => setShow(!show)}
          >
            {order.store.storeName}
          </div>
          <div
            className="order-name order-header-item "
            style={{ background: "#3498db" }}
          >
            {/* <Link
            className="background-on-hover"
            style={{ color: "white", textDecoration: "none" }}
            to={`/operations/orderboard/${encodeURIComponent(
              order.store.storeAnchor
            )}/${encodeURIComponent(order.name)}`}
            target="_blank"
            rel="noopener noreferrer"
          > */}
            {order.name}
            {/* </Link> */}
            <span className="payment_status_new">
              {order.financial_status === "paid" ? "(PREPAID)" : "(COD)"}
            </span>
          </div>
          <div className="order-issues order-header-item desktop">
            {reasons}
          </div>{" "}
        </div>

        <div className="order-history">
          {order.callAssignedTo && user.master ? (
            <div className="caller-info">
              {" "}
              <center>{`${order.callAssignedTo} `}</center>
            </div>
          ) : (
            ""
          )}
          {order.remarks ? (
            <div
              className={`order-info`}
              style={!user.master ? order_info : {}}
            >
              <center>{`Previously, ${order.remarks} - called ${
                order.count ? order.count : "1"
              } times`}</center>
            </div>
          ) : (
            ""
          )}
        </div>
        {order.callBack ? (
          <div className="order-history-priority">
            {order.callBack.comment} (Call back at,{" "}
            {new Date(order.callBack.date).toLocaleString()})
          </div>
        ) : (
          ""
        )}

        <div className={`order-details`}>
          <ul className="customer-details">
            <li>
              <center>
                <strong>
                  {order.shipping_address
                    ? order.shipping_address.first_name !== ""
                      ? order.shipping_address.first_name
                      : order.shipping_address.last_name
                    : "No Details"}{" "}
                  -{" "}
                  {order.shipping_address
                    ? order.shipping_address.phone
                      ? order.shipping_address.phone
                      : order.billing_address.phone
                      ? order.billing_address.phone
                      : "No Phone Number Found"
                    : "No phone Number Found"}{" "}
                  -{" "}
                  {order.email
                    ? order.email
                    : order.customer.email
                    ? order.customer.email
                    : "No Email Found"}
                </strong>
              </center>
            </li>
          </ul>
        </div>
        <div className={`products-section`}>
          {order.line_items.map((product) => (
            <Product
              product={product}
              key={product.product_id + order.name}
              id={product.product_id + order.name}
              order={order}
              user={user}
            />
          ))}
        </div>
        <div style={{ padding: "0px 20px", fontWeight: "600" }}>
          Total Amount: {total_price}
        </div>
        {action === "schedule" ? (
          <Modal address={order.shipping_address} id={id} />
        ) : action === "reason" ? (
          <div style={{ padding: "20px" }}>
            <input
              className="reason-input"
              type="text"
              placeholder="Please Enter The Reason"
              aria-label="Search"
              id="speacial-reason"
            />
          </div>
        ) : (
          ""
        )}
        <div className="callingboard-toolbar">
          <button
            id={`cb-button-${order.name}`}
            className="callback-button"
            onClick={() => callBack()}
          >
            Call Back
          </button>
          <button
            id={`check-serviceability-button`}
            className="callback-button"
            onClick={() => checkPincodeServiceability(order.name, order.store)}
          >
            Serviceability
          </button>
          <button
            id={`check-serviceability-button`}
            className="callback-button"
            onClick={() => converToPrepaid(order.name, order.store)}
          >
            Convert To Prepaid
          </button>
          <span
            id={`serviceability-span-${order.name}`}
            style={{
              visibility: "visible",
              fontWeight: "bold",
            }}
          ></span>
        </div>
        {(!called && order.callAssignedTo === user.name) ||
        (user.master && !called) ? (
          <div className="order-footer">
            <select
              className="reason-selector"
              id={`reason-selector_${order.name}_${order.customer.id}`}
              onChange={(e) => handleSelection(e.target.value)}
            >
              <option selected>Choose an option</option>
              <option value="Order confirmed and address updated">
                Order confirmed or address to be updated
              </option>
              <option value="Order cancelled">Order cancelled</option>
              <option value="Not picking up calls">Not picking up calls</option>
              <option value="Call disconnected by customer">
                Call disconnected by customer
              </option>
              <option value="Switched off">Switched off</option>
              <option value="No incoming calls or out of order">
                No incoming calls or out of order
              </option>
              <option value="Number busy">Number busy</option>
              <option value="Other Cases">Other Cases</option>
            </select>
            <button
              id={`order-button_${id}`}
              className="order-button"
              onClick={() => submitOrder()}
            >
              {action === "schedule" ? "Schedule" : "Submit"}
            </button>
          </div>
        ) : (
          <div
            className="order-footer"
            style={{
              padding: "15px",
              background: "#079991",
              color: "white",
            }}
          >
            <ul style={{ listStyleType: "none", marginBottom: "0px" }}>
              {order.reattempt ? (
                <li>{`You can schedule this order in the ${order.reattempt}`}</li>
              ) : (
                ""
              )}
              {order.callAssignedTo !== user.name ? (
                <li>
                  {order.callAssignedTo !== undefined &&
                  order.callAssignedTo !== ""
                    ? `Call Assigned To ${order.callAssignedTo}`
                    : "Not Assigned To Anyone"}
                </li>
              ) : (
                ""
              )}
              {order.status_code ? resolveStatus(order.status_code) : ""}

              {order.generatedBySystem ? (
                <li>Order Is Already Scheduled</li>
              ) : (
                ""
              )}
              {order.cancelledOrder ? (
                <li>Order Was Cancelled At The Calling Dashboard</li>
              ) : (
                ""
              )}
              {order.outOfStock ? <li>Items Are Out Of Stocl</li> : ""}
              {order.notifyOperations ? (
                <li>Order Is On Operations Board</li>
              ) : (
                ""
              )}
              {order.remarksForOrderRemoval ? (
                <li>Order Has Been Cancelled</li>
              ) : (
                ""
              )}
              {order.manifestedAbandonedOrder ? (
                <li>Order Has Been Scheduled(Abandoned)</li>
              ) : (
                ""
              )}
              {order.pincodeNotServiceable ? (
                <li>Pincode Not Serviceable</li>
              ) : (
                ""
              )}
              {order.shipmentCancelled ? (
                <li>Order Has Been Cancelled At The Operations Board</li>
              ) : (
                ""
              )}
            </ul>
          </div>
        )}
      </div>
      {showModal ? (
        <CallBackModal
          order={order}
          setShowModal={setShowModal}
          callBack={callBack}
        />
      ) : (
        ""
      )}
    </Fragment>
  ) : (
    ""
  );
}

export default OrderItem;

import React, { useContext, useState } from "react";
import { UserConsumer } from "../components/context/caller-context";

const AllSelector = () => {
  const context = useContext(UserConsumer);

  const handleFocusDate = () => {
    document.getElementById("datePicker-all").type = "date";
  };
  const handleBlurDate = () => {
    document.getElementById("datePicker-all").type = "text";
  };
  return (
    <div style={{ display: "flex" }}>
      {/* <input
        className="form-control datepicker"
        placeholder="To"
        type="text"
        onFocus={() => handleFocusDate()}
        onBlur={() => handleBlurDate()}
        id="datePicker-all"
        style={{ height: "50px", borderRadius: 0 }}
      /> */}
      <select
        className="form-control"
        id="call-type-selector-all"
        style={{ height: "50px", borderRadius: 0 }}
      >
        <option disabled selected>
          Call Type
        </option>
        <option value="Address Issue">Address Issue</option>
        <option value="Multiple Items">Multiple Items</option>
        <option value="Multiple Quantity">Multiple Quantity</option>
        <option value="Repeat Customer">Repeat Customer</option>
        <option value="Others">Others</option>
      </select>{" "}
      <select
        className="form-control"
        id="call-status-selector-all"
        style={{ height: "50px", borderRadius: 0 }}
      >
        <option disabled selected>
          Call Status
        </option>
        <option value="Order Confirmed">Order Confirmed</option>
        <option value="Order cancelled">Order Cancelled</option>
        <option value="Not picking up calls">Not Picking Up Calls</option>
        <option value="Switched off">Switched Off</option>
        <option value="No incoming calls or out of order">
          No Incoming Calls Or Out Of Order
        </option>
        <option value="Number busy">Number Busy</option>
        <option value="Other Cases">Other Cases</option>
      </select>
      <button
        type="button"
        className="btn btn-danger"
        style={{ borderRadius: "0px", padding: "10px" }}
        onClick={() => context.computeTotal()}
      >
        Submit
      </button>
    </div>
  );
};

export default AllSelector;

import Axios from "axios";

export const scheduleOrdersManually = async (
  e,
  fetchOrders,
  store,
  courier,
  user
) => {
  e.preventDefault();
  console.log("loaded shipmentForOrders file", user);

  const createShipmentOrdersForm = document.querySelector("form");
  const scheduledOrdersList = document.querySelector("#scheduledOrdersList");
  const unscheduledOrdersList = document.querySelector(
    "#unscheduledOrdersList"
  );

  let orderNames = [];

  let orderName,
    productLengthID,
    productLength,
    productWidthID,
    productWidth,
    productHeightID,
    productHeight,
    productDeadWeightID,
    productDeadWeight;
  let count = 0;
  for (let i = 0; i < createShipmentOrdersForm.length; i++) {
    if (createShipmentOrdersForm[i].checked) {
      orderName = createShipmentOrdersForm[i].value;

      productLengthID = orderName + "_length";
      productWidthID = orderName + "_width";
      productHeightID = orderName + "_height";
      productDeadWeightID = orderName + "_deadWeight";
      productLength = document.getElementById(productLengthID).value;
      productWidth = document.getElementById(productWidthID).value;
      productHeight = document.getElementById(productHeightID).value;
      productDeadWeight = document.getElementById(productDeadWeightID).value;

      const obj = {
        orderName,
        length: productLength,
        width: productWidth,
        height: productHeight,
        actualWeight: productDeadWeight,
      };
      orderNames.push(obj);

      // intercept request to
      // a. update the corresponsing orderId's product_id variant_id dimensions
      // b. modify orderNames[] such that orderNames[] should have orders containing exact product and qty combination

      let interceptRequestResult = await Axios.post(
        `/api/operations/retrieveSimilarOrders?store=${JSON.stringify(store)}`,
        obj
      );
      if (
        interceptRequestResult.data.msg &&
        interceptRequestResult.data.msg.length > 0
      ) {
        console.log("count inside = ", count);
        if (count > 0)
          return alert(
            "Please select only one order which states Reason as Unknown Product and try again"
          );
        for (let i = 0; i < interceptRequestResult.data.msg.length; i++) {
          if (interceptRequestResult.data.msg[i].name !== obj.orderName) {
            let order = {
              orderName: interceptRequestResult.data.msg[i].name,
              length: obj.length,
              width: obj.width,
              height: obj.height,
              actualWeight: obj.actualWeight,
            };
            orderNames.push(order);
          }
        }
        count++;
      }
    }
  }

  let data = {
    orderNames,
    store,
    user: {
      user_id: user.user_id,
      name: user.name,
    },
  };

  // console.log("count outside = ", count);
  count = 0;
  console.log(orderNames);

  // return;
  if (courier === "shiprocket") {
    fetch("/api/operations/scheduleOrdersManually", {
      method: "POST",
      body: JSON.stringify(data), // orderNames
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      orderNames = [];
      data = {};
      response.json().then((data) => {
        if (
          data.message &&
          data.message.includes("Please Select Order/Orders")
        ) {
          return alert(data.message);
        } else if (data.status) {
          return console.log(data.status);
        } else {
          scheduledOrdersList.textContent = "ScheduledOrdersList: ";
          scheduledOrdersList.textContent += data.scheduledOrders;
          unscheduledOrdersList.textContent = "UnsheduledOrdesList: ";
          unscheduledOrdersList.textContent += data.unscheduledOrders;
        }
      });
      fetchOrders(store);
      document.querySelector("form").reset();
    });
  } else if (courier === "ecom") {
    fetch("/api/ecom/operations/scheduleOrdersManually", {
      method: "POST",
      body: JSON.stringify(data), // orderNames
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      orderNames = [];
      data = {};
      response.json().then((data) => {
        if (
          data.message &&
          data.message.includes("Please Select Order/Orders")
        ) {
          return alert(data.message);
        } else if (data.status) {
          return console.log(data.status);
        } else {
          scheduledOrdersList.textContent = "ScheduledOrdersList: ";
          scheduledOrdersList.textContent += data.scheduledOrders;
          unscheduledOrdersList.textContent = "UnsheduledOrdesList: ";
          unscheduledOrdersList.textContent += data.unscheduledOrders;
        }
      });
      fetchOrders(store);
      document.querySelector("form").reset();
    });
  } else if (courier === "clickpost") {
    fetch("/api/clickpost/operations/scheduleOrdersManually", {
      method: "POST",
      body: JSON.stringify(data), // orderNames
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      orderNames = [];
      data = {};
      response.json().then((data) => {
        if (
          data.message &&
          data.message.includes("Please Select Order/Orders")
        ) {
          return alert(data.message);
        } else if (data.status) {
          return console.log(data.status);
        } else {
          scheduledOrdersList.textContent = "ScheduledOrdersList: ";
          scheduledOrdersList.textContent += data.scheduledOrders;
          unscheduledOrdersList.textContent = "UnsheduledOrdesList: ";
          unscheduledOrdersList.textContent += data.unscheduledOrders;
        }
      });
      fetchOrders(store);
      document.querySelector("form").reset();
    });
  } else if (courier === "goswift") {
    console.log("hey goswift");
    fetch("/api/goswift/operations/scheduleOrdersManually", {
      method: "POST",
      body: JSON.stringify(data), // orderNames
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      orderNames = [];
      data = {};
      response.json().then((data) => {
        if (
          data.message &&
          data.message.includes("Please Select Order/Orders")
        ) {
          return alert(data.message);
        } else if (data.status) {
          return console.log(data.status);
        } else {
          scheduledOrdersList.textContent = "OrdersAttemptedToSchedule: ";
          scheduledOrdersList.textContent += data.scheduledOrders;
          unscheduledOrdersList.textContent = "OrdersUnattemptedToSchedule: ";
          unscheduledOrdersList.textContent += data.unscheduledOrders;
        }
      });
      fetchOrders(store);
      document.querySelector("form").reset();
    });
  } else {
    return alert("please contact developers to get this rectified");
  }
};

export const toggleProductDimensionsVisible = (
  orderName,
  productsDetails,
  noOfProducts,
  store
) => {
  let orderNumberID = orderName + "_orderName";
  let isChecked = document.getElementById(orderNumberID).checked;
  let productDimensionsID = orderName + "_productDimensions";
  let productLengthID = orderName + "_length";
  let productWidthID = orderName + "_width";
  let productHeightID = orderName + "_height";
  let productDeadWeightID = orderName + "_deadWeight";

  if (isChecked) {
    console.log("checking ");
    console.log(store);
    document.getElementById(productDimensionsID).style.display = "block";
    if (noOfProducts > 1) {
      document.getElementById(productLengthID).setAttribute("required", true);
      document.getElementById(productWidthID).setAttribute("required", true);
      document.getElementById(productHeightID).setAttribute("required", true);
      document
        .getElementById(productDeadWeightID)
        .setAttribute("required", true);
    } else {
      if (productsDetails[0].quantity === 1) {
        // make a request to backend to populate productDimensions for the product
        console.log(store.storeAnchor);
        Axios.get(
          `/api/product/getProductDimensions?variant_id=${
            productsDetails[0].variant_id
          }&storeAnchor=${encodeURIComponent(store.storeAnchor)}`
        )
          .then((productDimensions) => {
            console.log("requesting backend");
            console.log(productDimensions);
            if (
              productDimensions.data.length &&
              productDimensions.data.width &&
              productDimensions.data.height &&
              productDimensions.data.actualWeight
            ) {
              // populate values in front end
              document.getElementById(productLengthID).value =
                productDimensions.data.length;
              document.getElementById(productWidthID).value =
                productDimensions.data.width;
              document.getElementById(productHeightID).value =
                productDimensions.data.height;
              document.getElementById(productDeadWeightID).value =
                productDimensions.data.actualWeight;
              // set readonly to input tags
              document.getElementById(productLengthID).readOnly = true;
              document.getElementById(productWidthID).readOnly = true;
              document.getElementById(productHeightID).readOnly = true;
              document.getElementById(productDeadWeightID).readOnly = true;
            } else {
              document
                .getElementById(productLengthID)
                .setAttribute("required", true);
              document
                .getElementById(productWidthID)
                .setAttribute("required", true);
              document
                .getElementById(productHeightID)
                .setAttribute("required", true);
              document
                .getElementById(productDeadWeightID)
                .setAttribute("required", true);
            }
          })
          .catch((err) => {
            document
              .getElementById(productLengthID)
              .setAttribute("required", true);
            document
              .getElementById(productWidthID)
              .setAttribute("required", true);
            document
              .getElementById(productHeightID)
              .setAttribute("required", true);
            document
              .getElementById(productDeadWeightID)
              .setAttribute("required", true);
          });
      } else {
        document.getElementById(productLengthID).setAttribute("required", true);
        document.getElementById(productWidthID).setAttribute("required", true);
        document.getElementById(productHeightID).setAttribute("required", true);
        document
          .getElementById(productDeadWeightID)
          .setAttribute("required", true);
      }
    }
  } else {
    document.getElementById(productLengthID).removeAttribute("required");
    document.getElementById(productWidthID).removeAttribute("required");
    document.getElementById(productHeightID).removeAttribute("required");
    document.getElementById(productDeadWeightID).removeAttribute("required");
    document.getElementById(productDimensionsID).style.display = "none";
  }
};

export const removeOrdersFromDashboard = (e, fetchOrders, store, user) => {
  e.preventDefault();
  console.log("loaded REMOVE shipmentForOrders file", user);

  const createShipmentOrdersForm = document.querySelector("form");
  const scheduledOrdersList = document.querySelector("#scheduledOrdersList");
  const unscheduledOrdersList = document.querySelector(
    "#unscheduledOrdersList"
  );
  const url = "/api/operations/removeOrdersFromDashboard";
  let orderNames = [];

  let orderName;
  let remarks;
  for (let i = 0; i < createShipmentOrdersForm.length; i++) {
    if (createShipmentOrdersForm[i].checked) {
      orderName = createShipmentOrdersForm[i].value;
      remarks = document.getElementById(orderName + "_remarks").value;

      if (!remarks) {
        alert(`Enter Remarks for ${orderName}`);
        return;
      }
      const obj = {
        orderName,
        remarks,
      };
      orderNames.push(obj);
    }
  }

  let data = {
    orderNames,
    store,
    user: {
      user_id: user.user_id,
      name: user.name,
    },
  };

  console.log(orderNames);
  console.log("remove url", url);
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data), // orderNames
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    orderNames = [];
    data = {};
    response.json().then((data) => {
      if (data.message && data.message.includes("Please Select Order/Orders")) {
        return alert(data.message);
      } else if (data.status) {
        return console.log(data.status);
      } else {
        scheduledOrdersList.textContent = "OrdersRemoved: ";
        scheduledOrdersList.textContent += data.ordersFound;
        unscheduledOrdersList.textContent = "OrdersNotRemoved: ";
        unscheduledOrdersList.textContent += data.ordersNotFound;
      }
    });
    fetchOrders(store);
  });
};

export const removeOrders = (orderName) => {
  let orderNumberID = orderName + "_orderNameDetails";
  console.log(orderNumberID);
  let isChecked = document.getElementById(orderNumberID).checked;
  console.log(isChecked);
  if (isChecked) {
    console.log("checked");
    console.log(orderNumberID);
    document.getElementById(orderName + "_remarks").style.display = "block";
    document.getElementById(orderName + "_remarks").style.width = "100%";
    document
      .getElementById(orderName + "_remarks")
      .setAttribute("required", true);
  } else {
    console.log("unchecked");
    document.getElementById(orderName + "_remarks").removeAttribute("required");
    document.getElementById(orderName + "_remarks").style.display = "none";
  }
};

import React, { useState, Fragment } from "react";
import PostPackScan from "./PostPackScan";
import PrePackScan from "./PrePackScan";
import ScanAndShipAWBForward from "./ScanAndShipAWBForward";
import ReverseScan from "./ReverseScan";
import ReverseRejectedScan from "./ReverseRejectedScan";
import "../res/Scanning.css";

const AllScanning = () => {
  const [page, setPage] = useState("main");
  const [mode, setMode] = useState("Jarvis");
  const [type, setType] = useState(null);

  const handlePage = (page, mode) => {
    setPage(page);
    setMode(mode || "Jarvis");
  };

  return (
    <Fragment>
      {page === "main" ? (
        <>
          <div className="scanning-container-2">
            <div className="scanning-top-controls">
              <div
                className={`scanning-top-control ${
                  type === "forward" ? "active" : null
                }`}
                onClick={() => setType("forward")}
              >
                Forward Scanning
              </div>
              <div
                className={`scanning-top-control ${
                  type === "reverse" ? "active red" : null
                }`}
                onClick={() => setType("reverse")}
              >
                Reverse Scanning
              </div>
            </div>
            <div className="scanning-options">
              {type ? (
                type === "forward" ? (
                  <div className="scanning-options-forward">
                    <button
                      className="scanning-button-2"
                      onClick={() => handlePage("Pre-packaging Scan")}
                    >
                      Pre-packaging Scan
                    </button>
                    <button
                      className="scanning-button-2"
                      onClick={() => handlePage("Forward Scan")}
                    >
                      Forward Scan (Jarvis)
                    </button>
                    <button
                      className="scanning-button-2"
                      onClick={() => handlePage("Forward Scan", "Meesho")}
                    >
                      Forward Scan{" "}
                      <span style={{ color: "#16a085" }}>(Meesho)</span>
                    </button>
                    <button
                      className="scanning-button-2"
                      onClick={() => handlePage("Forward Rejected Scan")}
                    >
                      Forward Rejected Scan
                    </button>
                  </div>
                ) : (
                  <div className="scanning-options-reverse">
                    <button
                      className="scanning-button-2 reverse"
                      onClick={() => handlePage("Reverse Scan")}
                    >
                      Reverse Scan (Jarvis)
                    </button>
                    <button
                      className="scanning-button-2 reverse"
                      onClick={() => handlePage("Reverse Scan", "Meesho")}
                    >
                      Reverse Scan{" "}
                      <span style={{ color: "#16a085" }}>(Meesho)</span>
                    </button>
                    <button
                      className="scanning-button-2 reverse"
                      onClick={() => handlePage("Reverse Rejected Scan")}
                    >
                      Reverse Rejected Scan
                    </button>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      {page === "Pre-packaging Scan" ? <PrePackScan setPage={setPage} /> : null}
      {page === "Forward Scan" ? (
        <PostPackScan setPage={setPage} mode={mode} />
      ) : null}
      {page === "Forward Rejected Scan" ? (
        <ScanAndShipAWBForward setPage={setPage} />
      ) : null}
      {page === "Reverse Scan" ? (
        <ReverseScan setPage={setPage} mode={mode} />
      ) : null}
      {page === "Reverse Rejected Scan" ? (
        <ReverseRejectedScan setPage={setPage} />
      ) : null}
    </Fragment>
  );
};

export default AllScanning;

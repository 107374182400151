import Axios from "axios";
import React, { Fragment, useState } from "react";

export default function ChannelCreation({ show }) {
  const [full_name, setFullName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [brand_name, setBrandName] = useState("");

  const submit = async () => {
    return;
    await Axios.post("/api/shipyaari/operations/createChannel", {
      full_name,
      company_name,
      email,
      mobile,
      brand_name,
    });
  };

  return (
    <div style={{ marginLeft: "2vw" }}>
      <button
        className="close-button"
        onClick={() => {
          show(false);
        }}
      >
        <i className="fas fa-times"></i> Close
      </button>
      <div>
        <div style={{ marginTop: "20px" }}>
          <input
            type="text"
            className="form-control button-height"
            id="channelName"
            placeholder="Channel Name/ Store Name"
            onChange={(e) => setFullName(e.target.value)}
          />
          <br />
          <input
            type="text"
            className="form-control button-height"
            id="companyName"
            placeholder="Company Name/ Store Name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <br />
          <input
            type="text"
            className="form-control button-height"
            id="email"
            placeholder="Channel/ Store Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <input
            type="number"
            className="form-control button-height"
            id="mobile"
            placeholder="Mobile"
            onChange={(e) => setMobile(e.target.value)}
          />
          <br />
          <input
            type="text"
            className="form-control button-height"
            id="brand"
            placeholder="Brand Name"
            onChange={(e) => setBrandName(e.target.value)}
          />
          <br />
        </div>
        <button
          className="add-button"
          style={{ marginTop: "-10px" }}
          onClick={() => submit()}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

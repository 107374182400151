import moment from "moment";

const constructDialerLink = (ngrokLink, callEvent) => {
  if (!callEvent.recordingFile) return { streamLink: null, downloadLink: null };

  const streamLink = `${ngrokLink}/astVlogger/asttecs/voicefiles/converted/${callEvent.recordingFile}.wav`;
  const downloadLink = `
	${ngrokLink}/astVlogger/asttecs/voicefiles/${new Date(
    callEvent.date
  ).getFullYear()}/${moment(callEvent.date).format("MMMM")}/${
    new Date(callEvent.date).getDate() < 10
      ? "0" + new Date(callEvent.date).getDate()
      : new Date(callEvent.date).getDate()
  }/${callEvent.recordingFile}.gsm`;

  return { streamLink, downloadLink };
};

export default constructDialerLink;

import React, { Fragment, useContext, useState } from "react";
import { UserConsumer } from "../context/user-context";
import OrderItem from "../Items/OrderItem";
import Spinner from "../Items/Spinner";
import SearchForm from "../Forms/SearchForm";
import WelcomeCard from "../Items/WelcomeCard";
import FilterBar from "../Forms/FilterBar";
import TagBar from "../Items/TagBar";
import CustomTagBar from "../Items/CustomTagBar";
import { GlobalConsumer } from "../../../../Global-Context/global-context";
import { useParams } from "react-router-dom";

const Dashboard = () => {
  let params = useParams();
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();

  const [user] = useState(userInfo);

  let columnOptions = [
    { key: "contact", text: "Contact", value: "contact" },
    { key: "created_on", text: "Created On", value: "created_on" },
    { key: "scheduledOn", text: "Scheduled On", value: "scheduledOn" },
    { key: "pickedUpOn", text: "Picked Up On", value: "pickedUpOn" },
    { key: "deliveredOn", text: "Delivered On", value: "deliveredOn" },
    {
      key: "turnAroundTime",
      text: "Turn Around Time",
      value: "turnAroundTime",
    },
    { key: "products", text: "Products", value: "products" },
    {
      key: "shipping_address",
      text: "Shipping Address",
      value: "shipping_address",
    },
    {
      key: "billing_adress",
      text: "Billing Address",
      value: "billing_address",
    },
    { key: "total_price", text: "Total Price", value: "total_price" },
    {
      key: "courier_company_id",
      text: "Courier Partner",
      value: "courier_company_id",
    },
  ];

  const boot = (searchOrder, setDate) => {
    if (params.store && params.id) searchOrder(params.id, params.store, true);

    return <WelcomeCard setDate={setDate} user={user} />;
  };

  return (
    <UserConsumer>
      {({
        page,
        count,
        showOrders,
        permuteOrders,
        loading,
        store,
        setFiltered,
        filterByDate,
        setDate,
        setPerspectiveOption,
        filterOrders,
        products,
        filtered,
        queryFilter,
        searchOrder,
        filterProducts,
        fetchInitialOrders,
        increaseOffset,
        decreaseOffset,
        resetOffset,
        offset,
        fetchAggregators,
        aggregators,
        limit,
        handleLimitChange,
        selectedColumns,
        selectedColumnsObject,
        superStatusTags,
        setSuperStatusTags,
        statusAggregations,
        statusArray,
        disabled,
        setDisabled,
        fetchAllOrders,
        toggleLoading,
        setSearchedOrders,
        verboseMode,
      }) =>
        store === "" ? (
          <WelcomeCard
            setDate={setDate}
            user={user}
            statusArray={statusArray}
            statusAggregations={statusAggregations}
            superStatusTags={superStatusTags}
            setSuperStatusTags={setSuperStatusTags}
            disabled={disabled}
            setDisabled={setDisabled}
            setPerspectiveOption={setPerspectiveOption}
            fetchAllOrders={fetchAllOrders}
            showOrders={showOrders}
            selectedColumnsObject={selectedColumnsObject}
            selectedColumns={selectedColumns}
            loading={loading}
            columnOptions={columnOptions}
            toggleLoading={toggleLoading}
            setSearchedOrders={setSearchedOrders}
            verboseMode={verboseMode}
          />
        ) : (
          <div className="ordersContainer">
            <Fragment>
              <center>
                <div>
                  <p id="FilterLog"></p>
                </div>
              </center>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {global.selected
                  ? global.selected.map((item, i) => (
                      <TagBar
                        item={item}
                        key={i}
                        filtered={filtered}
                        setFiltered={setFiltered}
                        permuteOrders={permuteOrders}
                      />
                    ))
                  : ""}
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                }}
              >
                {superStatusTags.map((item, i) => (
                  <CustomTagBar
                    item={item}
                    selectedItems={superStatusTags}
                    setSelectedItems={setSuperStatusTags}
                    statusArray={statusArray}
                  />
                ))}
              </div>
              <div className="ordersCard filterCard">
                <FilterBar
                  aggregators={aggregators}
                  permuteOrders={permuteOrders}
                  filterByDate={filterByDate}
                  queryFilter={queryFilter}
                  products={products}
                  filtered={filtered}
                  fetchAggregators={fetchAggregators}
                  filterProducts={filterProducts}
                  setDate={setDate}
                  setPerspectiveOption={setPerspectiveOption}
                  fetchInitialOrders={fetchInitialOrders}
                  resetOffset={resetOffset}
                  setSuperStatusTags={setSuperStatusTags}
                  disabled={disabled}
                  setDisabled={setDisabled}
                  statusAggregations={statusAggregations}
                  statusArray={statusArray}
                  verboseMode={verboseMode}
                  user={user}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="stats-mode">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="abandonedMode"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="abandonedCheck"
                    >
                      Abandoned Checkout Mode
                    </label>
                  </div>
                </div>

                <div className="form-check">
                  <select
                    className="form-control form-control-sm"
                    style={{
                      height: "50px",
                      fontSize: "0.9rem",
                      borderRadius: 0,
                      width: "100%",
                      marginTop: "20px",
                      paddingLeft: 30,
                    }}
                    onChange={handleLimitChange}
                    value={limit}
                    // id="aggregator-selector"
                  >
                    <option defaultValue>20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>
                </div>
              </div>
              <div className="tracking-page-info">
                {/* Page {Math.ceil((offset === 0 ? limit : offset + 1) / limit)} */}
                {page ? "Page " + page : ""}
                {/* {`(${offset + 1} - ${offset + 20})`} */}
              </div>
              <div style={{ margin: "20px" }}>
                {/* <center>{showOrders.length} results found</center> */}
                <center id="order-result"></center>

                <center id="order_percentage"></center>
              </div>
              {loading ? (
                <Spinner />
              ) : (
                <Fragment>
                  <div style={{ position: "relative" }}>
                    <SearchForm
                      filterOrders={filterOrders}
                      store={store}
                      searchOrder={searchOrder}
                    />
                    <button
                      className="btn btn-secondary dropdown-toggle trackingboard-columns-button"
                      data-tooltip="Customize Columns"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {columnOptions.map((option) => (
                        <div
                          key={option.value}
                          id={option.value}
                          className="dropdown-item operations-store-button-item"
                          onClick={(e) =>
                            selectedColumns(e, option.text, "add")
                          }
                        >
                          {option.text}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className="ordersCard"
                    style={{
                      overflowX: "scroll",
                      position: "relative",
                      minHeight: "500px",
                    }}
                  >
                    <Fragment>
                      {showOrders.length > 0 ? (
                        <Fragment>
                          <table className="table  ordersTable">
                            <thead>
                              <tr>
                                <th scope="col" style={{ minWidth: "auto" }}>
                                  No.
                                </th>
                                <th scope="col" style={{ minWidth: "auto" }}>
                                  Order Number
                                </th>

                                <th scope="col">AWB</th>

                                <th scope="col">Status</th>

                                {selectedColumnsObject.contact.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.contact.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.contact.value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.created_on.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.created_on.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.created_on
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.scheduledOn.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.scheduledOn.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.scheduledOn
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.pickedUpOn.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.pickedUpOn.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.pickedUpOn
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.deliveredOn.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.deliveredOn.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.deliveredOn
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.turnAroundTime.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.turnAroundTime.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.turnAroundtime
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.products.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.products.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.products.value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.total_price.status ? (
                                  <th scope="col">
                                    {selectedColumnsObject.total_price.value}
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.total_price
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.shipping_address
                                  .status ? (
                                  <th scope="col">
                                    {
                                      selectedColumnsObject.shipping_address
                                        .value
                                    }
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.shipping_address
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.billing_address
                                  .status ? (
                                  <th scope="col">
                                    {
                                      selectedColumnsObject.billing_address
                                        .value
                                    }
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject.billing_address
                                            .value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                                {selectedColumnsObject.courier_company_id
                                  .status ? (
                                  <th scope="col">
                                    {
                                      selectedColumnsObject.courier_company_id
                                        .value
                                    }
                                    <i
                                      className="fas fa-times-circle"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        selectedColumns(
                                          e,
                                          selectedColumnsObject
                                            .courier_company_id.value,
                                          "remove"
                                        )
                                      }
                                    ></i>
                                  </th>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {showOrders.map((order, i) => (
                                <OrderItem
                                  number={offset + i}
                                  key={order.name}
                                  order={order}
                                  user={user}
                                  selectedColumnsObject={selectedColumnsObject}
                                />
                              ))}
                            </tbody>
                          </table>
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  </div>
                </Fragment>
              )}
            </Fragment>
            {showOrders.length > 1 ? (
              <div className="pagination pagination-tracking">
                <button
                  className={
                    page === 1 && offset === 0
                      ? "btn-disabled"
                      : "pagination-button "
                  }
                  disabled={page === 1 && offset === 0 ? true : false}
                  onClick={() => decreaseOffset(store)}
                >
                  <i
                    className={
                      page === 1 && offset === 0
                        ? ""
                        : "fas fa-arrow-circle-left"
                    }
                  ></i>
                </button>
                <button
                  className={
                    (offset + limit) / count > 1
                      ? "btn-disabled"
                      : "pagination-button"
                  }
                  disabled={Math.ceil(
                    (offset + limit) / count > 1 ? true : false
                  )}
                  onClick={() => increaseOffset(store)}
                >
                  <i
                    className={
                      (offset + limit) / count > 1
                        ? ""
                        : "fas fa-arrow-circle-right"
                    }
                  ></i>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )
      }
    </UserConsumer>
  );
};

export default Dashboard;

import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import { grey } from "@mui/material/colors";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: grey[50],
  border: "2px solid #000",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 2,
};

const EditDimension = ({
  name,
  length,
  breadth,
  height,
  weight,
  user,
  onSearch,
}) => {
  const [open, setOpen] = React.useState(false);
  const lengthRef = React.useRef(null);
  const breadthRef = React.useRef(null);
  const heightRef = React.useRef(null);
  const weightRef = React.useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    const payload = {
      name,
      length: parseFloat(lengthRef.current.value),
      breadth: parseFloat(breadthRef.current.value),
      height: parseFloat(heightRef.current.value),
      weight: parseFloat(weightRef.current.value),
      user,
    };
    const editDimensionUrl = "/api/dimensions";
    try {
      const { status, data } = await axios.put(editDimensionUrl, payload);
      onSearch();
      handleClose();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div>
      <Button size="small" variant={"outlined"} onClick={handleOpen}>
        <EditIcon fontSize="1rem" />
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            fontSize="large"
            sx={{ position: "absolute", right: 3, top: 3, cursor: "pointer" }}
            onClick={handleClose}
          />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mb={2}
            textAlign={"center"}
            sx={{ overflowWrap: "break-word" }}
            paragraph
          >
            {name}
          </Typography>
          <Grid container textAlign={"center"} columnSpacing={1}>
            <Grid item mb={2} xs={6}>
              <TextField
                id="outlined-number"
                label="Length"
                type="number"
                defaultValue={length}
                inputRef={lengthRef}
              />
            </Grid>
            <Grid item mb={2} xs={6}>
              <TextField
                id="outlined-number"
                label="Breadth"
                type="number"
                defaultValue={breadth}
                inputRef={breadthRef}
              />
            </Grid>
            <Grid item mb={2} xs={6}>
              <TextField
                id="outlined-number"
                label="Height"
                type="number"
                defaultValue={height}
                inputRef={heightRef}
              />
            </Grid>
            <Grid item mb={2} xs={6}>
              <TextField
                id="outlined-number"
                label="Weight"
                type="number"
                defaultValue={weight}
                inputRef={weightRef}
              />
            </Grid>
          </Grid>
          <Stack alignItems={"center"}>
            <Button size="large" variant="outlined" onClick={handleSave}>
              Save Dimensions
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EditDimension;

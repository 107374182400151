import React, { Fragment, useState } from "react";
import Axios from "axios";

function ZoneItem({ zone, getZones, user, stores }) {
  const [edit, setEdit] = useState(false);
  const [storesList, setStoresList] = useState(stores);

  const [newZone, setNewZone] = useState(zone.priority);
  const [reRender, setReRender] = useState(false);

  const modifyPriority = (newPriority, index) => {
    if (newPriority >= 0 && newPriority < newZone.length) {
      let temp = newZone[newPriority];

      newZone[newPriority] = newZone[index];

      newZone[index] = temp;
      setReRender(!reRender);
      setNewZone(newZone);
    }
  };

  // const modifyPriority = (newPriority, index, storeIndex) => {
  //   let tempStoreList = zone.priority;

  //   let priorityList = [];
  //   let courier;
  //   for (courier of zone.priority[storeIndex]["courierPriority"]) {
  //     priorityList.push({ name: courier.name, priority: newPriority });
  //   }

  //   let temp2 = priorityList[newPriority];
  //   priorityList[newPriority] = priorityList[index];

  //   priorityList[index] = temp2;

  //   priorityList = priorityList.map((item, index) => {
  //     return { name: item.name, priority: index + 1 };
  //   });

  //   setReRender(!reRender);

  //   tempStoreList[storeIndex]["courierPriority"] = priorityList;
  //   setStoresList(tempStoreList);
  // };

  const editZone = async () => {
    // await Axios.post("/api/control/updateZone", {
    //   zone: zone.zone,
    //   storesList
    // });
    // console.log(storesList);
    // setReRender(!reRender);

    let priority = newZone.map((zone, index) => {
      zone.priority = index + 1;
      return zone;
    });
    console.log(priority);

    await Axios.post("/api/control/updateZone", { priority, zone: zone.zone });
  };

  return (
    <div className="aggregator">
      <div className={`aggregator-head`} style={{ display: "flex" }}>
        <div>Zone {zone.zone} </div>
        <div className="aggregator-options">
          {edit ? (
            <i
              className="fas fa-check-circle aggregator-op"
              onClick={() => {
                setEdit(!edit);
                editZone();
              }}
            ></i>
          ) : (
            <i
              className="fas fa-pencil-alt aggregator-op"
              onClick={() => setEdit(!edit)}
            ></i>
          )}
        </div>
      </div>
      <div className="aggregator-body">
        <p>Priority</p>

        {edit ? (
          // <div className="priority-list-stores">
          //   {zone.priority.map((itemStore, indexStore) => (
          //     <div
          //       key={itemStore.storeName}
          //       style={{ display: "flex", flexDirection: "column" }}
          //     >
          //       <div className="priority-store">{itemStore.storeName}</div>
          //       <div className="priority-list">
          //         {itemStore["courierPriority"].map((item, index) => (
          //           <div
          //             key={item.name}
          //             style={{ display: "flex", padding: "5px" }}
          //           >
          //             <div style={{ marginRight: "20px", minWidth: "30px" }}>
          //               {index > 0 ? (
          //                 <i
          //                   className="fas fa-chevron-up"
          //                   onClick={e =>
          //                     modifyPriority(index - 1, index, indexStore)
          //                   }
          //                 ></i>
          //               ) : (
          //                 ""
          //               )}
          //               {index < itemStore.courierPriority.length - 1 ? (
          //                 <i
          //                   className="fas fa-chevron-down"
          //                   onClick={e =>
          //                     modifyPriority(index + 1, index, indexStore)
          //                   }
          //                 ></i>
          //               ) : (
          //                 ""
          //               )}
          //             </div>
          //             {item.name}
          //           </div>
          //         ))}
          //       </div>
          //     </div>
          //   ))}
          // </div>
          <div className="priority-list">
            {newZone.map((item, index) => (
              <div key={item.name} style={{ display: "flex" }}>
                <div style={{ marginRight: "20px", minWidth: "30px" }}>
                  {index > 0 ? (
                    <i
                      className="fas fa-chevron-up"
                      onClick={e => modifyPriority(index - 1, index)}
                    ></i>
                  ) : (
                    ""
                  )}
                  {index < newZone.length - 1 ? (
                    <i
                      className="fas fa-chevron-down"
                      onClick={e => modifyPriority(index + 1, index)}
                    ></i>
                  ) : (
                    ""
                  )}
                </div>
                {item.name}
              </div>
            ))}
          </div>
        ) : (
          <div className="zone-body-box">
            {zone.priority.map(priority => (
              <div className="zone-priority">
                <div className="zone-priority-box">{priority.priority}</div>{" "}
                <div className="zone-priority-body">{priority.name}</div>
              </div>
            ))}
            {/* {zone.priority.map(priority => (
              <div className="priority-list-add">
                <div style={{ minWidth: "50px" }}>
                  {" "}
                  {priority.storeName}
                  {priority.courierPriority.map(p => (
                    <div className="zone-priority">
                      <div className="zone-priority-box">{p.priority}</div>{" "}
                      <div className="zone-priority-body">{p.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))} */}
          </div>
        )}

        {zone.zones.length > 0 ? (
          <div style={{ marginTop: "20px" }}>
            <p>Zones</p>
            <div
              style={{
                display: "flex",
                margin: "20px 0px",
                flexWrap: "wrap",
                overflow: "scroll",
                maxHeight: "150px",
                padding: "5px 20px"
              }}
            >
              {zone.zones.map(location => (
                <div
                  className="itemTag"
                  id={`tag_${location}`}
                  style={{ borderColor: "#16A085" }}
                >
                  {location}
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ZoneItem;

import { statusArray } from "../../../res/resourceArray";

const dissolveStatus = (statusQuery) => {
  statusQuery = statusQuery.toLowerCase();
  for (let x in statusArray) {
    if (statusArray[x].toLowerCase() === statusQuery) {
      return parseInt(x);
    }
  }
};

export default dissolveStatus;

import React, { useState, useEffect, Fragment, useContext } from "react";
import Axios from "axios";
import Spinner from "../res/Spinner";
import useDatePicker from "../../global/DatePicker";
import moment from "moment";
import TagBar from "../../../trackingboard/OrderTracker/components/Items/TagBar";
import { GlobalConsumer } from "../../../Global-Context/global-context";

function DeliveryPercentagePincodeModule({ number, active, setActive }) {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [user] = useState(userInfo);
  const [pincodeArrayReact, setPincodeArrayReact] = useState([]);
  const [province, setProvince] = useState(null);
  const [allStates, setAllStates] = useState(false);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const states = [
    "All States",
    "Andra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telagana",
    "Tripura",
    "Uttaranchal",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadar and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadeep",
    "Pondicherry",
  ];
  const refreshvalues = async () => {
    setProvince(null);
    setPincodeArrayReact([]);
    setStore("");
    setResult("");
    clearDate();
    global.selected = [];
  };
  const submitDeliveryDetails = async () => {
    const store = JSON.parse(document.getElementById("store_selector").value);
    if (!store) return alert("Please choose a store");
    let selectedProducts = global.selected;

    let productsList = selectedProducts.map((item) => {
      return parseInt(item[0]);
    });
    if (province === '"All States"') {
      setAllStates(true);
    } else {
      setAllStates(false);
    }

    if (Object.keys(store).length == 0) alert("Please select a store!");
    else {
      const pincodeArray = [...pincodeArrayReact];
      const data = {
        store,
        fromDate,
        toDate,
        pincodeArray,
        province,
        pidArray: productsList ? productsList : [],
      };

      setLoading(true);

      const result_data = await Axios.post(
        "/api/orders/deliveryPercentagePincode",
        data
      );
      setLoading(false);
      setResult(result_data.data);
      setStore("");
    }
  };
  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };

  const updateProducts = async (value) => {
    let store = value;
    let productList = await Axios.get(
      `/api/globalDashboard/fetchProducts/${encodeURIComponent(store)}`
    );

    setProducts(
      productList
        ? Array.isArray(productList.data)
          ? productList.data
          : []
        : []
    );
    setFiltered(
      productList
        ? Array.isArray(productList.data)
          ? productList.data
          : []
        : []
    );
    setStore(JSON.parse(value));
  };
  const multiCheck = (e, value) => {
    if (!isNaN(parseFloat(value))) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";

      if (value2send) {
        global.selected.push([...value2send.split(",")]);

        document.getElementById("productSearch").value = "";

        let productList = global.selected.map((item) => item[0]);

        let newArray = products.filter((product) => {
          return !productList.includes(product[0].toString());
        });

        setFiltered(newArray);
      } else {
        alert("Choose a product");
      }
    }
  };

  const getProducts = async () => {
    const userData = user.user_id;
    let result = await Axios.get(
      `/api/product/getAllFavourites?user=${userData}`
    );

    let dummy = [];
    for (let i of result.data.products)
      dummy.push([parseFloat(i.product_id), i.title]);
    setProducts(dummy);
    setFiltered(dummy);
  };
  const handleTextarea = async (e) => {
    if (e.which == 13) {
      let pincode = document.getElementById("pincodeArray").value.trim();
      let tempArray = [...pincodeArrayReact, pincode];

      tempArray = Array.from(new Set(tempArray));
      setPincodeArrayReact(tempArray);
      document.getElementById("pincodeArray").value = "";
    }
  };
  const removePincode = async (pincode) => {
    let tempArray = [...pincodeArrayReact];
    let index = tempArray.indexOf(pincode);
    if (index != -1) tempArray.splice(index, 1);
    setPincodeArrayReact(tempArray);
  };

  useEffect(() => {
    getStores();
    getProducts();
    global.selected = [];
    return () => clearDate();
  }, []);

  return (
    <Fragment>
      {active === number ? (
        <center>
          <p style={{ borderBottom: "1px solid black", width: "fit-content" }}>
            {"Delivery Percentage Module (Pincode)"}
          </p>
        </center>
      ) : (
        ""
      )}
      <div
        id={`module_${number}`}
        className={`card moduleCard ${
          active === number ? "activeModuleCard" : ""
        }`}
        style={{ marginBottom: "30px" }}
        onClick={(e) => setActive(6)}
      >
        {active === number ? (
          <Fragment>
            {loading ? (
              <div style={{ margin: "20px" }}>
                <Spinner />
              </div>
            ) : (
              <div className="moduleContent">
                {pincodeArrayReact.length > 0 || global.selected.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "10px",
                        width: "50%",
                      }}
                    >
                      {global.selected
                        ? global.selected.map((item, i) => (
                            <TagBar
                              item={item}
                              key={i}
                              permuteOrders={getStores}
                              setFiltered={setFiltered}
                              filtered={filtered}
                            />
                          ))
                        : ""}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "10px",
                      }}
                    >
                      {pincodeArrayReact.length > 0
                        ? pincodeArrayReact.map((item, i) => (
                            <div className="itemTag" id={`pincode_${item}`}>
                              {item}
                              <div
                                className="cross"
                                onClick={() => removePincode(item)}
                              >
                                x
                              </div>{" "}
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="moduleBar"
                  style={{
                    marginTop: "10px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="stats-filter"
                    style={{ width: "100%", height: "50px", boxShadow: "none" }}
                  >
                    <select
                      placeholder="Stores"
                      id="store_selector"
                      className="form-control form-control-sm"
                      style={{
                        height: "100%",
                        fontSize: "0.9rem",
                        borderRadius: 0,
                      }}
                      onChange={(e) => updateProducts(e.target.value)}
                    >
                      <option value={JSON.stringify(store)} selected disabled>
                        {store ? store.storeName : "Choose Store"}
                      </option>
                      {stores.map((store, index) => (
                        <option value={JSON.stringify(store)} key={index}>
                          {store.storeName}
                        </option>
                      ))}
                    </select>

                    <select
                      placeholder="States"
                      id="state_selector"
                      className="form-control form-control-sm"
                      disabled={pincodeArrayReact.length !== 0 ? true : false}
                      style={{
                        height: "100%",
                        fontSize: "0.9rem",
                        borderRadius: 0,
                      }}
                      onChange={(e) => {
                        setProvince(e.target.value);
                      }}
                    >
                      <option value={JSON.stringify(states)} selected disabled>
                        {"Choose State"}
                      </option>
                      {states.map((state, index) => (
                        <option value={JSON.stringify(state)} key={index}>
                          {state}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id={"productSearch"}
                      list="productList"
                      autoComplete="off"
                      placeholder="Products"
                      className="form-control form-control-sm"
                      style={{
                        height: "100%",
                        fontSize: "0.9rem",
                        borderRadius: 0,
                        width: "100%",
                      }}
                      onChange={(e) => {
                        multiCheck(e, e.target.value);
                      }}
                    />
                    <datalist id="productList">
                      {filtered.map((option, index) => (
                        <option
                          key={index}
                          data-value={option}
                          value={option[0]}
                        >
                          {option[1]}
                        </option>
                      ))}
                    </datalist>
                    <input
                      id="pincodeArray"
                      placeholder="pincode"
                      rows="3"
                      className="form-control"
                      disabled={province == null ? false : true}
                      style={{
                        height: "100%",
                        fontSize: "0.9rem",
                        borderRadius: 0,
                        padding: "15px",
                        width: "100%",
                      }}
                      onKeyPress={(e) => handleTextarea(e)}
                    />

                    <DatePicker />
                    <button
                      className="btn btn-danger"
                      style={{ borderRadius: 0 }}
                      onClick={() => submitDeliveryDetails()}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ borderRadius: 0 }}
                      onClick={() => refreshvalues()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                {result ? (
                  <div>
                    <div className="flag-module-filter-info">
                      {`Displaying orders between ${new Date(
                        fromDate
                      ).toLocaleDateString()} & ${new Date(
                        toDate
                      ).toLocaleDateString()} `}
                    </div>
                    <table
                      className="table mx-auto"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      <tbody>
                        <tr>
                          <th>
                            {global.selected.length > 0 ? "Product Id" : ""}
                          </th>
                          <th>Pincode</th>
                          <th>Shipped</th>
                          <th>Delivered</th>
                          <th>Delivery Percentage</th>
                        </tr>

                        {result.map((element, index) => (
                          <tr
                            style={
                              isNaN(element.Pincode) && !allStates
                                ? { background: "#ecf0f1" }
                                : {}
                            }
                          >
                            <td>{element.Variant_id}</td>
                            <td>{element.Pincode}</td>
                            <td>{element.Shipped}</td>
                            <td>{element.Delivered}</td>
                            <td>{`${element.Percentage}%`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </Fragment>
        ) : (
          <div className="moduleInactive">
            <p>{"Delivery Percentage Pincode Module"}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default DeliveryPercentagePincodeModule;

import React, { useState, useEffect, Fragment } from "react";
import useDatePicker from "../../../../components/global/DatePicker";
import Axios from "axios";
import TagBar from "../Items/TagBar";
import CustomTagBar from "../Items/CustomTagBar";
import useDatePerspective from "../../../../components/global/DatePerspective";
import useGlobalSearchBar from "../../../../components/global/GlobalSearchBar";
import OrderItem from "../Items/OrderItem";
import Spinner from "../Items/Spinner";

const WelcomeCard = ({
  user,
  setDate,
  statusArray,
  statusAggregations,
  superStatusTags,
  setSuperStatusTags,
  setPerspectiveOption,
  disabled,
  setDisabled,
  fetchAllOrders,
  showOrders,
  selectedColumns,
  selectedColumnsObject,
  loading,
  columnOptions,
  setSearchedOrders,
  verboseMode,
}) => {
  const [filtered, setFiltered] = useState([]);
  const [products, setProducts] = useState([]);
  const [aggregators, setAggregators] = useState([]);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");
  const [params] = useState(window.location.search.includes("?input="));

  const [DatePerspectiveFilter, perspectiveOption] = useDatePerspective();
  const [
    DashboardSearchBar,
    ,
    details,
    setDetails,
    getOrderDetails,
  ] = useGlobalSearchBar();

  //  send csv for details

  const fetchProducts = async () => {
    let result = await Axios.get(
      `/api/product/getAllFavourites?user=${user.user_id}`
    );

    setFiltered(result.data.products);
    setProducts(result.data.products);
  };

  const fetchAggregators = async () => {
    let { data } = await Axios.get("/api/control/fetchAggregators");
    setAggregators(data.aggregators);
  };

  const clearSelectors = () => {
    document.getElementById("statusSelector").value = 0;
    document.getElementById("superStatusSelector").value = "";
    if (document.getElementById("datePickerFrom"))
      document.getElementById("datePickerFrom").value = "";
    if (document.getElementById("datePickerTo"))
      document.getElementById("datePickerTo").value = "";
    if (document.getElementById("productSearch"))
      document.getElementById("productSearch").value = "";

    if (document.getElementById("datePickerRange"))
      document.getElementById("datePickerRange").value = 0;

    global.selected = [];
    if (document.getElementById("FilterLog"))
      document.getElementById("FilterLog").innerHTML = "";

    if (document.getElementById("aggregator-selector"))
      document.getElementById("aggregator-selector").value = "Aggregator";

    if (document.getElementById("paymentOptionSelector"))
      document.getElementById("paymentOptionSelector").value = 0;

    // if (document.getElementById("trafficSourceSelector"))
    //   document.getElementById("trafficSourceSelector").value = 0;

    clearDate();
    window.location.href = window.location.origin + window.location.pathname;
    setDisabled({
      statusSelector: false,
      superStatusSelector: false,
    });
    setSuperStatusTags([]);
    setDetails(null);
    fetchAllOrders(user);
  };

  const multiCheck = (e, value) => {
    if (!isNaN(parseFloat(value))) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";

      if (value) {
        let object = JSON.parse(value2send);
        global.selected.push([object.product_id, object.title]);

        document.getElementById("productSearch").value = "";
        let productList = global.selected.map((item) => item[0]);

        let newArray = products.filter((product) => {
          return !productList.includes(product.product_id);
        });
        setFiltered(newArray);
      } else {
        alert("Choose a product");
      }
    }
  };

  useEffect(() => {
    if (details) setSearchedOrders(details);
  }, [details]);

  useEffect(() => {
    global.selected = [];
    fetchProducts();
    fetchAggregators();
  }, []);

  useEffect(() => {
    fetchAllOrders(user);
  }, [user]);

  useEffect(() => {
    setDate(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    setPerspectiveOption(perspectiveOption);
  }, [perspectiveOption]);

  useEffect(() => {
    if (params) {
      const urlParams = new URLSearchParams(window.location.search);
      const input = urlParams.get("input");
      getOrderDetails(decodeURIComponent(input));
    }
  }, [params]);

  return (
    <div className="tableMessage" style={{ padding: "30px" }}>
      <div>
        <div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {global.selected
              ? global.selected.map((item, i) => (
                  <TagBar
                    item={item}
                    key={i}
                    filtered={filtered}
                    setFiltered={setFiltered}
                  />
                ))
              : ""}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "10px",
            }}
          >
            {superStatusTags.map((item, i) => (
              <CustomTagBar
                item={item}
                selectedItems={superStatusTags}
                setSelectedItems={setSuperStatusTags}
                statusArray={statusArray}
              />
            ))}
          </div>
          <div className="ordersCard filterCard">
            <div style={{ display: "flex", height: "60px" }}>
              <DatePerspectiveFilter />
              <select
                className="form-control form-control-sm"
                style={{
                  height: "100%",
                  fontSize: "0.9rem",
                  borderRadius: 0,
                  width: "100%",
                }}
                id="aggregator-selector"
              >
                <option key="0" defaultValue disabled>
                  Aggregator
                </option>
                <option value="All">All aggregators</option>
                {aggregators.map((aggregator, index) => (
                  <option key={index + 1} value={aggregator.name}>
                    {aggregator.name}
                  </option>
                ))}
              </select>
              <DatePicker />

              <select
                className="form-control form-control-sm"
                style={{
                  height: "100%",
                  fontSize: "0.9rem",
                  borderRadius: 0,
                  width: "100%",
                  display: !verboseMode && "none",
                }}
                id="statusSelector"
                disabled={disabled.statusSelector}
                onChange={() =>
                  setDisabled({
                    statusSelector: false,
                    superStatusSelector: true,
                  })
                }
              >
                <option value={0} selected disabled>
                  Status
                </option>
                {Object.keys(statusArray).map((status, index) => (
                  <option key={index} value={status}>
                    {statusArray[status]}
                  </option>
                ))}
              </select>

              <select
                className="form-control form-control-sm"
                style={{
                  height: "100%",
                  fontSize: "0.9rem",
                  borderRadius: 0,
                  width: "100%",
                }}
                id="superStatusSelector"
                disabled={disabled.superStatusSelector}
                onChange={(e) => {
                  setDisabled({
                    statusSelector: true,
                    superStatusSelector: false,
                  });
                  setSuperStatusTags(statusAggregations[e.target.value]);
                }}
              >
                <option value="" selected disabled>
                  Choose a super status
                </option>
                {Object.keys(statusAggregations).map((superStatus) => (
                  <option value={superStatus}>
                    {superStatus.charAt(0).toUpperCase() + superStatus.slice(1)}
                  </option>
                ))}
              </select>
              <select
                className="form-control form-control-sm"
                style={{
                  height: "100%",
                  fontSize: "0.9rem",
                  borderRadius: 0,
                  width: "100%",
                }}
                id="paymentOptionSelector"
              >
                <option value={0} selected disabled>
                  Choose a payment method
                </option>
                <option value="paid">Prepaid</option>
                <option value="pending">COD</option>
              </select>
              {!user.customer ? (
                <select
                  className="form-control form-control-sm"
                  style={{
                    height: "100%",
                    fontSize: "0.9rem",
                    borderRadius: 0,
                    width: "100%",
                  }}
                  id="trafficSourceSelector"
                >
                  <option value={0} selected disabled>
                    Choose a traffic source
                  </option>
                  <option value="Facebook">Facebook</option>
                  <option value="GoogleOrganic">Google-Organic</option>
                  <option value="GoogleCpc">Google-Paid</option>
                  <option value="TikTok">TikTok</option>
                  <option value="Chingari">Chingari</option>
                </select>
              ) : (
                ""
              )}
              <input
                type="text"
                id={"productSearch"}
                list="productList"
                autoComplete="off"
                placeholder="Products"
                className="form-control form-control-sm"
                style={{
                  height: "60px",
                  fontSize: "0.9rem",
                  borderRadius: 0,
                  width: "100%",
                }}
                // onChange={() => filterProducts()}
                // onKeyDown={e => {
                //   multiCheck(e, e.target.value);
                // }}
                onChange={(e) => {
                  multiCheck(e, e.target.value);
                }}
              />
              <datalist id="productList">
                {filtered.map((option, index) => (
                  <option
                    key={index}
                    data-value={JSON.stringify(option)}
                    value={option.product_id}
                  >
                    {option.title}
                  </option>
                ))}
              </datalist>
            </div>
            <div style={{ display: "flex", height: "50px", margin: "10px" }}>
              <button
                className="btn btn-danger"
                style={{ borderRadius: 0, width: "100%", marginRight: "10px" }}
                onClick={() => {
                  if (params)
                    window.location.href =
                      window.location.origin + window.location.pathname;

                  fetchAllOrders(user);
                }}
              >
                Search
              </button>
              <button
                className="btn btn-danger"
                style={{ borderRadius: 0, width: "100%" }}
                onClick={() => {
                  clearSelectors();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div className="stats-mode" style={{ marginBottom: "30px" }}>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="abandonedMode"
            />
            <label className="form-check-label" htmlFor="abandonedCheck">
              Abandoned Checkout Mode
            </label>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            boxShadow:
              "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
          }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <DashboardSearchBar />
              {showOrders.length > 0 ? (
                <div style={{ position: "relative" }}>
                  <button
                    className="btn btn-secondary dropdown-toggle trackingboard-columns-button"
                    data-tooltip="Customize Columns"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ top: "-5px", left: 0 }}
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </button>
                  {/* <button
                    className="btn btn-secondary  trackingboard-download-items-button"
                    data-tooltip="Download Searched Items"
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      top: "-150px",
                      left: "15px",
                      borderRadius: "50%",
                      width: "50px",
                    }}
                    onClick={() => downloadPayload()}
                  >
                    <i class="fas fa-arrow-down"></i>
                  </button> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {columnOptions.map((option) => (
                      <div
                        key={option.value}
                        id={option.value}
                        className="dropdown-item operations-store-button-item"
                        onClick={(e) => selectedColumns(e, option.text, "add")}
                      >
                        {option.text}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {showOrders.length > 0 || (details && details.length > 0) ? (
                <div style={{ overflowX: "scroll", minHeight: "550px" }}>
                  <table className="table  ordersTable">
                    <thead>
                      <tr>
                        <th scope="col" style={{ minWidth: "auto" }}>
                          No.
                        </th>
                        <th scope="col" style={{ minWidth: "auto" }}>
                          Order Number
                        </th>

                        <th scope="col">AWB</th>

                        <th scope="col">Status</th>

                        {selectedColumnsObject.contact.status ? (
                          <th scope="col">
                            {selectedColumnsObject.contact.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.contact.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.created_on.status ? (
                          <th scope="col">
                            {selectedColumnsObject.created_on.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.created_on.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.scheduledOn.status ? (
                          <th scope="col">
                            {selectedColumnsObject.scheduledOn.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.scheduledOn.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.pickedUpOn.status ? (
                          <th scope="col">
                            {selectedColumnsObject.pickedUpOn.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.pickedUpOn.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.deliveredOn.status ? (
                          <th scope="col">
                            {selectedColumnsObject.deliveredOn.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.deliveredOn.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.turnAroundTime.status ? (
                          <th scope="col">
                            {selectedColumnsObject.turnAroundTime.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.turnAroundtime.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.products.status ? (
                          <th scope="col">
                            {selectedColumnsObject.products.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.products.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.total_price.status ? (
                          <th scope="col">
                            {selectedColumnsObject.total_price.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.total_price.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.shipping_address.status ? (
                          <th scope="col">
                            {selectedColumnsObject.shipping_address.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.shipping_address.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.billing_address.status ? (
                          <th scope="col">
                            {selectedColumnsObject.billing_address.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.billing_address.value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                        {selectedColumnsObject.courier_company_id.status ? (
                          <th scope="col">
                            {selectedColumnsObject.courier_company_id.value}
                            <i
                              className="fas fa-times-circle"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                selectedColumns(
                                  e,
                                  selectedColumnsObject.courier_company_id
                                    .value,
                                  "remove"
                                )
                              }
                            ></i>
                          </th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!details
                        ? showOrders.map((order, i) => (
                            <OrderItem
                              number={i}
                              key={order.name}
                              order={order}
                              user={user}
                              selectedColumnsObject={selectedColumnsObject}
                            />
                          ))
                        : details.map((order, i) => (
                            <OrderItem
                              number={i}
                              key={order.name}
                              order={order}
                              user={user}
                              selectedColumnsObject={selectedColumnsObject}
                            />
                          ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;

import React, { useState } from "react";
import useDatePicker from "../../global/DatePicker";
import useDatePerspective from "../../global/DatePerspective";
import Spinner from "../../home/res/Spinner";
import Axios from "axios";
import useAlert from "../../global/Alert";

function StatsCSV() {
  const [DatePicker, fromDate, toDate] = useDatePicker("2020");
  const [DatePerspectiveFilter, perspectiveOption] = useDatePerspective();
  const [loading, setLoading] = useState(false);
  const [AlertBox, setNotification] = useAlert();

  const submitDetails = async () => {
    setLoading(true);
    document.getElementById("stats-csv-submit-button").disabled = "true";
    let data = {
      fromDate,
      toDate,
      perspectiveOption,
    };
    let result = await Axios.post("/api/stats/statsCSV", data);

    setLoading(false);
    document
      .getElementById("stats-csv-submit-button")
      .removeAttribute("disabled");

    if (result.data.msg) {
      setNotification("success", result.data.msg);
      return;
    }

    setNotification("success", "Please find the CSV attached below");

    let blob = new Blob([result.data.statsCSV], { type: "text/csv" });
    let a = document.createElement("a");
    a.download = "stats.csv";
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("style", "display:none;");
    a.textContent = "Download stats CSV";

    a.dataset.downloadurl = [{ type: "text/csv" }, a.download, a.href].join(
      ":"
    );
    document.body.appendChild(a);
    a.click();
  };
  return (
    <div className="stats-content">
      <AlertBox />
      <h1 style={{ textAlign: "center" }}>Stats CSV</h1>
      <div className="stats-filter">
        <DatePerspectiveFilter />
        <DatePicker />
        <button
          id="stats-csv-submit-button"
          className="btn btn-danger"
          style={{ borderRadius: 0 }}
          onClick={() => submitDetails(fromDate, toDate)}
        >
          Submit
        </button>
      </div>
      <div style={{ padding: "10px" }}>{loading ? <Spinner /> : ""}</div>
    </div>
  );
}

export default StatsCSV;

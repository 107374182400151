import axios from "axios";

export const addNewResource = async type => {
  document.getElementById(`add_${type}`).disabled = true;
  document.getElementById(`btn_${type}`).disabled = true;

  let resource = document
    .getElementById(`add_${type}`)
    .value.toLocaleLowerCase();

  let data = {
    type,
    resource
  };

  let res = await axios({
    method: "post",
    url: "/api/inventory/",
    data
  });

  console.log(`Added type: ${type} resource: ${resource}, got ${res}`);
  if (res.data === "Added") {
    document.getElementById(`add_${type}`).disabled = false;
    document.getElementById(`btn_${type}`).disabled = false;
    document.getElementById(`add_${type}`).value = "";
    document.getElementById(`add_${type}`).focus();
  } else {
    alert("Error while adding", res.data);
  }
};

import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import Cryptr from "cryptr";

const EmailConfigurations = () => {
  const [state, setState] = useState({});
  const [page, setPage] = useState("main");
  const [operation, setOperation] = useState("");
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [user, setUser] = useState([]);

  const handleStateChange = (type, e) => {
    setState({ ...state, [type]: e.target.value });
  };

  const handleSave = async () => {
    const api = `/api/emailConfigurations/dynamicOperations`;
    const payload = {
      ...state,
      operation,
    };
    const { data } = await axios.post(api, payload);
    alert(data.message);
    resetPage();
  };

  const handleTest = async () => {
    const api = `/api/emailConfigurations/testEmail`;
    const payload = {
      ...state,
      operation,
    };
    console.log(payload);
    const { data } = await axios.post(api, payload);
    alert(data.message);
    resetPage();
  };

  const update = (configuration) => {
    setOperation("update");
    setPage("add");
    let toString = "";
    let ccString = "";
    configuration.to.map((value, index) => {
      if (index !== configuration.to.length) {
        toString += `${value}, `;
      }
      // toString += value;
    });
    configuration.cc.map((value, index) => {
      console.log("this = ", value);
      if (index !== configuration.cc.length) {
        ccString += `${value}, `;
      }
      // console.log(ccString);
      // ccString += value;
    });

    setState({
      name: configuration.name,
      to: toString.slice(0, -2),
      cc: ccString.slice(0, -2),
      subject: configuration.subject,
      body: configuration.body,
      id: configuration._id,
    });
  };

  const deleteConfig = async (configuration) => {
    // handle config deletion here
    const api = `/api/emailConfigurations/dynamicOperations`;
    const payload = {
      operation: "delete",
      id: configuration._id,
    };
    const response = await axios.post(api, payload);
    alert(response.data.message);
    resetPage();
  };

  const resetPage = () => {
    setState({});
    setPage("main");
    setOperation("");
    setConfigs([]);
  };

  const handlePage = async (pageSelected) => {
    if (pageSelected === "view") {
      setLoading(true);
      const getConfigsApi = `/api/emailConfigurations/view`;
      const response = await axios.get(getConfigsApi);
      if (response.status === 500 || !response.data.status) {
        alert(response.data.message);
        return;
      }
      setConfigs(response.data.message);
      setLoading(false);
    }
    setPage(pageSelected);
    setOperation("add");
  };

  const fetchUser = () => {
    let info = JSON.parse(localStorage.info);
    const cryptr = new Cryptr("myTotalySecretKey");
    let details = JSON.parse(cryptr.decrypt(info));
    console.log(details);
    setUser({
      user_id: details.user_id,
      name: details.name,
      role: details.role,
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const styles = {
    container: {
      margin: "2vh auto 4vh auto",
      width: "60vw",
      textAlign: "center",
      border: "0.5px solid #bbb",
      padding: "0 1rem",
    },
    mainPageContainer: {
      display: "grid",
      placeItems: "center",
    },
    mainPageButtonContainer: {
      display: "flex",
      flex: "1 1 200px",
      width: "70%",
    },
    mainPageButton: {
      width: "40%",
      backgroundColor: "#2980b9",
      padding: "0.375rem 0.75rem",
      color: "#fff",
      margin: "2vh auto",
    },
    cancelContainer: {
      // border: "0.1px solid #ccc",
    },
    cancelSubContainer: {
      display: "flex",
      padding: "2vh 0",
      marginRight: "1vw",
    },
    cancelSubContainerlabel: {
      width: "20%",
    },
    cancelSubContainerInput: {
      width: "100%",
    },
    button: {
      width: "50%",
      backgroundColor: "#2980b9",
      padding: "0.375rem 0.75rem",
      color: "#fff",
      margin: "5px",
    },
    backButton: {
      display: "block",
      width: "30%",
      backgroundColor: "#2980b9",
      padding: "0.375rem 0.75rem",
      color: "#fff",
      margin: "1vh auto 0 auto",
    },
    subDivs: {
      marginBottom: "2vh",
      marginTop: "1vh",
    },
    h3: {
      backgroundColor: "#2980b9",
      padding: "1rem 0.75rem",
      color: "#fff",
      margin: "0 -1rem",
    },
    viewCardContainer: {
      border: "0.1px solid #bbb",
      margin: "1vh auto",
    },
    viewCardRow: {
      display: "flex",
    },
    viewCardLabel: {
      width: "20%",
      textAlign: "left",
      paddingLeft: "1%",
    },
    viewCardHeader: {
      width: "90%",
      backgroundColor: "#2980b9",
      color: "#fff",
      // padding: "0.5rem 0.25rem",
      textAlign: "left",
      paddingLeft: "1%",
      marginBottom: "0",
    },
    viewCardHeaderContainer: {
      display: "flex",
      flex: "1 1 50px",
    },
    viewCardheaderButton: {
      width: "5%",
      backgroundColor: "#2980b9",
      color: "#fff",
    },
    table: {
      borderCollapse: "collapse",
      width: "100%",
    },
    td: {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
    tdLabel: {
      width: "20%",
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  };

  const emailConfigMainPage = (
    <Fragment>
      <h3 style={styles.h3}>Email Configurations DB</h3>
      <div style={styles.mainPageContainer}>
        <div style={styles.mainPageButtonContainer}>
          {user.role === "developer" ? (
            <button
              style={styles.mainPageButton}
              onClick={() => handlePage("add")}
            >
              ADD
            </button>
          ) : null}
          <button
            style={styles.mainPageButton}
            onClick={() => handlePage("view")}
          >
            VIEW
          </button>
        </div>
      </div>
    </Fragment>
  );

  const viewPage = (
    <Fragment>
      <h3 style={styles.h3}>Email Configurations DB</h3>
      <div style={styles.subDivs}>
        {configs.map((config) => (
          <div style={styles.viewCardContainer}>
            <div style={styles.viewCardHeaderContainer}>
              <h5 style={styles.viewCardHeader}>{config.name}</h5>
              <button
                style={styles.viewCardheaderButton}
                onClick={() => update(config)}
              >
                <i class="fas fa-pencil-alt"></i>
              </button>
              <button
                style={styles.viewCardheaderButton}
                onClick={() => deleteConfig(config)}
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
            <div style={styles.viewCardRow}>
              <table style={styles.table}>
                <tr>
                  <td style={styles.tdLabel}>To</td>
                  <td style={styles.td}>
                    {config.to.length === 1 ? config.to : config.to.toString()}
                  </td>
                </tr>
              </table>
            </div>
            <div style={styles.viewCardRow}>
              <table style={styles.table}>
                <tr>
                  <td style={styles.tdLabel}>CC</td>
                  <td style={styles.td}>
                    {config.cc.length === 1 ? config.cc : config.cc.toString()}
                  </td>
                </tr>
              </table>
            </div>
            <div style={styles.viewCardRow}>
              <table style={styles.table}>
                <tr>
                  <td style={styles.tdLabel}>Subject</td>
                  <td style={styles.td}>{config.subject}</td>
                </tr>
              </table>
            </div>
            <div style={styles.viewCardRow}>
              <table style={styles.table}>
                <tr>
                  <td style={styles.tdLabel}>Body</td>
                  <td style={styles.td}>{config.body}</td>
                </tr>
              </table>
            </div>
          </div>
        ))}
        <button style={styles.backButton} onClick={() => resetPage()}>
          Back
        </button>
      </div>
    </Fragment>
  );

  const addPage = (
    <Fragment>
      <h3 style={styles.h3}>Email Configurations DB</h3>
      <div style={styles.subDivs}>
        <div style={styles.cancelContainer}>
          <div style={styles.cancelSubContainer}>
            <div style={styles.cancelSubContainerlabel}>Name</div>
            <div style={styles.cancelSubContainerInput}>
              {operation === "add" ? (
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => handleStateChange("name", e)}
                  value={state.name ? state.name : ""}
                ></input>
              ) : operation === "update" && user.role === "developer" ? (
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => handleStateChange("name", e)}
                  value={state.name ? state.name : ""}
                ></input>
              ) : (
                state.name
              )}
            </div>
          </div>
          <div style={styles.cancelSubContainer}>
            <div style={styles.cancelSubContainerlabel}>To</div>
            <div style={styles.cancelSubContainerInput}>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleStateChange("to", e)}
                value={state.to ? state.to : ""}
              ></input>
            </div>
          </div>
          <div style={styles.cancelSubContainer}>
            <div style={styles.cancelSubContainerlabel}>CC</div>
            <div style={styles.cancelSubContainerInput}>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleStateChange("cc", e)}
                value={state.cc ? state.cc : ""}
              ></input>
            </div>
          </div>
          <div style={styles.cancelSubContainer}>
            <div style={styles.cancelSubContainerlabel}>Subject</div>
            <div style={styles.cancelSubContainerInput}>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleStateChange("subject", e)}
                value={state.subject ? state.subject : ""}
              ></input>
            </div>
          </div>
          <div style={styles.cancelSubContainer}>
            <div style={styles.cancelSubContainerlabel}>Body</div>
            <div style={styles.cancelSubContainerInput}>
              <textarea
                className="form-control"
                onChange={(e) => handleStateChange("body", e)}
                value={state.body ? state.body : ""}
              ></textarea>
            </div>
          </div>
        </div>
        <div style={{ diplay: "flex" }}>
          <button style={styles.button} onClick={handleSave}>
            Save Configuration
          </button>
          <button style={styles.button} onClick={handleTest}>
            Test Email
          </button>
        </div>
        <button style={styles.backButton} onClick={() => resetPage()}>
          Back
        </button>
      </div>
    </Fragment>
  );

  return (
    <div style={styles.container}>
      {page === "main"
        ? emailConfigMainPage
        : page === "add"
        ? addPage
        : viewPage}
    </div>
  );
};

export default EmailConfigurations;

import React, { useState, useEffect } from "react";
import Axios from "axios";

export default function PickupAddressToggle() {
  const [pickupAddressSwapped, setPickupAddressSwapped] = useState(false);

  const togglePickupAddress = async () => {
    await Axios.post(`/api/warehouse/togglePickupAddress`, {
      toggledOn: !pickupAddressSwapped,
    });
    fetchTogglePickupAddress();
  };

  const fetchTogglePickupAddress = async () => {
    const result = await Axios.get(`/api/warehouse/getTogglePickupAddress`);

    if (result.data) setPickupAddressSwapped(true);
    else setPickupAddressSwapped(false);
  };

  console.log(pickupAddressSwapped);

  useEffect(() => {
    fetchTogglePickupAddress();
  }, []);

  return (
    // <div
    //   style={{
    //     padding: "20px",
    //     fontSize: "1.2rem",
    //   }}
    // >
    //   Toggle Pick Up Address To Delhi
    //   {pickupAddressSwapped ? (
    //     <i
    //       style={{ marginLeft: "20px", fontSize: "25px", color: "green" }}
    //       className="fas fa-toggle-on aggregator-op"
    //       onClick={() => togglePickupAddress()}
    //     ></i>
    //   ) : (
    //     <i
    //       style={{ marginLeft: "20px", fontSize: "25px", color: "red" }}
    //       className="fas fa-toggle-off aggregator-op"
    //       onClick={() => togglePickupAddress()}
    //     ></i>
    //   )}
    // </div>
    ""
  );
}

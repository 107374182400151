import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import Spinner from "../../home/res/Spinner";
import TagBar from "../../../trackingboard/OrderTracker/components/Items/TagBar";

function ThresholdCalculator() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filterd, setFiltered] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState([]);
  const [COGs, setCOGs] = useState([]);
  const [GSTs, setGSTs] = useState([]);
  const [sellingPriceValue, setSellingPirce] = useState(0);
  const [integralDeliveryPercentage, setIntegralDeliveryPercentage] = useState(
    0
  );
  const [integralRTOPercentage, setIntegralRTOPercentage] = useState(0);
  const [threshold, setThreshold] = useState();

  const updateProducts = async (value) => {
    let store = value;
    console.log(store);
    let productList = await Axios.get(
      `/api/globalDashboard/fetchProducts/${encodeURIComponent(store)}`
    );
    setProducts(productList.data);
    setFiltered(productList.data);
    setStore(JSON.parse(value));
  };

  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStore(storeList.data[0]);
    setStores(storeList.data);
  };

  const submitDetails = async () => {
    let selectedProducts = global.selected;
    let productsList = selectedProducts.map((item) => {
      return { "line_items.product_id": parseInt(item[0]) };
    });
    console.log(store, productsList);
    setLoading(true);
    let result = await Axios.post("/api/stats/thresholdCalculator", {
      store,
      product: productsList,
    });
    setLoading(false);
    console.log(result.data);
    let {
      COGs,
      GSTs,
      sellingPrice,
      integralDeliveryPercentage,
      integralRTOPercentage,
    } = result.data;

    setGSTs(GSTs);
    setCOGs(COGs);
    setSellingPirce(sellingPrice);
    setIntegralDeliveryPercentage(integralDeliveryPercentage);
    setIntegralRTOPercentage(integralRTOPercentage);
    setFetched(true);
  };

  const calculateThreshold = () => {
    let length = document.getElementById("length").value;
    let width = document.getElementById("width").value;
    let height = document.getElementById("height").value;
    let deadWeight = document.getElementById("deadWeight").value;

    let sellingPrice = document.getElementById("sellingPrice").value
      ? document.getElementById("sellingPrice").value
      : sellingPriceValue;

    let GST = document.getElementById("GST").value;
    let COGS = document.getElementById("COGS").value;

    let volWeight = (length * width * height) / 5000;
    let actualWeight = deadWeight > volWeight ? deadWeight : volWeight;
    let multiple = Math.ceil(actualWeight / 0.5);

    let forward = document.getElementById("forwardShipping").value
      ? document.getElementById("forwardShipping").value
      : 50;
    let codPercent = document.getElementById("forwardShipping").value
      ? document.getElementById("forwardShipping").value
      : 2;
    let fixedCOD = document.getElementById("forwardShipping").value
      ? document.getElementById("forwardShipping").value
      : 40;
    let rto = document.getElementById("forwardShipping").value
      ? document.getElementById("forwardShipping").value
      : 50;

    let CODFee = (codPercent / 100) * sellingPrice;

    let codCharge = CODFee > fixedCOD ? CODFee : fixedCOD;
    let forwardShippingCost = multiple * forward;
    let rtoShippingCost = multiple * rto;

    // console.log(
    //   `Multiple ${multiple}\nForward: ${forward}\nRTO: ${rto}\nCOD: ${CODFee}`
    // );

    console.log(
      `COD: ${codCharge}\nForward: ${forwardShippingCost}\nRTO: ${rtoShippingCost}\n`
    );

    let shippingCostPerPackageForEveryDeliveredOrder =
      forwardShippingCost + codCharge;
    let shippingCostPerPackageForEveryRTOedOrder =
      forwardShippingCost + rtoShippingCost;

    console.log(
      `Shipping Cost Per Order Delivered: ${shippingCostPerPackageForEveryDeliveredOrder}\nShipping Cost Per Order Delivered: ${shippingCostPerPackageForEveryRTOedOrder}\n`
    );

    console.log(
      `Integral Delivery Percentage: ${integralDeliveryPercentage}\nIntegral RTO Percentage: ${integralRTOPercentage}\n`
    );

    let shippingExpenseForDeliveredOrders =
      integralDeliveryPercentage * shippingCostPerPackageForEveryDeliveredOrder;
    let shippingExpenseForRTOOrders =
      integralRTOPercentage * shippingCostPerPackageForEveryRTOedOrder;

    let delRev = integralDeliveryPercentage * sellingPrice;
    GST = (GST / 100) * delRev;
    let shippingExpenseTotal =
      shippingExpenseForDeliveredOrders + shippingExpenseForRTOOrders;
    COGS = integralDeliveryPercentage * COGS;
    let x = delRev - GST - shippingExpenseTotal - COGS;

    let y = x / 100;

    console.log(
      `Selling Price: ${sellingPrice}\nDelivery Revenue: ${delRev}\nGST: ${GST}\nCOGS: ${COGS}\nTotal Shipping Expense: ${shippingExpenseTotal}`
    );

    console.log(
      `Shipping Expense For Delivered Orders: ${shippingExpenseForDeliveredOrders}`
    );

    console.log(
      `Shipping Expense For RTO Orders: ${shippingExpenseForRTOOrders}`
    );

    console.log(`Y: ${y}\nX: ${x}`);

    setThreshold(y);
  };

  const multiCheck = (e) => {
    if (e.keyCode === 13) {
      let value2send = document.querySelector(
        `#productList option[value="${
          document.getElementById("productSearch").value
        }"]`
      )
        ? document.querySelector(
            `#productList option[value="${
              document.getElementById("productSearch").value
            }"]`
          ).dataset.value
        : "";

      if (value2send) {
        global.selected.push([...value2send.split(",")]);

        document.getElementById("productSearch").value = "";

        let productList = global.selected.map((item) => item[0]);

        let newArray = products.filter((product) => {
          return !productList.includes(product[0].toString());
        });

        console.log(global.selected);
        setFiltered(newArray);
      } else {
        alert("Choose a product");
      }
    }
  };

  useEffect(() => {
    global.selected = [];
    getStores();
  }, []);
  return (
    <div>
      <div className="moduleBar" style={{ marginBottom: "20px" }}>
        <select
          placeholder="Stores"
          id="store_selector"
          className="form-control form-control-sm"
          style={{
            height: "50px",
            fontSize: "0.9rem",
            borderRadius: 0,
          }}
          onChange={(e) => updateProducts(e.target.value)}
        >
          <option value={JSON.stringify(store)} selected disabled>
            Choose a store
          </option>
          {stores.map((store, index) => (
            <option value={JSON.stringify(store)} key={index}>
              {store.storeName}
            </option>
          ))}
        </select>

        <input
          type="text"
          id={"productSearch"}
          list="productList"
          autoComplete="off"
          placeholder="Products"
          className="form-control form-control-sm"
          style={{
            height: "50px",
            fontSize: "0.9rem",
            borderRadius: 0,
            width: "100%",
          }}
          onKeyDown={(e) => {
            multiCheck(e);
          }}
        />
        <datalist id="productList">
          {filterd.map((option, index) => (
            <option key={index} data-value={option} value={option[0]}>
              {option[1]}
            </option>
          ))}
        </datalist>

        <button
          className="btn btn-danger"
          style={{ borderRadius: 0 }}
          onClick={() => submitDetails()}
        >
          Submit
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        {global.selected
          ? global.selected.map((item, i) => (
              <TagBar item={item} key={i} permuteOrders={getStores} />
            ))
          : ""}
      </div>
      <div style={{ margin: "10px" }}>
        {fetched ? (
          loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="shipping" style={{ marginBottom: "20px" }}>
                <h6>Shipping</h6>
                {/* <label>Forward Shipping</label> */}
                <input
                  className="form-control"
                  placeholder="Forward Shipping (Default 50)"
                  type="number"
                  id="forwardShipping"
                  style={{ height: "40px", borderRadius: 0 }}
                />
                <input
                  className="form-control"
                  placeholder="COD Percent (Default 2)"
                  type="number"
                  id="codPercent"
                  style={{ height: "40px", borderRadius: 0 }}
                />
                <input
                  className="form-control"
                  placeholder="Fixed COD (Default 40)"
                  type="number"
                  id="fixedCOD"
                  style={{ height: "40px", borderRadius: 0 }}
                />
                <input
                  className="form-control"
                  placeholder="RTO Shipping (Default 50)"
                  type="number"
                  id="RTOShipping"
                  style={{ height: "40px", borderRadius: 0 }}
                />
              </div>
              <div className="dimensions" style={{ marginBottom: "20px" }}>
                <h6>Dimensions</h6>
                <input
                  className="form-control"
                  placeholder="Length"
                  type="number"
                  id="length"
                  style={{ height: "40px", borderRadius: 0 }}
                />
                <input
                  className="form-control"
                  placeholder="Width"
                  type="number"
                  id="width"
                  style={{ height: "40px", borderRadius: 0 }}
                />
                <input
                  className="form-control"
                  placeholder="Height"
                  type="number"
                  id="height"
                  style={{ height: "40px", borderRadius: 0 }}
                />
                <input
                  className="form-control"
                  placeholder="Dead Weight"
                  type="number"
                  id="deadWeight"
                  style={{ height: "40px", borderRadius: 0 }}
                />
              </div>
              <div className="dimensions" style={{ marginBottom: "20px" }}>
                <h6>Miscellaneous</h6>
                <input
                  className="form-control"
                  placeholder="GST"
                  type="GST"
                  id="GST"
                  autoComplete="off"
                  list="GSTList"
                  style={{ height: "40px", borderRadius: 0 }}
                />

                <datalist id="GSTList">
                  {GSTs.map((value, i) => (
                    <option value={value} key={i} />
                  ))}
                </datalist>

                <input
                  className="form-control"
                  placeholder="Cost Of Goods"
                  list="COGSList"
                  type="COGS"
                  autoComplete="off"
                  id="COGS"
                  style={{ height: "40px", borderRadius: 0 }}
                />

                <datalist id="COGSList">
                  {COGs.map((value, i) => (
                    <option value={value} key={i} />
                  ))}
                </datalist>

                <input
                  className="form-control"
                  placeholder={`Selling Price (${
                    sellingPriceValue ? sellingPriceValue : 0
                  })`}
                  type="text"
                  autoComplete="off"
                  id="sellingPrice"
                  style={{ height: "40px", borderRadius: 0 }}
                />
              </div>
              <button
                className="btn btn-danger"
                style={{ borderRadius: 0 }}
                onClick={() => calculateThreshold()}
              >
                Submit
              </button>
              {threshold ? (
                <center>{threshold ? threshold.toFixed(2) : 0}</center>
              ) : (
                ""
              )}
            </Fragment>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ThresholdCalculator;

import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import ProductItem from "../res/ProductItem";
import { GlobalConsumer } from "../../../Global-Context/global-context";

function FakeDimensions() {
  let context = useContext(GlobalConsumer);
  let userInfo = context.getUser();
  const [user, setUser] = useState(userInfo);
  const [products, setProducts] = useState([]);
  const [mapped, setMapped] = useState([]);
  const [stores, setStores] = useState([]);

  const selectAll = value => {
    let temp = [];
    products.map(product => {
      console.log(product);
      product.variants.map(variant => {
        if (product.store.shopifyName === value)
          temp.push({
            store: product.store,
            name: product.title + variant.title,
            product_id: product.product_id,
            variant_id: variant.variant_id
          });
      });
    });

    setMapped(temp);
  };

  const submit = async () => {
    console.log("Submit", mapped);
    let result = await Axios.post(
      "/api/product/storeProductsForFakeDimensions",
      {
        products: mapped
      }
    );
    console.log(result.data);
  };

  const getProducts = async () => {
    const userData = user.user_id;

    let result = await Axios.get(
      `/api/product/getAllFavourites?user=${userData}`
    );
    let res = await Axios.get(`/api/IMS/getInventory?user=${userData}`);
    console.log("Fetched Favourites", result.data.products);
    setProducts(result.data.products);
  };

  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
    console.log(storeList.data);
  };

  const checkIfChecked = variant => {
    let checked = mapped.filter(
      product =>
        product.product_id === variant.product_id &&
        product.variant_id === variant.variant_id
    );

    if (checked.length > 0) return true;
  };

  console.log(mapped);

  useEffect(() => {
    getProducts();
    getStores();
  }, []);
  return (
    <div>
      {products.map(product =>
        product.variants.map(variant => {
          return (
            <ProductItem
              variant={variant}
              product={product}
              key={variant.variant_id}
              checked={checkIfChecked(variant)}
              mapped={mapped}
              setMapped={setMapped}
            />
          );
        })
      )}
      <div className="form-group inventoryForm">
        {/* <input
          type="text"
          list="storeListt"
          className="form-control inventory_input"
          id={`storeListt`}
          placeholder={`Choose Store For Group Selecting`}
          autoComplete="off"
          //   onKeyDown={e => {
          //     if (e.keyCode === 13) selectAll();
          //   }}
        /> */}

        <select
          id="storeListt"
          className="form-control inventory_input"
          onChange={e => {
            selectAll(e.target.value);
          }}
        >
          <option selected>Choose Store For Group Selecting (Custom)</option>;
          {stores.map((store, i) => {
            return (
              <option value={store.shopifyName} key={i}>
                {store.storeName}
              </option>
            );
          })}
        </select>
      </div>
      {/* <button
        type="button"
        id={`inventory_creator`}
        className="btn btn-success  btn-product-add btn-inventory-creator"
        onClick={e => selectAll()}
      >
        Select All
      </button>{" "} */}
      <button
        type="button"
        id={`inventory_creator`}
        className="btn btn-success  btn-product-add btn-inventory-creator"
        onClick={e => submit()}
      >
        Submit
      </button>{" "}
    </div>
  );
}

export default FakeDimensions;

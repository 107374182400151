import React, { useState, Fragment, useEffect } from "react";
import { confirmation } from "../../utils/confirmation";
import Axios from "axios";

const ProductSubstitute = ({
  product,
  store,
  order,
  fetchOrders,
  setShowSubstituteProduct,
}) => {
  const [inventory, setInventory] = useState([]);

  const fetchInventory = async () => {
    let res = await Axios.get(`/api/IMS/getInventoryInStock`);
    console.log(res.data.inventory);
    setInventory(res.data.inventory);
  };

  const handleProductSubstitute = async (substitute, store) => {
    substitute = JSON.parse(substitute);

    product = {
      product_id: product.product_id,
      variant_id: product.variant_id,
      name: product.name,
    };

    console.log(product, substitute, store, order);
    if (confirmation()) {
      if (confirmation()) {
        let result = await Axios.post("/api/operations/substituteProduct", {
          product,
          inventory: substitute,
          store,
          order,
        });

        if (result.data.message) alert(result.data.message);

        setShowSubstituteProduct(false);
        fetchOrders(store);
      }
    }
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  return (
    <div className="inventory-select-substitute">
      <select
        style={{
          width: "inherit",
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          padding: "10px",
        }}
        onChange={(e) => handleProductSubstitute(e.target.value, store)}
      >
        <option>Choose Substitution</option>
        {inventory.map((item) =>
          item.products.map((product) => (
            <option
              key={item.id + product.variant_id}
              value={JSON.stringify({
                product_id: product.product_id,
                variant_id: product.variant_id,
                name: product.name,
              })}
            >
              {product.name}
            </option>
          ))
        )}
      </select>
      {/* <button
          className="btn btn-primary"
          style={{
            height: "100%",
            fontSize: "0.9rem",
            borderRadius: 0,
          }}
          onClick={() => handleProductSubstitute(product, store)}
        >
          Substitute
        </button> */}
    </div>
  );
};

export default ProductSubstitute;

import React from "react";

const TagBar = ({
  item,
  permuteOrders,
  setFiltered,
  filtered,
  callOnDelete,
  setAllProducts,
}) => {
  const handleClick = (item) => {
    if (item[0].includes("Include all")) {
      let index = global.selected.indexOf(item);
      setFiltered([...filtered]);

      if (index > -1) {
        global.selected.splice(index, 1);
      }
      if (item[0].includes(`Include all products for all stores`)) {
        setAllProducts((current) => {
          const newCurrent = {};
          Object.keys(current).map((key) => {
            newCurrent[key] = false;
          });
          return newCurrent;
        });
      } else {
        setAllProducts((current) => ({
          ...current,
          [item[2]]: false,
        }));
      }
      return;
    }
    item[0] = parseInt(item[0]);
    let index = global.selected.indexOf(item);
    if (setFiltered) {
      setFiltered([...filtered, item]);
    }

    if (index > -1) {
      global.selected.splice(index, 1);
    }
    if (callOnDelete) callOnDelete(item);
    if (permuteOrders) permuteOrders();
  };
  return (
    <div className="itemTag" id={`tag_${item}`}>
      {item[1]}
      <div className="cross" onClick={() => handleClick(item)}>
        x
      </div>{" "}
    </div>
  );
};

export default TagBar;

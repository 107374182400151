import React, { useState, useEffect } from "react";

function Pagination({
  total,
  toShow,
  getOrders,
  user,
  type,
  current,
  setCurrent,
  ordersPerPage,
}) {
  let pageCount =
    total % ordersPerPage === 0
      ? parseInt(total / ordersPerPage)
      : parseInt(total / ordersPerPage) + 1;

  const condenseArray = (index) => {
    setCurrent(index);
    getOrders(user, type, index);
  };

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      <nav
        aria-label="..."
        style={{
          display: "inline-block",
          margin: "0 auto",
          marginTop: "20px",
          padding: "3px",
        }}
      >
        <ul className="pagination">
          <li
            className="page-item disabled page-link"
            onClick={() => {
              if (current !== 1) condenseArray(current - 1);
            }}
          >
            <span aria-hidden="true">&laquo;</span>
          </li>
          {Array.apply(null, Array(pageCount)).map((val, idx) => {
            idx = idx + 1;
            if (
              idx === current ||
              idx === 1 ||
              idx === pageCount ||
              idx === current - 1 ||
              idx === current + 1
            )
              return (
                <li
                  className={`page-item page-link ${
                    current === idx ? "page-active" : ""
                  }`}
                  key={idx}
                  onClick={() => condenseArray(idx)}
                >
                  {idx}
                </li>
              );
          })}
          <li
            className="page-item page-link"
            onClick={() => {
              if (current !== pageCount) condenseArray(current + 1);
            }}
          >
            <span aria-hidden="true">&raquo;</span>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;

import axios from "axios";
import React, { useState } from "react";
import StoreDropdownButton from "../res/StoreDropdownButton";

function LinkShipmentAndOrder() {
  document.title = `Link SR Shipment and Shopify order`;
  const [formState, setFormState] = useState({
    orderName: "",
    shipmentOrderId: "",
    orderNameError: "",
    shipmentOrderIdError: "",
    valid: false,
  });
  const [store, setStore] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const validateFields = (name, value) => {
    const currentState = formState;
    switch (name) {
      case "orderName":
        if (value !== "") {
          currentState.orderName = value;
          currentState.orderNameError = "";
        } else {
          currentState.orderName = value;
          currentState.orderNameError = "Please enter a shopify order name!";
        }
        break;

      case "shipmentOrderId":
        const regex = /^\d+$/;
        if (value !== "" && regex.test(value)) {
          currentState.shipmentOrderId = value;
          currentState.shipmentOrderIdError = "";
        } else {
          currentState.shipmentOrderId = value;
          currentState.shipmentOrderIdError =
            "Please enter a valid shipment order Id!";
        }

      default:
        break;
    }

    currentState.valid =
      currentState.orderName !== "" &&
      !currentState.orderNameError &&
      currentState.shipmentOrderId !== "" &&
      !currentState.shipmentOrderIdError;

    // set the final state
    setFormState({
      ...currentState,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      store,
      shopifyOrderName: formState.orderName,
      SROrderId: formState.shipmentOrderId,
    };
    console.log("Submit = ", payload);
    let data;
    try {
      const response = await axios.post(
        "/api/operations/linkShipmentAndOrder",
        payload
      );
      data = response.data;
    } catch (error) {
      setResponseMessage(error.message);
      return;
    }
    console.log(data);
    setResponseMessage(data.message);
    // if (data.status) window.open(response.data.link, "_blank");
    // https://${shopifyName}.myshopify.com/admin/orders/${finalData.draft_order.order_id}
  };

  return (
    <div className="container w-50 mt-5">
      <div className="w-50 mx-auto rounded border">
        <div className="bg-light p-3">
          <StoreDropdownButton
            setStore={setStore}
            store={store}
            showDeleted={false}
          />
          <span></span>
        </div>
        <form className="p-3" onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group">
            <label>Shopify Order Name</label>
            <input
              className="form-control"
              type="text"
              name="orderName"
              onChange={(e) => validateFields(e.target.name, e.target.value)}
              required
            ></input>
            {formState.orderNameError !== "" ? (
              <small className="form-text text-danger">
                {formState.orderNameError}
              </small>
            ) : (
              <small className="form-text text-muted">
                {"The name of the order in Shopify. Eg - #SXP23641"}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Shiprocket Shipment ID</label>
            <input
              className="form-control"
              type="text"
              name="shipmentOrderId"
              onChange={(e) => validateFields(e.target.name, e.target.value)}
              required
            ></input>
            {formState.shipmentOrderIdError !== "" ? (
              <small className="form-text text-danger">
                {formState.shipmentOrderIdError}
              </small>
            ) : (
              <small className="form-text text-muted">
                {"The order id of the shipment in Shiprocket. Eg - 165402349"}
              </small>
            )}
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!(formState.valid && store)}
            >
              Submit
            </button>
            {responseMessage ? (
              <span
                className={`d-block mt-3 ${
                  responseMessage === "Linked order successfully!"
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {responseMessage}
              </span>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
}

export default LinkShipmentAndOrder;

import React, { useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { login } from "../functions/login";
import { GlobalConsumer } from "../../Global-Context/global-context";
import forgotPassword from "../functions/forgotPassword";
import Spinner from "../../components/home/res/Spinner";

const Login = () => {
  document.title = "Login";
  const context = useContext(GlobalConsumer);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const { email, password } = formData;

  const handleForgotPassword = async () => {
    let email = prompt(
      "Enter your email address below and wait for instructions"
    );
    if (!email) {
      alert("Please enter an email to forward reset link.");
      return;
    }
    setLoading(true);
    email = email.trim();
    console.log(email);
    console.log("Forgot password link email entered : ", email);
    let response = await forgotPassword(email);
    setLoading(false);
    alert(response);
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = await login(email, password);
    if (data) context.authenticate(data);
  };

  if (context.isAuthenticated) {
    // textToSpeech(`Hi ${context.getUser().name}, Welcome To Jarvis`);
    return <Redirect to="/" />;
  }

  return (
    <div className="login-container">
      {!loading ? (
        <div className="login">
          <h2>Login</h2>
          <form className="form" onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => onChange(e)}
                name="email"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="4"
              />
            </div>
            <input type="submit" className="btn loginbutton" value="Login" />
          </form>
          <div style={{ position: "relative" }}>
            <a href="#" onClick={handleForgotPassword} className="login-forgot">
              Forgot Password?
            </a>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Login;

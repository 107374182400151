import React, { useState, useEffect, Fragment, useContext } from "react";
import Axios from "axios";
import Spinner from "../res/Spinner";
import useDatePicker from "../../global/DatePicker";
import TagBar from "../../../trackingboard/OrderTracker/components/Items/TagBar";

const AbandonedModule = ({ number, active, setActive }) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [DatePicker, fromDate, toDate, clearDate] = useDatePicker("2020");

  const initializaModule = async () => {
    let store = JSON.parse(document.getElementById("store_selector").value);
    if (!store) return alert("Please choose a store");
    let data = {
      store,
      fromDate,
      toDate,
    };

    setLoading(true);

    let result = await Axios.post("/api/stats/abandonedStats", data);
    setLoading(false);
    setResult(result.data);
  };
  const getStores = async () => {
    let storeList = await Axios.get(`/api/stores/`);
    setStores(storeList.data);
  };
  const updateStore = async (value) => {
    setStore(JSON.parse(value));
  };

  useEffect(() => {
    getStores();
    global.selected = [];
    return () => {
      clearDate();
    };
  }, []);

  return (
    <Fragment>
      {active === number ? (
        <center>
          <p style={{ borderBottom: "1px solid black", width: "100px" }}>
            {"Abandoned Module"}
          </p>
        </center>
      ) : (
        ""
      )}
      <div
        id={`module_${number}`}
        className={`card moduleCard ${
          active === number ? "activeModuleCard" : ""
        }`}
        style={{ marginBottom: "30px" }}
        onClick={(e) => setActive(4)}
      >
        {active === number ? (
          <Fragment>
            {loading ? (
              <div style={{ margin: "20px" }}>
                <Spinner />
              </div>
            ) : (
              <div className="moduleContent">
                <div
                  className="moduleBar"
                  style={{
                    marginTop: "10px",
                    justifyContent: "center",
                  }}
                >
                  {global.selected.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "10px",
                        width: "50%",
                      }}
                    >
                      {global.selected.map((item, i) => (
                        <TagBar
                          item={item}
                          key={i}
                          permuteOrders={getStores}
                          setFiltered={setFiltered}
                          filtered={filtered}
                        />
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="stats-filter"
                    style={{ width: "100%", height: "40px", boxShadow: "none" }}
                  >
                    <select
                      placeholder="Stores"
                      id="store_selector"
                      className="form-control form-control-sm"
                      style={{
                        height: "100%",
                        fontSize: "0.9rem",
                        borderRadius: 0,
                      }}
                      onChange={(e) => updateStore(e.target.value)}
                    >
                      <option value={JSON.stringify(store)} selected disabled>
                        {store ? store.storeName : "Choose Store"}
                      </option>
                      {stores.map((store, index) => (
                        <option value={JSON.stringify(store)} key={index}>
                          {store.storeName}
                        </option>
                      ))}
                    </select>

                    <DatePicker />

                    <button
                      className="btn btn-danger"
                      style={{ borderRadius: 0 }}
                      onClick={() => initializaModule()}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                {result ? (
                  <div>
                    <div className="flag-module-filter-info">
                      {`Displaying orders between ${new Date(
                        fromDate
                      ).toLocaleDateString()} & ${new Date(
                        toDate
                      ).toLocaleDateString()} `}
                    </div>

                    <table
                      className="table mx-auto"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      <tbody>
                        <tr>
                          <td>Total Abandoned Orders: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.totalAbandonedOrders}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Orders Called: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.called}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Orders Manifested:</td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            {result.manifestedOrders}
                          </td>
                        </tr>
                        <tr>
                          <td>Orders Cancelled Before Being Manifested: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.cancelledBeforeOrders}
                          </td>
                        </tr>
                        <tr>
                          <td>Orders Cancelled After Being Manifested: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            {result.cancelledAfterOrders}
                          </td>
                        </tr>
                        <tr>
                          <td>Orders Delivered: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.deliveredOrders}
                          </td>
                        </tr>
                        <tr>
                          <td>Orders RTO'ed: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.rtoOrders}
                          </td>
                        </tr>
                        <tr>
                          <td>Delivery%: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.called != 0
                              ? (
                                  result.deliveredOrders /
                                  result.manifestedOrders
                                ).toFixed(2) * 100
                              : 0}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td>RTO%: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.called != 0
                              ? (
                                  result.rtoOrders / result.manifestedOrders
                                ).toFixed(2) * 100
                              : 0}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td>Acceptance%: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.called != 0
                              ? (
                                  result.manifestedOrders / result.called
                                ).toFixed(2) * 100
                              : 0}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td>Rejection%: </td>
                          <td
                            style={{
                              background: "#ecf0f1",
                              textAlign: "center",
                            }}
                          >
                            {result.called != 0
                              ? (
                                  (result.called - result.manifestedOrders) /
                                  result.called
                                ).toFixed(2) * 100
                              : 0}
                            %
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </Fragment>
        ) : (
          <div className="moduleInactive">
            <p>{"Abandoned Module"}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AbandonedModule;

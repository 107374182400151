import React from "react";
import Statistics from "./home/components/Statistics";

export default function HomeTwo() {
  return (
    <div>
      <Statistics />
    </div>
  );
}

import React from "react";

export default function HistoryElement({ element }) {
  return (
    <div className="bug-history-element">
      <div>{new Date(element.date).toLocaleString()}</div>
      <div>{element.error}</div>
    </div>
  );
}
